'use strict';

// implement memory store spec'd to Storage prototype
(function(window) {
  const items = {}

  function MemoryStorage() {}

  MemoryStorage.prototype.getItem = function(key) {
    return items[key]
  }

  MemoryStorage.prototype.setItem = function(key, value) {
    items[key] = value
  }

  MemoryStorage.prototype.key = function(index) {
    return Object.keys(items)[index]
  }

  MemoryStorage.prototype.get = function() {
    return items
  }

  Object.defineProperty(MemoryStorage.prototype, 'length', {
    get: function length() {
      return Object.keys(items).length
    },
  })

  window.memoryStorage = new MemoryStorage()

  window.getLocalStorage = function() {
    const x = '__storage_test__'
    const storage = window.localStorage

    try {
      storage.setItem(x, x)
      storage.removeItem(x)
      return storage
    } catch (e) {
      return window.memoryStorage
    }
  }
})(window)
