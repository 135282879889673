appRun.$inject = ['routerHelper']

  /* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getStates())
}

function getStates() {
  return [
    {
      state: 'login',
      config: {
        url: '/login',
        templateUrl: 'app/account/login-view.html',
        controller: 'LoginController',
        controllerAs: 'loginCtrl',
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.pageTitle = 'InSight'
          $rootScope.hideBarHeader = true
          $rootScope.state = 'login login-advisor'
        }],
      },
    },
    {
      state: 'login-client',
      config: {
        url: '/loginClient?message&code',
        templateUrl: 'app/account/login-client-view.html',
        controller: 'LoginController',
        controllerAs: 'loginCtrl',
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.pageTitle = 'Penn Mutual Login'
          $rootScope.hideBarHeader = true
          $rootScope.state = 'login'
        }],
      },
    },
    {
      state: 'unauthorized-policy-payment',
      config: {
        url: '/unauthorized-policy-payment',
        templateUrl: 'app/account/unauthorized-policy-payment.html',
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.pageTitle = 'Unauthorized Policy Payment'
          $rootScope.hideBarHeader = true
          $rootScope.state = 'unauthorized-policy-payment'
        }],
      },
    },
    {
      state: '403',
      config: {
        url: '/403',
        templateUrl: 'app/account/403.html',
        onEnter: ['$rootScope', function($rootScope) {
          $rootScope.pageTitle = 'Forbidden access'
          $rootScope.hideBarHeader = true
          $rootScope.state = 'forbidden-page'
        }],
      },
    },
  ]
}

export {appRun}
