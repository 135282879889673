/* eslint-disable no-underscore-dangle */
import { IPromise, ITimeoutService } from 'angular'
import {AlertService} from '../alert-service'

export class AlertTableController implements ng.IController {
  static $inject = [
    '$rootScope',
    '$scope',
    'EMPTY_RESULTS_STATES',
    'EMPTY_TABLE_STATES',
    'policyService',
    'policyUtils',
    '$state',
    'alertService',
    'downloadManager',
    'dateUtils',
    'CONSTANTS',
    'stringUtils',
    '$q',
    'authService',
    '$filter',
    'deviceUtils',
    '$timeout',
  ]

  moreData: boolean = true
  loadingMore: boolean = false
  currentPage: number = 1
  archiveBuffer: any[] = []
  lastInitTime: any
  SCROLL_DISTANCE: number = 1
  numArchived: number = 0
  orderByField: string = ''
  reverseSort: boolean = false
  viewArchived: boolean = false
  selectAll: boolean = false
  alerts: any[] = []
  filterResults
  currentCategory: string
  clientName: string
  policyId: string
  orphaned: boolean
  ignoreOrphanedFlag: boolean
  startdate: Date | null
  enddate: Date | null
  client: any
  activityCategory: string
  reloadTableTrigger: number = 0
  emptyResultsState: {icon: string, title: string, text: string}
  emptyTableState: string
  connError: any
  numFound: number

  constructor(private $rootScope, private $scope, private EMPTY_RESULTS_STATES, private EMPTY_TABLE_STATES,
    private policyService, private policyUtils, private $state, private alertService: AlertService,
    private downloadManager, private dateUtils, private CONSTANTS, private stringUtils, private $q: angular.IQService,
    private authService, private $filter: angular.IFilterFunction, public deviceUtils ,private $timeout: ITimeoutService) {

    this.filterResults = this.$scope.filterResults // NOTE: `this.$scope.filterResults` does not seem to ever get set.
    this.currentCategory = this.$scope.currentCategory
    this.clientName = this.$scope.clientName
    this.policyId = this.$scope.policyId
    this.orphaned = this.$scope.orphaned
    this.ignoreOrphanedFlag = (this.$scope.ignoreOrphanedFlag !== undefined ? this.$scope.ignoreOrphanedFlag : false)
    this.startdate = this.$scope.startdate
    this.enddate = this.$scope.enddate
    this.client = this.$scope.client
    this.activityCategory = this.$scope.activityCategory
    this.emptyResultsState = this.EMPTY_RESULTS_STATES.initialState
    this.emptyTableState = this.EMPTY_TABLE_STATES.inbox

    /**
     * NOTE: This does not seem to do anything useful. Need to investigate more.
     * Also, these `$watches` are not getting cleaned up by `$destroy`.
     */
    this.bindScopeToSelf('policyId')
    this.bindScopeToSelf('clientName')
    this.bindScopeToSelf('orphaned')
    this.bindScopeToSelf('startdate')
    this.bindScopeToSelf('enddate')
    this.bindScopeToSelf('activityCategory')

    // Because this gets called by an event handler it looses it's context.
    // this.search = this.search.bind(this);

    // TODO: Unsure why this is needed, this controller will be
    //      on the scope already. `search` is a public method.
    // this.$scope.publicSearch = this.search
    const clearExecuteSearch = this.$scope.$on('executeSearch', (_event, params: IAlertSearchParams, ignoreOrphans?: boolean, _sortInfo?: ISortInfo, _pagingInfo?: IPagingInfo) => {

      this.currentCategory = params.activityCategory
      this.clientName = params.insured
      this.policyId = params.pid
      this.orphaned = params.orphaned
      this.ignoreOrphanedFlag = (ignoreOrphans !== undefined ? ignoreOrphans : false)
      this.startdate = params.startdate
      this.enddate = params.enddate
      // this.client = this.$scope.client
      this.activityCategory = this.$scope.activityCategory
      this.loadingMore = false
      this.$onInit()
    })

    this.$scope.$on('$destroy', () => {
      if (typeof clearExecuteSearch === 'function') clearExecuteSearch()
    })
  }

  bindScopeToSelf(name) {
    this.$scope.$watch(name, function(val) {
      self[name] = val
    })
  }

  $onInit() {
    this.lastInitTime = Date.now()
    this.moreData = true
    this.currentPage = 1
    this.numArchived = 0
    this.archiveBuffer = []
    this.alerts = []
    this.nextPage()
  }

  markSelectedAsRead() {
    const checkedAlerts = this.archiveBuffer.map((alert) => alert.id)

    if (checkedAlerts.length) {
      this.alertService.markRead(checkedAlerts).then(() => {
        this.archiveBuffer.forEach(alert => alert.read = true)

        this.archiveBuffer = []
        this.waitAndUpdateAlerts(checkedAlerts.length)
          .then(() => this.$onInit())
      })
    }
  }

  viewPolicy(polNumber) {
    this.policyService.getPolicyBase(polNumber)
      .then((result) => {
        this.policyUtils.gotoPolicySummaryByPolicyBaseOrNumber(result.error === '' ? result.policyBase : false, polNumber)
      })
  }

  viewAlertDetails(alert) {
    if (!alert.read) {
      this.markAsRead(alert)
    }
    this.$state.go('.detail', {
      aid: alert.id,
    })
  }

  viewingByCat() {
    return Boolean(this.currentCategory)
  }

  viewingSearch() {
    return this.emptyResultsState === this.EMPTY_RESULTS_STATES.forPolicyId ||
      this.emptyResultsState === this.EMPTY_RESULTS_STATES.forClientName || this.emptyResultsState === this.EMPTY_RESULTS_STATES.forCategory
  }

  agentCellDisplay(alert) {
    let agentDisplay = (alert.orphaned ? '*' : '') + alert.agent
    let noAgent = alert.orphaned && !alert.agent

    return noAgent ? 'Unassigned' : agentDisplay
  }

  areAlertsEmpty() {
    return (this.alerts.length - this.numArchived) === 0
  }

  showEmptyState() {
    return this.areAlertsEmpty() && !this.loadingMore && this.viewingSearch()
  }

  sortColumnBy(sortColumn) {
    if (sortColumn !== this.orderByField) {
      this.orderByField = sortColumn
      this.reverseSort = false
    } else {
      this.reverseSort = !this.reverseSort
    }

    this.alerts = []
    this.search()
  }

  showSmallSpinner() {
    return this.isLoadingMore() && !this.$rootScope.showingMainSpinner
  }

  // TODO: This function is not neccessary, property is all that is required.
  isLoadingMore() {
    return this.loadingMore
  }

  // TODO: This function is not neccessary, property is all that is required.
  isScrollDisabled() {
    return this.loadingMore || !this.moreData
  }

  reloadTable() {
    console.debug('reloadTableTrigger', this.reloadTableTrigger)
    return this.reloadTableTrigger
  }

  _indexInArchiveBuffer(alert) {
    let i

    for (i = 0; i < this.archiveBuffer.length; i++) {
      if (this.archiveBuffer[i].id === alert.id) {
        return i
      }
    }
    return -1
  }

  addToArchiveBuffer(alert) {
    let index

    if (this._indexInArchiveBuffer(alert) === -1) {
      this.archiveBuffer.push(alert)
    } else {
      index = this._indexInArchiveBuffer(alert)

      this.archiveBuffer.splice(index, 1)
    }
  }

  archiveAlerts2(): void {
    void this.nextPage()
      .then(() => {
        if(this.viewArchived) {
          const unarchiveAlertIds: string[] = this.archiveBuffer.map((alert) => {
            alert.archived = false
            this.numArchived += 1  // @NOTE: Not sure what this does.  It seems like it should decrementing.

            return alert.id
          })

          return this.alertService.unarchiveAlerts(unarchiveAlertIds)
        } else {
          const archiveAlertIds: string [] = this.archiveBuffer.map((alert) => {
            alert.archived = true
            this.numArchived += 1

            return alert.id
          })

          return this.alertService.archiveAlerts(archiveAlertIds)
        }
      })
      .catch((err) => console.error('Unexpected Exception:', err))
      .finally(() => {
        this.selectAll = false
        this.$rootScope.$broadcast('forceToShowSpinnerEvent')

        // console.log(this.alerts.length)
        // this.$scope.$applyAsync(() => this.alerts = this.alerts.filter((alert) => this.archiveBuffer.find((ab) => ab.id !== alert.id)))
        // console.log(this.alerts.length)
        void this.$timeout(2000)
          .then(() => this.$onInit())
      })
  }

  archiveAlerts(): void {
    const allArchivedQs: any[] = []

    void this.nextPage()
    if (this.viewArchived) {
      this.archiveBuffer.forEach((alert) => {
        this.alertService.setArchiveForAlert(alert.id, false)
          .then(() => this.$rootScope.$applyAsync(() => this.$onInit()))
        alert.archived = false
        this.numArchived -= 1
      })
    } else {
      this.archiveBuffer.forEach((alert) => {
        allArchivedQs.push(this.alertService.setArchiveForAlert(alert.id, true))
        // this.waitAndUpdateAlerts(alert.activityCategory, 1)

        alert.archived = true
        this.numArchived += 1
      })
    }
    this.selectAll = false

    this.$q.all(allArchivedQs)
      .then(() => this.$onInit())

  }

  /**
   *
   */
  waitAndUpdateAlerts(toBeDeletedCount: number) {
    const expectedAlertCount: number = this.alertService.numAlerts - toBeDeletedCount

    return this.alertService.pollSummary(expectedAlertCount)
  }

  archiveNow(alert) {
    this.addToArchiveBuffer(alert)
    this.archiveAlerts()
  }

  markAsRead(alert) {

    alert.read = true
    this.$q.resolve(this.alertService.markRead(alert.id)).then(() => this.waitAndUpdateAlerts(1))
  }

  showAlert(alert) {
    let viewingUnarchived = !alert.archived && !this.viewArchived
    let viewingArchived = alert.archived && this.viewArchived

    return viewingUnarchived || viewingArchived
  }

  toggleArchived() {
    let tableView

    this.viewArchived = !this.viewArchived
    tableView = this.viewArchived ? 'archived' : 'inbox'

    this.emptyTableState = this.EMPTY_TABLE_STATES[tableView]
    this.$onInit()
  }

  download() {

    /**
     * TODO: this.activityCategory might not be working as designed. It always seems to be undefined.
     * Investigation need to determine if it is needed at all. For now `categoryTitle` has been added
     * to ensure there is a proper title on the filename.
     */
    const category = this.currentCategory ? this.currentCategory : (this.activityCategory ? this.activityCategory : '')
    const categoryTitle = category ? category : 'all alerts'

    const params: IAlertSearchParams = {
      pid: this.policyId,
      insured: this.clientName,
      orphaned: this.orphaned,
      startdate: this.dateUtils.yyyymmdd(this.startdate),
      enddate: this.dateUtils.yyyymmdd(this.enddate),
      activityCategory: category === 'All alert categories' ? '' : category,
      filename: this.stringUtils.capitalize(categoryTitle) + ' report ' + this.$filter('date')(new Date(), 'MMM dd yyyy') + '.csv',
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Download Alerts',
      })
    }

    console.log('params', params)
    this.downloadManager.getAllAlerts(category, params)
  }

  search() {
    this.loadingMore = false
    this.$onInit()
  }

  nextPage(): IPromise<any> {
    let sortInfo: ISortInfo
    // let params: any

    sortInfo = {
      sorted: this.orderByField !== '',
      category: this.orderByField,
      reverse: this.reverseSort,
    }

    console.debug('nextPage: moreData: %s, loadingMore: %s, currentPage: %s, reloadTableTrigger: %s, currentCategory: %s, client: %s, policyId: %s', this.moreData, this.loadingMore, this.currentPage, this.reloadTableTrigger, this.currentCategory, this.client, this.policyId)
    this.loadingMore = true

    if (this.moreData) {
      if (this.currentCategory) {
        return this.alertService.getAlertsByCategory(this.currentCategory, this.CONSTANTS.alertsView.all, this.currentPage++, sortInfo, this.viewArchived)
          .then((r) => this._processFilteredAlerts(r))
          .catch(err => console.error(err))
          .then(() => {
            this._setEmptyResultsState(this.EMPTY_RESULTS_STATES.forCategory)
          })
      } else if (this.client) {
        if (!this.authService.isAClient()) {
          return this.alertService.getAlertsByClientData(this.client, this.CONSTANTS.alertsView.all, this.currentPage++, sortInfo, this.viewArchived)
            .then((r) => this._processFilteredAlerts(r))
            .then(() => {
              this._setEmptyResultsState(this.EMPTY_RESULTS_STATES.forClientName)
            })
        }
      } else {
        sortInfo = {
          sorted: this.orderByField !== '',
          category: this.orderByField,
          reverse: this.reverseSort,
        }

        const pagingInfo: IPagingInfo = {
          page: this.currentPage++,
          size: 10,
        }

        const params: IAlertSearchParams = {
          pid: this.policyId,
          insured: this.clientName,
          orphaned: this.orphaned,
          startdate: this.dateUtils.yyyymmdd(this.startdate),
          enddate: this.dateUtils.yyyymmdd(this.enddate),
          activityCategory: this.activityCategory === 'All alert categories' ? '' : this.activityCategory,
          // page: this.currentPage++,
          // sorted: this.orderByField !== '',
          // category: this.orderByField,
          // reverse: this.reverseSort,
        }

        return this.alertService.searchAlerts(params, this.ignoreOrphanedFlag, sortInfo, this.viewArchived, pagingInfo)
          .then((r) => this._processFilteredAlerts(r))
          .then(() => {
            this._setEmptyResultsState(this.EMPTY_RESULTS_STATES.forCategoryforCategoryview)
          })
      }
    } else {
      this.loadingMore = false
      return this.$q.resolve()
    }
  }

  _setEmptyResultsState(emptyResultsState) {
    if (this.alerts.length === 0) {
      this.emptyResultsState = emptyResultsState
    }
  }

  _processFilteredAlerts(result) {
    // console.debug("_processFilteredAlerts|before: result: %o, moreData: %s, lastInitTime: %s", result, this.moreData, this.lastInitTime)


    if (result.status === 0) {
      this.connError = result.error
    } else if (result.error) {
      this.moreData = false
    } else if (result.status === 404) {
      this.moreData = false
    } else if (result.alertList.length === 0) {
      this.moreData = false
    } else {
      if (result.timeCalled < this.lastInitTime) {
        return
      }

      // If a filterResults function was available on $scope then use the
      // function to filter the search results.
      if (this.filterResults !== undefined) {
        result.alertList = this.filterResults(result.alertList)
      }

      this.alerts = this.alerts.concat(result.alertList).filter((alert) => this.viewArchived ? alert.archived : !alert.archived)
      this.numFound = result.numFound

      if (this.alerts.length >= this.numFound) {
        this.moreData = false
      }
    }

    this.$scope.error = result.error
    this.loadingMore = false

    if (this.moreData && (this.currentPage < 3)) {
      this.reloadTableTrigger++
    }

    // console.debug("_processFilteredAlerts|after: moreData: %s, filterResults: %o, alerts: %o, reloadTableTrigger: %s, currentPage: %s", this.moreData, this.filterResults, this.alerts, this.reloadTableTrigger, this.currentPage, result)
  }

}
