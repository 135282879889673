/**
 * Renders the match directive
 */

import ng from "angular"

export class DefaultValueDirective implements ng.IDirective {

  restrict = 'A'
  replace = false
  require = 'ngModel'
  scope = {
    defaultValue: '=',
  }

  // constructor() {}

  _validate(scope, modelValue) {
    return scope.defaultValue?.tc !== modelValue?.tc
  }

  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes, ctrl: any) {
    ctrl.$validators.defaultValue = (modelValue) => this._validate(scope, modelValue)
  }

  /**
   * Creates an instance of MatchDirective
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new DefaultValueDirective()

    directive.$inject = []

    return directive
  }
}
