import {GenericGatewayResponse, IIGWTransactionSummary } from "../../types"

class TransactionSummaryController {
  static $inject: string[] = ['$state', '$stateParams']

  trxSummary: IIGWTransactionSummary[]
  transaction: IIGWTransactionSummary
  summaryResponse: GenericGatewayResponse
  errorView: string

  constructor(private $state: any) { }

  viewTransactionDetails(summary: IIGWTransactionSummary): void {
    const headerInfo = Object.assign({}, this.transaction, summary)

    this.$state.go('policy.roles.transactions.details', { actionId: summary.actionId, clientId: summary.cid || 'na', headerInfo})
  }

  $onInit(): void {
    this.trxSummary = this.summaryResponse.data
    this.transaction = this.trxSummary[0]

    if(this.summaryResponse.error) {
      this.errorView = JSON.stringify(this.trxSummary, null, 2)
    }
  }
}

export const transactionSummaryComponentConfig = {
  templateUrl: 'app/transaction-history/components/summary/transaction-summary.html',
  controller: TransactionSummaryController,
  controllerAs: 'trxSummaryCtrl',
  bindings: {
    summaryResponse: '<',
  },
}
