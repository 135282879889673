import * as angular from 'angular'
import { AcordCountries } from './acord-countries'
import { CountryList } from './address'
import { BeneficiariesController } from './beneficiary-component'
import './beneficiary-constants'
import { BeneficiariesDataProviderService } from './beneficiary-data-provider'
import './beneficiary-routes'
import { BeneficiaryCategoryController, beneficiaryCategoryComponentConfig } from './category/beneficiary-category'
import './change-buttons/change-buttons-component'
import './classes'
import './constants'
import './edit-trigger/beneficiary-edit-trigger-component'
import { EditBeneficiaryDirective, EditBeneficiaryController } from './editors/edit-beneficiary-directive'
import './header'
import './relationships'
import { taxIdComponentConfig } from './tax-id/tax-id-component'
import { DEFAULT_PHONE, PHONE_TYPES } from './constants/PHONE_TYPES'
import { roleAddressComponent } from './address/address-component'
import { ValidateTextDirective } from './editors/validate-text-directive'
import { BeneficiaryRestateModal } from './restate/beneficiary-restate-modal.component'

angular.module('app.beneficiaries')
  .constant('PHONE_TYPES', PHONE_TYPES)
  .constant('DEFAULT_PHONE', DEFAULT_PHONE)
  .constant('acordCountries', AcordCountries)
  .component('beneficiariesComponent', {
    templateUrl: 'app/client/beneficiaries/main-view.html',
    controller: BeneficiariesController,
    controllerAs: 'ctrl',
    bindings: {
      policySummary: '=',
      isBeneficiaryManagmentAllowed: '<',
      canSuppressCorrespondence: '<',
      organizedClients: '=',
    },
  })
  .service('beneficiariesDataProvider', BeneficiariesDataProviderService)
  .component('taxId', taxIdComponentConfig)
  .component('beneficiaryCategory', beneficiaryCategoryComponentConfig)
  .controller('editBeneficiaryController', EditBeneficiaryController)
  .directive('editBeneficiary', EditBeneficiaryDirective.factory())
  .constant('countryList', CountryList)
  .component('roleAddress', roleAddressComponent)
  .directive('validateText', ValidateTextDirective.factory())
  .component('beneficiaryRestateModal', BeneficiaryRestateModal)

