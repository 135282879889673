import ng, { IAttributes, IFilterService, INgModelController, IScope } from "angular"

export class DecimalPlacesNumberInputDirective implements ng.IDirective {
  require = 'ngModel'
  restrict = 'A'

  constructor() { }

  link(_scope: IScope, elem: JQLite, attrs: IAttributes, ctrl: INgModelController): void {
    if (!ctrl) return

    const decimalPlaces = parseInt(attrs['decimalPlaces'], 10)

    function limitDecimalPlaces(value: string): string {
      if (value.includes('.')) {
        const parts = value.split('.')
        if (parts[1].length > decimalPlaces) {
          parts[1] = parts[1].substring(0, decimalPlaces)

          return parts.join('.')
        }
      }

      return value
    }

    function roundToDecimalPlaces(value: any): string {
      if (isNaN(value) || value === '') return value

      return parseFloat(value).toFixed(decimalPlaces)
    }

    ctrl.$parsers.push((value: string) => {
      if (value === undefined) value = ''
      const limitedValue = limitDecimalPlaces(value)
      const parsedValue = parseFloat(limitedValue)

      if (isNaN(parsedValue)) {
        return undefined
      } else {
        return parsedValue
      }
    })

    ctrl.$formatters.push((value: any) => {
      return roundToDecimalPlaces(value)
    })

    elem.on('input', () => {
      const value = elem.val()
      const limitedValue = limitDecimalPlaces(value)

      if (value !== limitedValue) {
        elem.val(limitedValue)
      }
    })

    elem.on('blur', () => {
      const formattedValue = roundToDecimalPlaces(ctrl.$modelValue)

      elem.val(formattedValue)
    })

    elem.on('focus', () => {
      elem.val(ctrl.$modelValue)
    })
  }

  static factory(): ng.IDirectiveFactory {
    const directive = () => new DecimalPlacesNumberInputDirective()

    directive.$inject = []

    return directive
  }
}
