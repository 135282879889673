
const POLICY_UI_VIEW_TARGETS = [
  'policy.funds',
  'policy.rebalance',
  'policy.transfer',
  'policy.changeBeneficiaries',
  'policy.roles',
  'myPolicy.roles',
  'myPolicy.rebalance',
  'myPolicy.transfer',
  'myPolicy.funds',
  'myPolicy.clientBeneEdit',
]

export { PolicySummaryController }

PolicySummaryController.$inject = [
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$filter',
  'summaryResult',
  'coveragesResult',
  'policyService',
  'policyUtils',
  'recentlyViewedService',
  'authService',
  'utils',
  'dateUtils',
  'clientUtils',
  'CONSTANTS',
  'correspondenceService',
  'alertService',
  'eventsService',
  'stateTransitions',
  'policyHelper',
  'policyApplicationResult',
  'policyTransactionsHistorySummaryResult',
  'myAgents',
  'paymentDetails',
  'policyCanPayResult',
  '$anchorScroll',
  'clientResults',
  'canAllocate',
  'loanPayoffQuote',
  'canTransferResult',
  'configService',
  'canRebalanceResult',
  'stringUtils',
  'fundTransferService',
  'notificationCenterService',
  'policyValuesResult',
  'epayMessageResolver',
  'PolicyRelativesViewManager',
  'clientMeService',
  'rolesPermissionService',
  'modalService',
  'riderBlacklists',
  'beneficiaryMetadata',
]

/* @ngInject */
function PolicySummaryController(
  $rootScope,
  $scope,
  $state,
  $stateParams,
  $filter,
  summaryResult,
  coveragesResult,
  policyService,
  policyUtils,
  recentlyViewedService,
  authService,
  utils,
  dateUtils,
  clientUtils,
  CONSTANTS,
  correspondenceService,
  alertService,
  eventsService,
  stateTransitions,
  policyHelper,
  policyApplicationResult,
  policyTransactionsHistorySummaryResult,
  myAgents,
  paymentDetails,
  policyCanPayResult,
  $anchorScroll,
  clientResults,
  canAllocate,
  loanPayoffQuote,
  canTransferResult,
  configService,
  canRebalanceResult,
  stringUtils,
  fundTransferService,
  notificationCenterService,
  policyValuesResult,
  epayMessageResolver,
  PolicyRelativesViewManager,
  clientMeService,
  rolesPermissionService,
  modalService,
  riderBlacklists, beneficiaryMetadata) {
  const self = this
  const PT_ORGANIZATION = 'Organization'
  const RIDERS_BENEFITS_MAX_ITEMS = 3
  const BENEFICIARIES_MAX_ITEMS = 3
  const restrictedTransferTypes = ['2', '4', '9']
  const clientRestrictedTransferTypes = ['2', '3', '4', '5', '6', '9', '10', '12', '13', '14']
  const restrictedRebalanceTypes = ['2', '4', '9']
  const clientRestrictedRebalanceTypes = ['2', '3', '4', '5', '6', '9', '10', '12', '13', '14', '15']
  const restrictedAllocationTypes = ['2', '4', '9']
  const clientRestrictedAllocationTypes = ['2', '3', '4', '5', '6', '9', '10', '12', '13', '14']
  self.$state = $state
  self.CHANNELS = CONSTANTS.notificationType.policy + ',' + CONSTANTS.notificationType.system + ',' + CONSTANTS.notificationType.systemUnavailability
  self.errorMessage = ''
  self.errorHeader = ''
  self.termDateLabel = ''
  self.unableToTransferMessage = ''
  self.unableToRebalanceMessage = ''
  self.unableToAllocateMessage = ''
  self.clientTypeDescription = ''
  self.notAvailableInfo = CONSTANTS.notAvailableInfo
  self.isThereError = false
  self.id = $stateParams.id
  self.summary = summaryResult.policy
  self.summaryResult = summaryResult
  self.paymentDetails = paymentDetails
  self.isPaymentWidgetActive = false
  self.isConversionWidgetActive = false
  self.pendingPolicyApplication = {}
  self.lastCorrespondence = undefined
  self.anniversaryEvent = false
  self.terminationEvent = false
  self.amountAlertsFound = 0
  self.isRequirementsMetVisible = false
  self.notAvailableInfo = CONSTANTS.notAvailableInfo
  self.myAgent = {}
  self.creditsMinimum = CONSTANTS.conversionCreditsMinimum
  self.showClientDetailPrintSummary = false
  self.showAdviserDetailPrintSummary = false
  self.showPolicyLapsedMessage = false
  self.showPolicyWillLapseMessage = false
  self.isRpsPolicy = false
  self.agentList = myAgents && myAgents.data.parties ? myAgents.data.parties : []
  self.organizedAgents = policyUtils.organizedAgents(self.agentList)
  self.puaDetails = {}
  self.sevenPayGuidelinePremiumValues = []
  self.currentDate = new Date()
  self.servicingAdviser = {
    title: '',
    organizationName: '',
    adviserName: '',
  }
  self.canPay = policyCanPayResult.data
  self.canTransferValue = canTransferResult.data
  self.isTransferTransactable = canTransferResult.data && canTransferResult.data.isTransactable
  self.canRebalance = canRebalanceResult.data && canRebalanceResult.data.isTransactable
  self.canAllocate = canAllocate.data && canAllocate.data.isTransactable
  self.loanPayoffQuote = loanPayoffQuote
  self.clients = clientResults.data
  self.policyValues = policyValuesResult && policyValuesResult.policyValues
  self.organizedClients = clientUtils.organizeClientDetails(self.clients, CONSTANTS.roleOrders)
  self.policyRelatives = new PolicyRelativesViewManager(self.organizedClients, self.summary.isAnnuity, self.summary?.assignmentCode?.tc === '2')
  self.isProductCodeFixedException = policyUtils.isProductCodeFixedException(self.summary.productCode)
  self.policyWillLapseDate = null
  self.policyHasLapsedDate = null
  self.policyHasDaysLeft = null
  self.canViewTaxDocsButton = false
  self.fpStatus = {}
  self.starMasterStatus = false

  /* Begin: Pending Policy Advisor Section */
  self.primaryRoleNames = []
  self.servicingRoleNames = []
  self.additionalRoleNames = []
  self.beneficiaries = []
  self.contacts = []
  self.assignees = []
  self.owners = []
  self.clientType = []
  self.primaryAgent = {}
  /* End: Pending Policy Advisor Section */
  /* Begin: Pending Policy Correspondence Section */
  self.specialDocIDs = {
    'applicationDocId': null,
    'illustrationDocId': null,
    'policyPagesDocId': null,
  }

  self.canPayTypes = {
    premium: Boolean(utils.getData(self.canPay.paymentOptions.filter(p => p.paymentTypeCode === CONSTANTS.PAYMENT_TYPES.PREMIUM)[0], 'isPayable')),
    initialPayment: Boolean(utils.getData(self.canPay.paymentOptions.filter(p => p.paymentTypeCode === CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT)[0], 'isPayable')),
    pua: Boolean(utils.getData(self.canPay.paymentOptions.filter(p => p.paymentTypeCode === CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA)[0], 'isPayable')),
    loan: Boolean(utils.getData(self.canPay.paymentOptions.filter(p => p.paymentTypeCode === CONSTANTS.PAYMENT_TYPES.LOAN)[0], 'isPayable')),
  }

  self.strategicAlliance = policyUtils.policyStrategicAlliance(self.summary, self.agentList)
  /* End: Pending Policy Correspondence Section */

  self.hasConversionDate = Boolean(self.summary.conversionDate)

  self.transactionHistory = policyUtils.transactionHistory(policyTransactionsHistorySummaryResult)

  self.riderBlacklists = riderBlacklists
  self.beneficiaryMetadata = beneficiaryMetadata

  self.helper = policyHelper
  self.helper.setPolicy(self.summary)

  self.checkBeneSelfServePermissions = function () {
    const result = rolesPermissionService.clientCanManageBeneficiaries(self.summary, self.organizedClients, self.beneficiaryMetadata)

    modalService.open('app/policy/policy-self-serve-bene-reasons.html', {
      modalHeader: 'Ineligible Reasons',
      reasons: result.ineligibleReasons
    })
  }

  self.showBeneSelfServeCheckButton = function () {
    return configService.features.beneChangeSelfServeEligibilityButton
  }

  self.applicationType = function () {
    if (self.summary.aceIndicator) {
      return 'Electronic Application'
    }

    return 'Paper Application'
  }

  self.filingNameTitle = function () {
    if (self.summary.lineOfBusiness === 'Life') {
      return 'Insurance Policy'
    }
    return 'Annuity Contract'
  }

  self.filingNamePhrase = function () {
    const name = self.summary.filingName
    let ret = 'A'

    if ('AEIOU'.indexOf(name.substring(0, 1)) >= 0 && name.substring(0, 3) !== 'Uni') {
      ret += 'n'
    }

    ret += ' ' + name + ' '
    ret += (self.summary.lineOfBusiness === 'Life') ? 'policy' : 'contract'

    return ret
  }

  self.showFutureAllocations = function (productType) {
    const blockedByCanAllocateTypeCode = Boolean(
      canAllocate && canAllocate.data && canAllocate.data.reason &&
      ['2', '4', '5', '9', '11', '14'].includes(canAllocate.data.reason.tc)
    )

    return !blockedByCanAllocateTypeCode
  }

  self.showQualPlanType = function (planType, code) {
    return policyUtils.showQualPlanType(planType, code)
  }

  self.getPolicyValueSectionTitle = function () {
    if (self.summary.isPending) {
      return 'Policy Overview'
    } else if (self.summary.isAnnuity) {
      return 'Contract Values'
    }
    return 'Policy Values'
  }

  self.getPolicyValueGeneralLabelTitle = function () {
    if (self.summary.isAnnuity) {
      return 'Contract Value'
    }
    return 'Policy Value'
  }

  self.getPolicyValueBoxTitle = function () {
    if (self.summary.isULProductType) {
      return 'Policy Value'
    }
    return 'Guaranteed Cash Value'
  }

  self.getPaymentSectionTitle = function () {
    if (self.summary.isAnnuity) {
      return 'Purchase Payments'
    }

    return 'Premium and Billing'
  }

  self._getPaymentMode = function () {
    const paymentModeLabel = self.summary.paymentMode
    const showScheduled = self.summary.isULProductType && !self.isGracePeriod()

    if (self.summary.isULProductType && self.isGracePeriod()) {
      return 'Required Payment'
    } else if (showScheduled) {
      return 'Scheduled ' + paymentModeLabel + ' Payment'
    }

    if (self.isTermPolicy() && self.isWholeLife() && paymentModeLabel && paymentModeLabel.trim().toLowerCase() === CONSTANTS.paymentsMode.annual) {
      return 'Annual Modal Premium'
    }

    return paymentModeLabel + ' Payment'
  }

  self.isPastDueDate = function (dueDate) {
    return dateUtils.isPastDueDate(dueDate)
  }

  self.isAgentTerminated = function () {
    if (self.myAgent && self.myAgent.producer && self.myAgent.producer.status) {
      return self.myAgent.producer.status === 'Terminated'
    }
    return false
  }

  self.isAgentCAS = function () {
    if (self.myAgent && self.myAgent.producer && self.myAgent.producer.distributionChannelName) {
      return self.myAgent.producer.distributionChannelName === 'CAS'
    }
    return false
  }

  self.isHomeOfficeUser = function () {
    return authService.isInRole('HOMEOFFICE')
  }

  self.showCantPayReason = function () {
    const premiumReason = self._getPremiumPaymentReason()

    // return (!self.canPay.canMakePayment && premiumReason && self.helper.tradProduct() && policyUtils.isInforcePolicy(self.summary)) &&
    //   ([CONSTANTS.notPayableReasons.billIntercepted.typeCode, '15'].includes(premiumReason.reason.tc))
    return (!self.canPay.canMakePayment && Boolean(premiumReason) && !self.helper.tradProduct() && policyUtils.isInforcePolicy(self.summary))
  }

  self.getCantPayReason = function () {
    return self._getPremiumPaymentReason()
  }

  self.getLoanPaymentReason = function () {
    return self._getLoanPaymentReason()
  }

  self._getPremiumPaymentReason = function () {
    return epayMessageResolver.resolvePremiumMessage(self.canPay.paymentOptions
      .find(option => option.paymentTypeCode === '1')?.reason?.tc)
  }

  self._getLoanPaymentReason = function () {
    return epayMessageResolver.resolveLoanMessage(self.canPay.paymentOptions
      .find(option => option.paymentTypeCode === '2')?.reason?.tc)
  }

  self.getPUAPaymentReason = function () {
    return self._getPUAPaymentReason()
  }

  self._getPUAPaymentReason = function () {
    return epayMessageResolver.resolvePuaMessage(self.canPay.paymentOptions
      .find(option => option.paymentTypeCode === CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA)?.reason?.tc)
  }

  self.showCantLoanPayReason = function () {
    const loanPaymentReason = self._getLoanPaymentReason()

    return Boolean(loanPaymentReason)
  }

  self.capitalizeString = function (value) {
    return utils.capitalizeString(value)
  }

  self.printPolicy = function (data, windowTitle) {
    return utils.printPopupWindow(data, windowTitle)
  }

  /* TODO - MP @next person in here delete this method.  Does not appear to be used anywhere in the app */
  self.getFirst3Beneficiaries = function (beneficiaries) {
    const first3Beneficiaries = []
    let i

    if (!angular.isArray(beneficiaries)) {
      return first3Beneficiaries
    }

    for (i = 0; (i < beneficiaries.length && i < BENEFICIARIES_MAX_ITEMS); i++) {
      first3Beneficiaries.push(beneficiaries[i])
    }

    return first3Beneficiaries
  }

  self.getBeneficiariesMoreCount = function (beneficiaries) {
    const value = angular.isArray(beneficiaries) ? beneficiaries.length - BENEFICIARIES_MAX_ITEMS : 0

    return value > 0 ? value : 0
  }

  self._getPaymentAmount = function () {
    let paymentAmount

    if (self.paymentDetails.payments && self.canPay.canMakePayment) {
      const paymentResult = self.paymentDetails.payments.find(payment => payment.type === 'Premium Payment')

      paymentAmount = paymentResult ? paymentResult.amount : self.summary.paymentAmt
    } else {
      paymentAmount = self.summary.paymentAmt
    }

    return paymentAmount
  }

  self.getPrimaryBeneficiaries = function () {
    let primaryBeneficiaries = []

    if (angular.isArray(self.summary.beneficiaries)) {
      primaryBeneficiaries = primaryBeneficiaries.concat(self.summary.beneficiaries)
    }

    primaryBeneficiaries.forEach(function (beneficiary) {
      beneficiary.role = 'Primary'
    })

    return primaryBeneficiaries
  }

  self.getContingentBeneficiaries = function () {
    let contingentBeneficiaries = []

    if (angular.isArray(self.summary.contingentBeneficiaries)) {
      self.summary.contingentBeneficiaries.forEach(function (beneficiary) {
        beneficiary.role = '1st Contingent'
      })
      contingentBeneficiaries = contingentBeneficiaries.concat(self.summary.contingentBeneficiaries)
    }

    if (angular.isArray(self.summary.secondContigentBeneficiaries)) {
      self.summary.secondContigentBeneficiaries.forEach(function (beneficiary) {
        beneficiary.role = '2nd Contingent'
      })
      contingentBeneficiaries = contingentBeneficiaries.concat(self.summary.secondContigentBeneficiaries)
    }

    if (angular.isArray(self.summary.finalBeneficiaries)) {
      self.summary.finalBeneficiaries.forEach(function (beneficiary) {
        beneficiary.role = 'Final'
      })
      contingentBeneficiaries = contingentBeneficiaries.concat(self.summary.finalBeneficiaries)
    }

    return contingentBeneficiaries
  }

  self.getAllBeneficiaries = function () {
    return self.getPrimaryBeneficiaries().concat(self.getContingentBeneficiaries())
  }

  self.getRiders = function () {
    const riders = self.coverages.filter(coverage => (coverage.activeRiderInd === true))

    return riders
  }

  self.getRole = function (client) {
    return policyUtils.getRole(client)
  }

  self._getRidersAndBenefitsMoreCount = function (coverages, riders) {
    const remainingCount = angular.isArray(coverages) ? coverages.length - riders.length : 0

    return remainingCount > 0 ? remainingCount : 0
  }

  self.getRidersAndBenefitsMoreCount = function () {
    const value = angular.isArray(self.summary.coverageList.coverages) ? self.summary.coverageList.coverages.length - RIDERS_BENEFITS_MAX_ITEMS : 0

    return value > 0 ? value : 0
  }

  self.isLifePolicy = function (lineOfBusiness) {  // MP whats this arg used for?
    return policyUtils.isLifePolicy(self.summary.lineOfBusiness)
  }

  self.isTermPolicy = function () {
    return self.summary.productType === CONSTANTS.productType.term || self.summary.productType === CONSTANTS.productType.indeterminatePremiumLifeTerm
  }

  self.isTermOrWLPolicy = function () {
    return self.summary.productType === CONSTANTS.productType.term ||
      self.summary.productType === CONSTANTS.productType.indeterminatePremiumLifeTerm ||
      self.summary.productType === CONSTANTS.productType.wholeLife
  }

  self.canMakePremiumPayment = function () {
    return self.canPay && self.canPay.paymentOptions && self.canPay.paymentOptions.some(function (option) {
      return option.isPayable && option.paymentTypeCode === '1'
    })
  }

  self.currentPaymentProcessing = function () {
    return self.canPay && self.canPay.paymentOptions && self.canPay.paymentOptions.some(function (option) {
      return !option.isPayable && option.paymentTypeCode === '1' && option.reason.tc === CONSTANTS.notPayableReasons.sameTypeProcessed.typeCode
    })
  }

  self.isInsideBillingPeriod = function () {
    return self.canPay.state && self.canPay.state.tc === CONSTANTS.policyPaymentPeriods.inside.typeCode
  }

  self.isOutsideBillingPeriod = function () {
    return self.canPay.state && self.canPay.state.tc === CONSTANTS.policyPaymentPeriods.outside.typeCode
  }

  self.isGracePeriod = function () {
    return self.canPay.state && self.canPay.state.tc === CONSTANTS.policyPaymentPeriods.grace.typeCode
  }

  self.isPossibleGracePeriod = function () {
    return self.canPay.state && self.canPay.state.tc === CONSTANTS.policyPaymentPeriods.possibleGrace.typeCode
  }

  self.isMercyPeriod = function () {
    return self.canPay.state && self.canPay.state.tc === CONSTANTS.policyPaymentPeriods.mercy.typeCode
  }

  self._isPolicyLapsed = function () {
    return self.summary.policyStatus === 'Lapsed'
  }

  self.isWholeLife = function () {
    return self.summary.productType === CONSTANTS.productType.wholeLife
  }

  self.willLapseToNone = function () {
    return self.summary.productType === CONSTANTS.productType.wholeLife &&
      self.canPay.nonForfeitureProvision.tc === CONSTANTS.wholeLifeLapseOutcomes.none.typeCode
  }

  self.willLapseToETI = function () {
    return self.summary.productType === CONSTANTS.productType.wholeLife &&
      self.canPay.nonForfeitureProvision.tc === CONSTANTS.wholeLifeLapseOutcomes.extendedTermInsuarance.typeCode
  }

  self.willLapseToRPU = function () {
    return self.summary.productType === CONSTANTS.productType.wholeLife &&
      self.canPay.nonForfeitureProvision.tc === CONSTANTS.wholeLifeLapseOutcomes.reducedPaidUp.typeCode
  }

  self.willLapseToAPL = function () {
    return self.summary.productType === CONSTANTS.productType.wholeLife &&
      (self.canPay.nonForfeitureProvision.tc === CONSTANTS.wholeLifeLapseOutcomes.automaticPolicyLoan.typeCode ||
        self.canPay.nonForfeitureProvision.tc === CONSTANTS.wholeLifeLapseOutcomes.automaticPolicyLoanExtendedTerm.typeCode ||
        self.canPay.nonForfeitureProvision.tc === CONSTANTS.wholeLifeLapseOutcomes.automaticPolicyLoanReducedPaidUp.typeCode)
  }

  // @deprecated return policyUtils.isUniversalLifePolicy(self.summary.productType)
  self.isUniversalLifePolicy = function () {
    return self.summary.productType === CONSTANTS.productType.universalLife ||
      self.summary.productType === CONSTANTS.productType.IUL ||
      self.summary.productType === CONSTANTS.productType.variableUniversalLife
  }

  // TODO:
  // @deprecate self.isTermLife ? Looks like self.isTermPolicy is more comprehensive
  self.isTermLife = function () {
    return self.summary.productType === CONSTANTS.productType.term
  }

  self.isVariableUniversalLife = function () {
    return self.summary.productType === CONSTANTS.productType.variableUniversalLife
  }

  self.isIndexedUniversalLife = function () {
    return self.summary.productType === CONSTANTS.productType.IUL
  }

  self.showBeneficiaryTile = () => {
    return configService.serverMode !== 'client' ? $state.current.name !== 'policy.changeBeneficiaries' : !$state.current.name.startsWith('myPolicy.clientBeneEdit')
  }

  self.isIndexedAnnuity = function () {
    return self.summary.productType === CONSTANTS.productType.indexedAnnuity
  }

  self.isFixedAnnuity = function () {
    return self.summary.productType === CONSTANTS.productType.fixedAnnuity
  }

  self.isVariableAnnuity = function () {
    return self.summary.productType === CONSTANTS.productType.variableAnnuity
  }

  self.isAnnuity = function () {
    return self.summary.productType === CONSTANTS.productType.indexedAnnuity ||
      self.summary.productType === CONSTANTS.productType.fixedAnnuity ||
      self.summary.productType === CONSTANTS.productType.variableAnnuity
  }

  self.determineInsuredOrAnnuitant = function () {
    let clientType = []

    if (self.isAnnuity()) {
      clientType = self.organizedClients.annuitants
    } else {
      clientType = self.organizedClients.insureds
    }

    return clientType
  }

  self._clientTypeDescription = function () {
    return self.isAnnuity() ? 'Annuitant' : 'Insured'
  }

  self.showCostBasisValues = function () {
    return (self.isIndexedAnnuity() || self.isVariableAnnuity()) &&
      self.summary.qualifiedCode.toUpperCase() === CONSTANTS.annuityTaxStatuses.NON_QUALIFIED
  }

  self.hasAccount = function () {
    return !self.isTermPolicy() && !self.isWholeLife()
  }

  self.hideAnnual = function () {
    return self.summary.paymentMode.toLowerCase() === CONSTANTS.paymentsMode.annual
  }

  self.hidePolicyValueLink = function () {
    return self.summary.isPending || (self.summary.isAnnuityFixedProductType && !self.isProductCodeFixedException)
  }

  self.hideBeneficiariesLink = function () {
    return !self.summary.beneficiaries
  }

  self.hideTransactionHistoryLink = function () {
    return false
  }

  self.hidePaymentsLink = function () {
    return false
  }

  self.hideAdvisorLink = function () {
    return authService.isClientView()
  }

  self.hideCoverageLink = function() {
    // const hiddenRiderCodes = [
    //   'ODB8',
    //   'GMD3',
    //   'EED6',
    //   'ODB9',
    //   'GMDA',
    //   'GMD4',
    //   'GMDB',
    // ]

    // const hiddenPolicyStatuses = [
    //   'annuitized',
    //   'death claim',
    //   'not taken',
    //   'surrendered',
    //   'matured',
    // ]

    const hiddenRiderCodes = self.riderBlacklists.hiddenRiderCodes
    const hiddenPolicyStatuses = self.riderBlacklists.hiddenPolicyStatuses
    const hideByRiderCode = self.hasValidRider() && (self.rider.riderCode && hiddenRiderCodes.indexOf(self.rider.riderCode) > -1)
    const hideByPolicyStatus = hiddenPolicyStatuses.indexOf(self.summary.policyStatus.toLowerCase()) > -1

    return !self.hasValidRider() || hideByRiderCode || hideByPolicyStatus || self.isTermExpired()
  }

  self.hasValidRider = function () {
    return self.rider && Object.keys(self.rider).length > 0
  }

  self.isPennCheck = function () {
    return self.summary.paymentMethod === 'Electronic Funds Transfer'
  }

  self.isTermExpired = function () {
    let d

    if (self.rider && self.rider.termDate) {
      d = new Date()

      return d > new Date(self.rider.termDate)
    }
    return false
  }

  self.summarySectionTitle = function (isAnnuity) {
    return isAnnuity ? 'Contract Summary' : 'Policy Information'
  }

  self.setTransactionResult = function (section) {
    if (section === 'transfer') {
      return canTransferResult
    } else if (section === 'rebalance') {
      return canRebalanceResult
    }

    return canAllocate
  }

  self.setRestrictedTransactionList = function (section) {
    const isClientOrAgentClientView = (!authService.isARealClient() && authService.isClientView()) || authService.isARealClient()

    if (section === 'transfer') {
      return isClientOrAgentClientView ? clientRestrictedTransferTypes : restrictedTransferTypes
    } else if (section === 'rebalance') {
      return isClientOrAgentClientView ? clientRestrictedRebalanceTypes : restrictedRebalanceTypes
    }

    return isClientOrAgentClientView ? clientRestrictedAllocationTypes : restrictedAllocationTypes
  }

  self.showTransactionsSection = function (section) {
    const result = self.setTransactionResult(section)
    const restrictedList = self.setRestrictedTransactionList(section)

    if (section === 'transfer') {
      return (result.data.reason && !restrictedList.includes(result.data.reason.tc)) || self.isTransferTransactable
    } else if (section === 'rebalance') {
      return (result.data.reason && !restrictedList.includes(result.data.reason.tc)) || self.canRebalance
    }

    return (result.data.reason && !restrictedList.includes(result.data.reason.tc)) || self.canAllocate
  }

  self._termDateLabel = function () {
    if ((self.isWholeLife() && self.summary.policyStatus === 'Extended term') && (!self.isWholeLife() || !self.isUniversalLifePolicy())) {
      return 'Termination Date'
    }

    return 'Maturity Date'
  }

  self._isRpsPolicy = function () {
    return self.summary.source === CONSTANTS.policySource.rps
  }

  function checkUserAndGotoStateFnct(state, params) {
    params = params || {}
    return function () {
      if (authService.isAClient()) {
        stateTransitions.go('myPolicy.' + state, params)
      } else {
        stateTransitions.go('policy.' + state, params)
      }
    }
  }

  self.gotoCoverage = checkUserAndGotoStateFnct(self.summary.isPending ? 'benefits' : 'coverages')
  self.gotoBeneficiaries = checkUserAndGotoStateFnct('clientModal', {
    role: 'Beneficiary',
  })
  self.gotoAdvisor = checkUserAndGotoStateFnct('financialPros')
  self.gotoCorrespondence = checkUserAndGotoStateFnct('correspondence')
  self.gotoInformation = checkUserAndGotoStateFnct('information')
  self.gotoValues = checkUserAndGotoStateFnct('values')
  self.gotoRiders = checkUserAndGotoStateFnct('riders')
  self.gotoPayments = self.summary.isAnnuity ? checkUserAndGotoStateFnct('payments') : checkUserAndGotoStateFnct('premiumAndBilling')
  self.gotoTransactionsHistory = checkUserAndGotoStateFnct('transactions')
  self.goToPrintDetailedClientSummary = checkUserAndGotoStateFnct('policyPrint', { printView: 'detailed-client-summary' })
  self.goToPrintDetailedAdviserSummary = checkUserAndGotoStateFnct('policyPrint', { printView: 'detailed-adviser-summary' })
  self.goToPrintDefaultSummary = checkUserAndGotoStateFnct('policyPrint', { printView: 'default-summary' })

  self.hideCorrespondenceLink = function () {
    return self.summary.isPending || self.lastCorrespondence === undefined
  }

  self.showMoneySymbol = function (value) {
    return !utils.isNotAvailable(value)
  }

  self.correspondenceSectionTitle = function () {
    return self.summary.isPending ? 'New Business Documents' : 'Correspondence'
  }

  function initUnableToTransactMessages() {
    const transactionTypes = [
      { key: 'unableToAllocateMessage', section: 'allocate', data: canAllocate.data },
      { key: 'unableToRebalanceMessage', section: 'rebalance', data: canRebalanceResult.data },
      { key: 'unableToTransferMessage', section: 'transfer', data: canTransferResult.data },
    ]

    transactionTypes.forEach((transType) => {
      self[transType.key] = fundTransferService.resolveUnableToTransactMessage(transType.data, transType.section, self.summary)
    })
  }

  function initClients() {
    const byRoles = policyService.organizeClientsByRole(self.clients)

    self.summary.clients.owners = byRoles.owners

    if (byRoles.jointOwners && byRoles.jointOwners.length) {
      byRoles.jointOwners.forEach(function (client) {
        self.summary.clients.owners.push(client)
      })
    }

    self.summary.clients.insureds = byRoles.insureds ? byRoles.insureds : byRoles.annuitants
    self.summary.clients.payors = byRoles.payors

    self._processEventNotifications()
  }

  function initCorrespondence() {
    const pids = new Array(self.id)

    return correspondenceService.getCorrespondences(pids)
      .then(function (result) {
        self.correspondenceDocuments = result.documents
        if (!result.error && result.documents.length > 0) {
          correspondenceService.fillSpecialDocIDs(result.documents, self.specialDocIDs)

          self.lastCorrespondence = result.documents[0]

          if (self.lastCorrespondence && self.lastCorrespondence.modDate) {
            self.lastCorrespondence.modDate = dateUtils.parseDate(self.lastCorrespondence.modDate)
          }
        } else {
          self.lastCorrespondence = undefined
        }
      })
  }

  function initArrangement() {
    policyService.getPolicyArrangements(self.id).then(
      function (arrangements) {
        if (arrangements.data && arrangements.data.arrangement && arrangements.data.arrangement.length > 0) {
          self.summary.arrangement = arrangements.data.newArrangement

          if (!self.summary.arrangement.nextSystematicWithdrawalDate) {
            const specificFundsWithdrawal = arrangements.data.arrangement.filter(
              arrangement => arrangement.arrType === 'Specific Funds Withdrawal')[0]

            if (specificFundsWithdrawal) {
              self.summary.arrangement.nextSystematicWithdrawalDate = specificFundsWithdrawal.nextActivityDate
              self.summary.arrangement.systematicWithdrawal = 'Yes'
            }
          }
        }
      })
  }

  self.openWelis = function (pid) {
    if (pid) {
      if (dataLayer) {
        dataLayer.push({
          'event': 'Link to Wells',
        })
      }

      const url = CONSTANTS.welisURL.replace('{pid}', pid).replace('{sid}', authService.getSessionID())
      const target = window.device ? '_system' : '_blank'

      window.open(url, target)
    }
  }

  self.showConversionCreditsLink = function () {
    const acceptablePolicyStatusList = CONSTANTS.conversionCreditPolicyStatuses.map((policyStatus) => utils.firstToUpper(policyStatus))

    return authService.isAgentView() &&
      self.isTermPolicy() &&
      acceptablePolicyStatusList.includes(self.summary.policyStatus) &&
      self.summary.faceAmt >= self.creditsMinimum &&
      new Date(self.summary.conversionDate) > new Date()
  }

  self.allowPrintPolicy = () => (self.isTermPolicy() || self.isUniversalLifePolicy() || self.isWholeLife() || self.isAnnuity()) && !self.summary.isPending

  self.allowPrintToggle = () => (self.isUniversalLifePolicy() || self.isWholeLife() || self.isAnnuity() || self.isTermPolicy()) && self.isAgentView && !self.summary.isPending

  self.showCorrespondence = function (docId) {
    if (docId) {
      const url = CONSTANTS.correspondencesDownloadURL.replace('{0}', docId).replace('{1}', authService.getSessionID())

      window.open(url, '_system')
    }
  }

  self.showLastCorrespondence = function () {
    const url = CONSTANTS.correspondencesDownloadURL.replace('{0}', self.lastCorrespondence.docId).replace('{1}', authService.getSessionID())

    window.open(url, '_system')
  }

  self.showPolicyInfoLink = () => self.isAgentView() && !self.summary.isAnnuity && !self.summary.isPending && !self.isTermPolicy()

  self.getTermPeriodLabel = function (termPeriod) {
    if (termPeriod) {
      const str = termPeriod + ' year'

      return termPeriod > 1 ? str + 's' : str
    }

    return ''
  }

  self.getPolicyPaymentPeriodStr = function () {
    let str, premDurationDate

    if (self.summary.premDurationDate === undefined ||
      !dateUtils.parseDate(self.summary.premDurationDate) ||
      self.summary.payToAge === undefined ||
      !angular.isNumber(self.summary.payToAge)) {
      return undefined
    }

    str = '{0} (Age {1})'
    premDurationDate = $filter('date')(Date.parse(self.summary.premDurationDate), 'mediumDate')

    return str.replace('{0}', premDurationDate).replace('{1}', self.summary.payToAge)
  }

  self._isConvertibleProductCode = function (productCode) {
    const acceptedTermCodes = ['07', '09', '13', '14', '17']

    return productCode.indexOf('TERM') === 0 &&
      acceptedTermCodes.indexOf(productCode.substr(4, 2)) !== -1
  }

  self._processEventNotifications = function () {
    if (self.summary.clients.owners && self.summary.clients.owners.length > 0) {
      const fullName = clientUtils.partyName(self.summary.owners[0])

      if (self.summary.holdingStatus !== 'Pending') {
        self.anniversaryEvent = eventsService.processPolicyAnniversary(fullName, self.summary.anniversaryDate)
      }
    }

    if (self.summary.policyStatus === 'Lapsed' && $state.current.clientAppOnly) { // only display if in a client only route
      eventsService.showLapsedNotification(self.summary.policyStatus)
    }
  }

  /* function prefetchData() {
     if (!self.summary.isPending && !((self.isTermPolicy() || self.isWholeLife()) && !self.summary.isAnnuity)) {
       // policyService.getPolicyBase(self.id) // is this really needed? it is already done by resolve
       policyService.getPolicyLoans(self.id)
       policyService.getPolicyFunds(self.id)
     }
   }

   $scope.$on('$viewContentLoaded', function() {
     prefetchData()
   }) */

  self.showAlerts = function () {
    stateTransitions.go('policy.alerts', {})
  }

  self.showRequirements = function () {
    stateTransitions.go('policy.requirements', {})
  }

  function initPendingPolicyApplication() {
    self.pendingPolicyApplication = policyApplicationResult.policyApplication
  }

  function setAgentsNamesByRole(list) { // TODO: This can be put in policy-utils.  The print controller is using the same functionality.
    if (list) {
      list.forEach(function (agent, key) {
        agent.roles.forEach(function (item) {
          if (item.role) {
            if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.primary) {
              self.primaryRoleNames.push(agent.fullName)
            } else if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.servicing) {
              self.servicingRoleNames.push(agent.fullName)
            } else if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.additional) {
              self.additionalRoleNames.push(agent.fullName)
            }
          }
        })
      })
    }
  }

  function initPolicyAgents() { // TODO: This can be put in policy-utils.  The print controller is using the same functionality.
    setAgentsNamesByRole(self.agentList)
    if (!self.summary.isPending) {
      self.primaryAgent = policyUtils.filterPrimaryAgent(self.agentList)
    }
  }

  function initRidersAndBenefits() {
    if (coveragesResult && coveragesResult.data && coveragesResult.data.rider) {
      self.rider = coveragesResult.data.rider || {}
      self.coverages = policyUtils.coveragesList(coveragesResult.data.coverage || [])

      self.riders = utils.dedupeByPropertyName(self.getRiders(), 'marketingName')
      self.first3Riders = self.riders.slice(undefined, RIDERS_BENEFITS_MAX_ITEMS)
      self.ridersAndBenefitsMoreCount = Math.max(0, self.riders.length - self.first3Riders.length)
    }
  }

  function setRequirementsMetVisible() {
    const reqExist = self.summary.requirementInfo !== undefined
    const reqNotZero = reqExist && self.summary.requirementInfo.label !== '0/0'

    self.isRequirementsMetVisible = reqExist && reqNotZero
  }

  function initRequirementsMet() {
    if (authService.isAClient()) {
      return
    }

    self.summary.requirementInfo = policyUtils.calcRequirementsMet(self.summary.requirementsStatus)
    setRequirementsMetVisible()
  }

  function initAlerts() {
    if (authService.isAClient()) {
      return
    }
    alertService.getAlertsByPolicy(self.summary.polNumber, CONSTANTS.alertsView.all).then(
      function (result) {
        if (!result.error) {
          self.amountAlertsFound = result.numFound
        }
      })
  }

  self.showReqAndAlertsBar = function () {
    const stateIsGood = $rootScope.state !== 'alert-by-policy-detail' &&
      $rootScope.state !== 'policy-alerts' &&
      $rootScope.state !== 'policy-requirements'
    const isIllustratable = self.summary.illustratable
    const hasNumberToDisplay = self.isRequirementsMetVisible ||
      self.amountAlertsFound > 0

    return (hasNumberToDisplay || isIllustratable) &&
      stateIsGood
  }

  self.isAgentView = function () {
    return !authService.isAClient_Buffered()
  }

  self.existsFootnote = function () {
    return self.summary.mecInd ||
      !(self.isTermPolicy() || !self.summary.loanBalance) ||
      self.isWholeLife() ||
      (self.summary.isAnnuity && self.summary.qualifiedCodeDisplay) ||
      (self.summary.isAnnuity && self.summary.qualifiedCode && self.summary.qualifiedCode.toLowerCase() !== 'non-qualified')
  }

  self.closePaymentWidget = function () {
    self.isPaymentWidgetActive = false
    $anchorScroll()

    // reload the current state after exiting the client-payment widget
    $state.go($state.current, {}, { reload: true }) // second parameter is for $stateParams
  }

  self.closeConversionWidget = function () {
    self.isConversionWidgetActive = false
  }

  self.openPaymentWidget = function () {
    self.isPaymentWidgetActive = true
  }

  self.openConversionWidget = function () {
    self.isConversionWidgetActive = true

    window.dataLayer.push({
      policyId: self.summary.polNumber,
      action: 'START',
      event: 'CONVERSION_CREDIT',
      faceValue: self.summary.faceAmt,
      jurisdiction: self.summary.jurisdiction,
      policyAge: self.summary.yearsInForce,
    })
  }

  self.getFinEffDateAsDate = function (record) {
    return new Date(record.finEffDate)
  }

  self.isIE = function () {
    return utils.isIE()
  }

  self.isPhone = function () {
    return utils.isPhone()
  }

  self.isMobileSafari = function () {
    return utils.isMobileSafari()
  }

  self.returnSortedList = function (sortedList, item) {
    const list = sortedList

    return list[item] || Object.keys(sortedList).length + 1
  }

  self.viewAndChangeFunds = function () {
    window.dataLayer.push({
      event: 'FUTURE ALLOCATION',
      action: 'view-change',
      policyId: $state.params.id,
    })

    if (authService.isARealClient() || (!authService.isARealClient() && authService.isClientView())) {
      $state.go('myPolicy.funds')
    } else {
      $state.go('policy.funds')
    }
  }

  self.viewTransferFunds = function () {
    if (authService.isARealClient() || (!authService.isARealClient() && authService.isClientView())) {
      $state.go('myPolicy.transfer')
    } else {
      $state.go('policy.transfer')
    }
  }

  self.viewRebalanceFunds = function () {
    if (authService.isARealClient() || (!authService.isARealClient() && authService.isClientView())) {
      $state.go('myPolicy.rebalance')
    } else {
      $state.go('policy.rebalance')
    }
  }

  self.showTransfers = function () {
    return (self.isVariableUniversalLife() || self.isVariableAnnuity()) && self.showTransactionsSection('transfer')
  }

  if (self.canPay && self.canPay.state) {
    self.policyWillLapseDate = Date.parse(self.canPay.state.gracePeriodContractualEndDate)
    self.policyHasLapsedDate = Date.parse(self.canPay.state.gracePeriodEndDate)
    self.policyHasDaysLeft = (self.canPay.state.value.toUpperCase() === 'GRACE')
  }

  self.showLoanPayoff = function () {
    // console.log("showLoanPayoff", !self.isUniversalLifePolicy(), self.canPayTypes.loan)
    return self.canPayTypes.loan && !configService.features.disableEpay
  }

  self.showPurchasePUA = function () {
    return !self.isUniversalLifePolicy() && self.canPayTypes.pua && !configService.features.disableEpay
  }

  self.showPuaLimitReached = () => {
    return !self.isUniversalLifePolicy() && self.getPUAPaymentReason()?.tc === 18
  }

  self._showLoanOrPUAButtons = function () {
    const showIt = !self.canPayTypes.premium && ((!authService.isARealClient() && authService.isClientView()) || authService.isARealClient())

    return showIt && !configService.features.disableEpay
  }

  function _resolveServicingAdviserDisplayData() {
    // Setup legacy data
    self.servicingAdviser = {
      title: self.myAgent.fullName,
      organizationName: self.isAgentTerminated() ? self.myAgent.producer.primaryOfficeCodeName : self.myAgent.fullName,
      adviserName: '',
    }

    // Apply WAT-215 requirements.
    if (!self.isAgentTerminated() && self.myAgent.partyType === PT_ORGANIZATION) {
      self.servicingAdviser.adviserName = self.myAgent.firstName && self.myAgent.lastName ? self.myAgent.firstName + ' ' + self.myAgent.lastName : ''
    }
  }

  /**
   *
   * Original Logic
   *
   * (summaryCtrl.isTermPolicy() || summaryCtrl.isWholeLife()) && summaryCtrl.isOutsideBillingPeriod()
   * (summaryCtrl.isTermPolicy() || summaryCtrl.isWholeLife()) && summaryCtrl.isInsideBillingPeriod()
   */
  self._showPremiumDueButton = function () {
    const insideBillingPeriod = self.isInsideBillingPeriod()
    const inGracePeriod = self.isGracePeriod()
    const inMercyPeriod = self.isMercyPeriod()
    const isTrad = self.helper.tradProduct()

    // NOTE: TRAD policy, and
    //       requires that it be inside billing period, and not in Grace period or Mercy period.
    const finalAnswer = isTrad && insideBillingPeriod && !inGracePeriod && !inMercyPeriod

    return finalAnswer
  }

  self._showULPremiumDueButton = function () {
    // console.log(self.summary.isULProductType, self.isClientView, self.canMakePremiumPayment(), !self.isGracePeriod())
    return self.summary.isULProductType && self.isClientView && self.canMakePremiumPayment() && !self.isGracePeriod() && !self.isPossibleGracePeriod()
  }

  /**
   * Original Logic
   *
   * (summaryCtrl.isTermPolicy() || summaryCtrl.isWholeLife()) && summaryCtrl.isGracePeriod()
   */
  self._showGracePeriodButton = function () {
    const inGracePeriod = self.isGracePeriod()
    const inMercyPeriod = self.isMercyPeriod()
    // const isTrad = self.isTermPolicy() || self.isWholeLife()

    // NOTE: There is currently no logic applied here for UL policies. This is because the API
    //      does not return grace period data for UL.
    const finalAnswer = /* isTrad && */ inGracePeriod && !inMercyPeriod

    return finalAnswer
  }

  self._showMercyPeriodButton = function () {
    const isTermLife = self.isTermPolicy()
    const isWholeLife = self.isWholeLife()
    const isMercyPeriod = self.isMercyPeriod() && !self.isGracePeriod()
    const canLapseToSomething = self.willLapseToNone() || self.willLapseToETI() || self.willLapseToRPU() || self.willLapseToAPL()

    const finalAnswer = isMercyPeriod && (isTermLife || (isWholeLife && canLapseToSomething))

    return finalAnswer
  }

  self._showPossibleGracePeriodButton = function () {
    const isPossibleGracePeriod = self.isPossibleGracePeriod()
    const isUlPolicy = self.isUniversalLifePolicy()

    const finalAnswer = isPossibleGracePeriod && isUlPolicy

    return finalAnswer
  }

  self._showRightBox = function () {
    return self.hasPremiumStatus || self.isPolicyLapsed
  }
  // self.showPolicyLapsedButton = function () {
  //   const finalAnswer = self.isWholeLife() && self.isMercyPeriod() && (self.willLapseToNone() || self.willLapseToETI() || self.willLapseToRPU() || self.willLapseToAPL())

  //   return finalAnswer
  // }

  self._showNextPaymentDueBox = function () {
    const clientView = self.isClientView && self.helper.tradProduct() && !self.canMakePremiumPayment() && !self.isPolicyLapsed
    const internalView = !self.isClientView && self.helper.tradProduct() && self.canMakePremiumPayment() && !self.isPolicyLapsed
    // const clientView = !self.isClientView || self.isClientView && !self.canMakePremiumPayment() && !self.isPolicyLapsed()
    // console.log("clientView", self.helper.tradProduct(), !self.canMakePremiumPayment(), !self.isPolicyLapsed)
    // const internalView = !self.isClientView
    // self.hasPremiumStatusself.isClientView &&

    return clientView || internalView || configService.features.disableEpay
  }

  self._showPayNowButton = function () {
    return self.isClientView && self.canMakePremiumPayment() && !configService.features.disableEpay
  }

  self.setPageTitle = function () {
    if (configService.serverMode === 'agent') {
      let title = self.summary.polNumber + ' ' + self.summary.policyStatus

      if (!summaryResult.error) {
        title = (self.summary.owners?.[0].lastName || self.summary.owners?.[0].fullName) + ' ' + self.summary.polNumber + ' ' + self.summary.policyStatus
      } else {
        title = `Policy Summary ${self.summary.polNumber}`
      }

      $rootScope.pageTitle = title
      $rootScope.mobileHeaderTitle = title
    }
  }

  self._canViewTaxDocsButton = function () {
    return $rootScope.featureFlags.showAgentTaxDocs && !self.correspondenceDocuments.length && authService.isInRole('AGENT')
  }

  /** viewState Implementation */
  self.UI_VIEW_TARGETS = [
    'policy.funds',
    'policy.rebalance',
    'policy.transfer',
    'policy.changeBeneficiaries',
    'policy.roles',
    'policy.roles.list',
    'policy.roles.edit.role-details',
    'policy.roles.edit.personal-details',
    'policy.roles.edit.personal-information',
    'policy.roles.edit.address-edit',
    'policy.roles.transactions',
    'policy.roles.transactions.summary',
    'policy.roles.transactions.history',
    'policy.roles.transactions.details',
    'policy.roles.transactions.client',
    'myPolicy.roles.transactions.history',
    'myPolicy.roles.transactions.summary',
    'myPolicy.roles.transactions',
    'myPolicy.roles',
    'myPolicy.rebalance',
    'myPolicy.transfer',
    'myPolicy.funds',
    'myPolicy.clientBeneEdit',
  ]

  self._resolveUiViewTargetVisibility = function (currentRouteName) {
    const foundUiTarget = Boolean(POLICY_UI_VIEW_TARGETS.find((uiTarget) => currentRouteName.startsWith(uiTarget)))
    const visibilityState = {
      showPolicySummary: !foundUiTarget && !self.isRpsPolicy,
      showPolicySummaryRps: !foundUiTarget && self.isRpsPolicy,
    }

    return visibilityState
  }
  self.viewState = {
    showErrorMessageSection: false,
    showPolicySummarySection: false,
    showClientViewSwitch: false,
    // showPolicySummaryInfo: false,
    showPolicyRelatives: false,
    showPolicySummary: false,
    showPolicySummaryRps: false,
  }


  self.updateViewState = function (source) {
    const uiViewState = self._resolveUiViewTargetVisibility($state.current.name)

    self.viewState = {
      showErrorMessageSection: self.isThereError,
      showPolicySummarySection: !self.isThereError,
      showClientViewSwitch: self.summary.isPending === false,
      // showPolicySummaryInfo: false,
      showPolicyRelatives: true,
      ...uiViewState,
    }
  }

  self._showPaymentNotAllowed = function () {
    return self.isClientView && self.paymentDetails.totalAmount > 999999.99
  }

  self._fpStatus = {
    'Star': 'star-logo',
    'Master': 'master',
    'Master Pro': 'master-pro'
  }

  function resolveAgentDisplay() {
    const servicingAgents = myAgents.data.parties.filter((party) => {
      return party.roles.some((role) => role.tc === '38')
    })
    const servicingOffices = myAgents.data.parties.filter((party) => {
      return party.roles.some((role) => role.tc === '46')
    })

    if (servicingAgents.length > 1  || !servicingAgents.length || (servicingAgents.length === 1 && servicingAgents[0].producer.status === 'Terminated')) {
      self.myAgent = servicingOffices[0]
    }

    if (servicingAgents.length === 1) {
      self.myAgent = servicingAgents[0]
    }
  }

  function init() {
    self.showAsError = true

    if (window.dataLayer) {
      dataLayer.push({
        'lineOfBusiness': self.summary.lineOfBusiness,
        'productType': self.summary.productType,
        'yearsActive': self.summary.yearsInForce,
        'aceIndicator': self.summary.aceIndicator,
        'event': 'POLICY_SUMMARY_VIEW',
        'policyId': self.summary.polNumber,
        'userId': authService.getCurrentSession().loggedInAs,
      })
    }
    self.setPageTitle()

    if (!summaryResult.error) {
      if (self.summary.isPending) {
        initPendingPolicyApplication()
        // initPolicyAgents() Why is this only here?
      }

      initClients()
      initCorrespondence().then((result) => { // NOTE: This returns a promise.
        self.canViewTaxDocsButton = self._canViewTaxDocsButton()
      })
      initRidersAndBenefits()
      initArrangement()
      initRequirementsMet()
      initAlerts()
      initPolicyAgents()
      initUnableToTransactMessages()
      resolveAgentDisplay()

      self.logoUrl = self._fpStatus[self.primaryAgent?.producer?.starMasterStatus]
      self.starMasterStatus = self.isHomeOfficeUser() && Boolean(self.logoUrl)
      self.showLoanPayoffButton = self.showLoanPayoff()
      self.showPurchasePUAButton = self.showPurchasePUA()
      self.showLoanOrPUAButtons = self._showLoanOrPUAButtons()
      self.pmlLogo = policyUtils.deriveCarrierLogo(self.summary.carrierCode)
      self.termDateLabel = self._termDateLabel()
      self.isRpsPolicy = self._isRpsPolicy()
      self.clientType = self.determineInsuredOrAnnuitant()
      self.clientTypeDescription = self._clientTypeDescription()
      self.paymentAmount = self._getPaymentAmount()
      self.isClientView = authService.isClientView()
      self.isPolicyLapsed = self._isPolicyLapsed()
      self.summary.gracePeroidEndDate = self.summary.gracePeroidEndDate && new Date(self.summary.gracePeroidEndDate)
      self.hasPremiumStatus = self.helper.hasPremiumStatus()
      self.showNextPaymentDueBox = self._showNextPaymentDueBox()
      self.showRightBox = self._showRightBox()
      self.paymentMode = self._getPaymentMode()
      self.showPayNowButton = self._showPayNowButton()
      self.showULPremiumDueButton = self._showULPremiumDueButton()
      self.paymentSecondaryMessage = self.helper.getPremiumStatus(self.policyHasDaysLeft)
      self.showPolicyLapsedMessage = self.isPolicyLapsed && self.isClientView && !configService.features.disableEpay
      self.showPolicyWillLapseMessage = self.isClientView && self.canMakePremiumPayment() && !configService.features.disableEpay
      self.showPaymentNotAllowed = self._showPaymentNotAllowed()
      if (self.helper.tradProduct() && authService.isClientView() && self.isPennCheck() && self.paymentSecondaryMessage === CONSTANTS.notAvailableInfo) {
        self.paymentSecondaryMessage = CONSTANTS.paymentsDue.next
      }

      // console.log(">>>> showLoanOrPUAButtons", !authService.isARealClient() && authService.isClientView(), authService.isARealClient(), self.showLoanOrPUAButtons)

      if (self.summary.isAnnuity && self.policyValues) {
        self.annuityCashSurrenderValues = policyUtils.orderedValues(self.policyValues.cashsurrendervalue, CONSTANTS.annuityCashSurrenderValueMeta)
      }

      self.issueState = self.summary.jurisdiction // little bit of #WTF-BBQ where is issueState that was on search results
      self.restrictByIssueState = authService.restrictByIssueState(self.issueState)

      if (self.restrictByIssueState) {
        const phone = (self.issueState === 'New York' || self.issueState === 'NY') ? '(855) 446-7393' : '(800) 523-0650'

        self.errorMessage = 'You are not permitted to view details for this policy.'
        self.errorHeader = 'Not Authorized for Servicing.'
        self.helpMessage = 'This policy is serviced by the Penn Mutual Service Center at ' + phone + '.'
      } else if (summaryResult.warning) {
        // we may move back to using the reason in the message
        // let warningMessage = `This policy is restricted and not viewable by the field. Restriction Reason: ${summaryResult.restrictionCode}`
        let warningMessage = 'This policy is restricted and not viewable by the field or client.'

        if (summaryResult.policy.isSuspended && summaryResult.policy.isRestricted) {
          warningMessage = 'This is suspended and restricted. It is not viewable by the field or client.'
        } else if (summaryResult.policy.isSuspended) {
          warningMessage = 'This policy is suspended and not viewable by the field or client.'
        }

        notificationCenterService.add('info', warningMessage, CONSTANTS.notificationType.policy, CONSTANTS.eventId.SYSTEM, {
          'status': 'shown',
        })
      } else if (self.summary.isAnnuity && summaryResult.policy.policyStatusType.tc === '10') {
        const warningMessage = `<p>There is a pending death claim for this contract.
          Values are stated as of the date the claim was reported.</p><p>Actual claim proceeds may differ from the values below.
          If this contract is continued, values will be updated once the claim has been processed.</p>`

        notificationCenterService.add('info', warningMessage, CONSTANTS.notificationType.policy, CONSTANTS.eventId.SYSTEM, {
          'status': 'shown',
        })
      }

      recentlyViewedService.addPolicyPromise(self.summary)

      self.showPremiumDueButton = self._showPremiumDueButton()
      self.showPremuimPastDueButton = self._showGracePeriodButton()
      self.showPolicyLapsedButton = self._showMercyPeriodButton()
      self.showPolicyPossibleGraceButton = self._showPossibleGracePeriodButton()

      if ($state.params.openPayment) {
        self.openPaymentWidget()
      }
    } else if (summaryResult.status === '-1') {
      self.showAsError = false
      self.errorMessage = summaryResult.error
      self.errorHeader = CONSTANTS.policyRestricitonHeaderMessage
    } else {
      self.errorMessage = summaryResult.status !== 404 ? summaryResult.error : CONSTANTS.notFoundMessage
    }

    self.isThereError = Boolean(self.errorMessage)
    self.isThereEWarning = Boolean(self.warning)

    _resolveServicingAdviserDisplayData()

    self.updateViewState($state.current.name)
  }

  self.showForms = function () {
    modalService.openComponent('policyForms', {
      // windowClass: 'warning-modal',
      size: 'lg',
      keyboard: true,
      backdrop: 'static',
    }, { policy: self.summary })

    return modalService.modalInstance.result
  }

  /** Component Style Configuration */
  self._deregisterFns = []

  self.$onInit = function () {
    console.log('$onInit')

    self._deregisterFns.push($scope.$on('$stateChangeSuccess', self.updateViewState))
  }

  self.$onDestroy = function () {
    console.log('$onDestroy')
    self._deregisterFns.forEach((fn) => fn())
  }

  init() // TODO: Discuss with WAT devs if this shoud go inside of $onInit
}
