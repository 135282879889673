export {WgSpinnerSmall}

function WgSpinnerSmall() {
  return {
    restrict: 'E',
    transclude: false,
    template: function(elem, attr, scope) {
      let classAddition
      let block = false

      if (attr.blockElement !== undefined) {
        block = true
      }

      classAddition = (block ? ' spinner-within-document' : '')

      return '<div class="spinner spinner-small' + classAddition + '"></div>'
    },
  }
}
