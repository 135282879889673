/**
 * Renders the <quarter-selector /> directive as a list for buttons.
 */
export class QuarterSelectorDirective implements ng.IDirective {
  restrict = 'E'
  templateUrl = 'app/batch-statements/bs-quarter-selector.html'
  replace = false

  constructor (private $q: ng.IQService) {}

  /**
   * AngularJS post link function use for initial configuration of instances of QuarterSelectorDirective
   */
  // tslint:disable-next-line
  link(scope: any, el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    const bsCtrl: IBatchStatementsController = scope.bsCtrl

    bsCtrl.selectedQuarter = ''

    el.find('button').on('click', () => this.$q.resolve(bsCtrl.selectedQuarter)
      .then(() => bsCtrl.requestStatements(bsCtrl.selectedQuarter)
      // .then(() => bsCtrl.message = 'Request for ' + bsCtrl.selectedQuarter + ' batch statements sent successfully.')
      .then(() => bsCtrl.resolveData().catch(err => console.error('[QuarterSelectorDirective::link]', err))
      .catch(err => console.error('[QuarterSelectorDirective::link]', err))
      // .then(() => this.$timeout(() => bsCtrl.message = '', 1000))
      .then(() => bsCtrl.selectedQuarter = ''))))

    scope.$on('$destroy', () => bsCtrl.stopRefresh())
  }

  /**
   * Creates an instance of QuarterSelectorDirective, with dependencies injected.
   */
  static factory (): ng.IDirectiveFactory {

    const directive = ($q: ng.IQService) => new QuarterSelectorDirective($q)

    directive.$inject = ['$q', '$timeout']

    return directive
  }
}
