import {StateService, StateParams, UrlService} from '@uirouter/core'
import { MfaDataProvider } from '../mfa-data-provider'
import { MFA_TOKEN_TYPES } from '..'
export class MfaSendCodeController {
  static $inject: any = ['$stateParams', 'mfaDataProvider', 'authService', '$state', '$urlService']

  pocList: any
  selectedPoc: any
  showAddButton: boolean
  subGuid: string
  errorMessage: string
  noPocList: boolean = false
  onlyHasLandlinePoc: boolean = false

  constructor(private $stateParams: StateParams, private mfaDataProvider: MfaDataProvider, private authService, private $state: StateService, private $urlService: UrlService) {
    this.subGuid = this.authService.getSubGuid()
  }

  canText(methodArray) {
    return methodArray.some(method => method === 'TEXT')
  }

  canCall(methodArray) {
    return methodArray.some(method => method === 'VOICE')
  }

  requestCode(poc: any, mfaMethod: string): void {
    const p = { poc: poc, method: mfaMethod, redirectUrl: this.$stateParams.redirectUrl, tokenType: this.$stateParams.tokenType || MFA_TOKEN_TYPES.MFA_VERIFIED, redirectState: this.$stateParams.redirectState }

    void this.mfaDataProvider.requestVerificationCode(poc.uid, mfaMethod)
    void this.$state.go('mfa.submit-code', p)
  }

  private _stripPlus(countryCode) {
    return countryCode.replace('+', '')
  }

  addNewPoc(phoneNumber: string, countryCode: string) {
    let pocInfo = { phoneNumber, countryCode }

    pocInfo.countryCode = this._stripPlus(pocInfo.countryCode)
    this.mfaDataProvider.addPointOfContact(pocInfo).then(() => {
      this.$urlService.url('/#/mfa/check?redirect_url=' + this.$stateParams.redirectUrl)
    })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          this.errorMessage = error.data.message
        }
        console.log(error)
      })
  }

  _onlyHasLandlinePoc(poc) {
    return poc.metadata.phoneType === 'LANDLINE'
  }

  $onInit() {
    this.mfaDataProvider.listPointsOfContact().then(result => {
      if (result.length) {
        if (result.length === 1) {
          this.selectedPoc = result[0]
          this.onlyHasLandlinePoc = this._onlyHasLandlinePoc(result[0])
        }
        return this.pocList = result
      } else {
        this.noPocList = true
      }
    })
      .catch(error => {
        console.log(error)
      })
  }
}
