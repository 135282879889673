/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IPromise, IScope, IController } from 'angular'
import { IDropdownEntry } from '../../components/dropdown/dropdown.component'
import { LoggingService } from '../../utils/logging'
import { PERMISSION_IS_ADVISOR, ROLE_AGENT, ROLE_ASSISTANT, ROLE_FIELD_MGMT, ROLE_HOMEOFFICE } from '../../widgets/navigation-header/controller'
import { NotificationChannel, NotificationSettings, notificationTimes } from '../notification-settings'
import {  UserSettingsService } from '../user-settings.service'
import { IDelegator, IUserSettingResponse } from '../types'

export interface ISimplePhoneNumber {
  countryCode: string
  phoneNumber: string
}
export class NotificationsController implements IController {
  static $inject: string[] = ['$scope', 'userSettingsService', 'authService', 'utils', 'partyService', 'configService', 'loggingService']

  public notificationTimes: IDropdownEntry[] = notificationTimes
  public infoBlock: string
  public delegators: IDelegator[]
  public selectedFP: IDelegator = { userid: '' }
  public userSettings: NotificationSettings = new NotificationSettings()
  public myProfileLink: string
  public loggedInUser: {
    userId: string
    isAssistant: boolean
    email: string
    phone: string
    canManage: boolean
    userIsHomeOffice: boolean
    isAgent: boolean
    isFieldMgmt: boolean
  }

  private viewingAsFp: string
  private currentActiveAgent: any
  private unregisterWatches: unknown[] = []
  private uiState = {
    loading: true,
    updating: false,
    showFinancialProfessionals: false,
    showNotificationSettings: false,
    lnbaDisabled: false,
    inforceDisabled: false,
    noTimeSelected: true,
    messages: [],
    hasError: false,
  }

  constructor(private $scope: IScope, private userSettingsService: UserSettingsService, private authService: any, private utils: any, private partyService: any, private configService: any, private loggingService: LoggingService) { }

  /**
   * get targetUserId()
   * ------------------
   * Return the userId of the person who is to received the push notifications.
   *
   */
  get targetUserId(): string {
    let targetUser

    if (this.loggedInUser.isAgent && this.loggedInUser.isAssistant) {
      targetUser = this.loggedInUser.userId === this.selectedFP.userid ? undefined : this.selectedFP.userid
    } else if (this.loggedInUser.isAgent && !this.loggedInUser.isAssistant) {
      targetUser = undefined
    } else if (this.loggedInUser.isAssistant && !this.loggedInUser.isAgent) {
      targetUser = this.selectedFP.userid
    } else if (this.loggedInUser.userIsHomeOffice) {
      targetUser = this.viewingAsFp
    }

    return targetUser
  }

  handleApiErrors(err: any) {
    if(err.statusCode === 200) {
      this.uiState.hasError = true
    }

    this.loggingService.error(err, 'user-settings')
  }

  /**
   * handleUserSettingChanges
   * ------------------------
   * Called in response to changes to the userSettings triggered by user interaction with the UI.
   *
   * @param settingGroup
   * @param changedData
   * @param originalData
   */
  private handleUserSettingChanges(changedData: any, originalData: any): void {
    if (this.uiState.updating) return

    const delta = Object.entries(this.utils.compareObjects(changedData, originalData))[0]
    const [propName, diff]: [string, any] | [] = delta ?? []

    if (!delta) return

    this.uiState.messages = []

    if (propName === 'notificationTime') {
      this.userSettingsService.updateNotificationSettings(this.userSettings,this.targetUserId)
        .then((result: IUserSettingResponse) => {
          this.updateUI({ result })
        })
        .catch((err) => this.handleApiErrors(err))
    } else if (diff.after === true) {
      this.userSettingsService.saveNotificationSetting(this.userSettings, propName as NotificationChannel, this.targetUserId)
        .then((result: IUserSettingResponse) => {
          this.updateUI({ result })
        })
        .catch((err) => this.handleApiErrors(err))
    } else if (diff.after === false) {
      this.userSettingsService.deleteNotificationSetting(this.userSettings, propName as NotificationChannel, this.targetUserId)
        .then((result: IUserSettingResponse) => {
          this.updateUI({ result })
        })
        .catch((err) => this.handleApiErrors(err))
    }

  }

  /**
   * updateUI
   * --------
   * Updates the uiState object bases various UI/UX requirements, and values.
   *
   * The options parameter can be provided to supply custom values.
   *
   * @param options?
   */
  private updateUI(options?: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (options?.updating === false) {
      this.$scope.$applyAsync(() => {
        this.uiState.updating = options.updating
      })
    } else if (options?.updating === true) {
      this.uiState.updating = true
    }

    // NOTE: Not a fan of this approach, but it made it easier to see conditions.
    if (this.loggedInUser.isAssistant && Boolean(this.selectedFP.userid)) {
      this.uiState.showNotificationSettings = true
    } else if (!this.loggedInUser.isAssistant && this.loggedInUser.isAgent) {
      this.uiState.showNotificationSettings = true
    } else if (!this.loggedInUser.isAssistant && this.loggedInUser.userIsHomeOffice && Boolean(this.currentActiveAgent.key)) {
      this.uiState.showNotificationSettings = true
    } else if(this.loggedInUser.isAgent) {
    //   this.uiState.showNotificationSettings = true
    // } else {
      this.uiState.showNotificationSettings = false
    }

    this.uiState.inforceDisabled = this.loggedInUser.userIsHomeOffice && !this.loggedInUser.canManage
    this.uiState.lnbaDisabled = this.uiState.inforceDisabled
    this.uiState.noTimeSelected = this.userSettings.inforce.notificationTime.value === -1

    if (options?.result && options.result.statusCode !== 200) {
      this.handleApiErrors(options?.result)
    }
  }

  /**
   * resetNotificationSettings
   * -------------------------
   * Creates a new instance of NotificationSettings, esentially "reseting" state of the UI
   *
   */
  private resetNotificationSettings() {
    this.userSettings = new NotificationSettings()
  }

  public closeMessage() {
    this.uiState.hasError = false
  }

  /**
   * handleFpChange
   * --------------
   * Called in reponse to a user choosing an option in the "Financial Professional" dropdown.
   *
   */
  private handleFpChange(): IPromise<any> | void {
    if (!this.selectedFP?.userid) return

    this.updateUI({ updating: true })
    this.$scope.$applyAsync(() => {
      this.resetNotificationSettings()

      return this.userSettingsService.getSettings(this.selectedFP.userid === this.loggedInUser.userId ? undefined : this.selectedFP.userid)
        .then((result: IUserSettingResponse) => {
          this.userSettings.parse(result.settings)
          this.updateUI({ updating: false })
        })
    })

    // TODO: Account for Assistant/Agent role.


  }

  buildSamlRequest(uid: string, myProfileUrl: string): string {
    const encodedURL = encodeURIComponent(window.location.href)
    const saml: string = `<?xml version="1.0" encoding="UTF-8"?>
      <samlp:AuthnRequest
          xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol"
          ID="${uid}"
          Version="2.0"
          IssueInstant="${new Date().toISOString()}"
          ProtocolBinding="urn:oasis:names.tc:SAML:2.0:bindings:HTTP-Redirect"
          ProviderName="pennmutual.com/prodplace"
          AssertionConsumerServiceURL=""/>`

    return `${myProfileUrl}?profile=Y&RelayState=${encodedURL}&SAMLRequest=${btoa(saml)}`
  }

  /**
   *
   * @param mfaPhone
   * @returns string
   */
  private resolvePhoneNumber(mfaPhone: ISimplePhoneNumber): string {
    if (!mfaPhone) return 'n/a'
    if (mfaPhone.phoneNumber) return mfaPhone.countryCode + mfaPhone.phoneNumber

    return 'n/a'
  }

  $onDestroy(): void {
    this.unregisterWatches.forEach((fn: () => void) => fn())
  }

  $postLink(): void {
    const session = this.authService.getCurrentSession()

    this.myProfileLink = this.buildSamlRequest(this.utils.UID(), this.configService.ssoEngineURL)
    this.infoBlock = 'app/user-settings/tmpl/info-block-content.html'
    this.loggedInUser = {
      userId: session.loggedInAs, // TODO: This should use activeAgent.uid when logged in as an HO user.
      isAssistant: this.authService.checkRoles([ROLE_ASSISTANT]),
      email: session.emailAddress ?? 'n/a',  // TODO: Need to confirm if we have email, and phone # for the activeAgent
      phone: this.resolvePhoneNumber(session.mfaPhone),
      userIsHomeOffice: this.authService.isInRole(ROLE_HOMEOFFICE),
      isAgent: this.authService.matchAnyPermission([PERMISSION_IS_ADVISOR]),
      canManage: session.canAccess.canManageUserSettings,
      isFieldMgmt: this.authService.checkRoles([ROLE_FIELD_MGMT]),
    }
    this.viewingAsFp = this.loggedInUser.userIsHomeOffice ? this.partyService.getActiveAgent()?.uid : undefined
    this.currentActiveAgent = this.partyService.getActiveAgent()

    this.updateUI()

    if (this.loggedInUser.isAssistant) this.unregisterWatches.push(this.$scope.$watch('notificationsCtrl.selectedFP', () => this.handleFpChange()))
    this.unregisterWatches.push(this.$scope.$watchCollection('notificationsCtrl.userSettings.lnba', (n, o) => this.handleUserSettingChanges(n, o)))
    this.unregisterWatches.push(this.$scope.$watchCollection('notificationsCtrl.userSettings.inforce', (n, o) => this.handleUserSettingChanges(n, o)))

    if (this.loggedInUser.isAssistant) {
      this.userSettingsService.getDelegators(this.loggedInUser.userId)
        .then((delegators: IDelegator[]) => {
          this.uiState.showFinancialProfessionals = delegators.length > 0

          if(this.loggedInUser.isAgent) { // For when the user is both an assistant and an agent
            delegators.unshift({
              userid: this.loggedInUser.userId,
              fullName: session.name,
            })
          }

          this.delegators = delegators
        })
        .catch(err => this.loggingService.error(err))
        .finally(() => {
          this.uiState.loading = false
        })
    } else {
      this.uiState.updating = true
      this.userSettingsService.getSettings(this.viewingAsFp)
        .then((result: IUserSettingResponse) => {
          this.updateUI({ updating: true })
          this.userSettings.parse(result.settings)
          this.updateUI({ updating: false })
        })
        .catch(err => this.loggingService.error(err))
        .finally(() => {
          this.uiState.loading = false
        })
    }
  }
}

export const NotificationsComponent = {
  // bindings: {

  // },
  // require: {

  // },
  templateUrl: 'app/user-settings/notifications/notifications.html',
  controller: NotificationsController,
  controllerAs: 'notificationsCtrl',
}
