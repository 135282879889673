(function() {
  'use strict'

  angular.module('app.directives').directive('wgAlertDetails', wgAlertDetails)

  function wgAlertDetails() {
    return {
      controller: AlertDetailController,
      controllerAs: 'alertDetails',
      restrict: 'E',
      templateUrl: 'app/widgets/templates/wg-alert-details.html',
    }
  }

  AlertDetailController.$inject = ['$scope', '$rootScope', 'CONSTANTS', 'alertHasFieldService', 'policyUtils', 'policyService']

  /* @ngInject */
  function AlertDetailController($scope, $rootScope, CONSTANTS, alertHasFieldService, policyUtils, policyService) {
    const self = this

    self.alert = {}
    self.currentCategory = ''
    self.errorMessage = ''
    self.isThereError = false
    self.notAvailableInfo = CONSTANTS.notAvailableInfo

    self.isConservation = false
    self.isEarlyWarning = false
    self.isMarketing = false
    self.isCommonGroup = false

    self.has = alertHasFieldService

    self.cleanDateField = function(data) {
      if (data && data.toString().toLowerCase() === 'n') {
        return ''
      }

      return data
    }

    self.isMarketingTermConversion = function() {
      return self.alert && self.isMarketing && self.alert.activityType.toLowerCase() === 'term conversion'
    }

    self.hasFieldRemark = function() {
      return self.alert && self.alert.dataPointsJson.remarks
    }

    self.faceAmount = function() {
      return self.alert.famt ? self.alert.famt : self.alert.dataPointsJson.contractValue
    }

    self.agentCellDisplay = function() {
      const agentDisplay = (self.alert.orphaned ? '*' : '') + self.alert.agent
      const noAgent = self.alert.orphaned && !self.alert.agent

      return noAgent ? 'Unassigned' : agentDisplay
    }

    self.viewPolicy = function() {
      $scope.main.goBack() // Close the window
      policyService.getPolicyBase(self.alert.polNumber).then(function(result) {
        policyUtils.gotoPolicySummaryByPolicyBaseOrNumber(result.error === '' ? result.policyBase : false, self.alert.polNumber)
      })
    }

    function init() {
      if (!$scope.alertDetailsResult.error) {
        self.alert = $scope.alertDetailsResult.alert
        alertHasFieldService.setAlertType(self.alert.activityType)

        self.currentCategory = self.alert.activityCategory ? self.alert.activityCategory : 'Category Not Available'

        $rootScope.mobileHeaderTitle = self.currentCategory

        self.isConservation = self.currentCategory === CONSTANTS.alertGroups.conservation
        self.isEarlyWarning = self.currentCategory === CONSTANTS.alertGroups.earlyWarning
        self.isMarketing = self.currentCategory === CONSTANTS.alertGroups.marketing

        self.isCommonGroup = !(self.isConservation || self.isEarlyWarning)
      } else {
        self.errorMessage = $scope.alertDetailsResult.status !== 404 ? $scope.alertDetailsResult.error : CONSTANTS.notFoundMessage
      }

      self.isThereError = Boolean($scope.alertDetailsResult.error)
    }

    init()
  }
})()
