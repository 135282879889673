export class PaymentsDataProvider {
  static $inject = [
    '$http',
    'CONSTANTS',
  ]

  constructor(private $http: any, private CONSTANTS: any) { }

  getLoanPayoffQuote(pid) {
    const url = this.CONSTANTS.policyQuoteLoanPayoff.replace(/\{0\}/gi, pid)

    return this.$http.get(url)
      .then(r => r.data)
  }
}
