import * as angular from 'angular'
import {AlertService} from './alert-service'
/**
 * Renders the <alert-indicator />
 */

export class AlertIndicatorDirective implements angular.IDirective {

  restrict = 'E'
  templateUrl = 'app/alert/tmpl/alert-indicator.html'
  replace = false
  scope = {
    toggleAlerts: '=',
    showAlertsIndicator: '=',
  }

  constructor(private authService, private alertService: AlertService, private configService) {}

  watchNumAlerts() {
    return this.alertService.numAlerts
  }

  showTheAlertsIndicator(scope): void {
    const filterKey = this.authService.partyService.getAgentKey()
    const isHO = this.authService.isInRole('HOMEOFFICE')

    scope.showAlertsIndicator = this.configService?.features.showAlertsIndicator && (!isHO || filterKey ? true : false)
  }

  resolveAlertCount(scope, n, alertCountCutoff) {
    if (n !== undefined) {
      if (n < alertCountCutoff) {
        scope.alertCount = String(n)
      } else {
        scope.alertCount = String(alertCountCutoff) + '+'
      }
    } else {
      scope.alertCount = String(n)
    }

    scope.showAlertCount = n !== 0
  }

  /**
   * AngularJS post link function use for initial configuration of instances of PendingStatementJobs
   */
  // tslint:disable-next-line
  link(scope: any, _el: ng.IAugmentedJQuery, attrs: ng.IAttributes) {
    const alertCountCutoff: number = Boolean(attrs.maxCount) ? Number(attrs.maxCount) : Number.POSITIVE_INFINITY

    scope.alertCount = 0
    scope.showAlertCount = Boolean(scope.alertCount)

    // NOTE: Watching the scope for changes to `wgNavHeaderCtrl.alertService.numAlerts`
    // instead of letting the directive inject a new instance of the NavigationHeaderController
    // and placing on directive scope.
    const numAlertsWatch = scope.$watch(() => this.watchNumAlerts(), (n: number, _o: number) => this.resolveAlertCount(scope, n, alertCountCutoff))
    const activeAgentChangedWatch = scope.$on('activeAgentChanged', () => this.showTheAlertsIndicator(scope))

    scope.$on('$destroy', () => {
      numAlertsWatch && numAlertsWatch()
      activeAgentChangedWatch && activeAgentChangedWatch()
    })
  }

  /**
   * Creates an instance of AlertIndicatorDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = (authService, alertService: AlertService, configService) => new AlertIndicatorDirective(authService, alertService, configService)

    directive.$inject = ['authService', 'alertService', 'configService']

    return directive
  }
}
