import * as angular from 'angular'

import {NbaUploadController} from './nba-upload-controller'
import {ApplicationFormController} from './form/form.component'
import {UploadApplicationService} from './upload-application.service'
import { remoteStatusCodes, bootstrapTypes, remoteStatesToBootstrapMap, NBA_GA_EVENTS } from './constants'
import { ApplicationFilesController, FileListController, FileSelectorController } from './files'
import { NbaUploadRunner } from './routes'

export const FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES = {
  INVALID_FILES: 'app/nba-upload/modal-messages/invalid-files.html',
  UPLOAD_PROGRESS: 'app/nba-upload/modal-messages/upload-progress.html',
  CONTACT_US_PROGRESS: 'app/contact/us/upload-progress.html',
}

angular.module('app.nba-upload', [])
  .service('uploadApplicationService', UploadApplicationService)
  .constant('FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES', FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES)
  .constant('REMOTE_STATUS_CODES', remoteStatusCodes)
  .constant('BOOTSTRAP_TYPES', bootstrapTypes)
  .constant('REMOTE_STATUS_TO_BOOTSTRAP_MAP', remoteStatesToBootstrapMap)
  .constant('NBA_GA_EVENTS', NBA_GA_EVENTS)

angular.module('app.nba-upload')
.component('nbaUpload', {
  templateUrl: 'app/nba-upload/main-view.html',
  controller: NbaUploadController,
  controllerAs: 'ctrl',
})
.run((routerHelper) => new NbaUploadRunner(routerHelper))
.component('applicationForm', {
  templateUrl: 'app/nba-upload/form/form.html',
  controller: ApplicationFormController,
  controllerAs: 'formCtrl',
  bindings: {
    appData: '<',
    onFormReady: '&',
  },
})
  .component('applicationFiles', {
    templateUrl: 'app/nba-upload/files/files.html',
    controller: ApplicationFilesController,
    controllerAs: 'filesCtrl',
    bindings: {
      validFileTypes: '<',
      chosenFiles: '<',
      maxFileSize: '<',
      maxAttachments: '<',
      maxUploadSize: '<',
      browse: '<',
    },
  })
 .component('fileList', {
   templateUrl: 'app/nba-upload/files/list/file-list.html',
   controller: FileListController,
   controllerAs: 'fileListCtrl',
   bindings: {
     chosenFiles: '<',
     maxFileSize: '<',
     browse: '<',
   },
 })
.component('fileSelector', {
  templateUrl: 'app/nba-upload/files/selector/file-selector.html',
  controller: FileSelectorController,
  controllerAs: 'fsCtrl',
  bindings: {
    chosenFiles: '<',
    validFileTypes: '<',
    maxFileSize: '<',
    maxAttachments: '<',
    maxUploadSize: '<',
    onFilesDropped: '&',
  },
})
