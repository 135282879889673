(function() {
  'use strict'

  angular.module('app.directives').directive('handlePhoneSubmit', function() {
    return function(scope, element) {
      const textFields = angular.element(element).children('input[type=text]')

      angular.element(element).on('submit', function() {
        textFields.triggerHandler('blur')
      })
    }
  })
})()
