/**
 * ## pmlNgListSource
 *
 * ### Overview
 *
 * This directive allow requesting data from an AngularJS service declaratively in HTML markup.
 * The retrieved data is placed on the scope using the sourceMethod attributes value.
 *
 * ### Usage
 *    ```html
 *    <div pml-ng-list-source source-provider="clientDataProvider" source-method="productTypes">
 *    ```
 * ### Attributes
 *
 * |Name|Descriptions|
 * |----|------------|
 * |sourceProvider||
 * |sourceMethod||
 * |defaultIndex||
 *
 */
export class PmlNgSelect implements ng.IDirective {

  restrict = 'A'
  replace = true
  // require = '^^pmlNgListView'
  scope = {
    sourceProvider: '=',
    sourceMethod: '=',
    methodParams: '=',
    persistIn: '=',
    persistAs: '=',
    ngModel: '=',
    listData: '=',
    persistBucket: '=',
  }

  constructor(private $injector) { }

  getData(providerName: string, methodName: string, params?: any): Promise<any[]> {
    const sourceProvider: any = this.$injector.get(providerName)
    const sourceMethod: Function = sourceProvider[methodName]

    if (sourceProvider && sourceMethod) {
      return sourceMethod.call(sourceProvider, params)
        .catch(err => console.error('TODO: Implement errors better\n\t', err))
    } else {
      console.warn('%s is not found on data provider, "%s"', methodName, providerName)
      return Promise.resolve([])
    }
  }

  _findPersistedItem(data, value) {
    const found = data.find((pv) => pv.id === value.id)

    return found
  }

  refresh(scope, providerName: string, methodName: string, methodParams: any) {
    return this.getData(providerName, methodName, methodParams)
      .then(data => scope.listData = data)
      .then((data) => {
        if (scope.persistIn && scope.persistAs) {
          const webStorage: any = window[scope.persistIn]
          const currentPersistedValue = JSON.parse(webStorage.getItem(scope.persistBucket || scope.persistAs)) ?? {}
          const actualValue = scope.persistBucket ? currentPersistedValue[scope.persistAs] : currentPersistedValue
          const persistedValue = actualValue && this._findPersistedItem(data, actualValue)

          if (persistedValue) {
            scope.ngModel = persistedValue
          } else {
            scope.ngModel = data[0]
          }
          // @ts-ignore

          if (!scope.ngModel) scope.ngModel = data[0]
        } else {
          scope.ngModel = data[0]
        }
      })
  }

  /**
   * AngularJS post link function use for initial configuration of instances of PmlNgSelect
   */
  // @ts-ignore
  link(scope: any, el: ng.IAugmentedJQuery, attrs: ng.IAttributes, listView: any) {
    const providerName: string = scope.sourceProvider
    const methodName: string = scope.sourceMethod
    const methodParams: any = scope.methodParams
    let clearWatch: any

    el.on('change', () => {
      console.log('change:', attrs, scope.ngModel)
      if (scope.persistIn && scope.persistAs) {
        const webStorage = window[scope.persistIn]

        if (scope.persistBucket) {
          // @ts-ignore
          const cur = JSON.parse(webStorage.getItem(scope.persistBucket)) ?? {}

          cur[scope.persistAs] = scope.ngModel

          // @ts-ignore
          webStorage.setItem(scope.persistBucket, JSON.stringify(cur))

        } else {
          // @ts-ignore
          webStorage.setItem(scope.persistAs, JSON.stringify(scope.ngModel))
        }

      }
    })

    if (Boolean(attrs.$attr['refreshWhenMethodParamsChange'])) {
      clearWatch = scope.$watchCollection('methodParams', (n) => {
        return this.refresh(scope, providerName, methodName, n)
      })
    } else {
      return this.refresh(scope, providerName, methodName, methodParams)
    }

    scope.$on('$destroy', () => clearWatch())

  }

  /**
   * Creates an instance of TrainingsDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = ($injector) => new PmlNgSelect($injector)

    directive.$inject = ['$injector']

    return directive
  }
}
