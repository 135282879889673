export class FileListController {
  static $inject: any = ['$scope']

  validFileTypes: string[] = [
    'application/pdf',
    'image/tiff',
    'image/png',
    'image/jpeg',
  ]
  chosenFiles: any[]
  maxFileSize: number
  browse: Function

  constructor(private $scope) {}

  get noFilesSelected(): boolean {
    return this.chosenFiles.length === 0
  }

  removeFile(index) {
    console.log('removeFile', this.chosenFiles[index])
    this.chosenFiles.splice(index, 1)

    if (this.chosenFiles.length === 0) this.$scope.$emit('all-files-removed')
  }
}
