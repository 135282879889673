/**
 * ## pmlNgListViewPager
 *
 * ### Overview
 *
 *
 * ### Usage
 *    ```html
 *    ```
 * ### Attributes
 *
 * |Name|Descriptions|
 * |----|------------|
 *
 */
export class PmlNgListViewPager implements ng.IDirective {
  restrict = 'E'
  replace = false
  require = '^^pmlNgListView'
  // bindToController = true
  transclude = true
  templateUrl = 'app/directives/pml-ng/list-view/pager/pager.html'

  scope = {
    sourceProvider: '=',
    sourceMethod: '=',
    persistIn: '=',
    persistAs: '=',
    totalItems: '=',
    totalPages: '=',
    currentPage: '=',
    currentPageSize: '=',
    rowsPerPage: '=',
    noData: '=',
  }

  /**
   * Called by the pager's previous and next button.
   *
   * @param scope
   * @param dir
   */
  _changePage(scope, dir) {
    scope.disablePrev = scope.currentPage === 1
    scope.disableNext = scope.currentPage === scope.totalPages

    if (dir === -1 && !scope.disablePrev) scope.currentPage -= 1
    if (dir === 1 && !scope.disableNext) scope.currentPage += 1
  }

  /**
   * Calculates the range of items that are being view.
   *
   * @param scope
   */
  _resolveViewingInfo(scope) {
    const basePage = (scope.currentPage - 1) * scope.rowsPerPage
    const rows = scope.currentPageSize < scope.rowsPerPage ? scope.currentPageSize : scope.rowsPerPage

    scope.viewingFirst = basePage + 1
    scope.viewingLast = scope.viewingFirst + rows - 1

  }

  _selectText(el) {
    if (el) el.select()
  }

  /**
   * AngularJS post link function used for initial configuration
   * of instances of this directive
   */
  // @ts-ignore
  link(scope: any, el: ng.IAugmentedJQuery, attrs: ng.IAttributes, listView: any) {
    const clearWatches = []

    scope.showPageSizes = !attrs.$attr['hidePageSizes']
    scope.changePage = this._changePage.bind(this, scope)
    scope.selectText = this._selectText.bind(this, el.find('input')[0])
    scope.hidePager = true

    // @ts-ignore
    clearWatches.push(scope.$on('PML_NG_LISTVIEW_DATA_READY', (e) => {
      scope.noData = listView.data.length === 0
      if (Boolean(attrs.hideWhenOnePage)) {
        scope.hidePager = scope.noData || !scope.noData && listView.totalPages <= 1
      }

    }))

    // @ts-ignore
    clearWatches.push(scope.$watch('currentPage', (n, o, s) => {

      console.log('currentPage changed', n, o)

      s.selectText()

      this._resolveViewingInfo(s)

      if (n < 1) s.currentPage = 1
      if (n > s.totalPages) s.currentPage = s.totalPages
      if (n !== o) listView.sortFilterPage(undefined, undefined, {page: s.currentPage, size: s.rowsPerPage})

    }))

    // @ts-ignore
    clearWatches.push(scope.$watch('rowsPerPage', (n, o, s) => {
      s.currentPage = 1
      this._resolveViewingInfo(s)
      if (n !== o) listView.sortFilterPage(undefined, undefined, { page: s.currentPage, size: s.rowsPerPage })
    }))

    // // @ts-ignore
    // clearWatches.push(scope.$watch('totalPages', (n, o, scope) => {
    //   console.log('attrs.hideWhenOnePage', attrs.hideWhenOnePage, scope.noData, n)
    // }))

    // @ts-ignore
    clearWatches.push(scope.$watch('currentPageSize', (n) => {
      // console.log('attrs.hideWhenOnePage', attrs.hideWhenOnePage, scope.noData, n)
      // if (attrs.hideWhenOnePage) {
      //   scope.hidePager = scope.noData || !scope.noData && n === 1
      // }

      if (scope.totalItems <= scope.rowsPerPage) scope.currentPage = 1

      this._resolveViewingInfo(scope)
    }))

    // @ts-ignore
    scope.$on('$destroy', () => clearWatches.forEach(f => f()))
  }

  /**
   * Creates an instance of this directive, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new PmlNgListViewPager()

    directive.$inject = []

    return directive
  }
}
