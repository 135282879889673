/* eslint-disable quote-props */
const PHONE_TYPES = [
  { tc: '26', value: 'Primary', order: 1 },
  { tc: '1', value: 'Home', order: 2 },
  { tc: '2', value: 'Business', order: 3 },
  { tc: '12', value: 'Mobile', order: 4 },
  { tc: '11', value: 'Temporary', order: 5 },
  { tc: '19', value: 'Fax', order: 6 },
  { tc: '13', value: 'Pager', order: 7 },
  { tc: '0', value: 'Unknown', order: 8 },
]

const PHONE_TYPES_MAP = {
  '26': 'Primary',
  '1': 'Home',
  '2': 'Business',
  '12': 'Mobile',
  '11': 'Temporary',
  '19': 'Fax',
  '13': 'Pager',
  '0': 'Unknown',
}

const DEFAULT_PHONE = {
  number: '',
  id: '',
  typeCode: { tc: '26', value: 'Primary', order: 3 },
  countryCode: '',
  areaCode: '',
  dialNumber: '',
}

export {DEFAULT_PHONE, PHONE_TYPES, PHONE_TYPES_MAP}
