class StateUtils {
  static $inject = ['authService', 'stateTransitions']

  constructor(private authService, private stateTransitions) { }

  checkUserAndGotoState(state, params) {
    params = params || {}

    if (this.authService.isAClient()) {
      this.stateTransitions.go('myPolicy.' + state, params)
    } else {
      this.stateTransitions.go('policy.' + state, params)
    }
  }
}

angular.module('app.helpers', ['app', 'app.auth'])
    .service('stateUtils', StateUtils)
