export {PolicyDetailBeneficiariesController}
PolicyDetailBeneficiariesController.$inject = ['$scope']

function PolicyDetailBeneficiariesController($scope) {
  const self = this

  self.beneficiaries = []

  self.init = function() {
    console.log('Launching the stupid old modal that has its own dumb controller and everything.')
    self.beneficiaries = $scope.summaryCtrl.getAllBeneficiaries()
  }

  self.init()
}
