class ProfileDropdownController {
  $inject = ['authService', 'navigationService']

  fullName: string

  constructor (private authService, private navigationService) { }

  logout() {
    return this.navigationService.logout()
  }

  $onInit() {
    this.fullName = this.authService.getFullName()
  }
}

const ProfileDropdown = {
  templateUrl: 'app/components/profile-dropdown/profile-dropdown.html',
  controller: ProfileDropdownController,
  controllerAs: 'ctrl',
  bindings: {
  },
}

export {ProfileDropdown, ProfileDropdownController}
