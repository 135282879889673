export {ClientModalController}
ClientModalController.$inject = ['clients', '$stateParams', '$scope', '$rootScope', 'CONSTANTS', 'clientUtils', 'ROLE_LABELS', 'ROLES_ARRAY', 'ROLES_ORDER', 'ANNUITANT_ROLES', 'OWNER_ROLES', 'policySummary', 'beneficiariesDataProvider', 'configService']

function ClientModalController(clients, $stateParams, $scope, $rootScope, CONSTANTS, clientUtils, ROLE_LABELS, ROLES_ARRAY, ROLES_ORDER, ANNUITANT_ROLES, OWNER_ROLES, policySummary, beneficiariesDataProvider, configService) {
  const self = this
  const ROLE = {
    OWNER: 'owners',
    INSURED: 'insureds',
    PAYOR: 'payors',
    BENEFICIARY: 'beneficiaries',
  }

  self.ROLE_RESOLVERS = {
    'Owner': (clientList) => clientUtils.organizeRolesByType(clientList, 'Owner'),
    'Insured': (clientList) => clientUtils.organizeRolesByType(clientList, 'Insured'),
    'Annuitant': (clientList) => clientUtils.organizeRolesByType(clientList, 'Annuitant'),
    'Payor': (clientList) => clientUtils.organizeRolesByType(clientList, 'Payor'),
    'Beneficiary': (clientList) => clientUtils.organizeRolesByType(clientList, 'Beneficiary'),
    'Assignee': (clientList) => clientUtils.organizeRolesByType(clientList, 'Assignee'),
  }

  self.role = $stateParams.role
  self.clients = []
  self.partyName = clientUtils.partyName
  self.eligibility = JSON.parse($stateParams.eligibility)
  self.beneDesignationUrl = ''


  self.getConstantRole = function(role) {
    switch (role) {
      case 'Owner':
        return ROLE.Owner
      case 'Insured':
        return ROLE.INSURED
      case 'Payor':
        return ROLE.PAYOR
      case 'Beneficiary':
        return ROLE.BENEFICIARY
    }
  }

  self.getModalTitle = function() {
    switch (self.role) {
      case 'Beneficiary':
        return 'Beneficiaries'
      case 'Owner':
        return (self.clients.length > 1 ? 'Owners' : 'Owner')
      default:
        return self.role
    }
  }

  function getRoleOrder(role) {
    switch (role) {
      case 'Beneficiary':
        return 1
      case 'Contingent Beneficiary':
        return 2
      case 'Second Contingent Beneficiary':
        return 3
      case 'Final Beneficiary':
        return 4
      default:
        return 5
    }
  }

  self.isDropdownVisible = function(role) {
    role = role || self.getConstantRole(self.role)
    switch (role) {
      case ROLE.OWNER:
        return $rootScope.isOwnersDropdownVisible
      case ROLE.INSURED:
        return $rootScope.isInsuredsDropdownVisible
      case ROLE.PAYOR:
        return $rootScope.isPayorsDropdownVisible
      case ROLE.BENEFICIARY:
        return $rootScope.isBeneficiaryDropdownVisible
    }
  }

  self.isAnOrganization = function(client) {
    return client && client.partyType !== CONSTANTS.personPartyType
  }

  self.getRole = function(client) {
    return ROLE_LABELS[self.role][client.role.tc]
  }

  self.iconClass = function() {
    return self.role.toLowerCase() + '-link'
  }

  self.clientToSingleLineAddress = function(c) {
    let a

    // c.address = c.address || []
    a = c.role.address

    if (!a) {
      return 'Not provided'
    }

    return a.line1 + (a.line2 ? ' ' : ', ') +
        (a.line2 ? (a.line2 + ', ') : '') +
        a.city + ', ' +
        a.state + ' ' +
        a.zip
  }

  self._legacyRoleProcessor = function(clientList) {
    const processedClients = clientList.map(function(client) {
      client.roles.forEach(function(roleObject) {
        if (self._isMatchingRole(self.role, roleObject)) {
          client.sortOrder = getRoleOrder(roleObject.role)
          self.clients.push(client)
        }
      })
    })

    return processedClients
  }

  self.showIneligibilityMessage = function(serverMode) {
    return !self.eligibility.eligible && self.role === 'Beneficiary' && configService.serverMode === serverMode
  }

  self._init = function() {
    const organizedClients = clientUtils.organizeClientDetails(clients.data)
    const clientList = organizedClients[self.role]
    const hasStepInAnnuitant = Boolean(clientList.find(client => client.role.tc === ANNUITANT_ROLES.STEP_IN_ANNUITANT))
    const hasSuccessorOwner = Boolean(clientList.find(client => client.role.tc === OWNER_ROLES.SUCCESSOR_OWNER))

    if (hasStepInAnnuitant) {
      self.clients = clientList.filter(client => client.role.tc === ANNUITANT_ROLES.STEP_IN_ANNUITANT)
    } else if (hasSuccessorOwner) {
      self.clients = clientList.filter(client => client.role.tc === OWNER_ROLES.SUCCESSOR_OWNER)
    } else {
      self.clients = clientList
    }

    beneficiariesDataProvider.getDesignationFormUrl(policySummary).then((url) => {
      self.beneDesignationUrl = url
    })
  }

  self._isMatchingRole = function(roleName, roleObject) {
    return (roleObject.role.indexOf(roleName) !== -1) || (roleName === 'Owner' && roleObject.tc === '145')
  }

  self._init()
}
