(function() {
  'use strict'

  angular.module('app.directives').directive('preventCloseDialog', [function() {
    return {
      restrict: 'A',
      link: function(scope, elem) {
        elem.on('click', function(e) {
          e.stopPropagation()
        })
      },
    }
  }])
})()
