interface StackValueObject {
  rbThreshold: string
  geThreshold: string
  totalThreshold: number
  policyThreshold: number
}

interface PdfFileObject {
  title: string
  file: string
}

/* @ngInject */
export class ClubCreditsController {
  static $inject = ['authService', 'activeAgent', 'leaders', 'chairmans', 'stacked', 'starCredits', 'investmentResult', 'hasError', 'clubCreditsMessage', 'configService']

  public errorMessage: string
  public isThereError: boolean
  // public stacked: StackValueObject
  public policyPct: number
  public royalBluePct: number
  public goldenEaglePct: number
  public stackedPct: number
  public stackedPolicyPct: number
  public royalBlueConfPct: number
  public htkSubTotal: number
  public pmlSubTotal: number
  public investment
  public indicateAgentName: boolean
  public pdfDownloadPath: string
  public pdfFiles: PdfFileObject[]
  public message: any
  public currentYear: number
  public showStarReport: boolean
  public showStackedCredits: boolean
  public goldenEaglePolicyPct: number
  public goldenEagleConfPct: number
  public showStackedSection: boolean

  constructor(public authService, public activeAgent, public leaders, public chairmans, public stacked,
    public starCredits, public investmentResult, public hasError, clubCreditsMessage, private configService) {
    this.message = clubCreditsMessage
    this.indicateAgentName = !authService.isInRole('AGENT')
    this.leaders.asOfDate = Date.parse(this.leaders.asOfDate) || ''
    this.isThereError = this.hasError
    this.investment = this.investmentResult.credits
    this.policyPct = this.getPercentage(this.leaders.lifePolicyCount, this.leaders.lifePolicyCountThreshold)
    this.stackedPolicyPct = this.getPercentage(this.stacked.lifePolicyCount, this.stacked.policyCountThreshold)

    this.royalBluePct = this.getPercentage(this.leaders.lifeCredit, this.leaders.lifeThreshold)
    this.stackedPct = this.getPercentage(this.stacked.lifeCredit, this.stacked.lifeThreshold)
    this.goldenEaglePct = this.getGELifePercentage()
    this.goldenEaglePolicyPct = this.getGEPolicyPercentage()
    // this.stackedPct = this.getStackedPct()
    this.royalBlueConfPct = this.getRBInvestmentPercentage()
    this.goldenEagleConfPct = this.getGEInvestmentPercentage()
    this.htkSubTotal = this.getHtkSubTotal()
    this.pmlSubTotal = this.getPMLSubTotal()
    this.pdfDownloadPath = 'resources/clubs-pdfs/'
    this.pdfFiles = [{
      title: 'Penn Mutual Royal Blue',
      file: '2021 RB Qualifications - full PML HTK 12.4.pdf',
    },
    {
      title: 'Golden Eagle Qualifications',
      file: '2022 Golden Eagle Qualifications - final_KH11_16 MH.pdf',
    },
    ]
    this.currentYear = new Date().getFullYear()

    this.showStarReport = this._showStarReport()
    this.showStackedSection = this.configService.features.stackedClubCredits
  }

  // function isThereError() {
  //     return Boolean(this.errorMessage)
  //   }

  getLeadersSummary = () => this.leaders

  getCharimansSummary = () => this.chairmans

  getPercentage(creditsSold: number, threshold: number | undefined): number {

    if (threshold !== undefined && threshold !== 0) {
      const actualPercent = Math.round((creditsSold / threshold * 100))

      // number should not be bigger than 100.
      return Math.min(actualPercent, 100)
    }

    return 0
  }

  getGELifePercentage(): number {
    if (this.chairmans.lifeThreshold !== undefined && this.chairmans.lifeThreshold !== 0) {
      const actualPercent = Math.round((this.chairmans.lifeCredit / this.chairmans.lifeThreshold) * 100)

      console.info('Golden eagle pct is ', actualPercent)
      // number should not be bigger than 100.
      return Math.min(actualPercent, 100)
    }

    return 0
  }  // lifePolicyCount

  getGEPolicyPercentage(): number {
    if (this.chairmans.lifeThreshold !== undefined && this.chairmans.lifeThreshold !== 0) {
      const actualPercent = Math.round((this.chairmans.lifePolicyCount / this.chairmans.lifePolicyCountThreshold) * 100)

      console.info('Golden eagle pct is ', actualPercent)
      // number should not be bigger than 100.
      return Math.min(actualPercent, 100)
    }

    return 0
  }

  getTotalLifePoliciesSold(): number {
    const totalCredits = Math.max(this.sanitise(this.chairmans.lifeCredit), this.leaders.lifeCredit)

    return totalCredits
  }

  sanitise(x) {
    if (isNaN(x)) {
      return 0
    }

    return x
  }

  getRBInvestmentPercentage(): number {
    if (this.leaders.lifeThreshold !== undefined && this.leaders.lifeThreshold !== 0) {
      const actualPercent = Math.round((this.leaders.investmentCredit / this.leaders.investmentThreshold) * 100)

      // number should not be bigger than 100.
      return Math.min(actualPercent, 100)
    }

    return 0
  }

  getGEInvestmentPercentage(): number {
    if (this.chairmans.lifeThreshold !== undefined && this.chairmans.lifeThreshold !== 0) {

      const actualPercent = Math.round((this.leaders.investmentCredit / this.chairmans.investmentThreshold) * 100)

      console.info('getGEInvestmentPercentage', actualPercent)
      // number should not be bigger than 100.
      return Math.min(actualPercent, 100)
    }

    return 0
  }

  getHtkSubTotal(): number {
    if (this.investment?.htkLifeCredit !== undefined) {
      let subTotalAlt = 0

      subTotalAlt += this.investment.htkLifeCredit
      subTotalAlt += this.investment.htkAnnuityCredit
      subTotalAlt += this.investment.htkRIACredit
      subTotalAlt += this.investment.htkMutualFundCredit
      subTotalAlt += this.investment.htkAnnunityTrailCredit
      subTotalAlt += this.investment.htkLifeTrailerCredit
      subTotalAlt += this.investment.htkOtherCredit

      return Math.round(subTotalAlt)
    }

    return 0
  }

  getPMLSubTotal(): number {
    if (this.investment?.pmlVariableLifeCredit !== undefined) {
      let subTotalAlt = 0

      subTotalAlt += this.investment.pmlVariableLifeCredit
      subTotalAlt += this.investment.pmlVariableAnnuityCredit
      subTotalAlt += this.investment.fixedIndexAnnunityCredits
      subTotalAlt += this.investment.variableAnnuityTrailCredit
      subTotalAlt += this.investment.variableLifeTrailCredit

      return Math.round(subTotalAlt)
    }

    return 0
  }

  _showStarReport(): boolean {
    return this.starCredits.data.isCurrentStar || this.starCredits.data.isShootingStar
  }
}
