export const USER_SETTINGS_URLS = {
  DELEGATORS: '/userdata/relations',
  NOTIFICATIONS: '/user-settings/corporate/notifications',
  E_DELIVERY: '/user-settings/client/e-delivery',
}

export const EDELIVERY_TYPES = {
  STATEMENTS: 'statements',
  BILLING_NOTICES:  'billingNotices',
  TRANSACTION_CONFIRMATIONS: 'transactionConfirmations',
  POLICY_CORRESPONDENCE: 'policyCorrespondence',
}
