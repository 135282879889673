export {coreRun}
coreRun.$inject = ['$rootScope', '$window', 'routerHelper', 'configService', 'CONSTANTS', 'authService'] // , 'configService'

  /* @ngInject */
function coreRun($rootScope, $window, routerHelper, configService, CONSTANTS, authService) { // , configService
  let otherwise = configService.defaultRoute || CONSTANTS.DEFAULT_ROUTE

  if (authService.isAuthenticated()) {
    if (authService.isAClient()) {
      otherwise = '/client/me'
    } else {
      otherwise = '/myclients/search/result/'
    }
  }

  routerHelper.configureStates(getStates(), otherwise)

  $rootScope.offline = !navigator.onLine

  $window.addEventListener('offline', function() {
    $rootScope.$apply(function() {
      $rootScope.offline = true
    })
  })

  $window.addEventListener('online', function() {
    $rootScope.$apply(function() {
      $rootScope.offline = false
    })
  })
}

function getStates() {
  return [
      // {
      //    state: 'home',
      //    config: {
      //        url: '/',
      //        onEnter: function ($stateParams, $state, authService) {
      //            var sid = '';
      //            var url = window.location.href;

      //            if (url.indexOf('?sid') !== -1) {
      //                var array = url.split("?sid=");
      //                if (array && array.length > 0) {
      //                    sid = array[1];

      //                    $state.go('oauth2', { 'sid': sid });
      //                }
      //            }
      //            //else {
      //            //    $state.go('search');
      //            //}
      //        }
      //    }
      // }
  ]
}
