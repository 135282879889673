class MfaController {

  // constructor() {}

  // $onInit() {}

}

export const MainMfaComponent = {
  templateUrl: 'app/mfa/tmpl/main-view.html',
  controller: MfaController,
}
