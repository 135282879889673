
const POLICY_RELATIVE_TYPES = ['owner', 'insured']

WgUnsubmittedItemController.$inject = ['$scope', 'dateUtils', 'stateTransitions', 'authService', 'clientUtils', 'stringUtils', '$attrs', 'CONSTANTS', 'policyUtils', 'configService']

function WgUnsubmittedItemController($scope, dateUtils, stateTransitions, authService, clientUtils, stringUtils, $attrs, CONSTANTS, policyUtils, configService) {
  const self = this

  self.authService = authService
  self.advisorName = null

  // these two get set by self._setUnsubmittedRelativeName()
  self.ownerName = null
  self.insuredName = null

  self._unsubmittedRelativeName = function(relativeType) {
    const pluralizedRelativeType = stringUtils.pluralize(relativeType)
    const unsubmittedRelatives = self.application[pluralizedRelativeType]
    let relative

    if (unsubmittedRelatives && unsubmittedRelatives[0]) {
      return clientUtils.partyName(unsubmittedRelatives[0])
    }

    relative = self.application[relativeType]
    if (relative && relative.fullName) {
      return relative.fullName
    }

    return '—'
  }

  self._setUnsubmittedRelativeName = function(relativeType) {
    self[relativeType + 'Name'] = self._unsubmittedRelativeName(relativeType)
  }

  self.isPastDueDate = function(dueDate) {
    return dateUtils.isPastDueDate(dueDate)
  }

  self.showUnsubmitted = function() {
    stateTransitions.go(authService.isAClient() ? 'myUnsubmitted' : 'self.application', {
      'id': self.application.polNumber,
    })
  }

  self._determineUnsubmittedTypeLabelValue = function(application) {
    const type = {}

    if (application.applicationType === 'Term Conversion') {
      type.label = 'App Type:'
      type.value = application.applicationType
    } else {
      type.label = 'Type:'
      type.value = policyUtils.applicationMethod(application)
    }

    return type
  }

  self.eAppUrl = function(code) {
    if (!self.isHomeOffice) {
      return `${configService.eAppUrl}application/apppincode/${code}`
    }

    return ''
  }

  self._isHomeOffice = function() {
    return this.authService.checkRoles(['HOMEOFFICE'])
  }

  self.$onInit = function() {
    self.application.owners = self.application.owner
    POLICY_RELATIVE_TYPES.forEach(self._setUnsubmittedRelativeName)
    self.advisorName = self.application.primaryServicingAgent[0]
    self.type = self._determineUnsubmittedTypeLabelValue(self.application)
    self.isHomeOffice = self._isHomeOffice()
  }
}

export {WgUnsubmittedItem, WgUnsubmittedItemController}

function WgUnsubmittedItem(policyUtils) {
  return {
    restrict: 'EA',
    scope: {
      application: '=',
    },
    templateUrl: 'app/widgets/wg-unsubmitted-item/wg-unsubmitted-item.html',
    controller: WgUnsubmittedItemController,
    controllerAs: 'ctrl',
    bindToController: true,
    link: (scope) => { scope.policyUtils = policyUtils },
  }
}
