/* eslint-disable no-underscore-dangle */

import { IPromise } from 'angular'
export class MfaDataProvider {
  static $inject = ['$http', 'MFA_ENDPOINTS', 'authService', '$cookies']

  userGuid: string
  rememberedDevice: string | any | null

  constructor(private $http: angular.IHttpService, private MFA_ENDPOINTS, authService, private $cookies) {
    this.userGuid = authService.getSubGuid()
    this.rememberedDevice = this.getRememberedDevice()
  }

  _replaceUrlParameters(url: string, params: any): string {
    const newUrl = Object.keys(params).reduce((acc: string, key: string) => {
      const param: any = params[key]

      if (param) acc = acc.replace(key, param)

      return acc
    }, url)

    return newUrl
  }

  isMfaVerified() {
    return this.$cookies.get('mfaVerified')
  }

  checkMfaVerification(tokenType: string, returnValues?: any): IPromise<boolean> {
    const url: string = `/mfa/verify-token/${tokenType}${returnValues ? `?returnState=${returnValues.returnState}&returnUrl=${returnValues.returnUrl}` : ''}`

    return this.$http.get(url)
      .then((resp: any) => resp.status === 200)
      .catch(() => false)
  }

  getRememberedDevice(): any {
    let rememberedDevice: string | any = localStorage.getItem('rememberedDevice') || null

    if (typeof rememberedDevice === 'string') {
      rememberedDevice = JSON.parse(rememberedDevice)
    }

    return rememberedDevice
  }

  saveRememberedDevice(rememberedDevice:  any): void {
    localStorage.setItem('rememberedDevice', JSON.stringify(rememberedDevice))
  }

  getMfaStatus(): IPromise<any> {
    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.GET_STATUS, { ':guid': this.userGuid })
    const deviceTokenList = this.rememberedDevice ? [this.rememberedDevice.token] : []

    return this.$http.post<IMfaStatusResponse>(url, deviceTokenList)
      .then(result => result.data)
      .catch(err => console.log(err))
  }

  listPointsOfContact(): IPromise<any> {
    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.POC_LIST, {':guid': this.userGuid})

    return this.$http.get<IPointOfContact[]>(url)
      .then(result => result.data)

  }

  addPointOfContact(pocInfo: IPOCInfo): IPromise<any> {

    if (!pocInfo.phoneNumber) throw new RangeError('phoneNumber is required parameter.')

    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.POC_ADD, { ':guid': this.userGuid })

    return this.$http.post<IPointOfContact[] | IMFAError>(url, { pocInfo })
      .then(result => result.data)

  }

  deletePointOfContact(pocGuid): IPromise<any> {
    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.POC_DELETE, { ':guid': this.userGuid, ':pocGuid': pocGuid })

    return this.$http.delete<IPointOfContact[] | IMFAError>(url)
      .then(result => result.data)
  }

  requestVerificationCode(pocGuid: string, mfaMethod: string): IPromise<any> {

    if (!pocGuid) throw new RangeError('pocGuid is required parameter.')
    if (!mfaMethod) throw new RangeError('mfaMethod is required parameter.')

    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.CODE_REQUEST, { ':guid': this.userGuid, ':pocGuid': pocGuid })

    return this.$http.post<any | IMFAError>(url, { method: mfaMethod })
      .then(result => result.data)
  }

  verifyCode(pocGuid: string, code: string, tokenType?: string): IPromise<any> {

    if (!pocGuid) throw new RangeError('pocGuid is required parameter.')
    if (!code) throw new RangeError('code is required parameter.')

    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.CODE_VERIFY, { ':guid': this.userGuid, ':pocGuid': pocGuid })

    return this.$http.post<any | IMFAError>(url, { code, tokenType })
      .then(result => {
        return result.data
      })
  }

  trustDevice(nickname: string = ''): IPromise<any> {
    const url = this._replaceUrlParameters(this.MFA_ENDPOINTS.DEVICE_TRUST, { ':guid': this.userGuid })

    return this.$http.post<any | IMFAError>(url, { nickname })
      .then(result => {
        this.saveRememberedDevice(result.data)
      })
  }

}
