export {appRun}

appRun.$inject = ['routerHelper']

  /* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getStates())
}

function setClientStatusTitle($rootScope) {
  $rootScope.state = 'client'
  $rootScope.pageTitle = 'My Policies'
  $rootScope.mobileHeaderTitle = 'My Policies'
  $rootScope.isPopupVisible = false
}

function getStates() {
  const homeStateName = 'client.me'
  const profileStateName = 'client.me.profile'
  const changePasswordStateName = 'client.me.changePassword'

  return [{
    state: homeStateName,
    config: {
      url: '/client/me',
      templateUrl: 'app/client/me/client-me-view.html',
      controller: 'ClientController',
      controllerAs: 'client',
      clientAppOnly: true,
      data: {
        secure: true,
      },
      resolve: {
        messages: ['authService',
          function(authService) {
            return authService.getSystemMessages()
          },
        ],
        payablePolicies: ['clientMeService',
          function(clientMeService) {
            return clientMeService.getPayablePolicies()
          },
        ],
      },
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.hideBarHeader = false
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'resetBirthdayAlert',
    config: {
      url: '/reset-birthday-alert',
      template: '<h1>Reset Birthday notification successful.</h1><p>Now you can change the user\'s birthday just like magic. Do you believe in unicorns now?</p><label>Change the user\'s birthday to:</label><input type="date" ng-model="fakeBirthday"/><button ng-click="updateFakeBirthday()">Submit</button>',
      clientAppOnly: true,
      data: {
        secure: true,
      },
      controller: ['userAccountService', '$scope', 'dateUtils', function(userAccountService, $scope, dateUtils) {
        $scope.updateFakeBirthday = function() {
          console.log('yolo')
          userAccountService.fakeBirthday = new Date(dateUtils.parseDate($scope.fakeBirthday))
        }
        function init() {
          userAccountService.setLastBirthdayAlert(null)
        }
        init()
      }],
    },
  },
  {
    state: changePasswordStateName,
    config: {
      url: '/change-password',
      templateUrl: 'app/profile/change-password-view.html',
      controller: 'ChangePasswordController',
      controllerAs: 'ctrl',
      data: {
        secure: true,
      },
      clientAppOnly: true,
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.pageTitle = 'Change Password'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
      resolve: {
        previousState: ['$state', function($state) {
          const prevParams = {}

          if (!$state.current.name || $state.current.name === profileStateName) {
            return {name: homeStateName, params: {}}
          }

          Object.assign(prevParams, $state.params)

          return {name: $state.current.name, params: prevParams}
        }],
      },
    },
  },
  {
    state: 'client.me.correspondence',
    config: {
      url: '/correspondence',
      templateUrl: 'app/correspondence/correspondence-view.html',
      controller: 'CorrespondenceController',
      controllerAs: 'correspondenceCtrl',
      data: {
        secure: true,
      },
      clientAppOnly: true,
      resolve: {
        pids: ['$stateParams', 'clientMeService', 'clientAdvisorService', function($stateParams, clientMeService, clientAdvisorService) {
          return clientMeService
              .getClientInfo($stateParams.id)
              .then(clientAdvisorService.getClientInfoPolicyIds)
        }],
        correspondenceResult: ['pids', 'correspondenceService',
          function(pids, correspondenceService) {
            return correspondenceService.getCorrespondences(pids)
          },
        ],
        taxDocuments: [() => []], // CP does not need this injected, but CorrespondenceController shared with insight.
      },
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.state = 'client-correspondence'
        $rootScope.pageTitle = 'Client Correspondence'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.me.taxdocs',
    config: {
      url: '/taxdocs',
      templateUrl: 'app/client/tax-docs/tax-docs-view.html',
      controller: 'TaxDocumentController',
      controllerAs: 'ctrl',
      data: {
        secure: true,
      },
      clientAppOnly: true,
      resolve: {
        isMobilePlatform: ['deviceUtils',
          function(deviceUtils) {
            return deviceUtils.isMobilePlatform()
          },
        ],
      },
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.state = 'client-taxdocs'
        $rootScope.pageTitle = 'Tax Documents'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.me.contact',
    config: {
      url: '/contact-us',
      templateUrl: 'app/contact/contact-view.html',
      controller: 'ContactController',
      controllerAs: 'contactCtrl',
      data: {
            // addToNavigation: true,
        secure: true,
      },
      clientAppOnly: true,
      resolve: {
        apiResult: ['$stateParams', 'lazyLoadingDataService',
          function($stateParams, lazyLoadingDataService) {
            return lazyLoadingDataService.myPolicies()
                .then(function(result) {
                  return result
                })
          },
        ],
        contactResult: ['$stateParams', 'apiResult',
          function($stateParams, apiResult) {
            return apiResult
          },
        ],
        statesResult: ['contactResult',
          function(result) {
            if (result.client && result.client.policiesInfo && result.client.policiesInfo.states.length) {
              const states = []

              result.client.policiesInfo.states.forEach((stateObj) => {
                states.push(stateObj.state)
              })

              return states
            }
          },
        ],
      },
      onEnter: ['$rootScope', function($rootScope) {
            // $rootScope.state = "client-contact";
        $rootScope.pageTitle = 'Client Contact Us'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: profileStateName,
    config: {
      url: '/my-profile',
      templateUrl: 'app/profile/change-profile-view.html',
      controller: 'ChangeProfileController',
      controllerAs: 'ctrl',
      data: {
        secure: true,
      },
      clientAppOnly: true,
      resolve: {
        // previousState: ['$state', function($state) {
        //   const prevParams = {}

        //   if (!$state.current.name || $state.current.name === profileStateName) {
        //     return {name: homeStateName, params: {}}
        //   }

        //   Object.assign(prevParams, $state.params)

        //   return {name: $state.current.name, params: prevParams}
        // }],
      },
      onEnter: ['$rootScope', '$state', function($rootScope, $state) {
        $state.preProfileLocationHash = window.location.hash
        $rootScope.pageTitle = 'My Profile'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.me.accounts',
    config: {
      url: '/payment-accounts',
      templateUrl: 'app/payment/accounts-view.html',
      controller: 'AccountsController',
      controllerAs: 'accountsCtrl',
      data: {
        secure: true,
      },
      resolve: {
        accountsResult: ['clientService',
          function(clientService) {
            return clientService.getPaymentAccounts(false)
          },
        ],
      },
      clientAppOnly: true,
      onEnter: ['$rootScope', '$state', function($rootScope, $state) {
        $state.preProfileLocationHash = window.location.hash
        $rootScope.pageTitle = 'Payment Options'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  {
    state: 'client.me.manageAddresses',
    config: {
      url: '/manage-addresses',
      templateUrl: 'app/client/address/manage-addresses.html',
      controller: 'ManageAddressesController',
      controllerAs: 'manageAddressesCtrl',
      data: {
        secure: true,
      },
      resolve: {
        addressesResult: ['clientAddressService', '$stateParams', (clientAddressService, $stateParams) => clientAddressService.getAddresses($stateParams.id),
        ],
      },
      clientAppOnly: true,
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.pageTitle = 'Manage Addresses'
        $rootScope.state = 'manage-addresses'
      }],
      onExit: ['$rootScope', function($rootScope) {
        setClientStatusTitle($rootScope)
      }],
    },
  },
  ]
}
