import angular, { IController, IScope } from "angular"
import { Beneficiary } from "../../beneficiaries/classes/beneficiary"
import { ClientBeneficiariesController, ISaveResult } from "../client-bene.controller"
import { ITypeCodeValue } from "../../beneficiaries/types"
import { TypeCodeValue } from "../../beneficiaries/classes/type-code-value"
import { CLIENT_BENEFICIARY_VIEW_STATES } from "../../beneficiaries/constants/BENEFICIARY_VIEWS"
import { BENEFICIARY_ROLES } from "../../beneficiaries/constants/ROLES"
import { BENEFICIARIES_DIST_TYPES } from "../../beneficiaries/beneficiary-constants"

const byRepresentationIncludedRoles = ['5', '6', '1012300038']
const PER_CAPITA_INDEX = '0'
const PER_CAPITA_TC = '1'

class ClientBeneEditController implements IController {
  static $inject: any = ['$scope', '$state', '$element']

  BENEFICIARY_ROLES: any = BENEFICIARY_ROLES
  clientBeneMainCtrl: ClientBeneficiariesController
  bene: Beneficiary
  tmpBene: String
  distTypeTypeCodes: any
  viewState: any = CLIENT_BENEFICIARY_VIEW_STATES.EDIT
  showDistMethod: boolean = false
  distributionMethod: { value: string; message: string }

  constructor(private $scope: any, private $state: any, private $element: any) {
    this.clientBeneMainCtrl = this.$scope.$parent.clientBeneMainCtrl
    this.bene = this.clientBeneMainCtrl.currentBeneficiary
    // this.tmpBene = JSON.stringify(this.bene, null, 4)

    this.distTypeTypeCodes = this.clientBeneMainCtrl.distTypeTypeCodes
  }

  get isFinalBeneficiary(): boolean {
   return  this.BENEFICIARY_ROLES.FINAL === this.bene.roleId
  }

  private saveBeneficiary(): any {
    const result: ISaveResult = this.clientBeneMainCtrl.saveBeneficiaryChanges()

    if (result.success) {
      this.$state.go('^.list')
    } else {
      console.error('TODO: let user know that the save failed.', result.errorMessage)
    }
  }

  saveChanges(beneEditorForm: any): any {
    if (beneEditorForm?.addressForm?.$dirty) {
      this.clientBeneMainCtrl.validateAddress(this.bene).then((result) => {
        this.bene.address = result
        this.saveBeneficiary()
      }).catch(() => angular.noop())
    } else {
      this.saveBeneficiary()
    }
  }

  cancelChanges(saveButtonDisabled: Boolean): void {
    if (!saveButtonDisabled) {
      this.clientBeneMainCtrl.openWarningDialog().then(() => {
        this.clientBeneMainCtrl.setCurrentBeneficiary()
        this.$state.go('^.list')
      })
      .catch(() => {
        this.$element[0].scrollIntoView()
      })
    } else {
      this.clientBeneMainCtrl.setCurrentBeneficiary()
      this.$state.go('^.list')
    }
  }

  $onInit(): void {
    // This condition occurs when the user click the browser's refresh button
    if (!this.$scope.$parent.clientBeneMainCtrl.currentBeneficiary) {
      this.$state.go('^.list')

      return
    }

    const isPerCapita = this.bene.shareDistribution.distributionMethod.tc === PER_CAPITA_TC
    const setToPerCapita = isPerCapita || !this.bene.shareDistribution.distributionMethod.hasTypeCode

    if (this.bene.isNew || !this.bene.isNew && setToPerCapita) {
      this.showDistMethod = false
      this.bene.shareDistribution.distributionMethod = new TypeCodeValue(BENEFICIARIES_DIST_TYPES[PER_CAPITA_INDEX])
    } else if (!this.bene.isNew && !setToPerCapita) {
      this.showDistMethod = true
      this.distributionMethod = { value: this.bene.shareDistribution.distributionMethod.value, message: 'If you need to change the distribution method, please contact Penn Mutual support.' }
    }
  }
}

export const clientBeneEditComponentConfig = {
  templateUrl: 'app/client/client-bene-edit/edit-route/full-editor.html',
  controller: ClientBeneEditController,
  controllerAs: 'clientBeneEditCtrl',
  bindings: {
    beneficiaryMetaData: '<',
  },
}
