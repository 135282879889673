import * as angular from 'angular'
import { WgAlertSearchFormDirective } from '../alert/search-form/directive'
import { GoogleAnalyticsService, GaTrackerDirective } from '../components/google-analytics'
import { findOrCreateModule } from '../ng-helper'

findOrCreateModule('app.directives', [])

import { allowValues } from './allow-values.directive'
import { focusMe } from './focus-me.directive'
import { InputNumberFormatDirective } from './input-number-format.directive'
import './pml-ng'
import { PmlNgFileUpload } from './pml-ng/file-upload/directive'
import { PmlNgListViewPager } from './pml-ng/list-view/pager/pager-directive'
import { PmlNgSelect } from './pml-ng/list-view/select/directive'
import './requirement-directive'
import { InputNumbersOnlyDirective } from './input-numbers-only.directive'
import { DecimalPlacesNumberInputDirective } from './decimal-places-number-input.directive'

angular
.module('app.directives')
.directive('allowValues', ['valueCheckers', allowValues])
.directive('focusMe', ['$timeout', focusMe])
.directive('format', InputNumberFormatDirective.factory())
.directive('pmlNgFileUpload', PmlNgFileUpload.factory())
.directive('pmlNgListViewPager', PmlNgListViewPager.factory())
.directive('listViewSelect', PmlNgSelect.factory())
.service('googleAnalyticsService', GoogleAnalyticsService)
.directive('gaTracker', GaTrackerDirective.factory())
.directive('alertSearchForm', WgAlertSearchFormDirective.factory())
.directive('inputNumbersOnly', InputNumbersOnlyDirective.factory())
.directive('decimalPlaces', DecimalPlacesNumberInputDirective.factory())
