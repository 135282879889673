/**
 * <wg-alert-table />
 *
 * ##### Remarks:
 *
 * - Receives scope parameters passed declaratively from the implementing pages.
 */
export class WgAlertTableDirective implements ng.IDirective {

  restrict = 'E'
  scope = {
    onlyPolicies: '=?',
    currentCategory: '=?category',
    policyId: '=?',
    orphaned: '=?',
    startdate: '=?',
    enddate: '=?',
    clientName: '=?',
    client: '=?',
    activityCategory: '=?',
    error: '=?errorMessage',
    ignoreOrphanedFlag: '=?',
    alertsCtrl: '=',
  }
  transclude = true
  controller = 'AlertTableController'
  controllerAs = 'alertTable'
  templateUrl = 'app/alert/tmpl/wg-alert-table.html'

  link(scope, _el, _attrs) {
    scope.isEmptyState = scope.alertTable.areAlertsEmpty() && !scope.alertTable.loadingMore // && scope.alertTable.viewingSearch()

    // Watch for changes to the alertTable controller's alerts property. When it chages
    // update the hasAlerts property.
    const watchListener = (n, o, s) => {
      if (n !== undefined && o !== undefined /*&& n.length !== o.length*/) {
        s.hasAlerts = s.alertTable.alerts.length > 0
        s.isEmptyState = !s.hasAlerts && !s.alertTable.loadingMore // && s.alertTable.viewingSearch()
        // console.debug(">>>>>> s.isEmptyState:", s.isEmptyState, s.alertTable.alerts.length, s.hasAlerts, s.alertTable.loadingMore /*, s.alertTable.viewingSearch()*/)
      }
    }

    const clearWatch1 = scope.$watch('alertTable.alerts', watchListener, true)
    const clearWatch2 = scope.$watch('alertTable.loadingMore', watchListener, true)

    // Cleanup watches
    scope.$on('$destroy', () => {
      if (clearWatch1) clearWatch1()
      if (clearWatch2) clearWatch2()
    })

  }

  /**
   * Creates an instance of WgAlertTableDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new WgAlertTableDirective()

    directive.$inject = []

    return directive
  }

}
