import * as angular from 'angular'
import {AlertService} from '../alert-service'
export class AlertSearchForm implements IAlertSearchParams {
  insured: string = ''
  pid: string = ''
  orphaned: boolean = false
  startdate: Date | null = null
  enddate: Date | null = null
  activityCategory: string = ''
}
export class WgAlertSearchFormDirective implements ng.IDirective {

  restrict = 'E'
  scope = {
    alertsCtrl: '=',
    alertTable: '=',
  }
  transclude = true
  replace = true
  templateUrl = 'app/alert/tmpl/alert-search-form.html'

  constructor(private authService, private alertService: AlertService, private $filter: angular.IFilterFunction) {}

  link(scope, _el, _attrs) {

    // Initialize Search Form
    scope.searchForm = new AlertSearchForm()

    // Set default Activity option
    scope.selectedActivityOption = { id: 'default', name: 'Select', startDate: null, endDate: null }

    // Set default category
    scope.showForm = scope.alertsCtrl.currentCategory === ''

    // Initialize Filter Categories data
    if (this.alertService.categories) {
      scope.categories = Object.keys(this.alertService.categories).map(category => {
        return {key: category, value: this.$filter('capitalizeWithOverrides')(category, ['MEC'])}
      })

      scope.categories.unshift({key: '', value: 'All Alert Categories'})  // Adding to top of list
      scope.searchForm.activityCategory = scope.categories[0].key
    }

    // Initialize Date configuration options
    scope.inlineOptions = {
      customClass: (data) => {
        const date = data.date
        const mode = data.mode

        if (mode === 'day') {
          const dayToCheck = new Date(date).setHours(0, 0, 0, 0)

          for (let i = 0; i < scope.events.length; i++) {
            let currentDay = new Date(scope.events[i].date).setHours(0, 0, 0, 0)

            if (dayToCheck === currentDay) {
              return scope.events[i].status
            }
          }
        }

        return ''
      },
      minDate: new Date(),
      showWeeks: true,
    }

    scope.dateFromOptions = {
      formatDay: 'd',
      formatYear: 'yy',
      formatDayHeader: 'EEE',
      showWeeks: false,
    }

    scope.dateToOptions = {
      formatDay: 'd',
      formatYear: 'yy',
      showWeeks: false,
    }

    scope.inlineOptions.minDate = scope.inlineOptions.minDate ? null : new Date()
    scope.dateFromOptions.minDate = scope.inlineOptions.minDate

    // Enable/Disable Orphan filter.
    scope.hideUnassignedInput = this.authService.isInRole('AGENT') || this.authService.isInRole('ASSISTANT') || this.authService.isInRole('HTK-ASSISTANT')

    // ***** directive fuctions *****
    scope.search = () => {
      return scope.$root.$broadcast('executeSearch', scope.searchForm, scope.hideUnassignedInput)
    }

    scope.reset = () => {
      scope.searchForm = new AlertSearchForm()
      scope.selectedActivityOption = { id: 'default', name: 'Select', startDate: null, endDate: null }
      scope.search()
    }

    scope.$watch('selectedActivityOption', () => {

      if (!scope.selectedActivityOption) {
        return
      }

      if (scope.selectedActivityOption.id === 'default') {
        scope.startdate = null
        scope.enddate = null
      } else {
        scope.searchForm.startdate = scope.selectedActivityOption.startDate
        scope.searchForm.enddate = scope.selectedActivityOption.endDate
      }
    })
  }

  /**
   * Creates an instance of WgAlertSearchFormDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = (authService, alertService: AlertService, $filter: angular.IFilterFunction) => new WgAlertSearchFormDirective(authService, alertService, $filter)

    directive.$inject = ['authService', 'alertService', '$filter']

    return directive
  }

}
