export class ApplicationFilesController {
  static $inject: any = ['$element', '$scope']

  validFileTypes: string[]
  chosenFiles: File[]
  maxFileSize: number
  fileList: any
  fileSelector: any
  browse: Function

  constructor(private $element, private $scope) {}

  onFilesDropped() {
    if (this.chosenFiles.length > 0) {
      this.fileList.removeClass('hidden')
      this.fileSelector.removeClass('hovering')
      this.fileSelector.addClass('hidden')
      this.fileSelector.addClass('auto-size')
    } else {
      this.onAllFilesRemoved()
    }
  }

  onAllFilesRemoved() {
    this.fileList.addClass('hidden')
    this.fileSelector.removeClass('hidden')
    this.fileSelector.removeClass('hovering')
    this.fileSelector.removeClass('auto-size')
  }

  removeAll(e) {
    this.chosenFiles.splice(0, this.chosenFiles.length)
    this.$scope.$broadcast('all-files-removed', e)
  }

  $onInit() {
    this.fileList = this.$element.find('file-list')
    this.fileSelector = this.$element.find('file-selector')

    this.$scope.$on('all-files-removed', () => this.onAllFilesRemoved())
    this.fileList.on('dragenter', () => {
      this.fileSelector.removeClass('hidden')
      this.fileSelector.addClass('hovering')
      this.$scope.$apply()
    })

  }
}
