/* Help configure the state-base ui.router */

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

routerHelperProvider.$inject = ['$provide', '$stateProvider', '$urlServiceProvider']

  /* @ngInject */
function routerHelperProvider($provide, $stateProvider, $urlServiceProvider) {
    /* jshint validthis:true */
  const config = {
    resolveAlways: {},
  }

  this.configure = function(cfg) {
    angular.extend(config, cfg)
  }

  this.$get = RouterHelper
  RouterHelper.$inject = ['$window', '$location', '$rootScope', '$state', 'loggingService']
    /* @ngInject */
  function RouterHelper($window, $location, $rootScope, $state, loggingService) {
    let handlingStateChangeError = false
    let hasOtherwise = false
    const stateCounts = {
      errors: 0,
      changes: 0,
    }

    const service = {
      configureStates: configureStates,
      getStates: getStates,
      stateCounts: stateCounts,
    }

    init()

    return service

      /// ////////////

    function configureStates(states, otherwisePath) {
      states.forEach(function(state) {
        if (state.config.resolve) {
          state.config.resolve = angular.extend(state.config.resolve || {}, config.resolveAlways)
        }

        $stateProvider.state(state.state, state.config)
      })

      if (otherwisePath && !hasOtherwise) {
        hasOtherwise = true
        $urlServiceProvider.rules.otherwise(otherwisePath)
      }
    }

    function handleRoutingErrors() {
        // Route cancellation:
        // On routing error, go to the dashboard.
        // Provide an exit clause if it tries to do it twice.
      $rootScope.$on('$stateChangeError',
        function(event, toState, toParams, fromState, fromParams, error) {
          let destination, msg

          if (handlingStateChangeError) {
            return
          }
          stateCounts.errors++
          handlingStateChangeError = true
          destination = (toState &&
                (toState.title || toState.name || toState.loadedTemplateUrl)) ||
              'unknown target'
          msg = 'Error routing to ' + destination + '. ' +
              (error.data || '') + '. <br/>' + (error.statusText || '') +
              ': ' + (error.status || '')

          loggingService.log(msg + toState, 'warn')
          $location.path('/')
        }
      )
    }

    function init() {
      handleRoutingErrors()

      $provide.decorator('$uiViewScroll', function() {
        return function() {
          $window.scrollTo(0, 0)
        }
      })
    }

    function getStates() {
      return $state.get()
    }
  }
}


export {routerHelperProvider, capitalize}