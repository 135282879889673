export {policyService}

policyService.$inject = ['$http', 'utils', 'dateUtils', 'clientUtils', 'policyUtils', 'CONSTANTS', 'partyService', '$rootScope', '$q', '$filter']

  /* @ngInject */
function policyService($http, utils, dateUtils, clientUtils, policyUtils, CONSTANTS, partyService, $rootScope, $q, $filter) {
  const service = {}

  service.policy = {}
  service.getPolicyTransactionHistory = getPolicyTransactionHistory

    // @DEPRECATED in favor of organizeClientsByRole
  service.getClientsByRole = function(id) {
    return service.getClients(id)
        .then(function(result) {
          const clients = {
            'owners': [],
            'jointOwners': [],
            'insureds': [],
            'payors': [],
          }

          if (!result.error && result.data.parties) {
            result.data.parties.forEach(function(client) {
              client.roles.forEach(function(role) {
                const categorizedRole = service._categorizeRole(role.role.toLowerCase())

                if (clients[categorizedRole]) {
                  clients[categorizedRole].push(service._copyClientProperties(client))
                }
              })
            })
          }

          return clients
        })
  }

    /**
     *
     * @param {*} clients
     */
  service.organizeClientsByRole = function(clients) {
    const byRoles = {
      'owners': [],
      'jointOwners': [],
      'insureds': [],
      'payors': [],
    }

    if (clients && clients.parties) {
      clients.parties.forEach(function(client) {
        client.roles.forEach(function(role) {
          const categorizedRole = service._categorizeRole(role.role.toLowerCase())

          if (byRoles[categorizedRole]) {
            byRoles[categorizedRole].push(service._copyClientProperties(client))
          }
        })
      })
    }

    return byRoles
  }

  service.getClients = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }
    let url
    let promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyClientsURL.replace('{0}', id) : 'app/mock-api/clients.json'

    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())

    promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data ? httpData.data : {}

            result.data.parties = result.data.parties ? result.data.parties : []

            result.data.parties.forEach(function(client) {
              if (client) {
                client.birthDate = dateUtils.parseDate(client.birthDate)
                client.calculatedAge = client.birthDate ? dateUtils.getYearsFrom(client.birthDate) : client.age
              }
            })

            return result
          }

          result.error = 'Undefined response'
          return result
        }, function(httpData) {
          utils.fillAndLogError(httpData, result)

          return result
        })

    return promise
  }

  service.getClientsRaw = function(policyId) {
    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyClientsURL.replace('{0}', policyId) : 'app/mock-api/clients.json'

    return $http.get(url)
  }

  service.getPolicyLoanQuotes = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyLoanQuotes': [],
    }

    let url
    let promise

    url = CONSTANTS.policyLoanQuoteURL.replace('{0}', id)

    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.policyLoanQuotes = httpData.data.maxLoanQuoteModels

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.getPolicyCoverages = function(id, policySource) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }

    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyCoveragesURL.replace('{0}', policySource)
        .replace('{1}', id) : 'app/mock-api/policy-coverages.json'

    const promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data

            if (result.data.coverage && result.data.coverage.length > 0) {
              const deathBenefitCoverage = result.data.coverage.find((coverage) => coverage.typeCode === 'Death Benefit')

              result.data.rider = result.data.coverage[0]
              result.data.rider.effDate = dateUtils.parseDate(result.data.rider.effDate)
              result.data.rider.termDate = dateUtils.parseDate(result.data.rider.termDate)
              result.data.coverage.forEach((coverage) => {
                if (!coverage.lastStepUpDate && deathBenefitCoverage) {
                  coverage.lastStepUpDate = deathBenefitCoverage.lastStepUpDate
                }
              })
            } else {
              result.data.rider = {}
            }

            return result
          }
          result.error = 'Undefined response'
          return result
        }, function(httpData) {
          utils.fillAndLogError(httpData, result)

          return result
        })

    return promise
  }

  service.policyAgentsDelivered = false
  service.getPolicyAgents = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }

    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyAgentsURL.replace('{0}', id) : 'app/mock-api/policy-agents.json'

    const promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data
            service.policyAgentsDelivered = true
            return result
          }
          result.error = 'Undefined response'
          service.policyAgentsDelivered = true
          return result
        }, function(httpData) {
          utils.fillAndLogError(httpData, result)
          service.policyAgentsDelivered = true
          return result
        })

    return promise
  }

  service.getAvailablePolicyFunds = function(id) {
    const url = CONSTANTS.policyAvailableFundsURL.replace('{0}', id)

    return $http.get(url)
        .catch(angular.identity)
  }

  service.getPolicyFunds = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }
    let url, promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyFundsURL.replace('{0}', id) : 'app/mock-api/policy-funds.json'

    promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data
            return result
          }

          result.error = 'Undefined response'
          return result
        }, function(httpData) {
          utils.fillAndLogError(httpData, result)
          return result
        })

    return promise
  }

    // TODO move these to fundTransferService

  service.getPolicyCanAllocateFunds = function(id) {
    const url = CONSTANTS.policyCanAllocateFundsURL.replace('{0}', id)

    return $http.get(url)
  }

  service.getPolicyCanTransferFunds = function(id) {
    const url = CONSTANTS.policyCanTransferFundsURL.replace('{0}', id)

    return $http.get(url)
  }

  service.transferFunds = function(id, fundParams) {
    const url = CONSTANTS.policyTransferFundsURL.replace('{0}', id)

    return $http.post(url, fundParams)
  }

  service.getTaxInfo = function(id) {
    return $http.get(CONSTANTS.taxInfoURL.replace('{0}', id))
  }

  service.taxDocumentSearch = function taxDocumentSearch() {
    if (taxDocumentSearch.cache) {
      return $q.resolve(taxDocumentSearch.cache)
    }

    return $http.get(`${CONSTANTS.apiRoot}document/policy/search/tax`)
        .then(result => {
          taxDocumentSearch.cache = result

          return result
        })
  }

  service._policyTaxDocs = {}
  service.policyTaxDocumentSeach = function(pid) {
    const cachedTaxDoc = service._policyTaxDocs[pid]

    if (cachedTaxDoc) {
      return $q.resolve(cachedTaxDoc)
    }

    return $http.get(`${CONSTANTS.apiRoot}document/policy/search/tax/` + pid)
        .then(result => {
          if (result.status === 204) {
            return []
          }

          service._policyTaxDocs[pid] = result.data && result.data.documents

          return service._policyTaxDocs[pid]
        })
        .catch((err) => console.error(err))
  }

    // TODO rename these gets
  function getAAR(arrangement, arrangementItem) {
    if (arrangementItem.arrType && CONSTANTS.automaticAssetRebalanceTypes.indexOf(arrangementItem.arrType.toLowerCase()) !== -1) {
      if (arrangementItem.arrSubType && CONSTANTS.standardAssetRebalancing.indexOf(arrangementItem.arrSubType.toLowerCase()) !== -1) {
        arrangement.annuityAAR = arrangementItem.arrStatus
      }
    }
  }

  function getSystematicWithdrawal(arrangement, arrangementItem) {
    if (arrangementItem.arrType && CONSTANTS.annuitySystematicWithdrawalTypes.indexOf(arrangementItem.arrType.toLowerCase()) !== -1) {
      arrangement.systematicWithdrawal = arrangementItem.arrStatus

      if (arrangementItem.arrStatus && arrangementItem.arrStatus.toLowerCase()
          .indexOf('active') !== -1) {
        arrangement.nextSystematicWithdrawalDate = arrangementItem.nextActivityDate
        arrangement.endingSystematicWithdrawalDate = arrangementItem.endDate
      }
    }
  }

  function getDCA(arrangement, arrangementItem) {
    if (arrangementItem.arrType && CONSTANTS.dollarCostAveragingTypes.indexOf(arrangementItem.arrType.toLowerCase()) !== -1) {
      arrangement.annuityDCA = arrangementItem.arrStatus
    }
  }

  service._setAAREndDate = function(arrangement, arrangementItem) {
    if (arrangementItem.arrType === 'Asset Reallocation' && arrangementItem.endDate) {
      arrangement.AAREndDate = arrangementItem.endDate
    }
  }

  service._setDCAEndDate = function(arrangement, arrangementItem) {
    if (arrangementItem.arrType === 'Dollar Cost Averaging' && arrangementItem.endDate) {
      arrangement.DCAEndDate = new Date(arrangementItem.endDate)
          .toLocaleDateString('en-US', {
            day: 'numeric',
            year: 'numeric',
            month: 'short',
          })
    }
  }

  service._automaticAssetRebalanceMessage = function(newArrangement) {
    let endDate

    if (newArrangement.annuityAAR === 'Active' && newArrangement.AAREndDate) {
      endDate = Date.parse(newArrangement.AAREndDate)

      return 'Active. Ends ' + $filter('date')(endDate, 'MMM d, yyyy')
    }

    return newArrangement.annuityAAR
  }

  service._categorizeRole = function(lowerCaseRole) {
    if (lowerCaseRole === CONSTANTS.clientRoles.owner || lowerCaseRole === CONSTANTS.clientRoles.assignee) {
      return 'owners'
    }
    if (lowerCaseRole === CONSTANTS.clientRoles.jointOwner) {
      return 'jointOwners'
    }
    if (lowerCaseRole === CONSTANTS.clientRoles.payor || lowerCaseRole === CONSTANTS.clientRoles.payor2) {
      return 'payors'
    }
    if (lowerCaseRole.indexOf(CONSTANTS.clientRoles.insured) !== -1 ||
        lowerCaseRole.indexOf(CONSTANTS.clientRoles.annuitant) !== -1) {
      return 'insureds'
    }
    return null
  }

  service._copyClientProperties = function(client) {
    return {
      'id': client.id,
      'fullName': clientUtils.partyName(client),
      'birthDate': client.birthDate,
      'partyType': client.partyType,
      'relatable': client.relatable,
    }
  }

  service._dollarCostAveragingMessage = function(newArrangement) {
    if (newArrangement.annuityDCA === 'Active' && newArrangement.DCAEndDate) {
      return 'Active. Ends ' + newArrangement.DCAEndDate
    }

    return newArrangement.annuityDCA
  }

  service._createArrangementByList = function(arrangements) {
    let newArrangement = null

    if (arrangements && arrangements.length) {
      newArrangement = {
        'annuityAAR': CONSTANTS.NO_LABEL,
        'systematicWithdrawal': CONSTANTS.NO_LABEL,
        'annuityDCA': CONSTANTS.NO_LABEL,
        'AAREndDate': null,
        'DCAEndDate': null,
      }

      arrangements.forEach(function(arrangementItem) {
        if (newArrangement.annuityAAR === CONSTANTS.NO_LABEL) {
          getAAR(newArrangement, arrangementItem)
        }

        if (newArrangement.systematicWithdrawal === CONSTANTS.NO_LABEL) {
          getSystematicWithdrawal(newArrangement, arrangementItem)
        }

        if (newArrangement.annuityDCA === CONSTANTS.NO_LABEL) {
          getDCA(newArrangement, arrangementItem)
        }

        if (newArrangement.AAREndDate === null) {
          service._setAAREndDate(newArrangement, arrangementItem)
        }

        if (newArrangement.DCAEndDate === null) {
          service._setDCAEndDate(newArrangement, arrangementItem)
        }
      })

      newArrangement.dollarCostAveragingMessage = service._dollarCostAveragingMessage(newArrangement)
      newArrangement.automaticAssetRebalanceMessage = service._automaticAssetRebalanceMessage(newArrangement)
    }

    return newArrangement
  }

  service.getPolicyArrangements = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }

    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyArrangementsURL.replace('{0}', id) : 'app/mock-api/policy-arrangements.json'

    const promise = $http.get(url)
        .then(function(httpData) {
          let nextSystDate, nextEndDate

          if (httpData.data) {
            result.data = httpData.data
            result.data.newArrangement = service._createArrangementByList(result.data.arrangement) || {}

            // Format date correctly
            nextSystDate = (result.data.newArrangement && result.data.newArrangement.nextSystematicWithdrawalDate) ? new Date(result.data.newArrangement.nextSystematicWithdrawalDate) : undefined
            nextEndDate = (result.data.newArrangement && result.data.newArrangement.endingSystematicWithdrawalDate) ? new Date(result.data.newArrangement.endingSystematicWithdrawalDate) : undefined

            result.data.newArrangement.nextSystematicWithdrawalDate = nextSystDate
            result.data.newArrangement.endingSystematicWithdrawalDate = nextEndDate

            return result
          }

          result.error = 'Undefined response'
          return result
        }, function(httpData) {
          utils.fillAndLogError(httpData, result)
          return result
        })

    return promise
  }

  service.getPolicyLoans = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'loans': {},
    }
    let url, promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyLoansURL.replace('{0}', id) : 'app/mock-api/policy-loans.json'

    promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.loans = httpData.data.loans
            return result
          }

          result.error = 'Undefined response'
          return result
        }, function(httpData) {
          utils.fillAndLogError(httpData, result)
          return result
        })

    return promise
  }

  service.getPolicyBase = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyBase': {},
    }
    let url, promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyBaseURL.replace('{0}', id) : 'app/mock-api/policy-base.json'

    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.policyBase = httpData.data

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.makePayment = function(id, payment) {
    const result = {
      'error': '',
      'data': {},
    }

    const initialPayment = {
      'tc': CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT,
      'amount': payment.conversionCreditAmt ? Number((payment.initialPaymentAmount - payment.conversionCreditAmt).toFixed(2)) : payment.initialPaymentAmount,
    }
    const puaPayment = {
      'tc': CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA,
      'amount': payment.additionsPaymentAmount,
    }
    const premiumPayment = {
      'tc': CONSTANTS.PAYMENT_TYPES.PREMIUM,
      'amount': payment.premiumPaymentAmount,
    }
    const loanPayment = {
      'tc': CONSTANTS.PAYMENT_TYPES.LOAN,
      'amount': payment.loanPaymentAmount,
    }

    const body = {
      'pennCheckDate': payment.pennCheckDate,
      'payments': [],
    }

    if (payment.accountId) {
      body.account = {
        'accountId': payment.accountId,
      }
    } else {
      body.saveAccountInfo = payment.saveAccountInfo // only use this when dealing with a new payment account
      body.account = {
        'nickname': payment.accountNickname,
        'accountNumber': payment.accountNumber,
        'accountRtn': payment.routingNumber,
        'accountType': payment.accountType.name || payment.accountType,
        'defaultAccount': true,
      }
    }

    if (initialPayment.amount) {
      body.payments.push(initialPayment)
    }

    if (premiumPayment.amount) {
      body.payments.push(premiumPayment)
    }

    if (loanPayment.amount) {
      body.payments.push(loanPayment)
    }

    if (payment.isTraditional && puaPayment.amount !== 0) { // traditional also has a PUA, add it if amount is non-zero
      body.payments.push(puaPayment)
    }

    let url = CONSTANTS.policyPaymentSubmissionURL.replace('{0}', id)

    if (payment.bypassSign) {
      url = url + '?bypassSign=true'
    }

    const promise = $http.post(url, body)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.data = httpData.data

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            result.errorMessage = httpData.data
            return result
          }
        )

    return promise
  }

  service._createPaymentPayload = function(payment) {
    const payments = {
      initialPayment: {
        'tc': CONSTANTS.PAYMENT_TYPES.INITIAL_PAYMENT,
        'amount': payment.conversionCreditAmt ? Number((payment.initialPaymentAmount - payment.conversionCreditAmt).toFixed(2)) : payment.initialPaymentAmount,
      },
      puaPayment: {
        'tc': CONSTANTS.PAYMENT_TYPES.PURCHASE_PUA,
        'amount': payment.additionsPaymentAmount,
      },
      premiumPayment: {
        'tc': CONSTANTS.PAYMENT_TYPES.PREMIUM,
        'amount': payment.premiumPaymentAmount,
      },
      loanPayment: {
        'tc': CONSTANTS.PAYMENT_TYPES.LOAN,
        'amount': payment.loanPaymentAmount,
      },
    }
    const paymentAccount = payment.initialPaymentAccount ?? payment.pennCheckPaymentAccount
    const pennCheckAccount = payment.pennCheckPaymentAccount
    const body = {
      payments: [],
    }

    if (pennCheckAccount) {
      body.pennCheckDate = pennCheckAccount.pennCheckDate
      body.pennCheckAccount = {
        'accountType': pennCheckAccount.accountType.tc,
        'accountRtn': pennCheckAccount.routingNumber,
        'accountNumber': pennCheckAccount.accountNumber,
      }
    }

    if (paymentAccount.accountId) {
      body.account = {
        'accountId': paymentAccount.accountId,
      }
    } else {
      body.saveAccountInfo = paymentAccount.saveAccountInfo // only use this when dealing with a new payment account
      body.account = {
        'nickname': paymentAccount.accountNickname,
        'accountNumber': paymentAccount.accountNumber,
        'accountRtn': paymentAccount.routingNumber,
        'accountType': paymentAccount.accountType.tc,
        'defaultAccount': true,  // TODO: Do we really want this to always default to true?
      }
    }

    if (payments.initialPayment.amount) {
      body.payments.push(payments.initialPayment)
    }

    if (payments.premiumPayment.amount) {
      body.payments.push(payments.premiumPayment)
    }

    if (payments.loanPayment.amount) {
      body.payments.push(payments.loanPayment)
    }

    if (payment.isTraditional && payments.puaPayment.amount !== 0) { // traditional also has a PUA, add it if amount is non-zero
      body.payments.push(payments.puaPayment)
    }

    return body
  }

  service.makeInitialPayment = function(id, payment) {
    const result = {
      'error': '',
      'data': {},
    }
    const body = service._createPaymentPayload(payment)
    let url = CONSTANTS.policyPaymentSubmissionURL.replace('{0}', id)

    if (payment.bypassSign) {
      url = url + '?bypassSign=true'
    }

    return $http.post(url, body)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.data = httpData.data

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            result.errorMessage = httpData.data
            return result
          }
        )

      // return Promise.resolve(result)
  }

  service.getPolicyPaymentDetails = function(id, bypassSign) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyPaymentDetails': {},
    }

    let url, promise

    url = CONSTANTS.policyPaymentDetailsURL.replace('{0}', id) + (bypassSign ? '?bypassSign=true' : '')
    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.policyPaymentDetails = httpData.data

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.getPolicyValues = function(id, asOfDateString) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyBase': {},
    }
    let url = CONSTANTS.policyValuesURL.replace('{0}', id)

    if (typeof asOfDateString === 'string') {
      url = utils.appendURLParameter(url, 'asOf', asOfDateString)
    }

    return $http.get(url)
        .then(
          function(httpData) {
            let deathBenefitRiders

            if (httpData.data) {
              result.policyValues = httpData.data
              deathBenefitRiders = result.policyValues.deathbenefit && result.policyValues.deathbenefit.riders

              if (deathBenefitRiders) {
                deathBenefitRiders.forEach(function(dbRider) {
                  if (dbRider.riderName) {
                    result.policyValues.deathbenefit['dbRider' + dbRider.riderName.split(' ').join('')] = {
                      riderName: dbRider.riderName,
                      riderDBAmt: dbRider.riderDBAmt,
                    }
                  }
                })
              }

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })
  }

  service.getPolicyTableByType = function(policyId, tableType, isCmsRider) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'tables': [],
    }

    let url, promise

    // cmsRider=true
    url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyTablesURL.replace('{0}', policyId)
        .replace('{1}', tableType) : 'app/mock-api/policy-base.json'

    url += '&cmsRider=' + isCmsRider.toString()

    console.log('>>>>', url)
    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.tables = httpData.data.tables

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.calcAnnualPayment = function(policy) {
    return policy.annualPaymentAmt
  }

  service.getSpecifiedAmountLabel = function(policy) {
    const productType = policy.productType

    if (productType === CONSTANTS.productType.term ||
        productType === CONSTANTS.productType.indeterminatePremiumLifeTerm ||
        productType === CONSTANTS.productType.wholeLife ||
        productType === CONSTANTS.productType.endowment ||
        productType === CONSTANTS.productType.indeterminatePremium) {
      return CONSTANTS.productTypeLabel.faceAmount
    } else if (productType === CONSTANTS.productType.universalLife ||
        productType === CONSTANTS.productType.IUL ||
        productType === CONSTANTS.productType.variableUniversalLife) {
      return CONSTANTS.productTypeLabel.specifiedAmount
    }

    if (policy.isPending) {
      return CONSTANTS.productTypeLabel.specifiedAmount
    }

    return ''
  }

  service.getPolicySummary = function(id, policySource, enforceRestrict = true, bypassSign = false) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policy': {
          // Do not remove this initialization. It's used when an error is raised.
        'polNumber': id,
        'type': policySource,
      },
    }

    let url = CONSTANTS.isDataServiceActive ? CONSTANTS.policySummaryURL.replace('{0}', id) : 'app/mock-api/policy-summary.json'

    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())

    if (bypassSign) url = utils.appendURLParameter(url, 'bypassSign', true)

    const promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              const policy = httpData.data

              result.policy = policy
              policy.isSuspended = policy.holdingStatus === 'Suspended'
              policy.isRestricted = CONSTANTS.policyRestrictionCodes.includes(policy.restrictionCode)

              if (policy.isRestricted ||
                (policy.isSuspended && !CONSTANTS.policyStatusExemptions.includes(policy.policyStatus))) {
                const restrictionMessage = policy.jurisdiction === 'New York' ? CONSTANTS.policyRestrictionNYErrorMessage : CONSTANTS.policyRestrictionErrorMessage

                if (enforceRestrict) {
                  result.error = restrictionMessage
                  result.status = '-1'
                  return result
                }
                result.status = '-2'
                result.restrictionCode = policy.restrictionCode
                result.warning = restrictionMessage
              }

              policy.source = policyUtils.getPolicySourceFromStatus(policy.holdingStatus, policy.source)
              policy.isPending = policyUtils.isPendingPolicy(policy)
              policy.isAnnuity = policy.lineOfBusiness && policy.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()

              policy.totalAnnualPayment = service.calcAnnualPayment(policy)
              policy.billedToDate = dateUtils.parseDate(policy.billedToDate)
              policy.deathBenefitAmt = policy.grossDeathBenefitAmt
              policy.asOfDate = dateUtils.parseDate(policy.asOfDate)
              policy.billingStopDate = dateUtils.parseDate(policy.billingStopDate)
              policy.issueDate = dateUtils.parseDate(policy.issueDate)
              policy.paidToDate = dateUtils.parseDate(policy.paidToDate)
              policy.termDate = dateUtils.parseDate(policy.termDate)
              policy.effDate = dateUtils.parseDate(policy.effDate)
              policy.anniversaryDate = clientUtils.getAnniversaryDate(policy.effDate)
              policy.paymentDueDate = dateUtils.parseDate(policy.paymentDueDate)
              policy.lastUnschedPremDate = dateUtils.parseDate(policy.lastUnschedPremDate)
              policy.yearsInForce = dateUtils.getYearsFrom(policy.effDate)
              policy.specifiedAmountLabel = service.getSpecifiedAmountLabel(policy)
              policy.isULProductType = policyUtils.isUniversalLifePolicy(policy.productType)
              policy.isStrictULProductType = policy.productType === CONSTANTS.productType.universalLife
              policy.isEndowmentProductType = policy.productType === CONSTANTS.productType.endowment
              policy.isAnnuityFixedProductType = policy.productType === CONSTANTS.productType.fixedAnnuity
              policy.currentBaseCoverage = policy.faceAmt
              policy.paymentAmountValue = policyUtils.getPaymentAmountValue(policy.isAnnuity, policy.faceAmt, policy.initPaymentAmt, policy.paymentAmt)
              policy.hoReceiptDate = dateUtils.parseDate(policy.hoReceiptDate)
              policy.submitDate = dateUtils.parseDate(policy.submitDate)
              policy.lastPremDate = dateUtils.parseDate(policy.lastPremDate)
              policy.conversionDate = dateUtils.parseDate(policy.conversionDate)
              policy.policyStatus = utils.firstToUpper(policy.policyStatus)
              policy.lastUpdateDate = policy.lastUpdateDate ? dateUtils.parseDate(policy.lastUpdateDate) : dateUtils.parseDate(policy.asOfDate)
              policy.reqMaturityDate = dateUtils.parseDate(policy.reqMaturityDate)
              policy.futurePaymentDueDate = dateUtils.parseDate(policy.futurePaymentDueDate)

              if (policy.nextPayoutDate) {
                policy.nextPayoutDate = dateUtils.parseDate(policy.nextPayoutDate)
              }

              if (policy.firstPayoutDate) {
                policy.firstPayoutDate = dateUtils.parseDate(policy.firstPayoutDate)
              }

              if (policy.requirementsStatus) {
                policy.requirementInfo = policyUtils.calcRequirementsMet(policy.requirementsStatus)
              }

              policy.clients = {}

              policy.clients.owners = []
              policy.clients.insureds = []
              policy.clients.payors = []
              policy.clients.beneficiaries = []

              if (policy.owners) {
                policy.owners.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.owners.push(client)
                })
              }

              if (policy.jointOwners) { // joint owners being counted as owners per WIT-48. Were previously ignored.
                policy.jointOwners.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.owners.push(client)
                })
              }

              if (policy.insureds) {
                policy.insureds.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.insureds.push(client)
                })
              }

              if (policy.annuitants) {
                policy.annuitants.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.insureds.push(client)
                })
              }

              if (policy.jointAnnuitants) {
                policy.jointAnnuitants.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.insureds.push(client)
                })
              }

              if (policy.jointInsureds) {
                policy.jointInsureds.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.insureds.push(client)
                })
              }

              if (policy.contingentAnnuitants) {
                policy.contingentAnnuitants.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.insureds.push(client)
                })
              }

              if (policy.coverageInsureds) {
                policy.coverageInsureds.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.insureds.push(client)
                })
              }

              if (policy.payors) {
                policy.payors.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.payors.push(client)
                })
              }

              if (policy.beneficiary) {
                policy.beneficiary.forEach(function(client) {
                  client.fullName = clientUtils.partyName(client)
                  policy.clients.beneficiary.push(client)
                })
              }

              $rootScope.asOfDate = dateUtils.parseDate(policy.asOfDate)
              $rootScope.carrierName = policy.carrierName

              $rootScope.$on('updateFooter', function() {
                $rootScope.carrierName = policy.carrierName
              })

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.pendingPolicyRequirementsDelivered = false
  service.getPendingPolicyRequirements = function(id) {
    return service.getRequirementsByIdAndPolicySource(id, CONSTANTS.policySource.pending, 'pendingPolicyRequirementsDelivered')
  }

  service.canSubmitAgainstRequirements = function(id) {
    return $q((resolve, reject) => {
      $http.get(`${CONSTANTS.apiRoot}policy/${id}/requirements/can/user/submit`)
          .then(result => resolve(result.data.elements || []))
          .catch(err => {
            switch (err.status) {
              case 400:
                resolve([])
                break

              default:
                reject(err)
                break
            }
          })
    })

      // return $http.get(`${CONSTANTS.apiRoot}policy/pending/${id}/requirements?`)
  }

  service.inforcePolicyRequirementsDelivered = false
  service.getInforcePolicyRequirements = function(id) {
    return service.getRequirementsByIdAndPolicySource(id, CONSTANTS.policySource.inForce, 'inforcePolicyRequirementsDelivered')
  }

  service.getRequirementsByIdAndPolicySource = function(id, policySource, deliveredMarker) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
      'polNumber': id,
    }

    let data
    let requirement

    const urlTemplate = policySource === CONSTANTS.policySource.pending ? CONSTANTS.pendingRequirementsURL : CONSTANTS.requirementsURL

    const url = CONSTANTS.isDataServiceActive ? urlTemplate.replace('{0}', id) : 'app/mock-api/policy-requirements.json'

    const promise = $http.get(url)
        .then(
          function(httpData) {
            let i, status, roles, partyName, partyNameFormatted, role

            if (httpData.data) {
              data = httpData.data

              for (i = 0; i < data.requirements.length; i++) {
                requirement = data.requirements[i]

                requirement.pastDue = service.isRequirementPastDue(requirement)
                requirement.statusDate = dateUtils.parseDate(requirement.statusDate)
                requirement.requestedDate = dateUtils.parseDate(requirement.requestedDate)
                requirement.receivedDate = dateUtils.parseDate(requirement.receivedDate)

                status = requirement.reqStatus ? '' : requirement.reqStatus.toLowerCase()

                requirement.receiptDate = (status === CONSTANTS.policyRequirementType.waived) ? '' : requirement.receivedDate

                roles = requirement?.relatedParty?.roles
                partyName = requirement?.relatedParty?.party
                partyNameFormatted = partyName ? clientUtils.firstNameFirst(partyName) : partyName

                if (roles && roles.length > 0) {
                  role = roles[0].role

                  if (role) {
                    requirement.appliesTo = partyNameFormatted ? role + ': ' + partyNameFormatted : role
                  }
                }
              }

              if (data.requirementsStatus) {
                data.requirementInfo = policyUtils.calcRequirementsMet(data.requirementsStatus)
              }

              result.data = data
              if (deliveredMarker) service[deliveredMarker] = true
              return result
            }

            result.error = 'Undefined response'
            if (deliveredMarker) service[deliveredMarker] = true
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            if (deliveredMarker) service[deliveredMarker] = true
            return result
          })

    return promise
  }

  service.isRequirementPastDue = function(requirement) {
    const today = new Date()
    const requestedDate = new Date(requirement.requestedDate)
    const days = dateUtils.dateDiffInDays(requestedDate, today)
    const twoWeeks = 14 // 14 days

    if (!requirement) {
      return
    }

    return days > twoWeeks && !requirement.statusDate
  }

  service.pendingPolicyApplicationDelivered = false
  service.getPendingPolicyApplication = function(id) {
    const result = {
      'error': '',
      'status': '',
      'policyApplication': {},
    }
    let url, promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.pendingPolicyApplicationURL.replace('{0}', id) : 'app/mock-api/pending-policy-application.json'

    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.policyApplication = httpData.data

              result.policyApplication.type = CONSTANTS.policySource.pending
              result.policyApplication.polNumber = id
              result.policyApplication.signedDate = dateUtils.parseDate(result.policyApplication.signedDate)
              result.policyApplication.hoReceiptDate = dateUtils.parseDate(result.policyApplication.hoReceiptDate)

              service.pendingPolicyApplicationDelivered = true
              return result
            }

            result.error = 'Undefined response'
            service.pendingPolicyApplicationDelivered = true
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            service.pendingPolicyApplicationDelivered = true
            return result
          })

    return promise
  }

  service.getPolicyPageTitle = function(productName, policyNumber, defaultPageTitle) {
    let pageTitle = ''

    if (productName && policyNumber) {
      pageTitle = productName + CONSTANTS.pageTitleSeparator + policyNumber + CONSTANTS.pageTitleSeparator
    }

    pageTitle += defaultPageTitle

    return pageTitle
  }

  service.getPolicyCanUserConvertTerm = function(pid) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyCanUserConvertTerm': {},
    }
    let url, promise

    url = CONSTANTS.policyCanUserConvertTerm.replace('{0}', pid)

    promise = $http.get(url).then((httpData) => {
      if (httpData.data) {
        result.policyCanUserConvertTerm = httpData.data

        return result
      }

      result.error = 'Undefined response'
      return result
    })
        .catch((httpData) => {
          utils.fillAndLogError(httpData, result)
          return result
        })

    return promise
  }

  service.getPolicyCanConvertTerm = function(pid) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyCanConvertTerm': {},
    }
    let url, promise

    url = CONSTANTS.policyCanConvertTerm.replace('{0}', pid)

    promise = $http.get(url).then((httpData) => {
      if (httpData.data) {
        result.policyCanConvertTerm = httpData.data

        return result
      }

      result.error = 'Undefined response'
      return result
    })
        .catch((httpData) => {
          utils.fillAndLogError(httpData, result)
          return result
        })

    return promise
  }

  service.getLimitedPolicyTransactionHistory = function(id, limit, type) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'transactionHistory': {},
    }
    let url, promise, data

    url = CONSTANTS.policyTransactionsSummaryURL.replace('{0}', id)

    if (type !== undefined && CONSTANTS.isDataServiceActive) {
      url = utils.appendURLParameter(url, 'type', type)
    }

    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              data = httpData.data

              result.transactionHistory = data

              if (limit && result.transactionHistory.financialActivity && result.transactionHistory.financialActivity.length > limit) {
                result.transactionHistory.financialActivity.length = limit
              }

              return result
            }

            result.error = 'Undefined response'
            return result
          }
        )
        .catch(
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          }
        )

    return promise
  }

  function getPolicyTransactionHistory(id, page, type) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'transactionHistory': {},
    }
    let _page, url, promise, data

    _page = page || '1'
    url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyTransactionsURL : 'app/mock-api/policy-transactions-page-{0}.json'

    url = CONSTANTS.isDataServiceActive ? url.replace('{0}', id)
        .replace('{1}', _page) : url.replace('{0}', _page)

    if (type !== undefined && CONSTANTS.isDataServiceActive) {
      url += '&type={2}'
      url = url.replace('{2}', type)
    }

    promise = $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              data = httpData.data

              angular.forEach(data.financialActivity, function(activity) {
                activity.isDetailsOpen = false
              })

              result.transactionHistory = data
              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.getPolicyTransactionsHistorySummary = function(id) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'transactionsHistorySummary': {},
    }

    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.policyTransactionsSummaryURL.replace('{0}', id) : 'app/mock-api/policy-transactions-summary.json'

    const promise = $http.get(url)
        .then(
          function(httpData) {
            let data

            if (httpData.data) {
              data = httpData.data

              if (data.financialActivity) {
                data.financialActivity.forEach(activity => {
                  activity.finEffDate = new Date(activity.finEffDate)
                })
              }

              result.transactionsHistorySummary = data
              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return result
          })

    return promise
  }

  service.getPolicyCanPay = function(id, bypassSign) {
    return $http.get(`${CONSTANTS.apiRoot}policy/${id}/canPay${bypassSign ? '?bypassSign=true' : ''}`)
  }

  service.getPolicyHoUserCanPay = function(id) {
    return $http.get(`${CONSTANTS.apiRoot}policy/${id}/can/user/pay`)
  }

  service.getConversionCredits = function(id, conversionAmount, asOfDate, retainedAmount) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }
    let url

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.conversionCreditURL.replace('{0}', id)
        .replace('{1}', conversionAmount)
        .replace('{2}', asOfDate)
        .replace('{3}', retainedAmount) : 'app/mock-api/policy-transactions-summary.json'

    return $http.get(url)
        .then(
          function(httpData) {
            if (httpData.data) {
              result.data = httpData.data

              return result
            }

            result.error = 'Undefined response'
            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            result.errorMessage = httpData.data
            return result
          }
        )
  }

  service.riderMetaData = null
  service.getRiderBlacklists = () => {
    const url = '/crafter/metadata/rider-lists'

    if (!service.riderMetaData) {
      return $http.get(url)
        .then((response) => {
          service.riderMetaData = response.data.reduce((acc, item) => {
            acc[item.identifier] = item.listData.split('\n')

            return acc
          }, {})

          console.log(service.riderMetaData)

          return service.riderMetaData
        })
        .catch((err) => {
          console.error(err)

          return []
        })
    }

    return $q.resolve(service.riderMetaData)
  }

  return service
}