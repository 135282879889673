export {pendingPolicyService}

pendingPolicyService.$inject = ['$http', '$filter', 'loggingService', 'utils', 'dateUtils', 'clientUtils', 'policyUtils', 'CONSTANTS', 'partyService']

    /* @ngInject */
function pendingPolicyService($http, $filter, loggingService, utils, dateUtils, clientUtils, policyUtils, CONSTANTS, partyService) {
  const service = {}
  let currentPage = 0
  let loadingMore = false
  let lastAgentKey = ''

  service.policies = []
  service.message = ''
  service.status = ''
  service.error = ''
  service.connError = false
  service.moreData = true

  function resetErrorState() {
    service.connError = false
    service.message = ''
    service.status = ''
    service.error = ''
  }

  service.getDeliveryStatusCounts = function() {
    const url = utils.appendURLParameter(CONSTANTS.deliveryStatusCountsURL, 'filterKey', partyService.getAgentKey())

    return $http.get(url)
  }

  service.isLoadingMore = function() {
    return loadingMore && service.policies.length > 0
  }

  service.isFirstPage = function() {
    return service.policies.length === 0
  }

  service.resetData = function() {
    service.moreData = true
    service.numFound = 0
    currentPage = 0
    service.policies = []
  }

  service.keepFirst10 = function() {
    currentPage = 1
    service.moreData = true
    if (service.policies.length >= 10) {
      service.policies.slice(0, 10)
    }
  }

  service.nextPage = function(showOnly, deliveryStatuses) {
    let currentAgentKey, url

    showOnly = showOnly || ''

    if (currentPage === 0) {
      service.resetData()
    }

    currentAgentKey = partyService.getAgentKey()

    if (lastAgentKey !== currentAgentKey) {
      lastAgentKey = currentAgentKey
      service.resetData()
    }

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.pendingPoliciesByAgentURL.replace('{0}', ++currentPage) : 'app/mock-api/pending-policies-by-agent.json'

    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())
    url = utils.appendURLParameter(url, 'lob', showOnly)
    url = utils.appendURLParameter(url, 'substatus', deliveryStatuses)

    resetErrorState()

    loadingMore = true

    return $http.get(url)
          .then(
            function(httpData) {
              let policies, i, p, advisorNameList, length

              loadingMore = false

              if (httpData.data) {
                service.numFound = httpData.data.numFound
                service.moreData = service.numFound !== 0
                policies = httpData.data.policies

                if (!policies) {
                  service.moreData = false
                  return
                }

                if (policies.length === 0) {
                  service.moreData = false
                  if (service.policies && service.policies.length === 0) {
                    service.message = 'Undefined response'
                  }
                } else {
                  for (i = 0; i < policies.length; i++) {
                    p = policies[i]

                    p.hoReceiptDate = dateUtils.parseDate(p.hoReceiptDate)
                    p.submitDate = dateUtils.parseDate(p.submitDate)
                    p.lastUpdateDate = p.lastUpdateDate ? dateUtils.parseDate(p.lastUpdateDate) : dateUtils.parseDate(p.asOfDate)
                    p.type = CONSTANTS.policySource.pending
                    p.requirementInfo = policyUtils.calcRequirementsMet(p.requirementsStatus)
                    p.isAnnuity = p.lineOfBusiness && p.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()
                    p.paymentAmountValue = policyUtils.getPaymentAmountValue(p.isAnnuity, p.faceAmt, p.initPaymentAmt, p.paymentAmt)
                    p.policyStatus = utils.firstToUpper(p.policyStatus)
                    p.owner = clientUtils.parsePartyDetails(p.owner)
                    p.payor = clientUtils.parsePartyDetails(p.payor)
                    p.beneficiary = clientUtils.parsePartyDetails(p.beneficiary)

                    advisorNameList = p.primaryServicingAgent

                    if (advisorNameList) {
                      p.advisorFullName = advisorNameList.length === 1 ? advisorNameList[0] : CONSTANTS.multipleAdvisorNameLabel
                    }

                    if (p.isAnnuity) {
                      if (p.annuitants && p.annuitants.length > 0) {
                        p.insured = clientUtils.parsePartyDetails(p.annuitants)
                        p.insured.birthDate = dateUtils.parseDate(p.annuitants[0].birthDate)
                      }
                    } else if (p.insureds && p.insureds.length > 0) {
                      p.insured = clientUtils.parsePartyDetails(p.insureds)
                      p.insured.birthDate = dateUtils.parseDate(p.insureds[0].birthDate)
                    }
                  }

                  service.policies = service.policies.concat(policies || [])

                  if (service.policies.length === service.numFound) {
                    service.moreData = false
                  }

                  length = $filter('number')(service.policies.length)

                  if (length === 1) {
                    service.message = 'Showing ' + length + ' Pending Policy'
                  } else {
                    service.message = 'Showing ' + length + ' Pending Policies'
                  }
                }
              } else {
                service.message = 'Undefined response'
              }
            },
            function(httpData) {
              service.moreData = false
              if (httpData.status === -1) {
                service.connError = true
                service.error = httpData.statusText
                service.message = httpData.statusText
              } else if (httpData.status === 500) {
                service.status = httpData.status
                service.error = httpData.statusText
                service.message = httpData.statusText
              } else if (httpData.status === 404 || service.policies.length === 0) {
                service.message = 'Sorry, no results found'
              }

              loggingService.log(httpData.statusText, ', ' + httpData.data + ', Url: ' + httpData.config.url, 'error')
            })
  }

  return service
}
