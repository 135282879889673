export {ClientRow}

function ClientRow() {
  return {
    restrict: 'A',
    scope: {
      client: '=',
    },
    templateUrl: 'app/widgets/templates/wg-client-row.html',
    controller: ClientRowController,
    controllerAs: 'wgClientItemCtrl',
    bindToController: true,
  }
}

ClientRowController.$inject = ['stateTransitions']

  /* @ngInject */
function ClientRowController(stateTransitions) {
  const self = this

  self.isClientListEmpty = function() {
    return self.clients.length === 0
  }

  self.showClient = function() {
    const id = self.client.id ? self.client.id : self.client.clientID

    stateTransitions.go('client.agent', {
      'id': id,
    })
  }
}
