export { stringUtils }
stringUtils.$inject = []

/* @ngInject */
function stringUtils() { 
  
  const service = {}
  // injecting this (and its module) into another module's .run() call causes Karma test issues
  // this.capitalize = function(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1)
  // }


  // TODO: Make this work properly for names line 'Anderson von Cooper'
  service.capitalizeString = function (stringToCapitalize) {
    if (stringToCapitalize) {
      return stringToCapitalize.toLowerCase().replace(/\b-?\w/g, function (m) {
        return m.toUpperCase()
      })
    }
    return stringToCapitalize
  }

  service.capitalize = service.capitalizeString

  service.pluralize = function (string) {
    const lastCharacterIndex = string.length - 1

    if (string.charAt(lastCharacterIndex) === 'y') {
      return string.substr(0, lastCharacterIndex) + 'ies'
    }

    return string + 's'
  }

  service.trimStart = function (string, character) {
    let startIndex = 0

    while (string[startIndex] === character) {
      startIndex++
    }

    return string.substr(startIndex)
  }

  service.containsAny = function (str, substrings) {
    let i
    let substring

    for (i = 0; i !== substrings.length; i++) {
      substring = substrings[i]

      if (str.indexOf(substring) !== -1) {
        return substring
      }
    }
  }
  return service
}
