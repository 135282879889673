/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */

// <a href="https://www.pennmutual.com/contact-us" target="_blank">Contact Us</a>

export const epayErrorKeys = {
  // PayConnect
  ROUTING_NUMBER: 'R038',
  ROUTING_NUMBER_THOMPSON: 'R039',
  ACCOUNT_INVALID: 'R070',
  ACCOUNT_INCOMPLETE: 'R383',
  PAYMENT_RANGE: 'R362',
  PAYMENT_DATE: 'R363',
  INVALID_CREDENTIALS: 'R378',
  SERVER_500_ERROR: 'H500',
  NICKNAME_TOO_LONG: 'AN20',
  PROCESSING_DOWN: 'R999',

  // Paymentus
  PM_BANK_ACCOUNT_SUSPENDED: 'pmBankAccount.suspended',
  PM_ACCOUNT_NUMBER_INVALID: 'accountNumber.invalid',
  PM_BANK_INFO_VALIDATION_FAILED: 'pmBankInfo.validationFailed',
  PM_BANK_INFO_INVALID_DATA: 'pmBankInfo.invalidData',
  PM_CREDIT_CARD_NUMBER_INVALID: 'pmCreditCardNumber.invalid',
  PM_CREDIT_CARD_VERIFICATION_LENGTH: 'pmCreditCardVerification.length',
  PM_CREDIT_CARD_VERIFICATION_INVALID: 'pmCreditCardVerification.invalid',
  PM_EXPIRY_DATE_INVALID: 'pmExpiryDate.invalid',
  PM_EXPIRY_DATE_PAST: 'pmExpiryDate.past',
  PM_XML_PAYMENT_DECLINED: 'xmlPayment.declined',
  PM_XML_PROFILE_NOTSAVED: 'xmlProfile.notSaved',
  PM_XML_PROFILE_DUPLICATE: 'xmlCreateProfReq.duplicateProfile',

  // PML
  PM_ERROR_CODE_FAILED: '1012300003',
  PM_ERROR_CODE_UNKNOWN: '0',
  PM_ERROR_CODE_FAILURE: '-1',
  PM_ERROR_CODE_DECLINED: '1012300004',

  GUIDELINE_PREMIUM: '29', // TODO: Temporary until true error code is provided.
}

export const PAYMENT_RETRY_HASH = {
  [epayErrorKeys.PM_BANK_ACCOUNT_SUSPENDED]: false,
  [epayErrorKeys.PM_ACCOUNT_NUMBER_INVALID]: true,
  [epayErrorKeys.PM_BANK_INFO_VALIDATION_FAILED]: true,
  [epayErrorKeys.PM_BANK_INFO_INVALID_DATA]: true,
  [epayErrorKeys.PM_CREDIT_CARD_NUMBER_INVALID]: true,
  [epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_LENGTH]: true,
  [epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_INVALID]: true,
  [epayErrorKeys.PM_EXPIRY_DATE_INVALID]: true,
  [epayErrorKeys.PM_EXPIRY_DATE_PAST]: true,
  [epayErrorKeys.PM_XML_PAYMENT_DECLINED]: true,
  [epayErrorKeys.PM_XML_PROFILE_NOTSAVED]: true,
  [epayErrorKeys.PM_XML_PROFILE_DUPLICATE]: true,

  // PML
  [epayErrorKeys.PM_ERROR_CODE_FAILED]: false,
  [epayErrorKeys.PM_ERROR_CODE_UNKNOWN]: false,
  [epayErrorKeys.PM_ERROR_CODE_FAILURE]: false,
  [epayErrorKeys.PM_ERROR_CODE_DECLINED]: true,

  [epayErrorKeys.GUIDELINE_PREMIUM]: false,// TODO: Temporary until true error code is provided.
}

export interface IEpayMessage {
  typeCode: number
  CPText?: string
  FPText?: string
  HOText?: string
}

export class EpayMessageResolver {
  static $inject: string[] = ['authService']

  isHo: boolean = false
  isClientView: boolean = false

  // references for messages http://pmlwiki/pages/viewpage.action?spaceKey=PMLLEV&title=UI+Messages+When+User+Can%27t+Pay+Premium%2C+Loan%2C+or+PUA
  premiumMessages: IEpayMessage[] = [{
    typeCode: 1,
    HOText: 'There is a pending payment',
  },
  // {
  //   typeCode: 3,
  //   CPText: 'Premium are being paid through the Penn Check automatic debit program.',
  //   FPText: 'Premium are being paid through the Penn Check automatic debit program.',
  //   HOText: 'Premium are being paid through the Penn Check automatic debit program.',
  // },
  {
    typeCode: 4,
    HOText: 'Billing is suspended. (This message is only visible to Home Office staff.)',
  },
  {
    typeCode: 7,
    CPText: 'Premiums are being paid through a salary allotment program',
    FPText: 'Premiums are being paid through a salary allotment program',
    HOText: 'Premiums are being paid through a salary allotment program',
  },
  // {
  //   typeCode: 8,
  //   CPText: 'The scheduled premium will be deducted from the Premium Deposit Fund.',
  //   FPText: 'The scheduled premium will be deducted from the Premium Deposit Fund.',
  //   HOText: 'The scheduled premium will be deducted from the Premium Deposit Fund.',
  // },
  {
    typeCode: 9,
    CPText: 'Premiums are being paid through the Automated Premium Offset program.',
    FPText: 'Premiums are being paid through the Automated Premium Offset program.',
    HOText: 'Premiums are being paid through the Automated Premium Offset program.',
  },
  {
    typeCode: 10,
    CPText: 'Premiums are being waived under the Disability Waiver of Premium Agreement',
    FPText: 'Premiums are being waived under the Disability Waiver of Premium Agreement',
    HOText: 'Premiums are being waived under the Disability Waiver of Premium Agreement',
  },
  {
    typeCode: 12,
    HOText: 'Bill is intercepted. (This message is only visible to Home Office staff.)',
  },
  {
    typeCode: 28,
    HOText: 'Note: There is a discrepancy between policy and bill. (This message is only visible to Home Office staff.)"',
  },
  {
    typeCode: 999,
    HOText: 'Note: There was an error processing this request. (This message is only visible to Home Office staff.)',
  },
  {
    typeCode: 1012300002,
    CPText: 'Your card was declined.  Please use a different card or contact your Financial Institution.',
    HOText: 'Only client can make a card payment',
    FPText: 'Only client can make a card payment',
  },
  {
    typeCode: 1012300001,
    CPText: 'Your card was declined.  Please use a different card or contact your Financial Institution.',
    HOText: 'Only client can make a card payment',
    FPText: 'Only client can make a card payment',
  },
  {
    typeCode: 1012300003,
    CPText: 'Your card was declined.  Please use a different card or contact your Financial Institution.',
    HOText: 'Only client can make a card payment',
    FPText: 'Only client can make a card payment',
  },
  ]

  loanMesssages = [{
    typeCode: 1,
    CPText: 'There is a pending loan payment.',
    HOText: 'There is a pending loan payment.',
    FPText: 'There is a pending loan payment.',
  }]

  puaMessages = [{
    typeCode: 1,
    CPText: 'There is a pending Paid-Up Additions payment.',
    HOText: 'There is a pending Paid-Up Additions payment.',
    FPText: 'There is a pending Paid-Up Additions payment.',
  },
  {
    typeCode: 18,
    HOText: 'Paid-Up Additions yearly contribution limit has been hit.',
    FPText: 'Paid-Up Additions yearly contribution limit has been hit. (Client does not see this message.)',
  },

  ]

  ROLE_HASH_KEYS = {
    CP_USER: '10',
    HO_USER: '01',
    FP_USER: '00',
  }

  epayErrorMessages = {}

  epayDefaultErrorMessage = {}

  PHONE_NUMBER = {
    [this.ROLE_HASH_KEYS.CP_USER]: {
      NY: '855-446-7393',
      NON_NY: '800-523-0650',
    },
    [this.ROLE_HASH_KEYS.HO_USER]: {
      NY: '855-446-7393',
      NON_NY: '800-523-0650',
    },
    [this.ROLE_HASH_KEYS.FP_USER]: {
      NY: '855-446-7393',
      NON_NY: '800-523-0650',
    },
  }

  constructor(private authService: any) {
    this.isHo = this.authService.isHomeOfficeUser()
    this.isClientView = this.authService.isClientView() || this.authService.isARealClient()
    // authService.isClientView()) || authService.isARealClient()
    // console.info('is clientView', this.authService.isARealClient(), this.authService.isClientView(), this.authService.isHomeOfficeUser())

    this._initEpayErrorMessages()
  }

  _pickRoleMessage(msg): string {
    if (this.isClientView) {
      return msg?.CPText
    }

    if (this.isHo) {
      return msg?.HOText
    }

    return msg?.FPText
  }

  _initEpayErrorMessages(): void {
    this.epayDefaultErrorMessage = 'Online payment is currently unavailable; please do not make another payment attempt. Please contact Penn Mutual Client Services for more information. (D)'

    this.epayErrorMessages = {
      [epayErrorKeys.ROUTING_NUMBER]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'An invalid Bank Routing Number was used. Please try again with a valid Bank Routing Number. (R038)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.ROUTING_NUMBER_THOMPSON]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'An invalid Bank Routing Number was used. Please try again with a valid Bank Routing Number. (R039)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.ACCOUNT_INVALID]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'An invalid Bank Account Number was used. Please try again with a valid Bank Account Number. (R070)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.ACCOUNT_INCOMPLETE]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'An invalid Bank Account Number was used. Please use a valid Bank Account Number. (R383)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PAYMENT_RANGE]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'The payment amount was outside of allowed range. (R362)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PAYMENT_DATE]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Online payment is currently unavailable. Please contact your financial professional or Penn Mutual Client Services. (R363)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.INVALID_CREDENTIALS]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Online payment is currently unavailable. Please contact your financial professional or Penn Mutual Client Services. (R378)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PROCESSING_DOWN]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Online payment is currently unavailable due to system maintenance. Please try again in a few hours. (R999)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.SERVER_500_ERROR]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Online payment is currently unavailable, please do not make another payment attempt. Contact your financial professional or Penn Mutual Client Services for more information. (H500)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.NICKNAME_TOO_LONG]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'The account nickname should be no longer than 20 characters. Please try again using a shorter nickname. (AN20)',
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.GUIDELINE_PREMIUM]: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'We cannot process your payment at this time. Please contact your Financial Professional or Penn Mutual Client Services for more information.',
        [this.ROLE_HASH_KEYS.HO_USER]: 'Payment failed. Payment amount is over Guideline Premium.',
        [this.ROLE_HASH_KEYS.FP_USER]: this.epayDefaultErrorMessage,
      },
      1012300002: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Your card was declined.  Please use a different card or contact your Financial Institution.',
        [this.ROLE_HASH_KEYS.HO_USER]: 'Only client can make a card payment',
        [this.ROLE_HASH_KEYS.FP_USER]: 'Only client can make a card payment',
      },
      1012300001: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Your card was declined.  Please use a different card or contact your Financial Institution.',
        [this.ROLE_HASH_KEYS.HO_USER]: 'Only client can make a card payment',
        [this.ROLE_HASH_KEYS.FP_USER]: 'Only client can make a card payment',
      },
      1012300003: {
        [this.ROLE_HASH_KEYS.CP_USER]: 'Your card was declined.  Please use a different card or contact your Financial Institution.',
        [this.ROLE_HASH_KEYS.HO_USER]: 'Only client can make a card payment',
        [this.ROLE_HASH_KEYS.FP_USER]: 'Only client can make a card payment',
      },
      [epayErrorKeys.PM_BANK_ACCOUNT_SUSPENDED]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `We are unable to process this transaction with the bank account provided.  Please contact your bank or financial institution. (${epayErrorKeys.PM_BANK_ACCOUNT_SUSPENDED})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_ERROR_CODE_FAILED]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `We are unable to process this transaction with the bank account provided.  Please contact your bank or financial institution. (${epayErrorKeys.PM_ERROR_CODE_FAILED})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_ERROR_CODE_UNKNOWN]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `We are unable to process this transaction with the bank account provided.  Please contact your bank or financial institution. (${epayErrorKeys.PM_ERROR_CODE_UNKNOWN})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_ERROR_CODE_FAILURE]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `We are unable to process this transaction with the bank account provided.  Please contact your bank or financial institution. (${epayErrorKeys.PM_ERROR_CODE_FAILURE})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_ACCOUNT_NUMBER_INVALID]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The bank account information could not be verified.  Please try again with a valid bank account or contact your financial institution. (${epayErrorKeys.PM_ACCOUNT_NUMBER_INVALID})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_BANK_INFO_VALIDATION_FAILED]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The bank account information could not be verified.  Please try again with a valid bank account or contact your financial institution. (${epayErrorKeys.PM_BANK_INFO_VALIDATION_FAILED})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_BANK_INFO_INVALID_DATA]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The bank account information could not be verified.  Please try again with a valid bank account or contact your financial institution. (${epayErrorKeys.PM_BANK_INFO_INVALID_DATA})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_ERROR_CODE_DECLINED]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The bank account information could not be verified.  Please try again with a valid bank account or contact your financial institution. (${epayErrorKeys.PM_ERROR_CODE_DECLINED})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_XML_PROFILE_DUPLICATE]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `Payment account already exists. (${epayErrorKeys.PM_XML_PROFILE_DUPLICATE})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_XML_PROFILE_NOTSAVED]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The bank account information could not be verified.  Please try again with a valid bank account or contact your financial institution. (${epayErrorKeys.PM_XML_PROFILE_NOTSAVED})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_CREDIT_CARD_NUMBER_INVALID]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The credit card information could not be verified.  Please verify and re-enter your credit card information or use a different card. (${epayErrorKeys.PM_CREDIT_CARD_NUMBER_INVALID})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_LENGTH]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The credit card information could not be verified.  Please verify and re-enter your credit card information or use a different card. (${epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_LENGTH})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_INVALID]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The credit card information could not be verified.  Please verify and re-enter your credit card information or use a different card. (${epayErrorKeys.PM_CREDIT_CARD_VERIFICATION_INVALID})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_EXPIRY_DATE_INVALID]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The credit card information could not be verified.  Please verify and re-enter your credit card information or use a different card. (${epayErrorKeys.PM_EXPIRY_DATE_INVALID})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_EXPIRY_DATE_PAST]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `The credit card information could not be verified.  Please verify and re-enter your credit card information or use a different card. (${epayErrorKeys.PM_EXPIRY_DATE_PAST})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
      [epayErrorKeys.PM_XML_PAYMENT_DECLINED]: {
        [this.ROLE_HASH_KEYS.CP_USER]: `Your card was declined.  Please use a different card or contact your Financial Institution (${epayErrorKeys.PM_XML_PAYMENT_DECLINED})`,
        [this.ROLE_HASH_KEYS.HO_USER]: '',
        [this.ROLE_HASH_KEYS.FP_USER]: '',
      },
    }
  }

  resolveEpayErrorMessage(code: string, isClient: boolean, isHo: boolean, jurisdiction: string): string {
    const roleHashKey = String(Number(isClient)) + String(Number(isHo))
    const foundErrorMessage = this.epayErrorMessages[code]
    const roleMessage = foundErrorMessage?.[roleHashKey] || foundErrorMessage?.[this.ROLE_HASH_KEYS.CP_USER]
    const messageTemplate = !roleMessage ? this.epayDefaultErrorMessage : roleMessage
    const phoneNumberJurisdiction = jurisdiction === 'New York' ? 'NY' : 'NON_NY'
    const phoneNumber = this.PHONE_NUMBER[roleHashKey][phoneNumberJurisdiction]
    const finalResult = messageTemplate.replace('PHONE_NUMBER', phoneNumber)

    // console.group('resolveEpayErrorMessage')
    // console.log('code: %s, isClient: %s, isHo: %s, jurisdiction: %s', code, isClient, isHo, jurisdiction)
    // console.log(roleHashKey, roleMessage)
    // console.log(foundErrorMessage, finalResult)
    // console.groupEnd()

    return finalResult
  }

  /**
   * Returns the boolean value looked up in the PAYMENT_RETRY_HASH. Not found (undefined) return false.
   */
  showTryAgain(code: string): Boolean {
    return PAYMENT_RETRY_HASH[code] !== undefined ? PAYMENT_RETRY_HASH[code] : false
  }

  resolvePremiumMessage(typeCodeString: number): string {
    const typeCode = Number(typeCodeString)
    const msg = this.premiumMessages.find(element => element.typeCode === typeCode)

    return this._pickRoleMessage(msg)
  }

  resolvePuaMessage(typeCodeString: string): string {
    const typeCode = Number(typeCodeString)
    const msg = this.puaMessages.find(element => element.typeCode === typeCode)

    return this._pickRoleMessage(msg)
  }

  resolveLoanMessage(typeCodeString: string): string {
    const typeCode = Number(typeCodeString)
    const msg = this.loanMesssages.find(element => element.typeCode === typeCode)

    return this._pickRoleMessage(msg)
  }
}
