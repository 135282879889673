const EMPTY_RESULTS_STATES = {
  initialState: {
    icon: 'alert',
    title: 'Alerts',
    text: 'Search for alerts by policy number or by insured name.',
  },
  forPolicyId: {
    icon: 'search',
    title: 'No Results',
    text: 'No results found for Policy Number.',
  },
  forClientName: {
    icon: 'search',
    title: 'No Results',
    text: 'No results found for Insured.',
  },
  forCategory: {
    icon: 'alert',
    title: 'Alerts',
    text: 'There a no alerts available.',
  },
}

const EMPTY_TABLE_STATES = {
  inbox: 'There are no new alerts',
  archived: 'There are no archived alerts',
}

export {EMPTY_RESULTS_STATES, EMPTY_TABLE_STATES}
