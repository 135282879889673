const PENNCHECK_WITHDRAWAL_DATES = [
  {
    dom: '1',
    date: '1st',
  },
  {
    dom: '8',
    date: '8th',
  },
  {
    dom: '15',
    date: '15th',
  },
  {
    dom: '22',
    date: '22nd',
  },
]

const PAYMENT_METHODS = {
  noBilling: {
    typeCode: '1',
    description: 'No Billing',
  },
  regularBilling: {
    typeCode: '2',
    description: 'Regular Billing',
  },
  listBilling: {
    typeCode: '5',
    description: 'List Billing',
  },
  payrollDeduction: {
    typeCode: '6',
    description: 'Payroll Deduction',
  },
  electronicFundsTransfer: {
    typeCode: '7',
    description: 'Electronic Funds Transfer',
  },
  suspendedBilling: {
    typeCode: '11',
    description: 'Suspended Billing',
  },
  payFromPremiumDepositFund: {
    typeCode: '12',
    description: 'Pay From Premium Deposit Fund',
  },
  discountedPremium: {
    typeCode: '16',
    description: 'Discounted Premium',
  },
  paidFromDividends: {
    typeCode: '17',
    description: 'Paid From Dividends',
  },
  automatedPremiumOffset: {
    typeCode: '25',
    description: 'Automated Premium Offset',
  },
  waiverOfPremium: {
    typeCode: '30',
    description: 'Waiver Of Premium',
  },
  pensionTrust: {
    typeCode: '1012300001',
    description: 'Pension Trust',
  },
  singlePremium: {
    typeCode: '1012399920',
    description: 'Single Premium',
  },
}

const PAYMENT_MODE_TC = {
  'annual': '1',
  'semi-annual': '2',
  'quarterly': '3',
  'monthly': '4',
}

const BANK_ACCOUNT_TYPES = [
  {
    tc: 'C',
    value: 'Checking',
  },
  {
    tc: 'S',
    value: 'Savings',
  },
]

export {BANK_ACCOUNT_TYPES, PAYMENT_METHODS, PAYMENT_MODE_TC, PENNCHECK_WITHDRAWAL_DATES}
