import * as angular from 'angular'
import { RX_PNY_POLICY_PREFIX } from '../components/companies/companies-service'

interface Party {
  party: string
  roles: Role[]
}

export interface Reply {
  error: string | null
  inProgress: boolean
  sent: boolean
  done: boolean
  text: string
}

interface RepliesMap {
  [requirementId: string]: Reply
}

interface AbstractRequirement {
  id: string
  isRepliable: boolean
  noteOpened: boolean
  appliesTo: string
  fulfilledDate?: string
  pastDue: boolean
  receiptDate?: string
  receivedDate?: string
  relatedParty: Party
  reqCode: string
  reqCodeTC: string
  requestedDate: string
  restrictIssueCode: string
  statusDate: string
}

interface ICSError {
  description: string,
  code: string
}

export class CSError implements ICSError {
  description: string
  code: string

  constructor (description, code) {
    this.description = description
    this.code = code
  }
}

export interface Requirement extends AbstractRequirement {
  reqStatus: string,
  responseDocId: string,
  error: ICSError | null
  sending: boolean,
  uiState: any
}

interface RequirementDisplay extends AbstractRequirement {
  statusDisplay: string
  reqStatus: number
}

interface Role {
  role: string
}

class ViewRequirementError extends CSError {
  constructor () {
    super('This document is not available.', 0)
  }
}

class ReplyValidationError extends CSError {
  constructor () {
    super('Reply must be at least two characters long.', 0)
  }
}

const MAX_CHARS: number = 1000

const PR_GTM = {
  AGENT_REPLY: 'AGENT_REPLY',
  VIEW: 'VIEW',
}

export class PolicyRequirementsController {
  private replies: RepliesMap
  private requirementsData
  public currentRequirement: Requirement
  public orderByField: string
  public reverseSort: boolean
  public errorMessage: string
  public isThereError: boolean
  public showReplyButton: boolean
  public showUploadReplyButton: boolean

  constructor (private policyRequirementsResult, private downloadManager, private policyId, private $http: angular.IHttpService,
    private CONSTANTS, private authService, private utils, private deviceUtils, private $q: angular.IQService,
    private canSubmitToResult: string[], private requirementsHelperService) {
    this.requirementsData = {}
    this.replies = {}

    this.orderByField = 'reqStatus'
    this.reverseSort = false

    this.errorMessage = ''
    this.isThereError = false

    if (!policyRequirementsResult.error) {
      this.requirementsData = policyRequirementsResult.data
      this.requirementsData.requirements = this.requirementsData.requirements.map(requirement => {
        if (requirement.reqStatus === 'Pending' && requirement.requirementDetails) {
          requirement.noteOpened = true
        }
        return requirement
      })
      this.requirementsData.requirements = this.mapRequirementsStatus(this.requirementsData.requirements)
    } else {
      this.requirementsData = {}

      this.setError(policyRequirementsResult.error)
    }

    this.showReplyButton = requirementsHelperService.showReplyButton.bind(this.requirementsHelperService, this.canSubmitToResult, this.replies)
    this.showUploadReplyButton = requirementsHelperService.showReplyButton.bind(this.requirementsHelperService, this.canSubmitToResult, this.replies)
  }

  get isPNY(): boolean {
    return RX_PNY_POLICY_PREFIX.test(this.policyId)
  }

  /**
   * Opens and closes the notes row for the given requirements.
   *
   * @param requirement
   */
  toggleNote(requirement) {
    const isNoteOpen = requirement.noteOpened

    requirement.noteOpened = !isNoteOpen
  }

  /**
   * TODO: Ask Paul why we needed to do this.
   *
   * @param requirements
   */
  mapRequirementsStatus (requirements: Requirement[]): RequirementDisplay[] {
    let requirementStatuses = ['Pending', 'Received', 'Waived', 'Completed']

    return requirements.map(requirement => {
      return {
        ...requirement,
        statusDisplay: requirement.reqStatus,
        reqStatus: requirementStatuses.indexOf(requirement.reqStatus),
      }
    })
  }

  setError (error) {
    this.errorMessage = error
    this.isThereError = Boolean(this.errorMessage)
  }

  onSortColumnClick (sortColumn) {
    if (sortColumn !== this.orderByField) {
      this.orderByField = sortColumn
      this.reverseSort = false
    } else {
      this.reverseSort = !this.reverseSort
    }
  }

  /**
   * Resets the requirement's state.
   *
   * @param requirement
   */
  closeRequirementReply(requirement: Requirement, cancelled: boolean = false): void {
    const requirementId: string = requirement.id
    const reply: Reply = this.replies[requirementId]
    const uiState: any = requirement.uiState

    if (Boolean(reply) && cancelled) {
      reply.inProgress = false
      reply.done = false
      reply.sent = false
    }

    uiState.reset(reply)
    requirement.error = null
  }

  /**
   * Disables the
   * @param requirementId
   */
  disableReplyInputs (requirementId: string): boolean {
    return this.replies[requirementId] && this.replies[requirementId].sent
  }

  /**
   * Returns true when the length of the text is at least two characters.
   *
   * @param requirementId
   */
  longEnough (requirementId: string): boolean {
    const reply: Reply = this.replies[requirementId]

    return Boolean(reply) && Boolean(reply.text) && reply.text.length > 1
  }

  /**
   * Checks to see if the requirementId is in the canSubmitToResult payload.
   *
   * @param requirementId
   */
  // canReplyToRequirement(requirementId: string): boolean {
  //   return this.canSubmitToResult.includes(requirementId)
  //     // for debugging || requirementId === '05-02_0090633680_1012300040_27'
  // }

  /**
   * Initialize a new reply state, then shows the upload panel and
   * ensures that the choice panel is hidden.
   *
   * @param requirement
   */
  openUploadReply (requirement: Requirement): void {
    this.replies[requirement.id] = {
      error: null,
      inProgress: true,
      sent: false,
      done: false,
      text: '',
    }

    requirement.uiState.showUploadReplyPanel = true
    requirement.uiState.showChoicePanel = false
  }

  /**
   * Initialize a new reply state, then shows the text panel and
   * ensures that the choice panel is hidden.
   *
   * @param requirement
   */
  openTextReply (requirement: Requirement): void {
    // requirement.noteOpened = true
    this.replies[requirement.id] = {
      error: null,
      inProgress: true,
      sent: false,
      done: false,
      text: '',
    }

    requirement.uiState.showTextReplyPanel = true
    requirement.uiState.showChoicePanel = false
  }

  /**
   * Determine which type of reply the user can make for a given
   * `requirement`, and then display either the text reply panel,
   * or the upload reply panel. If the requirement supports both types
   * of reply then display the choice panel.
   *
   * @param requirement
   */
  openReplyRow (requirement: Requirement): void {
    const uiState = requirement.uiState

    uiState.showTextReplyPanel = uiState.canEnterReply && !uiState.canUploadReply
    uiState.showUploadReplyPanel = !uiState.canEnterReply && uiState.canUploadReply
    uiState.showChoicePanel = uiState.canEnterReply && uiState.canUploadReply
    uiState.showReplyButton = false
    uiState.showReplyRow = true

    if (uiState.showTextReplyPanel) {
      this.openTextReply(requirement)
    } else if (uiState.showUploadReplyPanel) {
      this.openUploadReply(requirement)
    }
  }

  /**
   * Return the number characters remaining as the user types into the reply field.
   *
   * @param requirementId
   */
  replyCharactersRemaining (requirementId: string): number {
    const reply: Reply = this.replies[requirementId]
    const remainingChars: number = reply ? MAX_CHARS - reply.text.length : MAX_CHARS

    return remainingChars
  }

  replyErrorMessage (requirementId: string): string {
    return (this.replies[requirementId] && this.replies[requirementId].error) || ''
  }

  isIE (): boolean {
    return this.utils.isIE()
  }

  /**
   * Sends a text reply to Core Services.
   *
   * @param requirement
   */
  sendReply (requirement: Requirement) { // TODO remove the <any> in favor of a union type or something
    const reply = this.replies[requirement.id]

    requirement.sending = true

    if (window.dataLayer) {
      window.dataLayer.push(this.utils.buildGtmObject('AGENT REPLY', 'REPLY', {
        policyID: this.policyId,
      }))
    }

    const formData = new FormData()
    const url = `${this.CONSTANTS.apiRoot}policy/${this.policyId}/requirements/submit/${requirement.id}`
    const ieOptions = {
      transformRequest: angular.identity,
      headers: {'content-type': undefined},
    }
    let options: any = {
      headers: {'content-type': 'multipart/form-data'}}

    formData.append('text', reply.text)

    if (this.isIE()) {
      options = ieOptions
    }

    this.$http.post(url, formData, options)
      .then(() => this._sendReplySuccess(requirement))
      .catch(response => this._sendReplyFailure(requirement, response))
  }

  /**
   * Sends a PDF reply to Core Services.
   *
   * @param requirement
   */
  uploadFile(requirement, theFile) {
    const url = `${this.CONSTANTS.apiRoot}policy/${this.policyId}/requirements/submit/${requirement.id}`
    // const url = `/upload-test/${this.policyId}/${requirement.id}`
    const deferred = this.$q.defer()
    const formData = new FormData()
    const config: any = {
      headers: {
        Authorization: 'Bearer ' + this.authService.getSessionID(),
      },
      eventHandlers: {
        progress: e => {
          if (e.lengthComputable) {
            const percentage = Math.round((e.loaded * 100) / e.total)

            deferred.notify(percentage)
          }
        },
        load: () => {
          deferred.notify(100)
        },
      },
    }

    if (this.isIE()) {
      config.transformRequest = angular.identity
      config.headers['content-type'] = undefined
    } else {
      config.headers['content-type'] = 'multipart/form-data'
    }

    formData.append('file', theFile)

    deferred.notify(0)

    this.$http.post(url, formData, config)
      .then(() => this._sendReplySuccess(requirement))
      .catch(response => this._sendReplyFailure(requirement, response))

    return deferred.promise
  }

  _sendReplySuccess(requirement: Requirement) {
    // const matchingRequirement = this.requirementsData.requirements.filter(requirement => requirement.id === requirementId)[0]
    const reply = this.replies[requirement.id]

    if (reply) {
      reply.sent = true
      reply.done = true
      reply.inProgress = false
    }

    this.closeRequirementReply(requirement)

  }

  _sendReplyFailure (requirement: Requirement, response: any) {

    const reply = this.replies[requirement.id]

    requirement.sending = false
    if (response.data) {
      requirement.error = new CSError(response.data.description, response.data.code)
    } else {
      console.debug('Unexpected response data %o', response)
      requirement.error = response
    }

    // reply.error = error.data || error.statusText || 'Unknown error.'
    reply.sent = false

  }

  isHomeOfficeUser (): boolean {
    return this.authService.isInRole('HOMEOFFICE')
  }

  /**
   * Returns `true` if the given `requirement.reqCode` is in
   * `VIEW_REPLY_ALLOWED_TYPES`, and `canReplyToRequirement` returns true.
   *
   * @param requirement
   */
  // showReplyButton (requirement: Requirement): boolean {
  //   const allowedType: boolean = !!VIEW_REPLY_ALLOWED_TYPES.filter(code => requirement.reqCode === code)[0]
  //   const canReply: boolean = this.canReplyToRequirement(requirement.id)

  //   if (allowedType && canReply) {
  //     let reply = this.replies[requirement.id]

  //     return (!reply || (!reply.inProgress && !reply.done))
  //   }

  //   // console.log(requirement.id, allowedType && canReply)
  //   return false
  // }

  /**
   * Returns `true` if the given `requirement.reqCode` is in
   * `UPLOAD_REPLY_ALLOWED_TYPES`, and `canReplyToRequirement` returns true.
   *
   * @param requirement
   */
  // showUploadReplyButton (requirement: Requirement): boolean {
  //   const allowedType: boolean = !!UPLOAD_REPLY_ALLOWED_TYPES.filter(code => requirement.reqCode === code)[0]
  //   const canReply: boolean = this.canReplyToRequirement(requirement.id)

  //   if (allowedType && canReply) {  // the service needs to be fixed to indicate this for upload types
  //     let reply = this.replies[requirement.id]

  //     return (!reply || (!reply.inProgress && !reply.done))
  //   }

  //   return false
  // }

  /**
   * Returns true if the given `reqCode` is in either `VIEW_REPLY_ALLOWED_TYPES` or `UPLOAD_REPLY_ALLOWED_TYPES`.
   *
   * @param reqCode
   */
  // showViewReplyButton (reqCode: string): boolean {
  //   const allowedType1: boolean = !!VIEW_REPLY_ALLOWED_TYPES.filter(code => reqCode === code)[0]
  //   const allowedType2: boolean = !!UPLOAD_REPLY_ALLOWED_TYPES.filter(code => reqCode === code)[0]

  //   // console.log(reqCode, allowedType1 || allowedType2)
  //   return allowedType1 || allowedType2
  // }

  /**
   * NOTE: Only used in Mobile
   *
   * @param requirementId
   */
  showReplyInputs (requirementId: string): boolean {
    const matchingRequirement = this.requirementsData.requirements.filter(a => a.id === requirementId)[0]

    if (matchingRequirement) {
      return this.replies[requirementId] && this.replies[requirementId].inProgress
    }

    return false
  }

  /**
   * Returns true if the requirementsData has no requirements.
   *
   */
  areRequirementsEmpty () {
    return !this.requirementsData || !this.requirementsData.requirements || this.requirementsData.requirements.length === 0
  }

  /**
   * Creates a CSV file containing the requirements data, and
   * downloads it to the user's download folder.
   *
   */
  downloadRequirements () {
    let policyNumber = this.policyRequirementsResult.polNumber

    this.downloadManager.getRequirements(policyNumber)
  }

  /**
   * Check to see if the user can view the document, and if so
   * opens the document in a new tab.  Logs click with Google
   * regardless of whether the user can view the document or not.
   *
   * @param requirement
   */
  viewReply (requirement: Requirement) {
    const url = this.CONSTANTS.policyDownloadURL.replace('{0}', requirement.responseDocId).replace('{1}', this.authService.getSessionID())

    // Always log the click with Google.
    this.gtmDownloadReply()

    this.$http.get(url)
      .then(r => {
        if (Boolean(r.data)) {

          if (this.deviceUtils.isMobilePlatform()) {
            window.open(url, '_system')
          } else {
            window.open(url, '_blank')
          }
        } else {
          requirement.error = new ViewRequirementError()
        }
      })
      .catch(() => {
        requirement.error = new ViewRequirementError()
      })
  }

  /**
   * Send GA tracking for AGENT_REPLY.
   *
   */
  private gtmDownloadReply () {
    if (window.dataLayer) {
      window.dataLayer.push(this.utils.buildGtmObject(PR_GTM.AGENT_REPLY, PR_GTM.VIEW, {
        policyId: this.policyRequirementsResult.polNumber,
      }))
    }
  }
}

PolicyRequirementsController.$inject = ['policyRequirementsResult', 'downloadManager', 'policyId', '$http', 'CONSTANTS', 'authService',
  'utils', 'deviceUtils', '$q', 'canSubmitToResult', 'requirementsHelperService', 'VIEW_REPLY_ALLOWED_TYPES', 'UPLOAD_REPLY_ALLOWED_TYPES']
