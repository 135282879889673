import * as angular from 'angular'
/**
 * Renders the <pml-ng-file-upload /> directive as a list for buttons.
 */
const allowedFileTypes = [
  'application/pdf',
]

export class PmlNgFileUpload implements ng.IDirective {
  restrict = 'E'
  templateUrl = 'app/directives/pml-ng/file-upload/template.html'
  replace = false
  scope = false

  constructor(private utils, private $sce: angular.ISCEService) { }

  _dragenter(e) {
    this._prevent(e)

    const target = e.currentTarget
    const overlay = angular.element(target.querySelector('drop-message'))

    overlay.removeClass('hidden')
    // e.dataTransfer.dropEffect = "copy";

    // console.log('_dragenter', e)
  }

  _dragexit(e) {
    this._prevent(e)

    const target = e.currentTarget
    const overlay = angular.element(target.querySelector('drop-message'))

    if (e.target.localName !== 'drop-target') {
      overlay.addClass('hidden')
        // e.dataTransfer.dropEffect = "none";
    }
  }

  _drop(e, scope) {
    this._prevent(e)

    const file = e.dataTransfer.files[0]
    const target = e.currentTarget
    const overlay = angular.element(target.querySelector('drop-message'))

    overlay.addClass('hidden')

    scope.invalidFileType = allowedFileTypes.indexOf(file.type) === -1
    if (!scope.invalidFileType) scope.chosenFile = e.dataTransfer.files[0]

    // console.log(scope.chosenFile, scope.invalidFileType)

    scope.$apply()

  }

  _prevent(e) {
    e.preventDefault()
    e.stopPropagation()
    // return false;
  }

  _fileChanged(e, scope) {
    scope.$apply(() => {
      const file: HTMLInputElement = e.target as HTMLInputElement

      scope.chosenFile = file.files && file.files[0]
      scope.invalidFileType = false  // Can't be wrong file type when chosen from file open dialog.
    })
  }

  _dropTargetClicked(e: any, file: any): any {
    this._prevent(e)
    file.click(e)
  }

  /**
   * AngularJS post link function use for initial configuration of instances of PmlNgFileUpload
   */
  // tslint:disable-next-line
  link(scope: any, el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    const body = angular.element(window.document.body)
    const theFile: ng.IAugmentedJQuery = el.find('input')
    const dropTarget = el.find('drop-target')
    const fileMessage = this.utils.isPhone() ? '<u>Browse</u> for file' : 'Drop a file here, or&nbsp;<u>browse</u>.'

    scope.selectedFileMessage = this.$sce.trustAsHtml(fileMessage)

    // const uploadProgress = el.find('upload-progress')
    dropTarget.on('click touchend', e => this._dropTargetClicked(e, theFile[0]))
    theFile.on('change', e => this._fileChanged(e, scope))

    if (!this.utils.isPhone()) {
      body.on('drop dragdrop dragenter dragover', e => this._prevent(e))
      dropTarget.on('dragenter dragover', e => this._dragenter(e))
      dropTarget.on('dragleave', e => this._dragexit(e))
      dropTarget.on('drop', e => this._drop(e, scope))
    }

  }

  /**
   * Creates an instance of PmlNgFileUpload, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = (utils, $sce) => new PmlNgFileUpload(utils, $sce)

    directive.$inject = ['utils', '$sce']

    return directive
  }
}
