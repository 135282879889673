import * as angular from 'angular'

import {PmlExpandableRowDirective} from './expandable-row'
import {PmlListViewFilterDirective} from './filter-directive'
import './pager/pager-directive'
import './select/directive'
import {PmlNgListViewDirective} from './pml-list-view-directive'
import {PmlNgListViewDirectiveController} from './pml-ng-list-view-directive-controller'
import {PmlSortableColumnDirective} from './sortable-column-directive'

function findOrCreateModule(moduleName, deps) {
  deps = deps || []
  try {
    return angular.module(moduleName)
  } catch (error) {
    return angular.module(moduleName, deps)
  }
}

findOrCreateModule('app.directives',[])
  .directive('pmlListViewFilter', PmlListViewFilterDirective.factory())
  .directive('pmlNgListView', PmlNgListViewDirective.factory())
  .directive('pmlSortableColumn', PmlSortableColumnDirective.factory())
  .controller('pmlNgListViewController', PmlNgListViewDirectiveController)

findOrCreateModule('app.cl-and-r', [])
  .directive('pmlExpandableRow', PmlExpandableRowDirective.factory())
