
export {MyAlertsController}

MyAlertsController.$inject = ['$scope', '$stateParams', 'authService', 'alertService', 'dateUtils', 'deviceUtils']

  /* @ngInject */
function MyAlertsController($scope, $stateParams, authService, alertService, dateUtils, deviceUtils) {
  const self = this

  self.formats = ['dd-MMMM-yyyy', 'MM/dd/yyyy', 'dd.MM.yyyy', 'shortDate']
  self.format = self.formats[1]
  self.altInputFormats = ['M!/d!/yyyy']
  self.categories = []
  self.isMobileView = deviceUtils.isMobileFormfactor()

  self.activityOptions = dateUtils.getActivityOptions()

  self.selectedActivityOption = self.activityOptions[0]

  self.alertStopPropagation = alertStopPropagation

  self.popupFrom = {
    opened: false,
  }

  self.calendarFrom = function(event) {
    let e

    self.popupFrom.opened = !self.popupFrom.opened
    self.popupTo.opened = false

    if (self.popupFrom.opened) {
      e = event ? event.target : null

      if (e && e.setSelectionRange) {
        e.setSelectionRange(0, e.value.length)
      } else if (e && e.select) {
        e.select()
      }
    }
  }

  self.popupTo = {
    opened: false,
  }

  self.calendarTo = function(event) {
    let e

    self.popupTo.opened = !self.popupTo.opened
    self.popupFrom.opened = false

    if (self.popupTo.opened) {
      e = event ? event.target : null

      if (e && e.setSelectionRange) {
        e.setSelectionRange(0, e.value.length)
      } else if (e && e.select) {
        e.select()
      }
    }
  }

    // TODO: Deprecated
    // self.inlineOptions = {
    //   customClass: getDayClass,
    //   minDate: new Date(),
    //   showWeeks: true,
    // }

    // TODO: Deprecated
    // self.dateFromOptions = {
    //   formatDay: 'd',
    //   formatYear: 'yy',
    //   formatDayHeader: 'EEE',
    //   showWeeks: false,
    // }

    // TODO: Deprecated
    // self.dateToOptions = {
    //   formatDay: 'd',
    //   formatYear: 'yy',
    //   showWeeks: false,
    // }

  self.hideUnassignedInput = function() {
    return authService.isInRole('AGENT') || authService.isInRole('ASSISTANT') || authService.isInRole('HTK-ASSISTANT')
  }

    // self.toggleMin = function() {
    //   self.inlineOptions.minDate = self.inlineOptions.minDate ? null : new Date()
    //   self.dateOptions.minDate = self.inlineOptions.minDate
    // }

    // self.toggleMin();

    // TODO: Figure out what this is really doing?  Why does it
    //       need to use the function version of $watch?
  $scope.$watch(function() {
    return self.startdate
  }, function(newVal) {
    checkStartDate()
  })

  function checkStartDate() {
    if (!self.selectedActivityOption) {
      return
    }

    if (self.selectedActivityOption.startDate !== self.startdate) {
      self.selectedActivityOption = self.activityOptions[0]
    }
  }

  $scope.$watch(function() {
    return self.enddate
  }, function(newVal) {
    checkEndDate()
  })

  function checkEndDate() {
    if (!self.selectedActivityOption) {
      return
    }

    if (self.selectedActivityOption.endDate !== self.enddate) {
      self.selectedActivityOption = self.activityOptions[0]
    }
  }

  self.isThereError = function() {
    return Boolean(self.errorMessage)
  }

    // self.reset = function() {
    //   initVars()
    //   self.search()
    // }

  function init() {
    self.currentCategory = $stateParams.category
      // initVars()
  }

    // function initVars() {
    //   self.errorMessage = ''
    //   self.clientName = ''
    //   self.policyId = ''
    //   self.orphaned = false
    //   self.startdate = null
    //   self.enddate = null

    //   if (alertService.categories) {
    //     self.categories = Object.keys(alertService.categories)
    //     self.categories.unshift('All alert categories')
    //     self.activityCategory = self.categories.length > 0 ? self.categories[0] : null
    //   }
    // }

    // TODO: Why is this function needed?
  function alertStopPropagation($event) {
    $event.stopPropagation()

    if ($event.target.id === 'startDate') {
      return
    }
    if ($event.target.id === 'endDate') {
      return
    }

    self.popupFrom.opened = false
    self.popupTo.opened = false
  }

  init()
}
