import ng from 'angular'
import { LoggingService } from '../../utils/logging'
export class CreditCardFormController implements ng.IController {
  $inject = ['$window', '$element', '$scope', '$sce', 'loggingService']

  private modalInstance: any
  private resolve: any

  public ccFormOpen: boolean = false
  public jpMorganUrl: string
  public messages: any[] = []
  public ccResult: any

  constructor(private $window, private $element, private $scope, private $sce, private loggingService: LoggingService) { }

  iframeReady(): void {
    this.ccFormOpen = true
    this.ccResult = ''
    this.$scope.$apply()
  }

  receiveMessage(event): any {
    const rawData: string = event.data ?? ''
    const firstColon: number = rawData.indexOf(':')
    const messageType = firstColon > 0 ? rawData.substring(0, firstColon) : 'Unknown'
    const payload = firstColon > 0 ? rawData.substring(firstColon + 1) : ''
    const unsafeOrigin = !event.origin?.endsWith('paymentus.com')

    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (unsafeOrigin) {
      this.modalInstance.dismiss({status: 'Error', payload: new Error(`Unsafe origin: ${event.origin}`)})
      return
    }

    switch (messageType) {
      case 'frameHeight':
        // Might want to do something if the frameHeight changes
        return
      case 'Result':
        // this is most likely a paymentus ui validation error the client needs to fix
        const resultDataObj = JSON.parse(payload)

        console.info('we recieved the following from paymentus', resultDataObj)
        return
      case 'pmDetails':
        this.ccResult = { status: 'Success', payload: JSON.parse(payload) }
        break
      default:
        this.ccResult = { status: 'Error', payload: new Error(`Unexpected message. ${messageType}`) }
    }

    this.ccFormOpen = false
    this.$scope.$apply()

    if (this.ccResult.status === 'Success') {
      this.modalInstance.close(this.ccResult) // causes promise to be resolved
    } else {
      this.modalInstance.dismiss(this.ccResult) // causes promise to be rejected
    }
  }

  closeModal(): void {
    this.modalInstance.close()
  }

  $onInit(): void {
    const iframe = this.$element.find('iframe')[0]

    this.$window.addEventListener('message', (e) => this.receiveMessage(e), false)

    iframe.addEventListener('load', () => this.iframeReady())

    this.jpMorganUrl = this.$sce.trustAsResourceUrl(this.resolve.deepLinkUrl)
  }
}