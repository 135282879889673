import { EntityName } from './entity-name'
import { ITypeCodeValue } from '../types'
import { TypeCodeValue } from './type-code-value'
export class ClientRole {
  id: string
  name: EntityName
  fullName: string
  // firstName: string
  // middleName: string
  // lastName: string
  birthDate: string
  address: any
  registered: boolean
  policyRoles: any[]
  genderTypeCode: TypeCodeValue
  isSeparator: boolean
  isAssociatedParty: boolean = true
  group: string = 'Existing Party'

  constructor(rawRole) {
    Object.entries(rawRole).forEach(([name, value]) => {
      if (!['firstName', 'middleName', 'lastName', 'policyRoles'].includes(name)) {
        if (['partyTypeCode', 'genderTypeCode'].includes(name)) {
          this[name] = new TypeCodeValue(value)
        } else {
          this[name] = value
        }
      }
    })

    this.policyRoles = this._flattenRoles(rawRole.policyRoles ?? [])
    this.name = new EntityName(rawRole, this.partyTypeCode)
  }

  /**
   * partyTypeCode
   */
  _partyTypeCode: TypeCodeValue
  set partyTypeCode(value: ITypeCodeValue) {
    this._partyTypeCode = new TypeCodeValue(value)
    // if (value.tc !== '1') {
    //   this._partyTypeCode = new TypeCodeValue({ tc: '2', value: 'Organization' })
    // } else {
    // }

    this.name?.updatePartyTypeCode(this._partyTypeCode)
  }

  get partyTypeCode(): ITypeCodeValue {
    return this._partyTypeCode
  }

  _flattenRoles(policyRoles): any[] {
    const roles: any[] = policyRoles.reduce((accRoles: any, polRole: any) => {
      accRoles = accRoles.concat(polRole.roles.map((role: any) => {
        role.polNumber = polRole.polNumber
        return role
      }))

      return accRoles
    }, [])

    return roles
  }

  get displayName(): string {
    if (this.partyTypeCode.tc === '1') {
      const birthDate = this.birthDate ? ` (${this.birthDate})` : ''

      return `${this.fullName}${birthDate}`
    }

    return this.fullName
  }
}
