export {clientService}

const PAYMENT_CATEGORY_CARD = ['2', '3']

clientService.$inject = ['$q', '$http', '$rootScope', 'loggingService', 'dateUtils', 'clientUtils', 'policyUtils', 'userAccountService', 'notificationCenterService', 'CONSTANTS', '$log', 'utils']

  /* @ngInject */
function clientService($q, $http, $rootScope, loggingService, dateUtils, clientUtils, policyUtils, userAccountService, notificationCenterService, CONSTANTS, $log, utils) {
  const service = {}

  service.isFavoriteClient = function(uid) {
    return userAccountService.isFavoriteClient(uid)
  }

  service.processClientInfo = function(party, insuredSince) {
    const client = party

    if (client.address && client.address.length > 0) {
      client.htmlAddress = clientUtils.getAddress(client.address[0])
    }

    client.birthDate = dateUtils.parseDate(client.birthDate)
    client.calculatedAge = client.birthDate ? dateUtils.getYearsFrom(client.birthDate) : client.age
    client.isAnOrganization = client.partyType !== CONSTANTS.personPartyType

    client.firstName = party.firstName
    client.fullName = clientUtils.partyName(party)
    client.phone = client.phone && client.phone.length > 0 ? client.phone[0].number : ''

    client.effDate = insuredSince
    client.uid = clientUtils.getClientHash(client)

    return client
  }

  service.processPolicies = function(policies) {
    let i, p, insureds
    let advisorNameList
    const insuredSince = new Date()

    const result = {
      policies: policies,
      insuredSince: insuredSince,
    }

    for (i = 0; i < result.policies.length; i++) {
      p = result.policies[i]
      insureds = p.insureds ? p.insureds : []

      insureds = insureds.concat(p.coverageInsureds ? p.coverageInsureds : [])
      insureds = insureds.concat(p.annuitants ? p.annuitants : [])
      insureds = insureds.concat(p.jointAnnuitants ? p.jointAnnuitants : [])
      insureds = insureds.concat(p.contingentAnnuitants ? p.contingentAnnuitants : [])

      p.type = policyUtils.getPolicySourceFromStatus(p.holdingStatus, p.source)
      p.deathBenefitAmt = p.grossDeathBenefitAmt
      p.isAnnuity = p.lineOfBusiness && p.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()
      p.insured = clientUtils.parsePartyDetails(insureds)
      p.owner = clientUtils.parsePartyDetails(p.owners)
      if (p.jointOwners && p.jointOwners.length) {
        p.jointOwner = clientUtils.parsePartyDetails(p.jointOwners)
        p.owner.isMultiple = true // annuities only have one owner, and possibly one joint owner. When there's a joint, mark owner as isMultiple to keep in line with life policies
        p.owner.fullName = p.owner.fullName + ', +1'
      }
      p.payor = clientUtils.parsePartyDetails(p.payors)
      p.beneficiary = clientUtils.parsePartyDetails(p.beneficiaries)
      p.effDate = dateUtils.parseDate(p.effDate)
      p.anniversaryDate = clientUtils.getAnniversaryDate(p.effDate)
      p.paymentDueDate = dateUtils.parseDate(p.paymentDueDate)
      p.lastUnschedPremDate = dateUtils.parseDate(p.lastUnschedPremDate)
      p.lastUpdateDate = p.lastUpdateDate ? dateUtils.parseDate(p.lastUpdateDate) : dateUtils.parseDate(p.asOfDate)
      p.paymentAmountValue = policyUtils.getPaymentAmountValue(p.isAnnuity, p.faceAmt, p.initPaymentAmt, p.paymentAmt)
      p.submitDate = dateUtils.parseDate(p.submitDate)
      p.hoReceiptDate = dateUtils.parseDate(p.hoReceiptDate)

      advisorNameList = p.primaryServicingAgent

      if (advisorNameList) {
        p.advisorFullName = advisorNameList.length === 1 ? advisorNameList[0] : CONSTANTS.multipleAdvisorNameLabel
      }

      p.isULProductType = policyUtils.isUniversalLifePolicy(p.productType)

      if (p.effDate && p.effDate < result.insuredSince) {
        result.insuredSince = p.effDate
      }
    }

    return result
  }

  service.getClientNotifications = function(sid, isClient) {
    let promise, data
    const url = CONSTANTS.notifyURL.replace('{0}', isClient ? 'client' : 'agent').replace('{1}', sid)

    $log.debug('Attempting to get client notifications from ' + url)

    promise = $http.get(url).then(
      function(httpData) {
        if (httpData.data) {
          data = httpData.data

          if (isClient) {
            notificationCenterService.removeById(CONSTANTS.eventId.NOTIFY_CLIENT)

            if (data.message && data.message !== '') {
              notificationCenterService.add(CONSTANTS.eventType.info, data.message, CONSTANTS.notificationType.systemUnavailability, CONSTANTS.eventId.NOTIFY_CLIENT, {
                'status': 'shown',
                'top': true,
              })
            }
          } else {
            notificationCenterService.removeById(CONSTANTS.eventId.NOTIFY_AGENT)

            if (data.message && data.message !== '') {
              notificationCenterService.add(CONSTANTS.eventType.info, data.message, CONSTANTS.notificationType.systemUnavailability, CONSTANTS.eventId.NOTIFY_AGENT, {
                'status': 'shown',
                'top': true,
              })
            }
          }

          return httpData.data
        }

        return {}
      },
      function(httpData) {
        loggingService.log('Error from server in clientService.getClientNotifications: ' + httpData.status + '. Client ID: ' + $rootScope.auth.getLogin(), 'error')

        return {}
      })

    return promise
  }

  service.saveAccount = function(account) {
    const result = {
      'error': '',
      'data': {},
    }

    const accountData = {
      'nickname': account.accountNickname,
      'accountNumber': account.accountNumber,
      'accountRtn': account.routingNumber,
      'accountType': account.accountType,
      'defaultAccount': account.defaultAccount,
    }

    const url = CONSTANTS.clientAccountsURL
    const promise = $http.post(url, accountData)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data
            return result
          }

          result.error = 'Undefined response'
          return result
        })
        .catch(function(httpData) {
          console.error('Failed to set account as default: ' + JSON.stringify(httpData.data))
          utils.fillAndLogError(httpData, result)
          result.errorMessage = httpData.data
          return result
        })

    return promise
  }

  service.setDefaultAccount = function(accountId) {
    const result = {
      'error': '',
      'data': {},
    }

    // const body = {
    //   'profileToken': accountId,
    // }
    const body = accountId
    const url = CONSTANTS.apiRoot + 'client/payment/accounts/default'
    const promise = $http.put(url, body)
        .then(function(httpData) {
          if (!httpData.data) {
            result.data = httpData.data  // should just be no content 204
            return result
          }

          result.error = 'Undefined response'
          return result
        })
        .catch(function(httpData) {
          console.error('Failed to set account as default: ' + JSON.stringify(httpData.data))
          utils.fillAndLogError(httpData, result)
          result.errorMessage = httpData.data
          return result
        }
        )

    return promise
  }

  service.isFromState = function(stateAbbreviation) {
    const result = {
      'error': '',
      'data': {},
    }

    const url = CONSTANTS.clientIsResidentForStateURL.replace('{0}', stateAbbreviation.toUpperCase())
    const promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data
            return result
          }

          result.error = 'Undefined response'
          return result
        })
        .catch(function(httpData) {
          console.error('Failed to test for residence on state: ' + JSON.stringify(httpData.data))
          utils.fillAndLogError(httpData, result)
          result.errorMessage = httpData.data
          return result
        })

    return promise
  }

  service._isFromCA = undefined
  service.isFromCA = function() {
    if (service._isFromCA === undefined) {
      return this.isFromState('CA')
          .then((r) => {
            service._isFromCA = r
            return service._isFromCA
          })
    }

    return $q.resolve(service._isFromCA)
  }

  service.getPaymentAccounts = function(allowCreditCards) {
    const result = {
      'error': '',
      'data': [],
    }

    const url = CONSTANTS.clientAccountsURL
    const promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data

            if (!allowCreditCards) {
              result.data = result.data.reduce((acc, item) => {
                const isCreditCard = PAYMENT_CATEGORY_CARD.includes(item.category.tc)

                if (item && item.category && !isCreditCard) {
                  acc.push(item)
                }

                return acc
              }, [])
            }

            return result
          }

          // Not sure when or why this condition would happen, but
          // when it does let's make it look like it was a 500 error.
          return {
            error: 'Undefined response',
            status: 500,
            statusText: 'Undefined response',
          }
        })
        .catch(function(httpData) {
          utils.fillAndLogError(httpData, result)
          result.errorMessage = httpData.data

          return result
        })

    return promise
  }

  service.deletePaymentAccount = function(accountId) {
    const result = {
      'error': '',
      'data': {},
    }

    const url = CONSTANTS.apiRoot + 'client/payment/accounts'
    const config = {headers: {'profileToken': accountId} }

    const promise = $http.delete(url, config)
        .then(function(httpData) {
          if (httpData.data) {
            result.data = httpData.data
            return result
          }

          result.error = 'Undefined response'
          return result
        })
        .catch(function(httpData) {
          // @TODO: need to handle {"code":"R356","description":"Account has pending or recurring payments still"} error with status message
          console.error('Failed to delete payment account: ' + JSON.stringify(httpData.data))
          utils.fillAndLogError(httpData, result)
          result.errorMessage = httpData.data
          return result
        })

    return promise
  }

  return service
}
