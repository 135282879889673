/* eslint-disable quote-props */
import { PhoneNumber } from '../../../client/beneficiaries/classes/phone'
import { TransactionChange } from '../../classes/transaction-change'
import { GenericGatewayResponse, IIGWTransactionDetails, ITransactionChange } from '../../types'
import { PHONE_TYPES_MAP } from '../../../client/beneficiaries/constants/PHONE_TYPES'
import { CS_PARTY_TYPES } from '../../../client/beneficiaries/constants/BENEFICIARY_OTHER_OPTIONS'

const ADDRESS_SECTION = {
  'role.address.line1': 'Line 1',
  'role.address.line2': 'Line 2',
  'role.address.line3': 'Line 3',
  'role.address.attention': 'Care of/Attn',
  'role.address.city': 'City',
  'role.address.state': 'State',
  'role.address.zip5': 'Zip',
  'role.address.zip4': 'Zip4',
  'role.address.addressCountry.value': 'Country',
}
const SECTION_DATA_MAP_PEOPLE: any = [
  {
    'name.prefix': 'Prefix',
    'name.first': 'First',
    'name.middle': 'Middle',
    'name.last': 'Last',
    'name.suffix': 'Suffix',
    'name.profSuffix': 'Professional Suffix',
    'role.role': 'Role',
    'role.relationshipToInsured.value': 'Relationship',
  },
  {
    'dob': 'DOB',
    'ssn': 'SSN',
    'ssnStatusLabel': 'SSN Certified',
    'gender.value': 'Gender',
  },
  {
    'shareDisplayName': 'Shares',
    'shareDistribution.distributionMethod.value': 'Distribution Method',
    'shareDistribution.settlementOption': 'Settlement Option',
  },
  ADDRESS_SECTION,
]

const SECTION_DATA_MAP_ORG: any = [
  {
    'name.fullName': 'Name',
    'role.role': 'Role',
    'role.relationshipToInsured.value': 'Relationship',
  },
  {
    'ssn': 'EIN',
    'ssnStatusLabel': 'Certified',
  },
  {
    'shareDisplayName': 'Shares',
    'shareDistribution.distributionMethod.value': 'Distribution Method',
    'shareDistribution.settlementOption': 'Settlement Option',
  },
  ADDRESS_SECTION,
]

const SECTION_DATA_MAP_TEXT: any = [
  {
    'name.fullName': 'Name',
    'role.role': 'Role',
    'role.relationshipToInsured.value': 'Relationship',
  },
  {
    'shareDisplayName': 'Shares',
    'shareDistribution.distributionMethod.value': 'Distribution Method',
    'shareDistribution.settlementOption': 'Settlement Option',
  },
]

const SECTION_MAP: any = {
  [CS_PARTY_TYPES.ORGANIZATION]: SECTION_DATA_MAP_ORG,
  [CS_PARTY_TYPES.PERSON]: SECTION_DATA_MAP_PEOPLE,
  [CS_PARTY_TYPES.TEXT]: SECTION_DATA_MAP_TEXT,
}


class TransactionDetailsController {
  static $inject: string[] = ['utils', '$filter']

  headerInfo: any
  detailsResponse: GenericGatewayResponse
  details: IIGWTransactionDetails
  sections: ITransactionChange[][] = []
  phoneSection: ITransactionChange[] = []
  errorView: string

  constructor(private utils, private $filter) { }

  buildDisplayModel(): void {
    let beforeData
    let afterData
    let partyTypeCode

    this.details = this.detailsResponse.data
    this.details.params = this.details.params ? JSON.parse(this.details.params) : {}

    if (this.detailsResponse.error) {
      beforeData = this.details.beforeSnapshot || {}
      afterData = this.details.afterSnapshot || {}

      // this.details.odataRaw = this.details.odataRaw ? JSON.parse(this.details.odataRaw) : {}
      this.errorView = ''
    } else {
      beforeData = this.details.beforeSnapshot
      afterData = this.details.afterSnapshot
    }

    partyTypeCode = this.details.party.partyTypeCode.tc

    const SECTION_DATA_MAP = SECTION_MAP[partyTypeCode]

    this.sections = SECTION_DATA_MAP.map((sectionMap: any) => {
      const tuples = Object.entries(sectionMap)
      const section: any = tuples.map(([propPath, label]): any => {
        const beforeValue = this.utils.getData(beforeData, propPath)
        const afterValue = this.utils.getData(afterData, propPath)
        const change: TransactionChange = new TransactionChange(label as string, beforeValue, afterValue)

        if (propPath === 'dob') {
          change.newValue = this.$filter('date')(change.newValue, 'MMM dd yyyy')
          change.originalValue = this.$filter('date')(change.originalValue, 'MMM dd yyyy')
        }

        return change
      })

      return section
    })
  }


  buildPhoneModel(): void {
    const beforeData = this.details.beforeSnapshot?.phoneList?.sorted || []
    const afterData = this.details.afterSnapshot?.phoneList?.sorted || []

    // if (afterData && beforeData) {
    afterData.forEach((phoneNumber: PhoneNumber) => {
      const foundNumber = beforeData.find((n) => n.id === phoneNumber.id)

      // Edit
      if (foundNumber && !phoneNumber?.isEmpty) this.phoneSection.push(new TransactionChange(PHONE_TYPES_MAP[phoneNumber.type.tc], this.$filter('phoneFormat')(foundNumber.completeNumber) , this.$filter('phoneFormat')(phoneNumber.completeNumber)))

      // Add
      if (!foundNumber && !phoneNumber.isEmpty) this.phoneSection.push(new TransactionChange(PHONE_TYPES_MAP[phoneNumber.type.tc], '', this.$filter('phoneFormat')(phoneNumber.completeNumber)))
    })

    // Delete
    beforeData.forEach((phoneNumber: PhoneNumber) => {
      const foundNumber = afterData.find((n) => n.id === phoneNumber.id)

      if (!foundNumber && !phoneNumber.isEmpty) this.phoneSection.push(new TransactionChange('Deleted', this.$filter('phoneFormat')(phoneNumber.completeNumber), ''))
    })
  }

  $onInit() {
    this.buildDisplayModel()
    this.buildPhoneModel()
  }
}

export const transactionDetailsComponentConfig = {
  templateUrl: 'app/transaction-history/components/details/transaction-details.html',
  controller: TransactionDetailsController,
  controllerAs: 'trxDetailsCtrl',
  bindings: {
    detailsResponse: '<',
    headerInfo: '<',
    countries: '<',
  },
}
