  wgClientAddPaymentAccountController.$inject = ['$scope', 'clientService', 'CONSTANTS', 'epayMessageResolver']

  function wgClientAddPaymentAccountController($scope, clientService, CONSTANTS, epayMessageResolver) {
    const self = this

    self.formData = {
      defaultAccount: true,
    }
    self.accountTypeOptions = [
      {
        name: 'C',
        display: 'Checking',
      },
      {
        name: 'S',
        display: 'Savings',
      },
    ]
    self.maxNumberPaymentAccounts = CONSTANTS.MAX_NUMBER_PAYMENT_ACCOUNTS
    self.formData.accountType = self.accountTypeOptions[0].name
    self.isOpen = false

    $scope.form = {}

    self.hasTooManyAccounts = function() {
      return self.numberOfAccounts >= CONSTANTS.MAX_NUMBER_PAYMENT_ACCOUNTS
    }

    self.saveNewAccount = function() {
      if ((self.formData.accountNumber && self.formData.accountNickname === undefined) || self.formData.accountNickname === '') {
        self.formData.accountNickname = (self.formData.accountType === 'S' ? 'Savings' : 'Checking') + '-XXX' + self.formData.accountNumber.substr(self.formData.accountNumber.length - 4)
      }

      self.loading = true
      clientService.saveAccount(self.formData).then(self.processAccountAddition)
    }

    self.processError = function(errorMessage) {

     const message = epayMessageResolver.resolveEpayErrorMessage(errorMessage.code, true, false, 'NA_NOT_POLICY_SPECIFIC')

     $scope.$emit('newAccountAlert', {
      type: CONSTANTS.eventType.danger,
      message: message,
    })

    //  if (CONSTANTS.accountResponses[errorMessage.code]?.description) {
    //     $scope.$emit('newAccountAlert', {
    //       type: CONSTANTS.eventType.danger,
    //       message: CONSTANTS.accountResponses[errorMessage.code].description,
    //     })
    //   } else {
    //     $scope.$emit('newAccountAlert', {
    //       type: CONSTANTS.eventType.danger,
    //       message: CONSTANTS.accountResponses.unknownError.description,
    //     })
    //   }

    }
 
    self.processAccountAddition = function(data) {
      let accountResponse

      if (!data.error && !data.errorMessage) {
        self.isOpen = false
        $scope.form.addAccount.$setPristine()
        $scope.form.addAccount.$setUntouched()
        self.formData = {}
        self.formData.accountType = self.accountTypeOptions[0].name
        self.formData.defaultAccount = true

        switch (data.data.code.tc) {
          case CONSTANTS.accountResponses.accountAdd.typeCode:
            accountResponse = {
              type: CONSTANTS.eventType.success,
              message: CONSTANTS.accountResponses.accountAdd.description,
            }
            break
          case CONSTANTS.accountResponses.accountUpdate.typeCode:
            accountResponse = {
              type: CONSTANTS.eventType.success,
              message: CONSTANTS.accountResponses.accountUpdate.description,
            }
            break
        }

        if (accountResponse) {
          $scope.$emit('newAccountAlert', accountResponse)
        }
      }

      if (data.error && data.errorMessage) {
        if (data.errorMessage.code) {
         self.processError(data.errorMessage)
        }
        self.formData.routingNumber = ''
      }
      self.loading = false
    }
  }

  angular.module('app.directives')
    .controller('wgClientAddPaymentAccountController', wgClientAddPaymentAccountController)
    .directive('wgClientAddPaymentAccount', [function() {
      return {
        restrict: 'EA',
        scope: {
          numberOfAccounts: '=',
        },
        bindToController: true,
        templateUrl: 'app/widgets/templates/wg-client-add-payment-account.html',
        controller: wgClientAddPaymentAccountController,
        controllerAs: 'ctrl',
      }
    }])
