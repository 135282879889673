export const COUNTRY_CODES = [ {
      tc: '1',
      value: 'United States & Canada',
    },
    {
      tc: '93',
      value: 'Afghanistan',
    },
    {
      tc: '355',
      value: 'Albania',
    },
    {
      tc: '213',
      value: 'Algeria',
    },
    {
      tc: '376',
      value: 'Andorra',
    },
    {
      tc: '244',
      value: 'Angola',
    },
    {
      tc: '1 264',
      value: 'Anguilla',
    },
    {
      tc: '54',
      value: 'Argentina',
    },
    {
      tc: '374',
      value: 'Armenia',
    },
    {
      tc: '297',
      value: 'Aruba',
    },
    {
      tc: '61',
      value: 'Australia',
    },
    {
      tc: '43',
      value: 'Austria',
    },
    {
      tc: '994',
      value: 'Azerbaijan',
    },
    {
      tc: '1 242',
      value: 'Bahamas',
    },
    {
      tc: '973',
      value: 'Bahrain',
    },
    {
      tc: '880',
      value: 'Bangladesh',
    },
    {
      tc: '1 246',
      value: 'Barbados',
    },
    {
      tc: '375',
      value: 'Belarus',
    },
    {
      tc: '32',
      value: 'Belgium',
    },
    {
      tc: '501',
      value: 'Belize',
    },
    {
      tc: '229',
      value: 'Benin',
    },
    {
      tc: '1 441',
      value: 'Bermuda',
    },
    {
      tc: '975',
      value: 'Bhutan',
    },
    {
      tc: '591',
      value: 'Bolivia',
    },
    {
      tc: '387',
      value: 'Bosnia and Herzegovina',
    },
    {
      tc: '267',
      value: 'Botswana',
    },
    {
      tc: '55',
      value: 'Brazil',
    },
    {
      tc: '673',
      value: 'Brunei ',
    },
    {
      tc: '359',
      value: 'Bulgaria',
    },
    {
      tc: '226',
      value: 'Burkina Faso',
    },
    {
      tc: '257',
      value: 'Burundi',
    },
    {
      tc: '855',
      value: 'Cambodia',
    },
    {
      tc: '237',
      value: 'Cameroon',
    },
    {
      tc: '238',
      value: 'Cape Verde',
    },
    {
      tc: '1345',
      value: 'Cayman Islands',
    },
    {
      tc: '235',
      value: 'Chad',
    },
    {
      tc: '56',
      value: 'Chile',
    },
    {
      tc: '86',
      value: 'China',
    },
    {
      tc: '57',
      value: 'Colombia',
    },
    {
      tc: '269',
      value: 'Comoros',
    },
    {
      tc: '243',
      value: 'Congo',
    },
    {
      tc: '682',
      value: 'Cook Islands',
    },
    {
      tc: '506',
      value: 'Costa Rica',
    },
    {
      tc: '225',
      value: 'Cote d\'Ivoire',
    },
    {
      tc: '385',
      value: 'Croatia',
    },
    {
      tc: '53',
      value: 'Cuba',
    },
    {
      tc: '357',
      value: 'Cyprus',
    },
    {
      tc: '420',
      value: 'Czech Republic',
    },
    {
      tc: '45',
      value: 'Denmark',
    },
    {
      tc: '253',
      value: 'Djibouti',
    },
    {
      tc: '1 767',
      value: 'Dominica',
    },
    {
      tc: '1 849',
      value: 'Dominican Republic',
    },
    {
      tc: '593',
      value: 'Ecuador',
    },
    {
      tc: '20',
      value: 'Egypt',
    },
    {
      tc: '503',
      value: 'El Salvador',
    },
    {
      tc: '240',
      value: 'Equatorial Guinea',
    },
    {
      tc: '291',
      value: 'Eritrea',
    },
    {
      tc: '372',
      value: 'Estonia',
    },
    {
      tc: '251',
      value: 'Ethiopia',
    },
    {
      tc: '298',
      value: 'Faroe Islands',
    },
    {
      tc: '679',
      value: 'Fiji',
    },
    {
      tc: '358',
      value: 'Finland',
    },
    {
      tc: '33',
      value: 'France',
    },
    {
      tc: '594',
      value: 'French Guiana',
    },
    {
      tc: '689',
      value: 'French Polynesia',
    },
    {
      tc: '241',
      value: 'Gabon',
    },
    {
      tc: '220',
      value: 'Gambia',
    },
    {
      tc: '995',
      value: 'Georgia',
    },
    {
      tc: '49',
      value: 'Germany',
    },
    {
      tc: '233',
      value: 'Ghana',
    },
    {
      tc: '350',
      value: 'Gibraltar',
    },
    {
      tc: '30',
      value: 'Greece',
    },
    {
      tc: '299',
      value: 'Greenland',
    },
    {
      tc: '1 473',
      value: 'Grenada',
    },
    {
      tc: '590',
      value: 'Guadeloupe',
    },
    {
      tc: '1 671',
      value: 'Guam',
    },
    {
      tc: '502',
      value: 'Guatemala',
    },
    {
      tc: '224',
      value: 'Guinea',
    },
    {
      tc: '245',
      value: 'Guinea-Bissau',
    },
    {
      tc: '592',
      value: 'Guyana',
    },
    {
      tc: '509',
      value: 'Haiti',
    },
    {
      tc: '504',
      value: 'Honduras',
    },
    {
      tc: '852',
      value: 'Hong Kong',
    },
    {
      tc: '36',
      value: 'Hungary',
    },
    {
      tc: '354',
      value: 'Iceland',
    },
    {
      tc: '91',
      value: 'India',
    },
    {
      tc: '62',
      value: 'Indonesia',
    },
    {
      tc: '353',
      value: 'Ireland',
    },
    {
      tc: '972',
      value: 'Israel',
    },
    {
      tc: '39',
      value: 'Italy',
    },
    {
      tc: '1 876',
      value: 'Jamaica',
    },
    {
      tc: '81',
      value: 'Japan',
    },
    {
      tc: '44',
      value: 'Jersey',
    },
    {
      tc: '962',
      value: 'Jordan',
    },
    {
      tc: '7',
      value: 'Kazakhstan',
    },
    {
      tc: '254',
      value: 'Kenya',
    },
    {
      tc: '686',
      value: 'Kiribati',
    },
    {
      tc: '82',
      value: 'Korea',
    },
    {
      tc: '965',
      value: 'Kuwait',
    },
    {
      tc: '996',
      value: 'Kyrgyzstan',
    },
    {
      tc: '856',
      value: 'Laos',
    },
    {
      tc: '371',
      value: 'Latvia',
    },
    {
      tc: '961',
      value: 'Lebanon',
    },
    {
      tc: '266',
      value: 'Lesotho',
    },
    {
      tc: '231',
      value: 'Liberia',
    },
    {
      tc: '218',
      value: 'Libya',
    },
    {
      tc: '423',
      value: 'Liechtenstein',
    },
    {
      tc: '370',
      value: 'Lithuania',
    },
    {
      tc: '352',
      value: 'Luxembourg',
    },
    {
      tc: '853',
      value: 'Macao',
    },
    {
      tc: '261',
      value: 'Madagascar',
    },
    {
      tc: '265',
      value: 'Malawi',
    },
    {
      tc: '60',
      value: 'Malaysia',
    },
    {
      tc: '960',
      value: 'Maldives',
    },
    {
      tc: '223',
      value: 'Mali',
    },
    {
      tc: '356',
      value: 'Malta',
    },
    {
      tc: '596',
      value: 'Martinique',
    },
    {
      tc: '222',
      value: 'Mauritania',
    },
    {
      tc: '230',
      value: 'Mauritius',
    },
    {
      tc: '52',
      value: 'Mexico',
    },
    {
      tc: '691',
      value: 'Micronesia',
    },
    {
      tc: '373',
      value: 'Moldova',
    },
    {
      tc: '377',
      value: 'Monaco',
    },
    {
      tc: '976',
      value: 'Mongolia',
    },
    {
      tc: '382',
      value: 'Montenegro',
    },
    {
      tc: '1664',
      value: 'Montserrat',
    },
    {
      tc: '212',
      value: 'Morocco',
    },
    {
      tc: '258',
      value: 'Mozambique',
    },
    {
      tc: '95',
      value: 'Myanmar',
    },
    {
      tc: '264',
      value: 'Namibia',
    },
    {
      tc: '674',
      value: 'Nauru',
    },
    {
      tc: '977',
      value: 'Nepal',
    },
    {
      tc: '31',
      value: 'Netherlands',
    },
    {
      tc: '687',
      value: 'New Caledonia',
    },
    {
      tc: '64',
      value: 'New Zealand',
    },
    {
      tc: '505',
      value: 'Nicaragua',
    },
    {
      tc: '227',
      value: 'Niger',
    },
    {
      tc: '234',
      value: 'Nigeria',
    },
    {
      tc: '683',
      value: 'Niue',
    },
    {
      tc: '47',
      value: 'Norway',
    },
    {
      tc: '968',
      value: 'Oman',
    },
    {
      tc: '92',
      value: 'Pakistan',
    },
    {
      tc: '680',
      value: 'Palau',
    },
    {
      tc: '970',
      value: 'Palestine ',
    },
    {
      tc: '507',
      value: 'Panama',
    },
    {
      tc: '675',
      value: 'Papua New Guinea',
    },
    {
      tc: '595',
      value: 'Paraguay',
    },
    {
      tc: '51',
      value: 'Peru',
    },
    {
      tc: '63',
      value: 'Philippines',
    },
    {
      tc: '48',
      value: 'Poland',
    },
    {
      tc: '351',
      value: 'Portugal',
    },
    {
      tc: '1 939',
      value: 'Puerto Rico',
    },
    {
      tc: '974',
      value: 'Qatar',
    },
    {
      tc: '40',
      value: 'Romania',
    },
    {
      tc: '7',
      value: 'Russia',
    },
    {
      tc: '250',
      value: 'Rwanda',
    },
    {
      tc: '1 869',
      value: 'Saint Kitts',
    },
    {
      tc: '1 758',
      value: 'Saint Lucia',
    },
    {
      tc: '685',
      value: 'Samoa',
    },
    {
      tc: '378',
      value: 'San Marino',
    },
    {
      tc: '966',
      value: 'Saudi Arabia',
    },
    {
      tc: '221',
      value: 'Senegal',
    },
    {
      tc: '381',
      value: 'Serbia',
    },
    {
      tc: '248',
      value: 'Seychelles',
    },
    {
      tc: '232',
      value: 'Sierra Leone',
    },
    {
      tc: '65',
      value: 'Singapore',
    },
    {
      tc: '421',
      value: 'Slovakia',
    },
    {
      tc: '386',
      value: 'Slovenia',
    },
    {
      tc: '677',
      value: 'Solomon Islands',
    },
    {
      tc: '252',
      value: 'Somalia',
    },
    {
      tc: '27',
      value: 'South Africa',
    },
    {
      tc: '34',
      value: 'Spain',
    },
    {
      tc: '94',
      value: 'Sri Lanka',
    },
    {
      tc: '249',
      value: 'Sudan',
    },
    {
      tc: '597',
      value: 'Suriname',
    },
    {
      tc: '46',
      value: 'Sweden',
    },
    {
      tc: '41',
      value: 'Switzerland',
    },
    {
      tc: '963',
      value: 'Syria',
    },
    {
      tc: '886',
      value: 'Taiwan',
    },
    {
      tc: '992',
      value: 'Tajikistan',
    },
    {
      tc: '255',
      value: 'Tanzania',
    },
    {
      tc: '66',
      value: 'Thailand',
    },
    {
      tc: '228',
      value: 'Togo',
    },
    {
      tc: '676',
      value: 'Tonga',
    },
    {
      tc: '1 868',
      value: 'Trinidad',
    },
    {
      tc: '216',
      value: 'Tunisia',
    },
    {
      tc: '90',
      value: 'Turkey',
    },
    {
      tc: '993',
      value: 'Turkmenistan',
    },
    {
      tc: '688',
      value: 'Tuvalu',
    },
    {
      tc: '256',
      value: 'Uganda',
    },
    {
      tc: '380',
      value: 'Ukraine',
    },
    {
      tc: '971',
      value: 'UAE',
    },
    {
      tc: '44',
      value: 'United Kingdom',
    },
    {
      tc: '598',
      value: 'Uruguay',
    },
    {
      tc: '998',
      value: 'Uzbekistan',
    },
    {
      tc: '678',
      value: 'Vanuatu',
    },
    {
      tc: '58',
      value: 'Venezuela',
    },
    {
      tc: '84',
      value: 'Vietnam',
    },
    {
      tc: '1 284',
      value: 'Virgin Islands, British',
    },
    {
      tc: '1 340',
      value: 'Virgin Islands, U.S.',
    },
    {
      tc: '732',
      value: 'Western Sahara',
    },
    {
      tc: '967',
      value: 'Yemen',
    },
    {
      tc: '260',
      value: 'Zambia',
    },
    {
      tc: '263',
      value: 'Zimbabwe',
    },
  ]


