export {wgAdvisorContactInfo}
function wgAdvisorContactInfo() {
  return {
    restrict: 'E',
    controller: AdvisorContactInfoController,
    controllerAs: 'contactInfCtrl',
    templateUrl: 'app/widgets/client/templates/wg-advisor-contact-information.html',
  }
}

AdvisorContactInfoController.$inject = ['$rootScope', 'popupManager', 'CONSTANTS', 'clientUtils']

  /* @ngInject */
function AdvisorContactInfoController($rootScope, popupManager, CONSTANTS, clientUtils) {
  const self = this
  const AGENT_POPUP_ID = CONSTANTS.popups.AGENT_POPUP_ID

  self.advisors = []

  popupManager.register(AGENT_POPUP_ID)

  self.isAgentPopupOpen = function() {
    return popupManager.isOpen(AGENT_POPUP_ID)
  }

  self.isTerminated = function(advisor) {
    return advisor.status === 'Terminated'
  }

  $rootScope.$on('SHOW_AGENT_POPUP', function(e, agents) {
    self.advisors = []

    angular.forEach(agents, function(agentItem) {
      const agent = agentItem.agent
      const advisor = {
        address: agent.address[0],
        cityStateZip: formatCityStateZip(agent.address[0]),
        fullName: clientUtils.partyName(agent),
        phone: !agent.phone || agent.phone.length === 0 ? undefined : agent.phone[0].number,
        email: !agent.emailAddress || agent.emailAddress.length === 0 ? undefined : agent.emailAddress[0].addrLine,
        status: agent.status,
      }

      self.advisors.push(advisor)
    })

    self.toggleAgentPopup()
  })

  self.toggleAgentPopup = function() {
    popupManager.toggle(AGENT_POPUP_ID)
  }

  function formatCityStateZip(address) {
    let result = ''

    if (address.city) {
      result = address.city
    }
    if (address.state) {
      if (result) {
        result += ', '
      }
      result += address.state
    }
    if (address.zip) {
      if (result) {
        result += ' '
      }
      result += address.zip
    }
    return result
  }
}
