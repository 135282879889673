export {PolicyDetailPaymentsController}

PolicyDetailPaymentsController.$inject = ['$stateParams', 'policyService', 'dateUtils', 'CONSTANTS']

function PolicyDetailPaymentsController($stateParams, policyService, dateUtils) {
  const self = this

  self.id = $stateParams.id
  self.policyBase = {}
  self.loan = {}

  self.errorMessage = ''
  self.isThereError = false

  self.isPastDueDate = function(dueDate) {
    return dateUtils.isPastDueDate(dueDate)
  }

    // self.showMailingInfo = function () {
    //     return self.policyBase.productCode.toLowerCase().indexOf(CONSTANTS.productCodes.MYGA) === -1 &&
    //         self.policyBase.productCode.toLowerCase().indexOf(CONSTANTS.productCodes.FIA) === -1;
    // };

  policyService.getPolicyLoans(self.id).then(function(result) {
    if (result.error) {
      self.loan = {}
      setError(result.error)
    } else if (result.loans && result.loans.length > 0) {
      self.loan = result.loans[0]
    } else {
      self.loan = {}
    }
  })

  function setError(error) {
    self.errorMessage = error
    self.isThereError = Boolean(self.errorMessage)
  }
}
