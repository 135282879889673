import { ClientRole } from './client-role'

/**
 *
 */
export class ClientRoles /* extends Array<ClientRole> implements IClientRoles */ {
  _allClientRoles: ClientRole[] = []

  constructor(rawRoles) {
    rawRoles.forEach((rawRole: any) => {
      this._allClientRoles.push(new ClientRole(rawRole))
    })
  }

  /**
   *  2. Order. Try to order elements in the dropdown in "phone book order" like this:
   *    Breslow, Todd
   *    Brooklyn Manufactuering
   *    Candy Enterprises
   *    Crend, Matthew
   */
  phoneBookSortedParties(): ClientRole[] {
    const sortedRoles: ClientRole[] = this._allClientRoles.sort((a, b) => {
      if (a.fullName < b.fullName) {
        return -1
      } else if (a.fullName > b.fullName) {
        return 1
      } else {
        return 0
      }
    })

    return sortedRoles
  }

  // dedupe(beneficiaries: Beneficiary[]): any[] {
  //   const beneIds = beneficiaries.map((bene) => bene.id)
  //   const deduped = this.reduce((dedupedParties, party) => {
  //     if (!beneIds.includes(party.id)) dedupedParties.push(party)

  //     return dedupedParties
  //   }, [])

  //   return deduped
  // }

}
