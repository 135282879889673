import { BeneficiaryAddress } from '../../beneficiaries/classes/address'

export class RolesEditAddressEditController {
  static $inject: any = ['stateUtils', '$element']

  address: BeneficiaryAddress
  countries: any[]

  constructor(public stateUtils, public $element) {}

  previous = () => this.stateUtils.checkUserAndGotoState('roles.edit.personal-information')

  // next = () => this.stateUtils.checkUserAndGotoState('roles.edit.personal-information')

  $onInit() {
    this.address = new BeneficiaryAddress({
      addressCountry: {
        isSeparator: false,
        tc: '1',
        value: 'United States of America',
      },
      addressTypeCode: {
        isSeparator: false,
        tc: '1',
        value: 'Home',
        group: '',
        hasTypeCode: false,
      },
      city: '',
      isNew: true,
      line1: '',
      line2: '',
      state: '',
      zip4: '',
      zip5: '',
    })

    this.countries = [
      {
        tc: '1',
        value: 'United States of America',
      },
      {
        tc: '93',
        value: 'Afghanistan',
      },
      {
        tc: '248',
        value: 'Aland Islands',
      },
      {
        tc: '355',
        value: 'Albania',
      },
    ]
  }
}
