import { IController, IOnChangesObject } from "angular"

class WarningModalController implements IController {
  $inject = []

  modalInstance: any


  constructor() { }

  closeModal(): void {
    this.modalInstance.dismiss()
  }

  confirm(): void {
    this.modalInstance.close()
  }

  $onInit(): void {
    console.log(this.modalInstance)
  }

  $onDestroy(): void {
    console.log('$onDestroy')
  }

  $onChanges(onChangesObj: IOnChangesObject): void {
    console.log(onChangesObj)
  }

}

export const warningModalComponentConfig = {
  templateUrl: 'app/client/client-bene-edit/warning-modal/warning-modal.html',
  controller: WarningModalController,
  controllerAs: 'warningModalCtrl',
  bindings: {
    modalInstance: '<',
  },
}
