import { IController, IQService } from 'angular'
import { ModalService } from '../modal/modal-service'
import { AddressValidationResult, IAddress } from '../../client/address/types'
import { Beneficiary } from '../../client/beneficiaries/classes/beneficiary'
import { BeneficiaryAddress } from '../../client/beneficiaries/classes/address'
import { TypeCodeValue } from '../../client/beneficiaries/classes/type-code-value'

interface IAddressValidationResolve {
  standardizedAddress: AddressValidationResult
  currentAddress: BeneficiaryAddress
  errorMessage: any
}

class AddressValidationController implements IController {
  static $inject: any = ['modalService']

  modalInstance: any
  suggestedAddress: AddressValidationResult
  newAddress: IAddress
  resolve: IAddressValidationResolve
  standardizedAddress: any
  currentAddress: BeneficiaryAddress
  errorMessage: any
  chosenAddress: string = 'suggestedAddress'

  constructor(private modalService: ModalService) {

  }

  // _errorHandler(err: any): void {

  // }

  // _successHandler(result: any): void {
  // }

  applySuggestions(): void {
    const addressScrubInd: any = this.chosenAddress === 'suggestedAddress'
    const addressToUse: AddressValidationResult | BeneficiaryAddress = addressScrubInd ? this.standardizedAddress : this.currentAddress

    if (addressScrubInd) {
      addressToUse.id = this.currentAddress.id
      addressToUse.constructor = { name: 'AddressValidationResult' }
      addressToUse.addressTypeCode = this.currentAddress?.addressTypeCode ? this.currentAddress.addressTypeCode : new TypeCodeValue({ tc: '1', value: 'Home' })
      addressToUse.addressCountry = this.currentAddress?.addressCountry ? this.currentAddress.addressCountry : new TypeCodeValue({ value: 'United States of America', tc: '1' })
      addressToUse.barCodeCheckDigit = '4'
      addressToUse.addressScrubInd = addressScrubInd
    }

    this.closeModal(new BeneficiaryAddress(addressToUse))
  }

  closeModal(address: BeneficiaryAddress): void {
    this.modalService.modalInstance.close(address)
  }

  cancelModal(address): void {
    this.modalService.modalInstance.dismiss(address)
  }

  /**
   * AngularJS life-cycle hook.
   */
  $onInit(): void {
    this.standardizedAddress = this.resolve.standardizedAddress
    this.currentAddress = this.resolve.currentAddress
    this.errorMessage = this.resolve.errorMessage
  }

  $onDestroy(): void {

  }
}

export const AddressValidationComponentConfig = {
  templateUrl: 'app/components/address-validation/address-validation.html',
  controller: AddressValidationController,
  controllerAs: 'manageAddressesCtrl',
  bindings: {
    resolve: '<',
  },
}
