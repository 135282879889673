/**
 * Tracks click events that pass event names and action names to Google Tag Mangager
 */

class GtmClickTracker implements ng.IDirective {

  restrict = 'A'
  replace = false

  /**
   * AngularJS post link function use for initial configuration of instances of GtmClickTracker
   */
  constructor(private utils: any) {}

  /**
   * Creates an instance of GtmClickTracker, with dependencies injected.
   */
  static factory (): ng.IDirectiveFactory {

    const directive = (utils) => new GtmClickTracker(utils)

    directive.$inject = ['utils']

    return directive
  }

  link (_scope, el, attrs) {
    el.on('click', () => {
      if (window.dataLayer) {
        window.dataLayer.push(this.utils.buildGtmObject(attrs.gtmClickTrackEvent, attrs.gtmClickTrackAction))
      }
    })
  }
}

angular.module('app.directives')
  .directive('gtmClickTrack', GtmClickTracker.factory())
