
userDataService.$inject = ['$http', 'authService', 'policyUtils', 'clientUtils', 'CONSTANTS', 'dateUtils', 'utils', '$q']

  /* @ngInject */
function userDataService($http, authService, policyUtils, clientUtils, CONSTANTS, dateUtils, utils, $q) {
  const service = {}

  service.data = null

  service.getDataPromise = function() {
    if (!service.data) {
      return service.refreshDataPromise()
    }

    return $q.when(service.data)
  }

  service.refreshDataPromise = function() {
    return service._fetchDataPromise()
        .then(function(data) {
          if (!data) { // the user has no existing data
            data = {}

            service._saveDataPromise(data)
          }

          service.data = data

          return data
        })
  }

  service.updateDataPromise = function(data) {
    if (data) {
      return service._saveDataPromise(data)
          .then(service.refreshDataPromise)
    }

    return $q.when(data)
  }

  service._saveDataPromise = function(data) {
    return $http.post(CONSTANTS.userDataURL, data)
        .then(function success(httpData) {
          if (!httpData.data) {
            console.error('Error syncing user data with server')
          }
        })
        .catch(function failure(err) {
          console.error('Error syncing user data with server:', err)
        })
  }

  service._fetchDataPromise = function() {
    return $http.get(CONSTANTS.userDataURL)
        .then(function success(httpData) {
          if (!httpData.data) {
            return null
          }
          return httpData.data
        })
        .catch(function failure(err) {
          console.log('Error retrieving user data from server: ', err)
          return null
        })
  }

  return service
}

export {userDataService}
