class ReplyChoice implements ng.IDirective {
  restrict = 'E'
  templateUrl = 'app/replyable-requirements/reply-choice/template.html'
  scope: {
    requirement: '=',
  }
  replace: true

  // constructor() {}

  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    scope.closeReplyRow = scope.$parent.parentController.closeReplyRow
    scope.openUploadReply = scope.$parent.parentController.openUploadReply
    scope.openTextReply = scope.$parent.parentController.openTextReply
  }

  static factory(): ng.IDirectiveFactory {

    const directive = () => new ReplyChoice()

    directive.$inject = []

    return directive
  }
}

angular.module('app.directives')
  .directive('replyChoice', ReplyChoice.factory())
