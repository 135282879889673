export class DownloadManager {
  alertDownloadFields: any

  constructor(private utils, private CONSTANTS, private partyService, private authService) {

    this.alertDownloadFields = {
      'defaultFields': [
        'activityType',
        'activityCategory',
        'activityDate',
        'servingOfficeCode',
        'servicingAgentCode',
        'polNumber',
        'insureds',
        'agent',
        'marketingName',
        'remarks',
      ],
      'required minimum distribution': [
        'polNumber',
        'annuitant',
        'annuitantIssueAge',
        'marketingName',
        'annuityRider',
        'grossAmount',
        'servingOfficeCode',
        'servicingAgentCode',
        'agent',
        'arrAutoPayDate',
        'arrPayFrequency',
        'arrPayoutType',
        'letterType',
      ],
    }
  }

  get (url: string, rows?, fields?: string[], options?) {
    fields = fields || []
    options = options || {}

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())

    // Get all policies
    url = this.utils.appendURLParameter(url, 'rows', rows)

    // Add all fields we want to the request URL
    fields.forEach((f) => url = this.utils.appendURLParameter(url, 'field', f))

    // Add options to the request URL
    Object.keys(options).forEach((key) => url = this.utils.appendURLParameter(url, key, options[key]))

    // Keep the same session
    url = this.utils.appendURLParameter(url, 'sid', this.authService.getSessionID())
    console.log('getAllAlerts: ', url)

    window.open(url, '_system')
  }

  getAllAlerts (category, options) {
    const fields = this.alertDownloadFields[category] || this.alertDownloadFields.defaultFields
    let url = this.CONSTANTS.downloadAlerts

    this.get(url, 1000, fields, options)
  }

  getAllBOB (options) {
    let allFields = [
      'productName',
      'productType',
      'polNumber',
      'policyStatus',
      'subStatus',
      'insuredFullName',
      'aceIndicator',
      'ownerFullName',
      'payerFullName',
      'primaryServicingAgent',
      'faceAmt',
      'submitDate',
      'lastUpdateDate',
    ]

    let url = this.CONSTANTS.downloadBOBURL

    this.get(url, 1000, allFields, options)
  }

  getAllUnsubmitted (options) {
    const allFields = [
      'insuredFullName',
      'ownerFullName',
      'policyStatus',
      'daysOpen',
      'primaryServicingAgent',
      'appStartDate',
      'lastUpdateDate',
      'hasIllustration',
      'expirydate',
      'formalappIndicator',
    ]

    let url = this.CONSTANTS.downloadBOBURL + '/app'

    this.get(url, 1000, allFields, options)
  }

  getAllClosed (source, options, fileName: string) {
    let allFields = [
      'productName',
      'insuredFullName',
      'policyStatus',
      'productType',
      'aceIndicator',
      'lineOfBusiness',
      'polNumber',
      'faceAmt',
      'ownerFullName',
      'lastUpdateDate',
      'issueDate',
      'primaryServicingAgent',
      'primaryAgentCode',
    ]

    let url = this.CONSTANTS.downloadBOBURL + '?holdingstatus=Closed'

    if (source === 'inforce') {
      url = this.CONSTANTS.downloadBOBURL + '/(inforce app)'
    }

    url = this.utils.appendURLParameter(url, 'fileName', fileName)

    return this.get(url, 1000, allFields, options)
  }

  getRequirements(policy: string) {
    let url = this.CONSTANTS.downloadRequirements.replace('{0}', policy)

    this.get(url)
  }
}
