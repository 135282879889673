import * as angular from 'angular'
export {OverlayUtils}
interface Overlayable {
  opaqueOverlay: boolean
  showOverlay: boolean
}

function OverlayUtils($timeout) {
  const service = {
      fadeOut (controller: Overlayable, delayInMilliseconds = 1): Promise<void> {
        return wait(delayInMilliseconds)()
          .then(() => controller.opaqueOverlay = false) // Remove the opaque CSS class.
          .then(wait(275)) // Wait for that transition to finish.
          .then(() => controller.showOverlay = false) // Let the user interact again.
      },

      transition (controller: Overlayable, midTransitionCallback: () => void): Promise<void> {
        controller.showOverlay = true // Stop user from messing with stuff.

        return wait(10)() // Briefly wait for the overlay to appear.
          .then(() => controller.opaqueOverlay = true) // Add the opaque CSS class shortly after showing the overlay so it transitions.
          .then(wait(275)) // Wait for the transition to finish.
          .then(midTransitionCallback.bind(controller))
          .then(wait(333)) // Wait for the DOM to finish rejiggering itself.
          .then(() => service.fadeOut(controller))
      },
    }

  function wait(milliseconds) {
      return $timeout.bind(service, angular.noop, milliseconds)
    }

  return service
}

OverlayUtils.$inject = ['$timeout']
