import { IController, IFormController, INgModelController } from "angular"
import { IGW_DISTRIBUTION_TYPECODES } from "../../client/beneficiaries/beneficiary-constants"
import { TypeCodeValue } from "../../client/beneficiaries/classes/type-code-value"
import { CS_PARTY_TYPECODES, CS_PARTY_TYPES } from "../../client/beneficiaries/constants/BENEFICIARY_OTHER_OPTIONS"
import { IEditableBeneficiary } from "../../client/beneficiaries/types"

class ShareInputController implements IController {
  static $inject = ['$attrs', '$scope']

  isClient: any
  bene: IEditableBeneficiary
  categoryCtrl: any

  constructor(private $attrs, private $scope) { }

  get ngModel(): INgModelController {
    return this.$scope.$parent[`si_${this.$attrs.contentIndex}`]?.$$controls[0]
  }

  $onInit(): void {
    if (this.$attrs.userType === 'client' && !this.bene.isFinalBeneficiary /* && (!this.bene.isFlatAmount && !this.bene.isPercentage) */) {
      // Force to percentage if no type exists
      if (!this.bene.shareDistribution.type || this.bene.shareDistribution.type.tc === '') this.bene.shareDistribution.type = new TypeCodeValue(IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE)

      // Force to Person of no partyType exists.
      if (!this.bene.partyTypeCode || this.bene.partyTypeCode.tc === '') this.bene.partyTypeCode = new TypeCodeValue(CS_PARTY_TYPECODES[CS_PARTY_TYPES.PERSON])
    }

  }

  formatInput(viewValue: string): any {
    if (viewValue === undefined || viewValue === null) {
      return viewValue
    }
    let numericValue: number = parseFloat(parseFloat(viewValue).toFixed(2))
    // numericValue = parseFloat(numericValue.toFixed(2))

    // Check if it's a number and just has 1 dec place
    if (!isNaN(numericValue) && hasTooManyDec(parseFloat(viewValue))) {
      this.ngModel.$setViewValue(numericValue)
      this.ngModel.$render()
      return numericValue
    }

    return viewValue
  }

  private ngModelHooked: boolean = false
  $doCheck(): void {
    if (this.$attrs.userType === 'client' && this.ngModel && !this.ngModelHooked) {

      this.ngModel.$parsers.push((viewValue) => this.formatInput(viewValue))

      this.ngModel.$validators.numeric = (modelValue, viewValue) => {
        if (this.ngModel.$isEmpty(modelValue)) {
          return true // Empty values are considered valid
        }
        // Check if the value is numeric
        if (isNaN(viewValue)) {
          return false
        } else {
          let retVal = modelValue <= 100 && modelValue > 0 // && !hasTooManyDec(modelValue)
          // console.count("retVal=" + retVal)
          return retVal
        }
      }

      this.ngModelHooked = true
    }
  }

}

function hasTooManyDec(value) {
  const decimalPlaces = (value.toString().split('.')[1] || '').length
  return decimalPlaces > 2
}

export const shareInputComponent = {
  templateUrl: ['$attrs', ($attrs) => {
    if ($attrs.userType === 'client') return 'app/components/share-input/client-share-input.html'

    return 'app/components/share-input/share-input.html'
  }],
  controller: ShareInputController,
  controllerAs: 'shareInputCtrl',
  bindings: {
    bene: '<',
  },
  require: {
    categoryCtrl: '^beneficiaryCategory',
  },
}
