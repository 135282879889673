import * as angular from 'angular'
import { ClientAddressService, ManageAddressesController } from './address'
import './advisor'
import './beneficiaries'
import './epay'
import './me'
import './roles'
import { ClientAddressesController } from './client-addresses.controller'
import { AccountsController } from './accounts-controller'
import { countryCodes } from '../mfa/country-data'
import { ClientDataProvider, ClientListController, ClientListRunner } from './list'
import { TaxDocumentController } from './tax-docs/tax-docs-controller'
import { ClientAlertController } from './client-alerts.controller'
import { ClientController } from './client-controller'
import { clientService } from './client-service'
import { appRun } from './client-routes' // maybe rename appRun
import { ClientBeneEditRunner } from './client-bene-edit/client-bene.routes'
import { ClientBeneficiariesController } from './client-bene-edit/client-bene.controller'
import { warningModalComponentConfig } from './client-bene-edit/warning-modal/warning-modal.component'
import { clientBeneChangeAgreementComponentConfig } from './client-bene-edit/bene-change-agreement/bene-change-agreement.component'
import { clientBeneListComponentConfig } from './client-bene-edit/list-route/client-bene-list.component'
import { clientBeneEditComponentConfig } from './client-bene-edit/edit-route/client-bene-edit.component'

angular.module('app.client', ['app.core', 'app.auth', 'app.correspondence', 'app.policy'])
  .controller('clientAddressesController', ClientAddressesController)
  .service('clientAddressService', ClientAddressService)
  .controller('ManageAddressesController', ManageAddressesController)
  .controller('AccountsController', AccountsController)
  .constant(
    'countryCodes', countryCodes)
  .service('clientDataProvider', ClientDataProvider)
  .controller('clientListController', ClientListController)
  .controller('TaxDocumentController', TaxDocumentController) // was app.policy
  .controller('ClientAlertController', ClientAlertController)
  .controller('ClientController', ClientController)
  .constant('CLIENT_LIST_ENDPOINTS', {
    CLIENTS: '/proxy/core-services-gateway/agent/clientList',
  })
  .factory('clientService', clientService)
  .controller('clientBeneficiariesController', ClientBeneficiariesController)
  .component('clientBeneListComponent', clientBeneListComponentConfig)
  .component('clientBeneEditComponent', clientBeneEditComponentConfig)
  .component('warningModalComponent', warningModalComponentConfig)
  .component('clientBeneChangeAgreement', clientBeneChangeAgreementComponentConfig)
  .run(appRun)
  .run((CONSTANTS, routerHelper) => new ClientListRunner(CONSTANTS, routerHelper))
  .run(['$transitions', 'routerHelper', 'configService', ($transitions, routerHelper, configService) => new ClientBeneEditRunner($transitions, routerHelper, configService)])
