export {AccountsController}
AccountsController.$inject = ['$scope', 'accountsResult', 'clientService', 'stateTransitions']

  /* @ngInject */
function AccountsController($scope, accountsResult, clientService, stateTransitions) {
  const self = this

  self.accounts = accountsResult.data
  self.enableCreditCards = false

  self.numberOfAccounts = function() {
    if (self.accounts) {
      return self.accounts.length
    }

    return 0
  }

  $scope.$on('newDefaultAccountAlert', function() {
    clientService.getPaymentAccounts(self.enableCreditCards).then(function(data) {
      self.accounts = data.data
    })
  })

  $scope.$on('newAccountAlert', function() {
    clientService.getPaymentAccounts(self.enableCreditCards).then(function(data) {
      self.accounts = data.data
    })
  })

  self.closeHandler = function(event) {
    if (event.target === event.currentTarget) {
      stateTransitions.goBack()
    }
  }
}
