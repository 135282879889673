/**
 * ## pmlListViewFilter
 *
 * ### Overview
 *
 * The directive adds and `<input>` tag with `ng-model="filterValue"` and
 * `ng-model-options="{debounce: 500}"` attributes to the input tag.
 *
 * This directive then watches it's scope for changes to `filterValue`, and
 * calls the controller's `filter()` method when an update occurs.
 *
 * This directive requires a parent directive of type `pmlNgListView`,
 * which provides a `filter` method, which accepts an optional instance of
 * `IDataFilter[]`.
 *
 * ### Usage
 *    ```html
 *    <pml-list-view-filter
 *      placeholder="Filter Trainings..."
 *      debounce="500"
 *      filter-columns="course state"
 *      show-clear-button class="col-sm-6 text-right"></pml-list-view-filter>
 *    ```
 * ### Attributes
 *
 * |Name|Descriptions|
 * |----|------------|
 * |placeholder|Defineds the `placeholder` text to show in the input tag.|
 * |debound|Amount of time in milliseconds to wait before applying the filter text. (Default = 500)|
 * |filter-columns|Space separated list of column (property) name to apply the filter text|
 * |show-clear-button|When added to the directive shows a button that when clicked clears the filter text. Optionally, settting the attribute value will set the buttons label. (Default = "Clear")|
 * |clear-button-class||
 * |input-class||
 *
 */
export class PmlListViewFilterDirective implements ng.IDirective {
  restrict = 'E'
  replace = false
  require = '^^pmlNgListView'
  // bindToController = true
  transclude = false

  // constructor() { } useless constructor
  compile(el: ng.IAugmentedJQuery, attrs: ng.IAttributes) {

    el.append(`<input type="text" placeholder="${attrs.placeholder}" ng-model="filterValue" ng-model-options="{debounce: ${attrs.debounce || 500}}" class="${attrs.inputClass}"/>`)

    if (attrs.hasOwnProperty('showClearButton')) {
      el.append(`<button class="${attrs.clearButtonClass}" type="button" ng-show="showClearFilterButton">${attrs.showClearButton}</button>`)
    }

    return this.link
  }

  /**
   * AngularJS post link function use for initial configuration of instances of TrainingListDirective
   */
  // tslint:disable-next-line
  link(scope: any, el: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
    // const input = el.find('input')
    const btn = el.find('button')
    const columns: string[] = attrs.filterColumns.split(' ')
    const filterValueWatch = scope.$watch('filterValue', (newFilterValue: string, oldFilterValue: string) => {
      /**
       * TODO: Currently the filter method only supports 'contains' searches. Future enhancements
       *       would allow for other types of search operators.
       */
      scope.showClearFilterButton = Boolean(newFilterValue)

      if (newFilterValue && newFilterValue !== oldFilterValue) {

        const filters: IDataFilter[] = columns.map(col => {
          return {field: col, value: newFilterValue, operator: 'in', logic: 'or' }
        })

        ctrl.filter(filters)

      } else {
        ctrl.filter()
      }
    })

    scope.showClearFilterButton = false

    if (attrs.hasOwnProperty('showClearButton')) {
      btn.on('click', () => {
        scope.filterValue = ''
        ctrl.filter()
        scope.$apply()
      })
    }

    // Clear the $watch listener when the directive is destroyed.
    scope.$on('$destroy', () => filterValueWatch())
  }

  /**
   * Creates an instance of TrainingsDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new PmlListViewFilterDirective()

    directive.$inject = []

    return directive
  }
}
