export function epayService(CONSTANTS, $log, clientService, epayMessageResolver, authService, loggingService) {
  const service = {}
  const payments = {} // keyed by policyId

  service.savePaymentInfo = function(policyId, model) {
    if (!payments[policyId]) {
      payments[policyId] = model
    } else {
      try {
        angular.copy(model, payments[policyId])
      } catch (e) {
        console.info('INFO', e)
      }
    }
  }

  service.mergeConfirmationInfo = function(policyId, model) {
    let payment = payments[policyId]

    if (!payment) {
      payment = payments[policyId] = {}
    }

    payment.confirmationNumber = model.confirmationNumber
    payment.accountNumberLast4 = model.accountNumberLast4
    payment.nextPaymentDate = new Date(model.nextPaymentDate)
    payment.errorMessage = model.errorMessage
    payment.errorCode = model.errorCode

    $log.debug('Merged payment state:', payment)
  }

  service.getErrorDescriptions = function(code, jurisdiction, pid, reason) {
    const isHo = authService.isHomeOfficeUser()
    const isClient = authService.isARealClient()
    const message = epayMessageResolver.resolveEpayErrorMessage(code, isClient, isHo, jurisdiction)

    if (pid && reason) {
      loggingService.warn(`Policy #: ${pid} Display Message: ${message}`, reason)
    }

    return message
  }

  service.showPaymentTryAgain = function(code) {
    return epayMessageResolver.showTryAgain(code)
  }

  service.getPaymentInfo = function(policyId) {
    return angular.copy(payments[policyId], {})
  }

  service.getNewPaymentModel = function() {
    return newPaymentModel()
  }

  service.hasSavedData = function(policyId) {
    return Boolean(payments[policyId])
  }

  return service
}

function newPaymentModel() {
  const paymentModel = {
    accountId: undefined, // if set, we use the existing account instead of form data
    accountNickname: undefined,
    accountNumber: undefined,
    accountType: 'C',
      // accountNickname: undefined,
    bypassSign: false,
    accountNumberLastFour: null,
    additionsPaymentAmount: 0,
    additionsPaymentDefault: 0,
    additionsPaymentMin: 0,
    additionsPaymentMax: 0,
    confirmationNumber: null,
    errorMessage: null,
    errorCode: null,
    initialPaymentAmount: 0,
    initialPaymentDefault: 0,
    initialPaymentMin: 0,
    initialPaymentMax: 0,
    isTraditional: false,
    loanPaymentAmount: 0,
    loanPaymentDefault: 0,
    loanPaymentMin: 0,
    loanPaymentMax: 0,
    nextPaymentDate: null,
    paymentDate: new Date(), // forced to be today's date per Ed
    paymentMode: undefined,
    pennCheckDate: undefined,
    periodLengthInMonths: 0,
    premiumPaymentAmount: 0,
    premiumPaymentBase: 0,
    premiumPaymentDefault: 0,
    premiumPaymentDividend: 0,
    premiumPaymentMin: 0,
    premiumPaymentMax: 0,
    routingNumber: undefined,
    saveAccountInfo: true,
    conversionCreditAmt: 0,
  }

  Object.defineProperties(paymentModel, {
    nickname: {
      get: () => paymentModel.accountNickname,
      set: (value) => {
        paymentModel.accountNickname = value
      },
    },
    type: {
      get: () => paymentModel.accountType,
      set: (value) => {
        paymentModel.accountType = value.value
      },
    },
  })

  return paymentModel
}

epayService.$inject = ['CONSTANTS', '$log', 'clientService', 'epayMessageResolver', 'authService', 'loggingService']
