import * as angular from 'angular'

const applicationTypePopup = 'applicationType'
const DEFAULT_FILTER_VALUE = 'All'
const productPopup = 'productType'
const lineOfBusinessPopup = 'lineofbusiness'
const policyStatusPopup = 'policyStatus'

  // TODO: Uncomment when the business decides if they want to allow not active polices to be downloaded.
  // interface DownloadOptions {
  //   productType?: string,
  //   aceindicator?: boolean,
  //   lineofbusiness?: string,
  //   policyStatus?: string,
  // }

interface ClosedParams {
  source: string,
  applicationMethod: string,
  deliveryStatus: string,
  stage: string,
  productType: string[],
  lineofbusiness: string[],
  policyStatusFilters: string[],
  issueState: string[],
}

interface CheckedOptions {
  checked: boolean,
  value: string,
}

interface ProductTypes {
  count: number,
  value: string,
  valueTypeCode: ValueTypeCode,
}

interface ValueTypeCode {
  value: string,
  tc: string,
}

export class ClosedPoliciesController {
  private applicationIssueStatePopup: string = 'issueState'
  public issueStates = [{
      checked: false,
      value: 'NY State',
    }, {
      checked: false,
      value: '49 States',
    }]
  private issueStateKey = 'issueState'
  public applicationMethods: CheckedOptions[]
  public appliedFiltersText: string
  public DEFAULT_FILTER_VALUE: string
  public dotChecked
  public isApplicationTypeMenuOpen: boolean
  public isLineOfBusinessMenuOpen: boolean
  public isPendingPolicy: boolean
  public isPolicyStatusesMenuOpen: boolean
  public isProductMenuOpen: boolean
  public isViewingClosedPending: boolean
  public lineOfBusinessFiltersText: string
  public lineOfBusinessOptions: CheckedOptions[]
  public params: ClosedParams
  public policyStatuses: CheckedOptions[]
  public policyStatusesFiltersText: string
  public policyStatusOptions: string
  public productTypes: ProductTypes[]
  public toggleApplicationTypeMenu
  public toggleLineOfBusinessMenu
  public togglePolicyStatusMenu
  public toggleProductMenu
  public showAppMethodDropdown: boolean
  public showIssueStatesFilter: boolean
  isIssueStateMenuOpen: {}
  toggleIssueStateMethodMenu: {}

  constructor (private $stateParams, public $state, private allPoliciesService, public popupManager, public utils, public policyUtils,
      public prodTypesResult, public policyStatusResult, public downloadManager, public authService, public $filter: angular.IFilterFunction, public CONSTANTS) {
      this.applicationMethods = [{
        checked: false,
        value: 'Electronic',
      }, {
        checked: false,
        value: 'Paper',
      }]

      this.lineOfBusinessOptions = [{
        checked: false,
        value: 'Life',
      }, {
        checked: false,
        value: 'Annuity',
      }]

      this.DEFAULT_FILTER_VALUE = 'All'
      this.appliedFiltersText = this.DEFAULT_FILTER_VALUE
      this.lineOfBusinessFiltersText = this.DEFAULT_FILTER_VALUE
      this.policyStatusOptions = this.DEFAULT_FILTER_VALUE
      this.policyStatusesFiltersText = this.DEFAULT_FILTER_VALUE
      this.popupManager.register(productPopup)
      this.popupManager.register(this.applicationIssueStatePopup)

      this.dotChecked = this.utils.dot('checked')
      this.productTypes = []
      this.policyStatuses = []

      this.isApplicationTypeMenuOpen = this.popupManager.isOpen.bind(this.popupManager, applicationTypePopup)
      this.isProductMenuOpen = this.popupManager.isOpen.bind(this.popupManager, productPopup)
      this.isLineOfBusinessMenuOpen = this.popupManager.isOpen.bind(this.popupManager, lineOfBusinessPopup)
      this.isPolicyStatusesMenuOpen = this.popupManager.isOpen.bind(this.popupManager, policyStatusPopup)
      this.isIssueStateMenuOpen = this.popupManager.isOpen.bind(this.popupManager, this.applicationIssueStatePopup)

      this.toggleApplicationTypeMenu = this.popupManager.toggle.bind(this.popupManager, applicationTypePopup)
      this.toggleProductMenu = this.popupManager.toggle.bind(this.popupManager, productPopup)
      this.toggleLineOfBusinessMenu = this.popupManager.toggle.bind(this.popupManager, lineOfBusinessPopup)
      this.togglePolicyStatusMenu = this.popupManager.toggle.bind(this.popupManager, policyStatusPopup)
      this.toggleIssueStateMethodMenu = this.popupManager.toggle.bind(this.popupManager, this.applicationIssueStatePopup)

      this.isPendingPolicy = this.policyUtils.isPendingPolicy
      this.isViewingClosedPending = true
      this.showAppMethodDropdown = true
      this.showIssueStatesFilter = this._showIssueStateFilter()

      this.productTypes = this.prodTypesResult.productTypes
      this.policyStatuses = this.policyStatusResult.policyStatuses

      this._setDefaultIssueStates()

      this.params = {
        source: 'closed',
        applicationMethod: this.$stateParams.applicationType || DEFAULT_FILTER_VALUE,
        deliveryStatus: this.$stateParams.deliveryStatus || DEFAULT_FILTER_VALUE,
        stage: 'New Business',
        productType: this.checkedProductTypeValues(),
        lineofbusiness: this.checkedLineOfBusinessValues(),
        policyStatusFilters: this.checkedPolicyStatusFiltersValues(),
        issueState: this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE),
      }
      this._restoreIssueStates()

      // this.showDownloadLink()
      this.allPoliciesService.resetData()
      this.updatePolicyStatuses(this.CONSTANTS.holdingStatusTypes.closed.description, this.params.stage)
     // this.nextPoliciesPage()
    }

  viewClosedInforcePolicies () {
      this.isViewingClosedPending = false
      this.params.stage = 'inforce'
      this.allPoliciesService.resetData()
      this.nextPoliciesPage()
      this.params.policyStatusFilters = []
      this.policyStatusesFiltersText = this.DEFAULT_FILTER_VALUE
      this.updatePolicyStatuses(this.CONSTANTS.holdingStatusTypes.inactive.description, this.params.stage)
      this.showAppMethodDropdown = this._showAppMethodDropdown()
    }

  viewClosedPendingPolicies () {
      this.isViewingClosedPending = true
      this.params.stage = 'New Business'
      this.allPoliciesService.resetData()
      this.nextPoliciesPage()
      this.params.policyStatusFilters = []
      this.policyStatusesFiltersText = this.DEFAULT_FILTER_VALUE
      this.updatePolicyStatuses(this.CONSTANTS.holdingStatusTypes.closed.description, this.params.stage)
      this.showAppMethodDropdown = this._showAppMethodDropdown()
    }

  _showIssueStateFilter(): boolean {
      return this.authService.isHomeOfficeUser()
    }

  _setDefaultIssueStates() {
      if (this._showIssueStateFilter()) {
        let response = this.authService.getCanAccessResponse()

        if (response.canAccess49 && !response.canAccessNY) {
          // set canAccess49 = checked
          this.issueStates[1].checked = true
        } else if (!response.canAccess49 && response.canAccessNY) {
          //
          this.issueStates[0].checked = true
        }
        this.$state.params.issueState = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)
      }
    }
  _showAppMethodDropdown (): boolean {
      return (this.params.lineofbusiness.includes('Annuity') && this.params.lineofbusiness.includes('Life')) ||
        (this.params.lineofbusiness.length === 1 && !this.params.lineofbusiness.includes('Annuity')) ||
        !this.params.lineofbusiness.length
    }

  updatePolicyStatuses (holdingStatus, stage) {
      return this.allPoliciesService.getClosedPolicyStatuses(holdingStatus, stage).then(result => {
        this.policyStatuses = result.policyStatuses
      })
    }

  showSearchHeader (): boolean {
      return this.$state.params.source !== 'notpaid'
    }

    // TODO: Uncomment when the business decides if they want to allow not active polices to be downloaded.
    // showDownloadLink () {
    //   let isCorrectRole = this.authService.checkRoles(['FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT', 'HOMEOFFICE'])

    //   return this.isViewingClosed() && isCorrectRole
    // }

  isViewingClosed (): boolean {
      return this.$state.params.source === 'closed'
    }

  nextPoliciesPage () {
      if (!this.allPoliciesService.isLoadingMore() && this.allPoliciesService.moreData) {
        this.allPoliciesService.nextPage(this.params)
      }
    }

  applicationMethodParam (applicationTypes, defaultValue): string {
      let checked = applicationTypes.filter(this.dotChecked)

      if (!checked.length || checked.length === this.applicationMethods.length) {
        return defaultValue
      }

      return checked[0].value
    }

  productTypesEmpty () {
      return this.productTypes && this.productTypes.length === 0
    }

  checkedProductTypeValues () {
      return this.productTypes
        .filter(this.isCheckedWithValue)
        .map(productType => productType.value)
    }

  checkedLineOfBusinessValues () {
      return this.lineOfBusinessOptions
        .filter(this.isCheckedWithValue)
        .map(lobValue => lobValue.value)
    }

  checkedPolicyStatusFiltersValues () {
      return this.policyStatuses
        .filter(this.isCheckedWithValue)
        .map(policyStatus => policyStatus.value)
    }

  isCheckedWithValue (object): boolean {
      return object.checked && object.value
    }

  setAppliedFiltersText () {
      this.appliedFiltersText = this.checkedProductTypeValues().join(', ') || DEFAULT_FILTER_VALUE
    }

  setLineOfBusinessFiltersText (): void {
      this.lineOfBusinessFiltersText = this.checkedLineOfBusinessValues().join(', ') || DEFAULT_FILTER_VALUE
    }

  setPolicyStatusFiltersText (): void {
      this.policyStatusesFiltersText = this.checkedPolicyStatusFiltersValues().join(', ') || DEFAULT_FILTER_VALUE
    }

  refreshResults (): void {
      this.params.applicationMethod = this.applicationMethodParam(this.applicationMethods, DEFAULT_FILTER_VALUE)
      this.params.productType = this.checkedProductTypeValues()
      this.params.lineofbusiness = this.checkedLineOfBusinessValues()
      this.params.policyStatusFilters = this.checkedPolicyStatusFiltersValues()
      this.params.issueState = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)
      this.allPoliciesService.resetData()
      this.allPoliciesService.getProductTypes(this.params).then(result => {
        this.productTypes = result.productTypes
      })
      this.allPoliciesService.nextPage(this.params)
      this.showAppMethodDropdown = this._showAppMethodDropdown()
    }

  applyApplicationMethodFilters (): void {
      this.$state.params.applicationMethod = this.applicationMethodParam(this.applicationMethods, DEFAULT_FILTER_VALUE)
      this.popupManager.close(applicationTypePopup)
      this.$state.go('search.closed', this.$state.params)
      this.refreshResults()
    }

  applyProductTypeFilters (): void {
      this.$state.params.productType = this.checkedProductTypeValues()
      this.popupManager.close(productPopup)
      this.$state.go('search.closed', this.$state.params)
      this.setAppliedFiltersText()
      this.refreshResults()
    }

  _issueStatesParam(issueStates, defaultValue) {
      let checked = this.issueStates.filter(this.dotChecked)

      if (!checked.length || checked.length === issueStates.length) {
        return defaultValue
      }

      /*
        includeState : Can be multiple, requires full state name, takes precedence over exclude param
        excludeState : Can be multiple, requires full state name, will not take effect if include is present
       */
      let value = checked[0].value

      if (value === 'Exclude NY') {
        console.log('excludeState=New York, should be the value')
      }

      return checked[0].value
    }

  applyIssueStateFilters(initializing) {
      let includeExcludeNyValue = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)

      this.$state.params.issueState = includeExcludeNyValue
      console.log('ny value ', includeExcludeNyValue)
      this.popupManager.close(this.applicationIssueStatePopup)
      this._storeIssueStates()
      // this.$state.go('search.closed', this.$state.params)
      if (!initializing) { this.refreshResults() }
      // this.refreshResults()
    }

  _storeIssueStates() {
      let localStorage = window.localStorage

      localStorage.setItem(this.issueStateKey, JSON.stringify(this.issueStates))
    }

  _restoreIssueStates() {
      let localStorage = window.localStorage

      let storedIssueStates = localStorage.getItem(this.issueStateKey)

      if (storedIssueStates) {
        // @ts-ignore
        this.issueStates = JSON.parse(storedIssueStates)
        this.applyIssueStateFilters(false)
      }
    }

  applyLineOfBusinessFilters (): void {
      this.$state.params.lineofbusiness = this.checkedLineOfBusinessValues()
      this.popupManager.close(lineOfBusinessPopup)
      this.$state.go('search.closed', this.$state.params)
      this.setLineOfBusinessFiltersText()
      this.appliedFiltersText = this.DEFAULT_FILTER_VALUE
      this.refreshResults()
    }

  applyPolicyStatusFilters (): void {
      this.$state.params.policyStatusFilters = this.checkedPolicyStatusFiltersValues()
      this.popupManager.close(policyStatusPopup)
      this.$state.go('search.closed', this.$state.params)
      this.setPolicyStatusFiltersText()
      this.refreshResults()
    }

    // TODO: Uncomment when the business decides if they want to allow not active polices to be downloaded.
    // download (): void {
    //   const fileName = 'Not Active report ' + this.$filter('date')(new Date(), 'MMM dd yyyy')
    //   let applicationTypeFilter = this.applicationTypeParam(this.applicationTypes, DEFAULT_FILTER_VALUE)
    //   let options: DownloadOptions = {}

    //   if (this.appliedFiltersText !== DEFAULT_FILTER_VALUE) {
    //     options.productType = this.checkedProductTypeValues().join('&productType=')
    //   }

    //   if (applicationTypeFilter !== DEFAULT_FILTER_VALUE) {
    //     options.aceindicator = applicationTypeFilter === 'Electronic'
    //   }

    //   if (this.lineOfBusinessFiltersText !== DEFAULT_FILTER_VALUE) {
    //     options.lineofbusiness = this.checkedLineOfBusinessValues().join('&lineOfBusiness=')
    //   }

    //   if (this.policyStatusesFiltersText !== DEFAULT_FILTER_VALUE) {
    //     options.policyStatus = this.checkedPolicyStatusFiltersValues().join('&policyStatus=')
    //   }

    //   this.downloadManager.getAllClosed(this.params.stage, options, fileName)
    // }
}
