recentlyViewedService.$inject = ['$http', 'authService', 'policyUtils', 'clientUtils', 'CONSTANTS', 'dateUtils', 'utils', '$q']

  /* @ngInject */
function recentlyViewedService($http, authService, policyUtils, clientUtils, CONSTANTS, dateUtils, utils, $q) {
  const service = {}

  service.PolicyItem = function(policy) {
    this.source = policy.source
    this.polNumber = policy.polNumber
  }

  service.recents = null // list of actual policies/apps
  service.recentPolicyItems = null // list of policy/app PolicyItem objects (polNumber, source, lastUpdatedOn)

  service.PolicyNumberList = function(policies) { // can pass it array of policies or array of PolicyItems
    const dotPolNumber = utils.dot('polNumber')

    this.policies = policies.map(dotPolNumber)
  }

  service.getListPromise = function() {
    if (!service.recents || !service.recentPolicyItems) {
      return service.refreshListPromise() // async call to update
    }

    return $q.when(service.recents)
  }

  service.refreshListPromise = function() {
    return service._fetchPolicyItemsPromise()
        .then(function(policyItems) {
          service.recentPolicyItems = policyItems

          return policyItems
        })
        .then(service._fetchPoliciesPromise)
        .then(function(policies) {
          service.recents = policies

          return policies
        })
  }

  service.addPolicyPromise = function(policy) { // accepts policy or PolicyItem
    let index

    if (service.recentPolicyItems) {
      index = service.recentPolicyItems.findIndex(function(item) {
        return policy.polNumber === item.polNumber
      })

      if (index > 0) { // if already in list, but not the first item
        service.recentPolicyItems.splice(index, 1) // remove it
      } else if (index === 0) { // if already in the list AND the first item
        return $q.when(service.recents) // do nothing and return
      }

      service.recentPolicyItems.splice(0, 0, new service.PolicyItem(policy)) // insert at the top of the list

      if (service.recentPolicyItems.length > CONSTANTS.MAX_NUMBER_RECENTS) {
        service.recentPolicyItems.pop()
      }

      return service._savePolicyItemsPromise(service.recentPolicyItems)
          .then(service.refreshListPromise)
    }
  }

  service._savePolicyItemsPromise = function(policyItems) {
    return $http.post(CONSTANTS.recentlyURL, policyItems)
        .then(function success(httpData) {
          if (!httpData.data) {
            console.error('Error syncing recents with server')
          }
        })
        .catch(function failure(err) {
          console.error('Error syncing recents with server:', err)
        })
  }

  service._fetchPolicyItemsPromise = function() {
    return $http.get(CONSTANTS.recentlyURL)
        .then(function success(httpData) {
          if (!httpData.data || !httpData.data.length) {
            return []
          }
          return httpData.data
        })
        .catch(function failure(httpData) {
          console.log('Error retrieving recent IDs from server: ', httpData)
          return []
        })
  }

  service._fetchPoliciesPromise = function(policyItemList) {
    if (!policyItemList || policyItemList.length === 0) { // if there are no policy items, immediately resolve with empty policy list
      return $q.when([])
    }

    return $http.post(CONSTANTS.refreshURL, new service.PolicyNumberList(policyItemList))
        .then(function success(httpData) {
          if (!httpData.data || !httpData.data.policies || !httpData.data.policies.length) {
            return []
          }

          httpData.data.policies = httpData.data.policies.map((policy) => {
            policy.nyIssued = policy.issueState === 'NY' || policy.issueState === 'New York' // this also sets a proper false rather than relying on undefined to == false

            return policy
          })
          return httpData.data.policies.map(service._reformatPoliciesBySource)
        })
        .catch(function failure(httpData) {
          console.error('Failed to get policies from refresh:', httpData)
          return null // null is an error, empty [] is valid
        })
  }

  service._reformatPoliciesBySource = function(policy) {
      // console.debug("[recentlyViewedService::_reformatPoliciesBySource] policy: %s", policy.polNumber)

    switch (policy.source) {
      case 'pending':
        policy = policyUtils.reformatPendingPolicy(policy)
        break
      case 'app':
        policy = policyUtils.reformatApplication(policy)
        break
      case 'inforce':
      default:
        policy = policyUtils.reformatInForcePolicy(policy)
        break
    }

    return policy
  }

  return service
}

export {recentlyViewedService}
