import * as angular from 'angular'

export class ReplyableRequirementsDataProviderService {
  static $inject = ['$q', '$http', 'policyUtils', 'CONSTANTS', 'utils', 'partyService', 'REPLIABLE_ENDPOINT']

  MAX_PAGE_LIMIT = 10
  PAGE_SIZE = 10

  operator: any

  constructor(private $q: angular.IQService, private $http: ng.IHttpService, private policyUtils, private CONSTANTS, private utils, private partyService, private REPLIABLE_ENDPOINT) {
    this.operator = {}
  }

  /**
   * Creates the final url with all querystring parameters appended.
   *
   * @param endPointName
   * @param options
   */
  _resolveEndPointUrl(endPointName, options) {
    let filterKey = options?.filterKey ?? this.partyService.getAgentKey()
    let url = endPointName

    url = this.utils.appendURLParameter(url, 'omitReq', this.CONSTANTS.requirementsToFilterOutValues)

    if (options?.sort?.direction) {
      url = this.utils.appendURLParameter(url, 'sort', options.sort.name)
      url = this.utils.appendURLParameter(url, 'order', options.sort.direction)
    }

    if (options?.page) {
      url = this.utils.appendURLParameter(url, 'page', options.page.page)
      url = this.utils.appendURLParameter(url, 'rows', options.page.size)
    }

    if (options?.filter) {
      options.filter.forEach(filter => {
        url = this.utils.appendURLParameter(url, filter.field, filter.value)
      })
    }
    url = this.utils.appendURLParameter(url, 'filterKey', filterKey)

    return url
  }

  /**
   * Check to see if there is already an active call inprogress.
   *
   */
  _requestActive() {
    return !!this.$http.pendingRequests.find(item => {
      return item.url.indexOf(this.REPLIABLE_ENDPOINT) > -1
    })
  }

  /**
   * Recursively calls end-point until MAX_PAGES or all available records are received.
   * Resolves with the final combined result.
   *
   * @param acc
   * @param page
   * @param deferred
   * @param options
   */
  _recurse(acc: any[], page: number, deferred: any, options?: any) {
    const active = this._requestActive()

    options.page = { page: page, size: this.PAGE_SIZE }

    if (!active) {
      this.get(options)
        .then((result) => {
          if (Array.isArray(result.data) && result.data.length > 0) {
            acc = acc.concat(result.data)
          }

          if ((result.data.length > 0 /*|| acc.length < result.totalItems*/) && page <= this.MAX_PAGE_LIMIT) {
            this._recurse(acc, page + 1, deferred, options)
          } else {
            deferred.resolve(acc)
          }
        })
        .catch((err) => deferred.reject(err))

    }
  }

  /**
   * Calls end-point recursively and the resolves with the final combined result set.
   *
   * @param options
   */
  getAllAvaiablePages(options?: any) {

    if (!options.isAgent && !options.filterKey) {
      return this.$q.resolve({
        totalPages: 0,
        totalItems: 0,
        data: [],
      })
    }

    const deferred = this.$q.defer()

    this._recurse([], 1, deferred, options)

    return deferred.promise
      .then(result => {
        return result
      })

  }

  /**
   * Performs http get operation agains CS end-point.
   *
   * @param options
   */
  get(options?: any) {
    let url = this._resolveEndPointUrl(this.REPLIABLE_ENDPOINT, options)
    const finalResult: any = {
      totalPages: 0,
      totalItems: 0,
      data: [],
    }

    return this.$http.get(url)
      .then((result: any) => {
        finalResult.data = result.data.requirementInfos || []
        finalResult.totalItems = result.data.totalNumPolicies
        finalResult.totalPages = Math.ceil(finalResult.totalItems / 10)

        return finalResult
      })
      .then(() => finalResult.data.map(policy => {
        policy.requirementsStatusTotal = this.policyUtils.calcRequirementsMet(policy.requirementsStatus)
        policy.requirements.map(requirement => {
          requirement.statusDate = Date.parse(requirement.statusDate)
          requirement.requestedDate = Date.parse(requirement.requestedDate)
          if (requirement.reqStatus === 'Pending' && requirement.requirementDetails) {
            requirement.noteOpened = true
          }
        })

        return policy
      }))
      .then(() => {
        return finalResult
      })
  }

  /**
   * NOTE: This function was added for debugging and is not required.
   *
   * @param data
   */
  beforeRender(data) {
    return data
  }

  requirements = (options) => this.get(options)
}
