
export function allowValues(valueCheckers) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      let allowedValues

      ctrl.$validators.allowValues = function(modelValue, viewValue) {
        try {
          allowedValues = typeof attrs.allowValues === 'string'
            ? JSON.parse(attrs.allowValues)
            : attrs.allowValues

          return valueCheckers.isInAllowedValues(allowedValues, modelValue, viewValue)
        } catch (parseError) {
          return false
        }
      }
    },
  }
}
