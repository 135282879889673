import * as angular from 'angular'
const apiPrefix = '/credits' //

interface Credit {
  lifePolicyCount: number
}
interface CreditResponse {
  hasError: boolean,
  status: String
  credits: Credit[]  // tell bobs to pluralize these!
}

export class ClubsService {
  constructor (private $http: angular.IHttpService, private $q: angular.IQService, private utils) {
    // axios.defaults.headers.common['SSO_TOKEN'] = auth
  }

  getSummaryCredits (filterKey?: string) {
    return this.$q((resolve) => {
      this.$http.get<CreditResponse>(this.utils.appendURLParameter(`${apiPrefix}`,'filterKey', filterKey))
        .then((response) => {
          response.data.credits.forEach((credit) => {
            if (!credit.lifePolicyCount) {
              credit.lifePolicyCount = 0
            }
          })
          resolve(response.data)
        }).catch(err => {
          resolve({
            credits: [{}, {}, {}],
            hasError: true,
            status: err.status,
          })
        })
    })
  }

  getStarCredits (filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/star`, 'filterKey', filterKey))
  }

  getCredit (creditType, filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/${creditType}`, 'filterKey', filterKey))
  }

  getLeadersSummary (filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/leaders`, 'filterKey',filterKey))
  }

  getChairmansSummary (filterKey?: string) {
    return this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/chairmans`, 'filterKey', filterKey))
  }

  getDetailCredits (clubMethodType, creditType, filterKey?: string) {
    return this.$q((resolve) => {
      this.$http.get(this.utils.appendURLParameter(`${apiPrefix}/${clubMethodType}/${creditType}`, 'filterKey', filterKey))
          .then((response) => {
            resolve({credits: response.data, hasError: false})
          }).catch(err => {
            resolve(
              {
                credits: {},
                hasError: true,
                status: err.status,
              })
          })
    })
  }

}

ClubsService.$inject = ['$http', '$q', 'utils']
