export {DownloadFormsController}

DownloadFormsController.$inject = ['$scope', 'isFromCAResponse', 'organizedForms']

  /* @ngInject */
function DownloadFormsController($scope, isFromCAResponse, organizedForms) {
  const self = this

  self.organizedForms = organizedForms
  self.errorMessage = ''
  self.lifeDownloadPath = 'resources/forms/life/'
  self.annuityDownloadPath = 'resources/forms/annuity/'
  self.isFromCA = isFromCAResponse && isFromCAResponse.data.isResident
  self.companies = $scope.client?.companies || []
  self.annuityDocuments = [{
    title: 'Authorize Direct Deposit',
    type: 'PDF',
    file: 'PM7432 Authorization for Direct Deposit of Annuity Payments.pdf',
    icon: 'icon-download',
    id: 'pm7432-authorization-for-direct-deposit-of-annuity-payments-form',
  },
  {
    title: 'Change My Name and/or My Address',
    type: 'PDF',
    file: 'PM6360 Life & Annuity Name & Address Change Form.pdf',
    icon: 'icon-download',
    id: 'pm6360-life-and-annuity-name-and-address-change-form',
    companies: ['PML', 'PIA', 'PNY'],
  },
  {
    title: 'Change Beneficiaries of My Policy',
    type: 'PDF',
    file: 'PM4799 Annuity Beneficiary Designation.pdf',
    icon: 'icon-download',
    id: 'pm4799-annuity-beneficiary-designation-form',
  },
  {
    title: 'Submit Fund Service Request',
    type: 'PDF',
    file: 'PM5795 Fund Service Request.pdf',
    icon: 'icon-download',
    id: 'pm5795-fund-service-request-form',
  },
  {
    title: 'Submit IRS Form W-4P',
    type: 'PDF',
    file: 'W4P 2019.pdf',
    icon: 'icon-download',
    id: 'w4p-2019-form',
  },
  {
    title: 'Submit IRS Form W-9',
    type: 'PDF',
    file: 'W9-11.2017.pdf',
    icon: 'icon-download',
    id: 'w9-11.2017-form',
  },
  {
    title: 'Provide Certification of a Trust',
    type: 'PDF',
    file: 'PM6389 Provide Certification of a Trust.pdf',
    icon: 'icon-download',
    id: 'pm6389-certification-of-trust-inforce-policy-form',
    companies: ['PML', 'PIA', 'PNY'],
  },
  ]

  self.CADocs = [
    {
      title: 'Cash Surrender Form for Annuity Products',
      type: 'PDF',
      file: 'EB2083 Cash Surrender Annuity.pdf',
      icon: 'icon-download',
      id: 'eb2083-cash-surrender-annuity',
    },
  ]

  self._hasPmlOrPia = function(companies) {
    return companies.some(function(company) {
      return company.company === 'PML' || company.company === 'PIA'
    })
  }

  self._hasPny = function(companies) {
    return companies.some(function(company) {
      return company.company === 'PNY'
    })
  }

  self.convertDocsToPny = function(docs) {
    return docs.map(function(doc) {
      const fileParts = doc.file.split('.')
      const nyFileName = fileParts[0] + '_ny.' + fileParts[1]
      const changes = {
        file: nyFileName,
        title: doc.title.concat(' ', '(NY)'),
        id: doc.id.concat('', '-ny'),
      }
      const final = Object.assign({}, doc, changes)

      return final
    })
  }

  self.organizeDocs = function(docs, hasNyPolicies, hasPnyPiaPolicies) {
    const organizedDocs = {
      nyDocs: [],
      nonNyDocs: [],
    }

    docs.forEach(function(doc) {
      if (hasNyPolicies && doc.companies && doc.companies.includes('PNY')) {
        organizedDocs.nyDocs.push(doc)
      }

      if (hasPnyPiaPolicies && doc.companies && (doc.companies.includes('PML') || doc.companies.includes('PIA'))) {
        organizedDocs.nonNyDocs.push(doc)
      }

      if (!doc.companies) {
        organizedDocs.nonNyDocs.push(doc)
      }
    })

    organizedDocs.nyDocs = self.convertDocsToPny(organizedDocs.nyDocs)

    return organizedDocs
  }

  function isThereError() {
    return Boolean(self.errorMessage)
  }

  self.$onInit = function() {
    self.lifeInsurancePolicies = getLifeInsurancePolicies()
    self.annuityContracts = getAnnuityContracts()

    self.annuityForms = self.organizeDocs(self.annuityDocuments, self._hasPny(self.companies), self._hasPmlOrPia(self.companies))
    self.lifeForms = self.organizeDocs(self.lifeInsurancePolicies.docs, self._hasPny(self.companies), self._hasPmlOrPia(self.companies))
    self.isThereError = isThereError
    self.hasPmlOrPia = self._hasPmlOrPia(self.companies)
    self.hasPny = self._hasPny(self.companies)
  }

  function getLifeInsurancePolicies() {
    return {
      title: 'Life Insurance Policies',
      docs: [{
        title: 'Change My Name and/or My Address',
        type: 'PDF',
        file: 'PM6360 Life & Annuity Name & Address Change Form.pdf',
        icon: 'icon-download',
        id: 'pm6360-life-and-annuity-name-and-address-change-form',
        companies: ['PNY', 'PIA', 'PML'],
      },
      {
        title: 'Change Beneficiaries of My Policy',
        type: 'PDF',
        file: 'PM6532 Beneficiary Designation Form.pdf',
        icon: 'icon-download',
        id: 'pm6532-beneficiary-designation-form',
        companies: ['PML', 'PIA', 'PNY'],
      },
      {
        title: 'Change Owner of My Policy',
        type: 'PDF',
        file: 'PM6533 Change Owner of My Policy.pdf',
        icon: 'icon-download',
        id: 'pm6533-owner-designation-form',
        companies: ['PML', 'PIA', 'PNY'],
      },
      {
        title: 'Assign My Policy as Collateral',
        type: 'PDF',
        file: '5278 Assignment of Policy as Collateral.pdf',
        icon: 'icon-download',
        id: '5278-assignment-of-policy-as-collateral-form',
        companies: ['PML', 'PIA', 'PNY'],
      },
      {
        title: 'Release a Collateral Assignment',
        type: 'PDF',
        file: '305 Release of Collateral Assignment.pdf',
        icon: 'icon-download',
        id: '305-release-of-collateral-assignment-form',
        companies: ['PML', 'PIA', 'PNY'],
      },
      {
        title: 'Provide Certification of a Trust',
        type: 'PDF',
        file: 'PM6389 Provide Certification of a Trust.pdf',
        icon: 'icon-download',
        id: 'pm6389-certification-of-trust-inforce-policy-form',
        companies: ['PML', 'PIA', 'PNY'],
      },
      ],
    }

      
  }

  function getAnnuityContracts() {
    return {
      title: 'Annuity Contracts',
      docs: self.isFromCA ? [...self.annuityDocuments, ...self.CADocs] : self.annuityDocuments,
    }
  }
}
