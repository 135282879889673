import * as angular from 'angular'
const APP = 'app'

export class ClientDataProvider {
  static $inject = ['$q', '$http', 'utils', 'CLIENT_LIST_ENDPOINTS', 'partyService', 'allPoliciesService', '$filter']

  operator: any

  constructor(private $q: angular.IQService, private $http: ng.IHttpService, private utils, private CLIENT_LIST_ENDPOINTS: any,
    private partyService, private allPoliciesService, private $filter: angular.IFilterFunction) {}

  /**
   * Plucks out the specifed property from the given raw result.
   *
   * @param rawResult
   * @param dataPropName
   */
  _extractResultsData(rawResult: any, dataPropName: string): any[] | any {

    const data = (rawResult.data || rawResult) || { [dataPropName]: [] }

    return data[dataPropName] || []
  }

  /**
   * Returns the final paged result object
   *
   * @param data
   * @param tmpResult
   */
  _resolveFinalResult(data: any[], tmpResult) {
    const finalResult = { data, totalItems: 0, totalPages: 0}

    finalResult.data = data
    finalResult.totalItems = tmpResult.numFound
    finalResult.totalPages = tmpResult.numPages

    return finalResult
  }

  _resolveEndPointUrl(endPointName, options) {
    const filterKey = options?.filterKey ?? this.partyService.getAgentKey()

    let url = this.CLIENT_LIST_ENDPOINTS[endPointName]

    url = this.utils.appendURLParameter(url, 'filterKey', filterKey)

    if (options?.sort?.direction) {
      url = this.utils.appendURLParameter(url, 'sort', options.sort.name)
      url = this.utils.appendURLParameter(url, 'order', options.sort.direction)
    }

    if (options?.page) {
      url = this.utils.appendURLParameter(url, 'page', options.page.page)
      url = this.utils.appendURLParameter(url, 'rows', options.page.size)
    }

    if (options?.filter) {
      options.filter.forEach(filter => {
        url = this.utils.appendURLParameter(url, filter.field, filter.value)
      })
    }

    // console.log(url)
    return url
  }

  /**
   * Returns all end-point data for the current logged in agent,
   * or assistant viewing as an agent.
   *
   * @param endPointName
   * @param dataPropertyName
   * @param filterKey
   */
  get(endPointName: string, dataPropertyName: string, options?: any) {
    const url = this._resolveEndPointUrl(endPointName, options)
    let tmpResult: any = null

    return this.$http.get(url)
      .then((result: any) => tmpResult = result.data)
      .then(() => this._extractResultsData(tmpResult, dataPropertyName))
      .then(data => this._resolveFinalResult(data, tmpResult))

  }

  /**
   * Returns client list
   */
  clients(options?: any) {
    if (options?.filterKey || options?.isAgent) {
      return this.get('CLIENTS', 'policies', options)
    }

    return this.$q.resolve(options?.pageable ? {data: []} : [])
  }

  productTypes() {
    return this.allPoliciesService.getProductTypes()
      .then(results => results.productTypes.map((prodType) => {
        return { id: prodType.valueTypeCode.tc, name: prodType.valueTypeCode.value }
      }).sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }))
      .then(data => {
        data.unshift({id: '', name: 'All'})
        return data
      })
  }

  pageSizes() {
    return this.$q.resolve([10, 20, 50, 100])
  }

  holdingStatusTypes() {
    return this.$q.resolve([
      { name: 'All', id: [1, 1012300004, 3, 1012300001]},
      { name: 'Unsubmitted Application', id: [1012300001] },
      { name: 'Pending', id: [3] },
      { name: 'Issued Not Paid', id: [1012300004] },
      { name: 'Active', id: [1] },
    ])
  }

  clientBeforeRender(rawClients) {
    let currentFullName = ''

    const clients = rawClients.map((client) => {

      // Create model version of the client object
      const newClient = {
        fullName: client.owner[0]?.fullName || '',
        displayPolicyNumber: client.source === APP ? client.appCode : this.$filter('formatPolicyNumber')(client.polNumber),
        policyNumber: client.polNumber,
        productType: client.productType,
        anniversaryDate: client.anniversaryDate,
        policyValue: client.policyValue,
        netDeathBenefitAmt: client.netDeathBenefitAmt,
        holdingStatus: client.holdingStatus,
        isLinkable: client.source !== APP, // ['Active', 'Pending'].includes(client.holdingStatus),
        policySource: client.holdingStatus === 'Active' ? 'inforce' : 'pending',
      }

      // "Group" Duplicate fullNames
      if (currentFullName === newClient.fullName) {
        newClient.fullName = ''
      } else {
        currentFullName = newClient.fullName
      }

      return newClient
    })

    return clients
  }
}
