import * as angular from 'angular'
import { MfaRunner } from './module'
import { MfaStatusCheckController } from './check/component'
import { MfaSendCodeController } from './mfa-send-code/component'
import { MfaSubmitCodeController } from './mfa-submit-code/component'
import { MfaTrustBrowserController } from './mfa-trust-browser/component'
import { MfaDataProvider } from './mfa-data-provider'
import './module'
import './routes'
import { MfaLoginRunner } from './routes'
import { MainMfaComponent } from './component'
import { SingleInputController } from './mfa-submit-code/single-input.component'


export const MFA_STATUS_CODES = {
  ENROLL_MFA: 'ENROLL_MFA',
  VERIFY_POC: 'VERIFY_POC',
  NONE: 'NONE',
}

export const MFA_UI_ROUTES = {
    CHECK: 'mfa.check',
    SEND_CODE: 'mfa.send-code',
    SUBMIT_CODE: 'mfa.submit-code',
}

export const MFA_TOKEN_TYPES = {
  MFA_VERIFIED: 'mfaVerified',
  CSSB_MFA_VERIFIED: 'cssbMfaVerified',
}

angular.module('app.mfa-login', ['app.utils'])
  .run(MfaRunner)
  .service('mfaDataProvider', MfaDataProvider)
  .component('mfaStatusCheck', {
    templateUrl: 'app/mfa/check/template.html',
    controller: MfaStatusCheckController,
  })
  .component('mainMfaComponent', MainMfaComponent)
  .component('mfaSendCode', {
    bindings: {

    },
    require: {
      mfaCtrl: '^mainMfaComponent',
    },
    templateUrl: 'app/mfa/mfa-send-code/template.html',
    controller: MfaSendCodeController,
    controllerAs: 'ctrl',
  })

angular.module('app.mfa-login')
  .component('singleInput', {
    templateUrl: 'app/mfa/mfa-submit-code/single-input-template.html',
    controller: SingleInputController,
    controllerAs: 'ctrl',
    bindings: {
      code: '=',
    },
  })
  .component('mfaSubmitCode', {
    templateUrl: 'app/mfa/mfa-submit-code/template.html',
    require: {
      mfaCtrl: '^mainMfaComponent',
    },
    controller: MfaSubmitCodeController,
    controllerAs: 'ctrl',
  })
  .component('mfaTrustBrowser', {
    templateUrl: 'app/mfa/mfa-trust-browser/template.html',
    require: {
      mfaCtrl: '^mainMfaComponent',
    },
    controller: MfaTrustBrowserController,
    controllerAs: 'ctrl',
  })
  .constant('MFA_ENDPOINTS', {
    GET_STATUS: '/mfa/status/:guid',
    POC_LIST: '/mfa/verifications/:guid',
    POC_ADD: '/mfa/verifications/:guid',
    CODE_REQUEST: '/mfa/verifications/:guid/:pocGuid/initiate',
    CODE_VERIFY: '/mfa/verifications/:guid/:pocGuid/verify',
    DEVICE_TRUST: '/mfa/devices/:guid',
    POC_DELETE: '/mfa/verifications/:guid/:pocGuid',
  })
  .constant('MFA_STATUS_CODES', MFA_STATUS_CODES)
  .constant('MFA_ALLOWED_STATES', [
    'mfa.check',
    'mfa.send-code',
    'mfa.submit-code',
  ])
  .run(routerHelper => new MfaLoginRunner(routerHelper))
