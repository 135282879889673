import { Beneficiary, BENEFICIARY_SOURCE_OBJECT_TYPES } from '../client/beneficiaries/classes/beneficiary'
import { ROLE_DISPLAY_ORDER_OTHER } from '../client/beneficiaries/constants/ROLE_DISPLAY_ORDER.ts'

export { clientUtils }

clientUtils.$inject = ['CONSTANTS', 'utils', 'ROLE_DISPLAY_ORDER', 'ROLES_ARRAY', 'ANNUITANT_ROLES', 'OWNER_ROLES']

/* @ngInject */
function clientUtils(CONSTANTS, utils, ROLE_DISPLAY_ORDER, ROLES_ARRAY, ANNUITANT_ROLES, OWNER_ROLES) {
  const service = {}

  service.getClientHash = function (client) {
    const birthDateStr = client.birthDate ? client.birthDate.toString() : ''
    const uniqueStr = client.fullName + birthDateStr + client.htmlAddress

    return utils.createMd5Hash(uniqueStr)
  }

  service.getMultipleNamesLabel = function (fullName, itemCount) {
    return fullName + (itemCount > 1 ? ', +' + String(itemCount - 1) : '')
  }

  service.firstNameFirst = function (fullName) {
    if (!angular.isDefined(fullName) || !angular.isString(fullName)) {
      return
    }

    const values = fullName.split(',')

    if (values.length === 2) {
      return service.getFullName(values[1].trim(), values[0].trim())
    }

    return fullName
  }

  service.getFullName = function (firstName, lastName) {
    if (firstName && lastName) {
      return firstName + ' ' + lastName
    } else if (firstName) {
      return firstName
    } else if (lastName) {
      return lastName
    }

    return CONSTANTS.notAvailableInfo
  }

  service.parsePartyDetails = function (partyList) {
    const data = {
      'fullName': '',
      'isMultiple': false,
      'isAnOrganization': false,
    }

    if (partyList && partyList.length > 0) {
      const info = partyList[0]
      const fullName = service.firstNameFirst(info.fullName)

      if (fullName) {
        data.fullName = service.getMultipleNamesLabel(fullName, partyList.length)
      }

      // Default is a person party type
      info.partyType = info.partyType || CONSTANTS.personPartyType

      if (partyList.length > 1) {
        data.isMultiple = true
      }

      data.isAnOrganization = !data.isMultiple && info.partyType !== CONSTANTS.personPartyType
    }

    return data
  }

  service.partyName = function (party) {
    party = party || {}

    if (party.partyType === CONSTANTS.personPartyType) {
      return [party.firstName, party.middleName, party.lastName, party.suffix]
        .filter(Boolean)
        .join(' ') || party.fullName
    }

    return party.fullName || CONSTANTS.notAvailableInfo
  }

  service.getAddress = function (apiAddress) {
    const addressFields = ['line1', 'line2', 'line3', 'line4', 'city', 'state', 'zip']

    return addressFields
      .reduce(function (addressParts, field) {
        if (apiAddress[field]) {
          addressParts.push(apiAddress[field])
        }

        return addressParts
      }, [])
      .join(', ')
  }

  service.getAnniversaryDate = function (effDate) {
    if (!angular.isDate(effDate)) {
      return
    }

    const today = new Date()
    let anniversaryYear = today.getFullYear()

    if (effDate.getMonth() < today.getMonth()) {
      anniversaryYear += 1
    }

    return new Date(anniversaryYear, effDate.getMonth(), effDate.getDate())
  }

  service._organizeContacts = function (contacts, hasStepInAnnuitant, hasSuccessorOwner) {
    const finalResult = contacts.reduce((tmpClients, contact) => {
      const beneClients = contact.roles.map((role) => {
        const tmpClient = Object.assign({}, contact, { role, sortOrder: ROLE_DISPLAY_ORDER[role.tc] || ROLE_DISPLAY_ORDER_OTHER })

        if (hasStepInAnnuitant && tmpClient.role.tc === ANNUITANT_ROLES.ANNUITANT) tmpClient.role.role = 'Original Annuitant'

        if (hasSuccessorOwner && tmpClient.role.tc === OWNER_ROLES.OWNER) tmpClient.role.role = 'Original Owner'

        return tmpClient
      })

      tmpClients = tmpClients.concat(beneClients)

      return tmpClients
    }, []).sort((a, b) => {
      if (a.sortOrder.order > b.sortOrder.order) return 1
      if (a.sortOrder.order < b.sortOrder.order) return -1

      return 0
    })

    return finalResult
  }

  service.organizeRolesByType = function (clientList, roleType) {
    const roleParties = clientList.filter((client) => ROLES_ARRAY[roleType].includes(client.role?.tc))
    const finalResult = roleParties.map((party) => {
      // const tmpClient = Object.assign({}, party, {isAssignee: roleType === 'Assignee'})
      party.isAssignee = roleType === 'Assignee'

      return party
    }).sort((a, b) => {
      if (a.sortOrder.order > b.sortOrder.order) return 1
      if (a.sortOrder.order < b.sortOrder.order) return -1

      return 0
    })

    return finalResult
  }

  /**
   * Creates one party entry for each role that a party has.
   * Meaning if you have 3 parties, with a combinded total
   * number of roles as 5, this function will return an array of 5
   * parties each with a unique single role.
   *
   * @param {*} originalParties
   * @returns
   */
  service.expandParties = function (originalParties) {
    const finalResult = []

    originalParties.forEach((party) => {
      party.roles.forEach((role) => {
        const tmpParty = Object.assign({}, party, { role, sortOrder: ROLE_DISPLAY_ORDER[role.tc] || ROLE_DISPLAY_ORDER_OTHER })

        finalResult.push(tmpParty)
      })
    })

    return finalResult.sort((a, b) => {
      if (a.sortOrder.order > b.sortOrder.order) return 1
      if (a.sortOrder.order < b.sortOrder.order) return -1

      return 0
    })
  }

  service.organizeClientDetails = function (partiesObject) {
    const expandedParties = service.expandParties(partiesObject?.parties ?? [])
    const expandedBeneficiaries = expandedParties.map((party) => {
      return new Beneficiary(null, party, BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)
    })

    const parties = {
      beneficiaries: service.organizeRolesByType(expandedBeneficiaries, 'Beneficiary'),
      contacts: null,
      insureds: service.organizeRolesByType(expandedBeneficiaries, 'Insured'),
      owners: service.organizeRolesByType(expandedBeneficiaries, 'Owner'),
      payors: service.organizeRolesByType(expandedBeneficiaries, 'Payor'),
      assignees: service.organizeRolesByType(expandedBeneficiaries, 'Assignee'),
      annuitants: service.organizeRolesByType(expandedBeneficiaries, 'Annuitant'),
      mailingOwner: null,
      nonBeneficiaries: null,
    }

    // Need to add support for client modal
    parties.Beneficiary = parties.beneficiaries
    parties.Insured = service.organizeRolesByType(expandedParties, 'Insured')
    parties.Owner = service.organizeRolesByType(expandedParties, 'Owner')
    parties.Payor = service.organizeRolesByType(expandedParties, 'Payor')
    parties.Assignee = service.organizeRolesByType(expandedParties, 'Assignee')
    parties.Annuitant = service.organizeRolesByType(expandedParties, 'Annuitant')

    // Support for "step in annuitants", and "successorOwner"
    const stepInAnnuitant = parties.Annuitant.find(annuitant => annuitant.role.tc === ANNUITANT_ROLES.STEP_IN_ANNUITANT)
    const successorOwner = parties.Owner.find(owner => owner.role.tc === OWNER_ROLES.SUCCESSOR_OWNER)

    parties.contacts = service._organizeContacts(partiesObject?.parties ?? [], Boolean(stepInAnnuitant), Boolean(successorOwner))

    // set mailing owner to successorOwner or first owner in list returned.
    parties.mailingOwner = successorOwner || parties.Owner[0]

    parties.nonBeneficiaries = expandedParties.filter((party) => !ROLES_ARRAY.Beneficiary.includes(party.role.tc))

    return parties
  }

  return service
}