export class ModalInstanceCtrl {
  static $injector: any = ['$uibModalInstance', 'modalData']

  constructor(private $uibModalInstance, public modalData) {}

  ok() {
    if (this.modalData?.onClose) this.modalData.onClose()

    this.$uibModalInstance.close()
  }
}
