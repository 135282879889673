import { IController } from "angular"

class InfoHeadingBlockController {
  $inject = []

  heading: string
  body: string
  isEditable: boolean
  onSubmit: Function
  onRevert: Function
  onAddNew: Function
  parentCtrl: IController
  // constructor () { }

  // $onInit() {
  //   console.log(this.onAddNew)
  // }
}

export const infoHeadingBlockComponent = {
  templateUrl: 'app/components/info-heading-block/templates/info-heading-block.html',
  controller: InfoHeadingBlockController,
  controllerAs: 'ctrl',
  bindings: {
    heading: '<',
    body: '<',
    isEditable: '<',
    onSubmit: '&',
    onRevert: '&',
    onAddNew: '&',
    parentCtrl: '<',
  },
  // require: {
  //   anotherCtrl: '^rolesComponent',
  // },
}
