/**
 * Renders the <availabe-dowbloads /> directive as a table of IBatchStatementsDatum
 * items that are marked as status = `DOWNLOAD`.
 */

class AvialableDownloads implements ng.IDirective {

  restrict = 'E'
  templateUrl = 'app/batch-statements/bs-available-downloads.html'
  replace = false

  /**
   * AngularJS post link function use for initial configuration of instances of PendingStatementJobs
   */
  // tslint:disable-next-line
  // link = (_scope: any, el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) => {
  //   // const bsCtrl: IBatchStatementsController = scope.bsCtrl
  //   const buttons = el.find('* > button')

  //   console.debug('>>>> buttons <<<<', buttons)
  // }

  /**
   * Creates an instance of PendingStatementJobs, with dependencies injected.
   */
  static factory (): ng.IDirectiveFactory {

    const directive = () => new AvialableDownloads()

    directive.$inject = []

    return directive
  }
}
export {AvialableDownloads}
