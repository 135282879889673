class TileViewComponentController {
  static $injector: any = []
  allPoliciesCtrl: any
  policiesCtrl: any
  // constructor() {}

  $onInit() {
    this.policiesCtrl = this.allPoliciesCtrl.policiesCtrl
  }
}

const AllPoliciesTileView = {
  templateUrl: 'app/search/all/tiles/main.html',
  controller: TileViewComponentController,
  controllerAs: 'tileViewCtrl',
  bindings: {
    lineOfBusiness: '<',
    productType: '<',
    listViewOptions: '<',
    allPoliciesCtrl: '<',
    includeState: '<',
    aceIndicator: '<',
    formalAppIndicator: '<',
    subStatus: '<',
    appStatus: '<',
    daysOpen: '<',
    daysToExpire: '<',
  },
}

export {AllPoliciesTileView}
