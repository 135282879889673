class PolicyTaxDocumentController {
  static $inject = ['CONSTANTS', 'googleAnalyticsService', 'authService', 'deviceUtils', '$stateParams']

  private GA_TAX_DOCUMENTS = 'TAX DOCUMENTS'
  private GA_DOCUMENT_DOWNLOAD = 'DOCUMENT DOWNLOAD'

  private isMobilePlatform: any
  private sessionId: string
  private pid: string

  public taxDocuments: any[]

  constructor(private CONSTANTS, private googleAnalyticsService, authService, deviceUtils, $stateParams) {
    this.sessionId = authService.getSessionID()
    this.isMobilePlatform = deviceUtils.isMobilePlatform()
    this.pid = $stateParams.id
  }

  downloadTaxDocument(docId: string, docYear: string, docType: string): void {
    const policyId = this.pid
    const fileName = `TaxDocument${policyId}_${docId}.pdf`
    const url = `${this.CONSTANTS.apiRoot}document/policy/download/tax?docId=${docId}&pid=${policyId}&filename=${fileName}&sid=${this.sessionId}`

    if (this.isMobilePlatform) {
      window.open(url, '_system')
    } else {
      window.open(url, '_blank')
    }

    this.googleAnalyticsService.send(this.GA_TAX_DOCUMENTS, this.GA_DOCUMENT_DOWNLOAD, {
      accountId: policyId,
      document: docType,
      year: docYear,
    })
  }

}

export const PolicyTaxDocuments =  {
  templateUrl: 'app/components/policy-tax-documents/policy-tax-documents.html',
  controller: PolicyTaxDocumentController,
  controllerAs: 'ctrl',
  bindings: {
    taxDocuments: '<',
  },
}
