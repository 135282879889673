import * as angular from 'angular'

import { CompensationController } from './compensation-controller'
import { getCompensationStates } from './compensation-routes'
import { compensationService } from './compensation-service'

angular.module('app.compensation', ['app.core'])
      .factory('compensationService', compensationService)
      .controller('CompensationController', CompensationController)
      .run(appRun)

appRun.$inject = ['routerHelper']
/* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getCompensationStates())
}
