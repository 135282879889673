export {policyHelper}
policyHelper.$inject = ['CONSTANTS']

function policyHelper(CONSTANTS) {
  const today = (new Date()).setHours(0, 0, 0, 0)
  let policy = {}

  const service = {
    'getPremiumStatus': getPremiumStatus,
    'hasPremiumStatus': hasPremiumStatus,
    'setPolicy': setPolicy,
    'showPremiumStatusDate': true,
    'tradProduct': tradProduct,
  }

  return service

  /// /////////////////////////////

  function getPremiumStatus(policyHasDaysLeft) {
    if (!_holdingActive()) {
      return CONSTANTS.notAvailableInfo
    }

    if (tradProduct()) {
      if (_policyStatus(['Active (inforce)']) && _paidToDate() && _paymentModeASorQ() && !_billingIndicator()) {
        return CONSTANTS.paymentsDue.next
      } else if (!_billingIndicator() && (
        _policyStatus(['Back billed', 'Issued not paid', 'Lapse pending']) ||
        (_policyStatus(['Active (inforce)']) && policyHasDaysLeft && _IsPaymentModeMonthly()))) {
        return CONSTANTS.paymentsDue.next
      } else if (!_billingIndicator() && (
        _policyStatus(['Back billed', 'Issued not paid', 'Lapse pending']) ||
        (_policyStatus(['Active (inforce)']) && !_paidToDate()))) {
        return CONSTANTS.paymentsDue.past
      } else if (_policyStatus(['Waiver', 'COI and disability waiver']) ||
        (_policyStatus(['Active (inforce)']) && _billingIndicator())) {
        service.showPremiumStatusDate = false
        return CONSTANTS.paymentsDue.none
      }
    } else if (_policyStatus(['Active (inforce)']) && !_billingIndicator()) {
      return CONSTANTS.paymentsDue.next
    } else if (!_billingIndicator() && _policyStatus(['Lapse pending'])) {
      return CONSTANTS.paymentsDue.past
    } else if (_policyStatus(['Active (inforce)']) && _billingIndicator()) {
      service.showPremiumStatusDate = false
      return CONSTANTS.paymentsDue.none
    }
    return CONSTANTS.notAvailableInfo
  }

  function hasPremiumStatus() {
    const premiumStatus = getPremiumStatus()
    const hasNoPremiumStatus = premiumStatus === CONSTANTS.notAvailableInfo

    return !hasNoPremiumStatus
  }

  function tradProduct() {
    return policy.productType === 'Whole Life' || policy.productType === 'Term Life' || policy.productType === 'Term'
  }

  function setPolicy(set) {
    policy = set
  }

  /// /////// Private Functions ///////////

  function _holdingActive() {
    return policy.holdingStatus === 'Active'
  }

  function _policyStatus(arr) {
    let ret = false

    angular.forEach(arr, function(val) {
      ret = ret || (policy.policyStatus === val)
      if (ret) return ret
    })
    return ret
  }

  function _paidToDate() {
    return policy.paidToDate >= today
  }

  function _paymentModeASorQ() {
    return policy.paymentMode === 'Annual' ||
      policy.paymentMode === 'Semi-Annual' ||
      policy.paymentMode === 'Quarterly'
  }

  function _IsPaymentModeMonthly() {
    return policy.paymentMode === 'Monthly'
  }

  function _billingIndicator() {
    return policy.billingStatusInd === 'True'
  }
}
