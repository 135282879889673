export {FavorPolicyController}

function FavorPolicyController($scope, userAccountService) {
  const self = this

  self.isFavoritePolicy = function() {
    return userAccountService.isFavoritePolicy(self.policy)
  }

  self.toggleFavorite = function($event) {
    userAccountService.toggleFavoritePolicy(self.policy)
    if ($event) {
      $event.stopPropagation()
    }
  }
}
