import angular from "angular"
import { BeneficiariesDataProviderService } from "../beneficiary-data-provider"

class BeneficiaryRestateModalController {
  $inject = ['beneficiariesDataProvider', '$filter']

  private resolve: any

  modalInstance: any
  effectiveDate: Date
  polNumber: string
  bodyMessage: string
  showDoneButton: boolean = false
  hasError: boolean = false

  constructor(private beneficiariesDataProvider: BeneficiariesDataProviderService, private $filter: angular.IFilterFunction) {}

  closeModal(): void {
    this.modalInstance.dismiss()
  }

  $onInit(): void {
    this.effectiveDate = this.resolve.effectiveDate
    this.polNumber = this.resolve.polNumber
    this.bodyMessage = `Please click Confirm below to restate all the beneficiaries on the policy with the selected Effective Date of ${this.$filter('date')(this.effectiveDate, 'mediumDate')}?`
  }

  restate(): void {
    void this.beneficiariesDataProvider.restateBeneficiaries(this.effectiveDate, this.polNumber).then((result) => {
      if (!result.hasError) {
        this.bodyMessage = 'Your changes have been submitted, please click Done to view your changes.'
        this.showDoneButton = true
      } else {
        this.hasError = true
        this.bodyMessage = `There has been an error.  Please cancel and try again later.`
      }
    })
  }

  refresh(): void {
    window.location.reload()
  }

}

export const BeneficiaryRestateModal = {
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  templateUrl: 'app/client/beneficiaries/restate/restate-modal.html',
  controller: BeneficiaryRestateModalController,
  controllerAs: 'modalCtrl',
}
