interface Coverage {
  annualPremAmt: number
  catchupPremAmt: number
  conversionDate?: string
  covOption?: CoverageOption[]
  deathBenefitAmt: number
  effDate: string
  fiveYrPaymentRequirement: number
  fiveYrPremAmt: number
  marketingName: string
  modalPremAmt: number
  participant?: Participant[]
  premAmtATD: number
  productCode: string
  requiredAmt: number
  riderLapsePayDueDate: string
  termDate?: string
  totalAnnualPremAmt: number
  typeCode: string
}

interface CoverageOption {
  deathBenefitAmt: number
  effDate: string
  marketingName: string
  productCode: string
  ratingDecision: string
  termDate: string
  typeCode: string
  underwritingClass: string
}

interface Participant {
  participantName: string
  ratingDecision: string
  underwritingClass: string
}

interface ParticipantCoverage {
  annualPremAmt: number
  catchupPremAmt: number
  conversionDate?: string
  deathBenefitAmt: number
  effDate: string
  fiveYrPaymentRequirement: number
  fiveYrPremAmt: number
  marketingName: string
  modalPremAmt: number
  participantName?: string
  premAmtATD: number
  productCode: string
  ratingDecision: string
  requiredAmt: number
  riderLapsePayDueDate: string
  termDate?: string
  totalAnnualPremAmt: number
  typeCode: string
  underwritingClass: string
}

function OrganizeCoverageWrapper(dateUtils) {
  function explodeCoverages(coverage: Coverage): ParticipantCoverage[] {
    const participantCoverage: ParticipantCoverage[] = []

    if (coverage.participant) {
      coverage.participant.forEach(participant => {
        participantCoverage.push(mapParticipant(coverage, participant))
      })
    }

    if (coverage.covOption) {
      coverage.covOption.forEach(option => {
        participantCoverage.push(mapCovOption(coverage, option))
      })
    }

    return participantCoverage
  }

  function mapParticipant(coverage: Coverage, currentParticipant: Participant): ParticipantCoverage {
    return {
      annualPremAmt: coverage.annualPremAmt,
      catchupPremAmt: coverage.catchupPremAmt,
      conversionDate: coverage.conversionDate,
      deathBenefitAmt: coverage.deathBenefitAmt,
      effDate: dateUtils.parseDate(coverage.effDate),
      fiveYrPaymentRequirement: coverage.fiveYrPaymentRequirement,
      fiveYrPremAmt: coverage.fiveYrPremAmt,
      marketingName: coverage.marketingName,
      modalPremAmt: coverage.modalPremAmt,
      participantName: currentParticipant.participantName,
      premAmtATD: coverage.premAmtATD,
      productCode: coverage.productCode || '',
      ratingDecision: currentParticipant.ratingDecision,
      requiredAmt: coverage.requiredAmt,
      riderLapsePayDueDate: dateUtils.parseDate(coverage.riderLapsePayDueDate),
      termDate: dateUtils.parseDate(coverage.termDate),
      totalAnnualPremAmt: coverage.totalAnnualPremAmt,
      typeCode: coverage.typeCode,
      underwritingClass: currentParticipant.underwritingClass,
    }
  }

  function mapCovOption(coverage: Coverage, covOption: CoverageOption): ParticipantCoverage {
    return {
      annualPremAmt: coverage.annualPremAmt,
      catchupPremAmt: coverage.catchupPremAmt,
      conversionDate: coverage.conversionDate,
      deathBenefitAmt: covOption.deathBenefitAmt,
      effDate: dateUtils.parseDate(covOption.effDate),
      fiveYrPaymentRequirement: coverage.fiveYrPaymentRequirement,
      fiveYrPremAmt: coverage.fiveYrPremAmt,
      marketingName: covOption.marketingName,
      modalPremAmt: coverage.modalPremAmt,
      // participantName: covOption.participantName ? covOption.participantName : '',
      premAmtATD: coverage.premAmtATD,
      productCode: covOption.productCode || '',
      ratingDecision: covOption.ratingDecision,
      requiredAmt: coverage.requiredAmt,
      riderLapsePayDueDate: dateUtils.parseDate(coverage.riderLapsePayDueDate),
      termDate: dateUtils.parseDate(covOption.termDate),
      totalAnnualPremAmt: coverage.totalAnnualPremAmt,
      typeCode: covOption.typeCode,
      underwritingClass: covOption.underwritingClass,
    }
  }

  return explodeCoverages
}

export { OrganizeCoverageWrapper }

OrganizeCoverageWrapper.$inject = ['dateUtils']
