export {unsubmittedApplicationsService}

unsubmittedApplicationsService.$inject = ['$http', '$filter', 'loggingService', 'CONSTANTS', 'utils', 'partyService', 'userDataService']

  /* @ngInject */
function unsubmittedApplicationsService($http, $filter, loggingService, CONSTANTS, utils, partyService, userDataService) {
  const service = {}

  service.currentPage = 0
  service.loadingMore = false
  service.applications = []
  service.message = ''
  service.status = ''
  service.error = ''
  service.connError = false
  service.moreData = true
  service.hasHadUnsubmittedApps = true
  service.noResultsMessage = ''

  function resetErrorState() {
    service.connError = false
    service.message = ''
    service.status = ''
    service.error = ''
  }

  service.isLoadingMore = function() {
    return service.loadingMore && service.applications.length > 0
  }

  service.isFirstPage = function() {
    return service.applications.length === 0
  }

  service.resetData = function() {
    service.moreData = true
    service.numFound = 0
    service.currentPage = 0
    service.applications = []
  }

  service.keepFirst10 = function() {
    service.currentPage = 1
    service.moreData = true
    if (service.applications.length >= 10) {
      service.applications = service.applications.slice(0, 10)
    }
  }

  service.setApplicationUserData = function() {
    userDataService.getDataPromise()
        .then(function(data) {
          if (data.hasHadUnsubmittedApps === undefined && service.numFound === 0) { // if unset and has none, set to false and save
            data.hasHadUnsubmittedApps = false
            userDataService.updateDataPromise(data)
          }

          if (!data.hasHadUnsubmittedApps && service.numFound > 0) { // if false and now has them, set to true and save
            data.hasHadUnsubmittedApps = true
            userDataService.updateDataPromise(data)
          }

          service.hasHadUnsubmittedApps = data.hasHadUnsubmittedApps // take the true/false value from data or set above and save to service
          return data // return potentially updated data
        })
  }

  service.nextPage = function(daysOpen, sortBy, statusTypeCode, daysToExpire, appType, params) {
    let sortOptions = new utils.SortByOptions('daysOpen', 'desc')
    let url

    if (sortBy) {
      sortOptions = utils.parseSortByOptions(sortBy)
    }

    if (service.currentPage === 0) {
      service.resetData()
    }

    service.currentPage++

    url = CONSTANTS.unsubmittedApplicationsURL
        .replace('{0}', service.currentPage)
        .replace('{1}', function() {
          if (isNaN(daysOpen)) {
            return ''
          }

          return '&daysOpen=' + daysOpen
        })
        .replace('{2}', sortOptions.fieldName)
        .replace('{3}', sortOptions.orderName)

    if (statusTypeCode) {
      url += '&status=' + statusTypeCode
    }

    if (daysToExpire) {
      url = utils.appendURLParameter(url, 'daysToExpire', daysToExpire)
    }

    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())

      /*
      includeState : Can be multiple, requires full state name, takes precedence over exclude param
      excludeState : Can be multiple, requires full state name, will not take effect if include is present
     */
      // url = utils.appendURLParameter(url, 'includeState', 'New Jersey')
    if (params) {
      if (params.issueState && params.issueState === CONSTANTS.NYBusiness.criteria.excludeNY) {
        url = utils.appendURLParameter(url, 'excludeState', CONSTANTS.NYBusiness.ny)
      } else if (params.issueState && params.issueState === CONSTANTS.NYBusiness.criteria.NYOnly) {
        url = utils.appendURLParameter(url, 'includeState', CONSTANTS.NYBusiness.ny)
      }
    }

    if (appType === 'Term Conversion') {
      url = utils.appendURLParameter(url, 'formalappIndicator', true)
      url = utils.appendURLParameter(url, 'appType', 'Term Conversion')
    } else {
      url = utils.appendURLParameter(url, 'formalappIndicator', appType)
    }

    resetErrorState()

    service.loadingMore = true

    return $http.get(url).then(
      function(httpData) {
        let applications
        let i, a
        let length

        service.loadingMore = false

        if (httpData.data) {
          service.numFound = httpData.data.numFound
          service.moreData = service.numFound !== 0
          applications = httpData.data.policies
          service.setApplicationUserData()

          if (service.numFound === 0) {
            service.noResultsMessage = CONSTANTS.filterResponses.notFoundBasedOnFilter
          } else {
            service.noResultsMessage = ''
          }

          if (!applications) {
            service.moreData = false
            return
          }

          if (applications.length === 0) {
            service.moreData = false
            if (service.applications && service.applications.length === 0) {
              service.message = 'Undefined response'
            }
          } else {
            for (i = 0; i < applications.length; i++) {
              a = applications[i]

              a.startDate = new Date(a.startDate)
              a.lastUpdateDate = new Date(a.lastUpdateDate)
            }

            service.applications = service.applications.concat(applications || [])

            if (service.applications.length === service.numFound) {
              service.moreData = false
            }

            length = $filter('number')(service.applications.length)

            if (length === 1) {
              service.message = 'Showing ' + length + ' Unsubmitted Policy'
            } else {
              service.message = 'Showing ' + length + ' Unsubmitted Policies'
            }
          }
        } else {
          service.message = 'Undefined response'
        }
      },
      function(httpData) {
        service.moreData = false
        if (httpData.status === -1) {
          service.connError = true
          service.error = httpData.statusText
          service.message = httpData.statusText
        } else if (httpData.status === 500) {
          service.status = httpData.status
          service.error = httpData.statusText
          service.message = httpData.statusText
        } else if (httpData.status === 404 || service.applications.length === 0) {
          service.message = 'Sorry, no results found'
        }

        loggingService.log(httpData.statusText, ', ' + httpData.data + ', Url: ' + httpData.config.url, 'error')
      })
  }

  return service
}
