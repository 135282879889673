export class MailingAddressesController {
  static $inject: any = ['companiesService']

  mailingLocations: any[]
  allNyPolicies = false
  someNyPolicies = false
  someNonNyPolicies = false

  constructor(private companiesService) {}

  $onInit() {
    this.companiesService.getMailingLocations()
      .then((mailingLocations) => {
        this.mailingLocations = mailingLocations.filter(loc => loc.type !== 'PML')  // skip PML because we show web links now
      })
      console.log('allNy' , this.allNyPolicies)
      console.log('someNy', this.someNyPolicies)
      console.log('someNonNy', this.someNonNyPolicies)

  }
}
