export { PolicyDetailRidersController }

PolicyDetailRidersController.$inject = ['$q', 'summaryResult', 'coveragesResult', 'clientResults', 'baseResult', 'tableResultLifetime', 'tableResultStandard', 'ridersHelper', 'policyUtils', 'clientUtils', 'dateUtils', 'riderBlacklists', 'ridersService']

/* @ngInject */
function PolicyDetailRidersController($q, summaryResult, coveragesResult, clientResults, baseResult, tableResultLifetime, tableResultStandard, ridersHelper, policyUtils, clientUtils, dateUtils, riderBlacklists, ridersService) {
  const self = this

  self.title = ''
  self.sections = []
  self.isThereError = false
  self.error = ''
  self.cmsRiders = []
  self.useCmsRiders = false

  self._gmaIIModalTitle = function (gmaIIRider, ehdbRider) {
    const gmaIITitle = gmaIIRider?.status === 'Active' ? 'Guaranteed Minimum Accumulation Benefit II' : ''
    const ehdbTitle = ehdbRider?.status === 'Active' ? 'Guaranteed Minimum Death Benefit' : ''

    return `${gmaIITitle}${gmaIIRider?.status === 'Active' && ehdbRider?.status === 'Active' ? ' & ' : ''}${ehdbTitle}`
  }

  function init() {
    const organizedClients = clientUtils.organizeClientDetails(clientResults.data)
    const coverages = coveragesResult.data?.coverage || []

    self.riderCodes = coveragesResult.data?.coverage?.map(cov => cov.riderCode) ?? []

    if (self.riderCodes.includes('GMA2')) {
      // Use GMA2 Template

      self.hasGmaII = self.riderCodes.includes('GMA2')
      self.hasEhdb = self.riderCodes.includes('EHDB')
      self.gmaIIRider = coveragesResult.data.coverage.find(cov => cov.riderCode === 'GMA2')
      self.ehdbRider = coveragesResult.data.coverage.find(cov => cov.riderCode === 'EHDB')
      self.summaryResult = summaryResult.policy
      self.clientResults = clientResults.data.parties
      self.annuitant = organizedClients.annuitants[0]
      self.annuitant.currentAge = dateUtils.extendedAgeTo(self.annuitant.birthDate, new Date())
      self.title = self._gmaIIModalTitle(self.gmaIIRider, self.ehdbRider)
    } else {
      policyUtils.riderDetailResults(summaryResult, coveragesResult, clientResults, baseResult, tableResultLifetime, tableResultStandard)
        .then(function(results) {
          const sortedCoverages = ridersService.sortCoverages(coverages, riderBlacklists.cmsRiders)
          const combinedCmsRiderCodes = sortedCoverages.filter(cov => riderBlacklists.cmsRiders.includes(cov.riderCode)).map(cov => String(cov.riderCode + (cov.status ? '-' + cov.status : '')).toLowerCase()) ?? []
          const data = { ...results, coverages: sortedCoverages, clients: organizedClients }

          self.useCmsRiders = combinedCmsRiderCodes.length > 0

          if (self.useCmsRiders) {
            // Use CMS Based Rider Rendering
            const { availableLifetime, availableStandard } = coveragesResult.data

            sortedCoverages.forEach((coverage) => {
              coverage.availableLifetime = availableLifetime
              coverage.availableStandard = availableStandard
            })

            ridersService.getRiderSectionDefs(combinedCmsRiderCodes)
              .then((riderSectionDefs) => {
                if (riderSectionDefs.length > 0) {
                  self.cmsRiders = combinedCmsRiderCodes.map((riderCode, i) => {
                    return ridersService.generateRiderSections(riderCode, riderSectionDefs[i], data)
                  })

                  // Modal Title
                  self.title = self.cmsRiders[0]?.title

                  if(self.cmsRiders.length > 1) ridersService.resolveFirstSectionTitle(self.cmsRiders, data.coverages)

                  console.log('Rider Display\n', self.cmsRiders)
                } else {
                  policyUtils.handleRidersError(new Error(`Rider Not Found.`))
                  self.isThereError = true
                }
              })
          } else {
            // Use Levitas Rider Rendering

            self.sections = policyUtils.populateRiderModal(results, coveragesResult, ridersHelper)
            self.title = policyUtils.riderModalSectionTitle(results)
          }
        })
        .catch(function(error) {
          policyUtils.handleRidersError(error)
          self.isThereError = true
        })
    }
  }

  init()
}
