export {PolicyDetailAdvisorController}

PolicyDetailAdvisorController.$inject = ['policyAgentsResult', 'CONSTANTS', 'policyUtils']

function PolicyDetailAdvisorController(policyAgentsResult, CONSTANTS, policyUtils) {
  const self = this

  self.adviserRoleRecords = []
  self.errorMessage = ''
  self.isThereError = false

  function setError(error) {
    self.errorMessage = error
    self.isThereError = Boolean(self.errorMessage)
  }

  self.init = function() {
    if (!policyAgentsResult.error) {
      self.adviserRoleRecords = policyUtils.organizedAgents(policyAgentsResult)
    } else {
      setError(policyAgentsResult.error)
    }
  }

  self.init()
}
