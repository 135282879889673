const HOUR_IN_MILLISECODS = 1000 * 60 * 60
const DOWNLOAD_EXPIRATION_TIMESPAN = 48 * HOUR_IN_MILLISECODS
const STATUS_DOWNLOAD = 'DOWNLOAD'

class BatchStatementsDatum implements IBatchStatementsDatum {
  year: number
  quarter: number
  status: string
  fileName: string
  dateCreated: Date
  dateExpires: Date

  constructor (datum: IBatchStatementsDatumRaw, private downloadHostUrl: string) {
    this.year = datum.year
    this.quarter = datum.quarter
    this.status = datum.status
    this.fileName = datum.fileName
    this.dateCreated = new Date(datum.dateCreated)
    this.dateExpires = new Date(this.dateCreated.valueOf() + DOWNLOAD_EXPIRATION_TIMESPAN)
  }

  get key (): string {
    return 'Q' + this.quarter + '-' + this.year
  }

  get disabled (): boolean {
    // console.debug('%s: status: ', this.key, this.status, this.status !== 'AVAILABLE')
    return this.status !== 'AVAILABLE'
  }

  get downloadUrl (): string {
    return this.downloadHostUrl.replace(/\{fileName\}/gi, this.fileName)
  }

  get isExpired () {
    const delta = Date.now() - this.dateCreated.valueOf()

    return delta > DOWNLOAD_EXPIRATION_TIMESPAN
  }
}

class BatchStatementsData implements IBatchStatementsData {
  private _all: IBatchStatementsDatum[] = new Array < IBatchStatementsDatum >()
  private _available: IBatchStatementsDatum[] = new Array<IBatchStatementsDatum>()
  private _waiting: IBatchStatementsDatum[] = new Array<IBatchStatementsDatum>()
  private _download: IBatchStatementsDatum[] = new Array<IBatchStatementsDatum>()

  constructor (private downloadHostUrl: string) {}

  /**
   * Adds an IBatchStatementsDatum to the appropriate data bucket.
   * (e.g. available, pending, downloads)
   *
   * @param datum
   */
  add (datum: IBatchStatementsDatumRaw): IBatchStatementsData {
    // Prefixing with the underscore so the private member
    // can be dereferenced from `this`
    const bucketKey = '_' + datum.status.toLowerCase()

    const bucket = this[bucketKey]
    const newDatum = new BatchStatementsDatum(datum, this.downloadHostUrl)

    // Don't put expired downloads in downloads bucket.
    if (newDatum.status !== STATUS_DOWNLOAD || (newDatum.status === STATUS_DOWNLOAD && !newDatum.isExpired)) {
      bucket.push(newDatum)
    }

    this._all.push(newDatum) // Put a reference to newDatum in all list.

    return this // Makes this function chainable
  }

  /**
   * Removes all data from all buckets
   */
  clear (): void {
    this._all = new Array<IBatchStatementsDatum>()
    this._available = new Array<IBatchStatementsDatum>()
    this._waiting = new Array<IBatchStatementsDatum>()
    this._download = new Array<IBatchStatementsDatum>()
  }

  /**
   * Deprecated.
   * @param now
   */
  resolveQuarters (now = new Date()): IBatchStatementsDatum[] {
    const currentQuarter: number = Math.ceil((now.getMonth() + 1) / 3)
    const currentYear: number = now.getFullYear()
    const previousYear: number = currentYear - 1
    const makeQuarterKey = (q: number, y: number) => {
      return new BatchStatementsDatum({year: y, quarter: q, status: 'UNKNOWN'} as IBatchStatementsDatumRaw, '')
    }
    const quarters = [
      makeQuarterKey(1, previousYear),
      makeQuarterKey(2, previousYear),
      makeQuarterKey(3, previousYear),
      makeQuarterKey(4, previousYear),
    ]

    for (let q = 1; q <= currentQuarter; q++) { quarters.push(makeQuarterKey(q, currentYear)) }

    return quarters.slice(-4)
  }

  private getSortedAvailable () {
    const sorted: IBatchStatementsDatum[] = this._all
      .sort((a, b) => b.quarter - a.quarter)
      .sort((a, b) => b.year - a.year)
      .slice(0, 4)
      .sort((a, b) => a.quarter - b.quarter)
      .sort((a, b) => a.year - b.year)

    return sorted
  }

  sortAndFilterAvailable (): void {
    this._available = this.getSortedAvailable()
  }

  /**
   * Returns up to the to 4 available quarters sorted by year decending  and quarter decending.
   */
  get quarters (): IBatchStatementsDatum[] {
    return this._available
  }

  /**
   * Returns the list statement batches that have been requested, but not yet ready for download.
   */
  get pending (): IBatchStatementsDatum[] {
    return this._waiting
  }

  /**
   * Returns the list of statement batches ready for download
   */
  get downloads (): IBatchStatementsDatum[] {
    return this._download.sort((a: IBatchStatementsDatum, b: IBatchStatementsDatum) => a.dateExpires.valueOf() - b.dateExpires.valueOf())
  }

  /**
   * Return a combined list of pending, and downloadable statements.
   */
  get status (): IBatchStatementsDatum[] {
    const combined: IBatchStatementsDatum[] = Array.prototype.concat(this.pending, this.downloads)

    return combined
  }

  get hasData (): boolean {
    // console.debug('[BatchStatementsData::hasData] status: %s, quarters: %s', this.status.length, this.quarters.length)
    return !!(this.status.length + this.quarters.length)
  }

}

/**
 * Provides methods for requesting and posting data from the Core Services Batch-WS endpoint.
 */
export class BatchStatementsDataProvider implements IBatchStatementsDataProvider {

  static $inject = [
    '$http',
    'CONSTANTS',
    'BS_MOCK_API_ENDPOINTS',
    'BS_ENDPOINTS',
    'utils',
  ]

  constructor (private $http: ng.IHttpService, private CONSTANTS: any, private BS_MOCK_API_ENDPOINTS: any, private BS_ENDPOINTS: any, private utils: any) {}

  /**
   * When filterKey is truthy add it to the url as a query parameter.
   *
   * When sid is truthy add it to url as a query parameter.
   *
   * @param url
   * @param filterKey
   * @param sid
   */
  private addParamsToUrl (url, filterKey?: string, sid?: string) {
    let newUrl = ''

    newUrl = this.utils.appendURLParameter(url, 'filterKey', filterKey)
    newUrl = this.utils.appendURLParameter(newUrl, 'sid', sid)

    return newUrl
  }

  /**
   * Returns the appropriate url for the `available statements` endpoint.
   */
  private availableStatementsUrl (filterKey: string): string {
    return this.CONSTANTS.isDataServiceActive ? this.addParamsToUrl(this.BS_ENDPOINTS.AVAILABLE, filterKey) : this.BS_MOCK_API_ENDPOINTS.AVAILABLE
  }

  /**
   * Returns the appropriate url for the `request statements` endpoint.
   */
  private requestStatementsUrl (filterKey: string, year: number, quarter: number): string {
    return this.CONSTANTS.isDataServiceActive ? this.addParamsToUrl(this.BS_ENDPOINTS.REQUEST.replace(/\{year\}/gi, year).replace(/\{quarter\}/gi, quarter), filterKey) : this.BS_MOCK_API_ENDPOINTS.REQUEST
  }

  /**
   * Returns all available statement data for the logged in user, and converts to an
   * IBatchDatatements data type.
   */
  getAvailable (filterKey: string): Promise<IBatchStatementsData> {
    const url = this.availableStatementsUrl(filterKey)
    const bsd = new BatchStatementsData(this.BS_ENDPOINTS.DOWNLOAD)

    return this.$http.get(url)
      .then((response) => {
        const data = (response.data ? response.data : {type: {}, options: []}) as IBatchWSResponse

        const tmp: IBatchStatementsData = data.options.reduce((acc: IBatchStatementsData, item: IBatchStatementsDatumRaw) => {
          return acc.add(item)
        }, bsd)

        return tmp

      })
      .catch(() => bsd) as unknown as Promise<IBatchStatementsData>
  }

  /**
   * Submits a request for a downloadable zip file of statements by year and quarter.
   *
   * @param year
   * @param quarter
   */
  requestStatements (filterKey: string, year: number, quarter: number): Promise<boolean> {
    return this.$http.post(this.requestStatementsUrl(filterKey, year, quarter), {})
      .then(response => {
        if (response.status !== 204) {
          throw new Error('Unexpected response status code: ' + response.status)
        }
        return true
      })
      .catch(err => console.error(err)) as unknown as Promise<boolean>
  }

  /**
   * Returns url to a Download file, with the appropriate query string parameters.
   *
   * @param fileUrl
   * @param sid
   * @param filterKey
   */
  getDownloadUrl (fileUrl: string, sid: string, filterKey?: string): string {
    const url = this.addParamsToUrl(fileUrl, filterKey, sid)

    console.log(url)

    return url
  }

  makeBatchStatementDataObject (rootUrl: string = ''): IBatchStatementsData {
    return new BatchStatementsData(rootUrl)
  }
}
