import { ROLE_CONSTANTS } from '../relationships/role-relationships-service'
import { Beneficiary } from './../classes/beneficiary'
const TAX_ID_CONFIGS = {
  person: {
    label: 'SSN',
    format: '999-99-9999',
  },
  trust: {
    label: 'TIN',
    format: '99-9999999',
  },
  business: {
    label: 'EIN',
    format: '99-9999999',
  },
}

export class TaxIdController {
  static $inject = []

  type: string
  bene: Beneficiary
  config: any
  disabled: boolean

  // constructor() { }

  $onInit() {
    let configId = 'person'

    if (this.bene.partyTypeCode.tc === '2' && this.bene?.shareDistribution?.relationDescription?.tc === ROLE_CONSTANTS.RELATION_NAMES.TRUST) {
      configId = 'trust'
    } else if (this.bene.partyTypeCode.tc === '2' && this.bene?.shareDistribution?.relationDescription?.tc !== ROLE_CONSTANTS.RELATION_NAMES.TRUST) {
      configId = 'business'
    }

    this.config = TAX_ID_CONFIGS[configId]
  }

  // $onChanges(c) { }

  // $doCheck() {

  // }
}

export const taxIdComponentConfig = {
  bindings: {
    bene: '=',
    disabled: '=',
    userType: '=',
  },
  templateUrl: 'app/client/beneficiaries/tax-id/tax-id.html',
  controller: TaxIdController,
  controllerAs: 'ctrl',
}
