import { IGroupable, ITypeCodeValue } from '../types'
import AbstractTransformableData from './abstract-transformable-data'

export class TypeCodeValue extends AbstractTransformableData implements ITypeCodeValue, IGroupable {
  tc: string
  value: string
  isSeparator?: boolean | undefined
  group: string | undefined

  constructor(typeCode: any, group?: string) {
    super(['isSeparator', 'group', 'hasTypeCode'], [])

    this.tc = typeCode?.tc ?? ''
    this.value = typeCode?.value ?? ''
    this.isSeparator = typeCode?.isSeparator ?? false
    this.group = group
  }

  serialize() {
    return { tc: this.tc }
  }

  get hasTypeCode() {
    return this.tc !== ''
  }
}
