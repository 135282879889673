class ReplyText implements ng.IDirective {
  restrict = 'E'
  templateUrl = 'app/replyable-requirements/reply-text/template.html'
  scope: {
    requirement: '=',
  }
  replace: true

  // constructor() {}

  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    scope.closeRequirementReply = scope.$parent.parentController.closeRequirementReply
    scope.disableReplyInputs = scope.$parent.parentController.disableReplyInputs
    scope.longEnough = scope.$parent.parentController.longEnough
    scope.replyCharactersRemaining = scope.$parent.parentController.replyCharactersRemaining
    scope.replies = scope.$parent.parentController.replies
    scope.sendReply = scope.$parent.parentController.sendReply
  }

  static factory(): ng.IDirectiveFactory {

    const directive = () => new ReplyText()

    directive.$inject = []

    return directive
  }
}

angular.module('app.directives')
  .directive('replyText', ReplyText.factory())
