import {StateService, StateParams} from '@uirouter/core'
interface FilterChoice {
  checked: boolean
  value: string
}
export class PoliciesController {
  private productPopup: string = 'productType'
  private applicationMethodPopup: string = 'applicationMethod'
  private applicationFormalitiesPopup: string = 'applicationFormalities'
  private applicationIssueStatePopup: string = 'issueState'
  private deliveryStatusPopup: string = 'deliveryStatus'
  private DEFAULT_FILTER_VALUE: string = 'All'
  private params: any = {
  }
  private LOB_VALUES = {
    ALL: 'All',
    LIFE: 'Life',
    ANNUITY: 'Annuity',
  }

  private applicationMethods = [{
    checked: false,
    value: 'Electronic',
  }, {
    checked: false,
    value: 'Paper',
  }]

  private applicationFormalities = [{
    checked: false,
    value: 'Formal',
  }, {
    checked: false,
    value: 'Informal',
  }]

  public issueStates = [{
    checked: false,
    value: 'NY State',
  }, {
    checked: false,
    value: '49 States',
  }]

  private lob = {
    all: false,
    life: false,
    annuity: false,
  }

  private issueStateKey = 'issueState'
  private dotChecked = this.utils.dot('checked')
  private dotTypeCode = this.utils.dot('typeCode')
  private dotValue = this.utils.dot('value')
  private dotValueTypeCode = this.utils.dot('valueTypeCode')
  private productTypes = []
  private deliveryStatuses: FilterChoice[] = []
  private appliedFiltersText = this.DEFAULT_FILTER_VALUE
  public deliveryStatusFiltersText = this.DEFAULT_FILTER_VALUE
  public error: string
  public messageResult: string
  public notAvailableInfo: string
  public showAppMethodDropdown: boolean
  public SCROLL_DISTANCE: number
  public showAppFormalityFilter: boolean
  public showIssueStatesFilter: boolean = false

  isApplicationMethodMenuOpen: {}
  isIssueStateMenuOpen: {}
  isApplicationFormalityMenuOpen: {}
  isDeliveryStatusMenuOpen: {}
  isProductMenuOpen: {}
  toggleApplicationMethodMenu: {}
  toggleIssueStateMethodMenu: {}
  toggleApplicationFormalityMenu: {}
  toggleDeliveryStatusMenu: {}
  toggleProductMenu: {}

  constructor (private $state: StateService, private $stateParams: StateParams, private policyUtils, private prodTypesResult,
              private allPoliciesService, private CONSTANTS, private popupManager, private downloadManager, private authService,
              private deliveryStatusCounts, private utils) {

     // TODO remove this state. It should only be necessary when refreshing results

    this.allPoliciesService = this.allPoliciesService
    this.messageResult = this.allPoliciesService.message
    this.error = this.allPoliciesService.error
    this.notAvailableInfo = CONSTANTS.notAvailableInfo
    this.showAppMethodDropdown = true

    this.SCROLL_DISTANCE = 0

    this.popupManager.register(this.productPopup)
    this.popupManager.register(this.deliveryStatusPopup)
    this.popupManager.register(this.applicationFormalitiesPopup)
    this.popupManager.register(this.applicationIssueStatePopup)

    this.isApplicationMethodMenuOpen = this.popupManager.isOpen.bind(this.popupManager, this.applicationMethodPopup)
    this.isIssueStateMenuOpen = this.popupManager.isOpen.bind(this.popupManager, this.applicationIssueStatePopup)
    this.isApplicationFormalityMenuOpen = this.popupManager.isOpen.bind(this.popupManager, this.applicationFormalitiesPopup)
    this.isDeliveryStatusMenuOpen = this.popupManager.isOpen.bind(this.popupManager, this.deliveryStatusPopup)
    this.isProductMenuOpen = this.popupManager.isOpen.bind(this.popupManager, this.productPopup)

    this.toggleIssueStateMethodMenu = this.popupManager.toggle.bind(this.popupManager, this.applicationIssueStatePopup)
    this.toggleApplicationMethodMenu = this.popupManager.toggle.bind(this.popupManager, this.applicationMethodPopup)
    this.toggleApplicationFormalityMenu = this.popupManager.toggle.bind(this.popupManager, this.applicationFormalitiesPopup)
    this.toggleDeliveryStatusMenu = this.popupManager.toggle.bind(this.popupManager, this.deliveryStatusPopup)
    this.toggleProductMenu = this.popupManager.toggle.bind(this.popupManager, this.productPopup)

    this._init()

  }

  isScrollDisabled() {
    return this.allPoliciesService.isLoadingMore() || !this.allPoliciesService.moreData
  }

  nextPoliciesPage() {
    if (!this.allPoliciesService.isLoadingMore() && this.allPoliciesService.moreData) {
      this.allPoliciesService.nextPage(this.params)
    }
  }

  setActiveLob() {
    this.lob.all = true
  }

  showSearchHeader() {
    return this.$state.params.source !== 'notpaid'
  }

  isViewingClosed() {
    return this.$state.params.source === 'closed'
  }

  _showAppFormalityFilter() {
    return this.lob.life && this.$state.params.source !== this.CONSTANTS.policySource.inForce // TODO Uncomment to get back the app formality dropdown
  }

  _showIssueStateFilter() {
    return this.authService.isHomeOfficeUser()
  }

  refreshResults() {
    this.params.deliveryStatus = this.deliveryStatuses.filter(this.dotChecked).map(this.dotTypeCode)
    this.params.productType = this._checkedProductTypeValues()
    this.params.applicationMethod = this._applicationMethodParam(this.applicationMethods, this.DEFAULT_FILTER_VALUE)
    this.params.applicationFormalities = this._applicationFormalitiesParam(this.applicationFormalities, this.DEFAULT_FILTER_VALUE)
    this.params.issueState = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)
    this.allPoliciesService.resetData()
    this.allPoliciesService.nextPage(this.params)
    this.showAppMethodDropdown = this._showAppMethodDropdown()
    // these are already called in init even though init also calls refreshResults
    this.showAppFormalityFilter = this._showAppFormalityFilter()
    this.showIssueStatesFilter = this._showIssueStateFilter()
  }

  loadInitialResults() {
    this.params.deliveryStatus = this.deliveryStatuses.filter(this.dotChecked).map(this.dotTypeCode)
    this.params.productType = this._checkedProductTypeValues()
    this.params.applicationMethod = this._applicationMethodParam(this.applicationMethods, this.DEFAULT_FILTER_VALUE)
    this.params.applicationFormalities = this._applicationFormalitiesParam(this.applicationFormalities, this.DEFAULT_FILTER_VALUE)
    this.showIssueStatesFilter = this._showIssueStateFilter()
    this.params.issueState = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)
    this.allPoliciesService.resetData()
    // this.allPoliciesService.nextPage(this.params) // #####THIS METHOD DOES NOT CALL NEXT PAGE
    this.showAppMethodDropdown = this._showAppMethodDropdown()
    // these are already called in init even though init also calls refreshResults
  }

  _applicationMethodParam(applicationMethods, defaultValue) {
    let checked = applicationMethods.filter(this.dotChecked)

    if (!checked.length || checked.length === applicationMethods.length) {
      return defaultValue
    }

    return checked[0].value
  }

  _storeIssueStates() {
    let localStorage = window.localStorage

    localStorage.setItem(this.issueStateKey, JSON.stringify(this.issueStates))
  }

  _restoreIssueStates() {
    let localStorage = window.localStorage

    let storedIssueStates = localStorage.getItem(this.issueStateKey)

    if (storedIssueStates) {
      // @ts-ignore
      this.issueStates = JSON.parse(storedIssueStates)
      this.applyIssueStateFilters(false)
    }
  }

  _setDefaultIssueStates() {
    if (this._showIssueStateFilter()) {
      let response = this.authService.getCanAccessResponse()

      if (response.canAccess49 && !response.canAccessNY) {
          // set canAccess49 = checked
        this.issueStates[1].checked = true
      } else if (!response.canAccess49 && response.canAccessNY) {
          //
        this.issueStates[0].checked = true
      }
      this.$state.params.issueState = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)
    }
  }

  _applicationFormalitiesParam(applicationFormalities, defaultValue) {
    let checked = this.applicationFormalities.filter(this.dotChecked)

    if (!checked.length || checked.length === applicationFormalities.length) {
      return defaultValue
    }

    return checked[0].value
  }

  _issueStatesParam(issueStates, defaultValue) {
    let checked = this.issueStates.filter(this.dotChecked)

    if (!checked.length || checked.length === issueStates.length || !this.showIssueStatesFilter) {
      return defaultValue
    }

    /*
      includeState : Can be multiple, requires full state name, takes precedence over exclude param
      excludeState : Can be multiple, requires full state name, will not take effect if include is present
     */

    return checked[0].value
  }

  _isCheckedWithValue(object) {
    return object.checked && object.value // we check for value because sometimes it's not there and we don't know why yet
  }

  _checkedDeliveryStatusTypeCodes() {
    return this.deliveryStatuses
        .filter(this._isCheckedWithValue)
        .map(this.dotTypeCode)
  }

  _checkedDeliveryStatusValues() {
    return this.deliveryStatuses
        .filter(this._isCheckedWithValue)
        .map(this.dotValue)
  }

  _checkedProductTypeValues() {
    return this.productTypes
        .filter(this._isCheckedWithValue)
        .map(this.dotValue)
  }

  public isPendingPolicy(policy) {
    return this.policyUtils.isPendingPolicy(policy)
  }

  applyApplicationMethodFilters(initializing) {
    this.$state.params.applicationMethod = this._applicationMethodParam(this.applicationMethods, this.DEFAULT_FILTER_VALUE)
    this.popupManager.close(this.applicationMethodPopup)
    this.$state.go('search.policies', this.$state.params)
    if (!initializing) { this.refreshResults() }
  }

  applyIssueStateFilters(initializing) {
    let includeExcludeNyValue = this._issueStatesParam(this.issueStates, this.DEFAULT_FILTER_VALUE)

    this.$state.params.issueState = includeExcludeNyValue
    this.popupManager.close(this.applicationIssueStatePopup)
    this._storeIssueStates()
    this.$state.go('search.policies', this.$state.params)
    if (!initializing) { this.refreshResults() }
  }

  applyApplicationFormalityFilters(initializing) {
    this.$state.params.formalAppIndicator = this._applicationFormalitiesParam(this.applicationFormalities, this.DEFAULT_FILTER_VALUE)
    this.popupManager.close(this.applicationFormalitiesPopup)
    this.$state.go('search.policies', this.$state.params)
    if (!initializing) { this.refreshResults() }
  }

  applyProductTypeFilters(initializing) {
    this.$state.params.productType = this._checkedProductTypeValues()
    this.popupManager.close(this.productPopup)
    this.$state.go('search.policies', this.$state.params)
    this._setAppliedFiltersText()
    // console.log('are we initializing', initializing)
    if (!initializing) { this.refreshResults() }
  }

  applyDeliveryStatusFilters(initializing) {
    this.$state.params.deliveryStatus = this._checkedDeliveryStatusTypeCodes()
    this.popupManager.close(this.deliveryStatusPopup)
    // this._storeIssueStates()
    this.$state.go('search.policies', this.$state.params)
    this._setDeliveryStatusFiltersText()
    if (!initializing) { this.refreshResults() }
  }

  productTypesEmpty() {
    return this.productTypes && this.productTypes.length === 0
  }

  download() {
    let applicationMethodFilter = this._applicationMethodParam(this.applicationMethods, this.DEFAULT_FILTER_VALUE)
    let bookOfBusinessParameters: any = {
      holdingstatus: this.issuedNotPaidSelected() ? this.CONSTANTS.holdingStatus.issuedNotPaid : '3',
      lineofbusiness: this.$state.params.lob,
    }

    if (this.appliedFiltersText !== 'All') {
      bookOfBusinessParameters.productType = this._checkedProductTypeValues().join('&productType=')
    }

    if (this.$state.params.deliveryStatus) {
      bookOfBusinessParameters.subStatus = this._checkedDeliveryStatusTypeCodes().join('&subStatus=')
    }

    if (this.$state.params.source === this.CONSTANTS.policySource.pending) {
      bookOfBusinessParameters.sort = 'lastupdatedate'
      bookOfBusinessParameters.order = 'desc'
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Download pending',
      })
    }

    if (applicationMethodFilter !== this.DEFAULT_FILTER_VALUE) {
      bookOfBusinessParameters.aceindicator = applicationMethodFilter === 'Electronic'
    }

    this.downloadManager.getAllBOB(bookOfBusinessParameters)
  }

  pendingSelected() {
    return this.params.source === this.CONSTANTS.policySource.pending
  }

  issuedNotPaidSelected() {
    return this.params.source === this.CONSTANTS.policySource.notPaid
  }

  showDownloadLink() {
    let isCorrectRole = this.authService.checkRoles(['FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT', 'HOMEOFFICE'])

    return (this.pendingSelected() || this.issuedNotPaidSelected()) && isCorrectRole
  }

  _checkDeliveryStatusesFromStateParams(params) {
    let deliveryStatusTypeCodesToCheck

    if (Array.isArray(params.deliveryStatus)) {
      deliveryStatusTypeCodesToCheck = params.deliveryStatus
    } else {
      deliveryStatusTypeCodesToCheck = [params.deliveryStatus]
    }

    this.deliveryStatuses.forEach(function (deliveryStatus: any) {
      if (deliveryStatusTypeCodesToCheck.indexOf(deliveryStatus.typeCode) !== -1) {
        deliveryStatus.checked = true
      }
    })
  }

  _deliveryStatusesFromCounts(counts) {
    if (counts && counts.data && counts.data.counts) {
      return counts.data.counts
          .map(this.dotValueTypeCode)
          .map(function (valueTypeCode) {
            return {
              checked: false,
              typeCode: valueTypeCode.tc,
              value: valueTypeCode.value,
            }
          })
    }

    return []
  }

  _setApplicationMethodsFromStateParams(params: any) {
    let applicationMethodsToCheck = []

    if (typeof params.applicationMethod === 'string') {
      // @ts-ignore
      applicationMethodsToCheck.push(params.applicationMethod)
    } else if (Array.isArray(params.applicationMethod)) {
      applicationMethodsToCheck = params.applicationMethod
    }

    this.applicationMethods.forEach(function (applicationMethod: any) {
      // @ts-ignore
      if (applicationMethodsToCheck.indexOf(applicationMethod.value) !== -1) {
        applicationMethod.checked = true
      }
    })
  }

  _setAppliedFiltersText() {
    this.appliedFiltersText = this._checkedProductTypeValues().join(', ') || this.DEFAULT_FILTER_VALUE
  }

  _setDeliveryStatusFiltersText() {
    this.deliveryStatusFiltersText = this._checkedDeliveryStatusValues().join(', ') || this.DEFAULT_FILTER_VALUE
  }

  _setProductTypesFromResult(result) {
    this.productTypes = result.productTypes
  }

  _updateProductTypes() {
    return this.allPoliciesService.getProductTypes(this.params)
      .then((result) => this._setProductTypesFromResult(result))
  }

  _uncheckDeliveryStatuses() {
    this.deliveryStatuses.forEach(function (status) {
      status.checked = false
    })
  }

  _showAppMethodDropdown() {
    return this.params.source !== 'closed' && this.params.lineofbusiness === 'Life'
  }

  addTermConversionAppTypeOption(source, lob) {
    if (source === 'pending' && lob === 'Life') {
      this.applicationFormalities.push({
        checked: false,
        value: 'Term Conversion',
      })
    }
  }

  _init() {
    this.productTypes = this.prodTypesResult.productTypes
    this.deliveryStatuses = this._deliveryStatusesFromCounts(this.deliveryStatusCounts)
    this._checkDeliveryStatusesFromStateParams(this.$stateParams)
    this._setDeliveryStatusFiltersText()
    this._setApplicationMethodsFromStateParams(this.$stateParams)
    this._setAppliedFiltersText()
    this.params = {
      source: this.$stateParams.source || '',
      lineofbusiness: this.$stateParams.lob || this.LOB_VALUES.LIFE,
      productType: this._checkedProductTypeValues(),
      applicationMethod: this.$stateParams.applicationMethod || this.DEFAULT_FILTER_VALUE,
      applicationFormality: this.$stateParams.formalAppIndicator || this.DEFAULT_FILTER_VALUE,
      deliveryStatus: this.$stateParams.deliveryStatus || this.DEFAULT_FILTER_VALUE,
    }
    this.setActiveLob()
    this.showAppFormalityFilter = this._showAppFormalityFilter()
    this.showIssueStatesFilter = this._showIssueStateFilter()
    this._setDefaultIssueStates()
    this._restoreIssueStates()
    this.loadInitialResults()
    this.addTermConversionAppTypeOption(this.params.source, this.params.lineofbusiness)
  }

}
