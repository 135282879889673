import * as angular from 'angular'
angular.module('app.batch-statements', ['app', 'app.utils', 'app.auth', 'app.core'])
// import './constants' // we really need to use types here
import {BatchStatementsController} from './batch-statements-controller'
import {BatchStatementsDataProvider} from './data-provider'
import './batch-statements-controller'
import {AvialableDownloads} from './directive-available-downloads'
import {QuarterSelectorDirective} from './directive-quarter-selector'
import {BatchStatementsRunner} from './routes'
import { PendingStatementJobs } from './directive-pending-statement-jobs'
// import './types'

angular.module('app.batch-statements')
.constant('BS_MOCK_API_ENDPOINTS', {
  AVAILABLE: 'app/mock-api/batch-statements-available.json',
  REQUEST: 'app/mock-api/batch-statements-request.json',
})
.constant('BS_ENDPOINTS', {
  AVAILABLE: '/proxy/batch-ws/field/available/quarterly/statements',
  REQUEST: '/proxy/batch-ws/field/job/submit/quarterly/statements/{year}/{quarter}',
  DOWNLOAD: '/proxy/batch-ws/field/download/{fileName}',
})
.constant('BS_GTM', {
  BATCH_STATEMENTS: 'BATCH STATEMENTS',
  SUBMIT: 'SUBMIT',
  DOWNLOAD: 'DOWNLOAD',
})
.service('batchStatementsDataProvider', BatchStatementsDataProvider)
.controller('batchStatementsController', BatchStatementsController)
.directive('availableDownloads', AvialableDownloads.factory())
.directive('pendingStatementJobs', PendingStatementJobs.factory())
.directive('quarterSelector', QuarterSelectorDirective.factory())
  .run(routerHelper => new BatchStatementsRunner(routerHelper))
