import * as angular from 'angular'

import './client'
import './policy-relatives'
import './client-link.directive'
import './column-sort-icon.directive'
import './dom-click-touch.directive'
import './empty-state.directive'
import './gtm-click-tracker.directive'
import './handle-phone-submit.directive'
import './notification-center'
// import './notifications.directive'
import './party-selector-dropdown-directive'
import './prevent-close-dialog'
import './wg-agent-client-view-switch-directive'
import './wg-alert-details.directive'
import './wg-bcheckbox.directive'
import './wg-checkbox.directive'
import './wg-client-accounts.directive'
import './wg-client-add-payment-account.directive'
import './wg-client-info-header.directive'
import './wg-client-payment-account-listing.directive'
import './wg-client-payment-accounts.directive'
import './wg-conversion-credits.component'
import './wg-dropdown.directive'
import './wg-error.directive'
import './wg-favorite-client.directive'
import './wg-linear-loader.directive'
import './wg-pending-requirements-bar.directive'
import './wg-policy-product-info.directive'
import './wg-policy-transactions-history-table.directive'
import './wg-progress-bar.directive'
import './wg-spinner.directive'
import './wg-swipe-container.directive'
import './wg-swipe-content.directive'
import './wg-swipe-option.directive'
import './when-scrolled.directive'
import { WgNavigationHeaderDirective } from './navigation-header/directive'
import { WgNavigationHeaderController } from './navigation-header/controller'
import { NavigationService } from './navigation-header/navigation-service'
import { ClientNavigationHeaderController } from './client/client-navigation-header/controller'
import { WgPolicyItem, WgPolicyItemController } from './wg-policy-item.directive'
import { WgPendingPolicyItem } from './wg-pending-policy-item.directive'
import { FavorPolicyController } from './wg-favorite-policy.directive'
import { ClientRow } from './wg-client-row.directive'
import { WgUnsubmittedItemController, WgUnsubmittedItem } from './wg-unsubmitted-item/wg-unsubmitted-item.directive'
import { WgSpinnerSmall } from './wg-spinner-small.directive'
import { WgWhatsNewController, wgWhatsNew } from './wg-whats-new/wg-whats-new.directive'

angular
  .module('app.directives')
  .directive('wgNavigationHeader', WgNavigationHeaderDirective.factory())
  .service('navigationService', NavigationService)
  .controller('wgNavigationHeaderController', WgNavigationHeaderController)
  .directive('wgClientRow', ClientRow)
  // Expose the controller for testing purposes.
  .controller('clientNavigationHeaderController', ClientNavigationHeaderController)
  .controller('WgUnsubmittedItemController', WgUnsubmittedItemController)
  .directive('wgUnsubmittedItem', ['policyUtils', WgUnsubmittedItem])
  .controller('WgWhatsNewController', WgWhatsNewController)
  .directive('wgWhatsNew', wgWhatsNew)
  .component('clientNavigationHeader', {
    templateUrl: 'app/widgets/client/client-navigation-header/client-navigation-header.html',
    controller: ClientNavigationHeaderController,
    controllerAs: 'clientNavCtrl',
    bindings: {
      showPaymentMethods: '<', // oneWay
    },
  })
  .controller('WgPolicyItemController', WgPolicyItemController)
  .directive('wgPolicyItem', WgPolicyItem)
  .directive('wgPendingPolicyItem', WgPendingPolicyItem)
  .directive('wgSpinnerSmall', WgSpinnerSmall)

  .directive('wgFavoritePolicy', [function () {
    return {
      restrict: 'EA',
      scope: {
        policy: '=',
      },
      template: "<a ng-if='$root.isAgentView && !$root.viewAsAClient' class=\"btn-favorite\" ng-class=\"{'active': favoritePolicyCtrl.isFavoritePolicy()}\" ng-click=\"favoritePolicyCtrl.toggleFavorite($event)\">" +
        ' <span class="icon icon-heart-unselected"></span>' +
        '</a>',
      controller: ['$scope', 'userAccountService', FavorPolicyController],
      controllerAs: 'favoritePolicyCtrl',
      bindToController: true,
    }
  }])

// angular.module('app.client.directives', [])
