import { TypeCodeValue } from '../classes/type-code-value'
import { IEditableBeneficiary, ITypeCodeValue } from '../types'
import { RoleRelationshipsService } from './role-relationships-service'

export class RoleRelationshipsComponentController {
  static $inject: string[] = ['roleRelationshipsService']

  beneficiary: IEditableBeneficiary
  relationships: any
  editorOpen: boolean
  userType: string
  viewState: any

  constructor(private roleRelationshipsService: RoleRelationshipsService) { }

  get defaultRelationship(): any {
    if (this.beneficiary.hasPartyType) {
      // @ts-ignore
      return this.roleRelationshipsService.getDefaultOptionByPartyType(this.beneficiary?.partyTypeCode?.value?.toLocaleLowerCase())
    }

    return this.roleRelationshipsService.standardDefaultOption
  }

  get relationshipForPartyType(): ITypeCodeValue[] {
    const partyTypeTC = this.userType === 'client' ? 'clientPortal' : (this.beneficiary?.partyTypeCode?.tc || '0')
    const relations = this.roleRelationshipsService.getByTypeCode(partyTypeTC)

    return [this.defaultRelationship].concat(relations)
  }

  /**
   * Forces the bene to partyTypeCode '1' when the users chooses 'Convert to a Person'
   *
   * @param bene
   */
  relationshipSelected() {
    if (this.beneficiary?.shareDistribution?.relationDescription?.tc === '-100000') {
      this.beneficiary.shareDistribution.relationDescription = undefined
      this.beneficiary.partyTypeCode = new TypeCodeValue({ tc: '1', value: 'Person' })
    }

    if (this.beneficiary?.shareDistribution?.relationDescription?.tc === '-100001') {
      this.beneficiary.shareDistribution.relationDescription = undefined
      this.beneficiary.partyTypeCode = new TypeCodeValue({ tc: '2', value: 'Organization' })
    }

    // console.log('relationshipSelected', this.beneficiary?.shareDistribution?.relationDescription)

  }

  $onInit(): void {
    console.log(this.viewState)
    this.roleRelationshipsService.load()
      .then(() =>{
        const relation: ITypeCodeValue = this.relationshipForPartyType.find((r: ITypeCodeValue) => r.tc === this.beneficiary.shareDistribution.relationDescription.tc)

        if(!relation) {
          this.beneficiary.shareDistribution.relationDescription = this.defaultRelationship
        }
      })
  }
}

export const roleRelationshipComponentConfig = {
  templateUrl: 'app/client/beneficiaries/relationships/dropdown.html',
  controller: RoleRelationshipsComponentController,
  controllerAs: 'ctrl',
  require: {},
  bindings: {
    beneficiary: '<',
    // relationships: '<',
    editorOpen: '<',
    userType: '<',
    viewState: '<',
  },
}