export class IdleMonitorController {
  static $inject: string[] = ['$interval', '$scope', 'Idle', 'Keepalive', 'loggingService']

  // Bindings
  sessionDebugOn: boolean

  // Public Properties
  idleValue: number
  timeoutValue: number
  keepAliveInterval: number
  keepLoggedIn: boolean = false
  sessionMinutes: number = 0

  // private
  _sessionStartTime: Date = new Date()

  constructor(private $interval, private $scope, public idle, public keepAlive, private loggingService) { }

  saveSettings() {
    const curIdleValue = this.idle.getIdle()
    const idleValueChanged = curIdleValue !== this.idleValue
    const curTimeoutValue = this.idle.getTimeout()
    const timeoutValueChanged = curTimeoutValue !== this.timeoutValue
    const curKeepAliveInterval = this.keepAlive._options().interval
    const keepAliveIntervalChanged = curKeepAliveInterval !== this.keepAliveInterval

    console.info('[saveSetting] Idle: current: %s, new: %s; Timeout: current %s, new: %s; Keepalive: current: %s, new: %s', curIdleValue, this.idleValue, curTimeoutValue, this.timeoutValue, curKeepAliveInterval, this.keepAliveInterval)

    if (idleValueChanged) {
      this.idle.setIdle(this.idleValue)
      console.info('[saveSetting] changed idle value to ', this.idleValue)
    }

    if (timeoutValueChanged) {
      this.idle.setTimeout(this.timeoutValue)
      console.info('[saveSetting] changed timeout value to ', this.timeoutValue)
    }

    if (keepAliveIntervalChanged) {
      this.keepAlive.setInterval(this.keepAliveInterval)
      this.keepAlive.start()
      console.info('[saveSetting] changed keepalive value to ', this.keepAliveInterval)
    }
  }

  $onInit() {
    this.idleValue = this.idle.getIdle()
    this.timeoutValue = this.idle.getTimeout()
    this.keepAliveInterval = this.keepAlive._options().interval

    // Only log ngIdle events when enableIdleMonitorDebugLogging is true.
    if (this.$scope.$root.featureFlags.enableIdleMonitorDebugLogging) {
      this.$scope.$on('IdleStart', () => {
        this.loggingService.log('IdleStart', 'info', 'IdleMonitor')
      })

      this.$scope.$on('IdleEnd', () => {
        this.loggingService.log('IdleEnd', 'info', 'IdleMonitor')
      })

      // this.$scope.$on('IdleTimeout', () => {
      //   this.loggingService.log('IdleTimeout', 'info', 'IdleMonitor')
      // })

      this.$scope.$on('IdleWarn', (_e, _countdown) => {
        // follows after the IdleStart event, but includes a countdown until the user is considered timed out
        // the countdown arg is the number of seconds remaining until then.
        // you can change the title or display a warning dialog from here.
        // you can let them resume their session by calling Idle.watch()
        // this.loggingService.log('IdleWarn', 'info', 'IdleMonitor')
      })

      this.$scope.$on('Keepalive', () => {
        this.$scope.keepAliveEvent = (new Date()).toLocaleString()
      })
    }

    this.$scope.$on('IdleTimeout', () => {
      this.loggingService.log(`IdleTimeout: Session was alive for ${this.sessionMinutes} minutes`, 'info', 'IdleMonitor')

      if (this.sessionDebugOn && this.keepLoggedIn) {
        this.idle.watch()
        return
      }

      this.$scope.$root.auth.logout('User Idle Timed out')
    })

    this.$scope.$on('KeepaliveResponse', (_data, _statusText, status) => {

      if (status !== 200) {
        this.$scope.$evalAsync(() => {
          this.loggingService.log(`KeepaliveResponse: Session timedout on the server, it was alive for ${this.sessionMinutes} minutes`, 'info', 'IdleMonitor')
          this.$scope.$root.auth.logout(`Keepalive failed with status code ${status}. Was expeecting status code of 200; forcing logout.`)
        })
      }
    })

    this.$interval(() => {
      // @ts-ignore
      this.sessionMinutes = Math.floor((Date.now() - this._sessionStartTime) / 1000 / 60)

    }, 60000)
  }
}
