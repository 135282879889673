export {ClientAlertController}

ClientAlertController.$inject = ['apiResult', 'policies']

/* @ngInject */
function ClientAlertController(apiResult, policies) {
  const self = this

  self.client = {}
  self.errorMessage = ''
  self.policies = policies

  self.filterAlertsByPolicies = function(alerts) {
    const ret = []
    let i

    angular.forEach(alerts, function(alert) {
      for (i = 0; i < policies.length; i++) {
        if (policies[i].polNumber === alert.polNumber) {
          ret.push(alert)
          return ret
        }
      }
    })

    return ret
  }

  self.isThereError = function() {
    return Boolean(self.errorMessage)
  }

  function init() {
    if (apiResult.error) {
      self.errorMessage = apiResult.error
    } else {
      self.client = apiResult.client
    }
  }

  init()
}
