import angular, { IDirective } from "angular"
import { RX_PNY_POLICY_PREFIX } from "../../components/companies/companies-service"


class ReplyUpload implements IDirective {
  restrict = 'E'
  templateUrl = 'app/replyable-requirements/reply-upload/template.html'
  scope: {
    requirement: '='
  }
  replace: true

  // constructor() {}

  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    scope.closeRequirementReply = scope.$parent.parentController.closeRequirementReply
    scope.uploadFile = scope.$parent.parentController.uploadFile
    scope.polNumber = scope.$parent.$parent.policy?.policyNumber
    scope.isPNY = RX_PNY_POLICY_PREFIX.test(scope.polNumber)
  }

  static factory(): ng.IDirectiveFactory {

    const directive = () => new ReplyUpload()

    directive.$inject = []

    return directive
  }
}

angular.module('app.directives')
  .directive('replyUpload', ReplyUpload.factory())
