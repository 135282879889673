import * as angular from 'angular'
import { ISerializabeProperty } from '../types'

export const CLIENT_SERIALIZABLE_PROPERTIES: ISerializabeProperty[] = [
  { name: 'id'},
  { name: 'trustDate', type: 'date'},
  { name: 'dob', gwName: 'birthDate', type: 'date' },
  // { name: 'taxID', gwName: 'ssn' },
  { name: 'ssn'},
  { name: 'ssnStatus'},
  { name: 'gender', type: 'TypeCodeValue' },
  { name: 'estateName', gwName: 'fullName'},
  { name: 'partyTypeCode', type: 'TypeCodeValue' }, // 1, 2
  { name: 'name', type: 'EntityName' },
]
const ROLE_SERIALIZABLE_PROPERTIES = [
  { name: 'id'}, // Client ID
  { name: 'roles', type: 'Array<IRole>' },
]

const DISTRIBUTION_SERIALIZABLE_PROPERTIES = [
  { name: 'distributionOption', type: 'ITypeCodeValue' },
  { name: 'distributionValue', type: 'number' },
  { name: 'distributionOptionDesc' },
  { name: 'beneShareMethod' },
]

angular.module('app.beneficiaries')
  .constant('CLIENT_SERIALIZABLE_PROPERTIES', CLIENT_SERIALIZABLE_PROPERTIES)
  .constant('ROLE_SERIALIZABLE_PROPERTIES', ROLE_SERIALIZABLE_PROPERTIES)
  .constant('DISTRIBUTION_SERIALIZABLE_PROPERTIES', DISTRIBUTION_SERIALIZABLE_PROPERTIES)
