(function() {
  'use strict'

  angular
    .module('app.directives')
    .directive('wgCheckbox', function() {
      let template

      controller.$inject = ['$attrs']

      function controller($attrs) {
        const self = this

        function init() {
        }

        init()

        self.toggle = function($event) {
          if (self.isDisabled) {
            return
          }
          self.checked = !self.checked

          if ($attrs.hasOwnProperty('onlyClickThis')) {
            $event.stopPropagation()
          }
        }
      }

      template = '<div class="wg-checkbox" ng-click="ctrl.toggle($event)"><div class="wg-checkbox-container pull-left">' +
        '<div class="wg-checkbox-inner" ng-class="{\'checked icon-check\': ctrl.checked, \'check disabled\': ctrl.isDisabled}"></div></div>' +
        '<ng-transclude></ng-transclude></div>'

      return {
        restrict: 'E', // Default for 1.3+
        scope: {
          checked: '=ngModel',
          isDisabled: '=disabled',
        },
        transclude: true,
        controller: controller,
        controllerAs: 'ctrl',
        bindToController: true, // required in 1.3+ with controllerAs
        template: template,
      }
    })
})()
