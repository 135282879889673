import {BUSINESS_UNITS} from './address-constants'
const FORMS: any[] = [
  {
    lineOfBusiness: 'annuities',
    title: 'Authorization for Direct Deposit of Annuity Payments PM7432',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'annuities',
    title: 'Annuity Beneficiary Designation Form PM4799',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'annuities',
    title: 'Withholding Certificate for Pension or Annuity Payments W-4P 2018',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'annuities',
    title: 'Fund Service Request Form for Annuity Products PM5795',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'annuities',
    title: 'Request for Taxpayer Identification Number and Certification Nov 2017',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Life & Annuity Name & Address Change Form PM6360',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Owner Designation Form PM6533',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Release of Collateral Assignment 305',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Beneficiary Designation Form PM6532',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Assignment of Policy as Collateral 5278',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Penn Mutual Certification of Trust PM6389',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },
  {
    lineOfBusiness: 'life',
    title: 'Request for Taxpayer Identification Number and Certification Nov 2017',
    type: '',
    file: {[BUSINESS_UNITS.PML]: '', [BUSINESS_UNITS.PML_NY]: '', [BUSINESS_UNITS.PNY]: ''},
    icon: '',
    id: '',
  },

]

const FORMS_MAJOR_SECTION_NAME = {
  all: '',
  life: 'Life Insurance Policies',
  annuity: 'Annuity Contracts',
}

export {FORMS_MAJOR_SECTION_NAME}
