export class InputNumberFormatDirective implements ng.IDirective {
  require = 'ngModel'
  restrict = 'A'

  constructor(private $filter) { }

  link(_scope, elem, attrs, ctrl) {
    if (!ctrl) return

    ctrl.$formatters.unshift(() => {
      return this.$filter(attrs.format)(ctrl.$modelValue)
    })

    ctrl.$parsers.unshift((viewValue) => {
      if (viewValue) {
        const plainNumber = Number(viewValue.replace(/[^\d|\-+|\.+]/g, ''))

        elem.val(this.$filter('number')(plainNumber))

        return plainNumber
      } else {
        return ''
      }
    })
  }

  static factory(): ng.IDirectiveFactory {
    const directive = ($filter) => new InputNumberFormatDirective($filter)

    directive.$inject = ['$filter']

    return directive
  }
}
