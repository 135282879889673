angular.module('app.directives').directive('wgFavoriteClient', [function() {
  return {
    restrict: 'EA',
    scope: {
      client: '=',
    },
    template: "<a ng-if='$root.isAgentView' class=\"btn-favorite\" ng-class=\"{'active': favoriteClientCtrl.isFavoriteClient()}\" ng-click=\"favoriteClientCtrl.toggleFavorite($event)\">" +
        '  <span class="icon icon-heart-unselected"></span>' +
        '</a>',
    controller: ['$scope', 'userAccountService', 'authService', function($scope, userAccountService, authService) {
      const self = this

      self.isFavoriteClient = function() {
        return userAccountService.isFavoriteClient(self.client)
      }

      self.toggleFavorite = function($event) {
        userAccountService.toggleFavoriteClient(self.client)
        if ($event) {
          $event.stopPropagation()
        }
      }
    }],
    controllerAs: 'favoriteClientCtrl',
    bindToController: true,
  }
}])
