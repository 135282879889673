import { IController, IFormController, INgModelController, IOnChangesObject, IScope } from "angular"
import { EditBeneficiaryController } from "../../client/beneficiaries/editors/edit-beneficiary-directive"
import { Beneficiary } from "../../client/beneficiaries/classes/beneficiary"

class PartyPersonNameController implements IController {
  static $inject = ['$scope']

  ngModel: INgModelController
  partyForm: IFormController
  beneEditCtrl: EditBeneficiaryController
  formTouched: Boolean = false
  currentBeneficiary: Beneficiary
  backupBeneficiary: Beneficiary

  constructor(private $scope: any) {}

  onChange(): void {
    if (!this.formTouched) {
      this.formTouched = true
      this.currentBeneficiary.ssn = ''
      this.currentBeneficiary.dob = undefined
    }
  }

  $onInit(): void {
    this.currentBeneficiary = this.beneEditCtrl.parentController.clientBeneMainCtrl.currentBeneficiary
    this.backupBeneficiary = this.beneEditCtrl.parentController.clientBeneMainCtrl.backupCurrentBeneficiary
  }

  $doCheck(): void {
    if (!this.partyForm) {
      this.partyForm = this.$scope.partyNameForm
    }
  }
}

export const partyPersonNameComponent = {
  templateUrl: 'app/components/party-person-name/party-person-name.html',
  controller: PartyPersonNameController,
  controllerAs: 'ctrl',
  bindings: {
    beneEditCtrl: '<',
  },
  require: {
    ngModel: 'ngModel',
  },
}
