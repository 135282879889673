import * as angular from 'angular'
import {notifications} from './notifications.directive'
angular.module('notification-center', [])

angular.module('notification-center').service('notificationCenterService', notificationCenterService)
angular.module('notification-center').directive('notifications', notifications)

notificationCenterService.$inject = ['$rootScope', '$sce', '$timeout', '$filter']

  /* @ngInject */
function notificationCenterService($rootScope, $sce, $timeout, $filter) {
  const service = {
    messages: this.messages || [],
    offlistener: this.offlistener || undefined,
    status: {
      unseen: 'unseen',
      shown: 'shown',
      next: 'next',
      permanent: 'permanent',
    },
  }

  service.add = function(type, text, channels, id, options) {
    const types = ['info', 'warning', 'danger', 'success']

    id = id || 0

    if (this.exist(id)) {
      return
    }

    channels = channels || ''
    options = options || {}

    if (types.indexOf(type) === -1) {
      throw new Error('Invalid message type')
    }

    const messageObject = {
      id: id,
      type: type,
      status: options.status || service.status.unseen,
      channels: stringToArray(channels),
      processed: false,
      close: function() {
        if (options.onClose) options.onClose()
        return service.remove(this)
      },
    }

    messageObject.text = options.html ? $sce.trustAsHtml(text) : text
    messageObject.html = Boolean(options.html)

    if (angular.isDefined(options.timeout)) {
      messageObject.timer = $timeout(function() {
        messageObject.close()
      }, options.timeout)
    }

    if (options.top) {
      this.messages.unshift(messageObject)
    } else {
      this.messages.push(messageObject)
    }

    return messageObject
  }

  service.exist = function(id) {
    for (let index = this.messages.length - 1; index >= 0; index--) {
      if (this.messages[index].id === id) {
        return true
      }
    }
    return false
  }

  function stringToArray(filters) {
    return filters.trim().split(',').map(function(value) {
      return value.trim()
    })
  }

  service.removeById = function(id) {
    for (let index = this.messages.length - 1; index >= 0; index--) {
      if (this.messages[index].id === id) {
        this.messages.splice(index, 1)
        return true
      }
    }

    return false
  }

  service.remove = function(message) {
    const index = this.messages.indexOf(message)

    this.messages.splice(index, 1)
  }

  service.reset = function() {
    this.messages = []
  }

  service.removeShown = function() {
    for (let index = this.messages.length - 1; index >= 0; index--) {
      if (this.messages[index].status === this.status.shown) {
        this.remove(this.messages[index])
      }
    }
  }

  service.markShown = function() {
    for (let index = this.messages.length - 1; index >= 0; index--) {
      if (!this.messages[index].processed) {
        if (this.messages[index].status === this.status.unseen) {
          this.messages[index].status = this.status.shown
          this.messages[index].processed = true
        } else if (this.messages[index].status === this.status.next) {
          this.messages[index].status = this.status.unseen
        }
      }
    }
  }

  return service
}
