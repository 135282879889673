import { Beneficiary } from '../../beneficiaries/classes/beneficiary'
import { PhoneList } from '../../beneficiaries/classes/phone-list'

export class RolesEditPersonalInfoController {
  static $inject: any = ['stateUtils']

  phoneList: PhoneList
  beneficiary: Beneficiary

  constructor(public stateUtils) {}

  previous = () => this.stateUtils.checkUserAndGotoState('roles.edit.personal-details')

  next = () => this.stateUtils.checkUserAndGotoState('roles.edit.address-edit')

  $onInit() {
    this.phoneList = new PhoneList([])
  }
}
