/**
 * This directive adds a click event to the element and toggles
 * it's expandable state. Two states are supported; Expand, and
 * Collapse
 *
 * This directive requires a parent directive of type `pmlNgListView`,
 * which provides an `toggleChildRow` function.
 *
 */
export class PmlExpandableRowDirective implements ng.IDirective {
  restrict = 'A'
  // templateUrl = 'app/cl-and-r/tmpl/sortable-column.html'
  replace = false
  require = '^^pmlNgListView'
  // bindToController = true
  transclude = false

  constructor(private utils) { }

  /*
   * NOTE: Uncomment this function if you need to do things before
           the directive is bound to the scope.
   */
  // compile(_el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
  //   console.log("compile", _el)
  //   return this.link
  // }

  /**
   * AngularJS post link function use for initial configuration of instances of PmlListViewDirective
   */
  // tslint:disable-next-line
  link(scope: any, el: ng.IAugmentedJQuery, attrs: ng.IAttributes, _ctrl) {

    // Initialize sorter and it's icon
    const toggle = this.utils.jqLiteFindByAttribute(el.children(), 'row-toggle')
    const expandRow = attrs.pmlExpandableRow === 'expand'

    if (toggle) {
      toggle.css({ cursor: 'pointer' })
      toggle.append(`<span title="Show Lines"><i class="icon ${expandRow ? 'icon-sort-up-arrow' : 'icon-sort-down-arrow'}"></i></span>`)

      const childRow = this.utils.jqLiteFindByAttribute(el.next(), 'pml-expandable-row-child')  // It is assumed that the next row is the child row.

      // console.log('>>>> childRow', childRow)
        /**
         * childRow defaults to collapsed state by default. Setting the directive's
         * attribute to 'expand' will cause the the childRows to be expanded.
         */

      if (attrs.pmlExpandableRow !== 'expand') {
        childRow.addClass('hidden')

      }

      const icon = toggle.find('i')

      el.on('click', () => {
        // console.debug(attrs.pmlSortableColumn)

        // Find all sorter icons and remove any arrow class applied
        // const allIcons = el.parent().find('i')

        // Apply expand/collapse state logic & trigger sort
        if (icon.hasClass('icon-sort-down-arrow')) {
          childRow.removeClass('hidden')
          // childRow.addClass('show')
          childRow.attr('title', 'Show Lines')

          icon.removeClass('icon-sort-down-arrow')
          icon.addClass('icon-sort-up-arrow')

        } else if (icon.hasClass('icon-sort-up-arrow')) {
          childRow.addClass('hidden')
          // childRow.removeClass('show')
          childRow.attr('title', 'Hide Lines')

          icon.removeClass('icon-sort-up-arrow')
          icon.addClass('icon-sort-down-arrow')
        }

          // Need this because we are not in an AngularJS event handler
        scope.$apply()

      })
    } else {
      throw new Error('pml-expandable-row directive requires a child cell to be decorated with a `row-toggle` attribute.')
    }
  }

  /**
   * Creates an instance of TrainingsDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = (utils) => new PmlExpandableRowDirective(utils)

    directive.$inject = ['utils']

    return directive
  }
}
