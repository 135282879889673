interface ReportSection {
  title: string
  allowedRoles: string[]
  links: Link[]
}
interface Link {
  title: string
  url: string
}

export class SalesAchievementReportsController {
  static $injector: any = ['authService', '$http']

  showFieldLeadershipLink: boolean
  public linkSections: ReportSection[] | undefined

  constructor(private authService, private $http: angular.IHttpService) {
    void this.populateSalesLinks().then(() => {
      console.info('linksections are', this.linkSections)
    })
  }

  private populateSalesLinks() {
    const url = '/insight-content/sales-achivement-reports.json'

    return this.$http.get(url).then((result: angular.IHttpResponse<ReportSection[]>) => {
      this.linkSections = result.data
    })
  }

  isSectionAllowed(section: ReportSection) {
    return section.allowedRoles ? this.authService.checkRoles(section.allowedRoles) : true
  }

}
