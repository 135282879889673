import * as angular from 'angular'
export class WgNavigationHeaderDirective implements angular.IDirective {
  restrict = 'E'
  // scope = {}
  templateUrl = 'app/widgets/navigation-header/navigation-header.html'
  controller = 'wgNavigationHeaderController'
  controllerAs = 'wgNavHeaderCtrl'

  // link(s, el, _a, c) {
  //   console.debug('HELLO', s.$root.isAgentView, el.html(), s.$root.hideBarHeader, c)
  // }
  /**
   * Creates an instance of ClientPaymentDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new WgNavigationHeaderDirective()

    directive.$inject = []

    return directive
  }
}
