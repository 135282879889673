/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as angular from 'angular'

export class CreditCardDataProviderService {
  static $injector = ['$q', '$http', 'CONSTANTS', 'configService']

  constructor(private $q: angular.IQService, private $http: angular.IHttpService, private CONSTANTS, private configService) {}

  getJpMorganCreditCardUrl() {
    const url = `${this.CONSTANTS.apiRoot}client/deepLink/CC?returnUrl=${this.configService.appRoot}/pconWeb/public/`

    return this.$http.get(url).then((result) => {
      return result.data
    })
  }

  getPaymentusCreditCardUrl() {
    const url = `${this.CONSTANTS.apiRoot}client/paymentus/deepLink/CC?postMessagePmDetailsOrigin=${this.configService.appRoot}`

    return this.$http.get(url).then((result) => {
      return result.data
    })
  }

  sendCreditCardType(jpmAccountPayload) {
    console.log('jpmAccountPayload', jpmAccountPayload)

    return this.$q((resolve, reject) => {

      if (!jpmAccountPayload || jpmAccountPayload.status === 'ERROR') {
        reject(new Error('Credit card modal closed by user'))
      } else {
        const payload = jpmAccountPayload.payload
       // const url = `${this.CONSTANTS.apiRoot}client/payment/accounts/card/${payload?.Token}/CC/${payload?.Type}`
        const url = `${this.CONSTANTS.apiRoot}client/payment/accounts/card/CC/${payload?.Type}`

        this.$http.post(url, payload?.Token).then(() => resolve(payload))
      }
    })
  }
}