export const AcordCountries = [{code: '1', name: 'USA'},
  {code: '2', name: 'CANADA'},
  {code: '7', name: 'RUSSIAN FEDERATION'},
  {code: '20', name: 'EGYPT'},
  {code: '27', name: 'SOUTH AFRICA'},
  {code: '30', name: 'GREECE'},
  {code: '31', name: 'NETHERLANDS'},
  {code: '32', name: 'BELGIUM'},
  {code: '33', name: 'FRANCE'},
  {code: '34', name: 'SPAIN'},
  {code: '36', name: 'HUNGARY'},
  {code: '38', name: 'YUGOSLAVIA'},
  {code: '39', name: 'ITALY'},
  {code: '40', name: 'ROMANIA'},
  {code: '41', name: 'SWITZERLAND'},
  {code: '42', name: 'CZECHLOSLOVAKIA'},
  {code: '43', name: 'AUSTRIA'},
  {code: '44', name: 'UNITED KINGDOM'},
  {code: '45', name: 'DENMARK'},
  {code: '46', name: 'SWEDEN'},
  {code: '47', name: 'NORWAY'},
  {code: '48', name: 'POLAND'},
  {code: '49', name: 'GERMANY'},
  {code: '51', name: 'PERU'},
  {code: '52', name: 'MEXICO'},
  {code: '53', name: 'CUBA'},
  {code: '54', name: 'ARGENTINA'},
  {code: '55', name: 'BRAZIL'},
  {code: '56', name: 'CHILE'},
  {code: '57', name: 'COLOMBIA'},
  {code: '58', name: 'VENEZUELA'},
  {code: '60', name: 'MALAYSIA'},
  {code: '61', name: 'AUSTRALIA'},
  {code: '62', name: 'INDONESIA'},
  {code: '63', name: 'PHILIPPINES'},
  {code: '64', name: 'NEW ZEALAND'},
  {code: '65', name: 'SINGAPORE'},
  {code: '66', name: 'THAILAND'},
  {code: '81', name: 'JAPAN'},
  {code: '82', name: 'KOREA'},
  {code: '84', name: 'VIET NAM'},
  {code: '86', name: 'CHINA'},
  {code: '90', name: 'TURKEY'},
  {code: '91', name: 'INDIA'},
  {code: '92', name: 'PAKISTAN'},
  {code: '93', name: 'AFGHANISTAN'},
  {code: '94', name: 'SRI LANKA'},
  {code: '95', name: 'BURMA (UNION OF MYANMAR)'},
  {code: '98', name: 'IRAN, ISLAMIC REPUBLIC OF'},
  {code: '212', name: 'MOROCCO'},
  {code: '213', name: 'ALGERIA'},
  {code: '216', name: 'TUNISIA'},
  {code: '218', name: 'LIBYAN ARAB JAMAHIRIYA'},
  {code: '220', name: 'GAMBIA'},
  {code: '221', name: 'SENEGAL'},
  {code: '222', name: 'MAURITANIA'},
  {code: '223', name: 'MALI'},
  {code: '224', name: 'GUINEA'},
  {code: '225', name: 'IVORY COAST, REPUBLIC OF'},
  {code: '226', name: 'BURKINA FASO'},
  {code: '227', name: 'NIGER'},
  {code: '228', name: 'TOGO'},
  {code: '229', name: 'BENIN'},
  {code: '230', name: 'MAURITIUS'},
  {code: '231', name: 'LIBERIA'},
  {code: '232', name: 'SIERRA LEONE'},
  {code: '233', name: 'GHANA'},
  {code: '234', name: 'NIGERIA'},
  {code: '235', name: 'CHAD'},
  {code: '236', name: 'CENTRAL AFRICAN REPUBLIC'},
  {code: '237', name: 'CAMEROON'},
  {code: '238', name: 'CAPE VERDE'},
  {code: '239', name: 'SAO TOME AND PRINCIPE'},
  {code: '240', name: 'EQUATORIAL GUINEA'},
  {code: '241', name: 'GABON'},
  {code: '242', name: 'BAHAMAS'},
  {code: '243', name: 'ZAIRE'},
  {code: '244', name: 'ANGOLA'},
  {code: '246', name: 'BARBADOS'},
  {code: '247', name: 'ASCENSION ISLANDS'},
  {code: '249', name: 'SUDAN'},
  {code: '250', name: 'RWANDA'},
  {code: '251', name: 'ETHIOPIA'},
  {code: '252', name: 'SOMALIA'},
  {code: '253', name: 'DJIBOUTI'},
  {code: '254', name: 'KENYA'},
  {code: '255', name: 'TANZANIA, UNITED REPUBLIC OF'},
  {code: '256', name: 'UGANDA'},
  {code: '257', name: 'BURUNDI'},
  {code: '258', name: 'MOZAMBIQUE'},
  {code: '260', name: 'ZAMBIA'},
  {code: '261', name: 'MADAGASCAR'},
  {code: '262', name: 'REUNION'},
  {code: '263', name: 'ZIMBABWE'},
  {code: '264', name: 'NAMIBIA'},
  {code: '265', name: 'MALAWI'},
  {code: '266', name: 'LESOTHO'},
  {code: '267', name: 'BOTSWANA'},
  {code: '268', name: 'ANTIGUA AND BARBUDA'},
  {code: '269', name: 'COMOROS'},
  {code: '270', name: 'GUINEA-BISSAU'},
  {code: '271', name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE'},
  {code: '284', name: 'VIRGIN ISLANDS, BRITISH'},
  {code: '290', name: 'SAINT HELENA'},
  {code: '297', name: 'ARUBA'},
  {code: '298', name: 'FAROE ISLANDS'},
  {code: '299', name: 'GREENLAND'},
  {code: '345', name: 'CAYMAN ISLANDS'},
  {code: '350', name: 'GILBRATAR'},
  {code: '351', name: 'PORTUGAL'},
  {code: '352', name: 'LUXEMBOURG'},
  {code: '353', name: 'IRELAND'},
  {code: '354', name: 'ICELAND'},
  {code: '355', name: 'ALBANIA'},
  {code: '356', name: 'MALTA'},
  {code: '357', name: 'CYPRUS'},
  {code: '358', name: 'FINLAND'},
  {code: '359', name: 'BULGARIA'},
  {code: '370', name: 'LITHUANIA'},
  {code: '371', name: 'LATVIA'},
  {code: '372', name: 'ESTONIA'},
  {code: '373', name: 'MOLDOVA, REPUBLIC OF'},
  {code: '374', name: 'ARMENIA'},
  {code: '375', name: 'BELARUS'},
  {code: '376', name: 'ANDORRA'},
  {code: '378', name: 'SAN MARINO'},
  {code: '380', name: 'UKRAINE'},
  {code: '385', name: 'CROATIA'},
  {code: '386', name: 'SLOVENIA'},
  {code: '387', name: 'BOSNIA AND HERZEGOVINA'},
  {code: '389', name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF'},
  {code: '420', name: 'CZECH REPUBLIC'},
  {code: '421', name: 'SLOVAKIA'},
  {code: '423', name: 'LIECHTENSTEIN'},
  {code: '441', name: 'BERMUDA'},
  {code: '473', name: 'GRENADA'},
  {code: '500', name: 'FALKLAND ISLANDS (MALVINAS)'},
  {code: '501', name: 'BELIZE'},
  {code: '502', name: 'GUATEMALA'},
  {code: '503', name: 'EL SALVADOR'},
  {code: '504', name: 'HONDURAS'},
  {code: '505', name: 'NICARAGUA'},
  {code: '506', name: 'COSTA RICA'},
  {code: '508', name: 'SAINT PIERRE AND MIQUELON'},
  {code: '509', name: 'HAITI'},
  {code: '510', name: 'PUERTO RICO'},
  {code: '511', name: 'VIRGIN ISLANDS, US'},
  {code: '512', name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS'},
  {code: '590', name: 'GUADELOUPE'},
  {code: '591', name: 'BOLIVIA'},
  {code: '592', name: 'GUYANA'},
  {code: '593', name: 'ECUADOR'},
  {code: '594', name: 'FRENCH GUIANA'},
  {code: '595', name: 'PARAGUAY'},
  {code: '596', name: 'MARTINIQUE'},
  {code: '597', name: 'SURIname'},
  {code: '598', name: 'URUGUAY'},
  {code: '599', name: 'NETHERLANDS ANTILLES'},
  {code: '649', name: 'TURKS AND CAICOS ISLANDS'},
  {code: '664', name: 'MONTSERRAT'},
  {code: '670', name: 'SAIPAN'},
  {code: '671', name: 'GUAM'},
  {code: '672', name: 'ANTARCTICA'},
  {code: '673', name: 'BRUNEI DARUSSALAM'},
  {code: '674', name: 'NAURU'},
  {code: '675', name: 'PAPUA NEW GUINEA'},
  {code: '676', name: 'TONGA'},
  {code: '677', name: 'SOLOMON ISLANDS'},
  {code: '678', name: 'VANUATU'},
  {code: '679', name: 'FIJI'},
  {code: '681', name: 'WALLIS AND FUTUNA'},
  {code: '682', name: 'COOK ISLANDS'},
  {code: '683', name: 'NIUE'},
  {code: '684', name: 'AMERICAN SAMOA'},
  {code: '685', name: 'WESTERN SAMOA'},
  {code: '686', name: 'KIRIBATI'},
  {code: '687', name: 'NEW CALEDONIA'},
  {code: '688', name: 'TUVALU'},
  {code: '689', name: 'FRENCH POLYNESIA'},
  {code: '690', name: 'AMERICAN SAMOA'},
  {code: '691', name: 'NORTHERN MARIANA ISLANDS'},
  {code: '692', name: 'GUAM'},
  {code: '693', name: 'PALAU'},
  {code: '694', name: 'MARSHALL ISLANDS'},
  {code: '695', name: 'MICRONESIA, FEDERATED STATES OF'},
  {code: '758', name: 'SAINT LUCIA'},
  {code: '767', name: 'DOMINICA'},
  {code: '784', name: 'SAINT VINCENT AND THE GRENADINES'},
  {code: '809', name: 'DOMINICAN REPUBLIC'},
  {code: '852', name: 'HONG KONG'},
  {code: '853', name: 'MACAO'},
  {code: '855', name: 'CAMBODIA'},
  {code: '856', name: 'LAO PEPS DEMOCRATIC REPUBLIC'},
  {code: '868', name: 'TRINIDAD AND TOBAGO'},
  {code: '869', name: 'SAINT KITTS AND NEVIS'},
  {code: '876', name: 'JAMAICA'},
  {code: '880', name: 'BANGLADESH'},
  {code: '886', name: 'TAIWAN, PROVINCE OF CHINA'},
  {code: '950', name: 'MYANMAR'},
  {code: '951', name: 'KOREA, REPUBLIC OF'},
  {code: '952', name: 'KOREA, DEMOCRATIC PEPS REPUBLIC OF'},
  {code: '961', name: 'LEBANON'},
  {code: '962', name: 'JORDAN'},
  {code: '963', name: 'SYRIAN ARAB REPUBLIC'},
  {code: '964', name: 'IRAQ'},
  {code: '965', name: 'KUWAIT'},
  {code: '966', name: 'SAUDI ARABIA'},
  {code: '967', name: 'YEMEN'},
  {code: '968', name: 'OMAN'},
  {code: '971', name: 'UNITED ARAB EMIRATES'},
  {code: '972', name: 'ISRAEL'},
  {code: '973', name: 'BAHRAIN'},
  {code: '974', name: 'QATAR'},
  {code: '975', name: 'BHUTAN'},
  {code: '977', name: 'NEPAL'},
  {code: '992', name: 'TAJIKISTAN'},
  {code: '993', name: 'TURKMENISTAN'},
  {code: '994', name: 'AZERBAIJAN'},
  {code: '995', name: 'GEORGIA'},
  {code: '998', name: 'UZBEKISTAN'},
  {code: '1001', name: 'ANGUILLA'},
  {code: '1002', name: 'PANAMA'},
  {code: '1003', name: 'COTE DIVOIRE'},
  {code: '1004', name: 'ERITREA'},
  {code: '1005', name: 'KAZAKHSTAN'},
  {code: '1006', name: 'KYRGYZSTAN'},
  {code: '1007', name: 'COCOS (KEELING) ISLANDS'},
  {code: '1008', name: 'MONGOLIA'},
  {code: '1009', name: 'BOUVET ISLAND'},
  {code: '1010', name: 'SEYCHELLES'},
  {code: '1011', name: 'BRITISH INDIAN OCEAN TERRITORY'},
  {code: '1012', name: 'COCOA ISLANDS'},
  {code: '1013', name: 'SAMOA'},
  {code: '1014', name: 'TIMOR-LESTE'},
  {code: '1015', name: 'FRENCH SOUTHERN TERRITORIES'},
  {code: '1016', name: 'HEARD ISLAND AND MCDONALD ISLANDS'},
  {code: '1017', name: 'MAYOTTE'},
  {code: '1018', name: 'PITCAIRN'},
  {code: '1019', name: 'SVALBARD AND JAN MAYEN'},
  {code: '1020', name: 'TOKELAU'},
  {code: '1021', name: 'UNITED STATES MINOR OUTLYING ISLANDS'},
  {code: '1022', name: 'WESTERN SAHARA'},
  {code: '1023', name: 'MALDIVES'},
  {code: '1024', name: 'CHRISTMAS ISLAND'},
  {code: '1025', name: 'NORFOLK ISLAND'},
  {code: '1026', name: 'CURACAO'},
  {code: '1027', name: 'CONGO'},
  {code: '1028', name: 'MONACO'},
  {code: '1029', name: 'HOLY SEE (VATICAN CITY STATE)'},
  {code: '1030', name: 'DIEGO GARCIA'},
  {code: '1031', name: 'SWAZILAND'},
  {code: '1032', name: 'PALESTINIAN TERRITORY OCCUPIED'},
]
