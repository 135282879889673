/* eslint-disable no-empty-function */
/* eslint-disable no-underscore-dangle */
import { IFormController, IHttpService, IIntervalService } from 'angular'
import { CrafterService } from '../../utils/crafter-service'
import { BOOTSTRAP_TYPES, REMOTE_STATUS_CODES, REMOTE_STATUS_TO_BOOTSTRAP_MAP } from '../../nba-upload/constants'
import { FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES } from '../../nba-upload'
import { ModalService } from '../../components/modal/modal-service'
import { UploadApplicationService } from '../../nba-upload/upload-application.service'

const UPLOAD_STATUS_POLLING_INTERVAL = 2500

interface SendResponse {
  message: string
  errorMessage?: string
}

export class ContactUsAttachmentsController {
  static $inject: any = ['$http', '$interval', '$scope', '$state', 'crafterService', 'modalService', 'uploadApplicationService']

  public content: string
  public successMessage: string
  public errorMessage: string
  public selectedContact: any = { email: '' }
  public emailContacts: any[] = []
  public validFileTypes: string[] = [
    'application/pdf',
    'image/tiff',
    'image/png',
    'image/jpeg',
  ]
  public maxFileSize: number = 20000000
  public maxAttachments: number = 10
  public maxUploadSize: number = 50000000

  private contactUsForm: IFormController

  private stopPolling: any
  private transactionId: string = ''
  private chosenFiles: any[] = []

  private uploadState: any
  private fileStats: any
  private appData: any

  constructor(private $http: IHttpService, private $interval: IIntervalService, private $scope: any, private $state: any, private crafterService: CrafterService, private modalService: ModalService, private uploadApplicationService: UploadApplicationService) {
    console.info('contact us with attachments controller entered')
  }

  // Begin code borrowed from NBA Upload
  get hasFiles(): boolean {
    return this.chosenFiles.length > 0
  }

  _resetUploadState(): void {
    this.uploadState = {
      progress: 0,
      status: '',
      type: BOOTSTRAP_TYPES.PRIMARY,
      active: true,
      errorCode: 0,
      errorMessage: '',
      remoteStatus: REMOTE_STATUS_CODES.IDLE,
      reason: '',
    }
  }

  _updateUploadState(message, progress, remoteState, active, error?): void {
    const type = REMOTE_STATUS_TO_BOOTSTRAP_MAP[remoteState]

    this.uploadState.progress = Math.round(progress) ?? 0
    this.uploadState.status = message
    this.uploadState.transactionId = this.transactionId
    this.uploadState.type = type
    this.uploadState.active = active
    this.uploadState.errorCode = error?.code ?? 0
    this.uploadState.messagePreamble = error?.messagePreamble
    this.uploadState.errorMessage = error?.message ?? ''
    this.uploadState.remoteStatus = remoteState
    this.uploadState.showModalButton = [REMOTE_STATUS_CODES.SENT, REMOTE_STATUS_CODES.FAILED].includes(remoteState)
  }


  browse(e): void {
    this.$scope.$root.$broadcast('show-file-select-dialog', e)
  }

  closeSuccessAlert(): any {
    this.uploadState.remoteStatus = REMOTE_STATUS_CODES.IDLE
  }

  _uploadFailed(e: any): any {
    console.log('_uploadFailed:', e)
    // const parameters = { reason: e.errorMessage }
    // const gaData = Object.assign({}, this.NBA_GA_EVENTS.DOCUMENT_UPLOAD_FAIL, { parameters, errorCode: e.errorCode })

    // this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)
  }
  _uploadSuccess(fileStats: any): any {
    console.log('_uploadSuccess:', fileStats)
    // const gaData = Object.assign({}, this.NBA_GA_EVENTS.DOCUMENT_UPLOAD_SUCCESS, { parameters: fileStats })

    // console.log('DOCUMENT_UPLOAD_SUCCESS', gaData)

    // this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)

  }

  _updateUploadStats(_appData: any, files: any): any {
    const numberOfFiles = files.length
    const totalSize = Math.ceil(files.reduce((acc, file) => {
      acc += file.size

      return acc
    }, 0) / 1024)

    const finalAnswer = { numberOfFiles, totalSize }

    return finalAnswer
  }

  upload(_e): any {
    const files = this.chosenFiles.map(f => f.file)
    const blobSelectedContact = new Blob([JSON.stringify(this.selectedContact)], {
      type: 'application/json',
    })
    this.appData = {
      to: blobSelectedContact,
      content: this.content,
    }

    console.log('Uploading', this.appData, files)
    this.$scope.$root.preventSpinnerFromShowing = true
    this._resetUploadState()
    this.fileStats = this._updateUploadStats(this.appData, files)
    this.modalService.open(FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES.CONTACT_US_PROGRESS, {
      remoteStatusCodes: REMOTE_STATUS_CODES,
      uploadState: this.uploadState, appData: this.appData, onClose: () => this.resetForm(),
    }, { size: 'lg', backdrop: 'static' })

    void this.uploadApplicationService.upload('/contactus', this.appData, files, (progressInfo) => {
      if (progressInfo.lengthComputable) {
        const progress = (progressInfo.loaded / progressInfo.total) * 100

        if (progress === 100) {
          this._updateUploadState('Message received.', 100, REMOTE_STATUS_CODES.SENDING, true)
        } else {
          this._updateUploadState(`Uploading... ${this.uploadState.progress}% complete`, progress, REMOTE_STATUS_CODES.RECEIVING, true)
        }
      }
    }).then(uploadInfo => this.pollUploadStatus(uploadInfo))
      .catch(e => {
        this._uploadFailed(e)
        this._updateUploadState('Application upload failed', 100, 'error', false, { code: e.errorCode, message: e.errorMessage })

      }).then(() => {
        this.$scope.$root.preventSpinnerFromShowing = false
      })
  }

  _checkStatus(uploadInfo: any): any {
    this.$scope.$root.preventSpinnerFromShowing = true

    return this.uploadApplicationService.poll('/contactus', uploadInfo.transaction)
      .then((uploadStatus) => {
        switch (uploadStatus.state) {
          case REMOTE_STATUS_CODES.SENT:
            this._stopStatusChecking()
            this.transactionId = uploadInfo.transaction
            this._uploadSuccess(this.fileStats)
            this._updateUploadState('Message sent successfully.', 100, uploadStatus.state, false)
            break

          case REMOTE_STATUS_CODES.FAILED:
            const confirmationFailed = uploadStatus.reason === 'nba-confirmation'
            const title = confirmationFailed ? 'Failed to deliver confirmation email.' : 'Failed to deliver message.'
            const messagePreamble = confirmationFailed ? `Your application has been received, but the confirmation email could not be delivered to ${this.selectedContact.email}.` : ''

            this.transactionId = uploadInfo.transaction
            this._uploadFailed(uploadStatus)
            this._updateUploadState(title, 100, uploadStatus.state, false, { messagePreamble, code: uploadStatus.errorCode, message: uploadStatus.errorMessage })
            this._stopStatusChecking()
            break

          case REMOTE_STATUS_CODES.IDLE:
            this._stopStatusChecking()
            break

          default:
            this._updateUploadState('Sending Message.', 100, uploadStatus.state, true)
        }

        return uploadStatus
      }).catch(err => {
        this._uploadFailed(err)
        this._updateUploadState('Unexpected Error.', 100, REMOTE_STATUS_CODES.FAILED, false, { code: err.errorCode, message: err.errorMessage })
        this._stopStatusChecking()

        return err

      })

  }

  _stopStatusChecking(): void {
    this.$scope.$root.preventSpinnerFromShowing = false
    if (this.stopPolling) {
      this.$interval.cancel(this.stopPolling)
      this.stopPolling = null
    }
  }

  pollUploadStatus(uploadInfo: any): void {
    console.log('uploadInfo', uploadInfo)

    if (!this.stopPolling && uploadInfo) {
      void this._checkStatus(uploadInfo)  // Check immediately if not already polling.
        .then((status) => {
          if (status.state !== REMOTE_STATUS_CODES.FAILED) this.stopPolling = this.$interval(() => this._checkStatus(uploadInfo), UPLOAD_STATUS_POLLING_INTERVAL)
        })
    }
  }

  // end code borrowed from

  /**
   * @deprecated
   *
   * @param content
   */
  public submit(content: string): void {
    console.info('submit entered, content is ', content)
    const url = `/contactus/`

    this.successMessage = ''

    this.$http.post<SendResponse>(url, { content: content, to: this.selectedContact })
      .then(response => this._sumbmitContactSuccess(response))
      .catch(response => this._sumbmitContactFailure(response))
  }

  private _sumbmitContactSuccess(response) {
    this.successMessage = response.data.message
    this.content = ''
    // this.chosenFiles = []
  }

  public closeAlerts(): void {
    this.resetForm()
    this.successMessage = ''
    this.errorMessage = ''
  }

  public close(): void {
    this.resetForm()
    this.$state.go('search.result')
  }

  private _sumbmitContactFailure(response) {
    this.errorMessage = response?.data?.error
  }

  public haveComments(): boolean {
    return this.content?.trim().length > 3
  }

  resetForm(): void {
    this.selectedContact = this.emailContacts[0]
    this.content = ''
    this.chosenFiles.splice(0, this.chosenFiles.length)
    this.contactUsForm?.$setPristine()
    this.contactUsForm?.$setUntouched()
    this.$scope.$broadcast('all-files-removed')
  }

  $onInit(): void {
    this.crafterService.getContactMetadata().then((result) => {
      this.emailContacts = result.sort((a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()

        if (nameA === 'Financial Professional'.toLowerCase()) {
          return -1
        }

        if (nameB === 'Financial Professional'.toLowerCase()) {
          return 1
        }

        return nameA.localeCompare(nameB)
      })

      this.resetForm()
    })
  }

  $doCheck(): void {
    if(!this.contactUsForm) {
      this.contactUsForm = this.$scope.contactUsForm
    }
  }
}

