import * as angular from 'angular'
import { authService } from './auth-service'
import { authHttpResponseInterceptor, configure, run } from './auth-module'
import { AuthModuleRunner } from './auth-module-runner'

angular.module('app.auth', [ 'app', 'app.core', 'app.utils', 'ngIdle' ])
  .service('authService', authService)
  .factory('authHttpResponseInterceptor', authHttpResponseInterceptor)
  .config(configure)
  // .run(run)
  .run(AuthModuleRunner)

