(function() {
  'use strict'

  angular.module('app.directives').directive('wgPolicyTransactionsHistoryTable', wgPolicyTransactionsHistoryTable)

  function wgPolicyTransactionsHistoryTable() {
    return {
      controller: wgPolicyTransactionsHistoryTableController,
      controllerAs: 'policyTransactionsHistoryTable',
      restrict: 'E',
      scope: {
        error: '=?errorMessage',
        policyId: '=?',
        showDetails: '=?',
        showFilters: '=?',
        showPrintIcon: '=?',
        type: '=?',
      },
      templateUrl: 'app/widgets/templates/wg-policy-transactions-history-table.html',
      transclude: true,
    }
  }

  wgPolicyTransactionsHistoryTableController.$inject = ['$rootScope', '$scope', '$stateParams', 'policyService', 'popupManager', '$window', '$timeout']

  function wgPolicyTransactionsHistoryTableController($rootScope, $scope, $stateParams, policyService, popupManager, $window, $timeout) {
    const self = this
    const filterPopupName = 'transactionHistoryFilter'
    let moreData = true
    let loadingMore = false
    let currentPage = 1
    const EMPTY_TABLE_STATES = {
      noResults: 'No data available',
    }

    self.financialActivity = []
    self.limitedFinancialActivity = []

    self.errorMessage = ''
    self.isThereError = false
    self.showPrintIcon = true

    function setError(error) {
      self.errorMessage = error
      self.isThereError = Boolean(self.errorMessage)
    }
    self.showPrintIcon = $scope.showPrintIcon === undefined ? true : $scope.showPrintIcon
    self.showDetails = $scope.showDetails
    self.showFilters = $scope.showFilters
    self.appliedFilters = 'None'

    self.policyId = $scope.policyId === undefined ? $stateParams.id : $scope.policyId

    self.emptyTableState = EMPTY_TABLE_STATES.noResults

    self.showEmptyTable = function() {
      return !loadingMore && self.financialActivity.length === 0
    }

    self.showEmptyState = function() {
      return !loadingMore
    }

    self.SCROLL_DISTANCE = 1

    self.showSmallSpinner = function() {
      return self.isLoadingMore() && !$rootScope.showingMainSpinner
    }

    self.isLoadingMore = function() {
      return loadingMore
    }

    self.isScrollDisabled = function() {
      return loadingMore || !moreData
    }

    self.nextPage = function(callback) {
      loadingMore = true

      if (moreData) {
        policyService.getPolicyTransactionHistory(self.policyId, currentPage++, $scope.type).then(function(result) {
          processResults(result, callback)
        })
      } else {
        loadingMore = false
      }
    }

    self.getLimitedPolicyTransactionHistoryAndPrint = function(limit) {
      policyService.getLimitedPolicyTransactionHistory(self.policyId, limit, $scope.type).then(function(result) {
        self.limitedFinancialActivity = result.transactionHistory.financialActivity
        $timeout(function() {
          $window.print()
        })
      })
    }

    self.applyFilter = function(filter) {
      if (filter !== self.appliedFilters) {
        self.appliedFilters = filter
        popupManager.close(filterPopupName)

        self.financialActivity = []
        currentPage = 1
        moreData = true
        $scope.type = filter === 'None' ? undefined : filter

        self.nextPage(function() {
          self.nextPage()
        })
      }
    }

    self.filterIsOpen = function() {
      return popupManager.isOpen(filterPopupName)
    }
    self.toggleFilterPopup = function() {
      return popupManager.toggle(filterPopupName)
    }

    function processResults(result, callback) {
      if (result.status === 0) {
        self.connError = result.error
      } else if (result.error) {
        moreData = false
      } else if (result.status === 404) {
        moreData = false
      } else if (result.transactionHistory.financialActivity === undefined || result.transactionHistory.financialActivity.length === 0) {
        moreData = false
      } else {
        self.financialActivity = self.financialActivity.concat(result.transactionHistory.financialActivity)
        self.numFound = result.transactionHistory.numFound

        if (self.financialActivity.length >= self.numFound) {
          moreData = false
        }
      }

      if (result.error && $scope.type === undefined) {
        setError(result.error)
      }

      if (callback) {
        callback()
      }

      loadingMore = false
    }

    self.isClient = function() {
      return $rootScope.auth.isAClient()
    }

    function init() {
      popupManager.register(filterPopupName)
      moreData = true
      currentPage = 1
      self.financialActivity = []
      self.nextPage()
    }

    init()
  }
})()
