import { stringUtils as StringUtils } from '../utils/string-utils'

export { appRun }
const stringUtils = StringUtils()

/* @ngInject */
function appRun(routerHelper, CONSTANTS) {
  routerHelper.configureStates(getStates(CONSTANTS))
}
appRun.$inject = ['routerHelper', 'CONSTANTS']

function setSummaryPageTitle($rootScope, source) {
  const pageTitle = source ? 'Policy Summary ' + stringUtils.capitalize(source) : 'Policy Summary'

  $rootScope.pageTitle = pageTitle
  $rootScope.mobileHeaderTitle = pageTitle
  $rootScope.state = 'policy-summary'
  $rootScope.isPopupVisible = false
}

function resolveIsCmsRider(coverageResult, cmsRiders) {
  const rider = coverageResult.data?.rider
  const isCmsRider = cmsRiders.includes(rider.riderCode)

  return isCmsRider
}

function getStates(CONSTANTS) {
  const coveragesConfig = {
    url: '/coverages',
    templateUrl: 'app/policy/policy-detail-coverages.html',
    controller: 'PolicyDetailCoveragesController',
    controllerAs: 'policyDetailCoverages',
    resolve: {
      // MP inherits from parent
      policyCoveragesResult: ['coveragesResult', (coveragesResult) => coveragesResult],
      policySummary: ['summaryResult', 'policyService', (summaryResult) => summaryResult], // MP inherits from parent
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Coverages ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Coverages'

      $rootScope.pageTitle = pageTitle
      $rootScope.isPopupVisible = true
      $rootScope.state = 'policy-detail-coverages'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const benefitsConfig = {
    url: '/benefits',
    templateUrl: 'app/policy/policy-detail-benefits.html',
    controller: 'PolicyDetailBenefitsController',
    controllerAs: 'policyDetailBenefitsController',
    resolve: {
      policyCoveragesResult: ['coveragesResult',
        function (coveragesResult) {
          console.log('coveragesResult', coveragesResult)
          return coveragesResult
        },
      ],
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Riders ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Riders'

      $rootScope.pageTitle = pageTitle
      $rootScope.state = 'policy-detail-benefits'
      $rootScope.isPopupVisible = true
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const futureAllocationsConfig = {
    url: '/future-allocations',
    templateUrl: 'app/policy/future-allocations.html',
    controller: 'FutureAllocationsController',
    controllerAs: 'controller',
    resolve: {
      availableFunds: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService.getAvailablePolicyFunds($stateParams.id)
      }],
      canAllocate: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService
          .getPolicyCanAllocateFunds($stateParams.id)
          .catch(error => {
            if (error.data && error.data.reason && error.data.reason.value) {
              return error
            }

            return angular.extend(error, {
              data: {
                isTransactable: false,
                reason: {
                  value: 'Trouble determining whether transactions can be made.',
                },
              },
            })
          })
      }],

      canRebalance: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
        return fundTransferService
          .getCanRebalanceFunds($stateParams.id)
          .catch(error => {
            if (error.data && error.data.reason && error.data.reason.value) {
              return error
            }

            return angular.extend(error, {
              data: {
                isTransactable: false,
                reason: {
                  value: 'Trouble determining whether a rebalance can be performed.',
                },
              },
            })
          })
      }],

      currentFunds: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService.getPolicyFunds($stateParams.id)
      }],

      aarStatus: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService.getPolicyArrangements($stateParams.id).then(function (arrangement) {
          return arrangement.data && arrangement.data.newArrangement && arrangement.data.newArrangement.annuityAAR
        })
      }],
    },
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Policy Funds'
      $rootScope.state = 'future-allocations'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const fundRebalanceConfig = {
    url: '/fund-rebalance',
    templateUrl: 'app/policy/fund-rebalance.html',
    controller: 'FundRebalanceController',
    controllerAs: 'controller',
    resolve: {
      availableFunds: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
        return fundTransferService.getDestinationRebalanceFunds($stateParams.id)
      }],
      canAllocate: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
        return fundTransferService
          .getCanTransferFunds($stateParams.id)
          .catch(error => {
            if (error.data && error.data.reason && error.data.reason.value) {
              return error
            }

            return angular.extend(error, {
              data: {
                isTransactable: false,
                reason: {
                  value: 'Trouble determining whether transactions can be made.',
                },
              },
            })
          })
      }],
      canRebalance: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
        return fundTransferService
          .getCanRebalanceFunds($stateParams.id)
          .catch(error => {
            if (error.data && error.data.reason && error.data.reason.value) {
              return error
            }

            return angular.extend(error, {
              data: {
                isTransactable: false,
                reason: {
                  value: 'Trouble determining whether a rebalance can be performed.',
                },
              },
            })
          })
      }],
      currentFunds: ['$stateParams', 'fundTransferService', function ($stateParams, fundsTransferService) {
        return fundsTransferService.getSourceFunds($stateParams.id)
      }],
      aarStatus: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService.getPolicyArrangements($stateParams.id).then(function (arrangement) {
          return arrangement.data && arrangement.data.newArrangement && arrangement.data.newArrangement.annuityAAR
        })
      }],
      fixedFunds: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService.getPolicyFunds($stateParams.id).then(function (result) {
          return result.data.subAccount.filter(fund => {
            if (fund.assetClass && fund.assetClass.value === 'Fixed') {
              return fund
            }
          })
        })
      }],
      summaryResult: ['$stateParams', 'policyService',
        function ($stateParams, policyService) {
          return policyService.getPolicySummary($stateParams.id, $stateParams.policySource)
        },
      ],
    },
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Policy Funds'
      $rootScope.state = 'future-allocations'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const fundTransferConfig = {
    url: '/transfer',
    templateUrl: 'app/policy/funds-transfer.html',
    controller: 'FundsTransferController',
    controllerAs: 'ctrl',
    resolve: {
      destinationFunds: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
        return fundTransferService.getDestinationFunds($stateParams.id)
      }],
      currentFunds: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
        return fundTransferService.getSourceFunds($stateParams.id)
      }],
    },
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Policy Funds Transfer'
      $rootScope.state = 'policy-funds-transfer'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const valuesConfig = {
    url: '/values',
    // component: 'policyDetailValues',
    templateUrl: 'app/policy/values/policy-detail-values.html',
    controller: 'PolicyDetailValuesController',
    controllerAs: 'policyDetailValues',
    // bindings: {
    //   policyBaseResult: '<',
    // },
    resolve: {
      policyBaseResult: ['baseResult', (baseResult) => baseResult],
      policyFundsResult: ['$stateParams', 'policyService',
        ($stateParams, policyService) => policyService.getPolicyFunds($stateParams.id),
      ],
      policyValuesResult: ['$stateParams', 'policyService', ($stateParams, policyService) => policyService.getPolicyValues($stateParams.id)],
      summaryResult: ['summaryResult', angular.identity],
      policyId: ['$stateParams', (stateParams) => stateParams.id],
      maxLoanQuotes: ['$stateParams', 'policyService', 'summaryResult', function ($stateParams, policyService, summaryResult) {
        if (!summaryResult.error && !summaryResult.policy.isAnnuity && !summaryResult.policy.isPending) {
          return policyService.getPolicyLoanQuotes($stateParams.id)
        }
        return {
          'data': {},
        }
      }],
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Values ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Values'

      $rootScope.pageTitle = pageTitle
      $rootScope.isPopupVisible = true
      $rootScope.state = 'policy-detail-values'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const correspondenceConfig = {
    url: '/correspondence',
    templateUrl: 'app/correspondence/correspondence-view.html',
    controller: 'CorrespondenceController',
    controllerAs: 'correspondenceCtrl',
    resolve: {
      pids: ['$stateParams',
        ($stateParams) => new Array($stateParams.id),
      ],
      correspondenceResult: ['pids', 'correspondenceService', (pids, correspondenceService) => correspondenceService.getCorrespondences(pids)],
      taxDocuments: ['pids', 'policyService', (pids, policyService) => policyService.policyTaxDocumentSeach(pids[0])],
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Correspondence ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Correspondence'

      $rootScope.pageTitle = pageTitle
      $rootScope.state = 'correspondence'
      $rootScope.isPopupVisible = true
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const informationConfig = {
    url: '/information',
    templateUrl: 'app/policy/policy-detail-information.html',
    controller: 'PolicyDetailInformationController',
    controllerAs: 'infoCtrl',
    resolve: {
      taxInfo: ['taxInfo',
        function (taxInfo) {
          return taxInfo
        },
      ],
      policyCoveragesResult: ['coveragesResult', (coveragesResult) => coveragesResult],
    },
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Policy Information'
      $rootScope.isPopupVisible = true
      $rootScope.state = 'policy-detail-information'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const paymentsConfig = {
    url: '/payments',
    templateUrl: 'app/policy/policy-detail-purchase-payments.html',
    controller: 'PolicyDetailPaymentsController',
    controllerAs: 'policyDetailPayments',
    resolve: {
      policyBaseResult: ['baseResult', (baseResult) => baseResult], // duh its just a rename of something parent state 'policy' has
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Premiums ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Premiums'

      $rootScope.pageTitle = pageTitle
      $rootScope.isPopupVisible = true
      $rootScope.state = 'policy-detail-payments'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const premiumAndBillingConfig = {
    url: '/premiumAndBilling',
    templateUrl: 'app/policy/policy-detail-premium-billing.html',
    controller: 'PolicyDetailPaymentsController',
    controllerAs: 'policyDetailPayments',
  }

  const transactionsHistoryConfig = {
    url: '/transaction-history',
    templateUrl: 'app/policy/policy-transactions-history.html',
    controller: 'PolicyTransactionsHistoryController',
    controllerAs: 'PolicyTransactionsHistory',
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Transaction History'
      $rootScope.isPopupVisible = true
      $rootScope.state = 'policy-transactions-history'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const financialProfessionalsConfig = {
    url: '/financial-pros',
    templateUrl: 'app/policy/policy-detail-financial-pro.html',
    controller: 'PolicyDetailAdvisorController',
    controllerAs: 'policyDetailAdvisor',
    resolve: {
      policyAgentsResult: ['myAgents', (myAgents) => myAgents && myAgents.data.parties ? myAgents.data.parties : { error: 'No parties here"' }],
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Financial Professional ' + stringUtils.capitalize($stateParams.policySource) : 'Financial Professional'

      console.log('attempting to enter state')
      $rootScope.pageTitle = pageTitle
      $rootScope.isPopupVisible = true
      $rootScope.state = 'policy-detail-financial-professional'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const alertsConfig = {
    url: '/alerts',
    templateUrl: 'app/policy/alert-by-policy.html',
    controller: 'AlertByPolicyController',
    controllerAs: 'alertByPolicyCtrl',
    data: {
      secure: true,
    },
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Policy Alert List'
      $rootScope.mobileHeaderTitle = 'Policy Alert List'
      $rootScope.state = 'policy-alerts'
      $rootScope.isPopupVisible = false
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      $rootScope.isPopupVisible = false
      $rootScope.state = 'policy-summary'
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const alertsDetailConfig = {
    url: '/detail/{aid}',
    templateUrl: 'app/policy/alert-by-policy-detail-view.html',
    resolve: {
      alertDetailsResult: ['alertService', '$stateParams',
        function (alertService, $stateParams) {
          return alertService.getAlertDetails($stateParams.aid)
        },
      ],
    },
    controller: ['$scope', 'alertDetailsResult',
      function ($scope, alertDetailsResult) {
        $scope.alertDetailsResult = alertDetailsResult
      },
    ],
    onEnter: ['$rootScope', function ($rootScope) {
      $rootScope.pageTitle = 'Policy Alert Detail'
      $rootScope.mobileHeaderTitle = 'Policy Alert Detail'
      $rootScope.isPopupVisible = true
      $rootScope.state = 'alert-by-policy-detail'
    }],
    onExit: ['$rootScope', function ($rootScope) {
      $rootScope.isPopupVisible = false
      $rootScope.pageTitle = 'Policy Alert List'
      $rootScope.mobileHeaderTitle = 'Policy Alert List'
      $rootScope.state = 'policy-alerts'
    }],
  }

  const requirementsConfig = {
    url: '/requirements',
    templateUrl: 'app/policy/policy-requirements.html',
    controller: 'PolicyRequirementsController',
    controllerAs: 'requirementsCtrl',
    resolve: {
      policyId: ['$stateParams', stateParams => stateParams.id],
      // canViewReplies: ['requirementsHelperService', (requirementsHelperService) => requirementsHelperService.notNeuteredUser()],
      canSubmitToResult: ['policyService', 'policyId', function (policyService, policyId) {
        return policyService.canSubmitAgainstRequirements(policyId).then(elements => elements)
      }],
      policyRequirementsResult: ['$stateParams', 'policyService', 'authService',
        function ($stateParams, policyService, authService) {
          if (authService.isAClient()) {
            return
          }
          const policySource = $stateParams.policySource

          if (policySource.toLocaleLowerCase() === CONSTANTS.policySource.pending) {
            return policyService.getPendingPolicyRequirements($stateParams.id)
          }
          return policyService.getInforcePolicyRequirements($stateParams.id)
        },
      ],
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Requirements ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Requirements'

      $rootScope.pageTitle = pageTitle
      $rootScope.isPopupVisible = false
      $rootScope.state = 'policy-requirements'
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const ridersConfig = {
    url: '/riders',
    templateUrl: 'app/policy/policy-detail-riders.html',
    controller: 'PolicyDetailRidersController',
    controllerAs: 'ridersDetail',
    resolve: {
      tableResultLifetime: ['$stateParams', 'policyService', 'coveragesResult', 'riderBlacklists',
        function ($stateParams, policyService, coveragesResult, riderBlacklists) {
          const lifetimeTable = coveragesResult.data?.tables.find((table) => table.tableName.toLowerCase().startsWith('lifetime'))

          return lifetimeTable ? [lifetimeTable] : []
        },
      ],
      tableResultStandard: ['$stateParams', 'policyService', 'coveragesResult', 'riderBlacklists',
        function ($stateParams, policyService, coveragesResult, riderBlacklists) {
          const standardTable = coveragesResult.data?.tables.find((table) => table.tableName.toLowerCase().startsWith('standard'))

          return standardTable ? [standardTable] : []
        },
      ],
    },
    onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      const pageTitle = $stateParams.policySource ? 'Policy Riders ' + stringUtils.capitalize($stateParams.policySource) : 'Policy Riders'

      $rootScope.pageTitle = pageTitle
      $rootScope.state = 'policy-detail-riders'
      $rootScope.isPopupVisible = true
    }],
    onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
      setSummaryPageTitle($rootScope, $stateParams.policySource)
    }],
  }

  const addressesConfig = {
    url: '/myclients/addresses/:role',
    templateUrl: 'app/policy/client/address-modal.html',
    data: {
      secure: true,
    },
    resolve: {
      clients: ['$stateParams', 'policyService', function ($stateParams, policyService) {
        return policyService.getClients($stateParams.id)
      }],
    },
    controller: ['clients', '$stateParams', function (clients, $stateParams) {
      let client, i, j, roleObj

      this.role = $stateParams.role
      this.clients = []

      for (i in clients.data.parties) {
        client = clients.data.parties[i]

        for (j in client.roles) {
          roleObj = client.roles[j]

          if (roleObj && roleObj.role && roleObj.role.indexOf(this.role) >= 0) {
            this.clients.push(client)
          }
        }
      }

      this.clientToSingleLineAddress = function (c) {
        let a

        c.address = c.address || []
        a = c.address[0]

        if (!a) {
          return 'Not provided'
        }

        return a.line1 + (a.line2 ? ' ' : ', ') +
          (a.line2 ? (a.line2 + ', ') : '') +
          a.city + ', ' +
          a.state + ' ' +
          a.zip
      }
    }],
    controllerAs: 'addressModal',
  }

  const clientModal = {
    url: '/client-modal/:role/:eligibility',
    templateUrl: 'app/policy/client-modal.html',
    data: {
      secure: true,
    },
    resolve: {
      clients: ['clientResults', function (clientResults) {
        return clientResults
      }],
      policySummary: ['summaryResult', (summaryResult) => summaryResult.policy],
    },
    params: {
      eligibility: null,
    },
    controller: 'ClientModalController',
    controllerAs: 'clientModal',
  }

  const policyPrint = {
    url: '/policy-print/:printView',
    templateUrl: 'app/policy/policy-summary-print-layout.html',
    controller: 'PolicyPrintController',
    controllerAs: 'policyPrint',
    data: {
      secure: true,
    },
    resolve: {
      currentFunds: ['$stateParams', 'policyService', 'summaryResult', function ($stateParams, policyService, summaryResult) {
        if (!summaryResult.error && !summaryResult.policy.isPending) {
          return policyService.getPolicyFunds($stateParams.id)
        }
        return {
          'data': {},
        }
      }],
      maxLoanQuotes: ['$stateParams', 'policyService', 'summaryResult', function ($stateParams, policyService, summaryResult) {
        if (!summaryResult.error && !summaryResult.policy.isAnnuity && !summaryResult.policy.isPending) {
          return policyService.getPolicyLoanQuotes($stateParams.id)
        }
        return {
          'data': {},
        }
      }],
      tableResultLifetime: ['coveragesResult', (coveragesResult) => {
        const lifetimeTable = coveragesResult.data?.tables.find((table) => table.tableName.toLowerCase().startsWith('lifetime'))

        return lifetimeTable ? [lifetimeTable] : []
      }],
      tableResultStandard: ['coveragesResult', (coveragesResult) => {
        const standardTable = coveragesResult.data?.tables.find((table) => table.tableName.toLowerCase().startsWith('standard'))

        return standardTable ? [standardTable] : []
      }],
      policyValuesResult: ['$stateParams', 'policyService', ($stateParams, policyService) => policyService.getPolicyValues($stateParams.id)],
      /*    clientResults: ['$stateParams', 'policyService',
            function($stateParams, policyService) {
              return policyService.getClients($stateParams.id)
            },
          ], inherited from policy. */
    },
  }

  return [{
    state: 'policy',
    config: {
      url: '/myclients/policy/:policySource/:id',
      templateUrl: 'app/policy/advisor/policy-summary-layout.html',
      controller: 'PolicySummaryController',
      controllerAs: 'summaryCtrl',
      // abstract: true,
      params: {
        policySource: CONSTANTS.policySource.inForce,
      },
      data: {
        secure: true,
      },
      resolve: {
        policyId: ['$stateParams', (stateParams) => stateParams.id],
        paymentDetails: ['policyId', 'policyService', (policyId, policyService) => {
          return policyService.getPolicyPaymentDetails(policyId, false).then(function (r) {
            if (r.error) {
              r.payments = []
              r.paymentMode = {}
              r.paymentMethod = {}
              return r
            }

            return r.policyPaymentDetails
          })
        }],
        canAllocate: ['policyId', 'policyService', function (policyId, policyService) {
          return policyService
            .getPolicyCanAllocateFunds(policyId)
            .catch(error => {
              if (error.data && error.data.reason && error.data.reason.value) {
                return error
              }

              return angular.extend(error, {
                data: {
                  isTransactable: false,
                  reason: {
                    value: 'Trouble determining whether transactions can be made.',
                  },
                },
              })
            })
        }],
        canRebalanceResult: ['policyId', 'fundTransferService', function (policyId, fundTransferService) {
          return fundTransferService.getCanRebalanceFunds(policyId)
            .catch(error => {
              if (error.data && error.data.reason && error.data.reason.value) {
                throw error
              }

              return angular.extend(error, {
                data: {
                  isTransactable: false,
                  reason: {
                    value: 'Trouble determining whether a rebalance can be performed.',
                  },
                },
              })
            })
        }],
        summaryResult: ['$stateParams', 'policyService', 'authService',
          function ($stateParams, policyService, authService) {
            const enforceRestrictCodes = !authService.isInRole('HOMEOFFICE')

            return policyService.getPolicySummary($stateParams.id, $stateParams.policySource, enforceRestrictCodes)
          },
        ],
        coveragesResult: ['$stateParams', 'policyService', 'summaryResult',
          function ($stateParams, policyService, summaryResult) {
            if (summaryResult.policy.source !== 'rps') {
              return policyService.getPolicyCoverages($stateParams.id, $stateParams.policySource)
            }

            return {
              'error': '',
              'status': '',
              'statusText': '',
              'policy': {},
            }
          },
        ],
        policyApplicationResult: ['$q', '$stateParams', 'policyService',
          function ($q, $stateParams, policyService) {
            if ($stateParams.policySource === 'pending') {
              return policyService.getPendingPolicyApplication($stateParams.id)
            }
            return $q.when({
              'policyApplication': {},
            })
          },
        ],
        policyTransactionHistoryResult: ['policyId', 'policyService', (policyId, policyService) => policyService.getPolicyTransactionHistory(policyId)],
        policyTransactionsHistorySummaryResult: ['policyId', 'policyService', (policyId, policyService) => policyService.getPolicyTransactionsHistorySummary(policyId)],
        myAgents: ['policyId', 'policyService', (policyId, policyService) => policyService.getPolicyAgents(policyId)],
        policyCanPayResult: ['policyId', 'policyService', (policyId, policyService) => policyService.getPolicyCanPay(policyId)],
        canTransferResult: ['policyId', 'policyService', (policyId, policyService) => policyService.getPolicyCanTransferFunds(policyId)],
        clientResults: ['policyId', 'policyService', (policyId, policyService) => policyService.getClients(policyId)],
        policyValuesResult: ['$stateParams', 'policyService', ($stateParams, policyService) => policyService.getPolicyValues($stateParams.id)],
        loanPayoffQuote: ['policyId', 'paymentsDataProvider', 'policyCanPayResult', 'policyUtils', function (policyId, paymentsDataProvider, policyCanPayResult, policyUtils) {
          if (policyUtils.loanIsPayable(policyCanPayResult.data)) {
            return paymentsDataProvider.getLoanPayoffQuote(policyId)
          }
        }],
        taxInfo: ['policyId', 'policyService', 'summaryResult',
          function (policyId, policyService, summaryResult) {
            if (!summaryResult.error && !summaryResult.policy.isAnnuity && !summaryResult.policy.isPending) {
              return policyService.getTaxInfo(policyId)
            }
          },
        ],
        loans: ['policyId', 'policyService', (policyId, policyService) => policyService.getPolicyLoans(policyId)],
        baseResult: ['policyId', 'policyService',
          function (policyId, policyService) {
            return policyService.getPolicyBase(policyId)
          }, // MP just used by child routes?
        ],
        riderBlacklists: ['policyService',
          (policyService) => {
            return policyService.getRiderBlacklists()
          },
        ],
        beneficiaryMetadata: ['crafterService', (crafterService) => crafterService.getBeneficiaryMetadata()]

      },
      onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
        setSummaryPageTitle($rootScope, $stateParams.policySource)
        $rootScope.showAsOfDate = true
      }],
      onExit: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
        setSummaryPageTitle($rootScope, $stateParams.policySource)
        $rootScope.showAsOfDate = false
      }],
    },
  },
  {
    state: 'myPolicy',
    config: { // @TODO: add support for pending policies for clients
      url: '/my-policy/i/:id', // 'i' for in-force; will use 'p' for pending when we add that support
      templateUrl: 'app/policy/advisor/policy-summary-layout.html',
      controller: 'PolicySummaryController',
      controllerAs: 'summaryCtrl',
      clientAppOnly: true,
      params: { 
        policySource: CONSTANTS.policySource.inForce,
        openPayment: false,
      },
      data: {
        secure: true,
      },
      resolve: {
        paymentDetails: ['$stateParams', 'policyService',
          function ($stateParams, policyService) {
            const id = $stateParams.id

            return policyService.getPolicyPaymentDetails(id, false).then(function (r) {
              if (r.error) {
                r.payments = []
                r.paymentMode = {}
                r.paymentMethod = {}
                return r
              }

              return r.policyPaymentDetails
            })
          }],
        coveragesResult: ['$stateParams', 'policyService',
          function ($stateParams, policyService) {
            return policyService.getPolicyCoverages($stateParams.id, $stateParams.policySource)
          },
        ],
        summaryResult: ['$stateParams', 'policyService',
          function ($stateParams, policyService) {
            return policyService.getPolicySummary($stateParams.id, $stateParams.policySource)
          },
        ],
        policyApplicationResult: ['$q', '$stateParams', 'policyService',
          function ($q, $stateParams, policyService) {
            if ($stateParams.policySource === 'pending') {
              return policyService.getPendingPolicyApplication($stateParams.id)
            }
            return $q.when({
              'policyApplication': {},
            })
          },
        ],
        policyTransactionHistoryResult: ['$q', '$stateParams', 'policyService',
          function ($q, $stateParams, policyService) {
            return policyService.getPolicyTransactionHistory($stateParams.id)
          },
        ],
        policyTransactionsHistorySummaryResult: ['$q', '$stateParams', 'policyService',
          function ($q, $stateParams, policyService) {
            return policyService.getPolicyTransactionsHistorySummary($stateParams.id)
          },
        ],
        myAgents: ['$stateParams', 'policyService',
          function ($stateParams, policyService) {
            return policyService.getPolicyAgents($stateParams.id)
          },
        ],
        policyCanPayResult: ['$stateParams', 'policyService',
          function ($stateParams, policyService) {
            return policyService.getPolicyCanPay($stateParams.id)
          },
        ],
        clientResults: ['$stateParams', 'policyService', function ($stateParams, policyService) {
          return policyService.getClients($stateParams.id)
        }],
        taxInfo: ['$stateParams', 'policyService', 'summaryResult',
          function ($stateParams, policyService, summaryResult) {
            if (!summaryResult.policy.isAnnuity && !summaryResult.policy.isPending) {
              return policyService.getTaxInfo($stateParams.id)
            }

            return {}
          },
        ],
        canTransferResult: ['$stateParams', 'policyService', function ($stateParams, fundTransferService) {
          return fundTransferService.getPolicyCanTransferFunds($stateParams.id)
        }],
        loans: ['$stateParams', 'policyService', function ($stateParams, policyService) {
          return policyService.getPolicyLoans($stateParams.id)
        }],
        canAllocate: ['$stateParams', 'policyService', function ($stateParams, policyService) {
          return policyService
            .getPolicyCanAllocateFunds($stateParams.id)
            .catch(error => {
              if (error.data && error.data.reason && error.data.reason.value) {
                return error
              }

              return angular.extend(error, {
                data: {
                  isTransactable: false,
                  reason: {
                    value: 'Trouble determining whether transactions can be made.',
                  },
                },
              })
            })
        }],
        loanPayoffQuote: ['$stateParams', 'paymentsDataProvider', 'policyCanPayResult', 'policyUtils', function ($stateParams, paymentsDataProvider, policyCanPayResult, policyUtils) {
          if (policyUtils.loanIsPayable(policyCanPayResult.data)) {
            return paymentsDataProvider.getLoanPayoffQuote($stateParams.id)
          }
        }],
        policyValuesResult: ['$stateParams', 'policyService', ($stateParams, policyService) => policyService.getPolicyValues($stateParams.id)],
        canRebalanceResult: ['$stateParams', 'fundTransferService', function ($stateParams, fundTransferService) {
          return fundTransferService.getCanRebalanceFunds($stateParams.id)
            .catch(error => {
              if (error.data && error.data.reason && error.data.reason.value) {
                throw error
              }

              return angular.extend(error, {
                data: {
                  isTransactable: false,
                  reason: {
                    value: 'Trouble determining whether a rebalance can be performed.',
                  },
                },
              })
            })
        }],
        baseResult: ['$stateParams', 'policyService',
          function ($stateParams, policyService) {
            return policyService.getPolicyBase($stateParams.id)
          },
        ],
        whoAmI: ['clientMeService', '$rootScope',
          function (clientMeService, $rootScope) {
            if ($rootScope.serverMode === 'client') {
              return clientMeService.getWhoAmI()
            }

            return
          }
        ],
        riderBlacklists: ['policyService',
          (policyService) => {
            return policyService.getRiderBlacklists()
          },
        ],
        beneficiaryMetadata: ['crafterService', (crafterService) => crafterService.getBeneficiaryMetadata()],
      },
      onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
        setSummaryPageTitle($rootScope, $stateParams.policySource)
        $rootScope.showAsOfDate = true
      }],
      onExit: ['$rootScope', function ($rootScope) {
        $rootScope.showAsOfDate = false
      }],
    },
  },
  // {
  //   state: 'policy.beneficiaries',
  //   config: beneficiariesConfig, // looking at
  // },
  // {
  //   state: 'myPolicy.beneficiaries',
  //   config: angular.merge({}, beneficiariesConfig, {clientAppOnly: true}),
  // },
  {
    state: 'policy.coverages',
    config: coveragesConfig,
  },
  {
    state: 'myPolicy.coverages',
    config: angular.merge({}, coveragesConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.information',
    config: informationConfig,
  },
  {
    state: 'policy.benefits',
    config: benefitsConfig,
  },
  {
    state: 'myPolicy.myBenefits',
    config: angular.merge({}, benefitsConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.funds',
    config: futureAllocationsConfig,
  },
  {
    state: 'myPolicy.funds',
    config: angular.merge({}, futureAllocationsConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.rebalance',
    config: fundRebalanceConfig,
  },
  {
    state: 'myPolicy.rebalance',
    config: angular.merge({}, fundRebalanceConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.transfer',
    config: fundTransferConfig,
  },
  {
    state: 'myPolicy.transfer',
    config: angular.merge({}, fundTransferConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.values',
    config: valuesConfig,
  },
  {
    state: 'myPolicy.values',
    config: angular.merge({}, valuesConfig, { clientAppOnly: true }),
  },
  // {
  //   state: 'policy.roles',
  //   config: rolesConfig,
  // },
  // {
  //   state: 'myPolicy.roles',
  //   config: angular.merge({}, rolesConfig, {clientAppOnly: true}),
  // },
  {
    state: 'policy.correspondence',
    config: correspondenceConfig,
  },
  {
    state: 'myPolicy.correspondence',
    config: angular.merge({}, correspondenceConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.payments',
    config: paymentsConfig,
  },
  {
    state: 'myPolicy.payments',
    config: angular.merge({}, paymentsConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.premiumAndBilling',
    config: premiumAndBillingConfig,
  },
  {
    state: 'myPolicy.premiumAndBilling',
    config: angular.merge({}, premiumAndBillingConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.transactions',
    config: transactionsHistoryConfig,
  },
  {
    state: 'myPolicy.transactions',
    config: angular.merge({}, transactionsHistoryConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.financialPros',
    config: financialProfessionalsConfig,
  },
  {
    state: 'myPolicy.financialPros',
    config: angular.merge({}, financialProfessionalsConfig, { clientAppOnly: true }),
  },
  {
    state: 'myPolicy.addresses',
    config: angular.merge({}, addressesConfig, { clientAppOnly: true }),
  },
  {
    state: 'policy.clientModal',
    config: clientModal,
  },
  {
    state: 'myPolicy.clientModal',
    config: angular.merge({}, clientModal, { clientAppOnly: true }),
  },
  {
    state: 'policy.policyPrint',
    config: policyPrint,
  },
  {
    state: 'myPolicy.policyPrint',
    config: angular.merge({}, policyPrint, { clientAppOnly: true }),
  },
  {
    state: 'policy.alerts',
    config: alertsConfig,
  },
  {
    state: 'policy.alerts.detail',
    config: alertsDetailConfig,
  },
  {
    state: 'policy.requirements',
    config: requirementsConfig,
  },
  {
    state: 'policy.riders',
    config: ridersConfig,
  },
  {
    state: 'myPolicy.riders',
    config: angular.merge({}, ridersConfig, { clientAppOnly: true }),
  },
  ]
}
