import { IDirective, IDirectiveFactory } from "angular"
import { GoogleAnalyticsService } from "./google-analytics.service"

/**
 * Tracks click events that pass event names and action names to Google Tag Mangager
 */
export class GaTrackerDirective implements IDirective {

  restrict = 'A'
  replace = false

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  /**
   * Creates an instance of GaTrackerDirective, with dependencies injected.
   */
  static factory(): IDirectiveFactory {

    const directive = (googleAnalyticsService: GoogleAnalyticsService) => new GaTrackerDirective(googleAnalyticsService)

    directive.$inject = ['googleAnalyticsService']

    return directive
  }

  /**
   * AngularJS post link function use for initial configuration of instances of GaTrackerDirective
   */
  link(_scope, el, attrs) {
    el.on('click', () => this.googleAnalyticsService.send(attrs.gtmClickTrackEvent, attrs.gtmClickTrackAction))
  }

}
