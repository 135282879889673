export {policyDetailRidersModels}

policyDetailRidersModels.$inject = ['CONSTANTS', '$filter', 'dateUtils']

/* @ngInject */
function policyDetailRidersModels(CONSTANTS, $filter, dateUtils) {
  const CLIENT_ROLE_TYPE_CODES = {
    [CONSTANTS.CLIENT_ROLES.ANNUITANT]: '35',
    [CONSTANTS.CLIENT_ROLES.JOINT_ANNUITANT]: '183',
    [CONSTANTS.CLIENT_ROLES.CONTINGENT_ANNUITANT]: '153',
    [CONSTANTS.CLIENT_ROLES.OWNER]: '8',
    [CONSTANTS.CLIENT_ROLES.PAYER]: '31',

  }
  const today = new Date()
  let annuitant = {}
  let jointAnnuitant = {}
  let has

  has = {
    // General Section
    quotedValuesAsOf: function(section, ep) {
      section.addField(CONSTANTS.RIDER.QUOTED_VALUES_AS_OF, ep.base.asOfDate)
    },
    benefitEffectiveDate: function(section, ep) {
      section.addField(CONSTANTS.RIDER.BENEFIT_EFFECTIVE_DATE, ep.coverage.effDate)
    },
    coverageChangeDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.COVERAGE_CHANGE_DATE,
          ep.coverage.jointToSingleChangeDate)
      }
    },
    investmentStatus: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.INVESTMENT_STATUS,
          ep.coverage.investStatus)
      }
    },
    effectiveBenefitPhase: function(section, ep) {
      section.addField(CONSTANTS.RIDER.EFFECTIVE_BENEFIT_PHASE, ep.coverage.status)
    },
    limitedVariable: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LIMITED_VARIABLE, ep.coverage.investStatus)
      }
    },
    issueState: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.ISSUE_STATE, ep.summary.jurisdiction)
      }
    },
    singleJointLifeGuarantee: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.SINGLE_JOINT_LIFE_GUARANTEE, ep.coverage.covOption[0].livesType)
      }
    },
    coveredLife: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.COVERED_LIFE, annuitant.fullName, 'nameCase')
      }
    },
    coveredLifeStatus: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.COVERED_LIFE_STATUS, annuitant.participantStatus)
      }
    },
    coverdLifeDateOfDeath: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.COVERED_LIFE_DATE_OF_DEATH, annuitant.estMortalityDate)
      }
    },
    coveredLifeIssueAge: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.COVERED_LIFE_ISSUE_AGE, annuitant.issueAge)
      }
    },
    coveredLifeCurrentAge: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.COVERED_LIFE_CURRENT_AGE, annuitant.birthDate ? dateUtils.extendedAgeTo(annuitant.birthDate, today) : undefined)
      }
    },
    jointCoveredLife: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (ep.coverage.covOption[0].livesType && ep.coverage.covOption[0].livesType.slice(0, 5) === 'Joint') {
          section.addField(CONSTANTS.RIDER.JOINT_COVERED_LIFE, jointAnnuitant.fullName, 'nameCase')
        }
      }
    },
    jointCoveredLifeStatus: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (ep.coverage.covOption[0].livesType && ep.coverage.covOption[0].livesType.slice(0, 5) === 'Joint') {
          section.addField(CONSTANTS.RIDER.JOINT_COVERED_LIFE_STATUS, jointAnnuitant.participantStatus)
        }
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.JOINT_COVERED_LIFE_STATUS, jointAnnuitant.participantStatus)
      }
    },
    jointCoveredLifeDateOfDeath: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (ep.coverage.covOption[0].livesType && ep.coverage.covOption[0].livesType.slice(0, 5) === 'Joint') {
          section.addField(CONSTANTS.RIDER.JOINT_COVERED_LIFE_DATE_OF_DEATH, jointAnnuitant.estMortalityDate)
        }
      }
    },
    jointCoveredLifeIssueAge: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (ep.coverage.covOption[0].livesType && ep.coverage.covOption[0].livesType.slice(0, 5) === 'Joint') {
          section.addField(CONSTANTS.RIDER.JOINT_COVERED_LIFE_ISSUE_AGE, jointAnnuitant.issueAge)
        }
      }
    },
    jointCoveredLifeCurrentAge: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (ep.coverage.covOption[0].livesType && ep.coverage.covOption[0].livesType.slice(0, 5) === 'Joint') {
          section.addField(CONSTANTS.RIDER.JOINT_COVERED_LIFE_CURRENT_AGE, jointAnnuitant.birthDate ? dateUtils.extendedAgeTo(jointAnnuitant.birthDate, today) : undefined)
        }
      }
    },
    firstWithdrawalDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.FIRST_WITHDRAWAL_DATE, ep.summary.firstAnyWithdrawalDate)
      }
    },
    ageOfAnnuitant: function(section, ep) {
      const checkKey = {}
      let displayAge

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        displayAge = annuitant.birthDate ? dateUtils.extendedAgeTo(annuitant.birthDate, today) : undefined

        section.addField(CONSTANTS.RIDER.AGE_OF_ANNUITANT, displayAge)
      }
    },
    ageOfJointAnnuitant: function(section, ep) {
      const checkKey = {}
      let displayAge

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (ep.coverage.covOption[0].livesType && ep.coverage.covOption[0].livesType.slice(0, 5) === 'Joint') {
          displayAge = jointAnnuitant.birthDate ? dateUtils.extendedAgeTo(jointAnnuitant.birthDate, today) : undefined

          section.addField(CONSTANTS.RIDER.AGE_OF_JOINT_ANNUITANT, displayAge)
        }
      }
    },
    inflationFactor: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.INFLATION_FACTOR, ep.coverage.lastAvCpiRate, 'percent:2')
      }
    },
    inflationAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.INFLATION_AMOUNT, ep.coverage.lastAvCpiAmount, 'currency')
      }
    },
    enhancementAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA2] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.ENHANCEMENT_AMOUNT, ep.coverage.enhancementAmt, 'currency' || 'None')
      }
    },
    lastStepUpDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GMA2] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_STEP_UP_DATE, ep.coverage.lastStepUpDate || 'None')
      }
    },
    nextStepUpDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GMA2] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.NEXT_STEP_UP_DATE, ep.coverage.nextStepUpDate)
      }
    },
    lastTrueUpDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_TRUE_UP_DATE, ep.coverage.lastTrueUpDate, 'currency')
      }
    },
    lastTrueUpAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_TRUE_UP_AMOUNT, ep.coverage.bonusTrueUpAmt, 'currency')
      }
    },
    currentGMABBaseAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_GMAB_BASE_AMOUNT, ep.coverage.enhancementAmt, 'currency')
      }
    },
    endOfCurrentGMABPeriod: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD, ep.coverage.renewalDate)
      }
    },
    riderChargeRate: function(section, ep) {
      const checkKey = {}

      ep.coverage.fee = ep.coverage.fee || {}
      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.RIDER_CHARGE_RATE, ep.coverage.fee.feePct, 'percent:2')
      }
    },
    firstLivingBenefitWithdrawalDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.FIRST_LIVING_BENEFIT_WITHDRAWAL_DATE, ep.coverage.firstLBWithdrawalDate)
      }
    },
    firstLivingBenefitWithdrawalAge: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.FIRST_LIVING_BENEFIT_WITHDRAWAL_AGE, ep.coverage.firstLBWithdrawalAge)
      }
    },

    // Guaranteed Minimum Accumulation Benefit
    lastRenewalDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_RENEWAL_DATE, ep.coverage.priorRenewalDate)
      }
    },
    lastTrueUpDateGMAB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_TRUE_UP_DATE, ep.coverage.lastTrueUpDate, 'currency')
      }
    },
    lastTrueUpAmountGMAB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_TRUE_UP_AMOUNT, ep.coverage.bonusTrueUpAmt, 'currency')
      }
    },
    currentGMABBaseAmountGMAB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_GMAB_BASE_AMOUNT, ep.coverage.enhancementAmt, 'currency')
      }
    },
    endOfCurrentGMABPeriodGMAB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      checkKey[CONSTANTS.RIDER_CODES.GMA2] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD, ep.coverage.renewalDate)
      }
    },

    // Guaranteed Minimum Withdrawal Benefit
    lastWBStepUpDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_WB_STEP_UP_DATE,
          ep.coverage.lastStepUpDate || 'None')
      }
    },
    nextWBStepUpDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.NEXT_WB_STEP_UP_DATE,
          ep.coverage.nextStepUpDate)
      }
    },
    currentExpenseRateGMWB: function(section, ep) {
      const checkKey = {}

      ep.coverage.fee = ep.coverage.fee || {}
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_EXPENSE_RATE,
          ep.coverage.fee.feePct, 'percent:2')
      }
    },
    withdrawalBenefitBaseForMinimumGuarantee: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.WITHDRAWAL_BENEFIT_BASE_FOR_MINIMUM_GUARANTEE,
          ep.coverage.withdrawalBenefitBaseMinimumGuarantee, 'currency')
      }
    },
    guaranteedGrowthRate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (ep.coverage.status !== 'Lifetime' && (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)])) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_GROWTH_RATE,
          ep.coverage.guarGrowthRate, 'percent:2')
      }
    },
    accruedGuaranteedGrowthAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.ACCRUED_GUARANTEED_GROWTH_AMOUNT,
          ep.coverage.accruedAmtYTD, 'currency')
      }
    },
    guaranteedGrowthBase: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGIB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_GROWTH_BASE,
          ep.coverage.maxBaseAmt, 'currency')
      }
    },
    inflationFactorGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.INFLATION_FACTOR, ep.coverage.lastAvCpiRate, 'percent:2')
      }
    },
    inflationAmountGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.INFLATION_AMOUNT, ep.coverage.lastAvCpiAmount, 'currency')
      }
    },
    preWithdrawalBenefitBase: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.PRE_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.accumPurchasePmt, 'currency')
      }
    },
    purchasePaymentsIncludingAccumulation: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.PURCHASE_PAYMENTS_INCLUDING_ACCUMULATION,
          ep.coverage.accumPurchasePmt, 'currency')
      }
    },
    highestAnniversaryValue: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.HIGHEST_ANNIVERSARY_VALUE,
          ep.coverage.maxAnnivCashValue, 'currency')
      }
    },
    minimumGuaranteedWithdrawalBenefitBase: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.MINIMUM_GUARANTEED_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.accumPurchasePmt, 'currency')
      }
    },
    currentCashValue: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_CASH_VALUE,
          ep.summary.policyValue, 'currency')
      }
      checkKey = {}
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_CASH_VALUE,
          ep.summary.policyValue, 'currency')
      }
    },
    currentWithdrawalBenefitBase: function(section, ep) {
      const checkKey = {}
      let maxValue

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        maxValue = Math.max(ep.coverage.accumPurchasePmt, ep.coverage.maxAnnivCashValue || 0, ep.summary.policyValue)

        section.addField(CONSTANTS.RIDER.CURRENT_WITHDRAWAL_BENEFIT_BASE,
          maxValue, 'currency', CONSTANTS.RIDER_FOOTNOTES.CURRENT_WITHDRAWAL_BENEFIT_BASE)
      }
    },
    standardWithdrawalBenefitBaseGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.swoBenBase, 'currency')
      }
    },
    guaranteedAnnualWithdrawalAmountGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT,
          ep.coverage.swoBenAnnAmt, 'currency')
      }
    },
    guaranteedAnnualLifetimeWithdrawalAmountGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT,
          ep.coverage.lifetimeAnnualWDAmt, 'currency')
      }
    },
    guaranteedAnnualLifetimeWithdrawalAmountRemainingGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_REMAINING,
          ep.coverage.lifetimeAnnualRemainingWDAmt, 'currency')
      }
    },
    standardWithdrawalPercentGMWB: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT,
          7, 'percent:2')
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT,
          either(ep.coverage.swoBaseRate, ep.coverage.benefitPct), 'percent:2')
      }
    },
    lifetimeWithdrawalPercentGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT, 5, 'percent:2')
      }
    },
    remainingGuaranteedAnnualWithdrawalAmountGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT,
          either(ep.coverage.swoBenAnnAmtRem, ep.coverage.robStandardAnnualRemainingWDAmt), 'currency')
      }
    },
    remainingStandardWithdrawalBenefitBalanceGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.REMAINING_STANDARD_WITHDRAWAL_BENEFIT_BALANCE,
          either(ep.coverage.swoBenRem, ep.coverage.remainingROBStandardWDBase), 'currency')
      }
    },
    minimumNumberOfYearsRemainingGMWB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.MINIMUM_NUMBER_OF_YEARS_REMAINING,
          ep.coverage.swoRemYrs)
      }
    },

    // Standard Withdrawal Option
    returnOfBenefitBase: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.RETURN_OF_BENEFIT_BASE,
          ep.coverage.robStandardWDBase, 'currency')
      }
    },
    guaranteedAnnualWithdrawalAmount: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT,
          ep.coverage.robStandardAnnualWDAmt, 'currency')
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT,
          either(ep.coverage.swoBenAnnAmt, ep.coverage.robStandardAnnualWDAmt), 'currency')
      }
    },
    standardWithdrawalBenefitBase: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_WITHDRAWAL_BENEFIT_BASE,
          either(ep.coverage.swoBenBase, ep.coverage.robStandardWDBase), 'currency')
      }
    },
    standardWithdrawalPercent: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT,
          7, 'percent:2')
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT,
          either(ep.coverage.swoBaseRate, ep.coverage.benefitPct), 'percent:2')
      }
    },
    remainingGuaranteedAnnualWithdrawalAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT,
          either(ep.coverage.swoBenAnnAmtRem, ep.coverage.robStandardAnnualRemainingWDAmt), 'currency')
      }
    },
    remainingStandardWithdrawalBenefitBalance: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.REMAINING_STANDARD_WITHDRAWAL_BENEFIT_BALANCE,
          either(ep.coverage.swoBenRem, ep.coverage.remainingROBStandardWDBase), 'currency')
      }
    },
    minimumNumberOfYearsRemaining: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.MINIMUM_NUMBER_OF_YEARS_REMAINING,
          ep.coverage.swoRemYrs)
      }
    },
    returnOfBenefitBaseRemaining: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.RETURN_OF_BENEFIT_BASE_REMAINING,
          ep.coverage.remainingBenefitBaseAmt, 'currency')
      }
    },

    // Lifetime Withdrawal Option
    withdrawalDate: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.WITHDRAWAL_DATE,
          ep.coverage.lwoEligDate)
      }
    },
    lifetimeWithdrawalBenefitBase: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if ((checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) && ep.coverage.status === 'Deferral') {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.lwoBenBase, 'currency')
      } else if ((checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) && ep.coverage.status === 'Withdrawal') {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.lifetimeWDBase, 'currency')
      }

      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (ep.coverage.status === 'Lifetime' && (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)])) {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.lifetimeWDBase, 'currency')
      } else {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_BENEFIT_BASE,
          ep.coverage.lwoBenBase, 'currency')
      }
    },
    guaranteedAnnualLifetimeWithdrawalAmount: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT,
          ep.coverage.lifetimeAnnualWDAmt, 'currency')
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT,
          ep.coverage.lwoBenAnnAmt, 'currency')
      }
    },
    fiveYearWaitingBonusUpTo: function(section, ep) {
      const checkKey = {}
      const showBonus = ep.summary.jurisdiction !== 'New York'

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.FIVE_YEAR_WAITING_BONUS_UP_TO,
          showBonus ? ep.coverage.lwoFiveYrBonusRate : undefined, 'percent:2')
      }
    },
    fiveYearWaitingBonusAvailable: function(section, ep) {
      const checkKey = {}
      const showBonus = ep.summary.jurisdiction !== 'New York'

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.FIVE_YEAR_WAITING_BONUS_AVAILABLE,
          showBonus ? ep.coverage.lwoFiveYrBonusEligDate : undefined)
      }
    },
    tenYearWaitingBonusUpTo: function(section, ep) {
      const checkKey = {}
      const showBonus = ep.summary.jurisdiction !== 'New York'

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.TEN_YEAR_WAITING_BONUS_UP_TO,
          showBonus ? ep.coverage.lwoTenYrBonusRate : undefined, 'percent:2')
      }
    },
    tenYearWaitingBonusAvailable: function(section, ep) {
      const checkKey = {}
      const showBonus = ep.summary.jurisdiction !== 'New York'

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.TEN_YEAR_WAITING_BONUS_AVAILABLE,
          showBonus ? ep.coverage.lwoTenYrBonusEligDate : undefined)
      }
    },
    guaranteedAnnualLifetimeWithdrawalAmountRemaining: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_REMAINING,
          ep.coverage.lifetimeAnnualRemainingWDAmt, 'currency')
      }
    },
    waitingBonusEffective: function(section, ep) {
      const checkKey = {}
      const showBonus = ep.summary.jurisdiction !== 'New York'

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.WAITING_BONUS_EFFECTIVE,
          showBonus ? ep.coverage.lwoBonusRate : undefined, 'percent:2')
      }
    },
    totalLifetimeWithdrawalPercentage: function(section, ep) {
      const checkKey = {}
      const notNY = ep.summary.jurisdiction !== 'New York'

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (notNY && (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)])) {
        section.addField(CONSTANTS.RIDER.TOTAL_LIFETIME_WITHDRAWAL_PERCENTAGE,
          ep.coverage.lwoTotalRate, 'percent:2')
      }
    },
    lifetimeWithdrawalPercent: function(section, ep) {
      let checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT, 5, 'percent:2')
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT, either(ep.coverage.lwoBaseRate, ep.coverage.benefitPct), 'percent:2')
      }
      checkKey = {}
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.WITHDRAWAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT, ep.coverage.benefitPct, 'percent:2')
      }
    },
    remainingGuaranteedAnnualLifetimeWithdrawalAmount: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT,
          ep.coverage.lwoBenAnnAmtRem, 'currency')
      }
    },

    // Enhanced Death Benefit
    purchasePaymentsIncludingAccumulationEDB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.PURCHASE_PAYMENTS_INCLUDING_ACCUMULATION,
          ep.coverage.preWithdrawalAccumPurchaseAmt, 'currency')
      }
    },
    totalPurchasePayments: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIPY + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.TOTAL_PURCHASE_PAYMENTS,
          ep.coverage.preWithdrawalAccumPurchaseAmt, 'currency')
      }
    },
    highestAnniversaryValueEDB: function(section, ep) {
      if (ep.coverage.status === CONSTANTS.RIDER_STATUS.DEFERRAL) {
        section.addField(CONSTANTS.RIDER.HIGHEST_ANNIVERSARY_VALUE,
          ep.coverage.preWithdrawalMaxAnnCashValue, 'currency')
      }
    },
    currentCashValueEDB: function(section, ep) {
      if (ep.coverage.status === CONSTANTS.RIDER_STATUS.DEFERRAL) {
        section.addField(CONSTANTS.RIDER.CURRENT_CASH_VALUE,
          ep.summary.policyValue, 'currency')
      }
    },
    currentEnhancedDeathBenefitBase: function(section, ep) {
      const hasNotes = ep.coverage.status === CONSTANTS.RIDER_STATUS.DEFERRAL

      section.addField(CONSTANTS.RIDER.CURRENT_ENHANCED_DEATH_BENEFIT_BASE,
        ep.coverage.preWithdrawalRemainingDBAmt, 'currency', hasNotes ? CONSTANTS.RIDER_FOOTNOTES.ENHANCED_DEATH_BENEFIT_BASE : '')
    },
    grossDeathBenefit: function(section, ep) {
      section.addField(CONSTANTS.RIDER.GROSS_DEATH_BENEFIT,
        ep.base.deathBenefitAmt, 'currency')
    },
    standardDeathBenefitEDB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIP8] = true
      checkKey[CONSTANTS.RIDER_CODES.GIPY] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9] = true
      checkKey[CONSTANTS.RIDER_CODES.PPPZ] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_DEATH_BENEFIT,
          ep.coverage.standardDeathBenefitAmt, 'currency')
      }
    },
    currentDeathBenefitEnhancementAmount: function(section, ep) {
      section.addField(CONSTANTS.RIDER.CURRENT_DEATH_BENEFIT_ENHANCEMENT_AMOUNT,
        ep.coverage.enhancementAmt, 'currency')
    },
    riderCharge: function(section, ep) {
      section.addField(CONSTANTS.RIDER.RIDER_CHARGE,
        ep.summary.feeAccruedAmtBenefitCharge, 'currency')
    },
    currentTotalDeathBenefit: function(section, ep) {
      section.addField(CONSTANTS.RIDER.CURRENT_TOTAL_DEATH_BENEFIT,
        ep.summary.netDeathBenefitAmt, 'currency')
    },

    // Guaranteed Minimum Death Benefit
    currentExpenseRateGMDB: function(section, ep) {
      const checkKey = {}

      ep.coverage.fee = ep.coverage.fee || {}
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_EXPENSE_RATE,
          ep.coverage.fee.feePct, 'percent:2')
      }
    },
    withdrawalBenefitBaseForMinimumGuaranteeGMDB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.WITHDRAWAL_BENEFIT_BASE_FOR_MINIMUM_GUARANTEE,
          ep.coverage.withdrawalBenefitBaseMinimumGuarantee, 'currency')
      }
    },
    enhancedDeathBenefitBase: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.ENHANCED_DEATH_BENEFIT_BASE,
          ep.coverage.preWithdrawalRemainingDBAmt, 'currency')
      }
    },
    standardDeathBenefit: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.STANDARD_DEATH_BENEFIT,
          ep.coverage.standardDeathBenefitAmt, 'currency')
      }
    },
    currentDeathBenefitEnhancement: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB + CONSTANTS.RIDER_STATUS.LIFETIME] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.CURRENT_DEATH_BENEFIT_ENHANCEMENT,
          ep.coverage.enhancementAmt, 'currency')
      }
    },
    lastStepUpDateGMDB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.LAST_STEP_UP_DATE, ep.coverage.lastStepUpDate || 'None')
      }
    },
    nextStepUpDateGMDB: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        section.addField(CONSTANTS.RIDER.NEXT_STEP_UP_DATE, ep.coverage.nextStepUpDate)
      }
    },

    // Footnotes
    guaranteedLifetimeAmountsFootnote: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GMA6] = true
      checkKey[CONSTANTS.RIDER_CODES.INFL] = true
      checkKey[CONSTANTS.RIDER_CODES.GGIB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGDB] = true
      checkKey[CONSTANTS.RIDER_CODES.EHDB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPWB] = true
      checkKey[CONSTANTS.RIDER_CODES.IPDB] = true
      checkKey[CONSTANTS.RIDER_CODES.ISWB] = true
      checkKey[CONSTANTS.RIDER_CODES.GGWB] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (section.hasTables(ep.coverage.riderCode, ep.coverage.status)) {
          section.addFootnote(CONSTANTS.RIDER_FOOTNOTES.GUARANTEED_LIFETIME_AMOUNTS)
        }
      }
    },
    lifetimeWithdrawalTableFootnote: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.GIPY] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (section.hasTables(ep.coverage.riderCode, ep.coverage.status, annuitant)) {
          section.addFootnote(CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_TABLE)
        }
      }
    },
    lifetimeWithdrawalRatesFootnote: function(section, ep) {
      const checkKey = {}

      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.DEFERRAL] = true
      checkKey[CONSTANTS.RIDER_CODES.PPP9 + CONSTANTS.RIDER_STATUS.STANDARD] = true
      if (checkKey[ep.coverage.riderCode] || checkKey[riderCodeStatusKey(ep)]) {
        if (section.hasTables(ep.coverage.riderCode, ep.coverage.status, annuitant)) {
          section.addFootnote(CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_RATES)
        }
      }
    },
    currentTotalDeathBenefitFootnote: function(section, ep) {
      section.addFootnote(CONSTANTS.RIDER_FOOTNOTES.CURRENT_TOTAL_DEATH_BENEFIT)
    },
  }

  function footnoteMarker(i) {
    switch (i) {
      case 0:
        return '*'
      case 1:
        return '†'
      case 2:
        return '‡'
      case 3:
        return '**'
      case 4:
        return '††'
      case 5:
        return '‡‡'
      default:
        return ''
    }
  }

  function Field(label, value, filter) {
    this.label = label
    this.value = value
    this.filter = filter
  }

  function Section(title) {
    this.title = title
    this.fields = []
    this.subSections = []
    this.tables = []
    this.footnotes = []
  }

  Section.prototype.getValue = function(value) {
    if (value === 0) {
      return value
    }

    return value || null
  }

  Section.prototype.addField = function(label, value, filter, footnote) {
    let noteSymbol = ''
    let noteNum
    let field
    let i

    value = this.getValue(value)
    if (value === null) return

    footnote = footnote || ''

    // If the footnote is a duplicate, then only add the symbol to the field.
    if (footnote) {
      noteNum = this.footnotes.length

      for (i = 0; i < noteNum; i++) {
        noteSymbol = footnoteMarker(i)
        if (this.footnotes[i].substring(noteSymbol.length) === footnote) {
          field = new Field(label + noteSymbol, value, filter)

          this.fields.push(field)
          return
        }
      }
      noteSymbol = footnoteMarker(this.nextFootnoteNumber())
    }

    field = new Field(label + noteSymbol, value, filter)

    this.fields.push(field)
    this.addFootnote(noteSymbol + footnote)
  }

  Section.prototype.addFootnote = function(text) {
    if (text && this.footnotes.indexOf(text) === -1) {
      this.footnotes.push(text)
    }
  }

  Section.prototype.nextFootnoteNumber = function() {
    let noteNum = 0
    let f

    for (f in this.footnote) {
      if (f.indexOf(footnoteMarker(noteNum)) === 0 && f.indexOf(footnoteMarker(noteNum)) !== 1) {
        noteNum++
      }
    }
    return noteNum
  }

  Section.prototype.addSubSection = function(subSection) {
    this.subSections.push(subSection)
  }

  Section.prototype.addTable = function(table) {
    this.tables.push(table)
  }

  Section.prototype.addTables = function(tables) {
    let i

    if (tables instanceof Array) {
      for (i = 0; i < tables.length; i++) {
        this.addTable(tables[i])
      }
    }
  }

  Section.prototype.hasSubSections = function() {
    return this.subSections.length > 0
  }

  Section.prototype.isRole = function(client, role) {
    return client.roles.find(roleObj => roleObj.tc === CLIENT_ROLE_TYPE_CODES[role])
  }

  Section.prototype.getClientByRole = function(clients, role) {
    let ret = null
    let foundClient = false
    let i = 0
    let client

    while (!foundClient && i < clients.length) {
      client = clients[i]

      if (this.isRole(client, role)) {
        ret = client
        foundClient = true
      }
      i++
    }

    if (!foundClient) {
      throw new Error(role + ' not found')
    }

    return ret
  }

  Section.prototype.showSection = function() {
    return true
  }

  function either(op1, op2) {
    return op1 !== undefined ? op1 : op2
  }

  function riderCodeStatusKey(ep) {
    return String(ep.coverage.riderCode) + ep.coverage.status
  }

  function setAnnuitants(scope, summary, clients) {
    annuitant = scope.getClientByRole(clients, CONSTANTS.CLIENT_ROLES.ANNUITANT)

    if (summary.jointAnnuitants) {
      jointAnnuitant = scope.getClientByRole(clients, CONSTANTS.CLIENT_ROLES.JOINT_ANNUITANT)
    } else if (summary.contingentAnnuitants) {
      jointAnnuitant = scope.getClientByRole(clients, CONSTANTS.CLIENT_ROLES.CONTINGENT_ANNUITANT)
    }
  }

  function addFieldIfApplicable(section, endpoints, fieldName) {
    let add

    if (fieldName === undefined) return

    add = {}
    // General Section

    add[CONSTANTS.RIDER.QUOTED_VALUES_AS_OF] = has.quotedValuesAsOf
    add[CONSTANTS.RIDER.BENEFIT_EFFECTIVE_DATE] = has.benefitEffectiveDate
    add[CONSTANTS.RIDER.COVERAGE_CHANGE_DATE] = has.coverageChangeDate
    add[CONSTANTS.RIDER.INVESTMENT_STATUS] = has.investmentStatus
    add[CONSTANTS.RIDER.EFFECTIVE_BENEFIT_PHASE] = has.effectiveBenefitPhase
    add[CONSTANTS.RIDER.LIMITED_VARIABLE] = has.limitedVariable
    add[CONSTANTS.RIDER.ISSUE_STATE] = has.issueState
    add[CONSTANTS.RIDER.SINGLE_JOINT_LIFE_GUARANTEE] = has.singleJointLifeGuarantee
    add[CONSTANTS.RIDER.COVERED_LIFE] = has.coveredLife
    add[CONSTANTS.RIDER.COVERED_LIFE_STATUS] = has.coveredLifeStatus
    add[CONSTANTS.RIDER.COVERED_LIFE_DATE_OF_DEATH] = has.coverdLifeDateOfDeath
    add[CONSTANTS.RIDER.COVERED_LIFE_ISSUE_AGE] = has.coveredLifeIssueAge
    add[CONSTANTS.RIDER.COVERED_LIFE_CURRENT_AGE] = has.coveredLifeCurrentAge
    add[CONSTANTS.RIDER.JOINT_COVERED_LIFE] = has.jointCoveredLife
    add[CONSTANTS.RIDER.JOINT_COVERED_LIFE_STATUS] = has.jointCoveredLifeStatus
    add[CONSTANTS.RIDER.JOINT_COVERED_LIFE_DATE_OF_DEATH] = has.jointCoveredLifeDateOfDeath
    add[CONSTANTS.RIDER.JOINT_COVERED_LIFE_ISSUE_AGE] = has.jointCoveredLifeIssueAge
    add[CONSTANTS.RIDER.JOINT_COVERED_LIFE_CURRENT_AGE] = has.jointCoveredLifeCurrentAge
    add[CONSTANTS.RIDER.FIRST_WITHDRAWAL_DATE] = has.firstWithdrawalDate
    add[CONSTANTS.RIDER.AGE_OF_ANNUITANT] = has.ageOfAnnuitant
    add[CONSTANTS.RIDER.AGE_OF_JOINT_ANNUITANT] = has.ageOfJointAnnuitant
    add[CONSTANTS.RIDER.INFLATION_FACTOR] = has.inflationFactor
    add[CONSTANTS.RIDER.INFLATION_AMOUNT] = has.inflationAmount
    add[CONSTANTS.RIDER.LAST_STEP_UP_DATE] = has.lastStepUpDate
    add[CONSTANTS.RIDER.NEXT_STEP_UP_DATE] = has.nextStepUpDate
    add[CONSTANTS.RIDER.LAST_TRUE_UP_DATE] = has.lastTrueUpDate
    add[CONSTANTS.RIDER.LAST_TRUE_UP_AMOUNT] = has.lastTrueUpAmount
    add[CONSTANTS.RIDER.CURRENT_GMAB_BASE_AMOUNT] = has.currentGMABBaseAmount
    add[CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD] = has.endOfCurrentGMABPeriod
    add[CONSTANTS.RIDER.RIDER_CHARGE_RATE] = has.riderChargeRate
    add[CONSTANTS.RIDER.FIRST_LIVING_BENEFIT_WITHDRAWAL_DATE] = has.firstLivingBenefitWithdrawalDate
    add[CONSTANTS.RIDER.FIRST_LIVING_BENEFIT_WITHDRAWAL_AGE] = has.firstLivingBenefitWithdrawalAge

    // Guaranteed Minimum Accumulation Benefit
    add[CONSTANTS.RIDER.LAST_RENEWAL_DATE] = has.lastRenewalDate
    add[CONSTANTS.RIDER.LAST_TRUE_UP_DATE_GMAB] = has.lastTrueUpDateGMAB
    add[CONSTANTS.RIDER.LAST_TRUE_UP_AMOUNT_GMAB] = has.lastTrueUpAmountGMAB
    add[CONSTANTS.RIDER.CURRENT_GMAB_BASE_AMOUNT_GMAB] = has.currentGMABBaseAmountGMAB
    add[CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD_GMAB] = has.endOfCurrentGMABPeriodGMAB
    add[CONSTANTS.RIDER.ENHANCEMENT_AMOUNT] = has.enhancementAmount

    // Guaranteed Minimum Withdrawal Benefit
    add[CONSTANTS.RIDER.LAST_WB_STEP_UP_DATE] = has.lastWBStepUpDate
    add[CONSTANTS.RIDER.NEXT_WB_STEP_UP_DATE] = has.nextWBStepUpDate
    add[CONSTANTS.RIDER.CURRENT_EXPENSE_RATE_GMWB] = has.currentExpenseRateGMWB
    add[CONSTANTS.RIDER.WITHDRAWAL_BENEFIT_BASE_FOR_MINIMUM_GUARANTEE] = has.withdrawalBenefitBaseForMinimumGuarantee
    add[CONSTANTS.RIDER.GUARANTEED_GROWTH_RATE] = has.guaranteedGrowthRate
    add[CONSTANTS.RIDER.ACCRUED_GUARANTEED_GROWTH_AMOUNT] = has.accruedGuaranteedGrowthAmount
    add[CONSTANTS.RIDER.GUARANTEED_GROWTH_BASE] = has.guaranteedGrowthBase
    add[CONSTANTS.RIDER.INFLATION_FACTOR_GMWB] = has.inflationFactorGMWB
    add[CONSTANTS.RIDER.INFLATION_AMOUNT_GMWB] = has.inflationAmountGMWB
    add[CONSTANTS.RIDER.PRE_WITHDRAWAL_BENEFIT_BASE] = has.preWithdrawalBenefitBase
    add[CONSTANTS.RIDER.PURCHASE_PAYMENTS_INCLUDING_ACCUMULATION] = has.purchasePaymentsIncludingAccumulation
    add[CONSTANTS.RIDER.HIGHEST_ANNIVERSARY_VALUE] = has.highestAnniversaryValue
    add[CONSTANTS.RIDER.MINIMUM_GUARANTEED_WITHDRAWAL_BENEFIT_BASE] = has.minimumGuaranteedWithdrawalBenefitBase
    add[CONSTANTS.RIDER.CURRENT_CASH_VALUE] = has.currentCashValue
    add[CONSTANTS.RIDER.CURRENT_WITHDRAWAL_BENEFIT_BASE] = has.currentWithdrawalBenefitBase
    add[CONSTANTS.RIDER.STANDARD_WITHDRAWAL_BENEFIT_BASE_GMWB] = has.standardWithdrawalBenefitBaseGMWB
    add[CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT_GMWB] = has.standardWithdrawalPercentGMWB
    add[CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT_GMWB] = has.guaranteedAnnualWithdrawalAmountGMWB
    add[CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT_GMWB] = has.remainingGuaranteedAnnualWithdrawalAmountGMWB
    add[CONSTANTS.RIDER.REMAINING_STANDARD_WITHDRAWAL_BENEFIT_BALANCE_GMWB] = has.remainingStandardWithdrawalBenefitBalanceGMWB
    add[CONSTANTS.RIDER.MINIMUM_NUMBER_OF_YEARS_REMAINING_GMWB] = has.minimumNumberOfYearsRemainingGMWB
    add[CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_GMWB] = has.guaranteedAnnualLifetimeWithdrawalAmountGMWB
    add[CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_REMAINING_GMWB] = has.guaranteedAnnualLifetimeWithdrawalAmountRemainingGMWB
    add[CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT_GMWB] = has.lifetimeWithdrawalPercentGMWB

    // Standard Withdrawal Option
    add[CONSTANTS.RIDER.RETURN_OF_BENEFIT_BASE] = has.returnOfBenefitBase
    add[CONSTANTS.RIDER.STANDARD_WITHDRAWAL_BENEFIT_BASE] = has.standardWithdrawalBenefitBase
    add[CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT] = has.standardWithdrawalPercent
    add[CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT] = has.guaranteedAnnualWithdrawalAmount
    add[CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT] = has.remainingGuaranteedAnnualWithdrawalAmount
    add[CONSTANTS.RIDER.REMAINING_STANDARD_WITHDRAWAL_BENEFIT_BALANCE] = has.remainingStandardWithdrawalBenefitBalance
    add[CONSTANTS.RIDER.MINIMUM_NUMBER_OF_YEARS_REMAINING] = has.minimumNumberOfYearsRemaining
    add[CONSTANTS.RIDER.RETURN_OF_BENEFIT_BASE_REMAINING] = has.returnOfBenefitBaseRemaining

    // Lifetime Withdrawal Option
    add[CONSTANTS.RIDER.WITHDRAWAL_DATE] = has.withdrawalDate
    add[CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_BENEFIT_BASE] = has.lifetimeWithdrawalBenefitBase
    add[CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_REMAINING] = has.guaranteedAnnualLifetimeWithdrawalAmountRemaining
    add[CONSTANTS.RIDER.FIVE_YEAR_WAITING_BONUS_UP_TO] = has.fiveYearWaitingBonusUpTo
    add[CONSTANTS.RIDER.FIVE_YEAR_WAITING_BONUS_AVAILABLE] = has.fiveYearWaitingBonusAvailable
    add[CONSTANTS.RIDER.TEN_YEAR_WAITING_BONUS_UP_TO] = has.tenYearWaitingBonusUpTo
    add[CONSTANTS.RIDER.TEN_YEAR_WAITING_BONUS_AVAILABLE] = has.tenYearWaitingBonusAvailable
    add[CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT] = has.lifetimeWithdrawalPercent
    add[CONSTANTS.RIDER.WAITING_BONUS_EFFECTIVE] = has.waitingBonusEffective
    add[CONSTANTS.RIDER.TOTAL_LIFETIME_WITHDRAWAL_PERCENTAGE] = has.totalLifetimeWithdrawalPercentage
    add[CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT] = has.guaranteedAnnualLifetimeWithdrawalAmount
    add[CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT] = has.remainingGuaranteedAnnualLifetimeWithdrawalAmount

    // Enhanced Death Benefit
    add[CONSTANTS.RIDER.PURCHASE_PAYMENTS_INCLUDING_ACCUMULATION_EDB] = has.purchasePaymentsIncludingAccumulationEDB
    add[CONSTANTS.RIDER.HIGHEST_ANNIVERSARY_VALUE_EDB] = has.highestAnniversaryValueEDB
    add[CONSTANTS.RIDER.CURRENT_CASH_VALUE_EDB] = has.currentCashValueEDB
    add[CONSTANTS.RIDER.TOTAL_PURCHASE_PAYMENTS] = has.totalPurchasePayments
    add[CONSTANTS.RIDER.CURRENT_ENHANCED_DEATH_BENEFIT_BASE] = has.currentEnhancedDeathBenefitBase
    add[CONSTANTS.RIDER.GROSS_DEATH_BENEFIT] = has.grossDeathBenefit
    add[CONSTANTS.RIDER.STANDARD_DEATH_BENEFIT_EDB] = has.standardDeathBenefitEDB
    add[CONSTANTS.RIDER.CURRENT_DEATH_BENEFIT_ENHANCEMENT_AMOUNT] = has.currentDeathBenefitEnhancementAmount
    add[CONSTANTS.RIDER.RIDER_CHARGE] = has.riderCharge
    add[CONSTANTS.RIDER.CURRENT_TOTAL_DEATH_BENEFIT] = has.currentTotalDeathBenefit

    // Guaranteed Minimum Death Benefit
    add[CONSTANTS.RIDER.ENHANCED_DEATH_BENEFIT_BASE] = has.enhancedDeathBenefitBase
    add[CONSTANTS.RIDER.WITHDRAWAL_BENEFIT_BASE_FOR_MINIMUM_GUARANTEE_GMDB] = has.withdrawalBenefitBaseForMinimumGuaranteeGMDB
    add[CONSTANTS.RIDER.STANDARD_DEATH_BENEFIT] = has.standardDeathBenefit
    add[CONSTANTS.RIDER.CURRENT_DEATH_BENEFIT_ENHANCEMENT] = has.currentDeathBenefitEnhancement
    add[CONSTANTS.RIDER.CURRENT_EXPENSE_RATE_GMDB] = has.currentExpenseRateGMDB
    add[CONSTANTS.RIDER.LAST_STEP_UP_DATE_GMDB] = has.lastStepUpDateGMDB
    add[CONSTANTS.RIDER.NEXT_STEP_UP_DATE_GMDB] = has.nextStepUpDateGMDB

    // Footnotes
    add[CONSTANTS.RIDER_FOOTNOTES.GUARANTEED_LIFETIME_AMOUNTS] = has.guaranteedLifetimeAmountsFootnote
    add[CONSTANTS.RIDER_FOOTNOTES.CURRENT_TOTAL_DEATH_BENEFIT] = has.currentTotalDeathBenefitFootnote
    add[CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_TABLE] = has.lifetimeWithdrawalTableFootnote
    add[CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_RATES] = has.lifetimeWithdrawalRatesFootnote

    add[fieldName](section, endpoints)
  }

  // TODO: Update Joint endpoints
  function GeneralInfo(summary, coverages, clients, base) {
    let coverage, endpoints

    Section.call(this, 'General')

    setAnnuitants(this, summary, clients)
    coverage = this.getCoverage(coverages)
    endpoints = {
      summary: summary,
      coverage: coverage,
      clients: clients,
      base: base,
    }

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.QUOTED_VALUES_AS_OF)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.BENEFIT_EFFECTIVE_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.COVERAGE_CHANGE_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.INVESTMENT_STATUS)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.EFFECTIVE_BENEFIT_PHASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LIMITED_VARIABLE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.ISSUE_STATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.SINGLE_JOINT_LIFE_GUARANTEE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.COVERED_LIFE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.COVERED_LIFE_STATUS)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.COVERED_LIFE_DATE_OF_DEATH)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.COVERED_LIFE_ISSUE_AGE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.COVERED_LIFE_CURRENT_AGE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.JOINT_COVERED_LIFE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.JOINT_COVERED_LIFE_STATUS)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.JOINT_COVERED_LIFE_DATE_OF_DEATH)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.JOINT_COVERED_LIFE_ISSUE_AGE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.JOINT_COVERED_LIFE_CURRENT_AGE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.FIRST_WITHDRAWAL_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.AGE_OF_ANNUITANT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.AGE_OF_JOINT_ANNUITANT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.INFLATION_FACTOR)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.INFLATION_AMOUNT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_STEP_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.NEXT_STEP_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_TRUE_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_TRUE_UP_AMOUNT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_GMAB_BASE_AMOUNT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.RIDER_CHARGE_RATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.FIRST_LIVING_BENEFIT_WITHDRAWAL_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.FIRST_LIVING_BENEFIT_WITHDRAWAL_AGE)
  }
  GeneralInfo.prototype = Object.create(Section.prototype, {
    getCoverage: {
      value: function(coverages) {
        const RIDER_CODES = Object.values(CONSTANTS.RIDER_CODES)

        let coverage = null
        let coverageFound = false
        let i = 0
        let currentCoverage

        while (!coverageFound && i < coverages.length) {
          currentCoverage = coverages[i]

          if (currentCoverage.riderCode) {
            if (RIDER_CODES.includes(currentCoverage.riderCode)) {
              coverage = coverages[i]
              coverageFound = true
            }
          }
          i++
        }
        if (!coverageFound) throw new Error('Coverage not found')

        coverage.covOption = coverage.covOption || [{}]

        return coverage
      },
    },
  })

  function GuaranteedMinimumAccumulationBenefit(coverage) {
    let endpoints

    Section.call(this, 'Guaranteed Minimum Accumulation Benefit')
    endpoints = {
      coverage: coverage,
    }

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_RENEWAL_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_TRUE_UP_DATE_GMAB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_TRUE_UP_AMOUNT_GMAB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_GMAB_BASE_AMOUNT_GMAB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD_GMAB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_STEP_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.NEXT_STEP_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.ENHANCEMENT_AMOUNT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.END_OF_CURRENT_GMAB_PERIOD)
  }
  GuaranteedMinimumAccumulationBenefit.prototype = Object.create(Section.prototype)

  // TODO: Update endpoints
  function GuaranteedMinimumWithdrawalBenefit(coverage, summary, clients, tables) {
    let endpoints

    Section.call(this, this.getSectionTitle(coverage))
    endpoints = {
      coverage: coverage,
      summary: summary,
      clients: clients,
      tables: tables,
    }

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_WB_STEP_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.NEXT_WB_STEP_UP_DATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_EXPENSE_RATE_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.WITHDRAWAL_BENEFIT_BASE_FOR_MINIMUM_GUARANTEE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_GROWTH_RATE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.ACCRUED_GUARANTEED_GROWTH_AMOUNT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_GROWTH_BASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.INFLATION_FACTOR_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.INFLATION_AMOUNT_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.PRE_WITHDRAWAL_BENEFIT_BASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.PURCHASE_PAYMENTS_INCLUDING_ACCUMULATION)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.HIGHEST_ANNIVERSARY_VALUE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.MINIMUM_GUARANTEED_WITHDRAWAL_BENEFIT_BASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_CASH_VALUE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_WITHDRAWAL_BENEFIT_BASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_REMAINING_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.STANDARD_WITHDRAWAL_BENEFIT_BASE_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.REMAINING_STANDARD_WITHDRAWAL_BENEFIT_BALANCE_GMWB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.MINIMUM_NUMBER_OF_YEARS_REMAINING_GMWB)

    this.addSubSection(new LifetimeWithdrawalOption(coverage, annuitant, tables.lifetime, summary, this))
    this.addSubSection(new StandardWithdrawalOption(coverage, annuitant, tables.standard, this))
  }
  GuaranteedMinimumWithdrawalBenefit.prototype = Object.create(Section.prototype, {
    getSectionTitle: {
      value: function(coverage) {
        if (coverage.riderCode === CONSTANTS.RIDER_CODES.INFL && coverage.status === CONSTANTS.RIDER_STATUS.STANDARD) {
          return 'Guaranteed Standard Withdrawal Benefit Values'
        }

        switch (coverage.riderCode) {
          case CONSTANTS.RIDER_CODES.GIP8:
          case CONSTANTS.RIDER_CODES.PPP9:
          case CONSTANTS.RIDER_CODES.GIPY:
          case CONSTANTS.RIDER_CODES.PPPZ:
            return 'Guaranteed Minimum Withdrawal Benefit'

          case CONSTANTS.RIDER_CODES.GGIB:
          case CONSTANTS.RIDER_CODES.GGDB:
          case CONSTANTS.RIDER_CODES.IPWB:
          case CONSTANTS.RIDER_CODES.IPDB:
          case CONSTANTS.RIDER_CODES.ISWB:
            return 'Guaranteed Withdrawal Benefit'

          case CONSTANTS.RIDER_CODES.GGWB:
            if (coverage.status === CONSTANTS.RIDER_STATUS.STANDARD) {
              return 'Standard Withdrawal Benefit Values'
            }
            return 'Guaranteed Withdrawal Benefit'

          default:
            return ''
        }
      },
    },
  })

  function StandardWithdrawalOption(coverage, annuitantWithOption, tables, parent) {
    let endpoints

    Section.call(this, this.getSectionTitle(coverage.riderCode, coverage.status))
    endpoints = {
      coverage: coverage,
      tables: tables,
    }

    this.showSection = function() {
      return coverage.availableStandard !== false
    }

    if (this.showSection()) {
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.RETURN_OF_BENEFIT_BASE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.STANDARD_WITHDRAWAL_BENEFIT_BASE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.STANDARD_WITHDRAWAL_PERCENT)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_WITHDRAWAL_AMOUNT)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.REMAINING_STANDARD_WITHDRAWAL_BENEFIT_BALANCE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.MINIMUM_NUMBER_OF_YEARS_REMAINING)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.RETURN_OF_BENEFIT_BASE_REMAINING)
    }

    if (this.hasTables(coverage.riderCode, coverage.status, annuitantWithOption)) {
      this.addTables(tables)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_TABLE)
    }
  }
  StandardWithdrawalOption.prototype = Object.create(Section.prototype, {
    getSectionTitle: {
      value: function(riderCode, status) {
        return 'Guaranteed Standard Benefit Withdrawal Values'
      },
    },
    hasTables: {
      value: function(riderCode, status, annuitantWithOption) {
        if (riderCode === CONSTANTS.RIDER_CODES.INFL &&
          status === CONSTANTS.RIDER_STATUS.DEFERRAL) {
          return true // annuitantWithOption.calculatedAge > 59.5 && annuitantWithOption.calculatedAge < 64.5
        } else if (riderCode === CONSTANTS.RIDER_CODES.GGWB) {
          return true
        }
        return status === CONSTANTS.RIDER_STATUS.DEFERRAL
      },
    },
  })

  function LifetimeWithdrawalOption(coverage, annuitantWithOption, tables, summary) {
    let endpoints

    Section.call(this, this.getSectionTitle(coverage))
    endpoints = {
      coverage: coverage,
      tables: tables,
      summary: summary,
    }

    this.showSection = function() {
      return coverage.availableLifetime !== false
    }

    if (this.showSection()) {
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.WITHDRAWAL_DATE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_BENEFIT_BASE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LIFETIME_WITHDRAWAL_PERCENT)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.WAITING_BONUS_EFFECTIVE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.TOTAL_LIFETIME_WITHDRAWAL_PERCENTAGE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.REMAINING_GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GUARANTEED_ANNUAL_LIFETIME_WITHDRAWAL_AMOUNT_REMAINING)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.FIVE_YEAR_WAITING_BONUS_UP_TO)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.FIVE_YEAR_WAITING_BONUS_AVAILABLE)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.TEN_YEAR_WAITING_BONUS_UP_TO)
      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.TEN_YEAR_WAITING_BONUS_AVAILABLE)

      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER_FOOTNOTES.GUARANTEED_LIFETIME_AMOUNTS)
    }

    if (this.hasTables(coverage.riderCode, coverage.status)) {
      this.addTables(tables)

      addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_RATES)
    }

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER_FOOTNOTES.LIFETIME_WITHDRAWAL_RATES)
  }
  LifetimeWithdrawalOption.prototype = Object.create(Section.prototype, {
    getSectionTitle: {
      value: function(coverage) {
        return 'Guaranteed Lifetime Withdrawal Benefit Values'
      },
    },
    hasTables: {
      value: function(riderCode, status) {
        return status === CONSTANTS.RIDER_STATUS.DEFERRAL
      },
    },
  })

  function EnhancedDeathBenefit(coverage, base, summary) {
    let endpoints

    Section.call(this, 'Enhanced Death Benefit')
    endpoints = {
      coverage: coverage,
      base: base,
      summary: summary,
    }

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.PURCHASE_PAYMENTS_INCLUDING_ACCUMULATION_EDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.HIGHEST_ANNIVERSARY_VALUE_EDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_CASH_VALUE_EDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.TOTAL_PURCHASE_PAYMENTS)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_ENHANCED_DEATH_BENEFIT_BASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.GROSS_DEATH_BENEFIT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.STANDARD_DEATH_BENEFIT_EDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_DEATH_BENEFIT_ENHANCEMENT_AMOUNT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.RIDER_CHARGE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_TOTAL_DEATH_BENEFIT)

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER_FOOTNOTES.CURRENT_TOTAL_DEATH_BENEFIT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER_FOOTNOTES.CURRENT_CASH_VALUE_EDB)
  }
  EnhancedDeathBenefit.prototype = Object.create(Section.prototype)

  function GuaranteedMinimumDeathBenefit(coverage, base, summary) {
    let endpoints

    Section.call(this, 'Guaranteed Minimum Death Benefit')
    endpoints = {
      coverage: coverage,
      base: base,
      summary: summary,
    }

    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.ENHANCED_DEATH_BENEFIT_BASE)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.WITHDRAWAL_BENEFIT_BASE_FOR_MINIMUM_GUARANTEE_GMDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.STANDARD_DEATH_BENEFIT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_DEATH_BENEFIT_ENHANCEMENT)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.LAST_STEP_UP_DATE_GMDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.NEXT_STEP_UP_DATE_GMDB)
    addFieldIfApplicable(this, endpoints, CONSTANTS.RIDER.CURRENT_EXPENSE_RATE_GMDB)
  }
  GuaranteedMinimumDeathBenefit.prototype = Object.create(Section.prototype)

  return {
    GeneralInfo: GeneralInfo,
    GuaranteedMinimumAccumulationBenefit: GuaranteedMinimumAccumulationBenefit,
    GuaranteedMinimumWithdrawalBenefit: GuaranteedMinimumWithdrawalBenefit,
    EnhancedDeathBenefit: EnhancedDeathBenefit,
    GuaranteedMinimumDeathBenefit: GuaranteedMinimumDeathBenefit,
  }
}
