/* @ngInject */
export class SalesAchievementReportsRunner {
  static $inject = ['routerHelper']

  constructor(routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates() {
    return [{
      state: 'sales-achievement-reports',
      config: {
        url: '/sales-achievement-reports',
        templateUrl: 'app/sales-achievement-reports/tmpl/sales-achievement-reports.html',
        controller: 'salesAchievementReportsController',
        controllerAs: 'ctrl',
        data: {
          // addToNavigation: true,
          secure: true,
          module: 'SalesAchievementReports',
        },
        onEnter: ['$rootScope', 'CONSTANTS', function ($rootScope, CONSTANTS) {
          $rootScope.pageTitle = CONSTANTS.modules.SalesAchievementReports.name
          $rootScope.mobileHeaderTitle = CONSTANTS.modules.SalesAchievementReports.name
          // $rootScope.hideBarHeader = false;
          $rootScope.state = CONSTANTS.modules.SalesAchievementReports.id
        }],
      },
    }]
  }
}
