/* eslint-disable @typescript-eslint/no-explicit-any */
import ng, { IAttributes, IController, IScope } from 'angular'

export interface IDropdownEntry {
  label: string
  value: any
  disabled?: boolean
  body?: string
}
export class DropdownController implements IController {
  static $inject = ['$element', '$scope', '$attrs', '$timeout']
  ngModel: ng.INgModelController
  menuOpen: boolean
  listSource: any[] | string[] | IDropdownEntry[]
  originalListSource: any[]  // @NOTE: This might not be needed.
  textField: string
  valueField: string
  bodyField: string
  internalValue: any
  optionListElement: any
  selectedIndex: number = -1
  initialValue: any
  listSourceType: string
  identifier: string
  onChange: () => void
  onParse: (viewValue: any) => any
  onRequired: (viewValue: any) => boolean
  placeholder: any | IDropdownEntry
  disabled: boolean = false
  isListBox: boolean = false

  private unregisterWatches: unknown[] = []

  constructor(private $element: any, private $scope: IScope, private $attrs: IAttributes) { }

  getCurrentViewValue(targetOption: any): any {
    // Get the value to set a view value, not IDropdownEntry.
    let selectedIndex: number = -1
    const viewValue: any = this.listSource.find((item, index) => {
      const match = item[this.valueField] === targetOption[this.valueField]

      if (match) {
        selectedIndex = index
        return true
      }

      return false
    })

    return { selectedIndex, viewValue }
  }

  getCurrentInternalValue(targetOption: any): any {
    const internalValue: any = this.listSource.find((item) => {
      const match = item[this.valueField] === targetOption[this.valueField]

      return match
    })

    return internalValue
  }

  $postLink(): void {
    const tmpPlaceholderValue = isFinite(this.$attrs.placeholderValue) ? Number(this.$attrs.placeholderValue) : this.$attrs.placeholderValue || ''

    this.isListBox = this.$attrs.isListBox

    /**
     * Resolve which properties to bind to for label, value,
     * and body on each dropdown option rendered.
     *
     */
    this.textField = this.$attrs.textField ?? 'label'
    this.valueField = this.$attrs.valueField ?? 'value'
    this.bodyField = this.$attrs.bodyField ?? 'body'

    this.ngModel.$overrideModelOptions({ allowInvalid: true })

    /**
     * Wire up event handlers
     */
    this.$element.on('keydown', (event) => {
      this.$scope.$applyAsync(() => this.onKeyup(event))
    })

    window.onclick = () => {
      this.$scope.$applyAsync(() => this.menuOpen = false)
    }

    /**
     * Wire up custom required validator
     */
    if (this.$attrs.required) {
      this.ngModel.$validators.required = (viewValue: any): boolean => {
        return viewValue[this.valueField] !== tmpPlaceholderValue
      }
    }

    // Find the ul tag to add the dropdown option to.
    this.optionListElement = this.$element.find('ul')[0]

    // Ensure the menu is closed
    this.menuOpen = false

    /**
     * Determin what the data type of the listSource is by checking the typeof the first element.
     * if it's a string, then assume the listSource is a string array. Otherwise, assume
     * it's an array of objects.
     *
     * @TODO: Determine what to do if the dropdown is empty. (This *should* never happen.)
     */
    this.listSourceType = typeof this.listSource?.[0]
    this.originalListSource = this.listSource

    if (this.listSourceType === 'object') {
      // @NOTE: 2/29/2024 the following code has been deemed faulty
      //
      // this.listSource = this.listSource.map((item: any): IDropdownEntry => {
      //   return { label: item[this.textField], value: item[this.valueField], body: item[this.bodyField] }
      // })
    } else {
      this.listSource = this.listSource.map((item: any): IDropdownEntry => {
        return { label: item, value: item }
      })
    }

    /**
     * Determine of a placeholder was defined, if so added it to the top of the list source array. If not, try to select
     * the current $viewValue, otherwise fallback to the first item in the list.
     */
    this.placeholder = this.$attrs.placeholder ? { [this.textField]: this.$attrs.placeholder, [this.valueField]: tmpPlaceholderValue || '', disabled: true } : null

    if (this.placeholder) {
      const placeholderExists = this.listSource.find((item) => item[this.valueField] === tmpPlaceholderValue)
      if (!placeholderExists) this.listSource.unshift(this.placeholder)
    }

    if (this.listSource?.length > 0) {
      this.$scope.$applyAsync(() => {

        // See if ngModel viewValue is in the listSource
        const option = this.listSource.find((o: any) => {
          const tmpOptionValue = this.ngModel.$viewValue?.[this.valueField]

          return o[this.valueField] === tmpOptionValue
        })

        console.log('>>>>>', this.placeholder, option)

        // Set intial internalValue
        // if (option?.[this.valueField] !== this.placeholder[this.valueField]) {
        //   this.selectOption(null, option)
        // } else if ((option?.[this.valueField] === tmpPlaceholderValue) || (!option && this.placeholder)) {
        //   this.internalValue = option || this.placeholder
        // } else if (!option && !this.placeholder) {
        //   this.selectOption(null, this.listSource[0])  // Default to first item in the listSource
        // }

        // if(option?.disable) {
        //   this.internalValue = option
        //   this.selectedIndex = 0
        // }

        this.selectOption(option || this.listSource[0], 0)

        this.ngModel.$setPristine()
      })
    }
  }

  $doCheck(): void {
    // the following line looks wrong; this.ngModel.$modelValue[this.valueField] would only be -1 of that was an expect value.
    const { selectedIndex, viewValue } = this.getCurrentViewValue(this.ngModel.$modelValue)

    if (this.ngModel.$modelValue?.[this.valueField] !== this.internalValue?.[this.valueField]) this.selectOption(viewValue, selectedIndex)
  }

  $onDestroy(): void {
    this.unregisterWatches.forEach((fn: () => void) => fn())
  }

  onToggleMenu(event: MouseEvent | TouchEvent): void {
    if (this.disabled) return

    this.menuOpen = !this.menuOpen
    event.stopPropagation()
  }

  selectOption(option: any, index: number): void {
    if (this.internalValue) {
      this.internalValue.selected = false
    }
    this.selectedIndex = index
    this.ngModel.$setViewValue(option)
    this.internalValue = option
    if (this.internalValue) {
      this.internalValue.selected = this.isListBox
    }
  }

  handleEvent(event: KeyboardEvent | MouseEvent | TouchEvent, option: any): void {

    // Get the value to set a view value, not IDropdownEntry.
    const { selectedIndex } = this.getCurrentViewValue(option)

    // only set touched if the user clicked or set focus.
    console.log('handleEvent', selectedIndex, option)

    if (option.disabled) return

    this.ngModel.$setTouched()

    // Close the dropdown if the user chose an option.
    if (event?.type === 'click') this.menuOpen = false

    this.selectOption(option, selectedIndex)

    // this.ngModel.$modelValue =  viewValue
    // this.ngModel.$processModelValue()

    // if (this.onChange) {
    //   this.onChange({
    //     name: this.$attrs.ngModel,
    //     value: this.internalValue,
    //     identifier: this.$attrs.identifier,
    //   })
    // }
  }

  private arrowKeys($event) {
    const listSize = this.listSource.length - 1

    if ($event.code === 'ArrowUp' && --this.selectedIndex < 0) this.selectedIndex = 0
    if ($event.code === 'ArrowDown' && ++this.selectedIndex > listSize) this.selectedIndex = listSize

    this.internalValue = this.listSource[this.selectedIndex]
    this.focusOption()
  }

  onKeyup($event: KeyboardEvent): void {
    $event.stopImmediatePropagation()
    $event.preventDefault()
    $event.stopPropagation()

    switch ($event.code) {
      case 'ArrowUp':
      case 'ArrowDown':
        this.arrowKeys($event)
        break

      case 'Enter':
        // case 27:
        this.handleEvent($event, this.internalValue)
        this.menuOpen = false
        break

      case 'Escape':
        this.internalValue = this.getCurrentInternalValue(this.ngModel.$viewValue)
        this.menuOpen = false
        break

      // case 9:
      //   $event.stopPropagation()
      //   $event.preventDefault()
      //   this.menuOpen = false
      //   this.$element.next()
      //   break

      case 'Space':
        this.menuOpen = true
        this.selectedIndex = this.listSource.findIndex((option) => option.value === this.internalValue?.value)
        if (this.selectedIndex === -1) {
          this.selectedIndex = 0
        }
        this.focusOption()
        break

      // default:
      //   if (!this.selectOnly) {
      //     this.filterOptions()
      //     this.ngModel.$setViewValue(this.internalValue)
      //   }
      // this.selectedIndex = -1
      // this.selectedItem = null
    }


  }

  focusOption(): void {
    const selectedItem = this.optionListElement?.children[this.selectedIndex]

    selectedItem.focus({ preventScroll: false })
    this.internalValue = this.listSource[this.selectedIndex]
  }
}

export const dropdownComponent = {
  templateUrl: 'app/components/dropdown/dropdown.html',
  controller: DropdownController,
  controllerAs: 'ctrl',
  transclude: true,
  bindings: {
    listSource: '=',
    disabled: '=',
  },
  require: {
    ngModel: 'ngModel',
  },
}
