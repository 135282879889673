import { CS_PARTY_TYPES } from '../constants/BENEFICIARY_OTHER_OPTIONS'
import { IPersonName, ISerializabeProperty, ITypeCodeValue } from '../types'
import AbstractTransformableData from './abstract-transformable-data'
import { TypeCodeValue } from './type-code-value'
export class EntityName extends AbstractTransformableData implements IPersonName {
  first: string
  middle: string
  last: string
  prefix?: string | undefined
  suffix?: string | undefined
  profSuffix?: string | undefined
  _fullName: string
  _partyTypeCode?: ITypeCodeValue

  // { prefix: this.prefix, firstName: this.first, middleName: this.middle, lastName: this.last, suffix: this.suffix, title: this.profSuffix } : { fullName: this.fullName }

  serializableProperties: ISerializabeProperty[] = [
    { name: 'first', gwName: 'firstName'},
    { name: 'middle', gwName: 'middleName' },
    { name: 'last', gwName: 'lastName' },
    { name: 'prefix' },
    { name: 'suffix' },
    { name: 'profSuffix', gwName: 'title' },
  ]

  constructor(csClientInfo?: any, partyTypeCode?: ITypeCodeValue) {
    super(['serializableProperties'], ['fullName'])

    switch (csClientInfo?.constructor?.name) {
      case 'EntityName':
        this._partyTypeCode = csClientInfo._partyTypeCode

        this._fullName = csClientInfo.fullName

        this.first = csClientInfo.first
        this.middle = csClientInfo.middle
        this.last = csClientInfo.last
        this.prefix = csClientInfo.prefix
        this.suffix = csClientInfo.suffix
        this.profSuffix = csClientInfo.profSuffix ?? ''
        break

      case 'ClientRole':
        this._partyTypeCode = csClientInfo._partyTypeCode

        this._fullName = csClientInfo.fullName

        this.first = csClientInfo?.name.first ?? ''
        this.middle = csClientInfo?.name.middle ?? ''
        this.last = csClientInfo?.name.last ?? ''
        this.prefix = csClientInfo?.prefix ?? ''
        this.suffix = csClientInfo?.suffix ?? ''
        this.profSuffix = csClientInfo?.title ?? ''
        break

      default:
        this.first = csClientInfo?.firstName ?? ''
        this.middle = csClientInfo?.middleName ?? ''
        this.last = csClientInfo?.lastName ?? ''
        this.prefix = csClientInfo?.prefix ?? ''
        this.suffix = csClientInfo?.suffix ?? ''
        this.profSuffix = csClientInfo?.title ?? ''

        this._fullName = csClientInfo?.fullName ?? ''

        this.updatePartyTypeCode(csClientInfo?.partyTypeCode ?? partyTypeCode)

    }
  }

  private get _isPerson(): boolean {
    return this._partyTypeCode?.tc === CS_PARTY_TYPES.PERSON
  }

  private _emptyString(testString: string | undefined, defaultString?: string) {
    return testString ?? (defaultString ?? '')
  }

  private _padString(inString: string | undefined, padLeft: boolean = false, padRight: boolean = false): string {
    let outString: string = inString ?? ''

    if (outString) {
      if (padLeft) outString = ' ' + outString
      if (padRight) outString = outString + ' '
    }

    return outString
  }

  get fullName(): string {
    const prefix: string = this._padString(this._emptyString(this.prefix), false, true)
    const first: string = this._padString(this._emptyString(this.first), false, true)
    const middle: string = this._padString(this._emptyString(this.middle), false, true)
    const last: string = this._padString(this._emptyString(this.last), false, true)
    const suffix: string = this._padString(this._emptyString(this.suffix), false, true)
    const profSuffix: string = this._padString(this._emptyString(this.profSuffix), false, false)
    const personName: string = prefix + first + middle + last + suffix + profSuffix

    return this._isPerson ? personName.trim() : this._fullName
  }

  set fullName(value) {
    this._fullName = value
  }

  get isEmpty(): boolean {
    return this.fullName === ''
  }

  // private _isTitle(namePart) {
  //   return ['Mr'].includes(namePart)
  // }

  // private _isSuffix(namePart) {
  //   return ['Jr'].includes(namePart)
  // }

  // private _parseFullName() {
  //   const nameParts = this._fullName.split(' ')

  //   switch (nameParts.length) {
  //     case 1:
  //       this.first = nameParts[0]
  //       break
  //     case 2:
  //       this.first = nameParts[0]
  //       this.last = nameParts[1]
  //       break
  //     case 3:
  //       if (this._isTitle(nameParts[0])) {
  //         this.title = nameParts[0]
  //         this.first = nameParts[1]
  //         this.last = nameParts[2]
  //       } else if (this._isSuffix(nameParts[3])) {
  //         this.first = nameParts[0]
  //         this.last = nameParts[1]
  //         this.suffix = nameParts[2]
  //       } else {
  //         this.first = nameParts[0]
  //         this.middle = nameParts[1]
  //         this.last = nameParts[2]
  //       }
  //       break
  //   }
  // }

  updatePartyTypeCode(partyTypeCode) {
    if (this._partyTypeCode?.tc !== partyTypeCode?.tc) {
      // if (this._partyTypeCode?.tc !== '1') {
      //   this._parseFullName()
      // }

      this._partyTypeCode = new TypeCodeValue(partyTypeCode)
    }
  }

  hasChange(original) {
    return this.fullName !== original.name.fullName || this._partyTypeCode?.tc !== original._partyTypeCode?.tc
  }


  serialize(originalName?: EntityName) {
    if (!originalName) {
      return this._isPerson ? { prefix: this.prefix, firstName: this.first, middleName: this.middle, lastName: this.last, suffix: this.suffix, title: this.profSuffix } : { fullName: this.fullName }
    } else {
      if (this._isPerson) {
        return this.serializableProperties.reduce((nameObj: any, propDef: ISerializabeProperty) => {
          const oProp = originalName[propDef.name]
          const nProp = this[propDef.name]

          if (oProp !== nProp) {
            nameObj[propDef.gwName || propDef.name] = nProp
          }

          return nameObj
        }, {})
      } else if (this.fullName !== originalName?.fullName) {
        return { fullName: this.fullName }
      }
    }
  }

  toCSObject(): any {
    const tmp: any = {}

    tmp.firstName = this.first
    tmp.middleName = this.middle
    tmp.lastName = this.last
    tmp.fullName = this.fullName
    tmp.prefix = this.prefix
    tmp.suffix = this.suffix
    tmp.title = this.profSuffix

    return tmp
  }
 }
