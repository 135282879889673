import * as angular from 'angular'
import {AlertService} from './alert-service'

/**
 * ## AlertDismissService
 *
 * Manages a buffer of alerts to be dismissed. And broadcasts notifications
 * when a change has completed.
 *
 */
export class AlertDismissService {

  static $inject = ['$http', 'CONSTANTS', 'alertService', '$q', 'utils', 'partyService']

  constructor(private $http: angular.IHttpService, private CONSTANTS, public alertService: AlertService,
    private $q: angular.IQService, private utils, private partyService) {}

  categoryBuffer: string[] = []
  subscribers: Function[] = []

  /**
   * Adds the provided `callback` function to the list of `subscribers`.
   *
   * @param callback
   */
  subscribeCallback(callback: Function) {
    this.subscribers.push(callback)
  }

  /**
   * Iterates over the list of `subscribers` callbacks
   * and calls them.
   */
  notifySubscribers() {
    this.subscribers.forEach((callback: Function) => {
      callback()
    })
  }

  /**
   * Adds the provided `category` to the `categoryBuffer`.
   *
   * @param category
   */
  addCategoryToBuffer(category: string) {
    if (this.isCategoryInBuffer(category)) {
      return false
    }

    this.categoryBuffer.push(category)
    this.notifySubscribers()
    return true
  }

  /**
   * Removed the provided `category` from the `categoryBuffer`.
   *
   * @param category
   */
  removeCategoryFromBuffer(category: string) {
    let index: number = 0

    if (this.isCategoryInBuffer(category)) {
      index = this.categoryBuffer.indexOf(category)

      this.categoryBuffer.splice(index, 1)
      this.notifySubscribers()
      return true
    }

    return false
  }

  /**
   * Returns `true` if the provided `category` is in the `categoryBuffer`.
   *
   * @param category
   */
  isCategoryInBuffer(category: string) {
    const index = this.categoryBuffer.indexOf(category)

    return index !== -1
  }

  /**
   * Returns the number of alerts to be deleted based on the `categoryBuffer`,
   * and the category 'summary' returned from the CS end-point.
   *
   * @param categories
   */
  countAlertsToBeDismissed(categories) {
    return this.categoryBuffer.reduce((count: number, categoryName: string) => {
      const catCount = categories[categoryName]

      count += catCount

      return count
    }, 0)
  }

  /**
   * Send request to CS end-point to dismiss all alert categories based
   * on the `categoryBuffer`. This is accomplished by making multiple
   * promised PUT requests. When they all successfully completed, all
   * registered subscribers are notified.
   *
   * TODO:  There as potential point of failure here because if there are
   *        multiple requests made, they MUST all succeed in order for
   *        this method to resolve.  Each individual call should
   *        handle it's own 'catch' so that a single failure does not
   *        prevent the others from succeeding.
   *
   */
  dismiss() {
    const promises: any[] = []

    this.categoryBuffer.forEach((category) => {
      let url: string = this.CONSTANTS.alertsCategoryMarkRead.replace(/\{0\}/, category)

      url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())

      // Dismiss <count> alerts on this category.
      promises.push(this.$http.put(url, {}))
    })

    this.categoryBuffer = []

    return this.$q.all(promises)
      .then((r) => {
        this.notifySubscribers()
        return r
      })
  }

}
