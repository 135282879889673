export {CountryList}
const CountryList = [
    {value: 'Afghanistan', tc: 'AF'},
    {value: 'Albania', tc: 'AL'},
    {value: 'Algeria', tc: 'DZ'},
    {value: 'Andorra', tc: 'AD'},
    {value: 'Angola', tc: 'AO'},
    {value: 'Anguilla', tc: 'AI'},
    {value: 'Argentina', tc: 'AR'},
    {value: 'Armenia', tc: 'AM'},
    {value: 'Aruba', tc: 'AW'},
    {value: 'Australia', tc: 'AU'},
    {value: 'Austria', tc: 'AT'},
    {value: 'Azerbaijan', tc: 'AZ'},
    {value: 'Bahamas', tc: 'BS'},
    {value: 'Bahrain', tc: 'BH'},
    {value: 'Bangladesh', tc: 'BD'},
    {value: 'Barbados', tc: 'BB'},
    {value: 'Belarus', tc: 'BY'},
    {value: 'Belgium', tc: 'BE'},
    {value: 'Belize', tc: 'BZ'},
    {value: 'Benin', tc: 'BJ'},
    {value: 'Bermuda', tc: 'BM'},
    {value: 'Bhutan', tc: 'BT'},
    {value: 'Bolivia', tc: 'BO'},
    {value: 'Bosnia and Herzegovina', tc: 'BA'},
    {value: 'Botswana', tc: 'BW'},
    {value: 'Brazil', tc: 'BR'},
    {value: 'Brunei ', tc: 'BN'},
    {value: 'Bulgaria', tc: 'BG'},
    {value: 'Burkina Faso', tc: 'BF'},
    {value: 'Burundi', tc: 'BI'},
    {value: 'Cambodia', tc: 'KH'},
    {value: 'Cameroon', tc: 'CM'},
    {value: 'Canada', tc: 'CA'},
    {value: 'Cape Verde', tc: 'CV'},
    {value: 'Cayman Islands', tc: 'KY'},
    {value: 'Chad', tc: 'TD'},
    {value: 'Chile', tc: 'CL'},
    {value: 'China', tc: 'CN'},
    {value: 'Colombia', tc: 'CO'},
    {value: 'Comoros', tc: 'KM'},
    {value: 'Congo', tc: 'CG'},
    {value: 'Cook Islands', tc: 'CK'},
    {value: 'Costa Rica', tc: 'CR'},
    {value: "Cote d'Ivoire", tc: 'CI'},
    {value: 'Croatia', tc: 'HR'},
    {value: 'Cuba', tc: 'CU'},
    {value: 'Cyprus', tc: 'CY'},
    {value: 'Czech Republic', tc: 'CZ'},
    {value: 'Denmark', tc: 'DK'},
    {value: 'Djibouti', tc: 'DJ'},
    {value: 'Dominica', tc: 'DM'},
    {value: 'Dominican Republic', tc: 'DO'},
    {value: 'Ecuador', tc: 'EC'},
    {value: 'Egypt', tc: 'EG'},
    {value: 'El Salvador', tc: 'SV'},
    {value: 'Equatorial Guinea', tc: 'GQ'},
    {value: 'Eritrea', tc: 'ER'},
    {value: 'Estonia', tc: 'EE'},
    {value: 'Ethiopia', tc: 'ET'},
    {value: 'Faroe Islands', tc: 'FO'},
    {value: 'Fiji', tc: 'FJ'},
    {value: 'Finland', tc: 'FI'},
    {value: 'France', tc: 'FR'},
    {value: 'French Guiana', tc: 'GF'},
    {value: 'French Polynesia', tc: 'PF'},
    {value: 'Gabon', tc: 'GA'},
    {value: 'Gambia', tc: 'GM'},
    {value: 'Georgia', tc: 'GE'},
    {value: 'Germany', tc: 'DE'},
    {value: 'Ghana', tc: 'GH'},
    {value: 'Gibraltar', tc: 'GI'},
    {value: 'Greece', tc: 'GR'},
    {value: 'Greenland', tc: 'GL'},
    {value: 'Grenada', tc: 'GD'},
    {value: 'Guadeloupe', tc: 'GP'},
    {value: 'Guam', tc: 'GU'},
    {value: 'Guatemala', tc: 'GT'},
    {value: 'Guinea', tc: 'GN'},
    {value: 'Guinea-Bissau',tc: 'GW'},
    {value: 'Guyana', tc: 'GY'},
    {value: 'Haiti', tc: 'HT'},
    {value: 'Honduras', tc: 'HN'},
    {value: 'Hong Kong', tc: 'HK'},
    {value: 'Hungary', tc: 'HU'},
    {value: 'Iceland', tc: 'IS'},
    {value: 'India', tc: 'IN'},
    {value: 'Indonesia', tc: 'ID'},
    {value: 'Ireland', tc: 'IE'},
    {value: 'Israel', tc: 'IL'},
    {value: 'Italy', tc: 'IT'},
    {value: 'Jamaica', tc: 'JM'},
    {value: 'Japan', tc: 'JP'},
    {value: 'Jersey', tc: 'JE'},
    {value: 'Jordan', tc: 'JO'},
    {value: 'Kazakhstan', tc: 'KZ'},
    {value: 'Kenya', tc: 'KE'},
    {value: 'Kiribati', tc: 'KI'},
    {value: 'Korea', tc: 'KR'},
    {value: 'Kuwait', tc: 'KW'},
    {value: 'Kyrgyzstan', tc: 'KG'},
    {value: 'Laos', tc: 'LA'},
    {value: 'Latvia', tc: 'LV'},
    {value: 'Lebanon', tc: 'LB'},
    {value: 'Lesotho', tc: 'LS'},
    {value: 'Liberia', tc: 'LR'},
    {value: 'Libya', tc: 'LY'},
    {value: 'Liechtenstein', tc: 'LI'},
    {value: 'Lithuania', tc: 'LT'},
    {value: 'Luxembourg', tc: 'LU'},
    {value: 'Macao', tc: 'MO'},
    {value: 'Madagascar', tc: 'MG'},
    {value: 'Malawi', tc: 'MW'},
    {value: 'Malaysia', tc: 'MY'},
    {value: 'Maldives', tc: 'MV'},
    {value: 'Mali', tc: 'ML'},
    {value: 'Malta', tc: 'MT'},
    {value: 'Martinique', tc: 'MQ'},
    {value: 'Mauritania', tc: 'MR'},
    {value: 'Mauritius', tc: 'MU'},
    {value: 'Mexico', tc: 'MX'},
    {value: 'Micronesia', tc: 'FM'},
    {value: 'Moldova', tc: 'MD'},
    {value: 'Monaco', tc: 'MC'},
    {value: 'Mongolia', tc: 'MN'},
    {value: 'Montenegro', tc: 'ME'},
    {value: 'Montserrat', tc: 'MS'},
    {value: 'Morocco', tc: 'MA'},
    {value: 'Mozambique', tc: 'MZ'},
    {value: 'Myanmar', tc: 'MM'},
    {value: 'Namibia', tc: 'NA'},
    {value: 'Nauru', tc: 'NR'},
    {value: 'Nepal', tc: 'NP'},
    {value: 'Netherlands', tc: 'NL'},
    {value: 'New Caledonia', tc: 'NC'},
    {value: 'New Zealand', tc: 'NZ'},
    {value: 'Nicaragua', tc: 'NI'},
    {value: 'Niger', tc: 'NE'},
    {value: 'Nigeria', tc: 'NG'},
    {value: 'Niue', tc: 'NU'},
    {value: 'Norway', tc: 'NO'},
    {value: 'Oman', tc: 'OM'},
    {value: 'Pakistan', tc: 'PK'},
    {value: 'Palau', tc: 'PW'},
    {value: 'Palestine ', tc: 'PS'},
    {value: 'Panama', tc: 'PA'},
    {value: 'Papua New Guinea', tc: 'PG'},
    {value: 'Paraguay', tc: 'PY'},
    {value: 'Peru', tc: 'PE'},
    {value: 'Philippines', tc: 'PH'},
    {value: 'Poland', tc: 'PL'},
    {value: 'Portugal', tc: 'PT'},
    {value: 'Puerto Rico', tc: 'PR'},
    {value: 'Qatar', tc: 'QA'},
    {value: 'Romania', tc: 'RO'},
    {value: 'Russia', tc: 'RU'},
    {value: 'Rwanda', tc: 'RW'},
    {value: 'Saint Kitts', tc: 'KN'},
    {value: 'Saint Lucia', tc: 'LC'},
    {value: 'Samoa', tc: 'WS'},
    {value: 'San Marino', tc: 'SM'},
    {value: 'Saudi Arabia', tc: 'SA'},
    {value: 'Senegal', tc: 'SN'},
    {value: 'Serbia', tc: 'RS'},
    {value: 'Seychelles', tc: 'SC'},
    {value: 'Sierra Leone', tc: 'SL'},
    {value: 'Singapore', tc: 'SG'},
    {value: 'Slovakia', tc: 'SK'},
    {value: 'Slovenia', tc: 'SI'},
    {value: 'Solomon Islands', tc: 'SB'},
    {value: 'Somalia', tc: 'SO'},
    {value: 'South Africa', tc: 'ZA'},
    {value: 'Spain', tc: 'ES'},
    {value: 'Sri Lanka', tc: 'LK'},
    {value: 'Sudan', tc: 'SD'},
    {value: 'Suriname', tc: 'SR'},
    {value: 'Sweden', tc: 'SE'},
    {value: 'Switzerland', tc: 'CH'},
    {value: 'Syria', tc: 'SY'},
    {value: 'Taiwan', tc: 'TW'},
    {value: 'Tajikistan', tc: 'TJ'},
    {value: 'Tanzania', tc: 'TZ'},
    {value: 'Thailand', tc: 'TH'},
    {value: 'Togo', tc: 'TG'},
    {value: 'Tonga', tc: 'TO'},
    {value: 'Trinidad', tc: 'TT'},
    {value: 'Tunisia', tc: 'TN'},
    {value: 'Turkey', tc: 'TR'},
    {value: 'Turkmenistan', tc: 'TM'},
    {value: 'Tuvalu', tc: 'TV'},
    {value: 'Uganda', tc: 'UG'},
    {value: 'Ukraine', tc: 'UA'},
    {value: 'UAE', tc: 'AE'},
    {value: 'United Kingdom', tc: 'GB'},
    {value: 'United States', tc: 'US'},
    {value: 'Uruguay', tc: 'UY'},
    {value: 'Uzbekistan', tc: 'UZ'},
    {value: 'Vanuatu', tc: 'VU'},
    {value: 'Venezuela', tc: 'VE'},
    {value: 'Vietnam', tc: 'VN'},
    {value: 'Virgin Islands, British', tc: 'VG'},
    {value: 'Virgin Islands, U.S.', tc: 'VI'},
    {value: 'Western Sahara', tc: 'EH'},
    {value: 'Yemen', tc: 'YE'},
    {value: 'Zambia', tc: 'ZM'},
    {value: 'Zimbabwe', tc: 'ZW'},
]
