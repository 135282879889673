/* eslint-disable no-underscore-dangle */
import { IGW_DISTRIBUTION_TYPECODES } from '../beneficiary-constants'
import { CS_PARTY_TYPECODES, CS_PARTY_TYPES } from '../constants/BENEFICIARY_OTHER_OPTIONS'
import { BENEFICIARIES_ROLE_VALUES, BENEFICIARY_ROLES, BENEFICIARY_ROLE_LABELS2, ALL_ROLES } from '../constants/ROLES'
import { CLIENT_SERIALIZABLE_PROPERTIES } from '../constants/SERIALIZABLE_PROPERTIES'
import { ROLE_CONSTANTS } from '../relationships/role-relationships-service'
import { IEditableBeneficiary, ISerializabeProperty, ITypeCodeValue } from '../types'
import AbstractTransformableData from './abstract-transformable-data'
import { BeneficiaryAddress } from './address'
import { EntityName } from './entity-name'
import { PhoneNumber } from './phone'
import { PhoneList } from './phone-list'
import { BeneficiaryShare } from './share'
import { TypeCodeValue } from './type-code-value'
import { toDateString } from '../../../utils/date-utils'
/**
 * These are the type of object that can be passed to the constructor.
 */
export const BENEFICIARY_SOURCE_OBJECT_TYPES = {
  CURRENT_BENEFICIARY: 'CURRENT_BENEFICIARY',
  BENEFICIARY_INSTANCE: 'BENEFICIARY_INSTANCE',
  ASSOCIATED_PARTY: 'ASSOCIATED_PARTY',
  NEW_PARTY_OPTION: 'NEW_PARTY_OPTION',
}

/**
 * Represents a single Beneficiary
 */
export class Beneficiary extends AbstractTransformableData implements IEditableBeneficiary {
  id: string
  partyType: string | undefined
  trustDate: Date | null | undefined
  isNew: boolean = true
  dob: Date | undefined
  toBeDeleted: boolean = false
  name: EntityName = new EntityName()
  taxID: string | null | undefined
  $$hashKey: string | undefined
  isExistingParty: boolean
  ssn: string
  ssnStatus: boolean
  gender: TypeCodeValue
  address: BeneficiaryAddress = new BeneficiaryAddress()
  isEditMode: boolean = false
  roleId: string
  estateName: string
  shareDistribution: BeneficiaryShare
  phoneList: PhoneList
  phoneNumber: PhoneNumber
  percentageAmountBackup: number | undefined
  isInsuredAnnuitant: boolean = false
  roles: any[]
  role: any
  sortOrder: Number
  govtIDStat: TypeCodeValue
  actions: string
  isAssignee: boolean

  private _isDirty: boolean = false

  /**
   *
   * @param BENEFICIARY_OTHER_OPTIONS
   * @param BENEFICIARY_ROLES
   * @param roleId
   * @param inData
   * @param objectType
   */
  constructor(roleId: string, inData?: any | undefined, objectType?: string) {
    super(['toBeDeleted', 'partyType', 'phoneNumber', 'sortOrder', 'role', 'shareDistribution', 'name', 'gender', 'inferredPartyType'], ['isInsuredAnnuitant', 'isOwner', 'partyTypeCode'])

    // console.info('Creating new Beneficiary: objectType: %s \n', objectType, inData.constructor.name)
    // Uncomment the following two line to log a shallow stack trace.
    // const temp = new Error()
    // console.log(temp.stack?.split('\n').splice(1, 2)?.join(' <= '))

    // UX Related Flags
    this.roleId = roleId ? roleId : inData?.role?.tc
    this.toBeDeleted = false
    this.isInsuredAnnuitant = inData?.isInsuredAnnuitant

    // Hydrate object based on objectType
    switch (objectType) {
      case BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY:
        // UX related flags
        this.isNew = false
        this.isExistingParty = false

        // Normalize and map properties to this instance
        this.id = inData.id
        this.partyTypeCode = new TypeCodeValue(inData.partyTypeCode)
        if (!this.partyTypeCode.hasTypeCode) this.partyTypeCode = this.inferredPartyType(inData)
        this.name = new EntityName(inData, this.partyTypeCode)
        this.shareDistribution = new BeneficiaryShare(inData.role)
        this.address = new BeneficiaryAddress(inData?.role?.address)
        this.dob = inData.birthDate ? new Date(inData.birthDate) : new Date(inData.dob)
        if (this.dob.toString() === 'Invalid Date') {
          this.dob = undefined
        } else {
          this.dob.setHours(this.dob.getHours() + this.dob.getTimezoneOffset() / 60)
        }
        this.gender = new TypeCodeValue(inData.genderTypeCode)
        this.phoneList = new PhoneList(inData.phones) // this._sortPhoneList(inData.phones ? inData.phones.map(phone => new PhoneNumber(phone)) : [new PhoneNumber(DEFAULT_PHONE)])
        this.phoneNumber = this.phoneList[0]
        this.ssn = inData.ssn
        this.ssnStatus = inData.ssnStatus
        this.roles = inData.roles
        this.role = inData.role
        if (inData.role?.address) this.role.address = new BeneficiaryAddress(inData.role.address)
        if (this.role) this.role.role = ALL_ROLES[this.roleId]

        this.sortOrder = inData.sortOrder
        this.actions = inData.actions
        this.isAssignee = false
        this.partyType = inData.partyType
        break
      case BENEFICIARY_SOURCE_OBJECT_TYPES.ASSOCIATED_PARTY:
        // UX related flags
        this.isNew = true // Not sure if this should be true or false
        this.isExistingParty = true // Not sure this is required

        // Normalize and map properties to this instance
        this.id = inData.id
        this.partyTypeCode = new TypeCodeValue(inData.partyTypeCode)
        this.name = new EntityName(inData, this.partyTypeCode)
        // this.relationship = new TypeCodeValue(inData.relationDescription)
        this.dob = inData.birthDate ? new Date(inData.birthDate) : undefined
        if (this.dob) this.dob.setHours(this.dob.getHours() + this.dob.getTimezoneOffset() / 60)
        this.gender = new TypeCodeValue(inData.genderTypeCode)
        this.address = new BeneficiaryAddress(inData?.address?.[0])
        this.phoneList = new PhoneList(inData.phones)
        this.phoneNumber = this.phoneList.all[0] // @deprecated
        this.shareDistribution = new BeneficiaryShare(undefined, this.roleId)
        this.ssn = inData.ssn
        this.ssnStatus = inData.ssnStatus
        break

      case BENEFICIARY_SOURCE_OBJECT_TYPES.NEW_PARTY_OPTION:
        // UX related flags
        this.isNew = true
        this.isExistingParty = false

        if (inData) {
          this.partyType = inData?.partyTypeCode?.tc
          this.partyTypeCode = new TypeCodeValue(inData.partyTypeCode)
          this.name = new EntityName({ fullName: '', partyTypeCode: inData?.partyTypeCode })
          this.shareDistribution = new BeneficiaryShare(inData)
          this.address = new BeneficiaryAddress()
          this.phoneList = new PhoneList([])   // this._sortPhoneList([new PhoneNumber(new PhoneNumber(DEFAULT_PHONE))])
          this.phoneNumber = this.phoneList.all[0] // @deprecated
          this.ssnStatus = false
          this.ssn = ""
        } else {
          this.partyTypeCode = new TypeCodeValue(CS_PARTY_TYPECODES[CS_PARTY_TYPES.PERSON])
          this.name = new EntityName({ fullName: '', partyTypeCode: this.partyTypeCode })
          this.partyType = this.partyTypeCode.tc
          this.address = new BeneficiaryAddress()
          this.phoneList = new PhoneList([])
          this.phoneNumber = this.phoneList.all[0]
          this.ssnStatus = false
          this.ssn = ""
        }

        // Adjustments for Final Beneficiaries
        if (this.roleId === BENEFICIARY_ROLES.FINAL) {
          this.shareDistribution.type = new TypeCodeValue(IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE)
          this.shareDistribution.relationDescription = new TypeCodeValue({ tc: '1012300011', value: 'Estate' })
          this.partyTypeCode = new TypeCodeValue(CS_PARTY_TYPECODES[CS_PARTY_TYPES.TEXT])
        }
        break

      case BENEFICIARY_SOURCE_OBJECT_TYPES.BENEFICIARY_INSTANCE:
      default:
        /**
         * An instance of the Beneficiary class
         */

        Object.keys(inData || {}).forEach((key) => {
          const value = inData[key]

          if (typeof value === 'object') {
            switch (value.constructor.name) {
              case 'EntityName':
                this[key] = new EntityName(value)
                break

              case 'BeneficiaryAddress':
                this[key] = new BeneficiaryAddress(value)
                break

              case 'TypeCodeValue':
                this[key] = new TypeCodeValue(value)
                break

              case 'BeneficiaryShare':
                this[key] = new BeneficiaryShare(value)
                break

              // case 'PhoneNumber':
              //   this[key] = new PhoneNumber(value, 0)
              //   break

              case 'PhoneList':
                this[key] = new PhoneList(value)
                break

              case 'Date':
                const tmpD: Date = new Date(value) // Create new date object from value

                this[key] = tmpD
                break
              default:
                this[key] = value
                break

            }
          } else {
            this[key] = value
          }
        })

        if (!this.shareDistribution) this.shareDistribution = new BeneficiaryShare(this.role)

        break
    }
  }
  maskedSSN: string

  /**
   * ssnStatus -- UX property govtIDStat
   */

  get ssnStatusLabel(): string {
    return this.ssnStatus ? 'Yes' : 'No'
  }
  // set ssnStatus(value: boolean) {
  //   this._ssnStatus = value ? new TypeCodeValue({ tc: '1', value: 'Verified' }) : new TypeCodeValue({ tc: '2', value: 'Unverified' })
  // }

  // get ssnStatus(): boolean {
  //   return this._ssnStatus?.tc === '1'
  // }

  /**
   * @deprecated dollarAmount -- UX property
   */
  set dollarAmount(value: number | '' | undefined) {
    if (typeof value === 'number') {
      this.shareDistribution.amount = Number(value)
    } else {
      this.shareDistribution.amount = value
    }

    // console.log('dollarAmount::set', this.roleId, this.id, this.shareDistribution, this.isFlatAmount)

  }

  get dollarAmount(): number | '' | undefined {
    // // Typically we want the shareDistribution.amount, but only when the type id Flat Amount (5)
    // // return this.shareDistribution?.type?.tc === '5' ? this.shareDistribution.amount : undefined
    // console.log('this.shareDistribution', this.shareDistribution)
    return this.isFlatAmount ? this.shareDistribution.amount : undefined
  }

  /**
   * @deprecated percentageAmount -- UX Property
   */
  set percentageAmount(value: number | string | undefined) {
    if (typeof value === 'undefined') {
      this.shareDistribution.amount = undefined
    } else {
      const value2: number = Number(value)

      this.shareDistribution.amount = Number(value2)
      this.percentageAmountBackup = value2
    }
  }

  get percentageAmount(): number | string | undefined {
    return this.isPercentage ? this.shareDistribution.amount : undefined
  }

  /**
   * partyTypeCode
   */
  private _partyTypeCode: TypeCodeValue
  set partyTypeCode(value: TypeCodeValue) {
    this._partyTypeCode = new TypeCodeValue(value)
    this.name.updatePartyTypeCode(this._partyTypeCode)
  }

  get partyTypeCode(): TypeCodeValue {
    return this._partyTypeCode
  }

  get isPerson(): boolean {
    return this.partyTypeCode.tc === CS_PARTY_TYPES.PERSON
  }

  get isBeneficiary(): boolean {
    return BENEFICIARIES_ROLE_VALUES.includes(this.roleId)
  }

  // shareDistribution helper properties
  get isEqualShare(): boolean {
    return this.shareDistribution?.type?.tc === IGW_DISTRIBUTION_TYPECODES.EQUAL_SHARE.tc
  }

  get isFlatAmount(): boolean {
    return this.shareDistribution?.type?.tc === IGW_DISTRIBUTION_TYPECODES.FLATAMOUNT_SHARE.tc
  }

  get isPercentage(): boolean {
    return this.shareDistribution?.type?.tc === IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE.tc
  }

  get isFinalBeneficiary(): boolean {
    return this.roleId === BENEFICIARY_ROLES.FINAL
  }

  get isEstate(): boolean {
    return this.shareDistribution?.relationDescription?.tc === ROLE_CONSTANTS.RELATION_NAMES.ESTATE
  }

  get hasRelationship(): boolean {
    return Boolean(this.shareDistribution?.relationDescription?.tc)
  }

  get hasPartyType(): boolean {
    return Boolean(this._partyTypeCode) && this.isEditMode
  }

  get isDirty(): boolean {
    return this._isDirty || (!this.phoneList.hasExistingNumbers && this.phoneList.isDirty)
  }

  set isDirty(value: boolean) {
    this._isDirty = value
  }

  get shareDisplayName(): string {
    if (this.isEqualShare) {
      return 'Equal'
    } else if (this.isPercentage) {
      return String(this.shareDistribution.amount) + '%'
    }

    return this.shareDistribution.amount ? '$' + String(this.shareDistribution.amount) : ''
  }

  get fullName(): string {
    return this.name.fullName
  }

  inferredPartyType(inData: any): TypeCodeValue {
    console.log(inData)
    if (inData.lastName || inData.firstName) return new TypeCodeValue(CS_PARTY_TYPECODES[CS_PARTY_TYPES.PERSON])
    if (inData.fullName && inData.address[0]) return new TypeCodeValue(CS_PARTY_TYPECODES[CS_PARTY_TYPES.ORGANIZATION])
    if (inData.fullName && !inData.address[0]) return new TypeCodeValue(CS_PARTY_TYPECODES[CS_PARTY_TYPES.TEXT])
  }

  _serializeDate(datePropName: string, nDate: Date): string {
    if (datePropName === 'dob') { // NOTE: Just being extra safe.
      return toDateString(nDate)
    }

    return nDate.toISOString()
  }

  _serializeClient(originalBene: Beneficiary | undefined): any {
    const clientChange = { operation: '', payloadData: {} as any }
    // Don't add a client change if the party is an existing party being added to a role.
    if ((this.isNew && this.isExistingParty && !this.isDirty) || this.toBeDeleted) return clientChange

    if (originalBene) {
      CLIENT_SERIALIZABLE_PROPERTIES.forEach((prop: ISerializabeProperty) => {
        const propName: string = prop.gwName ? prop.gwName : prop.name

        if (prop.name !== 'id') {
          switch (prop.type) {
            case 'TypeCodeValue':
              if (this[prop.name]?.tc !== originalBene[prop.name]?.tc) {
                clientChange.payloadData[propName] = (new TypeCodeValue(this[prop.name])).serialize()
              }
              break
            case 'EntityName':
              if (this.name.hasChange(originalBene)) {
                clientChange.payloadData = Object.assign({}, clientChange.payloadData, this.name.serialize(originalBene.name))
              }
              break

            case 'date':
              if (this[prop.name] && this[prop.name].toISOString() !== originalBene[prop.name]?.toISOString()) {
                clientChange.payloadData[propName] = this._serializeDate(prop.name, this[prop.name])
              }
              break
            case 'string':
            default:
              if (this[prop.name] !== undefined && this[prop.name] !== originalBene[prop.name]) {
                clientChange.payloadData[propName] = this[prop.name]
              }
              break
          }
        }
      })
    } else {
      CLIENT_SERIALIZABLE_PROPERTIES.forEach((prop: ISerializabeProperty) => {
        const propName: string = prop.gwName ? prop.gwName : prop.name
        const propValue: any = this[prop.name]

        if (prop.name !== 'id' && propValue !== undefined) {

          switch (prop.type) {
            case 'TypeCodeValue':
              clientChange.payloadData[propName] = (new TypeCodeValue(this[prop.name])).serialize()
              break
            case 'EntityName':
              clientChange.payloadData = Object.assign({}, clientChange.payloadData, this.name.serialize(undefined))
              break
            case 'date':
              clientChange.payloadData[propName] = this._serializeDate(prop.name, this[prop.name])
              break
            case 'string':
            default:
              clientChange.payloadData[propName] = this[prop.name]
              break
          }
        }
      })

      clientChange.operation = 'update'
    }

    // Forcing clients beneficiary role on the policy to be sent.
    // This value will be overwritten if this.isNew and not this.isExistingParty
    if (Object.keys(clientChange.payloadData).length > 0) clientChange.payloadData.roles = [{ tc: this.roleId }]

    if (this.isNew && !this.isExistingParty) {
      clientChange.operation = 'add'

      clientChange.payloadData = Object.assign({}, clientChange.payloadData, this.name.serialize(undefined)) // because name can be either fullName, or first, middle, last
      clientChange.payloadData.partyTypeCode = this.partyTypeCode.serialize()
      clientChange.payloadData.birthDate = this.dob ? toDateString(this.dob) : undefined
      clientChange.payloadData.gender = this.gender?.serialize()
      clientChange.payloadData.ssn = this.ssn
      clientChange.payloadData.ssnStatus = this.ssnStatus
      clientChange.payloadData.roles = [
        this.shareDistribution.serialize(originalBene, this.roleId, this.address),
      ]

      if (this.phoneList.isDirty) clientChange.payloadData.phones = this.phoneList.serialize()?.adds

    } else if (!this.isNew && !this.toBeDeleted) {
      clientChange.operation = 'update'
    }

    if (clientChange.operation === '' || Object.keys(clientChange.payloadData).length === 0) return

    clientChange.payloadData.id = this.id

    return clientChange
  }

  _serializeRole(originalBene: Beneficiary | undefined): any {
    const roleChange = { operation: '', payloadData: {} as any }

    // clientChange.payloadData.address = new BeneficiaryAddress(inData.address?.[0])
    // clientChange.payloadData.distributionOptionDesc = this.distributionOptionDesc

    // Role serialization
    if (this.isNew && this.isExistingParty) {
      roleChange.operation = 'add'
      roleChange.payloadData = {
        id: this.id,
        roles: [
          this.shareDistribution.serialize(originalBene, this.roleId, this.address),
        ],
      }
    } else if (!this.isNew && !this.toBeDeleted) {
      const tmpRole: any = this.shareDistribution.serialize(originalBene, this.roleId, this.address) // returns undefined if nothing has changed.

      if (tmpRole) {
        tmpRole.tc = this.roleId
        roleChange.operation = 'update'
        roleChange.payloadData = {
          id: this.id,
          roles: [tmpRole],
        }
      }
    } else if (this.toBeDeleted) {
      roleChange.operation = 'delete'
      roleChange.payloadData = {
        id: this.id,
        roles: [
          {
            tc: this.roleId,
          },
        ],
      }
    }

    return roleChange.operation === '' ? null : roleChange
  }

  /**
   * Currently you cannot delete a phone number. You can only add or update an address on
   * an existing beneficiary.  When the beneficiary is a new one, then the phone number
   * will be added to the clientChange payload.
   *
   * Return an array of phoneChanges, or null if there aren't any. Returns an array because
   * in the future we will need to support multiple phone #. This will make the implementation
   * easier when the time comes.
   *
   * @param originalBene
   * @returns
   */
  _serializePhoneNumbers(_originalBene: Beneficiary | undefined): any {
    const phoneChanges: any[] = []

    if (!this.phoneList.hasExistingNumbers && !this.phoneList.hasDeleted) return null
    if (this.isNew) return null // New bennies' phone number are handled in the client serializer.

    const serializedNumbers = this.phoneList.serialize()

    // if (originalBene && !this.phoneNumber.hasChange(originalBene)) return
    // this.phoneList.forEach((phone, index) => {
    //   console.log(phone.completeNumber, phone.hasChange(originalBene, index))
    // })

    if (serializedNumbers.adds.length > 0) {
      phoneChanges.push({
        operation: 'add',
        payloadData: { id: this.id, phones: serializedNumbers.adds },
      })
    }

    if (serializedNumbers.updates.length > 0) {
      phoneChanges.push({
        operation: 'update',
        payloadData: { id: this.id, phones: serializedNumbers.updates },
      })
    }

    if (serializedNumbers.deletes.length > 0) {
      phoneChanges.push({
        operation: 'delete',
        payloadData: { id: this.id, phones: serializedNumbers.deletes },
      })
    }

    return phoneChanges.length === 0 ? null : phoneChanges
  }

  /**
   * Returns plain javascript version of `this`.
   *
   * @returns any
   */
  serialize(originalBene: Beneficiary | undefined): any {
    const serializedObj: any = this.isNew && !this.isExistingParty ? {
      clientChange: this._serializeClient(originalBene),
      phoneChanges: [],
      roleChange: [],
    } : {
      clientChange: this._serializeClient(originalBene),
      phoneChanges: this._serializePhoneNumbers(originalBene),
      roleChange: this._serializeRole(originalBene),
    }

    return serializedObj
  }
}
