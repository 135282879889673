import { IController } from 'angular'

class RidersController implements IController {
  static $inject: any = []

  riderData: any

  constructor() {}

  $onInit(): void {
    console.log(this.riderData)
  }

}

export const ridersComponentConfig = {
  templateUrl: 'app/policy/riders/riders.html',
  controller: RidersController,
  controllerAs: 'ridersCtrl',
  bindings: {
    riderData: '<',
  },
}
