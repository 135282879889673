import { IAddressCountry } from '../../address/types'
import { IBeneficiaryAddress, IPhoneNumber } from '../types'
import AbstractTransformableData from './abstract-transformable-data'
import { Beneficiary } from './beneficiary'
import { TypeCodeValue } from './type-code-value'

export class BeneficiaryAddress extends AbstractTransformableData implements IBeneficiaryAddress {
  id: string | undefined
  attention?: string | undefined
  line1: string
  line2?: string | undefined
  line3?: string
  city: string
  state: string
  zip5: string
  zip4: string
  addressTypeCode?: TypeCodeValue | undefined
  addressCountry?: IAddressCountry | undefined
  phoneNumber?: IPhoneNumber | undefined
  isNew: boolean = false
  addressScrubInd?: boolean = false
  barCodeCheckDigit?: string = ''
  startDate?: Date

  constructor(addressData?: any) {
    super([], [])

    if (addressData) {
      const addressType: any = addressData?.constructor.name

      switch (addressType) {
        case 'BeneficiaryAddress':
          Object.keys(addressData).forEach((key) => {
            if (['addressTypeCode', 'addressCountry'].includes(key)) {
              this[key] = new TypeCodeValue(addressData[key])
            } else if (['startDate'].includes(key)) {
              this.startDate = new Date(addressData.startDate)
            } else {
              this[key] = addressData[key]
            }
          })
        break

        case 'AddressValidationResult':
          console.log(addressData)
          this.id = addressData.id
          this.line1 = addressData.line1
          this.line2 = addressData.line2
          this.line3 = addressData.line3
          this.attention = addressData.attention
          this.city = addressData.city
          this.state = addressData.state
          this.zip5 = addressData.zip5
          this.zip4 = addressData.zip4
          this.addressTypeCode = new TypeCodeValue(addressData.addressTypeCode)
          this.addressCountry = new TypeCodeValue(addressData.addressCountry)
          this.addressScrubInd = addressData.addressScrubInd
          this.barCodeCheckDigit = addressData.barCodeCheckDigit
          break

        default:
          const zipParts = addressData.zip?.split('-')

          this.id = addressData.primaryAddressId
          this.line1 = addressData.line1
          this.line2 = addressData.line2
          this.line3 = addressData.line3
          this.attention = addressData.attentionLine
          this.city = addressData.city
          this.state = addressData.state
          this.zip5 = addressData.zip5 || zipParts?.[0]
          this.zip4 = addressData.zip4 || zipParts?.[1]
          this.addressTypeCode = new TypeCodeValue(addressData.addressTypeCode)
          this.addressCountry = new TypeCodeValue(addressData.addressCountry?.tc ? addressData.addressCountry : { value: 'United States of America', tc: '1' }) // Note: This might not be correct.
          this.addressScrubInd = addressData.addressScrubInd
          this.barCodeCheckDigit = addressData.barCodeCheckDigit
          this.startDate = new Date(addressData.startDate)

      }
    } else {
      this.line1 = ''
      // this.line2 = ''
      this.city = ''
      this.state = ''
      this.zip5 = ''
      this.zip4 = ''
      this.addressTypeCode = new TypeCodeValue({tc: '1', value: 'Home'})
      this.addressCountry = new TypeCodeValue({ value: 'United States of America', tc: '1' })
      this.isNew = true
    }
  }

  /**
   * Because ngForm sets empty input values to undefined
   *
   * @param value
   * @returns
   */
  _emptyString(value) {
    return value ?? ''
  }

  /**
   * Returns true if all address fields are empty.
   *
   */
  get isClean() {
    const combinedProperties = this._emptyString(this.line1) + this._emptyString(this.line2) + this._emptyString(this.city) + this._emptyString(this.state) + this._emptyString(this.zip5)

    return combinedProperties === ''
  }

  get nonReqFieldsClean() {
    const combinedProperties = this._emptyString(this.line3) + this._emptyString(this.zip4) + this._emptyString(this.attention)

    return combinedProperties === ''
  }

  get zip(): string {
    return `${this.zip5}${this.zip4 ? '-' + this.zip4: ''}`
  }

  hasChange(originalBene: Beneficiary | undefined) {
    const changed = ['line1', 'line2', 'line3', 'attention', 'city', 'state', 'zip5', 'zip4', 'addressTypeCode', 'addressCountry'].reduce((hasChanged, key) => {
      const thisVal = this[key]
      const origVal = originalBene?.address?.[key]
      const isTypeCode = thisVal instanceof TypeCodeValue

      if (!hasChanged && isTypeCode ? thisVal?.tc !== origVal.tc : thisVal !== origVal) hasChanged = true

      // console.log(':', key, isTypeCode, thisVal, origVal, thisVal !== origVal)

      return hasChanged
    }, false)

    return changed
  }

  serialize(): any {
    /*
    {
        "prefAddress": true,
        "line1": "1355 1ST AVE FL 17",
        "city": "NEW YORK",
        "state": "NY",
        "zip": "10021-4403",
        "addressTypeCode": {
            "value": "Unknown",
            "tc": "0"
        },
        "primaryAddressId": "Address_7_5d727c03-6485-477d-929e-d2dda0168efc"
    }
    */

    const final = {
      primaryAddressId: this.id,
      line1: this.line1,
      line2: this.line2,
      line3: this.line3,
      attention: this.attention,
      city: this.city,
      state: this.state,
      zip5: this.zip5,
      zip4: this.zip4 ? this.zip4 : undefined, // Don't send zip4 property to the gateway if it is empty.
      addressTypeCode: this.addressTypeCode?.serialize(),
      addressCountry: new TypeCodeValue(this.addressCountry).serialize(), // @TODO: Not sure why this is needed, but not coming through as TypeCodeValue when selected from dropdown.
      addressScrubInd: this.addressScrubInd,
      barCodeCheckDigit: this.barCodeCheckDigit,
    }
    const serailizable: boolean = ['line1', 'city', 'state', 'zip5'].reduce((goodAddress: boolean, propKey: string): boolean => {
      const propValue: string = final[propKey]

      if (goodAddress && propValue === '') goodAddress = false

      return goodAddress
    }, true)

    // console.log('Address serailizable: %s, ', serailizable, final)
    return serailizable ? final : null
  }

  // transform(): any {
  //   const transformed: any = {}

  //   Object.keys(this).forEach((key: string) => {

  //     if (!['index', 'order', 'toBeDeleted'].includes(key) && !key.startsWith('_') && !key.startsWith('is') && !key.startsWith('$$')) {
  //       transformed[key] = this[key]
  //     }
  //   })

  //   return JSON.parse(JSON.stringify(transformed))
  // }
}
