import {CountService} from './count-service'
export class SearchRoutes {

  static $inject = ['routerHelper']

  constructor (routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  initSearchState ($rootScope) {
    $rootScope.pageTitle = 'InSight' // title is overridden for subsequently selected tabs
    $rootScope.mobileHeaderTitle = '' // is empty to show the Viewing By dropdown
    $rootScope.state = 'search'
  }

  capitalize (str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  getStates () {
    return [{
      state: 'search',
      config: {
        abstract: true,
        url: '/myclients/search',
        templateUrl: 'app/search/search-view.html',
        controller: 'SearchController',
        controllerAs: 'searchCtrl',
        data: {
          secure: true,
        },
        resolve: {
          messages: ['authService', (authService) => authService.getSystemMessages()],
          inforcePolicyCount: ['countService', (countService) => {
            return countService.getInforcePolicyCount()
          }],
          pendingPolicyCount: ['countService', (countService: CountService) => {
            return countService.getPendingPolicyCount()
          }],
          unsubmittedApplicationCount: ['countService', (countService) => {
            return countService.getUnsubmittedCount()
          }],
          notPaidCount: ['countService', (countService: CountService) => {
            return countService.getIssueNotPaidCount()
          }],
          canAccessResponse: ['authService', (authService) => authService.canAccess()],
          // unsubmittedApplicationCountOrig: ['searchService', (searchService) => searchService.getUnsubmittedApplicationCount().then((result) => result.unsubmittedApplicationCount)],
        },
        onEnter: ['navigationStack', function (navigationStack) {
          if (navigationStack) {
            navigationStack.reset()
          }
        }],
      },
    },
    {
      state: 'search.result',
      config: {

        url: '/result/:c', // /#/myclients/search/result/
        params: {
            // c: {array: true},
          c: { // <-- ParamDeclaration for 'nonUrlParam'
            type: 'string',
            value: '',
          },
          nonUrlCriteria: {
            type: 'string',
            value: '',
          },
        },
        templateUrl: 'app/search/result-view.html',
        controller: 'ResultController',
        controllerAs: 'resultCtrl',
        data: {
          secure: true,
        },
        onEnter: ['$rootScope', '$stateParams', ($rootScope) => {
          this.initSearchState($rootScope)
        }],
        onExit: ['$rootScope', ($rootScope) => {
          this.initSearchState($rootScope)
        }],
      },
    },
    {
      state: 'search.favorites',
      config: {
        url: '/favorites',
        templateUrl: 'app/search/favorites-view.html',
        controller: 'FavoritesController',
        controllerAs: 'favoritesCtrl',
        data: {
          secure: true,
        },
        onEnter: ['$rootScope', function ($rootScope) {
          $rootScope.state = 'favorites'
          $rootScope.pageTitle = 'Favorites'
          $rootScope.mobileHeaderTitle = 'Favorites'
        }],
      },
    },
    {
      state: 'search.unsubmitted',
      config: {
        url: '/unsubmitted',
        templateUrl: 'app/search/unsubmitted-applications-view.html',
        resolve: {
          counts: ['countService', (countService) => {
            return countService.getUnsubmittedCount(false)
          }],
          // counts2: ['unsubmittedApplicationsService', function (unsubmittedApplicationsService) {
          //   return unsubmittedApplicationsService.getCounts()
          // }],
        },
        controller: 'UnsubmittedApplicationsController',
        controllerAs: 'unsubCtrl',
        data: {
          secure: true,
        },
        onEnter: ['$rootScope', 'unsubmittedApplicationsService', function ($rootScope, unsubmittedApplicationsService) {
          let pageTitle = 'Unsubmitted'

          $rootScope.state = 'unsubmitted'
          $rootScope.pageTitle = pageTitle
          $rootScope.mobileHeaderTitle = pageTitle
          unsubmittedApplicationsService.resetData()

          return unsubmittedApplicationsService.nextPage()
        }],
      },
    },
    {
      state: 'search.policies',
      config: {
        url: '/policies/:source?lob&productType&applicationType&deliveryStatus',
        templateUrl: 'app/search/policies-view.html',
        resolve: {
          deliveryStatusCounts: ['$stateParams', 'pendingPolicyService', function ($stateParams, pendingPolicyService) {
            if ($stateParams.source === 'notpaid') {
              return pendingPolicyService.getDeliveryStatusCounts()
            }

            return null
          }],
          prodTypesResult: ['$q', '$stateParams', 'allPoliciesService',
            function ($q, $stateParams, allPoliciesService) {
              const params = {
                source: $stateParams.source,
                lineofbusiness: $stateParams.lob || 'Life',
              }

              if (params.lineofbusiness) {
                return allPoliciesService.getProductTypes(params)
                      .then(function (result) {
                        result.productTypes = allPoliciesService._checkProductTypesFromState($stateParams, result.productTypes)

                        return result
                      })
              }

              return $q.when({
                productTypes: [],
              })
            },
          ],
        },
        controller: 'PoliciesController',
        controllerAs: 'policiesCtrl',
        data: {
          secure: true,
        },
        onEnter: ['$rootScope', '$stateParams', ($rootScope, $stateParams) => {
          const pageTitle = $stateParams.source ? 'Policy List - ' + this.capitalize($stateParams.source) : 'Policy List'

          $rootScope.state = 'policies'
          $rootScope.pageTitle = pageTitle
          $rootScope.mobileHeaderTitle = pageTitle
        }],
      },
    },
    {
      state: 'search.closed',
      config: {
        url: '/:source',
        templateUrl: 'app/search/unsubmitted-applications-policies-view.html',
        resolve: {
          prodTypesResult: ['$q', '$stateParams', 'allPoliciesService',
            function ($q, $stateParams, allPoliciesService) {
              const params = {
                source: $stateParams.source,
                lineofbusiness: $stateParams.lob || 'Life',
              }

              if (params.lineofbusiness) {
                return allPoliciesService.getProductTypes(params)
                      .then(function (result) {
                        result.productTypes = allPoliciesService._checkProductTypesFromState($stateParams, result.productTypes)

                        return result
                      })
              }

              return $q.when({
                productTypes: [],
              })
            },
          ],

          policyStatusResult: ['allPoliciesService', (allPoliciesService) => {
            return allPoliciesService.getClosedPolicyStatuses('pending')
          },
          ],
        },
        controller: 'ClosedPoliciesController',
        controllerAs: 'policiesCtrl',
        data: {
          secure: true,
        },
        onEnter: ['$rootScope', '$stateParams', ($rootScope, $stateParams) => {
          let pageTitle = $stateParams.source ? 'Policy List - ' + this.capitalize($stateParams.source) : 'Policy List'

          $rootScope.state = 'closed'
          $rootScope.pageTitle = pageTitle
          $rootScope.mobileHeaderTitle = pageTitle
        }],
      },
    },
    {
      state: 'allPolicies',
      config: {
        url: '/myclients/all-policies?source&lob&productType&adressstate',
        templateUrl: 'app/search/all-policies-view.html',
        resolve: {
          prodTypesResult: ['$q', '$stateParams', 'allPoliciesService',
            function ($q, $stateParams, allPoliciesService) {
              if ($stateParams.lob) {
                return allPoliciesService.getProductTypes({
                  lineofbusiness: $stateParams.lob,
                  source: $stateParams.source,
                })
              }
              return $q.when({
                productTypes: [],
              })
            },
          ],
        },
        controller: 'AllPoliciesController',
        controllerAs: 'allPoliciesController',
        data: {
          secure: true,
        },
        onEnter: ['$rootScope', function ($rootScope) {
          $rootScope.pageTitle = 'All Policies'
          $rootScope.state = 'allPolicies'
        }],
      },
    }]
  }
}
