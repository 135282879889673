interface TaxDocument {
  docId: string
  docType: string
  docTypeTC: string
  docSuperType: string
  polNumber: string
  modDate: string // "MM/dd/yyyy"
  year: string
}

interface TaxDocuments {
  documents: TaxDocument[]
  numFound: number
}

export class TaxDocumentController {

  public taxDocuments: TaxDocuments
  private isMobilePlatform: boolean
  private apiRoot: string
  public error: string
  constructor(public policyService, private authService, isMobilePlatform, CONSTANTS, public utils) {
    this.apiRoot = CONSTANTS.apiRoot
    this.isMobilePlatform = isMobilePlatform
    // tslint:disable-next-line:no-unsafe-any
    this._loadDocuments(policyService)
      .then(() => this.error = '')
      .catch(error => this.error = error && error.data && error.data.code || 'Unknown!')
  }

  _loadDocuments(policyService): Promise<void> {
    return policyService.taxDocumentSearch()
      .then(result => {
        if (result.data && result.data.documents) {
          this.taxDocuments = result.data
        }
      })
  }

  downloadTaxDocument(policyId: string, docId: string, docYear: string, docType: string): void {
    let url = this._downloadTaxDocumentURL(docId, `TaxDocument${policyId}_${docId}.pdf`, this.authService.getSessionID())

    if (this.isMobilePlatform) {
      window.open(url, '_system')
    } else {
      window.open(url, '_blank')
    }

    if (window.dataLayer) {
      window.dataLayer.push(this.utils.buildGtmObject('TAX DOCUMENTS', 'DOCUMENT DOWNLOAD', {
        accountId: policyId,
        document: docType,
        year: docYear,
      }))
    }
  }

  _downloadTaxDocumentURL(docId: string, filename: string, sessionId: string): string {
    let apiRoot = this.apiRoot

    return `${apiRoot}document/policy/download/tax?docId=${docId}&filename=${filename}&sid=${sessionId}`
  }

}

TaxDocumentController.$inject = ['policyService', 'authService', 'isMobilePlatform', 'CONSTANTS', 'utils']
