/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { UploadApplicationService } from './upload-application.service'
export class NbaUploadController {
  static $inject: any = ['$interval', '$scope', 'FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES', 'REMOTE_STATUS_CODES', 'BOOTSTRAP_TYPES',
    'REMOTE_STATUS_TO_BOOTSTRAP_MAP', 'NBA_GA_EVENTS', 'uploadApplicationService', 'modalService', 'googleAnalyticsService', 'authService']

  UPLOAD_STATUS_POLLING_INTERVAL = 2500
  validFileTypes: string[] = [
    'application/pdf',
    'image/tiff',
    'image/png',
    'image/jpeg',
  ]

  chosenFiles: any[] = []
  maxFileSize: number = 20000000
  appData: any = {}
  maxAttachments: number = 10
  maxUploadSize: number = 50000000
  uploadState: any
  uiState: any = { formReady: false }
  defaults: any = {
    distributionChannel: '',
    hasNewYorkAppointments: false,
  }

  stopPolling: any = null // Promise
  fileStats: any = {}
  transactionId: string = ''
  session: any
  public defaultEmailAddress: string

  constructor(private $interval, private $scope, private FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES, private REMOTE_STATUS_CODES,
    private BOOTSTRAP_TYPES, private REMOTE_STATUS_TO_BOOTSTRAP_MAP, private NBA_GA_EVENTS, private uploadApplicationService: UploadApplicationService,
    private modalService, private googleAnalyticsService, private authService) {

    this.defaultEmailAddress = authService.getCurrentSession().emailAddress

  }

  get hasFiles() {
    return this.chosenFiles.length > 0
  }

  _resetUploadState() {
    this.uploadState = {
      progress: 0,
      status: '',
      type: this.BOOTSTRAP_TYPES.PRIMARY,
      active: true,
      errorCode: 0,
      errorMessage: '',
      remoteStatus: this.REMOTE_STATUS_CODES.IDLE,
      reason: '',
    }
  }

  resetForm() {
    // this.defaults.userEmail = this.defaultEmailAddress
    const isNotAgent = !this.authService.isInRole('AGENT')

    this.appData.isNotAgent = isNotAgent
    this.appData = Object.assign({ userEmail: this.defaultEmailAddress, isNotAgent }, this.defaults)
    this.$scope.appDataForm?.$setPristine()
    this.$scope.appDataForm?.$setUntouched()
    this.chosenFiles.splice(0, this.chosenFiles.length)
    this.$scope.$broadcast('all-files-removed')
    this._resetUploadState()
  }

  _updateUploadState(message, progress, remoteState, active, error?) {
    const type = this.REMOTE_STATUS_TO_BOOTSTRAP_MAP[remoteState]

    this.uploadState.progress = Math.round(progress) ?? 0
    this.uploadState.status = message
    this.uploadState.transactionId = this.transactionId
    this.uploadState.type = type
    this.uploadState.active = active
    this.uploadState.errorCode = error?.code ?? 0
    this.uploadState.messagePreamble = error?.messagePreamble
    this.uploadState.errorMessage = error?.message ?? ''
    this.uploadState.remoteStatus = remoteState
    this.uploadState.showModalButton = [this.REMOTE_STATUS_CODES.SENT, this.REMOTE_STATUS_CODES.FAILED].includes(remoteState)
  }

  onFormReady() {
    this.uiState.formReady = true
    this.defaults = Object.assign({}, this.appData)
  }

  browse(e) {
    this.$scope.$root.$broadcast('show-file-select-dialog', e)
  }

  closeSuccessAlert() {
    this.uploadState.remoteStatus = this.REMOTE_STATUS_CODES.IDLE
  }

  _uploadFailed(e) {
    const parameters = { reason: e.errorMessage }
    const gaData = Object.assign({}, this.NBA_GA_EVENTS.DOCUMENT_UPLOAD_FAIL, { parameters, errorCode: e.errorCode })

    this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)
  }

  _uploadSuccess(fileStats) {
    const gaData = Object.assign({}, this.NBA_GA_EVENTS.DOCUMENT_UPLOAD_SUCCESS, { parameters: fileStats })

    console.log('DOCUMENT_UPLOAD_SUCCESS', gaData)

    this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)

  }

  _updateUploadStats(appData, files) {
    const numberOfFiles = files.length
    const totalSize = Math.ceil(files.reduce((acc, file) => {
      acc += file.size

      return acc
    }, 0) / 1024)
    const issueState = appData.issuedInNewYork ? 'NY' : '49'
    const lineOfBusiness = 'Life'
    const finalAnswer = { numberOfFiles, totalSize, issueState, lineOfBusiness }

    return finalAnswer
  }

  upload(_e) {
    const files = this.chosenFiles.map(f => f.file)

    console.log('Uploading', this.appData, this.chosenFiles)
    this.$scope.$root.preventSpinnerFromShowing = true
    this._resetUploadState()
    this.fileStats = this._updateUploadStats(this.appData, files)
    this.modalService.open(this.FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES.UPLOAD_PROGRESS, {
      remoteStatusCodes: this.REMOTE_STATUS_CODES,
      uploadState: this.uploadState, appData: this.appData, onClose: () => this.resetForm()
    }, { size: 'lg', backdrop: 'static' })

    this.uploadApplicationService.upload('/nba-upload', this.appData, files, (progressInfo) => {
      if (progressInfo.lengthComputable) {
        const progress = (progressInfo.loaded / progressInfo.total) * 100

        if (progress === 100) {
          this._updateUploadState('Application received.', 100, this.REMOTE_STATUS_CODES.SENDING, true)
        } else {
          this._updateUploadState(`Uploading... ${this.uploadState.progress}% complete`, progress, this.REMOTE_STATUS_CODES.RECEIVING, true)
        }
      }
    }).then(uploadInfo => this.pollUploadStatus(uploadInfo))
      .catch(e => {
        this._uploadFailed(e)
        this._updateUploadState('Application upload failed', 100, 'error', false, { code: e.errorCode, message: e.errorMessage })

      }).then(() => {
        this.$scope.$root.preventSpinnerFromShowing = false
      })
  }

  _checkStatus(uploadInfo: any): any {
    this.$scope.$root.preventSpinnerFromShowing = true

    return this.uploadApplicationService.poll('/nba-upload', uploadInfo.transaction)
      .then((uploadStatus) => {
        switch (uploadStatus.state) {
          case this.REMOTE_STATUS_CODES.SENT:
            this._stopStatusChecking()
            this.transactionId = uploadInfo.transaction
            this._uploadSuccess(this.fileStats)
            this._updateUploadState('Application sent successfully.', 100, uploadStatus.state, false)
            break

          case this.REMOTE_STATUS_CODES.FAILED:
            const confirmationFailed = uploadStatus.reason === 'nba-confirmation'
            const title = confirmationFailed ? 'Failed to deliver confirmation email.' : 'Failed to deliver application.'
            const messagePreamble = confirmationFailed ? `Your application has been received, but the confirmation email could not be delivered to ${this.appData.userEmail}.` : ''

            this.transactionId = uploadInfo.transaction
            this._uploadFailed(uploadStatus)
            this._updateUploadState(title, 100, uploadStatus.state, false, { messagePreamble, code: uploadStatus.errorCode, message: uploadStatus.errorMessage })
            this._stopStatusChecking()
            break

          case this.REMOTE_STATUS_CODES.IDLE:
            this._stopStatusChecking()
            break

          default:
            this._updateUploadState('Sending Application.', 100, uploadStatus.state, true)
        }

        return uploadStatus
      }).catch(err => {
        this._uploadFailed(err)
        this._updateUploadState('Unexpected Error.', 100, this.REMOTE_STATUS_CODES.FAILED, false, { code: err.errorCode, message: err.errorMessage })
        this._stopStatusChecking()

        return err

      })

  }

  _stopStatusChecking() {
    this.$scope.$root.preventSpinnerFromShowing = false
    if (this.stopPolling) {
      this.$interval.cancel(this.stopPolling)
      this.stopPolling = null
    }
  }

  pollUploadStatus(uploadInfo) {
    console.log('uploadInfo', uploadInfo)

    if (!this.stopPolling && uploadInfo) {
      this._checkStatus(uploadInfo)  // Check immediately if not already polling.
        .then((status) => {
          if (status.state !== this.REMOTE_STATUS_CODES.FAIL) this.stopPolling = this.$interval(() => this._checkStatus(uploadInfo), this.UPLOAD_STATUS_POLLING_INTERVAL)
        })
    }
  }

  $onInit() {
    this.uploadApplicationService.getDistributionChannels().then((distributionChannel) => {
      this.defaults.distributionChannel = distributionChannel
      this.resetForm()
    })
  }
}
