
whatsNewService.$inject = ['$http', '$q', 'CONSTANTS']

function whatsNewService($http, $q, CONSTANTS) {
  const service = {}

  service.getContentPromise = function() {
    return $http.get(CONSTANTS.whatsNewURL)
        .then(function success(httpData) {
          return httpData.data || null
        })
        .catch(function failure(httpData) {
          console.log('Error retrieving Whats New content from server: ', httpData)
          return null
        })
  }

  service.getCodePromise = function() {
    return $http.get(CONSTANTS.whatsNewCodeURL)
        .then(function success(httpData) {
          return httpData.data || null
        })
        .catch(function failure(httpData) {
          console.log('Error retrieving Whats New code from server: ', httpData)
          return null
        })
  }

  service.getAllPromise = function() {
    return $q.all([service.getContentPromise(), service.getCodePromise()])
        .then(function(data) {
          return {
            content: data[0] || null,
            code: data[1] || null,
          }
        })
  }

  return service
}

export {whatsNewService}
