export {popupManager}

popupManager.$inject = []

function popupManager() {
  const self = this

  const getPopupName = function(id) {
    return id + 'Visible'
  }

  const popups = {}

  self.register = function(popupId) {
    const names = Object.keys(popups)
    const popupName = getPopupName(popupId)

    if (names[popupName] !== undefined) {
      return
    }
    popups[popupName] = false
  }

  self.setVisibility = function(popupId, val, event, options) {
    options = options || {}
    options.closeOthers = options.closeOthers || true

    if (options.closeOthers) {
      self.closeAll(event)
    }

    popups[getPopupName(popupId)] = val
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
  }

  self.isOpen = function(popupId) {
    return popups[getPopupName(popupId)]
  }

  self.open = function(popupId, event, options) {
    self.setVisibility(popupId, true, event, options)
  }

  self.close = function(popupId, event, options) {
    self.setVisibility(popupId, false, event, options)
  }

  self.toggle = function(popupId, event, options) {
    self.setVisibility(popupId, !self.isOpen(popupId), event, options)
  }

  self.closeAll = function(event) {
    let ret = false

    Object.keys(popups).forEach(function(key) {
      if (popups[key]) {
        ret = true
        popups[key] = false
      }
    })

    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    return ret
  }

  return self
}
