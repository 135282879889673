export {FavoritesController}

FavoritesController.$inject = ['userAccountService', 'pendingPolicyService', 'policyUtils']

/* @ngInject */
function FavoritesController(userAccountService, pendingPolicyService, policyUtils) {
  const self = this

  self.source = 'all'
  self.policyUtils = policyUtils

  self.getFavorites = function() {
    return userAccountService.getFavoritesPolicies(self.source)
  }

  self.hasFavorites = function() {
    return self.getFavorites().length > 0
  }

  self.getEmptyResultsMessage = function() {
    switch (self.source) {
      case 'policies':
        return {
          icon: 'policy',
          text: 'You have no favorited <br />Policies right now',
        }

      case 'people':
        return {
          icon: 'user',
          text: 'You have no favorited <br />People right now',
        }

      case 'all':
        return {
          icon: 'heart-unselected',
          text: 'You have no favorited <br />Policies or People right now',
        }
    }
  }

  function init() {
    pendingPolicyService.keepFirst10()
  }

  init()
}
