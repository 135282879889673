
import * as angular from 'angular'

import {whatsNewService} from '../common/whats-new-service'
import {partyService} from '../common/party-service'
import {constants} from './constants'
import { coreRun } from './core-route'
import { BENEFICIARY_ROLES, ROLE_LABELS, ROLES_ARRAY, ROLES_ORDER, BENEFICIARIES_ROLE_ORDER, ANNUITANT_ROLES, OWNER_ROLES } from '../client/beneficiaries/constants/ROLES'
import { ROLE_DISPLAY_ORDER } from '../client/beneficiaries/constants/ROLE_DISPLAY_ORDER'
import { config, configure } from './config'

angular
.module('app.core', [
  'ngAnimate',
  'ngSanitize',
  'app.logging',
  'blocks.exception',
  'blocks.router',
  'ui.router',
  'ui.router.state.events',
  'ui.bootstrap',
  'ngCookies',
  'notification-center',
  'infinite-scroll',
  'sticky',
  'ngMessages',
  'focus-if',
  'ordinal',
  'app.config',
  'ui.select',
  'app.utils',
  'app.components',
  'app.components.payment',
])
.config(configure)
.run(appRun)

appRun.$inject = ['$rootScope', 'deviceUtils']
/* @ngInject */
function appRun($rootScope, deviceUtils) {
// FastClick.attach(document.body)
  $rootScope.isMobile = deviceUtils.isMobileFormfactor() || deviceUtils.isAppleDevice()
}

angular.module('app.core')
.constant('CONSTANTS', constants)
.service('partyService', partyService)
.factory('whatsNewService', whatsNewService)
.constant('ROLE_DISPLAY_ORDER', ROLE_DISPLAY_ORDER)
.constant('BENEFICIARY_ROLES', BENEFICIARY_ROLES)
.constant('ROLE_LABELS', ROLE_LABELS)
.constant('ROLES_ARRAY', ROLES_ARRAY)
.constant('ROLES_ORDER', ROLES_ORDER)
.constant('BENEFICIARIES_ROLE_ORDER', BENEFICIARIES_ROLE_ORDER)
.constant('ANNUITANT_ROLES', ANNUITANT_ROLES)
.constant('OWNER_ROLES', OWNER_ROLES)
.value('config', config)
.config(configure)
.run(coreRun)
