
const DEFAULT_FILTER_VALUE = 'All'

export class UnsubmittedApplicationsController {
  public params
  public statusFiltersText
  public messageResult
  public error
  public notAvailableInfo
  public hasAppResults
  public SCROLL_DISTANCE
  public daysOpenOptions
  public daysToExpireOptions
  public applicationMethods
  public sortByOptions
  public statusOptions
  public selectedDaysOpenFilter
  public selectedDaysToExpireOption
  public selectedSortByOption
  public selectedStatusOption
  public noResultsMessage
  public selectedAppMethodOption
  public showFormalInformalFilter
  public isHomeOffice: boolean
  public eAppUrl: string

  constructor(private configService, private unsubmittedApplicationsService, private CONSTANTS, private downloadManager, private authService, counts) {
    this.params = {}
    this.statusFiltersText = DEFAULT_FILTER_VALUE
    this.messageResult = this.unsubmittedApplicationsService.message
    this.error = this.unsubmittedApplicationsService.error
    this.notAvailableInfo = CONSTANTS.notAvailableInfo
    this.hasAppResults = true
    this.showFormalInformalFilter = this.configService.features.showFormalInformalFilter
    this.SCROLL_DISTANCE = 0
    this.eAppUrl = this.configService.eAppUrl
    this.daysToExpireOptions = [
      {
        name: null,
        display: 'All',
      },
      {
        name: 7,
        display: 'In 7 days',
      },
      {
        name: 14,
        display: 'In 14 days',
      },
      {
        name: 21,
        display: 'In 21 days',
      },
    ]
    this.applicationMethods = [
      {
        name: null,
        display: 'All',
      },
      {
        name: true,
        display: 'Formal',
      },
      {
        name: false,
        display: 'Informal',
      },
      {
        name: 'Term Conversion',
        display: 'Term Conversion',
      },
    ]
    this.daysOpenOptions = [
      {
        name: 'All',
        display: 'All',
      },
      {
        name: '30',
        display: 'Last 30 Days',
      },
      {
        name: '60',
        display: 'Last 60 Days',
      },
      {
        name: '90',
        display: 'Last 90 Days',
      },
    ]

    this.sortByOptions = [
      {
        name: '-daysOpen',
        display: 'Newest',
      },
      {
        name: '+daysOpen',
        display: 'Oldest',
      },
      {
        name: '+insuredFullName',
        display: 'Insured',
      },
      {
        name: '+ownerFullName',
        display: 'Owner',
      },
    ]
    this.statusOptions = [] // {name, display} where name is the typeCode; set based on the counts dependency
    this.selectedStatusOption = null
    this.selectedDaysToExpireOption = this.daysToExpireOptions[0].name
    this.selectedAppMethodOption = this.applicationMethods[0].name
    this.selectedDaysOpenFilter = this.daysOpenOptions[0].name
    this.selectedSortByOption = this.sortByOptions[0].name
    if (counts) {
      this._setStatusOptions(counts)
    } else {
      this._setStatusOptions([])
    }

    this.isHomeOffice = this._isHomeOffice()
  }

  isScrollDisabled() {
    return this.unsubmittedApplicationsService.isLoadingMore() || !this.unsubmittedApplicationsService.moreData
  }

  nextPage() {
    if (!this.unsubmittedApplicationsService.isLoadingMore() && this.unsubmittedApplicationsService.moreData) {
      this.unsubmittedApplicationsService.nextPage(this.selectedDaysOpenFilter, this.selectedSortByOption, this.selectedStatusOption,
        this.selectedDaysToExpireOption, this.selectedAppMethodOption)
        .then(this.setNoResultsMessage.bind(this))
    }
  }

  setNoResultsMessage() {
    this.noResultsMessage = this.unsubmittedApplicationsService.noResultsMessage
    this.hasAppResults = this.unsubmittedApplicationsService.applications.length !== 0
  }

  filterOrSortChange() {
    this.unsubmittedApplicationsService.resetData()
    this.nextPage()
  }

  pendingSelected() {
    let isSourcePending = this.params.source === this.CONSTANTS.policySource.pending
    let isCorrectRole = this.authService.checkRoles(['FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT', 'HOMEOFFICE'])

    return isSourcePending && isCorrectRole
  }

  _isHomeOffice() {
    return this.authService.checkRoles(['HOMEOFFICE'])
  }

  _setStatusOptions(countsArray) {
    let showAllStatusesOption = { display: DEFAULT_FILTER_VALUE, name: null }
    let mappedStatusesFromCounts = countsArray.map(function (count) {
      return {
        display: count.value,
        name: count.valueTypeCode.tc,
      }
    })

    mappedStatusesFromCounts.unshift(showAllStatusesOption)
    this.statusOptions = mappedStatusesFromCounts
  }

  hasHadUnsubmittedApps() {
    return this.unsubmittedApplicationsService.hasHadUnsubmittedApps
  }

  download() {
    const options: any = {
      holdingstatus: '1012300001',
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Download unsubmitted applications',
      })
    }

    if (this.selectedStatusOption) {
      options.policyStatus = this.selectedStatusOption
    }

    if (this.selectedDaysOpenFilter !== DEFAULT_FILTER_VALUE) {
      options.daysOpen = this.selectedDaysOpenFilter
    }

    if (this.selectedAppMethodOption !== null) options.formalappIndicator = this.selectedAppMethodOption

    if (this.selectedAppMethodOption === 'Term Conversion') {
      options.formalappIndicator = true
      options.appType = 'Term Conversion'
    }

    this.downloadManager.getAllUnsubmitted(options)
  }
}

UnsubmittedApplicationsController.$inject = ['configService', 'unsubmittedApplicationsService', 'CONSTANTS', 'downloadManager', 'authService', 'counts']
