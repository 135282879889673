class ComboBoxController {
  static $inject = ['$element', '$scope']

  value: string = ''
  ngModel: ng.INgModelController
  menuOpen: boolean
  listSource: any[]
  textField: string
  valueField: string
  options: any[]
  internalValue: any
  optionListElement: any
  selectedItem: any
  selectedIndex: number = -1
  initialValue: any
  listSourceType: string

  constructor(private $element, private $scope) { }

  $onInit() {
    this.optionListElement = this.$element.find('ul')[0]

    /**
     * Determin what the data type of the listSource is by checking the typeof the first element.
     * if it's a string, then assume the listSource is a string array. Otherwise, assume
     * it's an array of objects.
     */
    this.listSourceType = typeof this.listSource?.[0]
    this.options = [].concat(this.listSource as any)

    const unregister = this.$scope.$watch(() => {
      return this.ngModel.$modelValue
    }, (value) => {
      this.selectOption(value)
      unregister()
    })

    this.menuOpen = false
  }

  blur() {
    this.menuOpen = false
  }

  focus(event, option) {
    if (event.target.tagName === 'LI') this.selectOption(option)
  }

  filterOptions(showAll: boolean = false) {
    this.menuOpen = true

    if (!showAll && this.internalValue) {
      this.options = this.listSource.filter((option) => {
        const inputValue = this.listSourceType === 'string' ? option : option[this.valueField]

        return inputValue?.toLowerCase().includes(this.internalValue?.toLowerCase())
      })
    } else {
      this.options = [].concat(this.listSource as any)
    }
  }

  selectOption(option) {
    this.internalValue = this.listSourceType === 'string' ? option : option[this.valueField]
    this.ngModel.$setViewValue(this.internalValue)
    // this.menuOpen = false
  }

  focusOption() {
    this.$scope.$applyAsync(() => {
      this.selectedItem = this.options[this.selectedIndex]
      this.optionListElement?.children[this.selectedIndex]?.focus({ preventScroll: false })
      this.internalValue = this.listSourceType === 'string' ? this.selectedItem : this.selectedItem[this.valueField]
    })
  }

  clear() {
    this.selectOption('')
    // this.menuOpen = false
    // this.selectedIndex = -1
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false
    } else {
      this.menuOpen = true
      // this.selectedIndex = -1
      this.filterOptions(true)
      // this.$timeout(() => this.focusOption(), 1000)
    }
  }

  onKeyup($event) {
    switch ($event.keyCode) {
      case 38:
      case 40:
        $event.stopPropagation()
        $event.preventDefault()

        if ($event.keyCode === 40 && ++this.selectedIndex > this.options.length - 1) this.selectedIndex = this.options.length - 1
        if ($event.keyCode === 38 && --this.selectedIndex < 0) this.selectedIndex = 0

        this.selectOption(this.listSource[this.selectedIndex])

        this.focusOption()

        break

      case 13:
      case 27:
        $event.stopPropagation()
        $event.preventDefault()
        this.selectOption(this.selectedItem)
        this.menuOpen = false
        break

      case 9:
        $event.stopPropagation()
        $event.preventDefault()
        this.menuOpen = false
        this.$element.next()
        break

      default:
        this.filterOptions()
        this.ngModel.$setViewValue(this.internalValue)
      // this.selectedIndex = -1
      // this.selectedItem = null
    }
  }
}

export const ComboBoxComponent = {
  templateUrl: 'app/components/combo-box/combo-box.html',
  controller: ComboBoxController,
  controllerAs: 'ctrl',
  bindings: {
    listSource: '=',
    label: '@',
    maxLength: '=',
    textField: '=',
    valueField: '=',
    initialValue: '=',
  },
  require: {
    ngModel: 'ngModel',
  },
}