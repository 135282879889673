export {AlertByPolicyController}

AlertByPolicyController.$inject = ['$stateParams']

  /* @ngInject */
function AlertByPolicyController($stateParams) {
  const self = this

  self.errorMessage = ''
  self.polNumber = null

  self.isThereError = function() {
    return self.errorMessage !== ''
  }

  function init() {
    self.polNumber = $stateParams.id
  }

  init()
}
