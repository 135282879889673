export {appRun}
appRun.$inject = ['routerHelper']

  /* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getStates())
}

function getStates() {
  return [{
    state: 'client.me.forms',
    config: {
      url: '/forms',
      templateUrl: 'app/download-forms/download-forms-view.html',
      controller: 'DownloadFormsController',
      controllerAs: 'downloadFormsCtrl',
      data: {
        secure: true,
      },
      resolve: {
        isFromCAResponse: ['clientService', (clientService) => clientService.isFromCA()],
        // organizedForms: ['companiesService', (companiesService) => companiesService.getAllForms()],
        organizedForms: ['companiesService', (companiesService) => companiesService.getOrganizedForms()],
      },
      onEnter: ['$rootScope', '$stateParams', function($rootScope, $stateParams) {
        const pageTitle = 'Download Forms'

          // can refer to $rootScope.isClientView: true
        $rootScope.pageTitle = pageTitle
        $rootScope.state = 'download-forms-view'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', '$stateParams', function($rootScope) {
        $rootScope.isPopupVisible = false
      }],
    },
  }]
}
