(function() {
  'use strict'

  angular.module('app.directives').directive('wgDropdown', selectDropDownDirective)

  function selectDropDownDirective() {
    return {
      scope: {
        selectOptions: '=options',
        placeholder: '@',
        model: '=',

      },
      restrict: 'E',
      controller: wgDropdownCtrl,
      controllerAs: 'dd',
      templateUrl: 'app/widgets/templates/wg-dropdown.html',
      bindToController: true,
    }
  }

  function wgDropdownCtrl() {
    const self = this

    self.toggleOptions = function() {
      self.showOptions = !self.showOptions
      self.isActive = !self.isActive
    }

    self.selectOption = function(option) {
      self.placeholder = option.display
      self.model = option.name
      self.isSelected = true
    }

    self.openMenu = function() {
      if (event.keyCode === 32) {
        event.preventDefault()
        self.toggleOptions()
      }
    }

    self.enterSelectOption = function(option) {
      if (event.keyCode === 13) {
        event.preventDefault()
        self.selectOption(option.display)
        self.toggleOptions()
      }
    }

    function init() {
      self.showOptions = false
      self.isActive = false

      if (self.model) {
        self.selectOption(self.selectOptions.filter(
          function(option) {
            return option.name === self.model
          })
        )
      }
    }

    init()
  }
})()
