export class InputNumbersOnlyDirective implements ng.IDirective {
  restrict = 'A'

  constructor() { }

  link(_scope, elem) {
    elem.on('keypress', (event) => {
      let keyCode = event.which || event.keyCode
      let valid = /[0-9]/.test(String.fromCharCode(keyCode))

      if (!valid) {
        event.preventDefault()
      }
    })
  }

  static factory(): ng.IDirectiveFactory {
    const directive = () => new InputNumbersOnlyDirective()

    directive.$inject = []

    return directive
  }
}
