import { StateParams } from "angular-ui-router"

class TransactionHistoryController {
  static $inject: string[] = ['$scope', '$stateParams', '$state']

  currentParties: any
  noDataAvailable: boolean
  clearWatches: Function[] = []

  constructor(private $scope: any, private $stateParams: StateParams, private $state: any) { }

  viewSummary(transaction: any): void {
    this.$state.go('policy.roles.transactions.summary', {batchId: transaction.batchId, transaction})
  }

  initWatches() {
    this.clearWatches.push(this.$scope.$on('PML_NG_LISTVIEW_DATA_READY', (_e, _name, listView) => {
      this.noDataAvailable = listView.data.length === 0
    }))
  }

  viewTransactionDetails(transactionId, clientId): void {
    this.$state.go('policy.roles.transactions.details', { actionId: transactionId, clientId: clientId })
  }

  $onInit(): void {
    const pid = this.$stateParams.id

    this.noDataAvailable = true
    this.initWatches()
    this.$scope.queryParams = { pid, pageable: true }
  }
}

export const transactionHistoryComponentConfig = {
  templateUrl: 'app/transaction-history/components/history/transaction-history.html',
  controller: TransactionHistoryController,
  controllerAs: 'trxCtrl',
  bindings: { currentParties: '<' },

}
