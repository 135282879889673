import * as angular from 'angular'

import { AllPoliciesController } from './all-policies-controller'
import { AllPoliciesView } from './all/all-policies-controller'
import { AllPoliciesDataProviderService } from './all/all-policies-data-provider-service'
import { FILTER_VIEW_STATES, POLICIES_GA_INFORCE_VIEW, POLICIES_POLICY_VIEWS } from './all/constants'
import { SortDropdown } from './all/sort-dropdown/sort-dropdown-comp'
import { AllPoliciesTableView } from './all/table/table-view-comp'
import { AllPoliciesTileView } from './all/tiles/tile-view-comp'
import { ClosedPoliciesController } from './closed-policies-controller'
import { CountService } from './count-service'
import { FavoritesController } from './favorites-controller'
import { PoliciesController } from './policies-controller'
import { ResultController } from './result-controller'
import { SearchController } from './search-controller'
import { SearchRoutes } from './search-routes'
import { SearchService } from './search-service'
import { UnsubmittedApplicationsController } from './unsubmitted-applications-controller'

angular
    .module('app.policy')
    .controller('ClosedPoliciesController', ClosedPoliciesController)
    .controller('AllPoliciesController', AllPoliciesController)
    .controller('PoliciesController', PoliciesController)
    .controller('FavoritesController', FavoritesController)
    .controller('ResultController', ResultController)
    .controller('SearchController', SearchController)
    .service('countService', CountService)
    .service('searchService', SearchService)
    .controller('UnsubmittedApplicationsController', UnsubmittedApplicationsController)
    .component('allPoliciesView', AllPoliciesView)
    .run(routerHelper => new SearchRoutes(routerHelper))

angular.module('app.search', ['app.core', 'app.policy'])
.constant('FILTER_VIEW_STATES', FILTER_VIEW_STATES)
.constant('POLICIES_GA_INFORCE_VIEW', POLICIES_GA_INFORCE_VIEW)
.constant('POLICIES_POLICY_VIEWS', POLICIES_POLICY_VIEWS)
.service('allPoliciesDataProvider', AllPoliciesDataProviderService)
.component('allPoliciesTileView', AllPoliciesTileView)
.component('allPoliciesTableView', AllPoliciesTableView)
.component('sortDropdown', SortDropdown)

ClosedPoliciesController.$inject = ['$stateParams', '$state', 'allPoliciesService', 'popupManager', 'utils', 'policyUtils', 'prodTypesResult', 'policyStatusResult', 'downloadManager', 'authService', '$filter', 'CONSTANTS']
CountService.$inject = ['$http', 'CONSTANTS', 'partyService', 'utils']
PoliciesController.$inject = ['$state', '$stateParams', 'policyUtils', 'prodTypesResult', 'allPoliciesService', 'CONSTANTS', 'popupManager', 'downloadManager', 'authService', 'deliveryStatusCounts', 'utils']
ResultController.$inject = ['$rootScope', '$location', 'searchService', 'recentlyViewedService', '$stateParams', 'authService', 'pendingPolicyService', 'clientAdvisorService', 'utils', 'dateUtils', 'policyUtils', 'CONSTANTS']
SearchService.$inject = ['$http', 'loggingService', 'utils', 'dateUtils', 'clientUtils', 'policyUtils', 'CONSTANTS', 'partyService']
