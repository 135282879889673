import { IController } from "angular"
import { CompaniesService } from "../companies/companies-service"

// sources/client/app/download-forms/download-forms-view.html
class ClientFormsController implements IController {
  static $inject = ['companiesService', 'configService']

  constructor(private companiesService: CompaniesService, private configService) { }

  sections: any
  modal: any
  dataStyle: string
  resolve: any
  searchText: string

  $onInit(): void {
    // console.log('sections', this.sections)
    this.searchText = ''
    this.modal = this.resolve.modalCtrl.modalInstance

    this.companiesService[this.resolve.modalData.dataStyle]()
      .then((sections: any) => this.sections = sections)
  }

  clearSearch(): void {
    this.searchText = ''
  }

  get isAgent(): boolean {
    return this.configService.serverMode === 'agent'
  }
}

const ClientForms = {
  templateUrl: 'app/components/client-forms/client-forms.html',
  controller: ClientFormsController,
  controllerAs: 'clientFormsCtrl',
  bindings: {
    resolve: '<',
    searchText: '<',
  },
}

export { ClientForms }
