import * as angular from 'angular'
angular.module('app.account', ['app.core'])

import {appRun} from './account-routes'
import {LoginController} from './login-controller'

angular
.module('app.account')
.run(appRun)
.controller('LoginController', LoginController)
