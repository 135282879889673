import * as angular from 'angular'
import { IGW_DISTRIBUTION_TYPECODES } from '../beneficiary-constants'
import { Beneficiary, BENEFICIARY_SOURCE_OBJECT_TYPES } from '../classes/beneficiary'
import { BeneficiaryShare } from '../classes/share'
import { TypeCodeValue } from '../classes/type-code-value'
import { BENEFICIARY_PARTY_TYPES } from '../constants/BENEFICIARY_OTHER_OPTIONS'
import { ROLE_CONSTANTS } from '../relationships/role-relationships-service'
class BeneficiaryEditTriggerController {
  $inject = ['$state', 'BENEFICIARY_OTHER_OPTIONS', 'BENEFICIARY_ROLES']

  // NEW_ESTATE = {
  //   fullName: 'New Estate',
  //   id: BENEFICIARY_PARTY_TYPES.ESTATE,
  //   isNew: true,
  //   isNewPartyOption: true,
  //   distribution: { tc: '2', value: 'Percent' },
  //   distributionValue: 100,
  // }

  NEW_FINAL_BENEFICIARY = {
    fullName: 'New Estate',
    id: BENEFICIARY_PARTY_TYPES.TEXT,
    isNew: true,
    isNewPartyOption: true,
    distribution: { tc: '2', value: 'Percent' },
    distributionValue: 100,
    relationshipToInsured: ROLE_CONSTANTS.RELATION_TYPE_CODES[ROLE_CONSTANTS.RELATION_NAMES.ESTATE],
    partyTypeCode: new TypeCodeValue({ tc: '0', value: 'Unknown' }),
  }

  partyList: any[]
  selectedParty: any
  onPartySelected: Function
  category: any
  hasExistingFinalBeneficiary: boolean
  disableDropdown: boolean
  onOpenEditor: Function

  constructor(private $state, public BENEFICIARY_OTHER_OPTIONS, public BENEFICIARY_ROLES) { }

  partySelected() {
    const selectedBene = this.selectedParty.isAssociatedParty ?
      new Beneficiary(this.category.rid, this.selectedParty, BENEFICIARY_SOURCE_OBJECT_TYPES.ASSOCIATED_PARTY) :
      new Beneficiary(this.category.rid, this.selectedParty, BENEFICIARY_SOURCE_OBJECT_TYPES.NEW_PARTY_OPTION)

    this.onPartySelected({ party: selectedBene })
    this.selectedParty = null
  }

  /**
   *
   */
  addFinalBeneficiary() {
    const bene = new Beneficiary(this.BENEFICIARY_ROLES.FINAL, this.NEW_FINAL_BENEFICIARY, BENEFICIARY_SOURCE_OBJECT_TYPES.NEW_PARTY_OPTION)

    this.onPartySelected({ party: bene })
    this.selectedParty = null

  }

  addNewClientBeneficiary(rid) {
    const newBene = new Beneficiary(rid, this.BENEFICIARY_OTHER_OPTIONS[0], BENEFICIARY_SOURCE_OBJECT_TYPES.NEW_PARTY_OPTION)

    this.$state.go('myPolicy.clientBeneFullEditor', { bene: newBene })
  }

  /**
   * You can only change an exiting final beneficiaries if there is only one. For this reason
   * we take first one.
   */
  changeFinalBeneficiary() {
    const bene = this.category.beneficiaries[0]

    bene.isEditMode = true
    bene.shareDistribution = new BeneficiaryShare({ distribution: IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE, distributionValue: 100, relationshipToInsured: ROLE_CONSTANTS.RELATION_TYPE_CODES[ROLE_CONSTANTS.RELATION_NAMES.ESTATE] })
    bene.partyType = 'estate'
    this.onPartySelected({ party: bene })
    this.selectedParty = null
  }

  // $onInit() {

  // }

  // $doCheck() {

  // }
}

angular.module('app.beneficiaries')
  .component('beneficiaryEditTrigger', {
    bindings: {
      partyList: '=',
      onPartySelected: '&',
      category: '=',
      disableDropdown: '=',
      userType: '<',
      onOpenEditor: '&',
    },
    templateUrl: 'app/client/beneficiaries/edit-trigger/edit-trigger.html',
    controller: BeneficiaryEditTriggerController,
    controllerAs: 'ctrl',
  })
