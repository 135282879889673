import * as angular from 'angular'
import { IPromise } from 'angular'

const ALERTS_URI = {
  MARK_ARCHIVED: '/proxy/core-services-gateway/alerts/markArchived',
  MARK_UNARCHIVED: '/proxy/core-services-gateway/alerts/markUnarchived',
  MARK_DISMISSED: '/proxy/core-services-gateway/alerts/markDismissed',
}
class SortInfo implements ISortInfo {
  sorted: boolean
  category: string
  reverse: boolean
}

class AlertFilteredResult implements IAlertFilteredResult {
  error: string = ''
  alertList: any[] = []
  timeCalled: number
  numFound: number = 0

  constructor(response?: ng.IHttpResponse<IAlertFilteredResult>, timeCalled?: number) {

    if (response && response.data) {
      this.error = response.data.error || ''
      this.alertList = response.data.alertList
      this.numFound = response.data.numFound

    } else {
      this.error = 'Unexpected response'
    }

    this.timeCalled = timeCalled || Date.now()
  }
}
export class AlertService implements IAlertSummaryResult {
  static $inject = ['$rootScope', '$timeout', '$http', 'utils', 'dateUtils', 'clientUtils', 'CONSTANTS', 'partyService', '$q']

  error: string = ''
  public categories: any[] = []
  numAlerts: number = 0
  expectedNumAlerts: number = 0

  ALERTS_POLLING_INTERVAL: number = 250
  ALERTS_POLLING_LIMIT: number = 30
  pollingIntervalId: any
  pollingCount: number = 0

  constructor(private $rootScope, private $timeout, private $http: angular.IHttpService, private utils, private dateUtils,
    private clientUtils, private CONSTANTS, private partyService, private $q: angular.IQService) { }

  /**
   * Returns the total number of alerts across all categories.
   * Optionally you can pass an object of category counts or,
   * it will use the `this.categories` property.
   *
   * @param categories
   */
  countAlertCategories(categories?: any) {
    const cats = categories || this.categories
    const categoryKeys = Object.keys(cats)

    return categoryKeys.reduce((numAlerts: number, categoryName: string) => {
      const categoryCount: number = cats[categoryName]

      numAlerts += categoryCount

      return numAlerts
    }, 0)

  }

  /**
   * Sets the `categories` and `numAlerts` properties with the results
   * of the called to 'alerts/summary'.
   *
   * NOTE: Returns `this` because other areas of the code use the `numAlerts` property
   * directly.  May change this once the refactor to TypeScript is complete.
   *
   * @param alertsView
   */
  getAlertsSummary() {
    const agentKey: string = this.partyService.getAgentKey()

    let url: string = this.CONSTANTS.alertsSummaryURL

    url = this.utils.appendURLParameter(url, 'filterKey', agentKey)

    this.$rootScope.preventSpinnerFromShowing = true

    return this.$http.get(url)
      .then((result: ng.IHttpResponse<any | undefined>) => {

        this.categories = result.data || []

        if (Object.keys(this.categories).length) {
          this.numAlerts = this.countAlertCategories(this.categories)
        }

        this.error = 'AlertsSummary failed to return any category data.'

        return this
      })
      .catch((err: any) => {
        this.utils.fillAndLogError(err, this)
      })
      .finally(() => this.$rootScope.preventSpinnerFromShowing = false)
  }

  /**
   * Return a `Promise` to call the `getAlertsSummary` function every `timeoutInterval`
   * until either the `expectedAlertCount` is met, or the `pollingCount` is greater than
   * or equal to `ALERTS_POLLING_LIMIT`
   *
   * @param expectedAlertCount
   * @param useLongPolling
   * @param timeoutInterval
   */
  pollSummary(expectedAlertCount: number, useLongPolling: boolean = false, timeoutInterval: number = this.ALERTS_POLLING_INTERVAL) {
    return this.$q((resolve: Function) => {
      this._pollTimer(expectedAlertCount, resolve, useLongPolling, timeoutInterval)
    }).finally(() => this.$rootScope.$broadcast('alertNumberUpdate'))
  }

  /**
   * Internal function that actually performs the call to getAlertsSummary,
   * and will call the provided `resolve` function when either of the
   * conditions of the `pollSummary` are met.
   *
   * That is until either the `expectedAlertCount` is met, or the `pollingCount` is greater than
   * or equal to `ALERTS_POLLING_LIMIT`
   *
   * @param expectedAlertCount
   * @param resolve
   * @param useLongPolling
   */
  _pollingTask(expectedAlertCount: number, resolve: Function, useLongPolling: boolean = false) {
    console.log('this.pollingCount < this.ALERTS_POLLING_LIMIT', this.pollingCount, this.ALERTS_POLLING_LIMIT, this.pollingCount < this.ALERTS_POLLING_LIMIT)
    if (useLongPolling || this.pollingCount < this.ALERTS_POLLING_LIMIT) {
      this.getAlertsSummary()
        .then(() => {
          const returnedAlertCount = this.countAlertCategories()

          if (expectedAlertCount !== returnedAlertCount) {
            this._pollTimer(expectedAlertCount, resolve)
          } else {
            this.pollingCount = 0
            resolve()
          }
        })
    } else {
      this.pollingCount = 0
      resolve()
    }
  }

  /**
   * this function implements the timer, and calls the `_pollingTask` on each
   * timeout event.
   *
   * @param expectedAlertCount
   * @param resolve
   * @param useLongPolling
   * @param timeoutInterval
   */
  _pollTimer(expectedAlertCount: number, resolve: Function, useLongPolling: boolean = false, timeoutInterval: number = this.ALERTS_POLLING_INTERVAL) {
    !useLongPolling && this.pollingCount++
    this.$timeout(this._pollingTask.bind(this, expectedAlertCount, resolve, useLongPolling), timeoutInterval)
  }

  getAlertsByClient(client: any, alertsView?: string, pageNumber?: number, sortInfo?: ISortInfo, viewArchived?: boolean) {
    let url = this.CONSTANTS.isDataServiceActive
      ? this.CONSTANTS.alertsByClientURL
        .replace('{0}', client)
        .replace('{1}', alertsView ? this.CONSTANTS.alertsView[alertsView] : '')
      : 'app/mock-api/alert-by-policy.json'

    url = this.utils.appendURLParameter(url, 'page', pageNumber)

    sortInfo = sortInfo || new SortInfo()
    sortInfo.sorted = sortInfo.sorted ? sortInfo.sorted : false

    if (sortInfo.sorted) {
      url = this.utils.appendURLParameter(url, 'sort', sortInfo.category)
      url = this.utils.appendURLParameter(url, 'order', sortInfo.reverse ? 'desc' : 'asc')
    }

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    url = this.utils.appendURLParameter(url, 'archive', viewArchived)

    return this.$http.get(url)
      .then((response: angular.IHttpResponse<IAlertFilteredResult>) => this.processFilteredAlerts(response))
      .catch((err) => this.processErrors(err))
  }

  getAlertsByClientData(client: any, alertsView?: string, pageNumber?: number, sortInfo?: ISortInfo, viewArchived?: boolean) {

    const policyNumbers: string[] = []
    const allPromises: any[] = []

    client.policies.forEach((pol: any) => {
      if (policyNumbers.indexOf(pol.polNumber) === -1) {
        policyNumbers.push(pol.polNumber)
      }
    })

    policyNumbers.forEach((polNum: string) => {
      allPromises.push(this.getAlertsByPolicy(polNum, alertsView, pageNumber, sortInfo, viewArchived))
    })

    const allAlerts: IAlertFilteredResult = new AlertFilteredResult()

    return this.$q.all(allPromises)
      .then((allResults: IAlertFilteredResult[]) => {

        allResults.forEach((result: IAlertFilteredResult): IAlertFilteredResult => {
          allAlerts.alertList = allAlerts.alertList.concat(result.alertList)
          allAlerts.numFound += result.numFound
          return allAlerts
        })

        return allAlerts
      })
      .catch((err: any) => {
        allAlerts.error = err
      })
  }

  getAlertsByPolicy(polNumber, alertsView, pageNumber, sortInfo, viewArchived) {
    let url = this.CONSTANTS.isDataServiceActive ? this.CONSTANTS.alertsByPolicyURL.replace('{0}', polNumber).replace('{1}', alertsView ? this.CONSTANTS.alertsView[alertsView] : '') : 'app/mock-api/alert-by-policy.json'

    url = this.utils.appendURLParameter(url, 'page', pageNumber)

    sortInfo = sortInfo || {}
    sortInfo.sorted = sortInfo.sorted ? sortInfo.sorted : false

    if (sortInfo.sorted) {
      url = this.utils.appendURLParameter(url, 'sort', sortInfo.category)
      url = this.utils.appendURLParameter(url, 'order', sortInfo.reverse ? 'desc' : 'asc')
    }

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    url = this.utils.appendURLParameter(url, 'archive', viewArchived)

    return this.$http.get(url)
      .then((r: ng.IHttpResponse<IAlertFilteredResult>) => this.processFilteredAlerts(r))
      .catch((err) => this.processErrors(err))

  }

  searchAlerts(params: IAlertSearchParams, ignoreOrphanParam: boolean, sortInfo?: ISortInfo, viewArchived?: boolean, pagingInfo?: IPagingInfo) {

    let url = this.CONSTANTS.isDataServiceActive ? this.CONSTANTS.searchAlertsURL : 'app/mock-api/alert-by-policy.json'

    if (sortInfo && sortInfo.sorted) {
      url = this.utils.appendURLParameter(url, 'sort', sortInfo.category)
      url = this.utils.appendURLParameter(url, 'order', sortInfo.reverse ? 'desc' : 'asc')
    }

    if (pagingInfo) {
      url = this.utils.appendURLParameter(url, 'page', pagingInfo.page)
    }

    url = this.utils.appendURLParameter(url, 'pid', params.pid)
    url = this.utils.appendURLParameter(url, 'insured', params.insured)
    url = this.utils.appendURLParameter(url, 'startdate', params.startdate)
    url = this.utils.appendURLParameter(url, 'enddate', params.enddate)
    url = this.utils.appendURLParameter(url, 'activityCategory', params.activityCategory)
    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    url = this.utils.appendURLParameter(url, 'archive', viewArchived)

    console.log('url', url)
    if (!ignoreOrphanParam) {
      url = this.utils.appendURLParameter(url, 'orphaned', Boolean(params.orphaned))
    }

    return this.$http.get(url)
      .then((r: angular.IHttpResponse<IAlertFilteredResult>) => this.processFilteredAlerts(r))
      .catch((err) => this.processErrors(err))
  }

  getAlertsByCategory(category, alertsView, pageNumber, sortInfo, viewArchived) {
    let timeCalled = Date.now()
    let url = this.CONSTANTS.isDataServiceActive ? this.CONSTANTS.alertsByCategoryURL.replace('{0}', category).replace('{1}', alertsView ? this.CONSTANTS.alertsView[alertsView] : '').replace('{2}', pageNumber) : 'app/mock-api/alerts-by-category.json'

    pageNumber = pageNumber || 1

    sortInfo = sortInfo || {}
    sortInfo.sorted = sortInfo.sorted ? sortInfo.sorted : false

    viewArchived = Boolean(viewArchived)

    if (sortInfo.sorted) {
      url = this.utils.appendURLParameter(url, 'sort', sortInfo.category)
      url = this.utils.appendURLParameter(url, 'order', sortInfo.reverse ? 'desc' : 'asc')
    }
    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    url = this.utils.appendURLParameter(url, 'archive', viewArchived)

    // Intent here was to have timeCalled available on the response.config object,
    // but we are now using typescript and timeCalled is not a recognized property.
    // So, instead we pass the timeCalled to processFilteredAlerts.
    return this.$http.get(url, {params: { timeCalled: timeCalled}})
      .then((r: angular.IHttpResponse<IAlertFilteredResult>) => this.processFilteredAlerts(r, timeCalled))
      .catch((err) => this.processErrors(err))
  }

  getAlertDetails(alertID) {
    let alert
    let firstInsuredName
    let url = this.CONSTANTS.isDataServiceActive ? this.CONSTANTS.alertDetailsURL.replace('{0}', alertID) : 'app/mock-api/alert-details.json'
    let result = {
      error: '',
      status: '',
      alert: [],
    }

    return this.$http.get(url)
      .then((httpData) => {
        if (httpData.data) {
          alert = httpData.data

          if (alert.insureds && alert.insureds.length > 0) {
            firstInsuredName = this.clientUtils.firstNameFirst(alert.insureds[0]).toLowerCase()

            alert.insuredsLabel = this.
            clientUtils.getMultipleNamesLabel(firstInsuredName, alert.insureds.length)
          }

          alert.advisorNameList = alert.primaryServicingAgent ? alert.primaryServicingAgent : []
          alert.activityDate = this.dateUtils.parseDate(alert.activityDate)
          alert.dataPointsJson = JSON.parse(alert.dataPoints)

          if (alert.dataPointsJson.dueDate) {
            alert.dataPointsJson.dueDate = this.dateUtils.parseDate(alert.dataPointsJson.dueDate)
          }

          result.alert = alert
          return result
        }

        result.error = 'Undefined response'
        return result
      })
      .catch((httpData) => {
        this.utils.fillAndLogError(httpData, result)

        return result
      })
  }

  setArchiveForAlert(aid: string, set: boolean) {
    let promise

    if (set === false) {
      promise = this.$http.put(this.CONSTANTS.alertsUnarchive,
        {eventid: aid},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          transformRequest: (obj) => {
            return this.utils.getObjectAsFormParams(obj)
          },
        })
    } else {
      promise = this.$http.put(this.CONSTANTS.alertsArchive,
        {eventid: aid},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          transformRequest: (obj) => {
            return this.utils.getObjectAsFormParams(obj)
          },
        })
    }

    return promise
  }

  archiveAlerts(alertIds: string[]): IPromise<any> {
    const url: string = ALERTS_URI.MARK_ARCHIVED

    return this.$http.put(url, alertIds, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  unarchiveAlerts(alertIds: string[]): IPromise<any> {
    const url: string = ALERTS_URI.MARK_UNARCHIVED

    return this.$http.put(url, alertIds, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  dismissAlerts(alertIds: string[]): IPromise<any> {
    const url: string = ALERTS_URI.MARK_DISMISSED

    return this.$http.put(url, alertIds, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  // TODO: Deprecated in favor of `markRead`
  markAsRead(aid) {
    return this.$http.put(this.CONSTANTS.alertsRead,
      { eventid: aid },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        transformRequest: (obj) => {
          return this.utils.getObjectAsFormParams(obj)
        },
      })
  }

  markRead(aid: string | string[]): IPromise<any> {
    const alertIds = {elements: typeof aid === 'string' ? [aid] : aid}

    return this.$http.put(this.CONSTANTS.alertsMarkRead,
      alertIds,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
  }

  processFilteredAlerts(response: ng.IHttpResponse<IAlertFilteredResult>, timeCalled?: number): IAlertFilteredResult {
    let firstInsuredName

    const alertFilteredResult: IAlertFilteredResult = new AlertFilteredResult(response, timeCalled)

    alertFilteredResult.alertList = alertFilteredResult.alertList.map(alert => {
      if (alert.insureds && alert.insureds.length > 0) {
        firstInsuredName = this.clientUtils.firstNameFirst(alert.insureds[0]).toLowerCase()
        alert.insuredsLabel = this.clientUtils.getMultipleNamesLabel(firstInsuredName, alert.insureds.length)
      }

      alert.advisorNameList = alert.primaryServicingAgent ? alert.primaryServicingAgent : []

      alert.activityDate = this.dateUtils.parseDate(alert.activityDate)

      alert.dataPointsJson = this.CONSTANTS.isDataServiceActive && alert.dataPoints
        ? JSON.parse(alert.dataPoints)
        : {}

      return alert
    })

    return alertFilteredResult
  }

  processErrors(err: any): any {
    return this.utils.fillAndLogError(err, {})
  }

}
