import { COUNTRY_CODES } from '../constants/COUNTRY_CODES'
import { IPhoneNumber } from '../types'
import AbstractTransformableData from './abstract-transformable-data'
import { TypeCodeValue } from './type-code-value'

export class PhoneNumber extends AbstractTransformableData implements IPhoneNumber {
  id: string
  type: TypeCodeValue
  countryCode: TypeCodeValue
  areaCode: string
  dialNumber: string
  ext: string
  order: number
  markedForDeletion: boolean = false
  index: number

  private _completeNumber: string


  constructor(phoneData: PhoneNumber | any, index: number) {
    super(['index', 'order', 'markedForDeletion', 'completeNumber'], [])

    this.index = index

    if (phoneData) {
      if (phoneData instanceof PhoneNumber) {
        this.id = phoneData.id
        this.type = phoneData.type
        this.countryCode = new TypeCodeValue(COUNTRY_CODES.find((countryCode) => {
          return countryCode.tc === phoneData.countryCode?.tc
        })) || {tc: '', value: ''} as TypeCodeValue
        this.areaCode = phoneData.areaCode
        this.dialNumber = phoneData.dialNumber
        this.ext = phoneData.ext ?? ''
      } else {
        this.id = phoneData.id
        this.type = new TypeCodeValue(phoneData.typeCode ?? { tc: '26', value: 'Primary' })
        if (!phoneData.countryCode) {
          phoneData.countryCode = '+1'
        }

        if(typeof phoneData.countryCode === 'object') {
            this.countryCode = phoneData.countryCode
        } else {
          this.countryCode = new TypeCodeValue(COUNTRY_CODES.find((countryCode) => {
            const phoneTC = phoneData.countryCode ? phoneData.countryCode.replace('+', '') : "1"  // Removing the plus sign. or default to US

            return countryCode.tc === phoneTC
          })) || { tc: '', value: '' } as TypeCodeValue
        }

        this.areaCode = phoneData.areaCode
        this.dialNumber = phoneData.dialNumber
        this.ext = phoneData.ext ?? ''
      }

    } else {
      this.id = ''
      this.type = new TypeCodeValue({ tc: '26', value: 'Primary' })
      this.countryCode = new TypeCodeValue({ value: 'United States', tc: '1' })
      this.areaCode = ''
      this.dialNumber = ''
      this.ext = ''
    }

    this._completeNumber = this.areaCode + this.dialNumber
  }

  get isEmpty(): boolean {
    return this.completeNumber === ''
  }

  get isNew(): boolean {
    return this.id === ''
  }

  get isExisting(): boolean {
    return !this.isNew
  }

  get nonUsNumber() {
    return this.countryCode?.tc !== '1'
  }

  set completeNumber(value: any) {
    // console.trace('completeNumber::set', value, this._completeNumber)
    if (value === undefined) return // Don't allow undefined

    this.parse(this.countryCode, value)
    this._completeNumber = value
  }

  get completeNumber() {
    return this._completeNumber
  }

  hasChange(origPhone?: PhoneNumber): boolean {
    // console.log('!origPhone && !this.isEmpty', !origPhone && !this.isEmpty)

    if (!origPhone && !this.isEmpty) return true // Assume changed under this condition

    const diff = origPhone?.completeNumber !== this.completeNumber
      || origPhone?.countryCode?.tc !== this.countryCode?.tc
      || origPhone?.ext !== this.ext
      || origPhone?.type.tc !== this.type?.tc

    // if(diff) console.log(origPhone?.id, origPhone?.countryCode?.tc, this.id, this.countryCode?.tc)

    return diff
  }

  parse(countryCode: TypeCodeValue, phoneNumber: string) {
    if (countryCode.tc === '1') {
      const areaCode = phoneNumber?.substring(0, 3) ?? ''

      this.dialNumber = phoneNumber?.replace(areaCode, '') ?? ''
      this.areaCode = areaCode
    } else {
      this.dialNumber = phoneNumber
    }
  }

  undelete() {
    this.markedForDeletion = false
  }

  serialize() {
    const serialized = {
      id: this.id,
      type: this.type.serialize(),
      countryCode: this.countryCode?.tc,
      areaCode: this.areaCode,
      dialNumber: this.dialNumber,
      ext: this.ext?.trim(),
    }

    return serialized
  }
}
