import { CLIENT_CARD_OPTIONS } from './constants/CLIENT_CARD_OPTIONS'
export class ClientCardController {
  $inject = ['modalService', '$filter', 'BENEFICIARY_PREFIXES', '$state', 'authService']

  party: any
  options: any
  modalInstance: any
  showClientTransactionLink: boolean

  constructor(private modalService, private $filter, private BENEFICIARY_PREFIXES, private $state, private authService) { }

  $onInit(): any {
    const options = Object.keys(CLIENT_CARD_OPTIONS).reduce((acc, elem) => {
      if (elem === this.party.shareDistribution.roleTypeCode.value.toUpperCase()) acc[elem] = CLIENT_CARD_OPTIONS[elem]

      return acc
    }, {})

    this.options = Object.values(options)[0]
    this.party.maskedSSN = this.$filter('maskedSSN')(this.party.ssn)
    this.showClientTransactionLink = this.authService.matchAnyPermission(['canViewPolicyRolesHistory'])
    // console.log(this.party)
  }

  openPhoneModal(): any {
    this.modalService.open('app/components/client-card/templates/phone-modal.html', {
      phoneNumbers: this.party.phoneList.sorted,
      fullName: this.party.name.fullName,
    })
  }

  openEditModal(): any {
    this.modalService.open('app/components/client-card/templates/section-edit-modal.html', {
      modalHeader: `Editing ${this.party.name.fullName}`,
      party: this.party,
      editingCurrent: true,
      deletingCurrent: false,
      revertCurrent: false,
      prefixes: this.BENEFICIARY_PREFIXES,
    },
      {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
      },
    )
  }

  openDeleteModal(): any {
    this.modalService.open('app/components/client-card/templates/section-edit-modal.html', {
      modalHeader: `Deleting ${this.party.name.fullName}`,
      party: this.party,
      editingCurrent: false,
      deletingCurrent: true,
      revertCurrent: false,
    },
      {
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  openRevertModal(): any {
    this.modalService.open('app/components/client-card/templates/section-edit-modal.html', {
      modalHeader: `Reverting ${this.party.name.fullName}`,
      party: this.party,
      editingCurrent: false,
      deletingCurrent: false,
      revertCurrent: true,
    },
      {
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  closeModal(): any {
    this.modalInstance.dismiss()
  }

  viewClientTransactions(clientId): any {
    this.$state.go('policy.roles.transactions.client', {clientId})
  }

  get roleColor(): string {
    return this.party.sortOrder.color
  }

  get phoneLabelText(): string {
    return this.party.phoneList.hasExistingNumbers && this.party.phoneList.count > 1 ? 'Phone Numbers' : 'Phone Number'
  }

  get hasMultiplePhoneNumbers(): boolean {
    return this.party.phoneList.hasExistingNumbers && this.party.phoneList.count > 1
  }
}

export const clientCardComponent = {
  templateUrl: 'app/components/client-card/templates/client-card.html',
  controller: ClientCardController,
  controllerAs: 'ctrl',
  bindings: {
    party: '<',
    editMode: '<',
  },
}
