import * as angular from 'angular'
import { RolesEditAddressEditController } from './address-edit/address-edit.component'
import { RolesEditPersonalDetailsController } from './personal-details/personal-details.component'
import { RolesEditPersonalInfoController } from './personal-information/personal-information.component'
import { RolesEditController } from './roles-edit.component'
import { PolicyRolesController } from './roles-list/policy-roles-component'
import { RolesRunner } from './routes'
import { RolesPermissionService } from '../client-bene-edit/roles-permission-service'

angular.module('app.roles', ['app.utils'])
  .run(routerHelper => new RolesRunner(routerHelper))
  .component('rolesEditComponent', {
    templateUrl: 'app/client/roles/tmpl/edit-main-view.html',
    controller: RolesEditController,
    controllerAs: 'ctrl',
    bindings: {
      clientResults: '<',
      beneficiaryMetadata: '<'
    },
  })
  .component('rolesEditAddressEditComponent', {
    // bindings: {
    // },
    require: {},
    templateUrl: 'app/client/roles/address-edit/address-edit.html',
    controller: RolesEditAddressEditController,
    controllerAs: 'ctrl',
  })
  .component('rolesEditPersonalDetailsComponent', {
    require: {},
    templateUrl: 'app/client/roles/personal-details/personal-details.html',
    controller: RolesEditPersonalDetailsController,
    controllerAs: 'ctrl',
  })
  .component('rolesEditPersonalInformationComponent', {
    require: {},
    templateUrl: 'app/client/roles/personal-information/personal-information.html',
    controller: RolesEditPersonalInfoController,
    controllerAs: 'ctrl',
  })
  .component('rolesComponent', {
    templateUrl: 'app/client/roles/roles-list/policy-roles.html',
    controller: PolicyRolesController,
    controllerAs: 'ctrl',
    bindings: {
      policySummary: '=',
      beneficiaryMetadata: '<',
    },
  })
  .service('rolesPermissionService', RolesPermissionService)
