
stateTransitions.$inject = ['$rootScope', '$state', '$window', 'navigationStack', 'deviceUtils']

  /* @ngInject */
function stateTransitions($rootScope, $state, $window, navigationStack, deviceUtils) {
  const service = {}

  service.pendingTransitionOptions = {}

  function fnSuccess(msg) {
    console.log('EVENT Transition Success')
  } // called when the animation has finished
  function fnError(msg) {
    console.log('EVENT Transition Error')
  } // called in case you pass in weird values

  $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
    if (service.pendingTransitionOptions.direction && service.isMobileAnimationAvailable()) {
      window.plugins.nativepagetransitions.slide(service.pendingTransitionOptions, fnSuccess, fnError)
    }
  })

  service.isMobileAnimationAvailable = function() {
    return deviceUtils.isMobilePlatform() && window.plugins
  }

  service.go = function(state, params, options, type) {
    service.pendingTransitionOptions = {}
    options = options || {}
    if (service.isMobileAnimationAvailable() && !options.notAnimation) {
      service.pendingTransitionOptions.direction = options.direction ? options.direction : 'left' // 'left|right|up|down', default 'left' (which is like 'next')
      service.pendingTransitionOptions.duration = options.duration ? options.duration : 400 // in milliseconds (ms), default 400
      service.pendingTransitionOptions.slowdownfactor = options.slowdownfactor ? options.slowdownfactor : 4 // overlap views (higher number is more) or no overlap (1), default 4
      service.pendingTransitionOptions.fixedPixelsTop = options.fixedPixelsTop ? options.fixedPixelsTop : 0 // optional, the number of pixels of your fixed header, default 0 (iOS and Android)
      service.pendingTransitionOptions.fixedPixelsBottom = options.fixedPixelsBottom ? options.fixedPixelsBottom : 0 // optional, the number of pixels of your fixed footer (f.i. a tab bar), default 0 (iOS and Android)
      service.pendingTransitionOptions.async = options.async === undefined ? true : options.async

      if (service.pendingTransitionOptions.async) {
        service.pendingTransitionOptions.iosdelay = -1
        service.pendingTransitionOptions.androiddelay = -1
      } else {
        service.pendingTransitionOptions.iosdelay = options.iosdelay ? options.iosdelay : 60 // ms to wait for the iOS webview to update before animation kicks in, default 60
        service.pendingTransitionOptions.androiddelay = options.androiddelay ? options.androiddelay : 70 // same as above but for Android, default 70
      }
    }

    $state.go(state, params).then(function() {
      if (service.pendingTransitionOptions.direction && service.isMobileAnimationAvailable()) {
        window.plugins.nativepagetransitions.executePendingTransition(fnSuccess, fnError)
        service.pendingTransitionOptions = {}
      }
    })
  }

  service.goBack = function(notAnimation) {
    let lastObj

    if (deviceUtils.isMobilePlatform()) {
      lastObj = navigationStack.getLast()

      service.go(lastObj.state, lastObj.params, {
        'direction': 'right',
        'async': true,
        'notAnimation': notAnimation,
      })
    } else {
      $window.history.back()
    }
  }

  return service
}

export {stateTransitions}
