export {alertHasFieldService}

alertHasFieldService.$inject = ['CONSTANTS']

function alertHasFieldService(CONSTANTS) {
  let type
  const service = {}

  service.setAlertType = function(alertType) {
    type = alertType
  }

  service.remarks = function() {
    return !containsOneOf(type, [
      'UL Early Warning',
      'Term Conversion',
      'Term Anniversary (No Conversion)',
      'DCA Payment Below Minimum',
      'DCA and Indexed Account Payment Below Minimum',
      'Indexed Account Payment Below Minimum',
      'Loan NLG Confirm',
      'Premium Offset',
      'Premium Offset Overloan',
      'Premium Offset Insufficient Funds',
      'Premium Offset Canceled',
      'Net Amount at Risk',
      'SPIA Program Canceled',
      'Step Up Letter Sent',
      'Living Benefit Maturity Letter Sent',
      'Rider Renewal',
      'End of Benefit',
      'Living Benefit Termination Ltr',
      'W4 Withholding',
      'Term Requalification Rates',
    ]) || getCategory() === CONSTANTS.alertGroups.conservation
  }

  service.loanAmount = function() {
    return containsOneOf(type, [
      'Loan NLG Confirm',
    ])
  }

  service.loanProcessed = function() {
    return containsOneOf(type, [
      'Loan NLG Confirm',
    ])
  }

  service.premiumAmount = function() {
    return containsOneOf(type, [
      'Premium Offset',
      'Premium Offset Overloan',
      'Premium Offset Insufficient Funds',
      'Premium Offset Canceled',
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.premiumMode = function() {
    return getCategory() === CONSTANTS.alertGroups.earlyWarning
  }

  service.premiumAmountNextPeriod = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.anniversaryDate = function() {
    return containsOneOf(type, [
      'Premium Offset',
      'Premium Offset Overloan',
      'Premium Offset Insufficient Funds',
      'Premium Offset Canceled',
      'Living Benefit Maturity Letter Sent',
      'Rider Renewal',
    ])
  }

  service.divPUASurrenderAmount = function() {
    return containsOneOf(type, [
      'Premium Offset',
      'Premium Offset Overloan',
      'Premium Offset Insufficient Funds',
      'Premium Offset Canceled',
    ])
  }

  service.overloan = function() {
    return containsOneOf(type, [
      'Premium Offset',
      'Premium Offset Overloan',
      'Premium Offset Insufficient Funds',
      'Premium Offset Canceled',
    ])
  }

  service.suspenseAmount = function() {
    return containsOneOf(type, [
      'Net Amount at Risk',
    ])
  }

  service.newDeathBenefitAmount = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Net Amount at Risk',
    ])
  }

  service.stepUpDate = function() {
    return containsOneOf(type, [
      'Step Up Letter Sent',
    ])
  }

  service.rider = function() {
    return containsOneOf(type, [
      'Step Up Letter Sent',
      'Living Benefit Maturity Letter Sent',
    ])
  }

  service.contractValue = function() {
    return containsOneOf(type, [
      'Living Benefit Maturity Letter Sent',
      'Rider Renewal',
    ])
  }

  service.maturityDate = function() {
    return containsOneOf(type, [
      'Living Benefit Maturity Letter Sent',
    ])
  }

  service.maturityDateMax = function() {
    return containsOneOf(type, [
      'Living Benefit Maturity Letter Sent',
    ])
  }

  service.ageMax = function() {
    return containsOneOf(type, [
      'Living Benefit Maturity Letter Sent',
    ])
  }

  service.riderBaseAmount = function() {
    return containsOneOf(type, [
      'Rider Renewal',
    ])
  }

  service.attainedAge = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.termConversionPeriodEndDate = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.hasYearsToEvidence = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.conversion = function() {
    return type === 'Term Conversion'
  }

  service.dateLevelPeriodEndDate = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.effectiveDate = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.policyYear = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.paidToDate = function() {
    return containsOneOf(type, [
      'Term Conversion',
      'Term Anniversary (No Conversion)',
    ])
  }

  service.currentRate = function() {
    return containsOneOf(type, [
      'Term Requalification Rates',
    ])
  }

  service.futureRate = function() {
    return containsOneOf(type, [
      'Term Requalification Rates',
    ])
  }

  service.rateChange = function() {
    return containsOneOf(type, [
      'Term Requalification Rates',
    ])
  }

  service.paymentMode = function() {
    return getCategory() === CONSTANTS.alertGroups.earlyWarning ||
        getCategory() === CONSTANTS.alertGroups.conservation
  }

  service.earlyWarningFields = function() {
    return getCategory() === CONSTANTS.alertGroups.earlyWarning
  }

  service.conservationFields = function() {
    return getCategory() === CONSTANTS.alertGroups.conservation
  }

  return service

    /** *** Private Functions *****/

  function containsOneOf(alertType, arr) {
    return arr.indexOf(alertType) !== -1
  }

  function getCategory() {
    const conservationTypes = ['Lapsed', 'Pre Lapse - Policy', 'Pre Lapse - GCP', 'Pre Lapse - GWB']
    const earlyWarningTypes = ['UL Early Warning']

    if (containsOneOf(type, conservationTypes)) {
      return CONSTANTS.alertGroups.conservation
    } else if (containsOneOf(type, earlyWarningTypes)) {
      return CONSTANTS.alertGroups.earlyWarning
    }
    return ''
  }
}
