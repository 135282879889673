(function() {
  'use strict'
  angular.module('app.directives').directive('columnSortIcon', [function() {
    return {
      restrict: 'E',
      scope: {
        isSorting: '=',
        sortReversed: '=',
      },
      templateUrl: 'app/widgets/templates/column-sort-icon.html',
      controller: [function() {}],
      controllerAs: 'wgColumnCtrl',
      bindToController: true,
    }
  }])
})()
