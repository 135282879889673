import { INgModelController } from "angular"

class CheckBoxController implements ng.IController {
  static $inject = ['$element', '$scope']

  ngModel: INgModelController
  disabled: boolean
  checked: boolean

  constructor(private $element, private $scope) { }

  toggle(): void {
    if(this.disabled) return

    this.ngModel.$setViewValue(!this.ngModel.$viewValue)
    this.checked = Boolean(this.ngModel.$viewValue)
  }

  // $onInit() {
  //   this.checked = Boolean(this.ngModel.$viewValue)
  // }

  $doCheck(): void {
    if (this.checked === undefined && typeof this.ngModel.$viewValue === 'boolean'){
      this.checked = Boolean(this.ngModel.$viewValue)
    }
  }
}

export const CheckBoxComponent = {
  templateUrl: 'app/components/check-box/check-box.html',
  controller: CheckBoxController,
  controllerAs: 'checkBoxCtrl',
  transclude: true,
  bindings: {
    disabled: '=',
  },
  require: {
    ngModel: 'ngModel',
  },
}
