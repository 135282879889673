import { IController, IOnChangesObject } from 'angular'
import { ILobSection } from './types'
import { FormFilterService } from './form-filter-service'
import { deepArrayOrObjectCopy } from '../../utils/utils'

class CompanyFormsController implements IController {
  static $inject: string[] = ['formFilterService']

  originalSections: ILobSection[]
  sections: ILobSection[] | ILobSection
  policyForms: boolean
  searchText: string

  constructor(private formFilterService: FormFilterService) {}
  // $onInit(): void {
  // }

  $onChanges(onChangesObj: IOnChangesObject): void {
    // Handle sections updates
    if (onChangesObj.sections) {

      // handle initial change to sections
      if (onChangesObj.sections?.isFirstChange() || !this.originalSections) {
        console.log(onChangesObj.sections)
        this.policyForms = !Array.isArray(this.sections)

        // When not policyForms make a backup the the sections.
        if(!this.policyForms) {
          this.originalSections = deepArrayOrObjectCopy(onChangesObj.sections.currentValue) as ILobSection[]
        }
      }
    }

    if (onChangesObj.searchText && !this.policyForms) {
      if (onChangesObj.searchText.currentValue) {
        this.sections = this.formFilterService.filterForms(this.searchText, this.originalSections)
      } else {
        this.sections = deepArrayOrObjectCopy(this.originalSections) as ILobSection[]
      }
    }
  }
}

const CompanyForms = {
  templateUrl: 'app/components/companies/company-forms.html',
  controller: CompanyFormsController,
  controllerAs: 'ctrl',
  bindings: {
    sections: '<',
    searchText: '<',
  },
}

export { CompanyForms }
