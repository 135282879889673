import * as angular from 'angular'

import { ContactController } from './contact-controllers'
import {MailingAddressesController} from './mailing-addresses/mailing-addresses-component'
import { ContactUsController } from './us/contact-us.controller'
import { ContactUsRunner } from './us/contact-us.routes'
import { ContactUsAttachmentsController } from './us/contact-us-attachments'

angular.module('app.contact', ['app.core'])
.component('mailingAddresses', {
  templateUrl: 'app/contact/mailing-addresses/mailing-addresses.html',
  controller: MailingAddressesController,
  controllerAs: 'ctrl',
  bindings: {
    someNonNyPolicies: '=',
    someNyPolicies: '<',
    allNyPolicies: '<',
  },
})
.controller('ContactController', ContactController)
// .controller('ContactUsController', ContactUsController)
.component('contactUs', {
  templateUrl: 'app/contact/us/contact-us.html',
  controller: ContactUsAttachmentsController,
  controllerAs: 'contactUsCtrl',
  bindings: {
  },
})
.run(routerHelper => new ContactUsRunner(routerHelper))
