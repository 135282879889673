import { IAngularEvent } from 'angular'

const ALLOWED_KEYS: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
export class SingleInputController {

  static inject = ['$element', '$scope']

  char: string[] = []
  code: string = ""
  submit: any
  inputCollection: HTMLInputElement[]

  constructor(private $element: any, private $scope: any) {}

  handleKeyup(event: KeyboardEvent, index: number): void {
    const prevIndex = index > 0 ? index - 1 : 0

    if (ALLOWED_KEYS.includes(event.key)) {
      this.char[index] = event.key

      if (index < 5) {
        this.inputCollection[index + 1].focus()
      } else {
        this.code = this.char.join('')
        console.log(this.char, this.code)
      }
    } else {
      if(event.key === "Backspace") {
        this.inputCollection[prevIndex].focus()
        this.inputCollection[index].value = this.char[index]
      }

    }
  }

  handleFocus($event: Event): void {
    const target: HTMLInputElement = $event.target as HTMLInputElement

    target.select()
  }

  $onInit(): void {
    this.inputCollection = Array.prototype.slice.apply(this.$element.find('input'))
    // this.$element.on('change', (event) => this.handleChange(event))

    this.$element.on('paste', (event: ClipboardEvent) => {
      const pastedText: string = event.clipboardData?.getData('text') || ''

      event.preventDefault()

      this.inputCollection.forEach((e, i) => e.value = pastedText.charAt(i))
      this.code = pastedText

      this.$scope.$applyAsync()
    })

    this.$scope.$on('$destroy', () => {
      // clearCollectionWatch()
      // clearWatch()
    })

    this.inputCollection[0].focus()
  }
}
