/* eslint-disable no-underscore-dangle */
import { Beneficiary } from '../../client/beneficiaries/classes/beneficiary'
import { ANNUITANT_ROLES, ASSIGNEE_ROLES, OWNER_ROLES } from '../../client/beneficiaries/constants/ROLES'

export const PARTY_ROLE_KEYS = {
  INSUREDS: 'insureds',
  OWNERS: 'owners',
  BENEFICIARIES: 'beneficiaries',
}

export const PARTY_TYPE_ORGANIZATION = '2'
export class ClientView {
  role: string = ''
  isAnnuity: boolean = false
  hasStepInAnnuitant: boolean = false
  hasSuccessorOwner: boolean = false
  isAssigned: boolean = false
  disabled: boolean = false
  hasMultiple: boolean = false
  isOrganization: boolean = false
  dropdownIsOpen: boolean = false

  private _list: any[] = []
  private _stepInAnnuitant: any
  private _successorOwner: any

  constructor(role: string, isAnnuity: boolean, isAssigned: boolean, parties: any[]) {
    this.role = role
    this.isAnnuity = isAnnuity
    this.isAssigned = isAssigned
    this._list = parties
    this._stepInAnnuitant = this._list.find((insured: any) => insured.role.tc === ANNUITANT_ROLES.STEP_IN_ANNUITANT)
    this.hasStepInAnnuitant = role === PARTY_ROLE_KEYS.INSUREDS && this.isAnnuity && Boolean(this._stepInAnnuitant)
    this._successorOwner = this._list.find((owner: any) => owner.role.tc === OWNER_ROLES.SUCCESSOR_OWNER)
    this.hasSuccessorOwner = role === PARTY_ROLE_KEYS.OWNERS && this.isAnnuity && Boolean(this._successorOwner)
    this.hasMultiple = this.nameLabelCount > 1

    if (this.hasSuccessorOwner) {
      this.isOrganization = this._successorOwner?.partyTypeCode?.tc === PARTY_TYPE_ORGANIZATION
    } else {
      this.isOrganization = this.list?.[0]?.partyTypeCode?.tc === PARTY_TYPE_ORGANIZATION
    }
  }

  /**
   * - When insured and there is a "Step In Annuitant", just return it in a new array.
   * - When owner and there is a "Successor Owner", just return it in a new array.
   * - Otherwise just return the original list parties for the role.
   */
  get list(): any[] {
    let finalList: any[] = this._list

    switch (this.role) {
      case PARTY_ROLE_KEYS.INSUREDS:
        if (this.hasStepInAnnuitant) finalList = [this._stepInAnnuitant]
        break
      case PARTY_ROLE_KEYS.OWNERS:
        if (this.hasSuccessorOwner) finalList = [this._successorOwner]
        break
    }

    return finalList
  }

  /**
   * Returns a count of parties in the list property.
   *
   * When the role is PARTY_ROLE_KEYS.OWNERS, we need to exlude assignees from the labelCount
   */
  get nameLabelCount(): number {
    return this.list.reduce((count, party) => {
      if (this.role === PARTY_ROLE_KEYS.OWNERS) {
        if (!Object.values(ASSIGNEE_ROLES).includes(party.role.tc)) count++
      } else {
        count++
      }

      return count
    }, 0)
  }

  /**
   * Create the correct tab title based on annuity vs life contracts, and how many parties there
   * are for the role. Also, account for "Step In Annuitants", and "Successor Owners." Also,
   * ensures the correct plualization is applied.
   */
  get multipleLabel(): string {
    // {{wgPolicyCtrl.policy.insured.isMultiple ? 'Multiple ' : ''}}
    // {{wgPolicyCtrl.policy.isAnnuity ?'Annuitant' : 'Insured'}}
    // {{ wgPolicyCtrl.policy.insured.isMultiple && wgPolicyCtrl.policy.isAnnuity ? 's' : '' }}

    const assignedLabel = this.role === PARTY_ROLE_KEYS.OWNERS && this.isAssigned ? ' (Assigned)' : ''

    let typeLabel
    let multipleLabel = this.hasMultiple ? 'Multiple ' : ''
    let pluralLabel = this.hasMultiple ? 's' : ''

    switch (this.role) {
      case 'insureds':
        if (this.isAnnuity) {
          if (this.hasStepInAnnuitant) {
            typeLabel = 'Step In Annuitant'
            pluralLabel = ''
            multipleLabel = ''
          } else {
            typeLabel = 'Annuitant'
          }
        } else {
          typeLabel = 'Insured'
        }
        break

      case 'owners':
        if (this.isAnnuity && this.hasSuccessorOwner) {
          typeLabel = 'Successor Owner'
          pluralLabel = ''
          multipleLabel = ''
        } else {
          typeLabel = 'Owner'
        }
        break

      case 'beneficiaries':
        typeLabel = 'Beneficiar'
        pluralLabel = this.hasMultiple ? 'ies ' : 'y '
        break
    }

    return `${multipleLabel}${typeLabel}${pluralLabel}${assignedLabel}`.trim()
  }

  /**
   * Displays the correct party full name, and addional parties for the same role.
   * Also, account for "Step In Annuitants", and "Successor Owners."
   */
  get nameLabel(): string {
    let additionalLabel = this.hasMultiple ? ` +${this.nameLabelCount - 1}` : ''
    let partyName = this.list[0]?.fullName ?? ''

    if (this.isAnnuity && this.role === PARTY_ROLE_KEYS.INSUREDS && this.hasStepInAnnuitant) {
      partyName = this._stepInAnnuitant.fullName
      additionalLabel = ''
    } else if (this.isAnnuity && this.role === PARTY_ROLE_KEYS.OWNERS && this.hasSuccessorOwner) {
      partyName = this._successorOwner.fullName
      additionalLabel = ''
    }

    return `${partyName}${additionalLabel}`
  }

  get nameSimpleNameLabel(): string {
    return this.primaryParty?.fullName
  }

  get primaryParty(): Beneficiary {
    return this._list[0]
  }

  get partyIconClass(): string {
    // Below are the existing logic blocks for how insight/client handle which icon to display.
    // this getter function refectors this logic for the client portal. It could easily be
    // used in insight as well.

    // {'icon-multiple-users': wgPolicyCtrl.policy.insured.isMultiple,
    // 'icon-user': !wgPolicyCtrl.policy.insured.isAnOrganization && !wgPolicyCtrl.policy.insured.isMultiple,
    // 'icon-business': wgPolicyCtrl.policy.insured.isAnOrganization && !wgPolicyCtrl.policy.insured.isMultiple}

    // {'icon-multiple-users': wgPolicyCtrl.policy.owner.isMultiple,
    // 'icon-user': !wgPolicyCtrl.policy.owner.isAnOrganization && !wgPolicyCtrl.policy.owner.isMultiple,
    // 'icon-business': wgPolicyCtrl.policy.owner.isAnOrganization && !wgPolicyCtrl.policy.owner.isMultiple}

    // { 'icon-multiple-users': wgPolicyCtrl.policy.beneficiary.isMultiple,
    // 'icon-user': !wgPolicyCtrl.policy.beneficiary.isAnOrganization && !wgPolicyCtrl.policy.beneficiary.isMultiple,
    // 'icon-business': wgPolicyCtrl.policy.beneficiary.isAnOrganization && !wgPolicyCtrl.policy.beneficiary.isMultiple }

    return this.hasMultiple ? 'icon-multiple-users' : this.primaryParty?.isPerson ? 'icon-user' : 'icon-business'
  }
}

export default class PolicyRelativesViewManager {
  insureds: ClientView
  owners: ClientView
  beneficiaries: ClientView

  constructor(clients: any, isAnnuity: boolean, isAssigned: boolean) {
    this.insureds = new ClientView(PARTY_ROLE_KEYS.INSUREDS, isAnnuity, isAssigned, [].concat(clients.insureds, clients.annuitants))
    this.owners = new ClientView(PARTY_ROLE_KEYS.OWNERS, isAnnuity, isAssigned, [].concat(clients.owners, clients.assignees))
    this.beneficiaries = new ClientView(PARTY_ROLE_KEYS.BENEFICIARIES, isAnnuity, isAssigned, clients.beneficiaries)
  }

  openDropdown(role: string): void {
    Object.values(this).forEach((viewState: ClientView) => {
      viewState.dropdownIsOpen = viewState.role === role
    })
  }

  closeDropdowns(): void {
    Object.values(this).forEach((viewState: ClientView) => {
      viewState.dropdownIsOpen = false
    })
  }
}
