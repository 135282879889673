import * as angular from 'angular'
import {StateService, StateParams} from '@uirouter/core'
interface ClientDataDTO {
  id: string
  fullName: string
  birthDate?: any
  loaded: boolean
  applications: any[]
  policies: any[]
}

export class ClientController {

  public isThereError: boolean
  public numAlertsFound: number
  public errorMessage: string
  public moreData: boolean = true
  public clientId: string
  public isLoading: boolean = false
  public currentPage: number
  public isRpsPolicy: boolean
  private requestedPages: boolean[] = []
  public companies: any
  public alerts: []
  public isClientBirthday: boolean
  public isInforcePolicy: boolean
  public isPendingPolicy: boolean
  CHANNELS: string
  CLIENT_CHANNELS: string
  downForMaintenance: any
  payablePids: []
  payablePolicies: []
  private dotPolNumber: string

  clientData: ClientDataDTO

  constructor(private $q: angular.IQService, private $rootScope, private $state: StateService, private utils, private authService, private clientUtils, private policyUtils, private clientMeService, private eventsService,
              private alertService, private CONSTANTS, private messages, private notificationCenterService, payablePoliciesResult, private stringUtils, private clientAdvisorService, private lazyLoadingDataService) {

    this.payablePolicies = payablePoliciesResult.data && (payablePoliciesResult.data.actions || [])
    this.isInforcePolicy = policyUtils.isInforcePolicy
    this.isPendingPolicy = policyUtils.isPendingPolicy
    this.isRpsPolicy = policyUtils.isRpsPolicy

    // first page is loaded automatically
    this.dotPolNumber = this.utils.dot('polNumber')

    /**
     * Checking if the current user is a real client is required because
     * this controller is shared between two routes; one for Insight and
     * one for Client Portal. As a client we need to start a zero
     * because no request for pages has happend yet.  Whereas Insight
     * needs to start at 1 because the 'client/me' route's resolver gets
     * the first page for us.
     *
     * This complicated by the fact that there are two functions
     * (`nextPage` used by Client Portal, and `nextPolicyPage` use by Insight)
     * increment `currentPage` after querying.
     */
    this.currentPage = this.authService.isARealClient() ? 1 : 1
    this.CHANNELS = this.CONSTANTS.notificationType.client + ',' + this.CONSTANTS.notificationType.systemUnavailability
    this.CLIENT_CHANNELS = this.CONSTANTS.notificationType.client + ',' + this.CONSTANTS.notificationType.system + ',' + this.CONSTANTS.notificationType.systemUnavailability
    this.downForMaintenance = {}
    this.errorMessage = ''
    this.isThereError = false
    this.numAlertsFound = 0
    this.payablePids = []

    this.clientData = {
      loaded: false,
      id: '',
      applications: [],
      policies: [],
      fullName: '',
    }
    this.init()
  }

  _initClientInfo() {
    return this.lazyLoadingDataService.myPolicies(true) // true forces the data to be request every time.
        .then((result) => {
          if (result.client) {
            let initialPaymentPolicyId = this.clientMeService.initialPaymentPolicyId(result.client)

            if (initialPaymentPolicyId) {
              this.$state.go('epay.policy.payment', {
                id: initialPaymentPolicyId,
              })

              return Promise.reject(new Error('Redirect to initial policy for payment.'))
            }

            this.clientData = result.client
            this.setApplicationsAndPolicies(this.clientData.policies || [])
            this.clientData.loaded = true
            this.clientData.id = this.$state.params.id

            // only populated for client side
            if (this.payablePolicies.length > 0) {
              this.indicatePayablePolicies(this.clientData.policies, this.payablePolicies)
            }
            // this.updatePageTitle()
            this.processEvents()
          }
          return result
        })
  }

  nextPage() {
    if (this.requestedPages.length <= this.currentPage) {
      this.isLoading = true
      this.requestedPages[this.currentPage] = true
      return this.clientMeService.getClientPolicies(this.currentPage)
          .then((results) => {
            if (results.client && results.client.policies && results.client.policies.length) {
              this.appendApplicationsAndPolicies(results.client.policies)
              this.currentPage++
              this.isLoading = false
              this.indicatePayablePolicies(this.clientData.policies, this.payablePolicies)
              this.companies = results.client.policiesInfo.companies || []
              // this.updatePageTitle()

              return results // for unit testing

              // this.getRequirementsMetPercents()  // comment out ** clients are not supposed to see the requirements
            } else {
              this.isLoading = false
            }
          },
          )
    }

    return this.$q.resolve()
  }

  isScrollDisabled() {
    return !this.moreData || this.isLoading
  }

  public goToAlerts() {
    this.$state.go('clientAlerts', {
      id: this.clientData.id,
    })
  }

  public openMap(address) {
    let url = 'http://maps.google.com/?q=' + address
    let target = '_blank'

    if (address) {
      // @ts-ignore
      if (window.device) {
        // @ts-ignore
        switch (window.device.platform) {
          case 'Android':
            url = 'geo:0,0?q=' + address
            break
          case 'iOS':
            url = 'maps:q=' + address
            break
        }

        target = '_system'
      }
      window.open(encodeURI(url), target, 'location=yes')
    }
  }

  processEvents() {
    this.isClientBirthday = this.eventsService.processBirthdayEvents(this.clientData.fullName, this.clientData.birthDate)
    // self.isClientBirthday is used by <wg-client-info-header used in both views
  }

  isPayablePolicy(policy, payables) {
    return payables.reduce((isPayable, payablePolicy) => {
      if (payablePolicy.pid === policy.polNumber) {
        isPayable = Boolean(payablePolicy.actions.filter(action => {
          // console.debug(" Number(action.tc) === 1", Number(action.tc), Number(action.tc) === 1)
          return Number(action.tc) === 1
        })[0])
      }

      return isPayable
    }, false)
  }

  isUniversalLifePolicy(policy) {
    return policy.productType === this.CONSTANTS.productType.universalLife ||
        policy.productType === this.CONSTANTS.productType.IUL ||
        policy.productType === this.CONSTANTS.productType.variableUniversalLife
  }

  indicatePayablePolicies(policies, payables) {
    policies.forEach((policy) => {
      policy.isPayable = this.isPayablePolicy(policy, payables) && !this.isUniversalLifePolicy(policy)
      // pidList.indexOf(policy.polNumber) !== -1
    })

    const count = policies.filter(policy => policy.isPayable).length

    console.debug('Total Payable Policies: %s', count)
  }

  getFullName(firstName, lastName) {
    return this.clientUtils.getFullName(firstName, lastName)
  }

  countLabel (numberOfThings, noun) {
    let newNoun = numberOfThings === 1 ? noun : this.stringUtils.pluralize(noun)

    return numberOfThings + ' ' + newNoun
  }

  initialPaymentClick(policyId) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'goInitialPayment',
        pid: policyId,
      })
    }
  }

  private appendApplicationsAndPolicies(policiesFromAPI) {
    const separatedApplicationsAndPolicies = this.separateApplicationsFromPolicies(policiesFromAPI)
    const newApplications = separatedApplicationsAndPolicies[0]
    const newPolicies = separatedApplicationsAndPolicies[1]

    this.clientData.applications = this.clientData.applications.concat(newApplications)
    this.clientData.policies = this.clientData.policies.concat(
        this.filterOutDuplicatePendingPolicies(newPolicies),
    )
    // this.updatePageTitle()
  }

  /* addMoreApplicationsAndPolicies(policiesFromAPI) {
     let separatedApplicationsAndPolicies = this.separateApplicationsFromPolicies(policiesFromAPI)
     let additionalPolicies = this.filterOutDuplicatePendingPolicies(separatedApplicationsAndPolicies[1])

     this.clientData.policies.concat(additionalPolicies)
     // = this.clientData.policies.concat(additionalPolicies)
     this.clientData.applications = separatedApplicationsAndPolicies[0]
     this.updatePageTitle()
   }*/

  private filterOutDuplicatePendingPolicies(policies) {
    const inforcePolicyNumbers = policies.filter(this.policyUtils.isInforcePolicy)
        .map(this.dotPolNumber)

    return policies.filter((policy) => {
      if (this.policyUtils.isPendingPolicy(policy) && inforcePolicyNumbers.indexOf(policy.polNumber) !== -1) {
        return false
      }

      return true
    })
  }

  private separateApplicationsFromPolicies(policies) {
    return this.utils.partition(this.policyUtils.isApplication, policies) // returns [applications, policies]
  }

  get hidePolicySection(): boolean {
    return ['client.agent.manageAddresses', 'client.agent.e-delivery'].includes(this.$state.current.name)
  }

  setApplicationsAndPolicies(policiesFromAPI) {
    const separatedApplicationsAndPolicies = this.separateApplicationsFromPolicies(policiesFromAPI)

    this.clientData.applications = separatedApplicationsAndPolicies[0]
    this.clientData.policies = this.filterOutDuplicatePendingPolicies(separatedApplicationsAndPolicies[1])
  }

  updatePageTitle() {
    // @TODO user Page component instead of rootScope
    this.$rootScope.pageTitle = this.clientData.fullName + ' ' + this.countLabel(this.clientData.policies.length, 'Policy')
    this.$rootScope.mobileHeaderTitle = this.$rootScope.pageTitle
  }

  nextPolicyPage() {
    this.isLoading = true

    return this.clientAdvisorService.getClientInfo(this.clientId, this.currentPage + 1)
        .then((clientInfoResult) => {
          if (clientInfoResult.error) {
            this.moreData = false
            this.errorMessage = clientInfoResult.error
            this.isLoading = false
          } else {
            this.appendApplicationsAndPolicies(clientInfoResult.client.policies)
            this.currentPage++
            this.populateRequirementsMetPct(clientInfoResult.client.policies)
            this.isLoading = false
          }
        })
  }

  populateRequirementsMetPct(policies) {

    if (!policies) {
      return this.$q.resolve()
    }

    let policiesToProcess = policies || this.clientData.policies

    policiesToProcess.forEach(policy => {
      policy.requirementInfo = this.policyUtils.calcRequirementsMet(policy.requirementsStatus)
    })

  }

  loadRelatedPolicies(clientId) {
    this.isLoading = true
    this.clientAdvisorService.getClientInfo(clientId)
        .then((clientInfoResult) => {
          if (clientInfoResult.error) {
            this.errorMessage = clientInfoResult.error
          } else if (clientInfoResult.client) {
            this.clientData = clientInfoResult.client
            this.clientData.loaded = true
            this.setApplicationsAndPolicies(clientInfoResult.client.policies)
            this.populateRequirementsMetPct(clientInfoResult.client.policies)
            // this.updatePageTitle()
            this.isLoading = false
          }
        })
  }

  init() {
    if (this.$rootScope.auth.isAgentView()) {
      if (this.$state.params.id) {
        this.clientData.id = this.$state.params.id
        this.clientId = this.$state.params.id
        this.loadRelatedPolicies(this.clientId)

        this.alertService.getAlertsByClientData(this.clientData).then((response) => {
          this.alerts = response.alertList
          this.numAlertsFound = response.numFound
        })
      } else {
        this.errorMessage = this.CONSTANTS.notFoundMessage
      }
    } else {
      this._initClientInfo()
    }
    this.isThereError = Boolean(this.errorMessage)
    if (this.messages) {
      this.messages.forEach((message) => {
        this.downForMaintenance = this.notificationCenterService.add(message.type, message.text, this.CONSTANTS.notificationType.systemUnavailability, this.CONSTANTS.eventId.SYSTEM + message.id, {
          status: 'shown',
        })
      })
    }
  }

}

ClientController.$inject = ['$q', '$rootScope', '$state', 'utils', 'authService', 'clientUtils', 'policyUtils', 'clientMeService', 'eventsService', 'alertService', 'CONSTANTS', 'messages', 'notificationCenterService', 'payablePolicies', 'stringUtils', 'clientAdvisorService', 'lazyLoadingDataService']
