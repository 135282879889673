import { ICompanyForms, IFormDescriptor, IFormsManifest, ILobSection, ILobSectionType } from './types'

export class FormFilterService {
  static $inject: string[] = ['utils']

  getters: any[]

  constructor(private utils: any) { }

  filterForms(searchText: string, sourceLobSections: ILobSection[]): ILobSection[] {
    const regex: RegExp = new RegExp(searchText, 'gi')
    const filterLobSections: ILobSection[] = this.utils.deepArrayOrObjectCopy(sourceLobSections)

    filterLobSections.forEach((lobSection: ILobSection) => {
      lobSection.sectionTypes.forEach((lobSectionType: ILobSectionType) => {
        lobSectionType.forms = lobSectionType.forms.filter((form) => regex.test(form.displayName))

      })
    })

    return filterLobSections
  }
}
