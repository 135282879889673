/**
 * Renders the <alert-nav-menu />
 */
import {AlertService} from './alert-service'
import {AlertDismissService} from './alert-dismiss-service'
export class AlertNavMenuDirective implements ng.IDirective {

  restrict = 'E'
  templateUrl = 'app/alert/tmpl/alert-nav-menu.html'
  replace = false
  scope = {
    isAgentView: '=',
    toggleAlerts: '=',  // Function to call when the close button is clicked
  }

  constructor(private $q: angular.IQService, private authService, private alertService: AlertService, private alertDismissService: AlertDismissService,
    private navigationService) { }

  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    scope.dismissAlertsActive = false

    scope.showCategory = (category: string) => this.navigationService.goAlerts(category)
    scope.dismissAlerts = () => {
      this._dismissAlerts()
    }

    scope.$on('activeAgentChanged', () => this.updateUI(scope))
    scope.$on('alertNumberUpdate', () => this.updateUI(scope))

    // Wireup listeners related to updating the alerts icon.
    this.alertDismissService.subscribeCallback(() => this._setDismissAlertsActive(scope))

    this.updateUI(scope)
  }

  loadAlerts(): any {
    if (this.authService.isAuthenticated() && !this.authService.isAClient()) {
      return this.alertService.getAlertsSummary()  // NO ARGS!!!!
        .then(response => {
          return response['categories'] // '?' get around compile issue with broken type.  We are returning the whole alertSerice??!
        })

    } else {
      return this.$q.resolve({})
    }
  }

  updateUI(scope) {
    this.loadAlerts()
      .then((categories: any) => {
        scope.categories = categories
      })
  }

  _setDismissAlertsActive(scope) {
    let dismissAmt = this.alertDismissService.categoryBuffer.length

    scope.dismissAlertsActive = dismissAmt > 0
  }

  _dismissAlerts(): void {
    const toBeDeletedCount: number = this.alertDismissService.countAlertsToBeDismissed(this.alertService.categories)
    const expectedAlertCount: number = this.alertService.numAlerts - toBeDeletedCount

    this.alertDismissService.dismiss()
      .then(() => this.alertService.pollSummary(expectedAlertCount))
      .catch(err => console.error(err))

  }

  /**
   * Creates an instance of AlertNavMenuDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = ($q, authService, alertService: AlertService, alertDismissService, navigationService) => new AlertNavMenuDirective($q, authService, alertService, alertDismissService, navigationService)

    directive.$inject = ['$q', 'authService', 'alertService', 'alertDismissService', 'navigationService']

    return directive
  }
}
