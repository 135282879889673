configure.$inject = ['$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider', '$locationProvider']

const config = {
  appErrorPrefix: '[pml Error] ',
  appTitle: 'pml',
}

/* @ngInject */
function configure($logProvider, routerHelperProvider, exceptionHandlerProvider, $locationProvider) {
  $locationProvider.html5Mode(false)
  $locationProvider.hashPrefix('')

  if ($logProvider.debugEnabled) {
    $logProvider.debugEnabled(false)
  }

  exceptionHandlerProvider.configure(config.appErrorPrefix)
  routerHelperProvider.configure({
    docTitle: config.appTitle + ': ',
  })
}

export {configure, config}
