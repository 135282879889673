import angular from 'angular'
import { BENEFICIARY_VIEWS } from '../constants/BENEFICIARY_VIEWS'

class ChangeButtonsController {

  /** Declare Bindings */
  disableSaveButton: boolean
  viewState: any
  onEdit: Function
  onSave: Function
  onUndo: Function
  onSubmit: Function
  userType: string

  save() {
    if(this.userType === 'client') {
      this.onSubmit()
    } else {
      switch (this.viewState.state) {
        case BENEFICIARY_VIEWS.EDIT:
          this.onSave()
          break
        case BENEFICIARY_VIEWS.REVIEW:
          this.onSubmit()
          break
      }
    }

  }

  // $onInit() {
  //   console.log(this)
  // }

//   $onChanges(c) {
//     console.log('changeButtons', c)
//   }
}

angular.module('app.beneficiaries')
  .component('changeButtons', {
    bindings: {
      viewState: '<',
      disableSaveButton: '<',
      onEdit: '&',
      onSave: '&',
      onSubmit: '&',
      onUndo: '&',
      userType: '<',
    },
    templateUrl: 'app/client/beneficiaries/change-buttons/change-buttons.html',
    controller: ChangeButtonsController,
    controllerAs: 'ctrl',
  })
