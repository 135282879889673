export const CLIENT_CARD_OPTIONS = {
  INSURED: [
    {
      display: 'Edit Address',
    },
  ],
  OWNER: [
    {
      display: 'Edit Client Details',
    },
    {
      display: 'Edit Address',
    },
    {
      display: 'Replace With Another',
    },
    {
      display: 'Remove From Role',
    },
  ],
  PAYER: [
    {
      display: 'Edit Client Details',
    },
    {
      display: 'Edit Address',
    },
    {
      display: 'Replace With Another',
    },
  ],
}

// const BENEFICIARY_SOURCE_OBJECT_TYPES = {
//   CURRENT_BENEFICIARY: 'CURRENT_BENEFICIARY',
//   BENEFICIARY_INSTANCE: 'BENEFICIARY_INSTANCE',
//   ASSOCIATED_PARTY: 'ASSOCIATED_PARTY',
//   NEW_PARTY_OPTION: 'NEW_PARTY_OPTION',
// }
