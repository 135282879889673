(function() {
  'use strict'

  angular.module('app.directives').directive('wgLinearLoader', [function() {
    return {
      restrict: 'E',
      transclude: true,
      template: '<div class="linear-loader"><div class="dot-01"></div><div class="dot-02"></div><div class="dot-03"></div></div>',
    }
  }])
})()
