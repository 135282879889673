import {stringUtils as StringUtils} from '../utils/string-utils'

const stringUtils = StringUtils()

export {alertsRun}
alertsRun.$inject = ['routerHelper']

/* @ngInject */
function alertsRun(routerHelper) {
  routerHelper.configureStates(getAlertStates())
}

function getAlertStates() {
  return [{
    state: 'alerts',
    config: {
      url: '/myclients/alerts/{category}',
      templateUrl: 'app/alert/alert-view.html',
      controller: 'MyAlertsController',
      controllerAs: 'alertsCtrl',
      resolve: {
        alertSummary: ['alertService',
          function(alertService) {
            return alertService.getAlertsSummary()
          },
        ],
      },
      data: {
        secure: true,
      },
      onEnter: ['$rootScope', '$stateParams', function($rootScope, $stateParams) {
            // var pageTitle = $stateParams.category ? "Alerts - " + capitalize($stateParams.category) : "Alerts";
        const pageTitle = 'Alerts'

        $rootScope.pageTitle = pageTitle
        $rootScope.mobileHeaderTitle = pageTitle
        $rootScope.state = 'alert-view'
        $rootScope.isPopupVisible = false
      }],
      onExit: ['$rootScope', '$stateParams', function($rootScope, $stateParams) {
        $rootScope.isPopupVisible = false
      }],
    },
  },
  {
    state: 'alerts.detail',
    config: {
      url: '/detail/{aid}',
      templateUrl: 'app/alert/alert-detail-view.html',
      resolve: {
        alertDetailsResult: ['alertService', '$stateParams',
          function(alertService, $stateParams) {
            return alertService.getAlertDetails($stateParams.aid)
          },
        ],
      },
      controller: ['$scope', 'alertDetailsResult', function($scope, alertDetailsResult) {
        $scope.alertDetailsResult = alertDetailsResult
      }],
      onEnter: ['$rootScope', '$stateParams', function($rootScope, $stateParams) {
        $rootScope.isPopupVisible = true
        $rootScope.state = 'alert-detail'
        $rootScope.pageTitle = $stateParams.category ? 'Alerts - ' + stringUtils.capitalize($stateParams.category) + ' Detail' : 'Alerts Detail'
      }],
      onExit: ['$rootScope', '$stateParams', function($rootScope, $stateParams) {
        const pageTitle = $stateParams.category ? 'Alerts - ' + stringUtils.capitalize($stateParams.category) : 'Alerts'

        $rootScope.pageTitle = pageTitle
        $rootScope.isPopupVisible = false
      }],
    },
  },
  ]
}
