/**
 * Renders the match directive
 */

class MatchDirective implements ng.IDirective {

  restrict = 'C'
  replace = false
  require = 'ngModel'
  scope = {
    ngModel: '=',
    comparedToValue: '=',
  }

  // constructor() {}

  _validate(scope, modelValue) {
    return scope.comparedToValue === modelValue
  }

  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes, ctrl: any) {
    ctrl.$validators.match = this._validate.bind(this, scope)
  }

  /**
   * Creates an instance of MatchDirective
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new MatchDirective()

    directive.$inject = []

    return directive
  }
}

angular.module('app.directives')
  .directive('match', MatchDirective.factory())
