export enum LOG_LEVEL {
  info = 'info',
  warn = 'warn',
  error = 'error'
}
export class LoggingService {
  static $inject: string[] = ['$http']

  apiPrefix: string = '/logging' //

  constructor(private $http: angular.IHttpService) { }

  log(message: string, level: LOG_LEVEL | string = LOG_LEVEL.info, module: string = 'general'): void {
    const messagePayload = { message, level, module }

    console[level](message)

    this.$http.post(this.apiPrefix, messagePayload)
      .then(response => response.data)
      .catch(err => console.error(err))
  }

  info = (message: string, module?: string): void => this.log(message, LOG_LEVEL.info, module)
  warn = (message: string, module?: string): void => this.log(message, LOG_LEVEL.warn, module)
  error = (message: string, module?: string): void => this.log(message, LOG_LEVEL.error, module)

}
