import './BENEFICIARY_OTHER_OPTIONS'
import './BENEFICIARY_VIEWS'
import './COUNTRY_CODES'
import './ROLE_DISPLAY_ORDER'
import './SERIALIZABLE_PROPERTIES'
import './PHONE_TYPES'

import { ANNUITANT_ROLES, BENEFICIARIES_ROLE_ORDER, BENEFICIARY_ROLES, OWNER_ROLES, ROLES_ARRAY, ROLES_ORDER, ROLE_LABELS, ASSIGNEE_ROLES, MISC_ROLES } from './ROLES'
import angular from 'angular'

angular.module('app.beneficiaries') // TODO: Putting this in the app.core module to make unit test happy. Will reorganize during TechDept season 2021.
  .constant('ANNUITANT_ROLES', ANNUITANT_ROLES)
  .constant('BENEFICIARY_ROLES', BENEFICIARY_ROLES)
  .constant('OWNER_ROLES', OWNER_ROLES)
  .constant('ROLE_LABELS', ROLE_LABELS)
  .constant('ROLES_ARRAY', ROLES_ARRAY)
  .constant('ROLES_ORDER', ROLES_ORDER)
  .constant('BENEFICIARIES_ROLE_ORDER', BENEFICIARIES_ROLE_ORDER)
  .constant('ASSIGNEE_ROLES', ASSIGNEE_ROLES)
  .constant('MISC_ROLES', MISC_ROLES)
