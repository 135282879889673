import * as angular from 'angular'
import {BeneficiaryAddress} from './address'
import {Beneficiaries} from './beneficiaries'
import {ClientRole} from './client-role'
import {ClientRoles} from './client-roles'
import './entity-name'
import './role-change-response'
import {PhoneList} from './phone-list'
import {PhoneNumber} from './phone'
import {Beneficiary, BENEFICIARY_SOURCE_OBJECT_TYPES} from './beneficiary'
import {BeneficiaryShare} from './share'
import {TypeCodeValue} from './type-code-value'

angular.module('app.beneficiaries')
    .factory('ClientRole', () => ClientRole)
    .factory('BeneficiaryAddress', () => BeneficiaryAddress)
    .factory('ClientRoles', () => ClientRoles)
    .factory('Beneficiary', () => Beneficiary)
    .factory('Beneficiaries', () => Beneficiaries)
    .factory('PhoneList', () => PhoneList)
    .factory('BENEFICIARY_SOURCE_OBJECT_TYPES', () => BENEFICIARY_SOURCE_OBJECT_TYPES)
    .factory('PhoneNumber', () => PhoneNumber)
    .factory('BeneficiaryShare', () => BeneficiaryShare)
    .factory('TypeCodeValue', () => TypeCodeValue)
