export {SearchController}
SearchController.$inject = ['$rootScope', '$window', '$timeout', '$state', 'searchService', 'pendingPolicyCount', 'deviceUtils', '$location', 'popupManager', 'CONSTANTS', 'messages', 'notificationCenterService', 'notPaidCount', 'utils', 'inforcePolicyCount', 'unsubmittedApplicationCount']

/* @ngInject */
function SearchController($rootScope, $window, $timeout, $state, searchService, pendingPolicyCount, deviceUtils, $location, popupManager, CONSTANTS, messages, notificationCenterService, notPaidCount, utils, inforcePolicyCount, unsubmittedApplicationCount) {
  const self = this
  const MAIN_MENU_ID = CONSTANTS.popups.MAIN_MENU_ID

  self.CHANNELS = CONSTANTS.notificationType.system + ',' + CONSTANTS.notificationType.systemUnavailability
  self.searchService = searchService
  self.isEditing = false
  self.pendingPolicyCount = pendingPolicyCount
  self.inSearchState = false
  self.downForMaintenance = {}
  self.notPaidPolicyCount = notPaidCount
  self.inforcePolicyCount = inforcePolicyCount
  self.unsubmittedApplicationCount = unsubmittedApplicationCount

  self.criteria = $state.params.c

  self.search = function() {
    $window.document.activeElement.blur()

    if (utils.isSsn(self.criteria)) {
      $state.go('search.result', {
        c: '', nonUrlCriteria: self.criteria,
      })
    } else {
      $state.go('search.result', {
        c: self.criteria, nonUrlCriteria: '',
      })
    }
  }

  self.isCurrentPathActive = function(path) {
    const currentPath = $location.path()

    return currentPath.indexOf(path) !== -1 ? 'active' : ''
  }

  self.isMainMenuOpen = function() {
    return popupManager.isOpen(MAIN_MENU_ID)
  }

  self.enterEditionMode = function($event) {
    self.isEditing = true
    self.autoSelectInputText($event)

    if (deviceUtils.isAppleDevice()) {
      $rootScope.fixfixed = true
    }

    return self.isEditing
  }

  self.autoSelectInputText = function($event) {
    if (!utils.isIE()) {
      $event.target.select()
    }
  }

  self.exitEditionMode = function() {
    // This timeout is necessary for the mobile version.
    $timeout(self._exitEditionMode, 200)
  }

  self._exitEditionMode = function() { // Separated out for testing purposes.
    self.isEditing = false
    $rootScope.fixfixed = false
  }

  function init() {
    $rootScope.$on('$stateChangeStart', function(_event, _toState, toParams) {
      if (toParams.source || !toParams.c) self.criteria = ''
    })

    self.inSearchState = $state.current.name.indexOf('search') === 0

    //   self.inSearchState = $state.current.name.indexOf('search') === 0
    // })

    if (messages) {
      messages.forEach(function(message) {
        self.downForMaintenance = notificationCenterService.add(message.type, message.text, CONSTANTS.notificationType.systemUnavailability, CONSTANTS.eventId.SYSTEM + message.id, {
          'status': 'shown',
          'html': true,
        })
      })
    }
  }

  init()
}
