(function() {
  'use strict'

  angular.module('app.directives').directive('wgEmptyState', function() {
    return {
      restrict: 'EA',
      scope: {
        icon: '=',
        title: '=',
        text: '=',
      },
      templateUrl: 'app/widgets/templates/empty-state.html',
      controller: [function() {}],
      controllerAs: 'wgEmptyStateCtrl',
      bindToController: true,
    }
  })
})()
