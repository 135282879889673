(function() {
  'use strict'

  angular.module('app.directives').directive('wgError', [function() {
    return {
      restrict: 'EA',
      scope: {
        buttonClick: '&buttonClick',
        buttonText: '<',
        message: '<',
        messageHeader: '<',
        notError: '<',
      },
      templateUrl: 'app/widgets/templates/error.html',
      transclude: true,
      replace: true,
      controller: ['$scope', '$window', '$state', 'utils', 'stateTransitions', '$attrs',
        function($scope, $window, $state, utils, stateTransitions, $attrs) {
          const self = this

          self.buttonText = $scope.buttonText || 'OK'

          self.hideButton = typeof $attrs.hideButton !== 'undefined'

          self.goBack = function() {
            if ($state.current.name === 'search') {
              stateTransitions.go('search.result', {
                c: '',
                t: 'recently',
              }, {
                'direction': 'right',
                'async': true,
              })
            } else {
              stateTransitions.goBack()
            }
          }

          // We check against the existence of an attribute here
          // because that's how a user would specify a custom click function,
          // and other ways of checking for it are unreliable.
          if (!$attrs.buttonClick) {
            self.buttonClick = self.goBack
          }
        },
      ],
      controllerAs: 'wgErrorCtrl',
      bindToController: true,
    }
  }])
})()
