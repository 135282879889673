/* eslint-disable no-underscore-dangle */
import * as angular from 'angular'
import { IFilterFunction, IHttpService, IPromise, IQService } from 'angular'

export interface ICommissionGridMetadata {
  productType: string
  company: string
  compensationMethod: string
  contractType: string
  agreement: string
  tieredCompensationRate: string
  filePath: string
  visible: boolean
}

export interface ICompensationMethod {
  type: string
  tieredType: string
  rate: string
}

export interface ICommissionDocument {
  agreementName: string
  commissionDocUrl: string
  iconHint: string
}

export const COMPENSATION_METHODS = {
  AGREEMENTS: 'AGREEMENTS',
  TIERED: 'TIERED',
  NONE: 'NONE',
}
export class CLandRDataProviderService {
  static $inject = ['$q', '$http', 'utils', 'CLANDR_ENDPOINTS', 'partyService', '$filter', 'configService']

  operator: any

  constructor(private $q: IQService, private $http: IHttpService, private utils, private CLANDR_ENDPOINTS: any, private partyService: any, private $filter: IFilterFunction, private configService: any) {
    this.operator = {
      equals: (a, b): boolean => a === b,
      notEquals: (a, b): boolean => a !== b,
    }
  }

  /**
   * Returns an empty string if the date 1/1/2300, which will translate to and emdash in the view.
   *
   * @param testDate
   */
  _remove_1_1_2300_date(testDate: string): string {
    return testDate === '01/01/2300' ? '' : testDate
  }

  /**
   *
   * @param data
   * @param options
   */
  _applyResultsFilter(data: any[], filter?: any): any[] {

    if (filter) {
      return data.filter(datum => {
        const testData = this.utils.getData(datum, filter.key)

        return this.operator[filter.op](testData, filter.value)
      })
    } else {
      return data
    }
  }

  /**
   *
   * Converts appointment.company value to 'Penn Mutual' when original value is 'PML'
   *
   * @param data
   */
  _applyCommonTransforms(data: any[]): any[] {
    return data.map((datum: any) => {
      if (datum.endDate) datum.endDate = this._remove_1_1_2300_date(datum.endDate)
      if (datum.linesOfAuthority) datum.linesOfAuthority = this._transformLinesOfAuthority(datum.linesOfAuthority)

      return datum
    })
  }

  /**
   * Applies data transformations specific to the linesOfAuthority
   *
   * @param linesOfAuthority
   */
  _transformLinesOfAuthority(linesOfAuthority): any[] {

    return linesOfAuthority.map(loa => {
      loa.endDate = this._remove_1_1_2300_date(loa.endDate)
      return loa
    })
  }

  _extractResultsData(rawResult: any, dataPropName: string): any[] {
    const data = rawResult.data || { [dataPropName]: [] }

    return data[dataPropName] || []
  }

  private getCommissionGridMetadata(): IPromise<ICommissionGridMetadata[]> {
    const url: string = "/crafter/metadata/commission-grids"

    if (!this.commissionGridMetaData) {
      return this.$http.get(url)
        .then((rsp) => this.commissionGridMetaData = rsp.data as ICommissionGridMetadata[])
        .then(() => this.commissionGridMetaData = this.commissionGridMetaData.map(data => {
          if (data.compensationMethod === 'tieredCompensationRate') {
            return { ...data, compensationMethod: COMPENSATION_METHODS.TIERED }
          } else {
            return data
          }
        }))
        .catch((err) => {
          console.error(err)

          return []
        })
    }

    return this.$q.resolve(this.commissionGridMetaData)
  }

  /**
   * Returns all end-point data for the current logged in agent,
   * or assistant viewing as an agent.
   *
   * @param endPointName
   * @param dataPropertyName
   * @param filterKey
   */
  get(endPointName: string, dataPropertyName: string, options?: any): any {
    const filterKey = this.partyService.getAgentKey()
    // console.debug('filterKey', filterKey)
    // console.debug('options', options)

    let url = this.CLANDR_ENDPOINTS[endPointName]

    url = this.utils.appendURLParameter(url, 'filterKey', filterKey)

    return this.$http.get(url)
      .then((result: any) => this._extractResultsData(result, dataPropertyName))
      .then((data: any[]) => this._applyResultsFilter(data, options && options.resultFilter))
      .then(data => this._applyCommonTransforms(data))
  }

  /**
   * Determines what the last three years are, if possible. Then filters
   * the whole list of trainings down to just the ones that match
   * the three years found.
   *
   * > NOTE: It is conceivable that less than three years are found. But,
   *         there should never be no years found, unless there are no trainings.
   *
   * @param trainings
   */
  private _reduceTrainingsDownToLastThreeYear(trainings): any[] {
    const yearsFiltered: string[] = Object.keys(trainings.reduce((years, training) => {
      const date: Date = new Date(training.completionDate)

      years[date.getFullYear()] = date.getFullYear()

      return years
    }, {})).sort().reverse().slice(0, 3)

    const lastThreeYears: any[] = trainings.reduce((trainingsFiltered, training) => {
      const completionDate: Date = new Date(training.completionDate)
      const completionYear: string = String(completionDate.getFullYear())

      if (yearsFiltered.indexOf(completionYear) > -1) trainingsFiltered.push(training)

      return trainingsFiltered
    }, [])

    return lastThreeYears

  }

  /**
   * Returns the last three years of trainings
   */
  trainings = (): any[] => this.get('TRAININGS', 'trainings')
    .then(trainings => this._reduceTrainingsDownToLastThreeYear(trainings))

  /**
   * Returns all active appointments
   */
  appointments = (): any[] => this.get('APPOINTMENTS', 'appointments', {
    resultFilter: {
      key: 'status',
      op: 'notEquals',
      value: 'Terminated',
    },
  })

  /**
   * Returns all active licenses
   */
  licenses = (): any[] => this.get('LICENSES', 'licenses', {
    resultFilter: {
      key: 'status',
      op: 'notEquals',
      value: 'Terminated',
    },
  })

  private groupAndMergeContracts(contracts: any[]): any[] {
    const grouped: any = contracts.reduce((acc: any, contract: any) => {
      const key: string = `${contract.office.code}-${contract.agentCode}-${contract.type}`
      const group: any[] = acc[key] || []
      if (group.length === 0) acc[key] = group

      group.push(contract)

      return acc
    }, {})
    const values: any[] = Object.values(grouped)
    const merged: any[] = values.reduce((acc: any[], entry: any[]) => {
      if (entry.length === 1) acc.push(entry.pop())
      if (entry.length > 1) {
        const some = entry.some((contract: any) => ['PML', 'PIA'].includes(contract.company))

        if (some) {
          const pmlPiaContacts = entry.filter((contract) => ['PML', 'PIA'].includes(contract.company))
          const nonPmlPiaContracts = entry.filter((contract) => !['PML', 'PIA'].includes(contract.company))
          const pmlContract = pmlPiaContacts.find((contract: any) => contract.company === 'PML')
          const piaContract = pmlPiaContacts.find((contract: any) => contract.company === 'PIA')

          // Merge the PML/PIA
          if (pmlContract) {
            pmlContract.company = 'Penn Mutual/PIA'
            pmlContract.companyCode = 'PML'
            acc.push(pmlContract)
          } else if (piaContract) {  // This conditions should never happen, but because of same bad test data this code was added.
            piaContract.company = 'Penn Mutual/PIA'
            pmlContract.companyCode = 'PML'
            acc.push(piaContract)
          }

          // Add non PML/PIA by themselves.
          nonPmlPiaContracts.forEach(contract => {
            contract.companyCode = contract.company

            acc.push(contract)
          })
        } else {
          entry.forEach(contract => {
            if(!contract.companyCode) contract.companyCode = contract.company

            acc.push(contract)
          })
        }
      }

      return acc
    }, [])

    return merged
  }

  private contractDisplayNames(contracts: any[]) {
    return Object.values(contracts.map((contract: any) => {
      // Resolve `relatedOrg` property
      contract.relatedOrg = { code: '', name: '', displayName: '' }
      if (contract.status && contract.status.date) contract.status.date = this.$filter('date')(Date.parse(contract.status.date), 'MM/dd/yyyy')
      if (contract.brokerDealer && contract.producerCorp) console.warn('Contracts should have either a `brokerDealer`, or a `producerCorp`, or neither. This contract has both.')
      // if (contract.brokerDealer) contract.relatedOrg = contract.brokerDealer
      if (contract.producerCorp) contract.relatedOrg = contract.producerCorp
      contract.relatedOrg.displayName = `${contract.relatedOrg.code} - ${contract.relatedOrg.name}`

      // Combine `office.code` and `office.name`
      contract.officeDisplayName = `${contract.office.code} - ${contract.office.name}`

      return contract
    }))
  }

  private compGridTitle(compMethod: ICompensationMethod): string {
    return compMethod.tieredType === 'ANNUITY' ? `Annunity Rate Schedule: ${compMethod.rate}` : `Life Compensation Rate: ${compMethod.rate}`
  }

  private commissionGridMetaData: ICommissionGridMetadata[]
  private handleCommissionRates(contracts: any[]) {
    return contracts.map((contract: any) => {
      let foundMetadata: ICommissionGridMetadata
      const agreement = contract.agreements[0] || {} // There will always only be 0 or 1 agreements

      // console.log('>>>>>', contract)

      contract.compensationDocs = contract.compensations.map((compMethod: ICompensationMethod): ICommissionDocument => {
        const compDoc: ICommissionDocument = {agreementName: '', commissionDocUrl: '', iconHint: '' }

        switch (compMethod.type) {
          case COMPENSATION_METHODS.TIERED:
            foundMetadata = this.commissionGridMetaData.find((metadata: ICommissionGridMetadata) => {
              const match: boolean = metadata.contractType.includes(contract.type)
                && metadata.compensationMethod === COMPENSATION_METHODS.TIERED
                && compMethod.rate === metadata.tieredCompensationRate
                && compMethod.tieredType === metadata.productType
                && contract.companyCode === metadata.company

                console.log(contract, metadata)
              return match
            })

            compDoc.agreementName = this.compGridTitle(compMethod)
            compDoc.commissionDocUrl = foundMetadata ? `${this.configService.secureDocRoot}${foundMetadata.filePath}` : ''
            compDoc.iconHint = compMethod.tieredType
            break

          case COMPENSATION_METHODS.AGREEMENTS:
            foundMetadata = this.commissionGridMetaData.find((metadata: any) => metadata.contractType.includes(contract.type) && metadata.agreement === agreement.code && contract.companyCode === metadata.company)
            compDoc.agreementName = agreement.name
            compDoc.commissionDocUrl = foundMetadata ? `${this.configService.secureDocRoot}${foundMetadata.filePath}` : ''
            compDoc.iconHint = 'Agreement'
            break

          default:
            foundMetadata = null
            compDoc.commissionDocUrl = ''
        }

        return compDoc

      }).filter((compDoc: ICommissionDocument) => compDoc.commissionDocUrl !== '')


      // Sample: https://sas-mo.pennmutual.com/secure-asset-service/file/delivery-test/insightcp/commission-docs/comp-grid-m-fin-fp-grid.pdf

      return contract
    })
  }

  /**
   * Returns all active contracts.  The original data is mutated to account for
   * descrete business logic regarding 'Related Org.'
   *
   */
  contracts(): any {
    return this.getCommissionGridMetadata()
      .then(() => this.get('CONTRACTS', 'contracts', {
        resultFilter: {
          key: 'status.sellingStatus',
          op: 'notEquals',
          value: 'Terminated',
        },
      })).then((contracts: any[]) => {
        const groupedAndMergedContracts: any[] = this.groupAndMergeContracts(contracts)
        const modifiedContracts: any[] = this.contractDisplayNames(groupedAndMergedContracts)
        const finalContracts: any[] = this.handleCommissionRates(modifiedContracts)

        return finalContracts
      })
  }

  contractsBeforeRender = (rawData: any, $scope: any): void => {
    const withProducerCorp = rawData.filter(contract => Boolean(contract.relatedOrg && contract.relatedOrg.name))

    $scope.showProducerCorp = withProducerCorp.length > 0
  }
}
