export {WgPolicyItemController}
const POLICY_RELATIVE_TYPES = ['beneficiary', 'owner', 'insured']

WgPolicyItemController.$inject = ['$scope', 'dateUtils', 'stateTransitions', 'authService', 'policyUtils', 'clientUtils', 'stringUtils', '$attrs', 'CONSTANTS', 'configService', 'beneficiariesDataProvider', 'PolicyRelativesViewManager']

function WgPolicyItemController($scope, dateUtils, stateTransitions, authService, policyUtils, clientUtils, stringUtils, $attrs, CONSTANTS, configService, beneficiariesDataProvider, PolicyRelativesViewManager) {
  const self = this
  const policy = $scope.wgPolicyCtrl.policy

    // these get set by self._setPolicyRelativeName()
  self.partiesReady = false
  self.ownerName = null
  self.beneficiaryName = null
  self.insuredName = null
  self.features = configService.features
  self._adviserDisplay = function() {
    if (authService.isAClient() && policy.primaryAgentStatus === 'Terminated') {
      if (policy.primaryAgentDistributionChannel === 'CAS') {
        return policy.primaryOfficeName
      }
      return ''
    }
    return policy.advisorFullName
  }

  self.isClientPortal = () => authService.isARealClient()

  self.isAnnuity = () => policyUtils.isAnnuity(self.policy)

  self.getPolicyParties = () => {
    return beneficiariesDataProvider.getAllRoles(policy.polNumber, false)
  }


  self._init = function() {
    self.getHrefString = authService.getPolicyHref(policy.source, policy.polNumber)
    POLICY_RELATIVE_TYPES.forEach(self._setPolicyRelativeName)

    if (!self.policy) {
      self.policy = $scope.wgPolicyCtrl.policy // fix unit tests not having a policy yet
    }

    // This is to be able to share this directive with full book, and all policies.
    self.isRestricted = self.policy.isRestricted !== undefined ? self.policy.isRestricted : authService.restrictByIssueState(self.policy.issueState || self.policy.jurisdiction)
    if (self.isRestricted) {
      self.getHrefString = ''
    }

    self.policyVerbiage = policyUtils.isAnnuity(self.policy) ? 'Contract' : 'Policy'
    self.isHomeOffice = authService.isHomeOfficeUser()

    if (!self.policy.nyIssued) {
      self.policy.nyIssued = self.policy.issueState === 'New York' || self.policy.issueState === 'NY' || self.policy.jurisdiction === 'New York' || self.policy.jurisdiction === 'NY'
    }

    if (authService.isARealClient()) {
      // Client Portal Specific
      self.getPolicyParties()
        .then((parties) => {
          self.viewState = new PolicyRelativesViewManager(parties, self.isAnnuity(), self.isAssigned())
          self.partiesReady = true
        })

    } else {
      // Insight Specific
      if (!self.policy.insured) {
        self.policy.insured = self.policy.insureds[0]
      }

      self.partiesReady = true
    }
  }

  self._policyRelativeName = function(relativeType) {
    const pluralizedRelativeType = stringUtils.pluralize(relativeType)
    const policyRelatives = policy[pluralizedRelativeType]
    let relative

    if (policyRelatives && policyRelatives[0]) { // this fails for inforce insureds, as we end up getting "Lastname, Firstname" because of lack of details on name (0090025030)
      return clientUtils.partyName(policyRelatives[0])
    }

    relative = policy[relativeType]
    if (relative && relative.fullName) {
      return relative.fullName // this gets returned in most cases, it seems
    }

    return CONSTANTS.notAvailableInfo
  }

  self._setPolicyRelativeName = function(relativeType) {
    self[relativeType + 'Name'] = self._policyRelativeName(relativeType)
  }

  self.isPastDueDate = function(dueDate) {
    return dateUtils.isPastDueDate(dueDate)
  }

  self.isAssigned = function() {
    return policy.assignmentCode && policy.assignmentCode.tc === '2'
  }

  self.showPolicy = function(e) {
    const route = authService.isAClient() ? 'myPolicy' : 'policy'
    const defaultRouteParams = { id: policy.polNumber }
    const routeParams = route === 'myPolicy' ? {...defaultRouteParams, openPayment: true } : defaultRouteParams

    e.preventDefault()
    stateTransitions.go(route, routeParams)
  }

  self.showRequirements = function() {
    return authService.isAgentView() && self.policy.requirementInfo && self.policy.requirementInfo.label !== '0/0'
  }

  self.adviserDisplay = self._adviserDisplay()
  self.authService = authService
  self.insuredNameProminent = policyUtils.isItemInsuredNameProminent(authService.isAgentView(), $attrs)
  self.policyUtils = policyUtils
    // The policy-relatives' names (i.e. beneficiaryName, insuredName, ownerName) are filled in on initialization.
    // They are listed here only for exposition and findability.
  self.beneficiaryName = null
  self.insuredName = null
  self.ownerName = null
  self.isRpsPolicy = policy.source === 'rps'
  self._init()
}

export function WgPolicyItem() {
  return {
    restrict: 'EA',
    scope: {
      policy: '=',
    },
    templateUrl: 'app/widgets/templates/wg-policy-item.html',
    controller: WgPolicyItemController,
    controllerAs: 'wgPolicyCtrl',
    bindToController: true,
  }
}
