(function() {
  'use strict'

  wgClientAccountsController.$inject = ['$scope', 'clientService', 'CONSTANTS']

  function wgClientAccountsController($scope, clientService, CONSTANTS) {
    const self = this

    self.alerts = []

    $scope.$on('newAccountAlert', function(event, data) {
      if (data) {
        self.alerts.push({
          type: data.type,
          msg: data.message,
        })
      }
    })

    $scope.$on('deleteAccountAlert', function(event, data) {
      if (data) {
        self.alerts.push({
          type: data.type,
          msg: data.message,
        })
      }
    })

    $scope.$on('deleteAccountAlertErrorPendingPayment', function(event, data) {
      if (data) {
        self.alerts.push({
          type: data.type,
          msg: data.message,
        })
      }
    })

    $scope.$on('newDefaultAccountAlert', function(event, data) {
      if (data) {
        self.alerts.push({
          type: data.type,
          msg: data.message,
        })
      }
    })

    self.close = function(index) {
      self.alerts.splice(index, 1)
    }
  }

  angular.module('app.directives')
    .controller('wgClientAccountsController', wgClientAccountsController)
    .directive('wgClientAccounts', [function() {
      return {
        restrict: 'EA',
        templateUrl: 'app/widgets/templates/wg-client-accounts.html',
        controller: wgClientAccountsController,
        controllerAs: 'clientAccountsCtrl',
      }
    }])
})()
