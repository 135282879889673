/* global Swiped */
(function() {
  'use strict'

  angular
    .module('app.directives')
    .directive('wgSwipeContent', wgSwipeContent)

  wgSwipeContent.$inject = []

  function wgSwipeContent() {
    return {
      restrict: 'E',
      require: '^^wgSwipeContainer',
      link: wgSwipeContentLinker,
    }

    function wgSwipeContentLinker(scope, elem, attrs, ctrl) {
      scope.swipedObj = Swiped.init({
        query: 'wg-swipe-content',
        right: ctrl.numOptions * ctrl.optionWidth,
        list: true,
      })
    }
  }
})()
