export class MfaTrustBrowserController {

  static inject = ['mfaDataProvider', '$stateParams', '$urlService']

  constructor(private mfaDataProvider, private $stateParams, private $urlService) { }

  trustDevice() {
    this.mfaDataProvider.trustDevice().then(() => {
      this.$urlService.url(this.$stateParams.redirectUrl || '/#/')
    })
      .catch((error) => {
        console.log(error)
      })
  }

  noTrust() {
    this.$urlService.url(this.$stateParams.redirectUrl || '/#/')
  }

}
