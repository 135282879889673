const MODAL_MESSAGE_TEMPLATES = {
  fpMessage: 'app/search/all/modal-messages/fp-message.html',
  downloadFailed: 'app/search/all/modal-messages/download-failed.html',
}

class AllPoliciesViewController {
  static $injector: any = ['$scope', 'authService', 'partyService', 'allPoliciesDataProvider', 'modalService', 'FILTER_VIEW_STATES', 'POLICIES_POLICY_VIEWS', 'configService', 'googleAnalyticsService', 'utils']

  viewStyle: string = 'table'
  noDataAvailable: boolean = true
  isAgent: boolean
  isNotAgent: boolean
  listViewDataOptions: any
  lineOfBusiness: any
  productType: any
  productTypes: any[]
  policiesCtrl: any
  includeStates: any[]
  includeState: any
  currentSort: ISortColumn
  isHomeOfficeUser: boolean
  noDataMessage: string
  canDownload: boolean
  activeAgent: any
  source: string
  showViewSelector: boolean
  showSortOptions: boolean
  showDownloadButton: boolean
  isPendingView: boolean
  currentViewState: any
  aceIndicator: any
  formalAppIndicator: any
  subStatus: any
  appStatus: any
  eAppUrl: string
  daysOpen: any
  daysToExpire: any
  pagingInfo: any
  productTypeParams: { source: string; lineOfBusiness: any }

  /**
   *
   * @param $scope
   * @param authService
   * @param partyService
   * @param allPoliciesDataProvider
   * @param modalService
   * @param FILTER_VIEW_STATES
   * @param POLICIES_GA_INFORCE_VIEW
   * @param configService
   * @param googleAnalyticsService
   * @param utils
   */
  constructor(private $scope, private authService, partyService, private allPoliciesDataProvider, private modalService, private FILTER_VIEW_STATES, private POLICIES_POLICY_VIEWS, private configService, private googleAnalyticsService, private utils) {
    this.activeAgent = partyService.getActiveAgent()

    // Check if the current user is able to viewAs an agent.

    this.isAgent = authService.isInRole('AGENT')
    this.isNotAgent = !this.isAgent
    this.listViewDataOptions = { filterKey: this.activeAgent.key, isAgent: this.isAgent, pageable: true, source: this.source }
    this.eAppUrl = this.configService.eAppUrl
  }

  /**
   *
   * @param view
   */
  switchViewStyle(view) {
    this.viewStyle = view
    localStorage.setItem('fullBookViewStyle', view)
    // this._logToGA(this)
  }

  download() {
    if (this.canDownload) {
      const options = Object.assign({}, this.listViewDataOptions, {
        sort: this.currentSort,
        filter: [
          {
            field: 'lineOfBusiness',
            value: this.lineOfBusiness.id,
          },
          {
            field: 'productType',
            value: this.productType.id,
          },
          {
            field: 'includeState',
            value: this.includeState.id,
          },
          {
            field: 'aceIndicator',
            value: this.aceIndicator.id,
          },
          {
            field: 'formalAppIndicator',
            value: this.formalAppIndicator.id,
          },
          {
            field: 'subStatus',
            value: this.subStatus.id,
          },
          {
            field: 'status',
            value: this.appStatus.id,
          },
          {
            field: 'daysOpen',
            value: this.daysOpen.id,
          },
          {
            field: 'daysToExpire',
            value: this.daysToExpire.id,
          },
        ],
        rows: 1500,
      })

      this.allPoliciesDataProvider.download(options)
        .catch((err) => {
          this.modalService.open(MODAL_MESSAGE_TEMPLATES['downloadFailed'], { additionalMessage: `(${err.status})` })
        })
    } else {
      this.modalService.open(MODAL_MESSAGE_TEMPLATES['fpMessage'])
    }

  }

  _onDataReady(_e, _name, listView) {

    // Update the noDataAvailable flag accordingly
    this.noDataAvailable = listView.data.length === 0
    this.noDataMessage = this.source === 'unsubmitted' ? 'No results found based on filter.' : 'There are no policies or contracts.'
    this.currentSort.dataType = listView._curSortColumn.dataType
    this.currentSort.name = listView._curSortColumn.name
    this.currentSort.direction = listView._curSortColumn.direction
    this.pagingInfo = listView._pagingInfo

    this._logToGA(this)
  }

  _showViewSelector(source) {
    return source === 'inforce'
  }

  _showSortOptions(source) {
    return source === 'inforce'
  }

  _showDownloadButton(source) {
    return source === 'inforce'
  }

  _isPendingView(source) {
    return source === 'pending'
  }

  _logToGA(context: any) {
    const sourceView = this.POLICIES_POLICY_VIEWS[this.source]
    const parameters: any = Object.keys(sourceView.propertyMap).reduce((acc: any, key: string) => {
      const contextKey = sourceView.propertyMap[key]
      const propVal = this.utils.getData(context, contextKey)

      acc[key] = propVal

      // If `view` property convert value from 'tile' to 'list'
      if (key === 'view') acc[key] = acc[key] === 'tile' ? 'list' : acc[key]

      return acc
    }, {})

    this.googleAnalyticsService.send(sourceView.event, sourceView.action, parameters)
  }

  clearWatches: Function[] = []
  initWatches() {
    this.clearWatches.push(this.$scope.$watch('allPoliciesCtrl.lineOfBusiness', (n) => {
      console.log('allPoliciesCtrl.lineOfBusiness', n)
      if (n) this.productTypeParams = { source: this.source, lineOfBusiness: this.utils.hermeticCopy(this.lineOfBusiness) }
    }))

    this.clearWatches.push(this.$scope.$on('PML_NG_LISTVIEW_DATA_READY', this._onDataReady.bind(this)))

  }

  $onInit() {
    const sourceFilters = JSON.parse(localStorage.getItem(this.source) ?? '{}')

    this.viewStyle = localStorage.getItem('fullBookViewStyle') ?? 'tile'
    this.currentSort = { name: 'ownerfullname', direction: 'ASC', dataType: undefined }
    this.isHomeOfficeUser = this.authService.isHomeOfficeUser()
    this.canDownload = this.isHomeOfficeUser && this.activeAgent.key && this.activeAgent.type === 'agent'
      || !this.isHomeOfficeUser && this.isNotAgent && this.activeAgent.key
      || this.isAgent
    this.showViewSelector = this._showViewSelector(this.source)
    this.showSortOptions = this._showSortOptions(this.source)
    this.showDownloadButton = this._showDownloadButton(this.source)
    this.isPendingView = this._isPendingView(this.source)
    this.currentViewState = this.FILTER_VIEW_STATES[this.source]
    this.listViewDataOptions.source = this.source

    this.lineOfBusiness = sourceFilters.lineOfBusiness ?? { name: 'All', id: '', key: 'All' }

    if (!this.isHomeOfficeUser) {
      // We hide the state dropdown, so force to all states
      sourceFilters.includeState = { name: 'All', id: '' }
    }

    if (this.source === 'pending') {
      this.viewStyle = 'tile'
      // this.currentSort = { name: 'INSUREDORANNUITANTFULLNAME', direction: 'ASC', dataType: undefined }
      this.currentSort = { name: 'LASTUPDATEDATE', direction: 'DESC', dataType: undefined }
    }

    if (this.source === 'unsubmitted') {
      this.viewStyle = 'tile'
      this.currentSort = { name: 'daysOpen', direction: 'DESC', dataType: undefined }
    }

    if (this.source === 'notpaid') {
      sourceFilters.lineOfBusiness = { name: 'Life Only', id: 1, key: 'Life' }
      this.lineOfBusiness = sourceFilters.lineOfBusiness
    }

    localStorage.setItem(this.source, JSON.stringify(sourceFilters))

    this.initWatches()

    this.$scope.$on('$destroy', () => this.clearWatches.forEach((fn => fn())))
  }
}

const AllPoliciesView = {
  templateUrl: 'app/search/all/policies.html',
  controller: AllPoliciesViewController,
  controllerAs: 'allPoliciesCtrl',
  bindings: {
    policiesCtrl: '<',
    source: '<',
  },
}

export { AllPoliciesView }
