const remoteStatusCodes = {
  FAILED: 'error',
  IDLE: '',
  RECEIVING: 'receiving',
  SENDING: 'sending',
  SENT: 'sent',
}
export const REMOTE_STATUS_CODES = remoteStatusCodes

const bootstrapTypes = {
  DANGER: 'danger',
  DEFAULT: 'default',
  INFO: 'info',
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
}
export const BOOTSTRAP_TYPES = bootstrapTypes

const remoteStatesToBootstrapMap = {
  [remoteStatusCodes.FAILED]: bootstrapTypes.DANGER,
  [remoteStatusCodes.IDLE]: bootstrapTypes.DEFAULT,
  [remoteStatusCodes.RECEIVING]: bootstrapTypes.INFO,
  [remoteStatusCodes.SENDING]: bootstrapTypes.INFO,
  [remoteStatusCodes.SENT]: bootstrapTypes.SUCCESS,
}
export const REMOTE_STATUS_TO_BOOTSTRAP_MAP = remoteStatesToBootstrapMap

const NBA_GA_EVENTS = {
  DOCUMENT_UPLOAD_SUCCESS: {
    event: 'DOCUMENT UPLOAD',
    action: 'SUCCESS',
    parameters: {
      numberOfFiles: 0,
      totalSize: 0,
      issueState: '',
      lineOfBusiness: 'life',
    },
  },
  DOCUMENT_UPLOAD_FAIL: {
    event: 'DOCUMENT UPLOAD',
    action: 'FAIL',
    parameters: {
      reason: '',
    },
    errorCode: 0,
  },
}

export {remoteStatusCodes, bootstrapTypes, remoteStatesToBootstrapMap, NBA_GA_EVENTS}
