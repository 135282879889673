angular.module('app.directives').directive('whenScrolled', [function() {
  return {
    restrict: 'A',
    link: function(scope, elem, attr) {
      const raw = elem[0]

      elem.bind('scroll', function(e) {
        e.stopPropagation()
        if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
          scope.$apply(attr.whenScrolled)
        }
      })
    },
  }
}])
