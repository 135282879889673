(function() {
  'use strict'

  angular.module('app.directives').directive('partySelectorDropdown', partySelectorDropdown)

  function partySelectorDropdown() {
    return {
      restrict: 'E',
      scope: {
        defaultText: '=?',
      },
      templateUrl: 'app/widgets/templates/party-selector-dropdown.html',
      controller: PartyController,
      controllerAs: 'party',
    }
  }

  PartyController.$inject = ['$scope', 'CONSTANTS', 'deviceUtils', 'partyService', 'authService', '$rootScope', '$state', 'userAccountService', '$timeout', 'popupManager', 'configService']

  /* @ngInject */
  function PartyController($scope, CONSTANTS, deviceUtils, partyService, authService, $rootScope, $state, userAccountService, $timeout, popupManager, configService) {
    const self = this
    let currentPage = 1
    let loadingData = false
    let moreData = true
    let _searchResults = []
    let _defaultList = []
    let _history = []
    let _selected = {}
    const _fullName = authService.getFullName()
    const _allParty = {
      name: 'All',
      key: '',
    }

    let searchTimer = null

    const PARTY_MENU_ID = 'PARTY_MENU_ID'

    self.isSearching = false
    self.menuShowTrigger = false
    self.noResults = false
    self.query = ''
    self.selectedIndex = 0
    self.defaultText = $scope.defaultText
    self.features = configService.features
    self.showGreeting = false

    //* ******************* PAGINATION ********************

    self.SCROLL_DISTANCE = 1
    self.isScrollDisabled = function() {
      return loadingData || !moreData
    }

    self.nextSearchPage = function() {
      if ((currentPage > 1) && moreData && !loadingData) {
        _search()
      }
    }

    self.isLoadingMore = function() {
      return loadingData
    }

    //* ***************** /END PAGINATION ******************

    /// //////////////////////////////////////

    popupManager.register(PARTY_MENU_ID)

    self.isPartyMenuOpen = function(e) {
      return popupManager.isOpen(PARTY_MENU_ID)
    }

    self.togglePartyMenu = function(e) {
      popupManager.toggle(PARTY_MENU_ID, e)

      if (self.isPartyMenuOpen()) {
        self.menuShowTrigger = true
      }
    }

    self.reloadLists = function() {
      if (searchTimer !== null) {
        $timeout.cancel(searchTimer)
      }

      searchTimer = $timeout(function() {
        _clearSearchResults()

        if (self.query === '') {
          self.isSearching = false
          _initDefaultList()
        } else {
          _search()
        }
      }, 1000)
    }

    self.clickParty = function(agent, e) {
      self.togglePartyMenu(e)
      _refreshAndSelectParty(agent)
    }

    self.getActiveList = function() {
      return self.isSearching ? _searchResults : _defaultList
    }

    self.hasHistory = function() {
      return _history.length > 0
    }

    self.hasPartyList = function() {
      return authService.isNonAgentOrAgentPlus()
    }

    self.activeClass = function(agent) {
      return agent.key === _selected.key ? 'active' : ''
    }

    self.enterEditionMode = function() {
      if (deviceUtils.isAppleDevice()) {
        $rootScope.fixfixed = true
      }
    }

    self.exitEditionMode = function() {
      $timeout(function() {
        self.isEditing = false
        $rootScope.fixfixed = false
      }, 200)
    }

    self.displayMember = function(member) {
      member = member || false

      if (member.orgCode) {
        return member.orgCode + ' - ' + member.name
      }
      return member.name
    }

    self.welcomeName = function() {
      return _fullName.split(' ')[0]
    }

    self._showGreeting = function() {
      return (!self.hasPartyList() && !self.defaultText) || $rootScope.isClientView
    }

    self.$onInit = function() {
      self.showGreeting = self._showGreeting()
    }

    /// ///// Private

    function _activate() {
      return partyService.getPartyHistory()
        .then(function(httpData) {
          _history = httpData.data || []
          _initDefaultList()
          _selectParty(_allParty)
        }, function(httpData) {
          console.error('Could not load recently viewed history from the server.')
          _history = []
          _initDefaultList()
          _selectParty(_allParty)
        })
    }

    function _getMeAsParty() {
      return {
        name: authService.getFullName() + ' (Me)',
        key: userAccountService.account._id,
      }
    }

    function _selectParty(agent) {
      const currentActiveAgent = partyService.getActiveAgent()

      partyService.setActiveAgent(agent)

      _selected = agent
      self.selectedName = _selected.name

      if ($state.current.abstract) return

      $state.current.data = $state.current.data || {
        'applyFilter': true,
      }

      if ($state.current.data.applyFilter === undefined || $state.current.data.applyFilter === true) {
        $state.reload()
      }

      _addToHistory(agent)

      // Don't do anything if the activeAgent hasn't changed
      if (!currentActiveAgent?.name || currentActiveAgent.key === agent?.key) return

      $rootScope.$broadcast('activeAgentChanged')

    }

    function _refreshAndSelectParty(agent) {
      if (agent === _allParty) {
        _selectParty(agent)
      } else {
        partyService.refreshByKey(agent)
          .then(function(result) {
            agent = result.agent
            _selectParty(agent)
          })
      }
    }

    function _clearSearchResults() {
      currentPage = 1
      self.noResults = true
      _searchResults = []
    }

    function _initDefaultList() {
      _defaultList = []

      _defaultList.push(_allParty)
      // Wait until we have access to the current agent's filter key to do this.
      // _defaultList.push(_getMeAsParty);
      _defaultList = _defaultList.concat(_history.slice().reverse())
    }

    function _search() {
      self.isSearching = true
      loadingData = true
      // moreData = true;

      partyService.getBy(self.query, currentPage, true).then(function(data) {
        loadingData = false

        if (data.agents && data.agents.length > 0) {
          _searchResults = _searchResults.concat(data.agents)
          moreData = true
          currentPage++
        } else {
          moreData = false
        }
      })
    }

    function _addToHistory(agent) {
      let existingIndex

      if (agent === _allParty ||
        agent === _getMeAsParty() ||
        agent === undefined) {
        return
      }

      existingIndex = _historyContains(agent)

      if (existingIndex >= 0) {
        _history.splice(existingIndex, 1)
      }
      _history.push(agent)

      if (_history.length > CONSTANTS.filterByPartyHistorySize) {
        _history.shift()
      }

      return partyService.syncPartyHistory(_history)
        .then(function(httpData) {
          if (httpData.data.parties) {
            _history = httpData.data.parties
          }
        }, function(httpData) {
          console.error('Could not sync recently viewed history with the server.')
        })
    }

    function _historyContains(agent) {
      let i = 0
      let ret = -1

      angular.forEach(_history, function(value) {
        if (agent.key === value.key) {
          ret = i
        }
        i++
      })
      return ret
    }

    if (authService.isAuthenticated()) {
      _activate()
    }
  }
})()
