import {StateService} from '@uirouter/core'
import { IMyAddressResponse, IClients } from './address/types'
export class ClientAddressesController {
  static $inject = ['$state']

  public addresses: any[]

  constructor (private $state: StateService) {
    this.addresses = this.$state.params['addresses']
  }

  public getRolesForAddress(address: IMyAddressResponse) {
    return address.policyClients.map((policyClient) => policyClient.partyRoles[0].roles.map((role) => role.value)?.join(', ')).join(' ,')
  }

  public getPolicyIdsForAddress(address: IMyAddressResponse) {
    return address.policyClients.map((policyClient) => policyClient.pid)?.join(', ')
  }

  public getPolicyRolesForPolicyClient(policyClient: IClients) {
    return policyClient.partyRoles[0].roles.map((role) => role.value)?.join(', ')
  }

  public close() {
    this.$state.go('^')
  }
}
