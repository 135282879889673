export class ClientPaymentDirective implements ng.IDirective {
  restrict = 'EA'
  scope = {
    policySummary: '=',
    paymentDetails: '=',
    canPayDetails: '=',
    closeCallback: '=',
    loanPayoffQuote: '=',
    summaryCtrl: '=',
  }

  templateUrl = 'app/widgets/templates/wg-client-payment.html'
  controller = 'wgClientPaymentController'
  controllerAs = 'ctrl'

  link(scope) {
    scope.shouldShowForm = scope.ctrl.shouldShowForm()
    scope.shouldShowPaymentDisabled = scope.ctrl.shouldShowPaymentDisabled()
    scope.shouldShowConfirmation = scope.ctrl.shouldShowConfirmation()
    scope.shouldShowError = scope.ctrl.shouldShowError()
    scope.canPrint = scope.ctrl.isIE || scope.ctrl.isMobileSafari || scope.ctrl.isPhone
    scope.isUniversalLifePolicy = scope.ctrl.isUniversalLifePolicy() // can just use controller
    scope.showPremiumRow = scope.summaryCtrl.canPayTypes.premium
    scope.$watchCollection('ctrl.formData', (newFormData) => {
      if (newFormData) {
        scope.shouldShowConfirmation = Boolean(newFormData.confirmationNumber)
        scope.shouldShowError = Boolean(scope.ctrl.formData.errorMessage)
        scope.shouldShowForm = !scope.shouldShowConfirmation && !scope.shouldShowError
      } else {
        scope.shouldShowForm = true
        scope.shouldShowError = false
        scope.shouldShowConfirmation = false
      }
    })
  }

  /**
   * Creates an instance of ClientPaymentDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new ClientPaymentDirective()

    return directive
  }
}
