import * as angular from 'angular'
angular.module('app.credits', ['app.core'])
import {ClubCreditsRouter} from './club-credits-router'
import {ClubsService} from './club-credit-service'
import {ClubCreditsController} from './club-credit-controller'

angular
    .module('app.credits')
    .service('clubsService', ClubsService)

angular
    .module('app.credits')
    .controller('ClubCreditsController', ClubCreditsController)

angular
    .module('app.credits')
    .run(routerHelper => new ClubCreditsRouter(routerHelper))
