/**
 * Beneficiary Contants & Lookup Tables
 */
const BENEFICIARY_ROLES = {
  PRIMARY: '34',
  SECOND: '1012300004',
  CONTINGENT: '36',
  FINAL: '1012300003',
}

const BENEFICIARIES_ROLE_VALUES = Object.values(BENEFICIARY_ROLES)

export const BENEFICIARY_ROLES_ARRAY = [
  BENEFICIARY_ROLES.PRIMARY,
  BENEFICIARY_ROLES.CONTINGENT,
  BENEFICIARY_ROLES.SECOND,
  BENEFICIARY_ROLES.FINAL,
]

const BENEFICIARIES_ROLE_ORDER = {
  [BENEFICIARY_ROLES.PRIMARY]: 1,
  [BENEFICIARY_ROLES.CONTINGENT]: 2,
  [BENEFICIARY_ROLES.SECOND]: 3,
  [BENEFICIARY_ROLES.FINAL]: 4,
}

export const BENEFICIARY_ROLE_LABELS = {
  [BENEFICIARY_ROLES.PRIMARY]: 'Primary Beneficiaries',
  [BENEFICIARY_ROLES.CONTINGENT]: 'First Contingent Beneficiaries',
  [BENEFICIARY_ROLES.SECOND]: 'Second Contingent Beneficiaries',
  [BENEFICIARY_ROLES.FINAL]: 'Final Beneficiaries',
}

export const BENEFICIARY_ROLE_LABELS2 = {
  [BENEFICIARY_ROLES.PRIMARY]: 'Primary Beneficiary',
  [BENEFICIARY_ROLES.CONTINGENT]: 'First Contingent Beneficiary',
  [BENEFICIARY_ROLES.SECOND]: 'Second Contingent Beneficiary',
  [BENEFICIARY_ROLES.FINAL]: 'Final Beneficiary',
}

export const CLIENT_BENEFICIARY_ROLE_LABELS = {
  [BENEFICIARY_ROLES.PRIMARY]: 'Primary Beneficiary',
  [BENEFICIARY_ROLES.CONTINGENT]: 'Contingent Beneficiary',
  [BENEFICIARY_ROLES.FINAL]: 'Final Beneficiary',
}

export const CLIENT_BENEFICIARY_ROLE_TYPECODES = {
  [BENEFICIARY_ROLES.PRIMARY]: { tc: BENEFICIARY_ROLES.PRIMARY,  value: 'Primary Beneficiary' },
  [BENEFICIARY_ROLES.CONTINGENT]: { tc: BENEFICIARY_ROLES.CONTINGENT, value: 'Contingent Beneficiary' },
  [BENEFICIARY_ROLES.FINAL]: { tc: BENEFICIARY_ROLES.FINAL, value: 'Final Beneficiary' },
}

/**
 * Owner Contants & Lookup Tables
 */
const OWNER_ROLES = {
  OWNER: '8',
  JOINT_OWNER: '184',
  SUCCESSOR_OWNER: '78',
  ORIGINAL_OWNER: '1012300017',
}

const OWNER_ROLES_ARRAY = [
  OWNER_ROLES.OWNER,
  OWNER_ROLES.ORIGINAL_OWNER,
  OWNER_ROLES.JOINT_OWNER,
  OWNER_ROLES.SUCCESSOR_OWNER,
]

const OWNER_ROLE_ORDER = {
  [OWNER_ROLES.OWNER]: 1,
  [OWNER_ROLES.ORIGINAL_OWNER]: 2,
  [OWNER_ROLES.JOINT_OWNER]: 3,
  [OWNER_ROLES.SUCCESSOR_OWNER]: 4,
}

const OWNER_ROLE_LABELS = {
  [OWNER_ROLES.OWNER]: 'Owner',
  [OWNER_ROLES.JOINT_OWNER]: 'Joint Owner',
}

/**
 * Payor/Payer Contants & Lookup Tables
 */
const PAYOR_ROLES = {
  PAYOR: '31',
}

const PAYOR_ROLES_ARRAY = [
  PAYOR_ROLES.PAYOR,
]

const PAYOR_ROLE_ORDER = {
  [PAYOR_ROLES.PAYOR]: 1,
}

/**
 * Payor/Payer Contants & Lookup Tables
 */
const INSURED_ROLES = {
  PRIMARY_INSURED: '32',
  JOINT_INSURED: '189',
  COVERAGE_INSURED: '33',
  INSURED_CHILD: '1012300014',
  ADDITIONAL_INSURED: '1012300013',
  INSURED_DEPENDENT: '40',
}

const INSURED_ROLES_ARRAY = [
  INSURED_ROLES.PRIMARY_INSURED,
  INSURED_ROLES.JOINT_INSURED,
  INSURED_ROLES.COVERAGE_INSURED,
  INSURED_ROLES.INSURED_CHILD,
  INSURED_ROLES.ADDITIONAL_INSURED,
  INSURED_ROLES.INSURED_DEPENDENT,
]

const INSURED_ROLE_ORDER = {
  [INSURED_ROLES.PRIMARY_INSURED]: 1,
  [INSURED_ROLES.JOINT_INSURED]: 2,
  [INSURED_ROLES.COVERAGE_INSURED]: 3,
  [INSURED_ROLES.INSURED_CHILD]: 4,
  [INSURED_ROLES.ADDITIONAL_INSURED]: 5,
  [INSURED_ROLES.INSURED_DEPENDENT]: 6,
}

/**
 * Assignee Contants & Lookup Tables
 */
export const ASSIGNEE_ROLES = {
  ASSIGNEE: '145',
  COLLATERAL_ASSIGNEE: '145',
}

export const ASSIGNEE_ROLES_ARRAY = [
  ASSIGNEE_ROLES.ASSIGNEE,
  ASSIGNEE_ROLES.COLLATERAL_ASSIGNEE,
]

export const ASSIGNEE_ROLE_ORDER = {
  [ASSIGNEE_ROLES.ASSIGNEE]: 1,
  [ASSIGNEE_ROLES.COLLATERAL_ASSIGNEE]: 2,
}

/**
 * Annuitant Contants & Lookup Tables
 */
const ANNUITANT_ROLES = {
  ANNUITANT: '35',
  JOINT_ANNUITANT: '183',
  CONTINGENT_ANNUITANT: '153',
  STEP_IN_ANNUITANT: '1012300009',
  ORIGINAL_ANNUINTANT: '1012300016',

}

const ANNUITANT_ROLES_ARRAY = [
  ANNUITANT_ROLES.ANNUITANT,
  ANNUITANT_ROLES.ORIGINAL_ANNUINTANT,
  ANNUITANT_ROLES.JOINT_ANNUITANT,
  ANNUITANT_ROLES.STEP_IN_ANNUITANT,
  ANNUITANT_ROLES.CONTINGENT_ANNUITANT,
]

const ANNUITANT_ROLE_ORDER = {
  [ANNUITANT_ROLES.ANNUITANT]: 1,
  [ANNUITANT_ROLES.ORIGINAL_ANNUINTANT]: 2,
  [ANNUITANT_ROLES.JOINT_ANNUITANT]: 3,
  [ANNUITANT_ROLES.STEP_IN_ANNUITANT]: 4,
}

/**
 * Aggregated Role lookup constants
 */
const ROLES_ARRAY = {
  Beneficiary: BENEFICIARY_ROLES_ARRAY,
  Owner: OWNER_ROLES_ARRAY,
  Payor: PAYOR_ROLES_ARRAY,
  Insured: INSURED_ROLES_ARRAY,
  Assignee: ASSIGNEE_ROLES_ARRAY,
  Annuitant: ANNUITANT_ROLES_ARRAY,
}

const ROLES_ORDER = {
  Beneficiary: BENEFICIARIES_ROLE_ORDER,
  Owner: OWNER_ROLE_ORDER,
  Payor: PAYOR_ROLE_ORDER,
  Insured: INSURED_ROLE_ORDER,
  Assignee: ASSIGNEE_ROLE_ORDER,
  Annuitant: ANNUITANT_ROLE_ORDER,
}

const ROLE_LABELS = {
  Beneficiary: BENEFICIARY_ROLE_LABELS,
  Owner: OWNER_ROLE_LABELS,
}

export const MISC_ROLES = {
  GUARDIAN: '27',
  CONSERVATOR: '205',
  POWER_OF_ATTORNEY: '113',
  POWER_OF_ATTORNEY_FOR: '114',
}
const ALL_ROLE_LABELS = Object.assign({}, BENEFICIARY_ROLE_LABELS2, OWNER_ROLE_LABELS)

export const ALL_ROLES = {
  8: "Owner",
  27: "Guardian",
  31: "Payer",
  32: "Insured",
  33: "Coverage Insured",
  34: "Primary Beneficiary",
  35: "Annuitant",
  36: "First Contingent Beneficiary",
  40: "Dependent",
  57: "Custodian",
  59: "Trustee of Minor",
  69: "Trustee",
  78: "Successor Owner",
  113: "Power of Attorney",
  114: "Power of Attorney for",
  145: "Collateral Assignee",
  153: "Contingent Annuitant",
  177: "Contingent Owner",
  183: "Joint Annuitant",
  184: "Joint Owner",
  189: "Joint Insured",
  205: "Conservator",
  227: "Dividend Payee",
  260: "Primary Contact",
  1012300003: "Final Beneficiary",
  1012300004: "Second Contingent Beneficiary",
  1012300005: "Ltd Power of Attorney",
  1012300006: "Recipient of Duplicate Bill",
  1012300007: "Recipient of Duplicate Lapse Notice",
  1012300008: "Recipient of Tax Form",
  1012300009: "Step In Annuitant",
  1012300010: "Payable To Alternate Addressee",
  1012300012: "Dup. Life Statement",
  1012300013: "Additional Insured",
  1012300014: "Insured Child",
  1012300015: "Alternate Addressee",
  1012300016: "Original Annuitant",
  1012300017: "Original Owner",
  1012300018: "Duplicate Premium Bill Recipient",
  1012300019: "Final Owner",
  2147483647: "Other",
}
/**
 * Insured Contants & Lookup Tables
 */

/**
 * Insured Contants & Lookup Tables
 */
export { BENEFICIARY_ROLES, ROLES_ARRAY, ROLES_ORDER, ROLE_LABELS, BENEFICIARIES_ROLE_ORDER, BENEFICIARIES_ROLE_VALUES, OWNER_ROLES, ANNUITANT_ROLES, ALL_ROLE_LABELS, INSURED_ROLES_ARRAY }
