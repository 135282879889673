import angular from 'angular'

const BENEFICIARY_VIEWS = {
  LOADING: 'LOADING',
  READONLY: 'READONLY',
  INITIAL: 'INITIAL',
  EDIT: 'EDIT',
  REVIEW: 'REVIEW',
  CONFIRMATION: 'CONFIRMATION',
  ERROR: 'ERROR',
} as const

const BENEFICIARY_VIEW_STATES = {
  [BENEFICIARY_VIEWS.LOADING]: {
    state: BENEFICIARY_VIEWS.LOADING,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: false,
  },
  [BENEFICIARY_VIEWS.READONLY]: {
    state: BENEFICIARY_VIEWS.READONLY,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: false,
  },
  [BENEFICIARY_VIEWS.INITIAL]: {
    state: BENEFICIARY_VIEWS.INITIAL,
    showChangeButton: true,
    showSaveButtons: false,
    showContinueButton: false,
  },
  [BENEFICIARY_VIEWS.EDIT]: {
    state: BENEFICIARY_VIEWS.EDIT,
    showChangeButton: false,
    showSaveButtons: true,
    showContinueButton: false,
    saveButtonLabel: 'Review Changes',
  },
  [BENEFICIARY_VIEWS.REVIEW]: {
    state: BENEFICIARY_VIEWS.REVIEW,
    showChangeButton: false,
    showSaveButtons: true,
    showContinueButton: false,
    saveButtonLabel: 'Submit Changes',
  },
  [BENEFICIARY_VIEWS.CONFIRMATION]: {
    state: BENEFICIARY_VIEWS.CONFIRMATION,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: true,
  },
  [BENEFICIARY_VIEWS.ERROR]: {
    state: BENEFICIARY_VIEWS.ERROR,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: true,
  },
}

const CLIENT_BENEFICIARY_VIEW_STATES = {
  [BENEFICIARY_VIEWS.LOADING]: {
    state: BENEFICIARY_VIEWS.LOADING,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: false,
  },
  [BENEFICIARY_VIEWS.READONLY]: {
    state: BENEFICIARY_VIEWS.READONLY,
  },
  [BENEFICIARY_VIEWS.EDIT]: {
    state: BENEFICIARY_VIEWS.EDIT,
    showChangeButton: false,
    showSaveButtons: true,
    showContinueButton: false,
    saveButtonLabel: 'Save Changes',
    showCategories: true,
  },
  [BENEFICIARY_VIEWS.REVIEW]: {
    state: BENEFICIARY_VIEWS.REVIEW,
    showChangeButton: false,
    showSaveButtons: true,
    showContinueButton: false,
    saveButtonLabel: 'Submit Changes',
    showCategories: true,
  },
  [BENEFICIARY_VIEWS.CONFIRMATION]: {
    state: BENEFICIARY_VIEWS.CONFIRMATION,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: true,
    showCategories: true,
  },
  [BENEFICIARY_VIEWS.ERROR]: {
    state: BENEFICIARY_VIEWS.ERROR,
    showChangeButton: false,
    showSaveButtons: false,
    showContinueButton: true,
    showCategories: false,
  },
}

export { BENEFICIARY_VIEWS, BENEFICIARY_VIEW_STATES, CLIENT_BENEFICIARY_VIEW_STATES }

angular.module('app.beneficiaries')
  .constant('BENEFICIARY_VIEWS', BENEFICIARY_VIEWS)
  .constant('BENEFICIARY_VIEW_STATES', BENEFICIARY_VIEW_STATES)
  .constant('CLIENT_BENEFICIARY_VIEW_STATES', CLIENT_BENEFICIARY_VIEW_STATES)
