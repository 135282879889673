export class ContactUsRunner {
    static $inject = ['routerHelper']

    constructor(routerHelper) {
      const myStates = this.getStates()

      routerHelper.configureStates(myStates)
      console.count('contact-us')

    }

    getStates() {
      return [{
        state: 'contact-us',
        config: {
          url: '/contact-us',
          component: 'contactUs',
          // templateUrl: 'app/contact/us/contact-us.html',
          // controller: 'ContactUsController',
          // controllerAs: 'contactUsCtrl',
          data: {
            // addToNavigation: true,
            secure: true,
            module: 'contactUs',
          },
          onEnter: ['$rootScope', ($rootScope) => {
            $rootScope.pageTitle = 'Contact Us'
            $rootScope.mobileHeaderTitle = 'Contact Us'
            // $rootScope.hideBarHeader = false;
            // $rootScope.state = CONSTANTS.modules.NbaUpload.id
          }],
        },
      }]
    }
  }
