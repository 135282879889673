export {WgPendingPolicyItemController}

function WgPendingPolicyItemController(stateTransitions, policyUtils, authService, $attrs) {
  const self = this

  self.authService = authService
  self.policyUtils = policyUtils

  self.isRequirementsMetVisible = function() {
    const infoExists = Boolean(self.policy.requirementInfo)
    const hasReqs = infoExists && self.policy.requirementInfo.label !== '0/0'

    return infoExists && hasReqs
  }

  self.showRequirements = function() {
    self.policy.requirementInfo = self.policy.requirementInfo || {}
    return self.policy.requirementInfo.label !== '0/0'
  }

  self.isAssigned = function() {
    return self.policy.assignmentCode && self.policy.assignmentCode.tc === '2'
  }

  self._init = function() {
    const isRestricted = authService.restrictByIssueState(self.policy.issueState)

    self.getHrefString = authService.getPolicyHref(self.policy.source, self.policy.polNumber)

    self.isRestricted = isRestricted

    if (self.isRestricted) {
      self.getHrefString = ''
    }

    self.insuredNameProminent = policyUtils.isItemInsuredNameProminent(authService.isAgentView(), $attrs)
  }

  self.policyVerbiage = policyUtils.isAnnuity(self.policy) ? 'Contract' : 'Policy'
  self.isHomeOffice = authService.isHomeOfficeUser()

  self._init()
}

export function WgPendingPolicyItem() {
  return {
    restrict: 'EA',
    scope: {
      policy: '=',
    },
    templateUrl: 'app/widgets/templates/wg-pending-policy-item.html',
    controller: [
      'stateTransitions',
      'policyUtils',
      'authService',
      '$attrs',
      WgPendingPolicyItemController,
    ],
    controllerAs: 'wgPendingPolicyCtrl',
    bindToController: true,
  }
}
