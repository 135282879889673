import { findOrCreateModule } from '../../ng-helper'
import './constants'
import {AlertTableController} from './controller'
import {WgAlertTableDirective} from './directive'
import { wgAlertCheckbox } from './wg-alert-checkbox.directive'

findOrCreateModule('app.directives', [])
  .directive('wgAlertTable', WgAlertTableDirective.factory())
  .controller('AlertTableController', AlertTableController)
  .directive('wgAlertCheckbox', wgAlertCheckbox)
