import * as angular from 'angular'
interface ConversionOption {
  name: string
  display: string
}

class WgConversionCreditsController {
  public applicationInit: boolean = false
  public termConversionApplicationUrl: string
  public conversionChoice: boolean = true
  public isHomeOfficeUser: boolean = false
  public effDate: Date
  public paidToDate: Date
  public policyDetails
  public clients
  public conversionDate: Date
  public faceAmount: number
  public currentDate: Date
  public monthiversaryOptions: ConversionOption[]
  public organizedClients: Clients[]
  public policyId: string
  public logo
  public closeCallback: () => void
  public closeWidget: () => void
  public convertTermResult: any
  public userConvertTermResult: any
  public userConvertTermErrorMessage: string = ''
  public convertTermErrorMessages: any
  public convertTermErrorTcs: string[]
  public formData: {
    confirmationNumber: string | null
    conversionAllowedValues: {
      numbers: number[]
      ranges: Array<{
        maximum: number
        minimum: number,
      }>,
    }
    conversionAmount: number
    conversionAmountMax: number | null
    conversionAmountMin: number | null
    conversionResult: any
    conversionRetainedMin: number | null
    errorCode: string | null
    errorMessage: string | null
    monthiversary: string | null,
  }

  constructor(private CONSTANTS, private dateUtils, private policyService, private utils, private clientUtils,
               private configService, private $window: angular.IWindowService, private policyUtils, authService) {
    this.currentDate = new Date()
    this.isHomeOfficeUser = authService.isHomeOfficeUser()
  }

  $onInit() {

    this.formData = {
      confirmationNumber: null,
      conversionAllowedValues: {
        numbers: [this.faceAmount],
        ranges: [{
          minimum: this.CONSTANTS.conversionCreditsMinimum,
          maximum: this.faceAmount,
        }],
      },
      conversionAmount: this.faceAmount,
      conversionAmountMax: this.faceAmount,
      conversionAmountMin: this.CONSTANTS.conversionCreditsMinimum,
      conversionResult : null,
      conversionRetainedMin: this.CONSTANTS.conversionCreditsRetainedMinimum,
      errorCode: null,
      errorMessage: null,
      monthiversary: null,
    }

    this.closeWidget = this.closeCallback
      // this.policyDetails = this.policyDetails
    this.organizedClients = this.clientUtils.organizeClientDetails(this.clients, this.CONSTANTS.roleOrders)
    this.termConversionApplicationUrl = this.configService.termConversionApplicationUrl
    this.policyService.getPolicyCanUserConvertTerm(this.policyId).then((result) => {
      this.userConvertTermResult = result.policyCanUserConvertTerm
      if (!this.userConvertTermResult.isTransactable) {
        this.userConvertTermErrorMessage = 'Financial Professionals are able to initiate digital term conversion for eligible policies.'
      } else {
        this.policyService.getPolicyCanConvertTerm(this.policyId).then((policyResult) => {
          this.convertTermResult = policyResult.policyCanConvertTerm.canTransacts.filter((item) => item.transaction.tc === '997')[0]
          if (!this.convertTermResult.isTransactable) {
            this.handlePolicyTermConversionErrors(this.convertTermResult.reasons)
          }
        })
      }
    })

    this.monthiversaryOptions = this.potentialConversionMonthiversaries(this.effDate, this.paidToDate, this.conversionDate, new Date())
    this.formData.monthiversary = this.monthiversaryOptions[0].name
    this.logo = this.policyUtils.deriveCarrierLogo(this.policyDetails.carrierCode)
  }

  handlePolicyTermConversionErrors(reasons) {
    this.convertTermErrorTcs = reasons.map((reason) => reason.tc)
    this.removeDuplicateErrorTc(this.convertTermErrorTcs)
    this.convertTermErrorMessages = this.convertTermErrorTcs.map((tc) => {
      return this.setConvertTermErrorMessage(tc)
    })
  }

  setConvertTermErrorMessage(errorTc) {
    return this.CONSTANTS.policyUnableTermConvert[errorTc]
  }

  removeDuplicateErrorTc(tcArray) {
    if (tcArray.includes('23') && tcArray.includes('29')) {
      tcArray.splice(tcArray.indexOf('23'), 1)
    }
  }

  disablePartialConversion(): boolean {
    return this.faceAmount < 50000
  }

  isUnderRetainedMin(faceAmt, amtToConvert): boolean {
    return this.faceAmountAfterConversion(faceAmt, amtToConvert) < this.CONSTANTS.conversionCreditsRetainedMinimum &&
          this.faceAmountAfterConversion(faceAmt, amtToConvert) !== 0
  }

  faceAmountAfterConversion(faceAmt, amtToConvert): number {
    return faceAmt - amtToConvert
  }

  shouldShowChoice(): boolean {
    return !this.formData.errorMessage && !this.formData.conversionResult && !this.applicationInit && this.conversionChoice
  }

  shouldShowForm(): boolean {
    return !this.formData.errorMessage && !this.formData.conversionResult && !this.applicationInit && !this.conversionChoice
  }

  shouldShowError(): boolean {
    return Boolean(this.formData.errorMessage) && !this.applicationInit
  }

  shouldShowResults(): boolean {
    return this.formData.conversionResult && !this.formData.errorMessage && !this.applicationInit && !this.conversionChoice
  }

  shouldShowApplicationInit(): boolean {
    return !this.formData.errorMessage && this.applicationInit && !this.conversionChoice
  }

  openApplicationInit() {
    this.conversionChoice = false
    this.applicationInit = true
  }

  showForm() {
    this.conversionChoice = false
  }

  tryAgain() {
    this.formData.errorCode = null
    this.formData.errorMessage = null
    this.formData.confirmationNumber = null
  }

  setConversionResults(data): void {
    if (!data.error) {
      this.formData.conversionResult = data.data

      window.dataLayer.push({
        event: 'CONVERSION_CREDIT',
        action: 'RESULT',
        policyId: this.policyId,
        baseFaceAmt: this.faceAmount,
        conversionAmt: this.formData.conversionAmount,
        jurisdiction: this.policyDetails.jurisdiction,
        policyAge: this.policyDetails.yearsInForce,
      })
    }
  }

  submitConversion() {
    const amountAfterConversion = this.faceAmount - this.formData.conversionAmount

    this.policyService.getConversionCredits(this.policyId, this.formData.conversionAmount, this.formData.monthiversary, amountAfterConversion)
          .then(this.setConversionResults.bind(this))
  }

  startApplication() {
    const url = `${this.configService.termConversionApplicationUrl}${this.policyId}`

    if (this.shouldShowApplicationInit() && !this.formData.conversionResult) {
      this.$window.open(`${url}/0/0`)
    } else {
      this.$window.open(`${url}/${this.formData.conversionAmount}/${this.faceAmountAfterConversion(this.faceAmount, this.formData.conversionAmount)}`)
    }

    this.utils.windowDataLayerPush(window, 'CONVERSION_CREDIT', 'START E-APP', {policyId: this.policyId}, '')
  }

  _monthiversaryOption(monthiversary): ConversionOption {
    return {
      name: this.dateUtils.formatDashJoinedDate(monthiversary),
      display: monthiversary.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'}),
    }
  }

    // most recent and up to paid monthiversaries
  potentialConversionMonthiversaries(effDateObject, paidToDateObject, conversionDate, now) {
    const monthiversaryOptions: ConversionOption[] = []
    const potentialConversionMonthiversary = this.dateUtils.mostRecentMonthiversary(effDateObject, now)

    while ((potentialConversionMonthiversary <= paidToDateObject && potentialConversionMonthiversary < conversionDate) && monthiversaryOptions.length < 3) { // eslint-disable-line no-unmodified-loop-condition
      monthiversaryOptions.push(this._monthiversaryOption(potentialConversionMonthiversary))
      potentialConversionMonthiversary.setMonth(potentialConversionMonthiversary.getMonth() + 1)
    }

    if (monthiversaryOptions.length === 0) {
      monthiversaryOptions.push(this._monthiversaryOption(paidToDateObject))
    }

    return monthiversaryOptions
  }

  isUnderConversionMinimum(): boolean {
    return this.formData.conversionAmount < this.CONSTANTS.conversionCreditsMinimum
  }

  printConversionResults = function (data, windowTitle) {
    return this.utils.printPopupWindow(data, windowTitle)
  }

  isIE = () => this.utils.isIE()

  isPhone = () => this.utils.isPhone()

  isMobileSafari = () => this.utils.isMobileSafari()

  setValuesValidDate = this.dateUtils.addDaysToDate
}

WgConversionCreditsController.$inject = ['CONSTANTS', 'dateUtils', 'policyService', 'utils', 'clientUtils', 'configService', '$window', 'policyUtils', 'authService']

angular.module('app.directives')
      .controller('wgConversionCreditsController', WgConversionCreditsController)
      .component('wgConversionCredits', {
        bindings: {
          faceAmount: '<',
          closeCallback: '<',
          effDate: '<',
          paidToDate: '<',
          policyId: '<',
          conversionDate: '<',
          policyDetails: '<',
          clients: '<',
        },
        templateUrl: 'app/widgets/templates/wg-conversion-credits.html',
        controller: WgConversionCreditsController,
        controllerAs: 'ctrl',
      })
