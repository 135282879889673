export class RolesEditController {
  static $inject: any = ['$state']

  stateName: string

  constructor(public $state) {}

  // $onInit() {}

  $doCheck() {
    this.stateName = this.$state.current.name
    console.log(this.$state.current.name)
  }

  stuff() {
    return 'stuff'
  }
}
