class SortDropdownComponentController {
  static $injector: any = ['$scope']

  sortField: string
  sortDirection: string
  currentSort: any

  // constructor() {}
}

const SortDropdown = {
  templateUrl: 'app/search/all/sort-dropdown/sort-dropdown.html',
  controller: SortDropdownComponentController,
  controllerAs: 'sortDropdownCtrl',
  bindings: {
    currentSort: '<',
    source: '<',
  },
}

export {SortDropdown}
