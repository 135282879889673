/* deprecated */
export class CommonNavController {
  $inject = []

  // constructor () { }

  // logout() {
  //   return this.navigationService.logout()
  // }

  // $onInit() {
  //   this.fullName = this.authService.getFullName()
  // }
}
