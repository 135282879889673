export {AllPoliciesController}

AllPoliciesController.$inject = ['$state', '$stateParams', 'allPoliciesService', 'prodTypesResult', 'policyUtils', 'CONSTANTS']

/* @ngInject */
function AllPoliciesController($state, $stateParams, allPoliciesService, prodTypesResult, policyUtils, CONSTANTS) {
  const self = this

  self.params = {
    'source': '',
    'lob': '',
    'productType': '',
  }

  self.lob = {
    all: null,
    life: null,
    annuity: null,
  }

  self.productTypes = []

  self.allPoliciesService = allPoliciesService
  self.messageResult = allPoliciesService.message
  self.error = allPoliciesService.error
  self.notAvailableInfo = CONSTANTS.notAvailableInfo

  self.SCROLL_DISTANCE = 1

  self.setActiveFilter = function(set) {
    allPoliciesService.resetData()
    init()
  }

  self.setActiveSource = function(source) {
    self.params.source = source
    self.refreshResults()
  }

  self.isSourceActive = function(source) {
    return self.params.source === source
  }

  self.setActiveLob = function(lob, refresh) {
    refresh = refresh === undefined ? true : refresh

    if (self.params.lob === lob || !lob) {
      self.params.lob = ''
      self.lob.all = false
      self.lob.life = false
      self.lob.annuity = false
    } else {
      self.params.lob = lob

      switch (self.params.lob) {
        case 'Life':
          self.lob.life = true
          self.lob.annuity = false
          self.lob.all = false
          break

        case 'Annuity':
          self.lob.annuity = true
          self.lob.life = false
          self.lob.all = false
          break

        case 'All':
          self.lob.annuity = false
          self.lob.life = false
          self.lob.all = true
          break

        default:
          console.warn('lob is not supported: ', self.params.lob)
      }
    }

    if (refresh) {
      self.refreshResults()
    }
  }

  self.setActiveProductType = function(productType, checked, e) {
    let i

    if (e && e.target && e.target.className && e.target.className.indexOf('checkbox-container') !== -1) {
      checked = !checked
    }

    for (i = 0; i < self.productTypes.length; i++) {
      if (self.productTypes[i].value === productType) {
        self.productTypes[i].checked = checked
        break
      }
    }

    self.refreshResults()
  }

  function setSelectedProductTypes(productTypesStr) {
    let selectedProductTypes, productTypes, i

    self.productTypes = []
    if (!productTypesStr) {
      return
    }

    selectedProductTypes = parseProductTypes(self.params.productType)
    productTypes = prodTypesResult.productTypes

    if (productTypes) {
      for (i = 0; i < productTypes.length; i++) {
        productTypes[i].checked = false
        if (selectedProductTypes.indexOf(productTypes[i].value) !== -1) {
          productTypes[i].checked = true
        }
      }
    }

    self.productTypes = prodTypesResult.productTypes
  }

  function parseProductTypes(productTypesStr) {
    let result = productTypesStr.replace('(', '').replace(')', '')

    result = result.replace(new RegExp('"', 'g'), '')
    result = result.split(' OR ')

    return result
  }

  self.isLobActive = function(lob) {
    return self.params.lob === lob
  }

  self.isProductTypeActive = function(productType) {
    return self.params.productType
  }

  self.isPendingScrollDisabled = function() {
    return allPoliciesService.isLoadingMore() || !allPoliciesService.moreData
  }

  self.nextPage = function() {
    if (!allPoliciesService.isLoadingMore() && allPoliciesService.moreData) {
      allPoliciesService.nextPage(self.params)
    }
  }

  self.isPendingPolicy = function(policy) {
    return policyUtils.isPendingPolicy(policy)
  }

  function getSelectedProductTypes() {
    const result = []
    const productTypes = self.productTypes
    let i

    if (productTypes.length === 0) {
      return ''
    }

    for (i = 0; i < productTypes.length; i++) {
      if (productTypes[i].checked) {
        result.push('"' + productTypes[i].value + '"')
      }
    }

    return result.length > 0 ? '(' + result.toString().replace(new RegExp(',', 'g'), ' OR ') + ')' : ''
  }

  self.refreshResults = function() {
    if ((self.params.source !== self.origSource)) {
      self.productTypes = []
    }

    if (self.params.lob && self.origLob && (self.params.lob !== self.origLob)) {
      self.productTypes = []
    }

    self.params.productType = getSelectedProductTypes()
    $state.go('allPolicies', self.params)
  }

  function init() {
    self.setActiveLob($stateParams.lob, false)

    self.params = {
      'source': $stateParams.source || '',
      'lob': $stateParams.lob || '',
      'productType': $stateParams.productType || '',
    }

    self.origSource = self.params.source
    self.origLob = self.params.lob

    setSelectedProductTypes(prodTypesResult.productTypes)
  }

  init()
}
