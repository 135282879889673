import {RequirementsHelperService} from './requirements-helper-service'
export class ReplyableRequirementsController {
  static $injector: any = ['$scope', 'requirementsHelperService', 'authService', 'partyService', 'REQUIREMENT_NO_DATA_MESSAGES']

  public showReplyButton: Function
  public showUploadReplyButton: Function
  public replies: any = {}
  public canSubmit: any[]
  public openReplyRow: Function
  public closeReplyRow: Function
  public openUploadReply: Function
  public openTextReply: Function
  public closeRequirementReply: Function
  public uploadFile: Function
  public disableReplyInputs: Function
  public longEnough: Function
  public replyCharactersRemaining: Function
  public sendReply: Function
  public toggleNote: Function
  public getDataOptions: any = {
    pageable: false,
  }

  constructor(private $scope, requirementsHelperService: RequirementsHelperService, private authService, partyService, private REQUIREMENT_NO_DATA_MESSAGES) {
    this.getDataOptions.filterKey = partyService.getAgentKey()
    this.getDataOptions.isAgent = this.authService.getCurrentSession()?.canAccess?.isAdvisor
    this.showReplyButton = requirementsHelperService.showReplyButton.bind(requirementsHelperService, undefined, this.replies)
    this.showUploadReplyButton = requirementsHelperService.showReplyButton.bind(requirementsHelperService, undefined, this.replies)
    this.openReplyRow = requirementsHelperService.openReplyRow.bind(requirementsHelperService, this.replies)
    this.closeReplyRow = requirementsHelperService.closeRequirementReply.bind(requirementsHelperService, this.replies)
    this.openUploadReply = requirementsHelperService.openUploadReply.bind(requirementsHelperService, this.replies)
    this.openTextReply = requirementsHelperService.openTextReply.bind(requirementsHelperService, this.replies)
    this.closeRequirementReply = requirementsHelperService.closeRequirementReply.bind(requirementsHelperService)
    this.uploadFile = requirementsHelperService.uploadFile.bind(requirementsHelperService, this.replies)
    this.disableReplyInputs = requirementsHelperService.disableReplyInputs.bind(requirementsHelperService, this.replies)
    this.longEnough = requirementsHelperService.longEnough.bind(requirementsHelperService, this.replies)
    this.replyCharactersRemaining = requirementsHelperService.replyCharactersRemaining.bind(requirementsHelperService, this.replies)
    this.sendReply = requirementsHelperService.sendReply.bind(requirementsHelperService, this.replies)
    this.toggleNote = requirementsHelperService.toggleNote.bind(requirementsHelperService)

    // Check if the current user is able to viewAs an agent.
    this.$scope.isAgent = this.authService.isInRole('AGENT')
    this.$scope.isNotAgent = !this.$scope.isAgent

    // Resolve noDataMessage
    if (this.$scope.isAgentView && this.$scope.isNotAgent && !this.getDataOptions.filterKey) {
      this.$scope.noDataMessage = this.REQUIREMENT_NO_DATA_MESSAGES.homeOffice.message
    } else if (this.$scope.isAgentView && !this.$scope.isAgent && this.getDataOptions.filterKey || this.$scope.isAgentView && this.$scope.isAgent) {
      this.$scope.noDataMessage = this.REQUIREMENT_NO_DATA_MESSAGES.agentNoData.message
    }
  }

  $onInit() {
    const clearDataWatch = this.$scope.$on('PML_NG_LISTVIEW_DATA_READY', (_e, _name, listViewCtrl) => {
      // Update the dataCounts for data source name received.

      // Update the noRequirementDataAvailable flag accordingly
      this.$scope.noRequirementDataAvailable = !listViewCtrl.data || listViewCtrl.data.length === 0 || !this.$scope.isAgent && !this.getDataOptions.filterKey
    })

    // Cleanup any watchers created by this controller.
    this.$scope.$on('$destroy', () => {
      if (typeof clearDataWatch === 'function') clearDataWatch()
    })
  }

}
