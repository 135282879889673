import { Beneficiary } from '../../beneficiaries/classes/beneficiary'

export class RolesEditPersonalDetailsController {
  static $inject: any = ['stateUtils']

  beneficiary: Beneficiary

  constructor(public stateUtils) {}

  previous = () => this.stateUtils.checkUserAndGotoState('roles.edit.role-details')

  next = () => this.stateUtils.checkUserAndGotoState('roles.edit.personal-information')

  $onInit() {
    this.beneficiary = new Beneficiary('34', { fullName: 'Person', group: 'New Party', id: 'person', isNew: true, isNewPartyOption: true, partyTypeCode: { tc: '1', value: 'Person'}}, 'NEW_PARTY_OPTION')
    console.log(this.beneficiary)
  }
}
