(function() {
  'use strict'

  angular.module('app.directives')
    .directive('wgSpinner', ['$http', 'CONSTANTS', 'deviceUtils', '$rootScope',
      function($http, CONSTANTS, deviceUtils, $rootScope) {
        return {
          scope: {
            showWhenBackground: '=showWhenBackground',
          },
          link: function(scope, elm) {
            let timeoutBegin = null
            let timeBegin = CONSTANTS.spinnerDelay ? CONSTANTS.spinnerDelay : 0
            const showWhenBackground = scope.showWhenBackground || false

            const showInBackground = function(request) {
              if (showWhenBackground) {
                return true
              }

              // Adding the negavtive test for '/user/keepalive' prevents $root.xhrActive
              // flag from being set to true when the keepalive polling occurs.
              return !request.background && request.url !== '/user/keepalive'
            }

            const showSpinner = function(isNotNative) {
              if (!timeoutBegin) {
                timeoutBegin = setTimeout(function() {
                  elm.removeClass('ng-hide')
                  $rootScope.showingMainSpinner = true
                }, timeBegin)
              }
            }

            const hideSpinner = function() {
              if (timeoutBegin) {
                clearTimeout(timeoutBegin)
                timeoutBegin = null
                if (!elm.hasClass('ng-hide')) {
                  elm.addClass('ng-hide')
                }
                $rootScope.showingMainSpinner = false
              }
            }

            scope.isLoading = function() {
              scope.$root.xhrActive = !scope.$root.preventSpinnerFromShowing && $http.pendingRequests.length > 0 &&
                $http.pendingRequests.some(showInBackground)

              return scope.$root.xhrActive
            }

            scope.$on('forceToShowSpinnerEvent', function() {
              timeBegin = 0
              showSpinner(true)
            })

            scope.$watch(scope.isLoading, function(loading) {
              if (loading) {
                showSpinner()
              } else {
                hideSpinner()
              }
            })
          },
        }
      },
    ])
})()
