import * as angular from 'angular'

angular.module('app.replyable-requirements', ['app.auth', 'app.utils'])
import {ReplyableRequirementsDataProviderService} from './replyable-requirements-data-provider'
import {RequirementsHelperService} from './requirements-helper-service'
import {ReplyableRequirementsController} from './controller'
import './reply-upload'
import './reply-text'
import './reply-choice'
import { ReplyableRequirementsRunner } from './routes'
import './constants'

angular.module('app.replyable-requirements')
  .service('ReplyableRequirementsDataProvider', ReplyableRequirementsDataProviderService)
  .controller('replyableRequirementsController', ReplyableRequirementsController)
  .run(routerHelper => new ReplyableRequirementsRunner(routerHelper))

angular.module('app.policy')
  .service('requirementsHelperService', RequirementsHelperService)
