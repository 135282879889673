/* eslint-disable no-underscore-dangle */
import * as angular from 'angular'

interface UploadStatus {
  state: string
  errorMessage?: string
  errorCode?: string
  reason: string
}
export class UploadApplicationService {
  static $inject = ['$http', 'utils', 'REMOTE_STATUS_CODES', 'CONSTANTS']

  constructor(private $http: angular.IHttpService, private utils, private REMOTE_STATUS_CODES, private CONSTANTS) { }

  private _resolveUrl(uri: string, transId?: string) {
    return this.utils.appendURLParameter(uri, 'transId', transId)
  }

  private _createFormDataPayload(data, files) {
    const formData = new FormData()

    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })

    files.forEach((f, i) => {
      formData.append(`files[${i}]`, f, f.name)
    })

    return formData
  }

  upload(uri: string, data: any, files: any[], progressFn: Function): any {
    const url = this._resolveUrl(uri)
    const formData = this._createFormDataPayload(data, files)
    const config: any = {
      url,
      method: 'POST',
      data: formData,
      timeout: 0,
      // assign content-type as undefined, the browser
      // will assign the correct boundary for us
      headers: { 'Content-Type': undefined },
      // prevents serializing payload.  don't do it.
      transformRequest: angular.identity,
    }

    if (progressFn) {
      config.uploadEventHandlers = {
        progress: progressFn,
      }
    }

    return this.$http(config)
      .then(r => r.data)
      // eslint-disable-next-line no-throw-literal
      .catch(err => { throw { state: this.REMOTE_STATUS_CODES.FAILED, errorCode: err.status, errorMessage: err.statusText } })
  }

  poll(uri: string, transId: string): any {
    const url = this._resolveUrl(uri, transId)

    return this.$http.get<UploadStatus>(url)
      .then(pollResponse => pollResponse.data)
  }

  getDistributionChannels(): any {
    const url = `${this.CONSTANTS.apiRoot}/agent/whoami`

    return this.$http.get(url).then((result: any) => {
      return result.data?.producer?.primaryDistChannel
    })
  }

}
