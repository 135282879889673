import { BENEFICIARY_ROLES, CLIENT_BENEFICIARY_ROLE_LABELS } from '../constants/ROLES'
import { IBeneficiaryResults } from '../types'
import { Beneficiary } from './beneficiary'
import { ClientRoles } from './client-roles'

/**
 * Represents a single category of Beneficiaries
 */
export class Beneficiaries implements IBeneficiaryResults {
  beneficiaries: Beneficiary[]
  title: string
  subTitle: string
  addButtonLabel: string
  pid: string
  rid: string
  isNew: boolean
  hasPendingChanges: boolean
  associatedParties: ClientRoles

  /**
   *
   * @param sectionTitle
   * @param pid
   * @param rid
   * @param beneficiaries
   */
  constructor(sectionTitle: string, subTitle: string, pid: string, rid: string, beneficiaries: Beneficiary[], parties: ClientRoles) {
    this.beneficiaries = beneficiaries
    this.title = sectionTitle
    this.subTitle = subTitle
    this.addButtonLabel = `Add New ${CLIENT_BENEFICIARY_ROLE_LABELS[rid]}` // Only used by CBSS edit-trigger
    this.pid = pid
    this.rid = rid
    this.hasPendingChanges = false
    this.associatedParties = parties
  }

  /**
   * Returns the total number of beneficiaries that are not marked for deletion.
   */
  get count(): number {
    return this.beneficiaries.reduce((acc: number, beneficiary: Beneficiary) => {
      if (!beneficiary.toBeDeleted) acc++

      return acc
    }, 0)
  }

  /**
   * Returns `true` if the number of beneficiaries is greater than 0
   */
  get hasData(): boolean {
    const realCount: number = this.count

    return realCount > 0
  }

  /**
   * Returns the total number of beneficiaries maked as being an equal share, and not marked for deletion.
   */
  get equalSharesCount(): number {
    return this.beneficiaries.reduce((count, bene) => {
      if (bene.isEqualShare && !bene.toBeDeleted) count++

      return count
    }, 0)
  }

  /**
   * Returns true when the Benficiaries::equalSharesCount is greater than zero.
   */
  get hasEqualShares(): boolean {
    return this.equalSharesCount > 0
  }

  /**
   * Returns the total number of beneficiaries marked as being a flat amount, and not marked for deletion.
   */
  get flatAmountCount(): number {
    return this.beneficiaries.reduce((count, bene) => {
      if (bene.isFlatAmount && !bene.toBeDeleted) count++

      return count
    }, 0)
  }

  /**
   * Returns the total number of beneficiaries marked as being a percentage amount, and not marked for deletion.
   */
  get percentageShareCount(): number {
    return this.beneficiaries.reduce((count, bene) => {
      if (bene.isPercentage && !bene.toBeDeleted) count++

      return count
    }, 0)
  }

  /**
   * Returns the total flat amount shares that are not marked for deletion.
   */
  get flatAmountTotal(): number {
    return this.beneficiaries.reduce((total, bene) => {
      if (bene.isFlatAmount && !bene.toBeDeleted && bene.shareDistribution.amount) total += (bene.shareDistribution.amount ?? 0)

      return total
    }, 0)
  }

  /**
   * Returns `true` if the current category has any values that are monetary
   * distributions vs percentage.
   */
  get hasDollarAmounts(): boolean {
    return this.flatAmountCount > 0 && this.flatAmountTotal > 0
  }

  get dollarAmountsValid(): boolean {
    const test = this.beneficiaries.filter((bene) => bene.isFlatAmount && (typeof bene.shareDistribution.amount !== 'number' || bene.shareDistribution.amount === 0)).length

    return test === 0
  }

  /**
   * Returns the number of beneficiaries, that have percentage share, and not marked toBeDeleted.
   */
  get percentageTotal(): number {
    const tmpTotal = this.beneficiaries.reduce((total: number, bene: Beneficiary) => {
      if (bene.isPercentage && !bene.toBeDeleted) total += Number((bene.shareDistribution.amount ?? 0))

      return total
    }, 0)

    return (tmpTotal)
  }

  get percentageTotalAsString(): string {
    return this.percentageTotal.toFixed(2) + '%'
  }

  /**
   * returns true when Beneficiaries::percentageShareCount is greater than zero.
   */
  get hasPercentageShares(): boolean {
    return this.percentageShareCount > 0
  }

  /**
   * Returns true when there is at least one beneficiary that has no share distribution.
   */
  get hasInvalidShares(): boolean {
    return this.beneficiaries.reduce((acc, bene): boolean => {
      if (!acc) acc = !bene.isFinalBeneficiary && !bene.shareDistribution.type

      return acc
    }, false as boolean)
  }

  /**
   *
   */
  get hasValidShares(): boolean {
    return !this.hasInvalidShares
  }

  /**
   * Returns true when Beneficiaries::percentageTotal equal 100
   * Must check to ensure that if there are some shares with an amount of zero.
   * If there are then this cateogry can not possibly total 100%
   */
  get totals100Percent(): boolean {
    const tmp: boolean = this.percentageTotal === 100
    const amountsOfZero: Beneficiary[] = this.beneficiaries.filter((bene: Beneficiary) => {
      return bene.isPercentage && !bene.toBeDeleted && !bene.shareDistribution.amount
    })

    return tmp && amountsOfZero.length === 0
  }

  /**
   * Returns `true` if the `this.percentageTotal` is not 100%
   *
   * NOTE: this may need to be adjusted to allow for 99.9%
   */
  get not100Percent(): boolean {
    const tmp: boolean = this.percentageTotal < 100 || this.percentageTotal > 100

    return tmp
  }

  get newBeneficiaryCount(): number {
    return this.beneficiaries.reduce((counter, item) => {
      if (item.isNew) counter++

      return counter
    }, 0)
  }

  get hasNewBeneficiaries(): boolean {
    return this.newBeneficiaryCount > 0
  }

  /**
   * Returns the number of beneficiaries that are marked toBeDeleted
   */
  get deletedPolicyCount(): number {
    return this.beneficiaries.reduce((counter, item) => {
      if (item.toBeDeleted) counter++

      return counter
    }, 0)
  }

  /**
   * Returns true if the cateory role is BENEFICIARY_ROLES.PRIMARY, and the
   * deleted policy count is greater than 1.  Otherwise, returns true when
   * the deleted policy count is greater that 0.
   */
  get hasDeletedBeneficiaries(): boolean {
    return /* this.rid === BENEFICIARY_ROLES.PRIMARY ? this.deletedPolicyCount > 1 : */ this.deletedPolicyCount > 0
  }

  /**
   * Returns true when a category has both equal, and percentage shares.
   */
  get inconsistentData(): boolean {
    return this.hasEqualShares && this.hasPercentageShares
  }

  /**
   * Convenience property that returns the logical opposite of Beneficiaries::inconstentData.
   */
  get hasConsistentData(): boolean {
    return !this.inconsistentData
  }

  /**
   * Returns true if there are any beneficiaries that are marked as dirty.
   *
   * NOTE: This differs from the AnularJS Form::$dirty because it is used to
   *       track changes that are not input form related.
   *       (e.g. deleting an existing beneficiary)
   */
  get isDirty(): boolean {
    return this.beneficiaries.filter((bene) => bene.isDirty).length > 0
  }

  get percentageShareAreBalanced(): boolean {
    const percentZeroAmountBenes = this.beneficiaries.filter((bene: Beneficiary) => bene.isPercentage && bene.percentageAmount === 0)
    const hasZeroAmountBenes = percentZeroAmountBenes.length > 0

    if (this.count > 1) return !hasZeroAmountBenes && this.totals100Percent
    if (this.count === 1) return this.hasPercentageShares && this.totals100Percent

    return this.rid !== BENEFICIARY_ROLES.PRIMARY// Returning true here because not having any shares in a given category is valid
  }

  get className(): string {
    return this.title.split(' ')[0].toLowerCase()
  }
  /**
   * Sets all Beneficiary::isDirty flag to false for all beneficiaries in the category.
   */
  cleanBeneficiaries(): void {
    this.beneficiaries.forEach((bene) => {
      bene.isDirty = false
    })
  }

  /**
   * Remove a beneficiary from the beneficiaries array.
   *
   * The parameters mirror the documentation for Array.slice.
   *
   * @param start
   * @param end
   */
  removeBeneficiaryByIndex(start, end): void {
    this.beneficiaries = this.beneficiaries.slice(start, end)
  }

  /**
   * Returns plain javascript version of `this`.
   *
   * @returns any
   */
  serialize(originalBenes: Beneficiary[]): any {
    const serializedBenes: any[] = this.beneficiaries.reduce((benes, beneObj) => {
      const oBene = originalBenes.find((bene) => beneObj.id === bene.id)
      const tBene = beneObj.serialize(oBene)

      benes.push(tBene)

      return benes
    }, [] as any[])

    const serialized = serializedBenes.reduce((final: any, beneOp) => {
      if (beneOp.addressChanges) {
        beneOp.addressChanges.forEach((addresseChange: any) => {
          final.addressChanges[addresseChange.operation].push(addresseChange.payloadData)
        })
      }

      final.clientChanges[beneOp.clientChange?.operation]?.push(beneOp.clientChange.payloadData)

      if (beneOp.phoneChanges) {
        beneOp.phoneChanges.forEach((phoneChange: any) => {
          final.phoneChanges[phoneChange.operation].push(phoneChange.payloadData)
        })
      }

      final.roleChanges[beneOp.roleChange?.operation]?.push(beneOp.roleChange.payloadData)

      return final
    }, { addressChanges: {add: [], update: []}, clientChanges: { add: [], update: [] }, phoneChanges: { add: [], update: [], delete: [] }, roleChanges: { add: [], update: [], delete: [] } })

    return serialized
  }
}
