export {ContactController}
ContactController.$inject = ['contactResult', 'statesResult']

  /* @ngInject */
function ContactController(contactResult, statesResult) {
  const self = this

  self.isThereError = false
  self.policyStates = []
  self.allNyPolicies = false
  self.noNyPolicies = false
  self.someNyPolicies = false
  self.someNonNyPolicies = false
  self.states = statesResult

  function setError(error) {
    self.errorMessage = error
    self.isThereError = Boolean(self.errorMessage)
  }

  function _allNyPolicies(states) {
    return states.filter((state) => state.tc === '37').length === states.length
  }

  function _noNyPolicies(states) {
    return states.filter((state) => state.tc === '37').length === 0
  }

  function _someNyPolicies(states) {
    return states.some((state) => state.tc === '37')
  }

  function _someNonNYPolicies(states) {
    return states.some((state) => state.tc !== '37')
  }

  self.$onInit = function() {
    if (contactResult.error) {
      setError(contactResult.error)
    } else {
      self.policies = contactResult.client.policies
      self.allNyPolicies = _allNyPolicies(self.states)
      self.noNyPolicies = _noNyPolicies(self.states)
      self.someNyPolicies = _someNyPolicies(self.states)
      self.someNonNyPolicies = _someNonNYPolicies(self.states)
    }
  }
}
