class BeneficiaryRunner {
  /* @ngInject */
  static $inject = ['routerHelper', '$transitions', 'configService', 'authService']

  // constructor($transitions, configService, private authService, private $stateRegistry) {
  constructor(routerHelper, $transitions, configService, private authService) {
    // const myStates = this.getStates()

    // to: "private.**"
    $transitions.onBefore({ to: 'policy.changeBeneficiaries' }, (trans) => {

      // trans.addResolvable({
      // token: 'isBeneficiaryManagmentAllowed',
      // resolveFn: () => this.isBeneficiaryManagmentAllowed(),
      // })

      if ((configService.features.enableBeneficiaries && authService.isHomeOfficeUser()) || (configService.features.beneChangeSelfServeEligibilityButton && authService.isARealClient())) {
        return true
      }

      console.warn('denying route transition to ', trans.to().name)
      return trans.router.stateService.target('denied', undefined, { location: true })
    })

    routerHelper.configureStates(this.getStates())
    // this.configureStates()
  }

  isBeneficiaryManagmentAllowed(isDeathClaim: boolean = false): boolean {
    return this.authService.isBeneficiaryManagmentAllowed(isDeathClaim)
  }

  canSuppressCorrespondence(): boolean {
    return this.authService.canSuppressCorrespondence()
  }
  /* configureStates() { */
  /*   this.$stateRegistry.register({ */
  /*     name: 'denied', */
  /*     url: '/denied', */
  /*     template: '<h3>You are denied access to this route</h3>', */
  /*   }) */
  /*   this.$stateRegistry.register({ */
  /*     name: 'policy.changeBeneficiaries', */
  /*     component: 'beneficiariesComponent', */
  /*     url: '/change-beneficiaries', */
  /*     data: { */
  /*       // addToNavigation: true, */
  /*       secure: true, */
  /*       module: 'beneficiaries', */
  /*     }, */
  /*     resolve: { */
  /*       policySummary: ['summaryResult', (summaryResult) => summaryResult.policy], */
  /*       isBeneficiaryManagmentAllowed: [() => { */
  /*         let allowed = this.isBeneficiaryManagmentAllowed() */
  /*         console.log('are they allowed to manage', allowed) */
  /*         return allowed */
  /*       }], */
  /*     }, */
  /*     onEnter: ['$rootScope', 'CONSTANTS', ($rootScope, CONSTANTS) => { */
  /*       $rootScope.pageTitle = CONSTANTS.modules.beneficiaries.name */
  /*       $rootScope.mobileHeaderTitle = CONSTANTS.modules.beneficiaries.name */
  /*       // $rootScope.hideBarHeader = false; */
  /*       $rootScope.state = CONSTANTS.modules.beneficiaries.id */
  /*     }], */
  /*   }) */
  /* } */

  getStates() {
    return [
      {
        state: 'denied',
        config: {
          url: '/denied',
          template: '<h3>You are denied access to this route</h3>',
        },
      },
      {
        state: 'policy.changeBeneficiaries',
        config: {
          component: 'beneficiariesComponent',
          url: '/change-beneficiaries',
          data: {
            // addToNavigation: true,
            secure: true,
            module: 'beneficiaries',
          },
          params: {
            eligibility: null,
          },
          resolve: {
            policySummary: ['summaryResult', (summaryResult) => summaryResult.policy],
            organizedClients: ['clientResults', 'clientUtils', (clientResults, clientUtils) => clientUtils.organizeClientDetails({ parties: clientResults.data?.parties || [] })],
            isBeneficiaryManagmentAllowed: ['policySummary', (policySummary) => this.isBeneficiaryManagmentAllowed(policySummary.policyStatus.toLowerCase().includes('death claim'))],
            canSuppressCorrespondence: [() => this.canSuppressCorrespondence()],
          },
          onEnter: ['$rootScope', 'CONSTANTS', ($rootScope, CONSTANTS) => {
            $rootScope.pageTitle = CONSTANTS.modules.beneficiaries.name
            $rootScope.mobileHeaderTitle = CONSTANTS.modules.beneficiaries.name
            // $rootScope.hideBarHeader = false;
            $rootScope.state = CONSTANTS.modules.beneficiaries.id
          }],
        },
      }]
  }

}

angular
  .module('app.beneficiaries')
  .run((routerHelper, $transitions, configService, authService) => new BeneficiaryRunner(routerHelper, $transitions, configService, authService))
