export { policyUtils }
policyUtils.$inject = ['CONSTANTS', '$q', 'loggingService', 'utils', 'stateTransitions', 'dateUtils', 'clientUtils', '$filter']

/* @ngInject */
function policyUtils(CONSTANTS, $q, loggingService, utils, stateTransitions, dateUtils, clientUtils, $filter) {
  const service = {}

  const fixedExceptionCodes = ['MYGA00', 'DRA000', 'ODI000', 'OGA000', 'SPDA00']

  service.applicationType = function (policy) {
    if (policy.aceIndicator) {
      return 'Electronic'
    }

    return 'Paper'
  }

  service.applicationMethod = function (policy) {
    if (policy.formalAppInd && policy.applicationType !== 'Term Conversion') {
      return 'Formal'
    }

    if (policy.formalAppInd && policy.applicationType === 'Term Conversion') {
      return 'Term Conversion'
    }

    return 'Informal'
  }

  service.isActivePolicy = function (policySummary) {
    return typeof policySummary.holdingStatus === 'string' &&
      policySummary.holdingStatus.toLowerCase() === CONSTANTS.holdingStatus.active
  }

  service.isItemInsuredNameProminent = function (isAgentView, $attrs) {
    if ($attrs.hasOwnProperty('productNameProminent')) {
      return false
    }

    return $attrs.hasOwnProperty('insuredNameProminent') || isAgentView
  }

  service.gotoPolicySummaryByPolicyBaseOrNumber = function (base, polNumber) {
    if (base) {
      service.gotoPolicySummary(base)
    } else {
      stateTransitions.go('policy', { 'id': polNumber })
    }
  }

  service.gotoPolicySummary = function (policy) {
    const policySource = service.getPolicySource(policy)

    stateTransitions.go('policy', { 'id': policy.polNumber, 'policySource': policySource })
  }

  service.getPolicySource = function (policy) {
    if (policy.source) {
      return policy.source.toLocaleLowerCase()
    } else if (policy.holdingStatus) {
      return service.policyHoldingStatusToSource(policy.holdingStatus)
    } else if (policy.type) {
      return policy.type.toLocaleLowerCase()
    }

    return CONSTANTS.policySource.inForce
  }

  service.isAnnuity = function (policy) {
    if (policy.isAnnuity) {
      return true
    }

    return policy.lineOfBusiness && policy.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()
  }

  service.getPolicySourceFromStatus = function (holdingStatus, policySource) {
    if (policySource) {
      if (policySource.toLocaleLowerCase() === CONSTANTS.policySource.pending) {
        return CONSTANTS.policySource.pending
      } else if (policySource.toLocaleLowerCase() === CONSTANTS.policySource.rps) {
        return CONSTANTS.policySource.rps
      }

      return CONSTANTS.policySource.inForce
    }

    return holdingStatus && (holdingStatus.toLowerCase() === CONSTANTS.holdingStatus.pending) ? CONSTANTS.policySource.pending : CONSTANTS.policySource.inForce
  }

  service.policySourceToHoldingStatus = function (source) {
    return (source && source.toLocaleLowerCase() === CONSTANTS.policySource.pending) ? CONSTANTS.holdingStatus.pending : CONSTANTS.holdingStatus.active
  }

  service.policyHoldingStatusToSource = function (holdingStatus) {
    return (holdingStatus && holdingStatus.toLocaleLowerCase() === CONSTANTS.holdingStatus.pending) ? CONSTANTS.policySource.pending : CONSTANTS.policySource.inForce
  }

  service.convertToShortPID = function (polNumber) {
    polNumber = polNumber.toString()
    if (!angular.isString(polNumber)) return

    return polNumber.length === 10 ? polNumber.substring(2, 9) : polNumber
  }

  service.isApplication = function (policy) {
    const policySource = service.getPolicySource(policy)

    return angular.isString(policySource) && policySource.toLowerCase() === CONSTANTS.policySource.application
  }

  service.isInforcePolicy = function (policy) {
    const policySource = service.getPolicySource(policy)

    return angular.isString(policySource) && policySource.toLowerCase() === CONSTANTS.policySource.inForce
  }

  service.isRpsPolicy = function (policy) {
    const policySource = service.getPolicySource(policy)

    return angular.isString(policySource) && policySource.toLowerCase() === CONSTANTS.policySource.rps
  }

  service.isIssuedNotPaid = function (policy) {
    const policySource = service.getPolicySource(policy)

    return angular.isString(policySource) && policySource.toLowerCase() === CONSTANTS.policySource.notPaid
  }

  service.isPendingPolicy = function (policy) {
    const policySource = service.getPolicySource(policy)

    return angular.isString(policySource) && policySource.toLowerCase() === CONSTANTS.policySource.pending
  }

  service.policyStrategicAlliance = function (policy, parties) {
    if (service.isInforcePolicy(policy) || service.isIssuedNotPaid(policy) || service.isPendingPolicy(policy)) {
      // First reduce the parties agreements down to an array,
      // then filter for 'Strategic Allience' agreements only.
      const agreements = parties.reduce((acc, val) => {
        if (val.agreements) {
          acc = acc.concat(val.agreements)
        }

        return acc
      }, []).filter(agreement => agreement.role === 'Strategic Alliance')

      // Finally, return the first SA or undefined.
      return agreements[0]
    }
  }

  service.isTraditional = function (policyProductType) {
    return service.isTermPolicy(policyProductType) || service.isWholeLifeOrEndowment(policyProductType)
  }

  service.isLifePolicy = function (lineOfBusiness) {
    return lineOfBusiness === 'Life'
  }

  service.isTermPolicy = function (policyProductType) {
    return policyProductType === CONSTANTS.productType.term || policyProductType === CONSTANTS.productType.indeterminatePremiumLifeTerm
  }

  service.isWholeLife = function (policyProductType) {
    return policyProductType === CONSTANTS.productType.wholeLife
  }

  service.isUniversalLifePolicy = function (policyProductType) {
    return policyProductType === CONSTANTS.productType.universalLife ||
      policyProductType === CONSTANTS.productType.IUL ||
      policyProductType === CONSTANTS.productType.variableUniversalLife
  }

  service.isWholeLifeOrEndowment = function (policyProductType) {
    return policyProductType === CONSTANTS.productType.wholeLife || policyProductType === CONSTANTS.productType.endowment
  }

  service.isDefined = function (field) {
    let isValidField = field !== undefined

    if (isValidField && angular.isArray(field)) {
      isValidField = field.length > 0
    }

    return isValidField
  }

  function undefinedIfZeroOrLess(field, arrayField) {
    let isDefined = false

    if (angular.isArray(field) && arrayField) {
      field.forEach(function (item) {
        if (undefinedIfZeroOrLess(item[arrayField]) !== undefined) {
          isDefined = true
        }
      })

      return isDefined
    }

    return field > 0 ? field : undefined
  }

  service.atLeastOneFieldIsDefined = function (fieldsArray) {
    let existsField = false

    fieldsArray.forEach(function (field) {
      existsField = existsField || service.isDefined(field)
    })

    return existsField
  }

  service.getPaymentAmountValue = function (isAnnuity, faceAmount, initPaymentAmount, paymentAmount) {
    if (isAnnuity) {
      return initPaymentAmount || paymentAmount
    }

    return faceAmount
  }

  service.calcRequirementsMet = function (requirementsStatus) {
    let requirementsTotal = 0
    const requirementInfo = {
      'label': '0/0',
      'percentage': '0%',
    }

    const requirementTypes = CONSTANTS.policyRequirementType

    const unfinshedRequirementTypes = [requirementTypes.cancelled, requirementTypes.completed, requirementTypes.waived, requirementTypes.received]

    if (requirementsStatus) {
      const finishedRequirements = requirementsStatus.filter(req => unfinshedRequirementTypes.includes(req.type.toLocaleLowerCase()))

      requirementsTotal = requirementsStatus.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)
      const metSum = finishedRequirements.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)

      requirementInfo.label = metSum + '/' + requirementsTotal
      requirementInfo.percentage = requirementsTotal > 0
        ? ((metSum * 100 / requirementsTotal).toFixed(0) + '%')
        : '0%'
    }

    return requirementInfo
  }

  // MP @TODO, just remove this fuction when yanking baseResult
  service.getPolicyBaseFromResult = function (result) {
    if (result.error !== '') {
      return $q.reject(result.error)
    }
    return $q.resolve(result.policyBase)
  }

  service.getPolicyValuesFromResult = function (result, policyBase, isBackDatedRequest, max) {
    const productType = policyBase.productType

    if (result.error !== '') {
      return $q.reject(result.error)
    }

    const policyValues = {
      deathBenefit: [],
      cashSurrender: [],
      costBasis: null,
      loan: [],
    }

    let maths = {
      itemType: 'math-container',
      items: [],
      resultItem: {},
    }

    if (
      service.isDefined(result.policyValues.costBasis) &&
      productType === CONSTANTS.productType.universalLife &&
      (policyBase.qualifiedCode && policyBase.qualifiedCode.toLowerCase() === 'non-qualified')
    ) {
      policyValues.costBasis = [{
        itemType: 'info-two-cols',
        label: 'Cost Basis:',
        value: result.policyValues.costBasis.costBasis,
        valueType: 'currency',
      }]
    }

    // Death Benefit sections begin
    if (service.isDefined(result.policyValues.deathbenefit.deathBenefitAmt) &&
      (
        (service.isUniversalLifePolicy(productType) &&
          service.atLeastOneFieldIsDefined([
            result.policyValues.deathbenefit.baseDeathBenefitAmt,
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.deathBenefitAmtIncrease),
            result.policyValues.deathbenefit.policyValue,
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.riders),
          ])) ||
        (service.isWholeLifeOrEndowment(productType) &&
          service.atLeastOneFieldIsDefined([
            result.policyValues.deathbenefit.baseDeathBenefitAmt,
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.riders),
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.pUADBTotalAmtITD),
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBDivDepPostMortDivAmt),
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.oYTPurchaseAmt),
            undefinedIfZeroOrLess(result.policyValues.deathbenefit.oYTDBRefundAmt),
          ])) ||
        (service.isTermPolicy(productType) && service.atLeastOneFieldIsDefined([
          result.policyValues.deathbenefit.baseDeathBenefitAmt,
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBDivDepPostMortDivAmt),
        ]))
      )
    ) {
      if (service.isDefined(result.policyValues.deathbenefit.baseDeathBenefitAmt)) {
        const item = {
          label: 'Base Death Benefit:',
          value: result.policyValues.deathbenefit.baseDeathBenefitAmt,
          operator: '+',
        }

        if (isBackDatedRequest) {
          item.label = 'Death Benefit:'
        }

        maths.items.push(item)
      }

      if (service.isUniversalLifePolicy(productType) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.deathBenefitAmtIncrease))) {
        maths.items.push({
          label: 'Increases:',
          value: result.policyValues.deathbenefit.deathBenefitAmtIncrease,
          operator: '+',
        })
      }

      if (service.isUniversalLifePolicy(productType) && service.isDefined(result.policyValues.deathbenefit.policyValue)) {
        maths.items.push({
          label: 'Cash Value:',
          value: result.policyValues.deathbenefit.policyValue,
          operator: '+',
        })
      }

      if (service.isUniversalLifePolicy(productType) || service.isWholeLifeOrEndowment(productType)) {
        if (service.isDefined(result.policyValues.deathbenefit.riders)) {
          result.policyValues.deathbenefit.riders.forEach(function (item) {
            if (undefinedIfZeroOrLess(item.riderDBAmt) !== undefined) {
              maths.items.push({
                label: item.riderName + ':',
                value: item.riderDBAmt,
                operator: '+',
              })
            }
          })
        }
      }

      if (service.isWholeLifeOrEndowment(productType)) {
        if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.pUADBTotalAmtITD))) {
          maths.items.push({
            label: 'Paid Up Additions:',
            value: result.policyValues.deathbenefit.pUADBTotalAmtITD,
            operator: '+',
          })
        }

        if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBDivDepPostMortDivAmt))) {
          maths.items.push({
            label: 'Dividend Accumulations and Post Mortem Dividends:',
            value: result.policyValues.deathbenefit.dBDivDepPostMortDivAmt,
            operator: '+',
          })
        }

        if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.oYTPurchaseAmt))) {
          maths.items.push({
            label: '1 Year Term Death Benefit:',
            value: result.policyValues.deathbenefit.oYTPurchaseAmt,
            operator: '+',
          })
        }

        if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.oYTDBRefundAmt))) {
          maths.items.push({
            label: '1 Year Term Dividend Refund:',
            value: result.policyValues.deathbenefit.oYTDBRefundAmt,
            operator: '+',
          })
        }
      }

      if (service.isTermPolicy(productType) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBDivDepPostMortDivAmt))) {
        maths.items.push({
          label: 'Dividend Accumulations and Post Mortem Dividends:',
          value: result.policyValues.deathbenefit.dBDivDepPostMortDivAmt,
          operator: '+',
        })
      }

      if (maths.items.length > 0) {
        maths.items[0].operator = ''
      }

      maths.resultItem = {
        label: 'Death Benefit:',
        value: result.policyValues.deathbenefit.deathBenefitAmt,
      }

      policyValues.deathBenefit.push(maths)
    }

    if (service.isDefined(result.policyValues.deathbenefit.netDeathBenefitAmt) &&
      (
        (service.isUniversalLifePolicy(productType) && service.atLeastOneFieldIsDefined([
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.loanBalance),
        ])) ||
        (service.isWholeLifeOrEndowment(productType) && service.atLeastOneFieldIsDefined([
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.loanBalance),
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.premiumDueAmt),
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBInterestAmt),
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBPremRefundAmt),
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.termDivAmt),
        ])) ||
        (service.isTermPolicy(productType) && service.atLeastOneFieldIsDefined([
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.premiumDueAmt),
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBInterestAmt),
          undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBPremRefundAmt),
        ]))
      )
    ) {
      maths = {
        itemType: 'math-container',
        items: [],
        resultItem: {},
      }

      if (policyValues.deathBenefit.length === 0 && service.isDefined(result.policyValues.deathbenefit.deathBenefitAmt)) {
        maths.items.push({
          label: 'Death Benefit:',
          value: result.policyValues.deathbenefit.deathBenefitAmt,
          operator: '',
        })
      }

      if ((service.isUniversalLifePolicy(productType) || service.isWholeLifeOrEndowment(productType)) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.loanBalance))) {
        maths.items.push({
          label: 'Loan Balance:',
          value: result.policyValues.deathbenefit.loanBalance,
          operator: '-',
        })
      }

      if ((service.isTermPolicy(productType) || service.isWholeLifeOrEndowment(productType)) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.premiumDueAmt))) {
        maths.items.push({
          label: 'Premium Due:',
          value: result.policyValues.deathbenefit.premiumDueAmt,
          operator: '-',
        })
      }

      if ((service.isTermPolicy(productType) || service.isWholeLifeOrEndowment(productType)) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBInterestAmt))) {
        maths.items.push({
          label: 'Death Benefit Interest:',
          value: result.policyValues.deathbenefit.dBInterestAmt,
          operator: '+',
        })
      }

      if ((service.isTermPolicy(productType) || service.isWholeLifeOrEndowment(productType)) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.deathbenefit.dBPremRefundAmt))) {
        maths.items.push({
          label: 'Premium Refund:',
          value: result.policyValues.deathbenefit.dBPremRefundAmt,
          operator: '+',
        })
      }

      if (service.isWholeLifeOrEndowment(productType) && service.isDefined(undefinedIfZeroOrLess(undefinedIfZeroOrLess(result.policyValues.deathbenefit.termDivAmt)))) {
        maths.items.push({
          label: 'Termination Dividend:',
          value: result.policyValues.deathbenefit.termDivAmt,
          operator: '+',
        })
      }

      if (policyValues.deathBenefit.length === 0 && maths.items.length > 0) {
        maths.items[0].operator = ''
      }

      maths.resultItem = {
        label: 'Net Death Benefit:',
        value: result.policyValues.deathbenefit.netDeathBenefitAmt,
      }

      policyValues.deathBenefit.push(maths)
    }
    // Death Benefit sections end

    // Cash Surrender Value sections begin
    if (service.isUniversalLifePolicy(productType) || service.isWholeLifeOrEndowment(productType)) {
      if (
        (service.isUniversalLifePolicy(productType) &&
          service.isDefined(result.policyValues.cashsurrendervalue.netSurrenderValue) &&
          service.atLeastOneFieldIsDefined([
            result.policyValues.cashsurrendervalue.policyValue,
            result.policyValues.cashsurrendervalue.surrenderChargeAmt,
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.surrLoanBalance),
          ])) ||
        (service.isWholeLifeOrEndowment(productType) &&
          service.isDefined(result.policyValues.cashsurrendervalue.netSurrenderValue) &&
          service.atLeastOneFieldIsDefined([
            result.policyValues.cashsurrendervalue.policyValue,
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.pUACashValueTotalAmt),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.oYTCVRefundAmt),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.divOnDepositAmt),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.divOnDepositIntAmt),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.termDivAmt),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.unusedReduceDividend),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.premiumRefundAmt),
            undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.surrLoanBalance),
          ]))
      ) {
        maths = {
          itemType: 'math-container',
          items: [],
          resultItem: {},
          enhancedSurrenderValue: {},
        }

        if (service.isDefined(result.policyValues.cashsurrendervalue.enhancedSurrenderValue)) {
          maths.enhancedSurrenderValueAmount = {
            label: 'Enhanced Surrender Value:',
            value: result.policyValues.cashsurrendervalue.enhancedSurrenderValue,
          }
        }

        if (service.isDefined(result.policyValues.cashsurrendervalue.policyValue)) {
          maths.items.push({
            label: service.isUniversalLifePolicy(productType) ? 'Policy Cash Value:' : 'Guaranteed Cash Value:',
            value: result.policyValues.cashsurrendervalue.policyValue,
            operator: '',
            highlight: true,
          })
        }

        if (service.isUniversalLifePolicy(productType) && service.isDefined(result.policyValues.cashsurrendervalue.surrenderChargeAmt)) {
          maths.items.push({
            label: 'Surrender Charges:',
            value: result.policyValues.cashsurrendervalue.surrenderChargeAmt,
            operator: '-',
          })
        }

        if (service.isWholeLifeOrEndowment(productType)) {
          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.pUACashValueTotalAmt))) {
            maths.items.push({
              label: 'Paid Up Additions Cash Value:',
              value: result.policyValues.cashsurrendervalue.pUACashValueTotalAmt,
              operator: '+',
            })
          }

          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.oYTCVRefundAmt))) {
            maths.items.push({
              label: 'One Year Term Cash Value/Refund Value:',
              value: result.policyValues.cashsurrendervalue.oYTCVRefundAmt,
              operator: '+',
            })
          }

          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.divOnDepositAmt))) {
            maths.items.push({
              label: 'Dividends on Deposit:',
              value: result.policyValues.cashsurrendervalue.divOnDepositAmt,
              operator: '+',
            })
          }

          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.divOnDepositIntAmt))) {
            maths.items.push({
              label: 'Dividend on Deposit Interest:',
              value: result.policyValues.cashsurrendervalue.divOnDepositIntAmt,
              operator: '+',
            })
          }

          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.termDivAmt))) {
            maths.items.push({
              label: 'Termination Dividend:',
              value: result.policyValues.cashsurrendervalue.termDivAmt,
              operator: '+',
            })
          }

          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.unusedReduceDividend))) {
            maths.items.push({
              label: 'Unused Reduce Dividend:',
              value: result.policyValues.cashsurrendervalue.unusedReduceDividend,
              operator: '+',
            })
          }

          if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.premiumRefundAmt))) {
            maths.items.push({
              label: 'Premium Refund:',
              value: result.policyValues.cashsurrendervalue.premiumRefundAmt,
              operator: '+',
            })
          }
        }

        if (service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.surrLoanBalance))) {
          maths.items.push({
            label: 'Loan Balance:',
            value: result.policyValues.cashsurrendervalue.surrLoanBalance,
            operator: '-',
          })
        }

        if (maths.items.length > 0) {
          maths.items[0].operator = ''
        }

        maths.resultItem = {
          label: 'Net Cash Surrender Value:',
          value: result.policyValues.cashsurrendervalue.netSurrenderValue,
        }

        policyValues.cashSurrender.push(maths)
      }

      if (service.isUniversalLifePolicy(productType) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.premiumDepositFundAmt))) {
        policyValues.cashSurrender.push({
          itemType: 'highlight-info',
          label: 'Premium Deposit Fund:',
          value: result.policyValues.cashsurrendervalue.premiumDepositFundAmt,
          valueType: 'currency',
        })
      }

      if (service.isWholeLifeOrEndowment(productType) && service.isDefined(undefinedIfZeroOrLess(result.policyValues.cashsurrendervalue.premiumDepositFundAmt))) {
        policyValues.cashSurrender.push({
          itemType: 'highlight-info',
          label: 'Discounted Premium Amount:',
          value: result.policyValues.cashsurrendervalue.premiumDepositFundAmt,
          valueType: 'currency',
        })
      }
    }
    // Cash Surrender Value sections end

    // Loan sections begin
    if ((service.isUniversalLifePolicy(productType) || service.isWholeLifeOrEndowment(productType)) && service.isDefined(result.policyValues.loans)) {
      result.policyValues.loans.forEach(function (loan) {
        if (service.isDefined(loan.loanBalance) &&
          service.atLeastOneFieldIsDefined([
            undefinedIfZeroOrLess(loan.loanAmt),
            undefinedIfZeroOrLess(loan.loanIntAmtDue),
            undefinedIfZeroOrLess(loan.loanInterestRate),
            undefinedIfZeroOrLess(loan.loanType),
          ])
        ) {
          maths = {
            itemType: 'math-container',
            items: [],
            resultItem: {},
          }

          if (service.isDefined(loan.loanAmt)) {
            maths.items.push({
              label: 'Loan Principal:',
              value: loan.loanAmt,
              operator: '',
            })
          }

          if (service.isDefined(loan.loanIntAmtDue)) {
            maths.items.push({
              label: 'Accrued Loan Interest:',
              value: loan.loanIntAmtDue,
              operator: '+',
            })
          }

          if (maths.items.length > 0) {
            maths.items[0].operator = ''
          }

          maths.resultItem = {
            label: 'Loan Balance:',
            value: loan.loanBalance,
          }

          policyValues.loan.push(maths)
        }

        if (policyValues.loan.length > 0 ||
          service.atLeastOneFieldIsDefined([
            undefinedIfZeroOrLess(loan.loanAmt),
            undefinedIfZeroOrLess(loan.loanInterestRate),
            undefinedIfZeroOrLess(loan.loanType),
          ])
        ) {
          if (policyValues.loan.length === 0 && service.isDefined(loan.loanAmt)) {
            policyValues.loan.push({
              itemType: 'info-two-cols',
              label: 'Loan Principal:',
              value: loan.loanAmt,
              valueType: 'currency',
            })
          }

          if (service.isDefined(loan.loanInterestRate)) {
            policyValues.loan.push({
              itemType: 'info-two-cols',
              label: 'Loan Interest Rate:',
              value: max.policyLoanQuotes.length ? parseFloat(max.policyLoanQuotes[0].loanInterestRate) : loan.loanInterestRate,
              valueType: 'percent',
            })
          }

          if (service.isDefined(loan.loanType)) {
            policyValues.loan.push({
              itemType: 'info-two-cols',
              label: 'Loan Type:',
              value: loan.loanType,
              valueType: 'text',
            })
          }
        }
      })
    }
    // Loan sections end

    const sections = []

    if (policyValues.deathBenefit.length > 0) {
      const section = {
        sectionTitle: 'Death Benefit',
        sectionItems: policyValues.deathBenefit,
      }

      if (service.isDefined(result.policyValues.deathbenefit.messages)) {
        section.footnotes = result.policyValues.deathbenefit.messages
      }
      sections.push(section)
    }

    if (policyValues.cashSurrender.length > 0) {
      sections.push({
        sectionTitle: 'Cash Surrender Value',
        sectionItems: policyValues.cashSurrender,
      })
    }

    if (policyValues.costBasis) {
      sections.push({
        sectionTitle: 'Cost Basis',
        sectionItems: policyValues.costBasis,
      })
    }

    if (policyValues.loan.length > 0) {
      sections.push({
        sectionTitle: 'Loan',
        sectionItems: policyValues.loan,
      })
    }

    const rows = []
    let currentRow = {
      sections: [],
    }

    for (let i = 0; i < sections.length; i++) {
      currentRow.sections.push(sections[i])

      if (currentRow.sections.length === 2) { //
        rows.push(currentRow)

        currentRow = {
          sections: [],
        }
      } else if (i === sections.length - 1) {
        rows.push(currentRow)
      }
    }

    return $q.resolve(rows)
  }

  service.generateConfirmationNumber = function (transferType, policyId, date) {
    // Example policy id: 'ベーフジラ'
    // Example date: Wed Aug 29 2018 14:38:39 GMT-0400 (Eastern Daylight Time) or new Date(1535567919337)
    const twoDigit = '2-digit'
    const dateOptions = { day: twoDigit, month: twoDigit, year: twoDigit, hour: twoDigit, hour12: false, minute: twoDigit }
    const slashlessDate = date
      .toLocaleDateString('en-US', dateOptions)
      .replace(/\D/g, '')
    // '0829180545'
    const last4DigitsOfPolicyId = policyId.substr(-4)
    // 'ーフジラ'
    const day = slashlessDate.substr(2, 2)
    // '29'
    const secondTwoCharsInMonthName = date
      .toString()
      .substr(5, 2)
      .toUpperCase()
    // 'UG' for August. Would be 'EC' for December, 'AN' for January, and so on.
    const base9EncodedYearHourMinute = Number(slashlessDate.substr(4)).toString(9)

    return `INS${last4DigitsOfPolicyId}${transferType}${day}${secondTwoCharsInMonthName}${base9EncodedYearHourMinute}`
    // Output:
    // INSーフジラFA29UG305787
    // Parts:
    // INS,ーフジラ,FA,29,UG,305787
    // Meanings:
    // "Insight", last-4-of-policy-id, "Future Allocation", day, second-2-of-month-name, base-9-encoded-year-hour-minute
  }

  service.getLoanFromResult = function (result) {
    if (result.error !== '') {
      return $q.reject(result.error)
    }
    if (result.loans && result.loans.length > 0) {
      return $q.resolve(result.loans[0])
    }
    return $q.resolve({})
  }

  service.getSubAccountsFromResult = function (result) {
    if (!result.error) {
      const subAccountsData = result.data.subAccount || []

      if (subAccountsData && subAccountsData.length > 0) {
        return $q.resolve(populateSubAccounts(subAccountsData))
      }
    } else {
      /* If this happens we don't want to display an error to the user because
       * it actually doesn't make sense at all. We can fail silently printing a log
       * to the console and keep the application running.
       */

      return $q.reject(result.error)
    }

    return $q.resolve([])
  }

  service.getAccountsTotalValueFromResult = function (result) {
    if (!result.error) {
      return $q.resolve(result.data.subAccountTotal)
    }
    /* If this happens we don't want to display an error to the user because
     * it actually doesn't make sense at all. We can fail silently printing a log
     * to the console and keep the application running.
     */
    loggingService.log('We couldn\'t load the policy subAccountTotal from the API service: ' + result.error, 'warning')
    return $q.reject(result.error)
  }

  service.reformatApplication = function (policy) {
    return policy
  }

  service.reformatInForcePolicy = function (policy) {
    let advisorNameList

    policy.hoReceiptDate = dateUtils.parseDate(policy.hoReceiptDate)
    policy.submitDate = dateUtils.parseDate(policy.submitDate)
    policy.deathBenefitAmt = typeof policy.grossDeathBenefitAmt === 'undefined' ? policy.netDeathBenefitAmt : policy.grossDeathBenefitAmt
    policy.anniversaryDate = policy.anniversaryDate || clientUtils.getAnniversaryDate(policy.effDate)
    policy.lastUpdateDate = policy.lastUpdateDate ? dateUtils.parseDate(policy.lastUpdateDate) : dateUtils.parseDate(policy.asOfDate)
    policy.type = CONSTANTS.policySource.pending
    policy.requirementInfo = service.calcRequirementsMet(policy.requirementsStatus)
    policy.isAnnuity = policy.lineOfBusiness && policy.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()
    policy.paymentAmountValue = service.getPaymentAmountValue(policy.isAnnuity, policy.faceAmt, policy.initPaymentAmt, policy.paymentAmt)
    policy.policyStatus = utils.firstToUpper(policy.policyStatus)
    policy.owner = clientUtils.parsePartyDetails(policy.owner)
    if (policy.jointOwner) { // annuities don't have multiple owners, they have one plus an optional joint owner [WIT-48]
      policy.owner.isMultiple = true
      policy.owner.fullName = policy.owner.fullName + ', +1'
    }
    policy.payor = clientUtils.parsePartyDetails(policy.payor)
    policy.beneficiary = clientUtils.parsePartyDetails(policy.beneficiary)

    advisorNameList = policy.primaryServicingAgent

    if (advisorNameList) {
      policy.advisorFullName = advisorNameList.length === 1 ? advisorNameList[0] : CONSTANTS.multipleAdvisorNameLabel
    }

    if (policy.isAnnuity) {
      if (policy.annuitants && policy.annuitants.length > 0) {
        policy.insured = clientUtils.parsePartyDetails(policy.annuitants)
        policy.insured.birthDate = dateUtils.parseDate(policy.annuitants[0].birthDate)
      }
    } else if (policy.insureds && policy.insureds.length > 0) {
      policy.insured = clientUtils.parsePartyDetails(policy.insureds)
      policy.insured.birthDate = dateUtils.parseDate(policy.insureds[0].birthDate)
    }

    return policy
  }

  service.reformatPendingPolicy = function (policy) {
    let advisorNameList

    policy.hoReceiptDate = dateUtils.parseDate(policy.hoReceiptDate)
    policy.submitDate = dateUtils.parseDate(policy.submitDate)
    policy.lastUpdateDate = policy.lastUpdateDate ? dateUtils.parseDate(policy.lastUpdateDate) : dateUtils.parseDate(policy.asOfDate)
    policy.type = CONSTANTS.policySource.pending
    policy.requirementInfo = service.calcRequirementsMet(policy.requirementsStatus)
    policy.isAnnuity = policy.lineOfBusiness && policy.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()
    policy.paymentAmountValue = service.getPaymentAmountValue(policy.isAnnuity, policy.faceAmt, policy.initPaymentAmt, policy.paymentAmt)
    policy.policyStatus = utils.firstToUpper(policy.policyStatus)
    policy.owner = clientUtils.parsePartyDetails(policy.owner)
    policy.payor = clientUtils.parsePartyDetails(policy.payor)
    policy.beneficiary = clientUtils.parsePartyDetails(policy.beneficiary)

    advisorNameList = policy.primaryServicingAgent

    if (advisorNameList) {
      policy.advisorFullName = advisorNameList.length === 1 ? advisorNameList[0] : CONSTANTS.multipleAdvisorNameLabel
    }

    if (policy.isAnnuity) {
      if (policy.annuitants && policy.annuitants.length > 0) {
        policy.insured = clientUtils.parsePartyDetails(policy.annuitants)
        policy.insured.birthDate = dateUtils.parseDate(policy.annuitants[0].birthDate)
      }
    } else if (policy.insureds && policy.insureds.length > 0) {
      policy.insured = clientUtils.parsePartyDetails(policy.insureds)
      policy.insured.birthDate = dateUtils.parseDate(policy.insureds[0].birthDate)
    }

    return policy
  }

  service.reduceCoveragesToCommissions = function (coverageArray) {
    function reducer(commissionInformation, coverage) {
      let commissionObj

      if (coverage.targetPremAmt) {
        commissionObj = {
          marketingName: coverage.marketingName,
          targetPremAmt: coverage.targetPremAmt,
          indicatorCode: coverage.indicatorCode,
        }

        commissionInformation.push(commissionObj)
      }
      return commissionInformation
    }
    return coverageArray.reduce(reducer, [])
  }

  function populateSubAccounts(subAccountsData) {
    const subAccounts = subAccountsData.map(subAcc => mapDataToAccount(subAcc))

    return subAccounts
  }

  service.isProductCodeFixedException = (productCode) => {
    return Boolean(fixedExceptionCodes.includes(productCode))
  }

  function mapDataToAccount(data) {
    let account = null

    if (data.productCode.toUpperCase() !== 'LL') {
      account = data

      account.isFutureAllocPct =
        !utils.isNotAvailable(data.subsequentPremiumAllocationTransferPct)
      account.futureAlloc = data.isFutureAllocPct
        ? data.subsequentPremiumAllocationTransferPct
        : data.subsequentPremiumAllocationTransferAmt

      account.aar = data.standardAssetRebalancingTransferPct

      account.isDcaPct = !utils.isNotAvailable(data.standardDollarCostAveragingTransferPct)
      account.dca = data.isDcaPct
        ? data.standardDollarCostAveragingTransferPct
        : data.standardDollarCostAveragingTransferAmt

      account.isFixed = account.assetClass.tc === '1' // string ??
      account.isFixedException = service.isProductCodeFixedException(account.productCode)
      if (account.isFixedException) {
        account.guaranteedRate = account.guaranteed.lifetimeRate
      }
    }

    return account
  }

  service.isNotOfficeRecord = function (role) {
    return role.tc !== CONSTANTS.mappedAgentRoles.office.typeCode
  }

  service.adviserRoleRecord = function (party, role) {
    return {
      roleHolderFullName: party.fullName,
      roleHeld: role,
      contract: party.producer.primaryAgentCode,
      officeName: party.producer.primaryOfficeCodeName,
      advisorySplit: party.producer.interestPercent,
      adviserStatus: party.producer.status === 'Terminated' ? 'Inactive' : party.producer.status,
      officeCode: party.producer.primaryOfficeCode,
      typeCode: role.tc,
    }
  }

  service.compareAdviserRoles = function (roleRecordA, roleRecordB) {
    const roleWeights = CONSTANTS.agentRoleSortWeights

    if (roleWeights[roleRecordA.typeCode] === roleWeights[roleRecordB.typeCode]) {
      return 0
    }
    return roleWeights[roleRecordA.typeCode] < roleWeights[roleRecordB.typeCode] ? -1 : 1
  }

  service.formatAdviserRoleRecord = function (roleRecord) {
    switch (roleRecord.typeCode) {
      case CONSTANTS.mappedAgentRoles.primaryServicingAdviser.typeCode:
        roleRecord.roleHeld.role = CONSTANTS.mappedAgentRoles.primaryServicingAdviser.description
        roleRecord.advisorySplit = CONSTANTS.notApply
        break

      case CONSTANTS.mappedAgentRoles.primaryWritingAdviser.typeCode:
        roleRecord.roleHeld.role = CONSTANTS.mappedAgentRoles.primaryWritingAdviser.description
        break

      case CONSTANTS.mappedAgentRoles.additionalWritingAdviser.typeCode:
        roleRecord.roleHeld.role = CONSTANTS.mappedAgentRoles.additionalWritingAdviser.description
        break
    }

    return roleRecord
  }

  service.organizedAgents = function (agentsResult) {
    const hasRoles = party => Boolean(party && party.roles)
    const collectNonOfficeRoleRecords = (accumulator, party) => {
      const nonOfficeRoleRecords = party.roles
        .filter(service.isNotOfficeRecord)
        .map(role => service.adviserRoleRecord(party, role))

      return accumulator.concat(nonOfficeRoleRecords)
    }

    return agentsResult
      .filter(hasRoles)
      .reduce(collectNonOfficeRoleRecords, [])
      .sort(service.compareAdviserRoles)
      .map(service.formatAdviserRoleRecord)
  }

  service.eppuaAppuaRiders = function (coverage) {
    return coverage.filter(function (rider) {
      rider.termDate = dateUtils.parseDate(rider.termDate)
      if (typeof rider.productCode === 'string') {
        return rider.productCode.includes('EPPUA') ||
          rider.productCode.includes('APPUA') ||
          rider.productCode.includes('EPPJT') ||
          rider.productCode.includes('APPJT')
      }

      return false
    })[0]
  }

  service.eppuaAppuaRiderIsExpired = function (rider) {
    return rider.termDate < new Date()
  }

  service.insured = function (cov) {
    let i, participant

    if (!cov.participant || cov.participant.length === 0) return null

    for (i = 0; i < cov.participant.length - 1; i++) {
      participant = cov.participant[i]

      if (participant.participantRoleCode === CONSTANTS.primaryInsured) {
        return participant
      }
    }

    return cov.participant[0]
  }

  service.coveragesList = function (coverageResult) {
    const coverageColl = []

    coverageResult.map(function (cov) {
      let insured

      if (cov) {
        insured = service.insured(cov)

        if (insured) {
          cov.participantName = insured.participantName
          cov.underwritingClass = insured.underwritingClass
        }

        cov.isRider = ['Rider', 'Integrated', 'NA Basic Coverage', 'Not Basic Coverage'].includes(cov.indicatorCode)
        cov.effDate = dateUtils.parseDate(cov.effDate)
        cov.termDate = dateUtils.parseDate(cov.termDate)
        cov.riderLapsePayDueDate = dateUtils.parseDate(cov.riderLapsePayDueDate)
        cov.marketingName = cov.marketingName ? cov.marketingName : cov.typeCode

        coverageColl.push(cov)

        if (cov.covOption) {
          cov.covOption.forEach(function (covOpt) {
            covOpt.participantName = cov.participantName
            covOpt.marketingName = covOpt.marketingName ? covOpt.marketingName : covOpt.typeCode
            cov.marketingName = cov.marketingName ? cov.marketingName : cov.typeCode
            covOpt.effDate = dateUtils.parseDate(covOpt.effDate)
            covOpt.termDate = dateUtils.parseDate(covOpt.termDate)

            coverageColl.push(covOpt)
          })
        }
      }
    })
    return coverageColl
  }

  service.filterPrimaryAgent = function (parties) {
    if (parties) {
      const primaryAgent = {}

      for (let i = 0; i < parties.length; i++) {
        parties[i].roles.forEach(function (role) {
          if (role.tc === '38') {
            primaryAgent.fullName = parties[i].fullName
            primaryAgent.producer = parties[i].producer
            primaryAgent.phone = service.primaryAgentNumber(parties[i].phone)
            if (parties[i]?.emailAddress?.length) {
              primaryAgent.email = parties[i].emailAddress[0].addrLine
            }
          }
        })

        if (parties[i].address) {
          parties[i].address.forEach(function (address) {
            if (address.tc === '25') {
              primaryAgent.address = address
            }
          })
        } else {
          primaryAgent.address = {}
        }
      }

      return primaryAgent
    }
  }

  service.primaryAgentNumber = function (phoneNumberList) {
    if (phoneNumberList && phoneNumberList[0]) {
      const primaryAgentNumbers = phoneNumberList.filter(num => num.tc === '21')

      return (primaryAgentNumbers[0] && primaryAgentNumbers[0].number) || phoneNumberList[0].number
    }

    return null
  }

  service.showQualPlanType = function (planType, qualifiedCode) {
    return (planType && planType.toUpperCase() !== CONSTANTS.annuityTaxStatuses.NON_QUALIFIED) || (planType && qualifiedCode.toUpperCase() !== CONSTANTS.annuityTaxStatuses.NON_QUALIFIED)
  }

  service.riderDetailResults = function (summaryResult, coveragesResult, clientsResult, baseResult, tableResultLifetime, tableResultStandard) {
    let i, error, cov, results

    for (i = 0; i < arguments.length; i++) {
      error = arguments[0].error

      if (error !== '') {
        return $q.reject(error)
      }
    }

    cov = coveragesResult.data.coverage[0]

    cov.availableStandard = coveragesResult.data.availableStandard
    cov.availableLifetime = coveragesResult.data.availableLifetime

    results = {
      summary: summaryResult.policy,
      coverages: coveragesResult.data.coverage,
      clients: clientsResult.data.parties,
      base: baseResult.policyBase,
      tables: {
        lifetime: tableResultLifetime,
        standard: tableResultStandard,
      },
    }

    return $q.resolve(results)
  }

  service.populateRiderModal = function (results, coveragesResult, ridersHelper) {
    // service.riderModalSectionTitle(results)  this does nothing because all it does is return a string that is not be used.

    // Move the availableStandard and availableLifetime properties
    // to the coverages object.

    results.coverages = results?.coverages?.map((cov) => {
      cov.availableStandard = coveragesResult.data.availableStandard
      cov.availableLifetime = coveragesResult.data.availableLifetime

      return cov
    }) ?? []

    const sections = ridersHelper.populateSections(
      results.summary,
      results.coverages,
      results.clients,
      results.base,
      results.tables
    )

    const modalSections = service.splitFieldsIntoGrid(service.setFilterOnDateFields(sections))

    return modalSections
  }

  service.riderModalSectionTitle = function (results) {
    return results.coverages[0].marketingName
  }

  service.splitFieldsIntoGrid = function (sections) {
    const COLS = 4

    sections.forEach(function (section) {
      const modifiedFields = []

      while (section.fields.length) {
        modifiedFields.push(section.fields.splice(0, COLS))
      }

      section.fields = modifiedFields

      if (section.hasSubSections()) {
        section.subSections = service.splitFieldsIntoGrid(section.subSections)
      }
    })

    return sections
  }

  service.setFilterOnDateFields = function (sections) {
    sections.forEach(function (section) {
      section.fields.forEach(function (field) {
        if (field.value instanceof Date) {
          field.filter = 'date:MM/dd/yyyy'
        }
      })
    })

    return sections
  }

  service.handleRidersError = function (error) {
    console.error('ERROR MESSAGE-----', error.message)
    return error
  }

  service.orderedValues = function (data, orderedMetaData) {
    return Object.keys(data)
      .map(function (id) {
        const valueMetaData = id.indexOf('dbRider') === 0 ? orderedMetaData['dbRider*'] : orderedMetaData[id]

        if (valueMetaData) {
          return {
            description: service.metaDescriptions(data, id, valueMetaData),
            sortOrder: valueMetaData.sortOrder,
            sign: valueMetaData.sign,
            value: service.dataValues(data, id, orderedMetaData),
            highlight: valueMetaData.highlight,
            valueFilter: valueMetaData.valueFilter,
            isTotal: valueMetaData.isTotal,
          }
        }
      })
      .filter(Boolean)
      .sort(function (a, b) {
        return a.sortOrder - b.sortOrder
      })
  }

  service.metaDescriptions = function (data, id, valueMetaData) {
    if (id.indexOf('dbRider') === 0 && Object.keys(data).indexOf(id) !== -1) {
      return data[id].riderName
    }

    return valueMetaData.description
  }

  service.dataValues = function (data, id) {
    if (id.indexOf('dbRider') === 0 && Object.keys(data).indexOf(id) !== -1) {
      return data[id].riderDBAmt
    }

    return data[id]
  }

  service.transactionHistory = function (result) {
    const transactionHistory = angular.copy(result.transactionsHistorySummary)

    angular.forEach(transactionHistory.financialActivity, function (activity) {
      activity.finActivityDate = new Date(activity.finActivityDate)
    })

    transactionHistory.financialActivity = $filter('orderBy')(transactionHistory.financialActivity, 'finEffDate', true)
    transactionHistory.financialActivity = transactionHistory?.financialActivity?.slice(0, 4) || []

    return transactionHistory
  }

  service.getRole = function (client) { // TODO: Naming should not be using get and should be named more specific to beneficiaries
    // const role = client.roles[0].role
    //
    // if (role === 'Beneficiary') {
    //   return 'Primary ' + role
    // }
    // if (role === 'Contingent Beneficiary') {
    //   return 'First Contingent Beneficiary'
    // }
    // return role
    return this.getBeneficiaryRole(client)
  }

  service.getBeneficiaryRole = function (client) { // TODO: Naming should not be using get and should be named more specific to beneficiaries
    const foundRole = client.roles.find(role => ['34', '36', '1012300003'].includes(role.tc))?.role

    if (foundRole === 'Beneficiary') {
      return 'Primary ' + foundRole
    }
    if (foundRole === 'Contingent Beneficiary') {
      return 'First Contingent Beneficiary'
    }
    return foundRole
  }

  service.pidReducer = function (data) {
    return data
      .reduce((acc, policy) => acc.concat(policy.policyClients), [])
      .reduce((acc, policyClient) => {
        acc.push(policyClient.pid)
        return acc
      }, [])
      .reduce((acc, pid) => {
        const found = acc.filter(item => item === pid)[0]

        if (!found) acc.push(pid)

        return acc
      }, [])
  }

  service.deriveCarrierLogo = function (carrierCode) {
    const imageUrl = 'crafter/assets/images/logos'
    const logo = {}

    switch (carrierCode) {
      case 'PNY':
        // carrierName = 'Penn Insurance and Annuity of New York'
        logo.url = `${imageUrl}/${carrierCode}_logo.png`
        logo.alt = 'Penn Insurance and Annuity Company of New York Logo'
        break
      case 'PIA':
        logo.url = `${imageUrl}/${carrierCode}_logo.png`
        logo.alt = 'Penn Insurance and Annuity Logo'
        break
      case 'PML':
        // logo.url = `${imageUrl}/PML_linear_logo_cmyk-color.png`
        logo.url = `${imageUrl}/${carrierCode}_logo.png`
        logo.alt = 'Penn Mutual Logo'
        break
    }
    return logo
  }

  /*
  service.deriveCarrierLogo = (carrierCode) => {
        let carrierName = null
        const imageUrl = 'images/'
        let logo = {}

        if (!policy.carrierName) {
          switch (policy.carrierCode) {
            case 'PNY':
              // carrierName = 'Penn Insurance and Annuity of New York'
              logo.url = `${imageUrl}/PML_linear_logo_cmyk-color.png`
              logo.alt = 'Penn Mutual Logo'
              break
            case 'PIA':
              logo.url = `${imageUrl}/PIA_logo.jpg`
              logo.alt = 'Penn Insurance and Annuity Logo'
              break
            case 'PML' :
              // logo.url = `${imageUrl}/PML_linear_logo_cmyk-color.png`
              logo.url = `${imageUrl}/${carrierCode}_logo.png`
              logo.alt = 'Penn Mutual Logo'
              break
            default: carrierName = 'Bobs Appliances'
          }
        }
        carrierName = policy.carrierName

        return carrierName
      }
      */

  service.loanIsPayable = function (data) {
    let loan

    if (data && data.paymentOptions && data.paymentOptions.length) {
      loan = data.paymentOptions.find(option => option.paymentTypeCode === '2')
    }

    return loan && loan.isPayable
  }

  return service
}
