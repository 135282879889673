export function focusMe($timeout) {
  const directive = {
    restrict: 'A',
    scope: {
      trigger: '=focusMe',
    },
    link: focusMeLink,
  }

  return directive

  function focusMeLink(scope, element, attrs) {
    scope.$watch('trigger', function(val) {
      $timeout(function() {
        if (val === true) {
          element[0].select()
          scope.trigger = false
        }
      }, 20)
    })
  }
}
