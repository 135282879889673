
/* @ngInject */
export class ReplyableRequirementsRunner {
  static $inject = ['routerHelper']

  constructor (routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates () {
    return [{
      state: 'replyable-requirements',
      config: {
        url: '/replyable-requirements',
        templateUrl: 'app/replyable-requirements/tmpl/main-view.html',
        data: {
          // addToNavigation: true,
          secure: true,
          module: 'ReplyableRequirements',
        },
        controller: 'replyableRequirementsController',
        controllerAs: 'requirementsCtrl',
        onEnter: ['$rootScope','CONSTANTS', function ($rootScope, CONSTANTS) {
          $rootScope.pageTitle = CONSTANTS.modules.ReplyableRequirements.name
          $rootScope.mobileHeaderTitle = CONSTANTS.modules.ReplyableRequirements.name
          // $rootScope.hideBarHeader = false;
          $rootScope.state = CONSTANTS.modules.ReplyableRequirements.id
        }],
      },
    }]
  }
}
