const BUSINESS_UNITS = {
  PML: 'PML',
  PIA: 'PIA',
  PNY: 'PNY',
  PML_NY: 'PML-NY',
}
const ADDRESS_FRAGMENTS = {
  'PML': {
    service: `Penn Mutual Life Insurance Company<br/>
PO Box 178<br/>
Philadelphia, PA 19105-0178`,
    overnight: `Penn Mutual Life Insurance Company<br/>
Lockbox #7460<br/>
525 Fellowship Road Suite 330<br/>
Mt Laurel, NJ 08054-3415`,
  },
  'PML-NY': {
    service: `Penn Mutual Life Insurance Company<br/>
PO Box 170<br/>
Philadelphia, PA 19105-0170`,
    overnight: `Penn Mutual Life Insurance Company<br/>
Lockbox #825638<br/>
525 Fellowship Road Suite 330<br/>
Mt Laurel, NJ 08054-3415`,
  },
  'PNY': {
    service: `Penn Insurance and Annuity Company of New York<br/>
PO Box 50001<br/>
Woburn, MA  01815`,
    overnight: `Penn Insurance and Annuity Company of New York<br/>
Lockbox #844819<br/>
20 Commerce Way Suite 800<br/>
Woburn MA 01801-1057`,
  },
}
const MESSAGES = {
  REGULAR_MAIL: '',
  PML_NY_MAIL: 'For policies issued in New York',
  PML_NY_ONLY_1: 'For policies issued by the Penn Mutual Life Insurance Company',
  PML_NY_ONLY_2: 'For policies issued by the Penn Insurance and Annuity Company of New York',
  PML_ALL_1: 'For policies issued by the Penn Mutual Life Insurance Company (excluding policies issued in New York)',
  PML_ALL_2: 'For policies issued in New York by the Penn Mutual Life Insurance Company',
}
const ADDRESS_MESSAGE_MAP = [
  // When only one address
  { addressTypes: [BUSINESS_UNITS.PML], messages: { [BUSINESS_UNITS.PML]: MESSAGES.REGULAR_MAIL } },
  { addressTypes: [BUSINESS_UNITS.PML_NY], messages: { [BUSINESS_UNITS.PML_NY]: MESSAGES.REGULAR_MAIL } },
  { addressTypes: [BUSINESS_UNITS.PNY], messages: { [BUSINESS_UNITS.PNY]: MESSAGES.REGULAR_MAIL } },

  // When two addresses
  {
    addressTypes: [BUSINESS_UNITS.PML, BUSINESS_UNITS.PML_NY],
    messages: {
      [BUSINESS_UNITS.PML]: MESSAGES.REGULAR_MAIL,
      [BUSINESS_UNITS.PML_NY]: MESSAGES.PML_NY_MAIL,
    },
  },
  {
    addressTypes: [BUSINESS_UNITS.PML, BUSINESS_UNITS.PNY],
    messages: {
      [BUSINESS_UNITS.PML]: MESSAGES.REGULAR_MAIL,
      [BUSINESS_UNITS.PNY]: MESSAGES.PML_NY_MAIL,
    },
  },
  {
    addressTypes: [BUSINESS_UNITS.PML_NY, BUSINESS_UNITS.PNY],
    messages: {
      [BUSINESS_UNITS.PML_NY]: MESSAGES.PML_NY_ONLY_1,
      [BUSINESS_UNITS.PNY]: MESSAGES.PML_NY_ONLY_2,
    },
  },

  // When three addresses
  {
    addressTypes: [BUSINESS_UNITS.PML, BUSINESS_UNITS.PML_NY, BUSINESS_UNITS.PNY],
    messages: {
      [BUSINESS_UNITS.PML]: MESSAGES.PML_ALL_1,
      [BUSINESS_UNITS.PML_NY]: MESSAGES.PML_ALL_2,
      [BUSINESS_UNITS.PNY]: MESSAGES.PML_NY_ONLY_2,
    },
  },
]

export {ADDRESS_FRAGMENTS, ADDRESS_MESSAGE_MAP, BUSINESS_UNITS, MESSAGES}
