export {valueCheckers}
valueCheckers.$inject = []

function valueCheckers() {
  const self = this

  self.isInAllowedValues = function(allowedValues, modelValue) {
    const floatModelValue = parseFloat(modelValue, 10)
    let i, range

    if (!isNaN(floatModelValue)) {
      if (allowedValues.ranges) {
        for (i = 0; i < allowedValues.ranges.length; i++) {
          range = allowedValues.ranges[i]
          if (floatModelValue <= range.maximum && floatModelValue >= range.minimum) {
            return true
          }
        }
      }
      if (allowedValues.numbers && allowedValues.numbers.indexOf(floatModelValue) !== -1) {
        return true
      }
    } else if (allowedValues.blank && (modelValue === null || modelValue === undefined || modelValue === '')) {
      return true
    }

    return false
  }

  return self
}
