(function() {
  'use strict'

  angular
    .module('app.directives')
    .directive('wgSwipeOption', wgSwipeOption)

  wgSwipeOption.$inject = []

  function wgSwipeOption() {
    return {
      restrict: 'E',
      require: '^^wgSwipeContainer',
      link: wgSwipeOptionLinker,
    }

    function wgSwipeOptionLinker(scope, elem, attrs, ctrl) {
      ctrl.optionWidth = 104
      elem.attr('style', 'right: ' + (ctrl.numOptions * ctrl.optionWidth) + 'px')

      elem.addClass(attrs.color)

      ctrl.numOptions += 1
    }
  }
})()
