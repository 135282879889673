angular.module('app.policy')
  .constant('VIEW_REPLY_ALLOWED_TYPES', [
    { name: 'Agent Reply Required - UW Review', text: true, upload: true, nonHOUser: true, hoRoles: ['AGENT', 'FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT'] },
    { name: 'Agent Reply Required - CA Review', text: true, upload: true, nonHOUser: true, hoRoles: ['AGENT', 'FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT'] },
    { name: 'Issue Instructions', text: true, upload: true, nonHOUser: true, hoRoles: ['AGENT', 'FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT'] },
    { name: 'Submitted Illustration', text: false, upload: true, nonHOUser: true, hoRoles: ['AGENT', 'FIELD MGMT', 'FOFSUPPORT', 'DSTSUPPORT'] },
  ])

  .constant('REQUIREMENT_NO_DATA_MESSAGES', {
    agentNoData: {
      message: 'You do not have any requirements that can be replied to online. <span class="text-danger">Please be sure to check individual cases for additional requirements.',
    },
    homeOffice: {
      message: 'Sorry, there are no requirements that can be replied to online for the individual. Please check your “View As” setting to confirm that you have selected the proper individual, and please be sure to check individual cases for additional requirements.',
    },
  })

  .constant('REPLIABLE_ENDPOINT', '/proxy/core-services-gateway/agent/requirements/repliable')

  // @deprecated
  .constant('UPLOAD_REPLY_ALLOWED_TYPES', [
    'Submitted Illustration',
    'Issue Instructions',
  ])
