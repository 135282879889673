import { PhoneNumber } from '../../client/beneficiaries/classes/phone'
import { PhoneList } from '../../client/beneficiaries/classes/phone-list'

class PhoneListController {
  static $inject = ['$scope']

  numbers: PhoneList
  isReadOnlyMode: boolean
  isRequired: boolean
  showCountries: boolean
  showLabel: boolean
  showDeleteButton: boolean
  primaryPhone: PhoneNumber
  // onRemove: Function
  // onAddPhoneNumber: Function
  listLabel: String

  constructor(private $scope: any) { }

  remove(targetPhone: PhoneNumber): void {
    const phoneInput = this.$scope.phoneList['phone_' + String(targetPhone.index)]

    this.numbers.remove(targetPhone)
    phoneInput.$setDirty()

  }

  undelete(targetPhone: PhoneNumber): void {
    const phoneInput = this.$scope.phoneList['phone_' + String(targetPhone.index)]

    targetPhone.undelete()
    phoneInput.$setPristine()

    const tmp = this.$scope.phoneList.$$controls.find((t) => t.$dirty)

    if (!tmp) this.$scope.phoneList.$setPristine()

    const tmp2 = this.$scope.phoneList.$$parentForm.$$controls.find((c) => c.$dirty)

    if (!tmp2) this.$scope.phoneList.$$parentForm.$setPristine()
  }

  // $onInit() {}

  // $doCheck() {
  //   if (this.numbers?.sorted && !this.primaryPhone) this.primaryPhone = this.numbers.sorted[0]
  // }
}

export const phoneListComponent = {
    templateUrl: 'app/components/phone-list/phone-list.html',
    controller: PhoneListController,
    controllerAs: 'phoneListCtrl',
    bindings: {
      numbers: '<',
      isReadOnlyMode: '<',
      isRequired: '<',
      showCountries: '<',
      showLabel: '<',
      showDeleteButton: '<',
      listLabel: '<',
      // onRemove: '&',
      // onAddPhoneNumber: '&'
    },
  }
