import { IController } from "angular"

class ClientBeneficiaryChangeAgreementController implements IController {
  static $inject: any = []

  viewState: any
  onRevertAll: Function
  onSubmit: Function
  termsAccepted: boolean = false
  hasInvalidData: boolean

  // constructor(private $stateParams) { }

  get disableSubmitButton(): boolean {
    return this.hasInvalidData || (!this.termsAccepted && !this.hasInvalidData)
  }
}

export const clientBeneChangeAgreementComponentConfig = {
  templateUrl: 'app/client/client-bene-edit/bene-change-agreement/bene-change-agreement.html',
  controller: ClientBeneficiaryChangeAgreementController,
  controllerAs: 'changeAgreementCtrl',

  bindings: {
    viewState: '<',
    onRevertAll: '&',
    onSubmit: '&',
    hasInvalidData: '<',
  },
}
