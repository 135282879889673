import { IController } from "angular"
import { CompaniesService } from "../companies/companies-service"
import { ILobSection } from "../companies/types"

// sources/client/app/download-forms/download-forms-view.html
class PolicyFormsController implements IController{
  static $inject = ['companiesService']

  constructor(private companiesService: CompaniesService) { }

  section: ILobSection
  modal: any
  policy: any
  resolve: any

  $onInit(): void {
    // console.log('sections', this.sections)
    this.modal = this.resolve.modalCtrl.modalInstance
    this.policy = this.resolve.modalData.policy

    this.companiesService.getPolicyForms(this.policy)
      .then((section: any) => this.section = section)
  }
}

const PolicyForms = {
  templateUrl: 'app/components/policy-forms/policy-forms.html',
  controller: PolicyFormsController,
  controllerAs: 'policyFormsCtrl',
  bindings: {
    resolve: '<',
  },
  // resolve: {
  //   sections: ['companiesService', (companiesService: CompaniesService) => {

  //     return companiesService.getPolicyForms({})
  //   }],
  // },
}

export { PolicyForms }
