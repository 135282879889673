export class NbaUploadRunner {
  static $inject = ['routerHelper']

  constructor(routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)

  }

  getStates() {
    return [{
      state: 'nba-upload',
      config: {
        url: '/nba-upload',
        component: 'nbaUpload',
        data: {
          // addToNavigation: true,
          secure: true,
          module: 'NbaUpload',
        },
        onEnter: ['$rootScope', 'CONSTANTS', function ($rootScope, CONSTANTS) {
          $rootScope.pageTitle = CONSTANTS.modules.NbaUpload.name
          $rootScope.mobileHeaderTitle = CONSTANTS.modules.NbaUpload.name
          // $rootScope.hideBarHeader = false;
          $rootScope.state = CONSTANTS.modules.NbaUpload.id
        }],
      },
    }]
  }
}
