partyService.$inject = ['$http', 'utils', 'CONSTANTS']

  /* @ngInject */
function partyService($http, utils, CONSTANTS) {
  let activeAgent = null
  const service = {}

  service.syncPartyHistory = function(history) {
    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.recentpartiesUrl : 'app/mock-api/recentparties.json'

    return $http.post(url, history)
  }

  service.getPartyHistory = function() {
    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.recentpartiesUrl : 'app/mock-api/recentparties.json'

    return $http.get(url)
  }

  service.setActiveAgent = function(agent) {
    activeAgent = agent
  }

  service.getActiveAgent = () => (activeAgent)

  service.getAgentKey = function() {
    activeAgent = activeAgent || {}
    activeAgent.key = activeAgent.key || ''
    return activeAgent.key
  }

  service.refreshByKey = function(agent) {
    const key = agent.refreshKey || ''

    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'agent': {},
    }

    const url = CONSTANTS.isDataServiceActive ? CONSTANTS.partyByRefreshKey.replace('{0}', key)
      : 'app/mock-api/party-by-refresh.json'

    const promise = $http.get(url).then(
      function(httpData) {
        if (!httpData.data) {
            // There is no Data
          result.error = 'Undefined response'
          return result
        }
        if (!httpData.data.numFound || httpData.data.numFound === 0) {
            // There are no Results
          return result
        }

        result.agent = httpData.data.partyList[0]

        return result
      },
      function(httpData) {
        utils.fillAndLogError(httpData, result)
        return {
          agent: agent,
        }
      }
    )

    return promise
  }

  service.getBy = function(partyQuery, pageNumber) {
    let result, url, promise

    pageNumber = pageNumber || 1

    result = {
      'error': '',
      'status': '',
      'statusText': '',
      'agents': [],
    }

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.partiesByNameOrOfficeCode.replace('{0}', partyQuery).replace('{1}', pageNumber) : 'app/mock-api/client-info.json'

    promise = $http.get(url).then(
      function(httpData) {
        if (!httpData.data) {
            // There is no Data
          result.error = 'Undefined response'
          return result
        }
        if (!httpData.data.numFound || httpData.data.numFound === 0) {
            // There are no Results
          return result
        }

        result.agents = httpData.data.partyList

        return result
      },
      function(httpData) {
        utils.fillAndLogError(httpData, result)
        return result
      }
    )

    return promise
  }

  return service
}
export {partyService}
