import * as angular from 'angular'

export class AppSwitcherService {
  static $injector: any = ['$http', '$q']

  constructor(private $http: angular.IHttpService, private $q: angular.IQService) {}

  _appSwitcherLinks: any[] | null = null

  getContent() {
    if (this._appSwitcherLinks === null) {
      return this.$http.get<any>('/insight-content/app-switcher-links.json').then((httpData) => {
        this._appSwitcherLinks = httpData.data
        return this._appSwitcherLinks
      })
      .catch((httpData) => {
        console.log('Error retrieving data from server :', httpData)
        return null
      })
    } else {
      return this.$q.resolve(this._appSwitcherLinks)
    }
  }
}
