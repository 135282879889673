/**
 * Renders the <alert-indicator />
 */

export class PolicySummaryRpsDirective implements ng.IDirective {

  restrict = 'E'
  templateUrl = 'app/policy/advisor/policy-summary-rps.html'
  replace = false
  scope = {
    summaryCtrl: '=',
  }

  constructor(private policyService, private $stateParams, private CONSTANTS) { }

  _getMyAgents() {
    return this.policyService.getPolicyAgents(this.$stateParams.id)
  }

  /**
   * AngularJS post link function use for initial configuration of instances of PendingStatementJobs
   */
  link(scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) {
    scope.summary = scope.summaryCtrl.summary

    this._getMyAgents().then(results => {
      const agentResults = results && results.data.parties ? results.data.parties : {}

      scope.primaryAgent = results && results.data.parties ? results.data.parties[0] : {}
      scope.primaryRoleNames = []
      scope.servicingRoleNames = []
      scope.additionalRoleNames = []

      if (agentResults) {
        agentResults.forEach(agent => {
          agent.roles.forEach(item => {
            if (item.role) {
              if (item.role.toLowerCase() === this.CONSTANTS.pendingPolicyAgentRoles.primary) {
                scope.primaryRoleNames.push(agent.fullName)
              }
              // } else if (item.role.toLowerCase() === this.CONSTANTS.pendingPolicyAgentRoles.servicing) {
              //   scope.servicingRoleNames.push(agent.fullName)
              // } else if (item.role.toLowerCase() === this.CONSTANTS.pendingPolicyAgentRoles.additional) {
              //   scope.additionalRoleNames.push(agent.fullName)
              // }
            }
          })
        })
      }
    })
  }

  /**
   * Creates an instance of PolicySummaryRpsDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = (policyService, $stateParams, CONSTANTS) => new PolicySummaryRpsDirective(policyService, $stateParams, CONSTANTS)

    directive.$inject = ['policyService', '$stateParams', 'CONSTANTS']

    return directive
  }
}
