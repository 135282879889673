/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPromise } from 'angular'
import { IUserSettingPayload, NotificationChannel, NotificationSettings } from './notification-settings'
import { EDELIVERY_TYPES, USER_SETTINGS_URLS } from './constants'
import { IDelegator, RelationsResponse, IUserSettingResponse, IEDeliveryResult, IEDeliveryOption } from './types'

//  "userid": "emack",
//             "firstName": "Elizabeth",
//             "lastName": "Mack",
//             "email": "testfieldemail@pennmutual.com",
//             "href": "https://iam-persona-mo.pennmutual.com/persona-service/user/userid/emack?ctx=porch"
export class UserSettingsService {

  static $inject: string[] = ['$http', '$q']

  private eDeliveryTypes: any[]

  constructor(private $http: angular.IHttpService, private $q: angular.IQService) {
    this.eDeliveryTypes = Object.entries(EDELIVERY_TYPES)
  }

  /** Notification Settings */
  /**
   * Returns a list of FP's that delegate task to the give userId
   *
   * @returns IPromise<IDelegator[]>
   */
  public getDelegators(userId: string): IPromise<IDelegator[]> {
    const url = USER_SETTINGS_URLS.DELEGATORS + `/${userId}`

    return this.$http.get<RelationsResponse>(url)
      .then(relationsResponse => relationsResponse.data.delegators)
      .then((delegators: IDelegator[]): IDelegator[] => delegators.map((delegator: IDelegator): IDelegator => {
        delegator.fullName = delegator.firstName + ' ' + delegator.lastName

        return delegator
      }))
      .then((delegators: IDelegator[]): IDelegator[] => delegators.sort((a: IDelegator, b: IDelegator): number => {
        // if (!a.fullName || !b.fullName) return 0 // this makes typescript happy.
        if (a.fullName < b.fullName) return -1
        if (a.fullName === b.fullName) return 0

        return 1
      }))

  }

  /**
   * getSettings
   * -----------
   *
   * Returns any existing notification settings for the supplied userId.
   *
   * If the userId is not supplied, the current user's userId is resolved from
   * session data on the server side.
   *
   * When called with a userID, it is assume that is a delegator call.
   *
   * @param userId: string | undefined
   * @returns IPromise<unknown | IUserSettingResponse>
   */
  public getSettings(delegatorId?: string): IPromise<IUserSettingResponse> {
    let url = USER_SETTINGS_URLS.NOTIFICATIONS

    if (delegatorId) {
      url += `/${delegatorId}`
    }

    return this.$http.get(url)
      .then((userSettingResponse: any): IUserSettingResponse => userSettingResponse.data as IUserSettingResponse)
      .catch((err) => err)
  }

  /**
   * saveNotificationSetting
   * -----------------------
   *
   * @param type
   * @param propName
   * @param value
   * @returns IPromise<IUserSettingResponse>
   */
  public saveNotificationSetting(settings: NotificationSettings, setting: NotificationChannel, userId?: string): IPromise<IUserSettingResponse> {
    const url = userId ? USER_SETTINGS_URLS.NOTIFICATIONS + `/${userId}` : USER_SETTINGS_URLS.NOTIFICATIONS
    const payload = settings.getPayload(setting)

    return this.$http.post(url, payload)
      .then((resp) => resp.data as IUserSettingResponse)
      .catch(err => err.data)
  }

  public updateNotificationSettings(settings: NotificationSettings, userId?: string): IPromise<IUserSettingResponse> {
    const url = userId ? USER_SETTINGS_URLS.NOTIFICATIONS + `/${userId}` : USER_SETTINGS_URLS.NOTIFICATIONS
    const payload: IUserSettingPayload[] = settings.getUpdatePayload()

    return this.$http.put(url, payload)
      .then((resp) => resp.data as IUserSettingResponse[])
      .catch(err => err.data)
  }

  /**
   *
   * @param type
   * @param propName
   * @param value
   * @returns IPromise<IUserSettingResponse>
   */
  public deleteNotificationSetting(settings: NotificationSettings, setting: NotificationChannel, userId?: string): IPromise<IUserSettingResponse> {
    const payload = settings.getPayload(setting)
    let url = USER_SETTINGS_URLS.NOTIFICATIONS

    url += `/${payload.frequency}/${payload.channel}`
    url += userId ? `/${userId}` : ''

    return this.$http.delete(url)
      .then((resp) => resp.data as IUserSettingResponse)
      .catch(err => err.data)
  }

  /** eDelivery Settings */

  /**
   * Returns a user e-delivery settings.
   *
   * @param uid?: String
   * @returns
   */
  public getDeliverySettings(optionIds: string, uid?: string): IPromise<IEDeliveryResult> {
    let url: string = USER_SETTINGS_URLS.E_DELIVERY + '?options=' + optionIds

    if(uid) url += `&uid=${uid}`

    return this.$http.get(url)
      .then((eDeliveryResponse: any): IEDeliveryResult => eDeliveryResponse.data.settings)
      .catch((err) => err)
  }

  public buildDeliveryPayload(deliverySettings: IEDeliveryOption[], originalSettings: IEDeliveryResult): any[] {
    const payload: any = deliverySettings.reduce((acc, option: IEDeliveryOption) => {
      // const serverKey: string = this.eDeliveryTypes.find((type) => type[1] === option.identifier)?.[0]
      const optionChanged: boolean = originalSettings[option.identifier] !== option.eDelivery

      if (optionChanged) acc.push({ type: option.userSettingID, optIn: option.eDelivery })

      return acc
    }, [])

    // console.log('buildDeliveryPayload:', deliverySettings, originalSettings, payload)

    return payload
  }

  public saveDeliverySettings(payload: any): IPromise<any> {
    const url: string = USER_SETTINGS_URLS.E_DELIVERY

    return this.$http.post(url, payload)
  }
}

// https://test.pennmutual.com/sso-engine/sso/prodplace?profile=Y&RelayState=http%3A%2F%2Fdinsight102.pennmutual.com%3A3001%2F%23%2Fmyclients%2Fsearch%2Fresult%2F&SAMLRequest=PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KICAgICAgPHNhbWxwOkF1dGhuUmVxdWVzdAogICAgICAgICAgeG1sbnM6c2FtbHA9InVybjpvYXNpczpuYW1lczp0YzpTQU1MOjIuMDpwcm90b2NvbCIKICAgICAgICAgIElEPSI0IgogICAgICAgICAgVmVyc2lvbj0iMi4wIgogICAgICAgICAgSXNzdWVJbnN0YW50PSIyMDIyLTEwLTE5VDE0OjM5OjU0Ljc3OVoiCiAgICAgICAgICBQcm90b2NvbEJpbmRpbmc9InVybjpvYXNpczpuYW1lcy50YzpTQU1MOjIuMDpiaW5kaW5nczpIVFRQLVJlZGlyZWN0IgogICAgICAgICAgUHJvdmlkZXJOYW1lPSJwZW5ubXV0dWFsLmNvbS9wcm9kcGxhY2UiCiAgICAgICAgICBBc3NlcnRpb25Db25zdW1lclNlcnZpY2VVUkw9IiIvPg==