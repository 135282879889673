export {ConfigServiceProvider}
function ConfigServiceProvider() {
  let config

  this.init = function(data) {
    if (data instanceof Object) {
      config = data

      if (typeof config.features === 'object') {
        Object.keys(config.features).forEach(feature => {
          const featureState = config.features[feature] ? 'on' : 'off'

          console.warn(`Feature "${feature}" is ${featureState}`)
        })
      }
    } else {
      try {
        config = JSON.parse(data)
        console.log('Configuration from server: ', JSON.stringify(config))
      } catch (e) {
        console.error('Failed to parse config in configService provider')
      }
    }
  }

  this.$get = function() {
    return config
  }
}
