export function getCompensationStates() {
  return [{
    state: 'compensation',
    config: {
      url: '/mybusiness/compensation',
      templateUrl: 'app/compensation/compensation-view.html',
      controller: 'CompensationController',
      controllerAs: 'compensationCtrl',
      data: {
          // addToNavigation: true,
        secure: true,
        module: 'mybusiness',
      },
      resolve: {
        compensationResult: ['compensationService',
          function(compensationService) {
            return compensationService.getMostRecentCompensations()
          },
        ],
      },
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.pageTitle = 'Compensation Statements'
        $rootScope.mobileHeaderTitle = 'Compensation Statements'
          // $rootScope.hideBarHeader = false;
        $rootScope.state = 'compensation'
      }],
    },
  }]
}
