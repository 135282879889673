
/* @ngInject */
export class ClientListRunner {
  static $inject = ['CONSTANTS', 'routerHelper']

  constructor(private CONSTANTS, routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates() {
    return [{
      state: 'client.list',
      config: {
        url: '/client-list',
        templateUrl: 'app/client/list/tmpl/main-view.html',
        // controller: 'CorrespondenceController',
        // controllerAs: 'correspondenceCtrl',
        data: {
          secure: true,
          module: this.CONSTANTS.modules.clientlist.id,
        },
        resolve: {
        },
        onEnter: ['$rootScope', ($rootScope) => {
          $rootScope.state = this.CONSTANTS.modules.clientlist.id
          $rootScope.pageTitle = this.CONSTANTS.modules.clientlist.name
          $rootScope.isPopupVisible = false
        }],
        // onExit: ['$rootScope', function ($rootScope) {
        //   // setClientStatusTitle($rootScope)
        // }],
      },
    }]
  }
}
