export {WgWhatsNewController, wgWhatsNew}
WgWhatsNewController.$inject = ['$scope', '$window', 'CONSTANTS', 'popupManager', 'deviceUtils', 'whatsNewService', 'userDataService', 'configService']

function WgWhatsNewController($scope, $window, CONSTANTS, popupManager, deviceUtils, whatsNewService, userDataService, configService) {
  const self = this

  self.content = 'Loading...'
  self.unread = false
  self.isOpen = false

  self.onToggle = function(isOpen) {
    if (isOpen) {
      self.isOpen = true
    }

    if (isOpen && self.unread) {
      userDataService.getDataPromise()
          .then(function(data) {
            if (!data) {
              data = {}
            }
            data.whatsNewCode = configService.whatsNewCode
            self.unread = false
            userDataService.updateDataPromise(data)
          })
    }
  }

  self.close = function() {
    if (self.isOpen) {
      self.isOpen = false
    }
  }

  self.$onInit = function() {
    // whatsNewService.getContentPromise()
    //     .then(function(content) {
    //       self.content = content
    //     })
    self.content = configService.whatsNew

    userDataService.getDataPromise()
        .then(function(data) {
          if (configService.whatsNewCode !== data.whatsNewCode) {
            self.unread = true
          }
          return data
        })
  }
}

function wgWhatsNew() {
  return {
    restrict: 'EA',
    templateUrl: 'app/widgets/wg-whats-new/wg-whats-new.html',
    scope: {},
    bindToController: true,
    controller: WgWhatsNewController,
    controllerAs: 'ctrl',
  }
}
