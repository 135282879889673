userAccountService.$inject = ['$rootScope', '$http', 'utils', 'clientUtils', 'policyUtils', 'CONSTANTS']

  /* @ngInject */
function userAccountService($rootScope, $http, utils, clientUtils, policyUtils, CONSTANTS) {
  const service = {
    account: $rootScope.auth.isClientView() ? {} : initUserAccount(),
    fakeBirthday: false,
  }

  function initUserAccount(account) {
    if (account && account.username && (
      (account.favoritePolices && account.favoriteClients) ||
          ($rootScope.auth.isClientView())
    )) {
      return account
    } else if ($rootScope.auth.isAgentView()) {
      return {
        favoritePolices: [],
        favoriteClients: [],
      }
    }
    throw new Error('Invalid user account')
  }

  function getFavoritePolicyIndex(pKey) {
    let favoritePolicies, currentPKey, i

    if (pKey && service.account && service.account.favoritePolices) {
      favoritePolicies = service.account.favoritePolices

      for (i = 0; i < favoritePolicies.length; i++) {
        currentPKey = getPolicyKey(favoritePolicies[i].policy)

        if (currentPKey === pKey) {
          return i
        }
      }
    }

    return -1
  }

  function getFavoriteClientIndex(uid) {
    let favoriteClients, i

    if (uid && service.account && service.account.favoriteClients) {
      favoriteClients = service.account.favoriteClients

      for (i = 0; i < favoriteClients.length; i++) {
        if (favoriteClients[i].uid === uid) {
          return i
        }
      }
    }

    return -1
  }

  function getPolicyKey(policy) {
    const pid = policy.pid ? policy.pid : policy.polNumber
    const source = policy.source ? policy.source : policy.type

    return source + ':' + pid
  }

  service.isFavoritePolicy = function(policy) {
    const pKey = getPolicyKey(policy)

    return getFavoritePolicyIndex(pKey) !== -1
  }

  service.isFavoriteClient = function(client) {
    return getFavoriteClientIndex(client.uid) !== -1
  }

  service.getFavoritesPolicies = function(source) {
    switch (source) {
      case 'policies':
        return service.account.favoritePolices
      case 'people':
        return service.account.favoriteClients
      default:
        return service.account.favoritePolices.concat(service.account.favoriteClients)
    }
  }

  function getInsured(policy) {
    if (!policy) return

    if (policy.insured) {
      return policy.insured
    }
    return policy.annuitants ? clientUtils.parsePartyDetails(policy.annuitants) : clientUtils.parsePartyDetails(policy.insureds)
  }

  function getOwner(policy) {
    if (!policy) return

    if (policy.owner) {
      return policy.owner
    }
    return policy.owners && policy.owners.length > 0 ? clientUtils.parsePartyDetails(policy.owners) : ''
  }

  function getPayor(policy) {
    if (!policy) return

    if (policy.payor) {
      return policy.payor
    }
    return policy.payors && policy.payors.length > 0 ? clientUtils.parsePartyDetails(policy.payors) : ''
  }

  function getBeneficiary(policy) {
    if (!policy) return

    if (policy.beneficiary) {
      return policy.beneficiary
    }
    return policy.beneficiaries && policy.beneficiaries.length > 0 ? clientUtils.parsePartyDetails(policy.beneficiaries) : ''
  }

  function createPolicyItem(policy) {
    const isPending = policyUtils.isPendingPolicy(policy)
    const advisorNameList = policy.producers ? policy.producers : []

    const policyRV = {
      'pid': policy.polNumber,
      'aceIndicator': policy.aceIndicator,
      'source': policy.source,
      'polNumber': policy.polNumber,
      'marketingName': policy.marketingName,
      'insured': getInsured(policy),
      'policyStatus': policy.policyStatus,
      'isAnnuity': policy.isAnnuity,
      'advisorFullName': advisorNameList.length === 1 ? advisorNameList[0] : 'Multiple',
      'owner': getOwner(policy),
      'payor': getPayor(policy),
      'beneficiary': getBeneficiary(policy),
      'faceAmt': isPending ? policy.faceAmt : '',
      'paymentAmountValue': isPending ? policy.paymentAmountValue : '',
      'hoReceiptDate': isPending ? policy.hoReceiptDate : '',
      'submitDate': isPending ? policy.submitDate : '',
      'lastUpdateDate': isPending ? policy.lastUpdateDate : '',
      'requirementInfo': isPending ? policy.requirementInfo : '',
      'deathBenefitAmt': isPending ? '' : policy.deathBenefitAmt,
      'anniversaryDate': isPending ? '' : policy.anniversaryDate,
      'paymentDueDate': isPending ? '' : policy.paymentDueDate,
      'lastUnschedPremDate': isPending ? '' : policy.lastUnschedPremDate,
      'specifiedAmountLabel': policy.specifiedAmountLabel,
      'isULProductType': isPending ? '' : policy.isULProductType,
      'nyIssued': policy.issueState === 'NY' || policy.issueState === 'New York',
      'issueState': policy.issueState,
    }

    return policyRV
  }

  service.toggleFavoritePolicy = function(policy) {
    let pKey, index

    if (!policy.polNumber) {
      return
    }

    pKey = getPolicyKey(policy)
    index = getFavoritePolicyIndex(pKey)

    if (index === -1) {
      service.account.favoritePolices.push({
        'pid': policy.polNumber,
        'policy': createPolicyItem(policy),
      })
    } else if (index > -1) {
      service.account.favoritePolices.splice(index, 1)
    }

    saveAccountAndRefresh()
  }

  service.toggleFavoriteClient = function(client) {
    let index

    if (!client.uid) {
      return
    }

    index = getFavoriteClientIndex(client.uid)

    if (index === -1) {
      service.account.favoriteClients.push({
        'uid': client.uid,
        'client': {
          'uid': client.uid,
          'id': client.id ? client.id : client.clientID,
          'fullName': client.fullName,
          'calculatedAge': client.calculatedAge,
          'age': client.age,
          'birthDate': client.birthDate,
          'phone': client.phone,
          'gender': client.gender,
          'address': angular.isString(client.address) ? client.address : client.htmlAddress,
          'isAnOrganization': client.isAnOrganization,
        },
      })
    } else if (index > -1) {
      service.account.favoriteClients.splice(index, 1)
    }

    saveAccountAndRefresh()
  }

  function updateAccount() {
    let url

    if ($rootScope.auth.isClientView()) {
      url = CONSTANTS.isDataServiceActive ? CONSTANTS.appClientUserAccountURL : 'app/mock-api/user-account.json'
    } else {
      url = CONSTANTS.isDataServiceActive ? CONSTANTS.appUserAccountURL : 'app/mock-api/user-account.json'
    }

    return $http.post(url, service.account).then(
      function(httpData) {
        if (httpData.data) {
          service.account = initUserAccount(httpData.data)
        }
      },
      function(httpData) {
        console.log('Error')
      })
  }

  function saveAccountAndRefresh() {
    if (!service.account.username) {
      return service.getUserAccount()
          .then(function() {
            return updateAccount()
          })
    }
    return updateAccount()
  }

  service.getUserAccount = function() {
    let url, promise

    if ($rootScope.auth.isClientView()) {
      url = CONSTANTS.isDataServiceActive ? CONSTANTS.appClientUserAccountURL : 'app/mock-api/user-account.json'
    } else {
      url = CONSTANTS.isDataServiceActive ? CONSTANTS.appUserAccountURL : 'app/mock-api/user-account.json'
    }

    promise = $http.get(url).then(
      function(httpData) {
        if (httpData.data) {
          service.account = initUserAccount(httpData.data)
        }
      },
      function(httpData) {
        console.log('Error')
      })

    return promise
  }

  service.whoAreMyParties = function(name) {
    let url, promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.whoAreMyParties : 'app/mock-api/user-account.json'

    promise = $http.get(url).then(function(httpData) {
      console.log(httpData)
    },
    function(httpData) {

    })

    return promise
  }

  service.setLastBirthdayAlert = function(d) {
    service.getUserAccount(true)
        .then(function() {
          service.account.lastBirthdayAlert = new Date(d)
          saveAccountAndRefresh(true)
        })
  }

  service.getLastBirthdayAlert = function() {
    return new Date(service.account.lastBirthdayAlert)
  }

  return service
}

export {userAccountService}
