export {ChangePasswordController}
function initialRequirements() {
  return {
    hasLowerCase: false,
    hasNumber: false,
    hasUpperCase: false,
    hasWhitespace: false,
    isLongEnough: false,
    isTooShort: false,
    lacksWhitespace: false,
    needsLowerCase: false,
    needsUpperCase: false,
    needsNumber: false,
  }
}

ChangePasswordController.$inject = ['$state', 'CONSTANTS', '$http', 'utils', 'previousState']

  /* @ngInject */
function ChangePasswordController($state, CONSTANTS, $http, utils, previousState) {
  const self = this

  self.closing = false

  self.errorMessage = null

  self.passwordsDontMatch = false // A double-negative, but precise. Should only become true when neither new nor confirm are blank.

  self.passwordFields = {
    current: '',
    new: '',
    confirm: '',
  }

  self.requirements = initialRequirements()

  self.saveRequestPending = false

  self.submittedSuccessfully = false

  self.canSave = function() {
    return self.passwordFields.current &&
        self.passwordFields.new === self.passwordFields.confirm &&
        self._newPasswordMeetsRequirements() &&
        !self.saveRequestPending
  }

  self.changePassword = function() {
    if (self.canSave()) {
      self.saveRequestPending = true

      $http.put(CONSTANTS.changePasswordURL, {
        password: self.passwordFields.current,
        newPassword: self.passwordFields.new,
      })
        .then(self._changePasswordSuccess)
        .catch(self._changePasswordFailure)
        .then(self._saveRequestComplete)
    }
  }

  self.checkPasswordRequirements = function() {
    self.requirements = self._calculateRequirements(self.passwordFields.new)
    if (self.passwordFields.new && self.passwordFields.confirm) {
      self.passwordsDontMatch = self.passwordFields.new !== self.passwordFields.confirm
    } else {
      self.passwordsDontMatch = false
    }
  }

  self.clearErrorMessage = function() {
    self.errorMessage = null
  }

  self.exit = function() {
    self.closing = true
    $state.go(previousState.name, previousState.params)
  }

  self._calculateRequirements = function(password) {
    const requirements = initialRequirements()

    if (!password || typeof password !== 'string') {
      return requirements
    }

    requirements.isTooShort = password.length < self._minimumPasswordLength
    requirements.isLongEnough = !requirements.isTooShort
    requirements.hasUpperCase = password.toLowerCase() !== password
    requirements.needsUpperCase = !requirements.hasUpperCase
    requirements.hasLowerCase = password.toUpperCase() !== password
    requirements.needsLowerCase = !requirements.hasLowerCase
    requirements.hasNumber = /\d/.test(password)
    requirements.needsNumber = !requirements.hasNumber
    requirements.hasWhitespace = /\s/.test(password)
    requirements.lacksWhitespace = !requirements.hasWhitespace

    return requirements
  }

  self._changePasswordFailure = function(error) {
    self.submittedSuccessfully = false

    if (error.status === 422) {
        // 422 comes with a helpful error message.
      self.errorMessage = self._joinErrorSentences(error.data)
    } else {
      self.errorMessage = 'Sorry, something went wrong on our end. Try again later.'
    }
  }

  self._changePasswordSuccess = function(result) {
    self.errorMessage = null
    self.submittedSuccessfully = true
  }

  self._joinErrorSentences = function(errorCodesAndMessages) {
    let messages

    if (!errorCodesAndMessages) {
      return null
    }

    messages = utils.objectValues(errorCodesAndMessages)

    if (!messages.length) {
      return null
    }

    return messages.join('. ') + '.'
  }

  self._minimumPasswordLength = 8

  self._newPasswordMeetsRequirements = function() {
    return self.requirements.hasLowerCase &&
        self.requirements.hasNumber &&
        self.requirements.hasUpperCase &&
        self.requirements.isLongEnough
  }

  self._saveRequestComplete = function() {
    self.saveRequestPending = false
  }
}
