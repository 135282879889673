
import * as angular from 'angular'
interface CountsResult {
  counts: CountResult[]
}

export interface CountResult {
  count: number
  value: string
  valueTypeCode: any
}

export class CountService {

  public inforcePolicyCount: number = 0
  public notPaidPolicyCount: number = 0
  public unsubmittedApplicationCount: number = 0
  private holdingStatues

  private defaultUrl = `${this.CONSTANTS.apiRoot}/policy/counts/lineofbusiness`

  // const routeSourceHoldingStatus = {
  //   inforce: '1', // Active
  //   // We have a different idea of what it means to be closed.
  //   // closed: '2', // Inactive
  //   pending: '3', // Pending
  //   // Our routes may support suspended or issued in the future.
  //   suspended: '4', // Suspended
  //   isssued: '1012300004', // Issued
  //   notpaid: '1012300005', // Issued Not Paid
  // }

  constructor(private $http: angular.IHttpService, private CONSTANTS, private partyService, private utils) {
    this.defaultUrl = `${this.CONSTANTS.apiRoot}/policy/counts/lineofbusiness`
    this.holdingStatues = this.CONSTANTS.holdingStatusTypes
  }

  getInforcePolicyCount() {
    let url = `${this.defaultUrl}?holdingstatus=${this.holdingStatues.active.typeCode}`

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    return this.$http.get<CountsResult>(url).then((result) => this.sumResults(result))
  }

  getPendingPolicyCount() {
    let url = `${this.defaultUrl}?holdingstatus=${this.holdingStatues.pending.typeCode}`

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    return this.$http.get<CountsResult>(url).then((result) => this.sumResults(result))
  }

  getIssueNotPaidCount() {
    let url = `${this.defaultUrl}?holdingstatus=${this.holdingStatues.issuedNotPaidPending.typeCode}` // 1012300005

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    return this.$http.get<CountsResult>(url).then((result) => this.sumResults(result))
  }

  getUnsubmittedCount(summarize: boolean = true) {
    // let url = `/proxy/core-services-gateway/policy/app/counts/policystatus?holdingstatus=1012300001`
    let url = `${this.CONSTANTS.apiRoot}/policy/app/counts/policystatus?holdingstatus=${this.holdingStatues.applicationStarted.typeCode}`

    url = this.utils.appendURLParameter(url, 'filterKey', this.partyService.getAgentKey())
    return this.$http.get<CountsResult>(url).then((result) => summarize ? this.sumResults(result) : result?.data?.counts ?? [])
  }

  private sumResults(result: ng.IHttpResponse<CountsResult | undefined>) {
    return result.data?.counts ?
      result.data?.counts?.reduce((accumulator, currentValue) => { return accumulator + currentValue.count }, 0) : 0
  }

}
