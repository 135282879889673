import * as angular from 'angular'
/**
 * This directive adds a click event to the element and toggles
 * it's sortable state. Three states are supported; ASC (Up arrow),
 * DESC (Down arrow), or natual sort (No Arrow).
 *
 * This directive requires a parent directive of type `pmlNgListView`,
 * which provides a `sort` function that accepts an optional instance of
 * ISortColumn.
 *
 */
export class PmlSortableColumnDirective implements ng.IDirective {
  restrict = 'A'
  // templateUrl = 'app/cl-and-r/tmpl/sortable-column.html'
  replace = false
  require = '^^pmlNgListView'
  // bindToController = true
  transclude = false
  scope = true
  // constructor() { } useless constructor
  priority = 0

  compile(el) {
    const sorter = angular.element(`<div class="filter-arrows-container">
      <i class="icon icon-sort-up-arrow" ng-class="{sorted: sortInfo.direction === 'ASC'}">&nbsp;</i>
      <i class="icon icon-sort-down-arrow" ng-class="{sorted: sortInfo.direction === 'DESC'}">&nbsp;</i>
    </div>`)
    const innerContent = el.text()
    const div = angular.element(`<div class="th-contents"><span>${innerContent}</span></div>`)

    div.append(sorter)

    el.empty()
    el.append(div)

    return this.link
  }

  /**
   * AngularJS post link function use for initial configuration of instances of PmlSortableColumnDirective
   */

  // tslint:disable-next-line
  link(scope: any, el: ng.IAugmentedJQuery, attrs: ng.IAttributes, listView) {
    scope.sortInfo = { name: attrs.pmlSortableColumn, direction: attrs.initialSortDir || '', dataType: attrs.pmlSortableDataType }

    if (Boolean(attrs.initialSortDir)) listView.sortFilterPage(scope.sortInfo)

    scope.$on('list-view-state-change', (_event: any, curentSortColumn: ISortColumn, _1, _2, previousSortColumn) => {
      scope.previousSortColumn = previousSortColumn

      if (curentSortColumn?.name !== attrs.pmlSortableColumn) {
        scope.sortInfo.direction = ''
      }
    })

    // Initialize sorter and it's icon

    // const icon = sorter.find('i')

    el.css({cursor: 'pointer'})  // TODO: move to scss

    el.on('click', () => {

      console.log(scope.sortInfo?.name, scope.previousSortColumn?.name)

      if (scope.sortInfo?.name !== scope.previousSortColumn?.name && scope.sortInfo?.direction === '') {
        scope.sortInfo.direction = attrs.defaultSortDir || 'ASC'
      } else {
        switch (scope.sortInfo.direction) {
          case 'ASC':
            scope.sortInfo.direction = 'DESC'
            break
          case 'DESC':
            scope.sortInfo.direction = 'ASC'
            break
          // default:
          //   scope.sortInfo.direction = 'ASC'
        }

      }

      listView.sortFilterPage(scope.sortInfo)
      scope.$apply()
    })
  }

  /**
   * Creates an instance of PmlSortableColumnDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = () => new PmlSortableColumnDirective()

    directive.$inject = []

    return directive
  }
}
