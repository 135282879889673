export class MfaRunner {
  static $inject = ['$rootScope']

  constructor ($rootScope) {
    $rootScope.$on('$stateChangeSuccess', (_event, toState, _toParams, _fromState, _fromParams) => {
      $rootScope.mfa = {
        showLogoHeader: toState.name !== 'mfa.check',
      }
    })
  }
}
