/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { GoogleAnalyticsService } from '../../components/google-analytics'
import { CrafterService } from '../../utils/crafter-service'
import { IAddressCountry } from '../address/types'
import { RolesPermissionService } from '../client-bene-edit/roles-permission-service'
import { IRoleEligibilityResult } from '../roles/types'
import { BENEFICIARY_GA_EVENTS, USE_TRANSACTIONAL_BENEFICIARY_ENDPOINT } from './beneficiary-constants'
import { BeneficiariesDataProviderService } from './beneficiary-data-provider'
import { Beneficiaries } from './classes/beneficiaries'
import { RoleChangeReponse } from './classes/role-change-response'
import { BENEFICIARY_VIEWS } from './constants/BENEFICIARY_VIEWS'
import { RoleRelationshipsService } from './relationships/role-relationships-service'
import { IEditableBeneficiary, IRoleChanges } from './types'

export class BeneficiariesController {
  static $inject: any = ['$scope', '$q', '$state', '$stateParams','$cookies', 'BENEFICIARY_ROLES', 'beneficiariesDataProvider', 'BENEFICIARY_EVENTS',
    'BENEFICIARY_VIEWS', 'BENEFICIARY_VIEW_STATES', 'BENEFICIARY_PARTY_TYPES', 'OWNER_ROLE_TYPE_CODES', 'BENEFICIARY_SUFFIXES', 'roleRelationshipsService', 'configService', 'googleAnalyticsService', 'rolesPermissionService', 'crafterService']

  groupedBeneficiaries: IEditableBeneficiary
  sections: any[] = []
  partyList: any[]
  owners: any[]
  relationships: any = {}
  showAllSections: boolean = false
  disableSaveButton: boolean = true
  showCategories: boolean = false
  showSuffixes: boolean = false
  viewState: any
  confirmation: any
  pid: any
  session: any
  policySummary: any
  datePickerOptions: any
  effectiveDate: any
  changeResults: RoleChangeReponse | null = null
  isBeneficiaryManagmentAllowed: boolean
  canSuppressCorrespondence: boolean
  sliderLabelText: string
  showCorrespondenceToggle: boolean
  sendCorrespondence: boolean
  acordCountries: IAddressCountry
  isDeathClaim: boolean
  eligibility: IRoleEligibilityResult
  debugData: any
  organizedClients: any

  private _categoryForms: any = {}
  private _readyCounter: any = {}
  private _originalData: Beneficiaries[] = []
  private _ownerRoleIds: string[]
  private _deregisterFunctions: Function[] = []
  private _editorOpen: boolean = false
  private _hasChanges: any = {}
  private _invalidCategories: any = {}
  private payload: IRoleChanges | null | undefined = null

  constructor(private $scope, private $q, private $state, private $stateParams, $cookies, private BENEFICIARY_ROLES, private beneficiariesDataProvider: BeneficiariesDataProviderService,
    private BENEFICIARY_EVENTS, public beneficiaryViews: typeof BENEFICIARY_VIEWS, private BENEFICIARY_VIEW_STATES,
    public BENEFICIARY_PARTY_TYPES, private OWNER_ROLE_TYPE_CODES, public BENEFICIARY_SUFFIXES, private roleRelationshipsService: RoleRelationshipsService, private configService: any, private googleAnalyticsService: GoogleAnalyticsService, private rolesPermissionService: RolesPermissionService, private crafterService: CrafterService) {
    this.pid = this.$state.params.id

    this.sections[0] = {
      rid: this.BENEFICIARY_ROLES.PRIMARY,
      title: 'Primary Beneficiaries',
      pid: this.pid,
    }
    this.sections[1] = {
      rid: this.BENEFICIARY_ROLES.CONTINGENT,
      title: 'First Contingent Beneficiaries',
      pid: this.pid,
    }
    this.sections[2] = {
      rid: this.BENEFICIARY_ROLES.SECOND,
      title: 'Second Contingent Beneficiaries',
      pid: this.pid,
    }
    this.sections[3] = {
      rid: this.BENEFICIARY_ROLES.FINAL,
      title: 'Final Beneficiaries',
      pid: this.pid,
    }

    this._ownerRoleIds = this.OWNER_ROLE_TYPE_CODES.map((role: any): string[] => role.tc)
    this.session = JSON.parse($cookies.get('session'))
    this.datePickerOptions = {
      formatDay: 'd',
      formatYear: 'yy',
      formatDayHeader: 'EEE',
      showWeeks: false,
      maxDate: new Date(),
    }
    this.effectiveDate = new Date()
    this.showCorrespondenceToggle = false
    this.sliderLabelText = 'Send Confirmation?'
    this.sendCorrespondence = true
  }

  _getClientRolesByOwner() {
    return this.beneficiariesDataProvider.getPolicyRoles(this.pid, this._ownerRoleIds)
      .then((owners) => owners.map((owner) => owner.id))
      .then((ownerIds: string[]) => this.beneficiariesDataProvider.getClientRolesByOwner(ownerIds))
      .then((associatedParties) => {
        this.partyList = associatedParties
        return this.partyList
      })
  }

  isReadOnly(): boolean {
    return !this.isBeneficiaryManagmentAllowed
  }

  /**
   * Make the calls for each of the beneficiary role types. (e.g. 34, 26, and 1012300003)
   *
   * Then it performs a check to see if there are any beneficiaries with specific dollar amount distributions.
   * If so then the `this.showAllSections` is set to `false`, and a message is displayed to the user. Otherwise,
   * the flag is set to `true`.
   *
   * If `this.showAllSections` flag is `true`, then backup of the data is made, so a complete undo can be
   * performed during the `REVIEW` view state.
   *
   * @returns Promise<Beneficiaries[]>
   */
  _getBeneficiariesForEachClass(associatedParties) {
    return this.$q.all(this.sections.map(async (section) => this.beneficiariesDataProvider.getBeneficiaries(section, associatedParties)))
      .then((beneficiaries) => {
        this.sections = beneficiaries

        // Making a backup of the origin data from when the route loaded. This will be used for
        // reverting all changes made via the review state, and to determine if there are changes to submit.
        this._originalData = this.sections.map((section) => this.beneficiariesDataProvider.deepCloneBeneficiaries(section))

        this.showAllSections = true

      })
  }

  /**
   * Called when the call to `BeneficiariesDataProvider.submitBeneficiaries` results
   * in a CoreServicesGateway error.
   *
   * Sets `BeneficiariesController.viewState` to `BENEFICIARY_VIEWS.ERROR`.
   *
   * @param err
   */
  _errorHandler(err: any): void {
    const parameters = { reason: err.errorMessage }
    const gaData: any = Object.assign({}, BENEFICIARY_GA_EVENTS.HO_FAIL, { parameters, errorCode: err.errorCode })

    console.error('_errorHandler', err, gaData)

    this.viewState = this.BENEFICIARY_VIEW_STATES[this.beneficiaryViews.ERROR]
    this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)
  }

  /**
   * Called when the call to `BeneficiariesDataProvider.submitBeneficiaries` results
   * in a successful submission.
   *
   * Calls BeneficiariesController._getAllSections() to refresh the list of
   * current beneficiaries.
   *
   * When completed, set the `BeneficiariesController.viewState` to `BENEFICIARY_VIEWS.CONFIRMATION`
   *
   * @param result
   */
  _successHandler(result: RoleChangeReponse) {
    let gaData: any = {}

    this.changeResults = result

    if (this.changeResults.hasError) {
      this.viewState = this.BENEFICIARY_VIEW_STATES[this.beneficiaryViews.ERROR]
      gaData = Object.assign({}, BENEFICIARY_GA_EVENTS.HO_FAIL, { parameters: { reason: result.statusText, policyId: this.pid }, errorCode: result.status })
    } else {
      // NOTE: Temporarily removing this due to CS limitation
      //
      // return this._getBeneficiariesForEachClass(this.partyList)
      //   .then(() => {
      //     this.viewState = this.BENEFICIARY_VIEW_STATES[this.BENEFICIARY_VIEWS.CONFIRMATION]
      //   })

      gaData = Object.assign({}, BENEFICIARY_GA_EVENTS.HO_SUCCESS, { parameters: {reason: 'Success', policyId: this.pid } })

      this.$scope.$broadcast(this.BENEFICIARY_EVENTS.CHANGES_COMPLETED)

      this.viewState = this.BENEFICIARY_VIEW_STATES[this.beneficiaryViews.CONFIRMATION]

      this.$scope.$applyAsync()
    }

    this.googleAnalyticsService.send(gaData.event, gaData.action, gaData.parameters, gaData.errorCode)

    console.log('_successHandler', result, gaData)
  }

  /**
   * This function is used as an output binding to `onReady` on the beneficiary-category components.
   * This function expects a categoryIndex value to identify which category has called back, and
   * the name of the `BENEFICIARY_VIEWS` that are being waited on.
   *
   * If the current `BeneficiariesComponent.viewState` is `BENEFICIARY_VIEWS.CONFIRMATION` this does
   * nothing and returns immediately. For any other view state, the function waits until the
   * `BeneficiariesComponent._readyCounter` for the active view state reaches three (3). When it
   * does the current `BeneficiariesComponent.viewState` is set to the active one.
   *
   * @param categoryIndex
   * @param view
   * @returns undefined
   */
  categoryReady(categoryIndex, view) {
    // console.log('viewState: current: %s, incoming: view', this.viewState?.state, view)
    // const hasChanges = this.checkForChanges(this._originalData, this.sections)

    if (!this.isBeneficiaryManagmentAllowed) {
      return
    }

    if (this.viewState?.state === this.beneficiaryViews.CONFIRMATION) return

    if (!this._readyCounter[view]) this._readyCounter[view] = []

    this._readyCounter[view].push(categoryIndex)

    if (this._readyCounter[view].length === 4) {
      this.viewState = this.BENEFICIARY_VIEW_STATES[view]
      this._readyCounter[view] = null
      // console.log('viewState: current: %s => new: %s vuew', this.viewState?.state, view)
    }
  }

  categoryEditorStateChanged(categoryId, state) {
    // console.log('categoryEditorStateChanged', categoryId, state)

    this.$scope.$broadcast(this.BENEFICIARY_EVENTS.EDITOR_STATE_CHANGE, { categoryId, state })
  }

  /**
   * ngClick handler for the "Continue" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.CONFIRMATION`
   *
   * Changes `BeneficiariesComponent.viewState` to `BENEFICIARY_VIEWS.INITIAL`
   */
  changesComplete() {
    // this.changeResults = null
    // this.viewState = this.BENEFICIARY_VIEW_STATES[this.BENEFICIARY_VIEWS.INITIAL]

    this.$state.go('^', null, { reload: true })
  }

  /**
   * ngClick handler for the "Save" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.EDIT`
   *
   * Broadcasts a `BENEFICIARY_EVENTS.SAVE_CHANGES` message, which the Category componets listen for.
   * The categories respond by calling their onReady function with `BENEFICIARY_VIEWS.REVIEW`
   * (See categoryReady method shown above.)
   */
  saveChanges() {
    try {
      const tmp = this.beneficiariesDataProvider.getAllPartiesInvolved(this.sections as Beneficiaries[], this._originalData)

      if(this.payload) {
        this.payload.beforeSnapShot = JSON.stringify(tmp.beforeData)
        this.payload.afterSnapShot = JSON.stringify(tmp.afterData)
      }
    } catch (err) {
      console.error(err)
    }

    this.$scope.$broadcast(this.BENEFICIARY_EVENTS.SAVE_CHANGES)
  }

  /**
   * ngClick handler for the "Change" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.INITIAL`
   *
   * Broadcasts a `BENEFICIARY_EVENTS.START_CHANGES` message, which the Category componets listen for.
   * The categories respond by calling their onReady function with `BENEFICIARY_VIEWS.EDIT`
   * (See categoryReady method shown above.)
   */
  startChanges() {
    this.$scope.$broadcast(this.BENEFICIARY_EVENTS.START_CHANGES, this.viewState.state)
  }

  /**
   * ngClick handler for the "Submit Changes" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.REVIEW`.
   *
   * Sets the `BeneficiariesComponent.viewState` to `BENEFICIARY_VIEWS.LOADING`, and then calls the
   * `BeneficiariesDataProvider.submitBeneficiaries` method with the current `pid`, and all of the
   * categories beneficiary data.
   *
   * @returns a Promise<void>
   */
  async submitChanges() {
    this.viewState = this.BENEFICIARY_VIEW_STATES[this.beneficiaryViews.LOADING]

    return this.beneficiariesDataProvider.submitBeneficiaries(this.payload, this.configService.features.useTransactionBeneficiaryUpdates && USE_TRANSACTIONAL_BENEFICIARY_ENDPOINT)
      .then((result) => this._successHandler(result))
      .catch((err) => this._errorHandler(err))
  }

  /**
   * ngClick handler for the "Cancel" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.EDIT`
   *
   * Broadcasts a `BENEFICIARY_EVENTS.UNDO_CHANGES` message, which the Category componets listen for.
   * The categories respond by calling their onReady function with `BENEFICIARY_VIEWS.INITIAL`
   * (See categoryReady method shown above.)
   */
  undoChanges() {
    this.$scope.$broadcast(this.BENEFICIARY_EVENTS.UNDO_CHANGES)
  }

  /**
   * ngClick handler for the "Cancel" button, shown when the `BeneficiariesComponent.viewState`
   * is `BENEFICIARY_VIEWS.REVIEW`
   *
   * Restores the `BeneficiariesComponent.sections` with the data backed up `BeneficiariesComponent._originalData`
   */
  cancelChanges() {
    this.sections = this._originalData.map((section) => this.beneficiariesDataProvider.deepCloneBeneficiaries(section))
  }

  /**
   * This function is used as an output binding to `onValidate` on the beneficiary-category components.
   * This function expects a categoryIndex value to identify which category has called back, and
   * the name of the ngForm being validated, and boolean flag that indidcates if the category dirty.
   *
   * @param formName
   * @param hasData
   * @param categoryIsValid
   * @param hasChanges
   * @param validationKey
   */
  validateCategory(formName: string, hasData: boolean, categoryIsValid: boolean, hasChanges: boolean, validationKey: string): void {

    this._categoryForms[formName] = { formName, hasData, categoryIsValid, hasChanges, validationKey }

    if (this._categoryForms[formName].hasChanges) {
      this._hasChanges[formName] = true
    } else {
      delete this._hasChanges[formName]
    }

    if (!this._categoryForms[formName].categoryIsValid && !['000', '001'].includes(this._categoryForms[formName].validationKey)) {
      this._invalidCategories[formName] = true
    } else {
      delete this._invalidCategories[formName]
    }

    // This map is used to determine the visibiltiy of the four beneficiary classes (category)
    const categoryHasDataMap = Object.keys(this._categoryForms).reduce((acc, catFormKey) => {
      const catForm = this._categoryForms[catFormKey]
      const beneRoleTc = catFormKey.split('catForm')[1]

      acc[beneRoleTc] = catForm.hasData

      return acc
    }, {})

    this.payload = this.beneficiariesDataProvider.buildPayload(this.pid, this.sections, this._originalData, this.effectiveDate, this.sendCorrespondence)

    this.$scope.$broadcast(this.BENEFICIARY_EVENTS.ENABLE_ADD_BENE_DD, categoryHasDataMap)

    const changedCount = Object.keys(this._hasChanges).length
    const invalidCount = Object.keys(this._invalidCategories).length

    this.disableSaveButton = this._editorOpen || !this.isValidPayload || invalidCount > 0

    this.debugData = { disableSaveButton: this.disableSaveButton, changedCount, invalidCount, _categoryForms: this._categoryForms, _editorOpen: this._editorOpen, _hasChangesList: this._hasChanges, _invalidCategoriesList: this._invalidCategories }
  }

  get isValidPayload(): boolean {
    if (!this.payload) return false

    const clientChanges: number = this.payload.clientChanges.add.length + this.payload.clientChanges.update.length
    const phoneChanges: number = this.payload.phoneChanges.add.length + this.payload.phoneChanges.update.length + this.payload.phoneChanges.delete.length
    const roleChanges: number = this.payload.roleChanges.add.length + this.payload.roleChanges.update.length + this.payload.roleChanges.delete.length

    return clientChanges + phoneChanges + roleChanges > 0
  }
  /**
   * AngularJS life-cycle hook.
   */
  $onInit(): void {
    this.isDeathClaim = this.policySummary.policyStatus.toLowerCase().includes('death claim')

    if (!this.isBeneficiaryManagmentAllowed) {
      this.viewState = this.BENEFICIARY_VIEW_STATES[this.beneficiaryViews.READONLY]
    }

    if (this.canSuppressCorrespondence) {
      this.showCorrespondenceToggle = true
    }

    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.EDITOR_STATE_CHANGE, (_e, viewState) => this._updateViewState(viewState)))
    this.roleRelationshipsService.load()
      .then(() => this.crafterService.getBeneficiaryMetadata().then((beneficiaryMetadata: any) => this.eligibility = this.rolesPermissionService.clientCanManageBeneficiaries(this.policySummary, this.organizedClients, beneficiaryMetadata)))
      .then(() => this.beneficiariesDataProvider.getAccordCountries().then((countries) => this.acordCountries = countries))
      .then(() => this._getClientRolesByOwner())
      .then((partyList) => this._getBeneficiariesForEachClass(partyList))
  }

  $onDestroy(): void {
    this._deregisterFunctions.forEach(fn => fn())
  }

  private _updateViewState(viewState: any) {
    this._editorOpen = viewState.state === 'open'
  }
}
