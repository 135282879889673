export {PendingPolicyController}

PendingPolicyController.$inject = ['$stateParams', 'policySummaryResult', 'policyService', 'recentlyViewedService',
  'authService', 'correspondenceService', 'dateUtils', 'CONSTANTS', 'downloadManager',
]

/* @ngInject */
function PendingPolicyController($stateParams, policySummaryResult, policyService, recentlyViewedService,
  authService, correspondenceService, dateUtils, CONSTANTS, downloadManager) {
  const self = this

  self.CHANNELS = CONSTANTS.notificationType.policy + ',' + CONSTANTS.notificationType.system + ',' + CONSTANTS.notificationType.systemUnavailability

  self.id = $stateParams.id

  self.errorMessage = ''
  self.resultMessage = 'Loading'
  self.notAvailableInfo = CONSTANTS.notAvailableInfo
  self.isThereError = false

  self.orderByField = ''
  self.reverseSort = false

  self.specialDocIDs = {
    'applicationDocId': null,
    'illustrationDocId': null,
    'policyPagesDocId': null,
  }

  self.pendingPolicy = {}
  self.pendingPolicyApplication = {}
  self.requirementsData = {}

  self.summary = policySummaryResult.policy

  self.primaryRoleNames = []
  self.servicingRoleNames = []
  self.additionalRoleNames = []

  self.clients = {
    'owners': [{
      'fullName': 'Loading...',
    }],
    'insureds': [{
      'fullName': 'Loading...',
    }],
    'beneficiaries': [{
      'fullName': 'Loading...',
    }],
  }

  function initClients() {
    if (authService.isAClient()) {
      return
    }

    policyService.getClientsByRole(self.pendingPolicy.polNumber).then(
      function(clients) {
        self.clients.owners = clients.owners
        if (clients.jointOwners && clients.jointOwners.length) {
          clients.jointOwners.forEach(function(client) {
            self.clients.owners.push(client)
          })
        }
        self.clients.insureds = clients.insureds ? clients.insureds : clients.annuitants
        self.clients.payors = clients.payors

        if (self.pendingPolicy.isAnnuity) {
          self.clients.birthDateLabel = 'Owner'
          self.clients.birthDate = clients.owners.length > 0 ? dateUtils.parseDate(clients.owners[0].birthDate) : ''
        } else {
          self.clients.birthDateLabel = 'Insured'
          self.clients.birthDate = clients.insureds.length > 0 ? dateUtils.parseDate(clients.insureds[0].birthDate) : ''
        }
      })
  }

  self.onSortColumnClick = function(sortColumn) {
    if (sortColumn !== self.orderByField) {
      self.orderByField = sortColumn
      self.reverseSort = false
    } else {
      self.reverseSort = !self.reverseSort
    }
  }

  self.areRequirementsEmpty = function() {
    if (self.requirementsData && self.requirementsData.requirements && self.requirementsData.requirements.length > 0) {
      return false
    }

    return true
  }

  self.showCorrespondence = function(docId) {
    let url

    if (docId) {
      url = CONSTANTS.correspondencesDownloadURL.replace('{0}', docId).replace('{1}', authService.getSessionID())

      window.open(url, '_system')
    }
  }

  self.downloadRequirements = function() {
    const policyNumber = self.pendingPolicy.polNumber

    downloadManager.getRequirements(policyNumber)
  }

  function setAgentsNamesByRole(list) {
    if (list) {
      list.forEach(function(agent, key) {
        agent.roles.forEach(function(item) {
          if (item.role) {
            if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.primary) {
              self.primaryRoleNames.push(agent.fullName)
            } else if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.servicing) {
              self.servicingRoleNames.push(agent.fullName)
            } else if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.additional) {
              self.additionalRoleNames.push(agent.fullName)
            }
          }
        })
      })
    }
  }

  self.allRequestsReturned = function() {
    let ret = policyService.pendingPolicyApplicationDelivered

    ret = ret && policyService.pendingPolicyRequirementsDelivered
    ret = ret && policyService.policyAgentsDelivered
    ret = ret && correspondenceService.correspondencesDelivered

    return ret
  }

  function init() {
    if (!policySummaryResult.error) {
      self.pendingPolicy = policySummaryResult.policy

      recentlyViewedService.addPolicyPromise(self.pendingPolicy)

      policyService.getPendingPolicyApplication(self.pendingPolicy.polNumber).then(
        function(result) {
          if (!result.error) {
            self.pendingPolicyApplication = result.policyApplication
          }
        })

      if (!authService.isAClient()) {
        policyService.getPendingPolicyRequirements(self.pendingPolicy.polNumber).then(
          function(result) {
            if (!result.error) {
              self.requirementsData = result.data

              if (self.areRequirementsEmpty()) {
                self.resultMessage = 'Results not found'
              } else {
                self.resultMessage = ''
              }
            }
          })

        correspondenceService.getCorrespondences(new Array(self.pendingPolicy.polNumber)).then(
          function(result) {
            if (!result.error) {
              correspondenceService.fillSpecialDocIDs(result.documents, self.specialDocIDs)
            }
          })
      }

      initClients()

      policyService.getPolicyAgents(self.pendingPolicy.polNumber, true).then(
        function(result) {
          setAgentsNamesByRole(result.data.parties)
        })
    } else {
      self.errorMessage = policySummaryResult.status === 404 ? CONSTANTS.notFoundMessage : policySummaryResult.error
    }

    self.isThereError = Boolean(self.errorMessage)
  }

  init()
}
