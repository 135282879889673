(function() {
  'use strict'

  angular.module('app.directives').directive('wgPendingRequirementsBar', [function() {
    return {
      restrict: 'EA',
      scope: {
        requirementInfo: '=',
      },
      templateUrl: 'app/widgets/templates/pending-requirements-bar.html',
      controller: [function() {}],
      controllerAs: 'wgPendingRequirementsBarCtrl',
      bindToController: true,
    }
  }])
})()
