
navigationStack.$inject = []

  /* @ngInject */
function navigationStack() {
  const service = {}
  let navStack = []
  const localStorage = window.getLocalStorage()

  service.init = function() {
    navStack = localStorage.navStack ? JSON.parse(localStorage.navStack) : []
  }

  service.reset = function() {
    navStack = []
    localStorage.navStack = JSON.stringify(navStack)
  }

  service.push = function(state, params) {
    navStack.push({
      state: state,
      params: params,
    })
    localStorage.navStack = JSON.stringify(navStack)
  }

  service.pop = function() {
    navStack.pop()
    localStorage.navStack = JSON.stringify(navStack)
  }

  service.getLast = function() {
    if (navStack.length > 0) {
      return navStack[navStack.length - 1]
    } else if (localStorage.navStack && JSON.parse(localStorage.navStack).length > 0) {
      service.init()
      return navStack[navStack.length - 1]
    }

    service.init()
    return {}
  }

  service.init()

  return service
}

export {navigationStack}
