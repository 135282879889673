(function() {
  'use strict'

  angular.module('app.directives').controller('WgProgressBarCtrl', WgProgressBarCtrl).directive('wgProgressBar', progressBarDirective)

  function progressBarDirective() {
    return {
      scope: {
        model: '=',
        states: '=',
      },
      restrict: 'E',
      controller: WgProgressBarCtrl,
      controllerAs: 'pbCtrl',
      templateUrl: 'app/widgets/templates/wg-progress-bar.html',
      bindToController: true,
    }
  }

  WgProgressBarCtrl.$inject = ['$state']

  function WgProgressBarCtrl($state) {
    const self = this

    function init() {
      self.currentState = $state.current.name.split('.').pop()

      self.states.forEach(function(step) {
        if (self.currentState === step.description) {
          self.currentStep = step
        }
      })

      self.steps = self.states.map(function(step) {
        step.completed = step.stepNumber < self.currentStep.stepNumber
        return step
      })
    }

    init()
  }
})()
