export class ClientListController {

  static $injector: any = ['$scope', 'authService', 'partyService']

  constructor(private $scope, authService, partyService) {
    const filterKey = partyService.getAgentKey()

    this.$scope.dataCounts = {
      clients: 0,
    }

    this.$scope.noDataAvailable = true

    // Check if the current user is able to viewAs an agent.
    this.$scope.isAgent = authService.isInRole('AGENT')
    this.$scope.isNotAgent = !this.$scope.isAgent

    // Resolve noDataMessage
    if (this.$scope.isAgentView && this.$scope.isNotAgent && !filterKey) {
      this.$scope.noDataMessage = 'Please pick an individual from the View As menu in the upper left to see their client list.'
    } else if (this.$scope.isAgentView && this.$scope.isNotAgent && filterKey) {
      this.$scope.noDataMessage = 'There are no clients to display. Please make another selection from the View As menu in the upper left.'
    } else if (this.$scope.isAgentView && this.$scope.isAgent) {
      this.$scope.noDataMessage = 'There are no clients to display.'
    }

    // Set listView methodOptions on current scope. Is passed to listViews isolated scope in the template.
    this.$scope.listViewDataOptions = { filterKey, isAgent: this.$scope.isAgent, pageable: true }

    const clearDataCountsWatch = this.$scope.$on('PML_NG_LISTVIEW_DATA_READY', this._onDataReady.bind(this))

    // Cleanup any watchers created by this controller.
    this.$scope.$on('$destroy', () => {
      if (typeof clearDataCountsWatch === 'function') clearDataCountsWatch()
    })

  }

  _onDataReady(_e, name, listViewCtrl) {
    // Update the dataCounts for data source name received.
    this.$scope.dataCounts[name] = listViewCtrl.data?.length ?? 0

    // Count the total data received to determine if there is
    // any credentials data available.
    const total = Object.keys(this.$scope.dataCounts).reduce((acc, itemName) => {
      const count = this.$scope.dataCounts[itemName]
      acc += count
      return acc
    }, 0)

    // Update the noDataAvailable flag accordingly
    this.$scope.noDataAvailable = total === 0

    // console.log('this.$scope.noDataMessage', this.$scope.noDataAvailable, this.$scope.noDataMessage)
  }
}
