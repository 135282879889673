import {StateService, StateParams, UrlService} from '@uirouter/core'
import { MfaDataProvider } from '../mfa-data-provider'
import { MFA_TOKEN_TYPES } from '..'
export class MfaStatusCheckController {
  static $inject = ['$state', '$stateParams', 'utils', 'MFA_STATUS_CODES', 'mfaDataProvider', '$urlService']

  constructor($state: StateService, $stateParams: StateParams, utils, MFA_STATUS_CODES, mfaDataProvider: MfaDataProvider, $urlService: UrlService) {
    const queryParams = utils.queryStringToObject(location.hash.split('?', 2)[1])

    console.log('Entering MFA Check Controller', queryParams, $stateParams)
    if (queryParams.redirect_url) queryParams.redirect_url = decodeURIComponent(queryParams.redirect_url)
    if ($stateParams.redirect_url) queryParams.redirect_url = decodeURIComponent($stateParams.redirect_url)

    void mfaDataProvider.getMfaStatus()
      .then((mfaStatus: IMfaStatusResponse) => {
        console.log('mfaStatus', mfaStatus)

        switch (mfaStatus.actionRequired) {
          case MFA_STATUS_CODES.ENROLL_MFA:
            void $state.go('mfa.send-code', { firstTimeMfaUser: true, redirectUrl: queryParams.redirect_url })
            break

          case MFA_STATUS_CODES.VERIFY_POC:
            void $state.go('mfa.send-code', { firstTimeMfaUser: false, redirectUrl: queryParams.redirect_url })
            break

          case MFA_STATUS_CODES.NONE:
            console.log('Redirecting from %s to %s', $urlService.url(), queryParams.redirect_url)

            $urlService.url(queryParams.redirect_url)
            // $location.url(queryParams.redirect_url)
            break

          default:
            throw new Error('Unexpected response from MFA Service')

        }
      })
  }
}
