import * as angular from 'angular'
import { ClientPaymentDirective, CP_CONSTANTS, PaymentsDataProvider } from './payment'
import './client-navigation-header'
import { ClientPaymentController } from './payment/client-payment-controller'
import { wgAdvisorContactInfo } from './wg-advisor-contact-information.directive'

angular.module('app.client.directives', [])

angular.module('app.client.directives')
    .directive('wgClientPayment', ClientPaymentDirective.factory())
    .service('paymentsDataProvider', PaymentsDataProvider)
    .controller('wgClientPaymentController', ClientPaymentController)
    .constant('CP_CONSTANTS', CP_CONSTANTS)
    .directive('wgAdvisorContactInfo', wgAdvisorContactInfo)
