import { TypeCodeValue } from '../../client/beneficiaries/classes/type-code-value'
import { PhoneNumber } from './../../client/beneficiaries/classes/phone'
import { COUNTRY_CODES } from './../../client/beneficiaries/constants/COUNTRY_CODES'
class PhoneNumberInputController {
  static $inject = ['PHONE_TYPES']
  COUNTRY_TYPE_CODES: TypeCodeValue[]
  PHONE_TYPES: TypeCodeValue[]
  phoneNumber: PhoneNumber
  isRequired: boolean
  showLabel: boolean
  showDeleteButton: boolean
  localNumber: string
  typeInputClassList: string
  countriesInputClassList: string
  numberInputClassList: string

  constructor(PHONE_TYPES) {
    this.COUNTRY_TYPE_CODES = COUNTRY_CODES.map((cc) => new TypeCodeValue(cc))
    this.PHONE_TYPES = PHONE_TYPES.map((tcv) => new TypeCodeValue(tcv))
  }

  get isFirstNewNumber() {
    return this.phoneNumber.index === 0 && this.phoneNumber.isNew
  }

//   $onInit() {
//     console.log('phoneNumberInput:$onInit', this)
//   }

  $doCheck() {
    // this.phoneNumber = {
    //   tc: '',
    //   countryCode: this.localCountryCode,
    //   areaCode: '',
    //   dialNumber: '',
    //   ext: '',
    // }
  }
}

export const PhoneNumberInput = {
  templateUrl: 'app/components/phone-number-input/phone-number-input.html',
  controller: PhoneNumberInputController,
  controllerAs: 'ctrl',
  bindings: {
    phoneNumber: '=',
    showCountries: '<',
    isRequired: '<',
    showLabel: '<',
    showDeleteButton: '<',
    typeInputClassList: '<',
    countriesInputClassList: '<',
    numberInputClassList: '<',
    onDelete: '&',
    onUndelete: '&',
  },
}
