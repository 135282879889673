export class PolicyDetailCoveragesController implements ng.IController {

  static $inject = ['policyCoveragesResult', 'policySummary', 'CONSTANTS', 'policyUtils', 'organizeCoverages']

  coverageCollection: any[]
  errorMessage: string
  isThereError: boolean
  eppuaAppuaRiderIsExpired: boolean
  PUA: RiderObject
  showEppuaAppua: boolean

  constructor(private policyCoveragesResult, private policySummary, private CONSTANTS, private policyUtils, private organizeCoverages) {

  }

  $onInit() {
    let collection

    if (this.policyCoveragesResult.error) {
      this.coverageCollection = []
      this.setError(this.policyCoveragesResult.error)
    } else {
      collection = this.policyCoveragesResult.data.coverage || []
      this.coverageCollection = collection.map(this.organizeCoverages)
      this.coverageCollection = Array.prototype.concat([], ...this.coverageCollection)
      this.PUA = this.policyUtils.eppuaAppuaRiders(collection)
      this.showEppuaAppua = this.PUA && !this.policyUtils.eppuaAppuaRiderIsExpired(this.PUA)
    }
  }

  setError(error: string): void {
    this.errorMessage = error
    this.isThereError = Boolean(this.errorMessage)
  }

  isCoverageEmpty(): boolean {
    return this.coverageCollection && this.coverageCollection.length === 0
  }

  getTableDateHeading(): string {
    const polType = this.policySummary.policy.productType

    if (polType === this.CONSTANTS.productType.term) {
      return 'Level period end date'
    }
    return 'Expire date'
  }

  getTableDate(coverage): Date {
    const polType = this.policySummary.policy.productType

    if (polType === this.CONSTANTS.productType.term) {
      return coverage.conversionDate
    }
    return coverage.termDate
  }

  showPuaRequirements(productCode: string): boolean {
    return productCode === 'EPPUA' || productCode === 'APPUA'
  }
}
