export class MfaLoginRunner {
  static $inject = ['routerHelper']

  constructor (routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
    // console.log(myStates)
  }

  getStates () {
    return [{
      state: 'mfa',
      config: {
        url: '/mfa',
        component: 'mainMfaComponent',
        data: {
          // addToNavigation: true,
          secure: true,
          module: 'MfaComponent',
        },
        onEnter: ['$rootScope','CONSTANTS', function ($rootScope, CONSTANTS) {
          $rootScope.pageTitle = CONSTANTS.modules.MfaLogin.name
          $rootScope.mobileHeaderTitle = CONSTANTS.modules.MfaLogin.name
          // $rootScope.hideBarHeader = false;
          $rootScope.state = CONSTANTS.modules.MfaLogin.id
        }],
      },
    },
    {
      state: 'mfa.check',
      config: {
        url: '/check',
        component: 'mfaStatusCheck',
        params: {
          redirect_url: '',
        },
      },
    },
    {
      state: 'mfa.send-code',
      config: {
        url: '/send-code?firstTimeMfaUser&redirectUrl',
        component: 'mfaSendCode',
        params: {
          tokenType: '',
          redirectState: null,
        },
      },
    },
    {
      state: 'mfa.submit-code',
      config: {
        url: '/submit-code',
        component: 'mfaSubmitCode',
        params: {
          poc: null,
          method: '',
          redirectUrl: '',
          tokenType: '',
          redirectState: null,
        },
      },
    },
    {
      state: 'mfa.trust-browser',
      config: {
        url: '/trust-browser',
        component: 'mfaTrustBrowser',
        params: {
          redirectUrl: '',
        },
      },
    }]
  }
}
