import { TargetState, Transition, TransitionService } from "angular-ui-router"
import { MfaDataProvider } from "../../mfa/mfa-data-provider"
import { MFA_TOKEN_TYPES, MFA_UI_ROUTES } from "../../mfa"
import { IQService } from "angular"

export class ClientBeneEditRunner {
  static $inject = ['$transitions', 'routerHelper', 'configService']

  constructor($transitions: TransitionService, routerHelper: any, configService: any) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)

    $transitions.onBefore({ to: 'myPolicy.clientBeneEdit.**' }, (trans: Transition): any => {
      const mfaDataProvider: MfaDataProvider = trans.injector().get('mfaDataProvider')
      const $q: IQService = trans.injector().get('$q')
      const defer = $q.defer()

      if (!configService.features.selfServeBeneficiariesMFA) return

      void mfaDataProvider.checkMfaVerification(MFA_TOKEN_TYPES.CSSB_MFA_VERIFIED)
        .then((isCssbMfaVerified: boolean) => {
          if (isCssbMfaVerified) defer.resolve(true)

          const redirectState: TargetState = trans.router.stateService.target(MFA_UI_ROUTES.SEND_CODE, { firstTimeMfaUser: false, tokenType: MFA_TOKEN_TYPES.CSSB_MFA_VERIFIED, redirectState: trans.targetState() }, { location: true })

          defer.resolve(redirectState)
        })

      return defer.promise
    })
  }

  getStates(): any[] {
    const clientBeneAbstractRoute = {
      abstract: true,
      url: '/beneficiaries',
      template: '<ui-view></ui-view>',
      controller: 'clientBeneficiariesController',
      controllerAs: 'clientBeneMainCtrl',
      clientAppOnly: true,
      resolve: {
        policySummary: ['summaryResult', (summaryResult) => {
          return summaryResult.policy
        }],
        clientResults: ['clientResults', (clientResults) => {
          return clientResults.data
        }],
        beneficiaryMetaData: ['beneficiaryMetadata', (beneficiaryMetadata) => beneficiaryMetadata],
      },
    }

    const clientBeneList = {
      url: '/list',
      component: 'clientBeneListComponent',
      clientAppOnly: true,
      resolve: {
        whoAmI: ['whoAmI', (whoAmI) => whoAmI],
        policySummary: ['summaryResult', (summaryResult) => summaryResult.policy],
        beneficiaryMetadata: ['beneficiaryMetadata', (beneficiaryMetadata) => beneficiaryMetadata],
      },
    }
    const clientBeneEdit = {
      url: '/edit',
      component: 'clientBeneEditComponent',
      clientAppOnly: true,
      params: {
        bene: null,
      },
      resolve: {
        beneficiaryMetaData: ['beneficiaryMetadata', (beneficiaryMetadata) => beneficiaryMetadata],
      },
    }

    return [
      {
        state: 'myPolicy.clientBeneEdit',
        config: clientBeneAbstractRoute,
      },
      {
        state: 'myPolicy.clientBeneEdit.list',
        config: clientBeneList,
      },
      {
        state: 'myPolicy.clientBeneEdit.edit',
        config: clientBeneEdit,
      },
    ]
  }
}
