export class CredentialsController {

  static $inject: any = ['$scope', 'authService']

  bonusSchedFTSolicitingDocs: string[] = ['96FT', '9595', '1616', '01SR']
  bonusSchedEmergingDocs: string[] = ['19EA', '1919']
  docPermissions: any
  sectionData: any
  noCredentialDataAvailable: boolean
  isNotAgent: boolean
  isAgent: boolean

  constructor(private $scope, private authService) {}

  bonusScheduleDocs() {
    let docPermissions = {
      bonusSchedFTSoliciting: false,
      bonusSchedEmerging: false,
    }

    if (this.authService.isInRole('AGENT')) {
      this.sectionData?.contracts?.forEach((contract) => {
        if (this.bonusSchedFTSolicitingDocs.includes(contract.type)) {
          docPermissions.bonusSchedFTSoliciting = true
        }

        if (this.bonusSchedEmergingDocs.includes(contract.type)) {
          docPermissions.bonusSchedEmerging = true
        }
      })
    }

    return docPermissions
  }

  get showBonusDocs() {
    return this.isAgent && (this.docPermissions.bonusSchedFTSoliciting || this.docPermissions.bonusSchedEmerging)
  }

  get showCommissionTitle() {
    return this.sectionData.contracts.find((contract) => contract.commissionDocUrl)
  }

  $onInit() {
    this.$scope.dataCounts = {
      contracts: 0,
      licenses: 0,
      appointments: 0,
      trainings: 0,
    }

    this.sectionData = {}

    this.noCredentialDataAvailable = true

    // Check if the current user is able to viewAs and agent.
    this.isNotAgent = !this.authService.isInRole('AGENT')
    this.isAgent = !this.isNotAgent

    const clearDataCountsWatch = this.$scope.$on('PML_NG_LISTVIEW_DATA_READY', (_e, name, listViewCtrl) => {
      // Update the dataCounts for data source name received.
      this.$scope.dataCounts[name] = listViewCtrl.data.length
      this.sectionData[name] = listViewCtrl.data

      // Count the total data received to determine if there is
      // any credentials data available.
      const total = Object.keys(this.$scope.dataCounts).reduce((acc, itemName) => {
        const count = this.$scope.dataCounts[itemName]
        acc += count
        return acc
      }, 0)

      // Update the noCredentialDataAvailable flag accordingly
      this.noCredentialDataAvailable = total === 0
    })

    // Cleanup any watchers created by this controller.
    this.$scope.$on('$destroy', () => {
      if (typeof clearDataCountsWatch === 'function') clearDataCountsWatch()
    })
  }

  $doCheck() {
    this.docPermissions = this.bonusScheduleDocs()
  }

}

export const credentialsComponentConfig = {
  templateUrl: 'app/cl-and-r/tmpl/main-view.html',
  controller: CredentialsController,
  controllerAs: 'ctrl',
}
