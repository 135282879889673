import * as angular from 'angular'

import './bank-info-form.component'
import './banking-info-modal.component'
import { CreditCardFormController } from './cc-form.component'
import { BANK_ACCOUNT_TYPES, PAYMENT_MODE_TC, PAYMENT_METHODS, PENNCHECK_WITHDRAWAL_DATES } from './constants'
import {CreditCardDataProviderService} from './credit-card-data-provider-service'
import {PaymentOptionButtonsController} from './payment-option-buttons.component'

angular.module('app.components.payment', ['app.utils'])
  .service('creditCardDataProvider', CreditCardDataProviderService)
  .component('paymentOptionButtons', {
    bindings: {
      invalidPaymentForm: '<',  // ???
      acceptedPaymentMethods: '<',
      showAddNewCreditCardButton: '<',
      isPennCheck: '<',
      onSave: '&',
      initialPaymentAmount: '<',
      totalPaymentAmount: '<',
      creditCardButtonText: '<',
      achButtonText: '<',
    },
    templateUrl: 'app/components/payments/templates/payment-option-buttons.html',
    controller: PaymentOptionButtonsController,
    controllerAs: 'ctrl',
  })
  // .controller('creditCardFormController', CreditCardFormController)
  .component('creditCardForm', {
    bindings: {
      resolve: '<',
      modalInstance: '<',
    },
    templateUrl: 'app/components/payments/templates/cc-modal.html',
    controller: CreditCardFormController,
    controllerAs: 'ccCtrl',
  })
  .constant('CC_TEMPLATE_URLS', {
    MODAL: 'app/components/credit-card-payment/templates/cc-modal.html',
  })
  .constant('BANK_ACCOUNT_TYPES', BANK_ACCOUNT_TYPES)
  .constant('PAYMENT_MODE_TC', PAYMENT_MODE_TC)
  .constant('PAYMENT_METHODS', PAYMENT_METHODS)
  .constant('PENNCHECK_WITHDRAWAL_DATES', PENNCHECK_WITHDRAWAL_DATES)