// tslint:disable: object-literal-key-quotes

export const ROLE_DISPLAY_ORDER_OTHER = {
  order: 35,
  name: 'Other',
  color: 'red',
}

export const ROLE_DISPLAY_ORDER = {
  8: {
    order: 11,
    name: 'Owner',
    color: 'green',
  },
  27: {
    order: 21,
    name: 'Guardian',
    color: 'green',
  },
  31: {
    order: 17,
    name: 'Payor',
    color: 'green',
  },
  32: {
    order: 6,
    name: 'Primary Insured',
    color: 'orange',
  },
  34: {
    order: 26,
    name: 'Primary Beneficiary',
    color: 'blue',
  },
  35: {
    order: 1,
    name: 'Annuitant',
    color: 'orange',
  },
  36: {
    order: 27,
    name: 'First Contingent Beneficiary',
    color: 'blue',
  },
  40: {
    order: 10,
    name: 'Insured Dependent',
    color: 'orange',
  },
  57: {
    order: 36,
    name: 'Custodian',
    color: 'red',
  },
  59: {
    order: 20,
    name: 'Trustee of Minor',
    color: 'green',
  },
  69: {
    order: 19,
    name: 'Trustee',
    color: 'green',
  },
  78: {
    order: 15,
    name: 'Successor Owner',
    color: 'green',
  },
  113: {
    order: 24,
    name: 'Power of Attorney',
    color: 'green',
  },
  145: {
    order: 18,
    name: 'Collateral Assignee',
    color: 'green',
  },
  153: {
    order: 4,
    name: 'Contingent Annuitant',
    color: 'orange',
  },
  177: {
    order: 14,
    name: 'Contingent Owner',
    color: 'green',
  },
  183: {
    order: 3,
    name: 'Joint Annuitant',
    color: 'orange',
  },
  184: {
    order: 13,
    name: 'Joint Owner',
    color: 'green',
  },
  189: {
    order: 7,
    name: 'Joint Insured',
    color: 'orange',
  },
  205: {
    order: 22,
    name: 'Conservator',
    color: 'green',
  },
  227: {
    order: 30,
    name: 'Dividend Payee',
    color: 'red',
  },
  260: {
    order: 37,
    name: 'Primary Contact',
    color: 'red',
  },
  1012300003: {
    order: 29,
    name: 'Final Beneficiary',
    color: 'blue',
  },
  1012300004: {
    order: 28,
    name: 'Second Contingent Beneficiary',
    color: 'blue',
  },
  1012300005: {
    order: 23,
    name: 'Limited Power of Attorney',
    color: 'green',
  },
  1012300006: {
    order: 31,
    name: 'Recipient of Duplicate Bill',
    color: 'red',
  },
  1012300007: {
    order: 25,
    name: 'Recipient of Duplicate Lapse Notice',
    color: 'green',
  },
  1012300008: {
    order: 32,
    name: 'Tax Form Recipient',
    color: 'red',
  },
  1012300009: {
    order: 5,
    name: 'Step In Annuitant',
    color: 'orange',
  },
  1012300010: {
    order: 33,
    name: 'Payable To Alternate Addressee',
    color: 'red',
  },
  1012300013: {
    order: 8,
    name: 'Additional Insured',
    color: 'orange',
  },
  1012300014: {
    order: 9,
    name: 'Insured Child',
    color: 'orange',
  },
  1012300015: {
    order: 34,
    name: 'Alternate Addressee',
    color: 'red',
  },
  1012300016: {
    order: 2,
    name: 'Original Annuitant',
    color: 'orange',
  },
  1012300017: {
    order: 12,
    name: 'Original Owner',
    color: 'green',
  },
  1012300018: {
    order: 38,
    name: 'Duplicate Premium Bill Recipient',
    color: 'red',
  },
  1012300019: {
    order: 16,
    name: 'Final Owner',
    color: 'green',
  },
  2147483647: ROLE_DISPLAY_ORDER_OTHER,
}

