export {wgAlertCheckbox}

function wgAlertCheckbox() {
  return {
    restrict: 'EA',
    controller: WgAlertCheckbox,
    controllerAs: 'cb',
    scope: {
      'callbackParam': '=',
      'clickCallback': '&',
      'checked': '=?ngModel',
    },
    template: '<div class="wg-checkbox" ng-click="cb.click($event);"><div class="wg-checkbox-container"><div class="wg-checkbox-inner" ng-class="{\'checked icon-check\': cb.checked}"></div></div></div>',
    replace: true,
    link: function(scope, elem, attr, ctrl) {
      ctrl.onlyClickThis = attr.onlyClickThis !== undefined
      ctrl.checkboxGroupTrigger = attr.groupTrigger
      ctrl.checkboxGroupName = attr.group
      ctrl.init()
    },
    bindToController: true,
  }
}

WgAlertCheckbox.$inject = ['$rootScope', '$scope']

function WgAlertCheckbox($rootScope, $scope) {
  const self = this

  self.checked = self.checked || false

  self.set = function(val) {
    self.checked = val
    if (self.clickCallback) {
      self.clickCallback(self.callback)
    }
  }

  self.toggle = function() {
    self.set(!self.checked)
    return self.checked
  }

  self.click = function(e) {
    if (e && self.onlyClickThis) {
      e.stopPropagation()
    }
    self.toggle()
    _addGroupTrigger()
  }

  self.init = function() {
    _addGroupListener()
    if (!self.ngModel) {
      self.checked = false
    }
  }

  function _addGroupListener() {
    let deregisterGroupCallback

    if (!self.checkboxGroupName) return

    deregisterGroupCallback = $rootScope.$on(self.checkboxGroupName + '-event', function(e, val) {
      if (val) {
        if (!self.checked) {
          self.set(true)
        }
      } else if (self.checked) {
        self.set(false)
      }
    })

    $scope.$on('$destroy', function() {
      deregisterGroupCallback()
    })
  }

  function _addGroupTrigger() {
    if (!self.checkboxGroupTrigger) return
    $rootScope.$emit(self.checkboxGroupTrigger + '-event', self.checked)
  }
}
