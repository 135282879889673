
export class ModalService {
  static $injector: any = ['$uibModal']
  modalInstance: any

  constructor(private $uibModal) {}

  open(templateUrl, modalData, modalOptions) {
    const modalConfig = Object.assign({}, {
      animation: true,
      templateUrl: templateUrl,
      controller: 'modalInstanceCtrl',
      controllerAs: 'modalCtrl',
      // size: 'sm',
      resolve: { modalData: () => modalData },
    }, modalOptions)

    this.modalInstance = this.$uibModal.open(modalConfig)
  }

  openComponent(component, modalOptions, modalData?) {
    const modalConfig = Object.assign({}, {
      animation: true,
      component,
      backdrop: false,
      resolve: {
        modalData: () => modalData,
        modalCtrl: () => this,
      },
    }, modalOptions)

    return this.modalInstance = this.$uibModal.open(modalConfig)
  }
}
