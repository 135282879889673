/* eslint-disable no-underscore-dangle */
import { IUsStates } from '../../address/types'
import { BeneficiaryAddress } from '../classes/address'
import { BeneficiariesDataProviderService } from '../beneficiary-data-provider'
export class AddressController {
  static $inject = ['$sce', '$scope', 'CONSTANTS', '$element', 'beneficiariesDataProvider', 'authService', '$filter']

  addressInfo: BeneficiaryAddress
  addressForm: any
  isEditMode: boolean // This indicates that a newly created beneficiary is being edited.
  acordCountries: any[]
  usStates: IUsStates[]
  badText: any = {}
  showCountries: boolean
  addressValidated: boolean = false
  public addressErrorMessage: string = ''
  _deregisterFunctions: Function[] = []

  constructor(private $sce, public $scope, private CONSTANTS, public $element, private beneficiariesDataProvider: BeneficiariesDataProviderService, private authService, private $filter) {
    // console.info('countries are', acordCountries)
    // this.countryList = this.countryList.map((country) => new TypeCodeValue(country))
    this.usStates = this.CONSTANTS.usStates
  }

  clearAddress(): void {
    this.addressInfo = new BeneficiaryAddress()
  }

  get shouldValidateAddress(): boolean {
    return Boolean(this.addressInfo.zip5) && this.addressInfo.zip5.length === 5
  }

  get cityStateZipRequired(): boolean {
    return Boolean(!this.addressInfo.isClean && this.addressInfo.addressCountry.tc === '1')
  }


  $onInit(): void {
    if(this.showCountries === undefined) this.showCountries = true

    this._deregisterFunctions.push(this.$scope.$watch('addressCtrl.addressInfo.zip5', (a, b) => {
      if (this.shouldValidateAddress && a !== b) {
        void this.beneficiariesDataProvider.validate(this.addressInfo, true).then((result) => {
          this.addressInfo.city = this.$filter('capitalize')(result.zipCode.city)
          this.addressInfo.state = result.zipCode.state
          this.addressInfo.zip5 = result.zipCode.zip5
          this.addressInfo.zip4 = ''
          this.addressErrorMessage = ''
          this.$scope.$apply()

          if (!result.zipCode.zip5) {
            this.addressErrorMessage = 'We are unable to verify this zip code with the U.S. Postal Service.  Please try again.'
            this.$scope.$apply()
          }
        })
      }
    }))
  }

  // $onChanges(c) {
  //   if (!c.addressForm?.isFirstChange()) {
  //     this.addressForm.$setPristine()
  //     this.addressForm.$setUntouched()
  //   }
  // }

  $doCheck() {
    if (this.$scope.addressForm) {
      if (this.addressInfo.isClean && !this.addressInfo.nonReqFieldsClean) {
        this.$scope.addressForm.$setPristine()
        this.$scope.addressForm.$setUntouched()
        this.$scope.addressForm.$setValidity('required', false)
      } else if (this.addressInfo.isClean && this.addressInfo.nonReqFieldsClean) {
        this.$scope.addressForm.$setPristine()
        this.$scope.addressForm.$setUntouched()
        this.$scope.addressForm.$setValidity('required', true)
      }
    }
  }

  handleBadCharacters(invalidTextKey: string, invalidText: string): void {
    this.badText[invalidTextKey] = this.$sce.trustAsHtml(invalidText)
  }

  $onDestroy(): void {
    this._deregisterFunctions.forEach(fn => fn())
  }

}

export const roleAddressComponent = {
  bindings: {
    addressInfo: '=',
    addressForm: '<',
    isEditMode: '<',
    acordCountries: '<',
    showCountries: '<',
    // disableSaveButton: '<',
    // onEdit: '&',
    // onSave: '&',
    // onSubmit: '&',
    // onUndo: '&',
  },
  templateUrl: 'app/client/beneficiaries/address/address.html',
  controller: AddressController,
  controllerAs: 'addressCtrl',
}