export {compensationService}
compensationService.$inject = ['$http', 'partyService', 'utils', 'CONSTANTS']

/* @ngInject */
function compensationService($http, partyService, utils, CONSTANTS) {
  const format = 'yyyy-MM-dd'
  const service = {}

  function getFirstDateOfYear(year) {
    if (!year) return

    return year.toString() + '-01-01'
  }

  function getFirstDateOfMonth(month, year) {
    year = year || Date.today().getFullYear()
    if (month === 0) {
      month++
    }

    return year.toString() + '-' + month.toString() + '-01'
  }

  function getLastDateOfMonth(month, year) {
    const firstDateOfMonth = getFirstDateOfMonth(month, year)

    return Date.parse(firstDateOfMonth).moveToLastDayOfMonth().toString(format)
  }

  service.checkIfCompensationLast3Years = function(params) {
    const endYear = new Date().getFullYear()
    const startYear = endYear - 2

    const startDate = getFirstDateOfYear(startYear)
    const endDate = Date.today().toString(format) // getLastDateOfYear(endYear);

    params = params || {}
    params.pageNumber = params.pageNumber || 1

    return service.getCompensations({
      startDate: startDate,
      endDate: endDate,
      pageNumber: params.pageNumber,
    })
  }

  service.getCompensationsByMonth = function(params) {
    let startDate
    let endDate

    params = params || {}
    params.year = params.year || new Date().getFullYear()
    params.pageNumber = params.pageNumber || 1

    startDate = getFirstDateOfMonth(params.month, params.year)
    endDate = getLastDateOfMonth(params.month, params.year)

    return service.getCompensations({
      filters: params.filters,
      startDate: startDate,
      endDate: endDate,
      pageNumber: params.pageNumber,
    })
  }

  service.computeMostRecentMonthAndYear = function(results) {
    let modDatetime
    const today = new Date()

    if (results.documents.length) {
      modDatetime = new Date(results.documents[0].modDate)
      return {
        month: modDatetime.getMonth(),
        year: modDatetime.getFullYear(),
      }
    }

    return {
      month: today.getMonth(),
      year: today.getFullYear(),
    }
  }

  service.getCompensations = function(params) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'documents': [],
      'numFound': 0,
    }
    const docTypeQueryPrefix = '&docType='
    let url
    let promise

    params = params || {}
    params.pageNumber = params.pageNumber || 1

    if (params.filters && params.filters.length) {
      url = CONSTANTS.compensationFilterURLBase
      url += docTypeQueryPrefix + params.filters.join(docTypeQueryPrefix)
    } else {
      url = CONSTANTS.isDataServiceActive ? CONSTANTS.compensationURL : 'app/mock-api/compensation.json'
    }

    url = utils.appendURLParameter(url, 'startdate', params.startDate)
    url = utils.appendURLParameter(url, 'enddate', params.endDate)
    url = utils.appendURLParameter(url, 'page', params.pageNumber)
    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())

    promise = $http.get(url)
      .then(function(httpData) {
        if (httpData.data && httpData.data.documents) {
          result.documents = httpData.data.documents
          result.numFound = httpData.data.numFound

          return result
        }

        result.error = 'Undefined response'
        return result
      }, function(httpData) {
        utils.fillAndLogError(httpData, result)

        return result
      })

    return promise
  }

  service._apiResultDataStructure = function(error, month, year, documents) {
    return {
      error: error,
      month: {
        name: CONSTANTS.MONTHS[month],
        value: month,
        documents: [],
      },
      year: {
        name: year,
        months: [],
        isMonthSelectOpen: false,
      },
      documents: documents,
    }
  }

  service.getMostRecentCompensations = function(params) {
    return service.getCompensations(params)
      .then(function(result) {
        const mostRecent = service.computeMostRecentMonthAndYear(result)
        const mostRecentDocuments = result.documents.filter(service._mostRecentDocumentFilter.bind(this, mostRecent))

        return service._apiResultDataStructure(result.error, mostRecent.month, mostRecent.year, mostRecentDocuments)
      })
  }

  service.getRelevantAgentDocumentTypes = function() {
    const url = CONSTANTS.isDataServiceActive
      ? CONSTANTS.agentDocumentTypesURL
      : 'app/mock-api/relevant-agent-document-types.json'

    return $http.get(url)
  }

  service._mostRecentDocumentFilter = function(mostRecent, doc) {
    const monthString = utils.minTwoDigits(mostRecent.month + 1) + '/'
    const yearString = '/' + mostRecent.year

    return doc.modDate.indexOf(monthString) === 0 && doc.modDate.indexOf(yearString) === (doc.modDate.length - yearString.length)
  }

  return service
}
