export {dateUtils}

export const toDateString = function (inDate) {
  const tmpS = `${inDate.getFullYear()}-${String(inDate.getMonth() + 1).padStart(2, '0')}-${String(inDate.getDate()).padStart(2, '0')}`

  return tmpS
}

dateUtils.$inject = []

  /* @ngInject */
function dateUtils() {
  const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24
  const formats = ['dd-MMMM-yyyy', 'MM/dd/yyyy', 'dd.MM.yyyy', 'shortDate']
  const format = formats[1]

  const month = Date.parse('today').toString('MM')
  const year = Date.parse('today').toString('yyyy')
  const quarter = (Math.floor(month / 3)) + 1
  const lastQuarter = (quarter > 1) ? quarter - 1 : 4
  const lastFullWeekStart = Date.today().add(-7).days().last().monday().toString(format)
  const lastFullWeekEnd = Date.today().add(-7).days().last().monday().add(6).days().toString(format)
  const lastFullMonthStart = Date.today().add(-1).months().moveToFirstDayOfMonth().toString(format)
  const lastFullMonthEnd = Date.today().add(-1).months().moveToLastDayOfMonth().toString(format)
  const lastSixMonthsDayStart = Date.today().add(-6).months().moveToFirstDayOfMonth().toString(format)
  const lastSixMonthsDayEnd = Date.today().add(-1).months().moveToLastDayOfMonth().toString(format)
  const lastFullQuarterStart = ((((lastQuarter - 1) * 3) + 1) < 10) ? year + '-0' + (((lastQuarter - 1) * 3) + 1) + '-01' : year + '-' + (((lastQuarter - 1) * 3) + 1) + '-01'
  const lastFullQuarterEnd = Date.parse(lastFullQuarterStart).add(2).months().moveToLastDayOfMonth().toString(format)
  const lastFullYearStart = '01/01/' + Date.today().add(-1).years().toString('yyyy')
  const lastFullYearEnd = '12/31/' + Date.today().add(-1).years().toString('yyyy')

  const service = {}

  service.getActivityOptions = function() {
    return [{
      id: 'default',
      name: 'Select',
      startDate: null,
      endDate: null,
    },
    {
      id: 'last_week',
      name: 'Last Week',
      startDate: new Date(lastFullWeekStart),
      endDate: new Date(lastFullWeekEnd),
    },
    {
      id: 'last_month',
      name: 'Last Month',
      startDate: new Date(lastFullMonthStart),
      endDate: new Date(lastFullMonthEnd),
    },
    {
      id: 'last_quarter',
      name: 'Last Quarter',
      startDate: new Date(lastFullQuarterStart),
      endDate: new Date(lastFullQuarterEnd),
    },
    {
      id: 'last_6_months',
      name: 'Last 6 months',
      startDate: new Date(lastSixMonthsDayStart),
      endDate: new Date(lastSixMonthsDayEnd),
    },
    {
      id: 'last_year',
      name: 'Last Year',
      startDate: new Date(lastFullYearStart),
      endDate: new Date(lastFullYearEnd),
    },
    ]
  }

  service.dateDiffInDays = function(date1, date2) {
    let utc1
    let utc2

    if (!angular.isDate(date1) || !angular.isDate(date2)) {
      throw new Error('Invalid arguments, both argument must be valid Dates')
    }

    utc1 = service.discardTimeAndZone(date1)
    utc2 = service.discardTimeAndZone(date2)

    return Math.floor((utc2 - utc1) / DAY_IN_MILLISECONDS)
  }

  service.dateDiffMonthIndex = function(date1, date2) {
    const utc1 = service.discardTimeAndZone(date1)
    const utc2 = service.discardTimeAndZone(date2)
    const difference = Math.abs(utc1 - utc2)

    if (!difference) {
      return 0
    }

    return new Date(difference).getMonth()
  }

  service.discardTimeAndZone = function(date) {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  }

  service.isPastDueDate = function(date) {
    const dueDate = new Date(date)

    if (!date) {
      return
    }

    if (!angular.isDate(dueDate)) {
      return
    }

    return dueDate < new Date()
  }

  service.areSameCalendarDate = function(date1, date2) {
    const clonedDate1 = new Date(date1)
    const clonedDate2 = new Date(date2)

    clonedDate1.setHours(0, 0, 0, 0)
    clonedDate2.setHours(0, 0, 0, 0)

    return clonedDate1.getTime() === clonedDate2.getTime()
  }

  service.parseDate = function(date) {
    const d = new Date(date)

    if (!date) {
      return
    }

    if (!angular.isDate(d)) {
      return
    }

    return d
  }

  service.yyyymmdd = function(date) {
    let yyyy, mm, dd

    if (!date) return
    yyyy = date.getFullYear().toString()
    mm = (date.getMonth() + 1).toString() // getMonth() is zero-based
    dd = date.getDate().toString()

    return yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]) // padding
  }

  service.getYearsFrom = function(date) {
    let initialDateYear, initialDateMonth, initialDateDay, todayDate, todayYear, todayMonth, todayDay, yearsFrom

    if (!angular.isDate(date)) {
      return
    }

    initialDateYear = date.getFullYear()
    initialDateMonth = date.getMonth()
    initialDateDay = date.getDate()

    todayDate = new Date()
    todayYear = todayDate.getFullYear()
    todayMonth = todayDate.getMonth()
    todayDay = todayDate.getDate()

    yearsFrom = todayYear - initialDateYear

    if (todayMonth < initialDateMonth || (todayMonth === initialDateMonth && todayDay < initialDateDay)) {
      yearsFrom--
    }

    return yearsFrom
  }
  service.extendedAgeTo = function(date, end) {
    if (!date) return ''

    const dd = end.getDate() - date.getDate()
    let dm = end.getMonth() - date.getMonth()
    let dy = end.getFullYear() - date.getFullYear()

    if ((dy === 0 && dm <= 0) || dy < 0) {
      return 'Invalid Age'
    }

    if (dd < 0) dm -= 1
    if (dm < 0) {
      dm += 12
      dy -= 1
    }

    return dy + ' years, ' + dm + ' month' + (dm !== 1 ? 's' : '')
  }

  service.getLastWeek = function() {
    const today = new Date()
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

    return lastWeek
  }

  service.addDaysToDate = function(date, addedDays) {
    const originalDate = new Date(date)

    originalDate.setDate(originalDate.getDate() + addedDays)

    return originalDate
  }

  service.sortDocumentsInDescendingOrder = function(result) {
    result.documents.sort(function(a, b) {
      const date1 = new Date(a.modDate)
      const date2 = new Date(b.modDate)

      if (date1 > date2) return -1
      if (date1 < date2) return 1
      return 0
    })
  }

  service.formatDashJoinedDate = function(date) {
    const d = new Date(date)
    let monthString = String(d.getMonth() + 1)
    let dayString = String(d.getDate())
    const yearString = d.getFullYear()

    if (monthString.length < 2) monthString = '0' + monthString
    if (dayString.length < 2) dayString = '0' + dayString

    return [yearString, monthString, dayString].join('-')
  }

  service._monthiversary = function(effDateObject, now) {
    const monthiversary = new Date(now)
    const effDate = effDateObject.getDate()

    monthiversary.setHours(0, 0, 0, 0)
    monthiversary.setDate(effDate)

    return monthiversary
  }

  service.mostRecentMonthiversary = function(effDateObject, now) {
    const monthiversary = service._monthiversary(effDateObject, now)
    const todaysDate = now.getDate()
    const effDate = effDateObject.getDate()

    if (todaysDate < effDate) {
      monthiversary.setMonth(monthiversary.getMonth() - 1)
    }

    return monthiversary
  }

  return service
}
