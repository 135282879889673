(function() {
  'use strict'

  angular.module('app.directives').directive('wgAgentClientViewSwitch', [function() {
    return {
      restrict: 'EA',
      scope: {
        summary: '=',
      },
      templateUrl: 'app/widgets/templates/wg-agent-client-view-switch.html',
      controller: ['$state', 'stateTransitions', 'configService', wgAgentClientViewSwitchController],
      controllerAs: 'wgAgentClientViewSwitchCtrl',
      bindToController: true,
    }
  }])

  function wgAgentClientViewSwitchController($state, stateTransitions, configService) {
    const self = this

    self.showSwitchBar = showSwitchBar
    self.showClientView = showClientView
    self.showAdviserView = showAdviserView
    self.activeAdviserView = activeAdviserView
    self.activeClientView = activeClientView
    self.activeRolesView = activeRolesView
    self.isAnnuity = isAnnuity
    self.showRolesView = showRolesView
    self.showRolesTransactionHistory = showRolesTransactionHistory
    self.showRolesTransactionsLink = configService.features.showRolesTransactionsLink

    function showSwitchBar() {
      return ['policy', 'myPolicy', 'policy.roles', 'myPolicy.roles', 'policy.roles.list', 'myPolicy.roles.list'].includes($state.current.name)
    }

    function activeAdviserView() {
      return $state.current.name === 'policy'
    }

    function activeClientView() {
      return $state.current.name === 'myPolicy'
    }

    function activeRolesView() {
      return ['policy.roles', 'myPolicy.roles', 'policy.roles.list', 'myPolicy.roles.list'].includes($state.current.name)
    }

    function isAnnuity() {
      return self.summary.isAnnuity
    }

    function showClientView() {
      let params

      if (activeAdviserView() || activeRolesView()) {
        params = {
          id: self.summary.polNumber,
        }

        stateTransitions.go('myPolicy', params)
      }
    }

    function showAdviserView() {
      let params

      if (activeClientView() || activeRolesView()) {
        params = {
          id: self.summary.polNumber,
          policySource: self.summary.source,
        }

        stateTransitions.go('policy', params)
      }
    }

    function showRolesView() {
      stateTransitions.go('policy.roles.list', {id: self.summary.polNumber, policySource: self.summary.source})
    }

    function showRolesTransactionHistory() {
      stateTransitions.go('policy.roles.transactions.history')
    }
  }
})()
