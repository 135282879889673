LoginController.$inject = ['CONSTANTS', '$window', '$rootScope', '$cookies', '$state', '$stateParams', 'authService', 'configService']

function LoginController(CONSTANTS, $window, $rootScope, $cookies, $state, $stateParams, authService, configService) {
  const self = this

  self.LOGIN_CHANNELS = CONSTANTS.notificationType.login + ',' + CONSTANTS.notificationType.system + ',' + CONSTANTS.notificationType.systemUnavailability
  self.passwordInputShow = false
  self.params = $stateParams

  self.getLoginMessage = function(code) {
    if (CONSTANTS.loginErrors.hasOwnProperty(code)) {
      return CONSTANTS.loginErrors[code]
    }

    return 'Unknown error'
  }

  self.togglePasswordInputShow = function() {
    self.passwordInputShow = !self.passwordInputShow
  }

  self.oauthClient = function() {
    $window.location.href = CONSTANTS.authProxyURL + '/client'
  }

  self.forgotPassword = function() {
    window.location.href = configService.forgotPasswordURL
  }

  self.forgotUsername = function() {
    window.location.href = configService.forgotUsernameURL
  }

  self.register = function() {
    window.location.href = configService.registerURL
  }

  self.oauth = function() {
    let authURL

    if (CONSTANTS.isDataServiceActive) {
      authURL = CONSTANTS.authProxyURL + '/agent'
      $window.location.href = authURL
    } else if ($rootScope.auth.isClientView()) {
      $window.location.href = CONSTANTS.authProxyURL + '/client'
      $state.go('client.me')
    } else {
      $state.go('search.result')
    }
  }
}

export {LoginController}
