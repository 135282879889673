(function() {
  'use strict'

  wgClientPaymentAccountListingController.$inject = ['$scope', 'clientService', 'CONSTANTS', 'utils']

  function wgClientPaymentAccountListingController($scope, clientService, CONSTANTS, utils) {
    const self = this

    self.$onInit = function() {
      self.accounts.forEach(function(i) {
        i.deleteOpened = false
        i.editOpened = false
      })
    }

    self.deleteAccount = function(accountId) {
      self.loading = true
      clientService.deletePaymentAccount(accountId, false).then(self.processAccountDelete)
    }

    self.processAccountDelete = function(data) {
      if (!data.error && !data.errorMessage) {
        $scope.$emit('deleteAccountAlert', {
          type: CONSTANTS.eventType.success,
          message: CONSTANTS.accountResponses.accountDelete.description,
        })
        clientService.getPaymentAccounts(self.enableCreditCards).then(function(response) {
          self.accounts = response.data
        })
      }

      if (data.error && data.errorMessage.code === CONSTANTS.accountResponses.accountDeleteErrorPaymentPending.typeCode) {
        $scope.$emit('deleteAccountAlertErrorPendingPayment', {
          type: CONSTANTS.eventType.danger,
          message: CONSTANTS.accountResponses.accountDeleteErrorPaymentPending.description,
        })
        clientService.getPaymentAccounts(self.enableCreditCards).then(function(response) {
          self.accounts = response.data
        })
      }
      self.loading = false
    }

    self.initEdit = function(account) {
      account.editOpened = true
    }

    self.initDeleteAccount = function(account) {
      account.deleteOpened = true
    }

    self.cancelEdit = function(account) {
      account.editOpened = false
      account.deleteOpened = false
    }

    self.setDefaultAccount = function(accountId, account) {
      clientService.setDefaultAccount(accountId).then(self.processDefaultAccount)
      account.defaultAccount = true
    }

    self.processDefaultAccount = function(data) {
      if (!data.error && !data.errorMessage) {
        $scope.$emit('newDefaultAccountAlert', {
          type: CONSTANTS.eventType.success,
          message: CONSTANTS.accountResponses.newDefaultAccount.description,
        })
      }
    }

    self.getFullAccountType = function(account) {
      if (account.accountType && account.accountType === 'C') {
        return 'Checking'
      } else if (account.accountType && account.accountType === 'S') {
        return 'Savings'
      }

      return utils.capitalizeString(account.cardType)
    }
  }

  angular.module('app.directives')
    .controller('wgClientPaymentAccountListingController', wgClientPaymentAccountListingController)
    .directive('wgClientPaymentAccountListing', [function() {
      return {
        restrict: 'EA',
        templateUrl: 'app/widgets/templates/wg-client-payment-account-listing.html',
        controller: wgClientPaymentAccountListingController,
        controllerAs: 'accountListingCtrl',
        bindToController: true,
        scope: {
          accounts: '=accountsData',
          enableCreditCards: '<',
        },
      }
    }])
})()
