export {ChangeProfileController}
ChangeProfileController.$inject = ['$scope', '$http', '$state', 'CONSTANTS', 'mfaDataProvider', 'countryCodes' ,'modalService']

  /* @ngInject */
function ChangeProfileController($scope, $http, $state, CONSTANTS, mfaDataProvider, countryCodes, modalService) {
  const self = this

  self.submittedSuccessfully = false
  self.requestPending = false
  self.errorMessage = null
  self.closing = false
  self.pocList = []
  self.addPocErrorMessage = ''
  self.addPocIsOpen = false
  self.countryCodes = countryCodes

  self.form = {
    userId: '',
    name: '',
    email: '',
  }

  self.canSave = function() {
    if (!self.form.email) {
      return false
    }
    if (!self.form.phoneContact) {
      return false
    }

    return true
  }

  self.exit = function() {
    modalService.modalInstance.dismiss()
  }

  self.setProfile = function() {
    self.requestPending = true

    $http.put(CONSTANTS.clientProfileURL, self._mapFormToPayload(self.form))
        .then(self._setProfileSuccess)
        .catch(self._setProfileFailure)
  }

  self._setProfileSuccess = function(result) {
    self.requestPending = false
    self.submittedSuccessfully = true
    self.form = self._mapPayloadToForm(result)
  }

  self._setProfileFailure = function(result) {
    self.requestPending = false
    self.submittedSuccessfully = false
    self.errorMessage = 'We were unable to save your changes at this time. Please try again later.'
  }

  self.getProfile = function() {
    self.requestPending = true

    $http.get(CONSTANTS.clientProfileURL)
        .then(self._getProfileSuccess)
        .catch(self._getProfileFailure)
  }

  self._getProfileSuccess = function(result) {
    self.requestPending = false
    self.form = self._mapPayloadToForm(result)
  }

  self._getProfileFailure = function(result) {
    self.requestPending = false
    self.errorMessage = 'We were unable to fetch your profile at this time. Please try again later.'
  }

  self._mapPayloadToForm = function(payload) {
    return {
      name: payload.data.firstName + ' ' + payload.data.lastName,
      userId: payload.data.userId,
      email: payload.data.email,
    }
  }

  self._mapFormToPayload = function(form) {
    return {
      email: form.email,
    }
  }

  self.isInternationalNumber = function(countryCode) {
    return countryCode !== '1'
  }

  self.getPocList = function() {
    mfaDataProvider.listPointsOfContact()
        .then(self.processPocList)
  }

  self.processPocList = function(result) {
    self.pocList = result.slice(0, 5).map((poc) => {
      poc.deleteOpened = false
      poc.isInternational = self.isInternationalNumber(poc.info.countryCode)

      return poc
    })
  }

  self.deletePoc = function(pocGuid, $event) {
    $event.preventDefault()
    mfaDataProvider.deletePointOfContact(pocGuid).then(() => {
      self.getPocList()
    })
  }

  self.nonUs = function() {
    return self.countryCode && self.countryCode.name !== 'United States'
  }

  function stripPlus(dialCode) {
    return dialCode.replace('+', '')
  }

  self.addNewPoc = function(phoneNumber, countryCodeInfo, $event) {
    $event.preventDefault()
    const countryCode = stripPlus(countryCodeInfo.dial_code)

    mfaDataProvider.addPointOfContact({phoneNumber, countryCode}).then(() => {
      self.getPocList()
      self.addPocErrorMessage = ''
      self.addPocIsOpen = false
      $scope.addNewPoc = null
      self.submittedSuccessfully = true
    })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          self.addPocErrorMessage = error.data.message
        }
      })
  }

  self.initDeletePoc = function(poc, $event) {
    $event.preventDefault()
    poc.deleteOpened = true
  }

  self.cancelEdit = function(poc, $event) {
    $event.preventDefault()
    poc.deleteOpened = false
  }

  self.closeAlert = function() {
    self.submittedSuccessfully = false
  }

  function init() {
    self.getProfile()
    self.getPocList()
    self.countryCode = {'name': 'United States', 'dial_code': '+1', 'code': 'US', 'latitude': 38, 'longitude': -97}
  }

  init()
}

export const changePersonalInfoConfig = {
  templateUrl: 'app/profile/change-profile-view.html',
  controller: ChangeProfileController,
  controllerAs: 'ctrl',
}
