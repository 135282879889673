import { IHttpPromise, IHttpResponse, IQService } from 'angular'
import { GenericGatewayResponse, IIGWTransactionBatchPage, IIGWTransactionDetails, IIGWTransactionSummary, IPMLListViewPagedResult, IPageableRequestOptions, IIGWTransactionBatch } from './types'
import { BENEFICIARY_SOURCE_OBJECT_TYPES, Beneficiary } from '../client/beneficiaries/classes/beneficiary'
import { ALL_ROLE_LABELS } from '../client/beneficiaries/constants/ROLES'
import { GENDER_MAP } from '../client/beneficiaries/beneficiary-constants'
import { BeneficiariesDataProviderService } from '../client/beneficiaries/beneficiary-data-provider'
import { RELATION_UNDEFINED, RoleRelationshipsService } from '../client/beneficiaries/relationships/role-relationships-service'
import { testTransactionData } from '../mock-api/test-transaction-history'

export const ACTION_TYPE_CODES = {
  ADD_ROLE: '1012300010',
  UPDATE_ROLE: '1012300004',
  DELETE_ROLE: '1012300006',
  ADD_CLIENT: '1012300002',
  UPDATE_CLIENT: '1012300003',
}

export const WHITELIST_TYPES = ['Restate Beneficiaries', 'Address Change']

export class TransactionHistoryDataProvider {
  static $inject: string[] = ['$q', 'CONSTANTS', '$http', 'partyService', 'utils', 'clientUtils', '$filter', 'beneficiariesDataProvider', 'roleRelationshipsService']

  private countries: any = null

  // eslint-disable-next-line no-empty-function
  constructor(private $q: IQService, private CONSTANTS: any, private $http: ng.IHttpService, private partyService: any, private utils: any, private clientUtils: any, private $filter: any, private beneficiariesDataProvider: BeneficiariesDataProviderService, private roleRelationshipsService: RoleRelationshipsService) { }

  private getCountries() {
    if (!this.countries) {
      return this.beneficiariesDataProvider.getAccordCountries().then((countries) => this.countries = countries)
    }

    return this.$q.resolve(this.countries)
  }

  /**
   *
   * @param options
   */
  private resolveEndPointUrl(options: IPageableRequestOptions, subRoute: string): string {
    const filterKey: string = options?.filterKey ?? this.partyService.getAgentKey()
    const routeParams: string = options?.routeParams ? options.routeParams.reduce((result: string, param: string) => {
      result += `${param}/`

      return result
    }, '/').replace(/\/$/, '') : ''
    let url = `${this.CONSTANTS.apiRoot}transactions/${subRoute}${routeParams}`

    url = this.utils.appendURLParameter(url, 'filterKey', filterKey)

    // Add any custom query string parameters
    options?.queryParams?.forEach((value: string, key: string) => {
      url = this.utils.appendURLParameter(url, key, value)
    })

    if (options?.sort?.direction) {
      url = this.utils.appendURLParameter(url, 'sort', options.sort.name)
      url = this.utils.appendURLParameter(url, 'order', options.sort.direction.toLowerCase())
    }

    if (options?.page) {
      url = this.utils.appendURLParameter(url, 'skip', (options.page.page - 1) * options.page.size)
      url = this.utils.appendURLParameter(url, 'take', options.page.size)
    }

    return url
  }

  /**
   *
   * @param rawData
   * @returns
   */
  transactionHistoryPostProcessing(rawData: IIGWTransactionBatchPage): IPMLListViewPagedResult {
    const resp: IPMLListViewPagedResult = {
      data: [],
      totalItems: rawData.totalBatches,
    }

    rawData.page.forEach((entry: any) => {
      if (entry && !entry?.error) {
        entry.effectiveDate = new Date(entry.effectiveDate)
        entry.entryDate = new Date(entry.entryDate)
        entry.type = WHITELIST_TYPES.includes(entry.type) ? entry.type : 'Beneficiary Change'
        entry.parties = entry.parties.map((party) => {
          const expParties = this.clientUtils.expandParties([party])
          const expParty = expParties[0] || party // No parties to expand for Delete Role scenario
          const tmpParty = new Beneficiary(null, expParty, BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)
          if (tmpParty.role) {
            tmpParty.role.role = ALL_ROLE_LABELS[tmpParty?.role?.tc]
          }

          return tmpParty
        })

        resp.data.push(entry)
      }
    })

    return resp
  }

  /**
   *
   * @param party
   * @returns
   */
  updatePartyData(party: any) {
    party.role.role = ALL_ROLE_LABELS[party.role.tc]
    party.role.relationDescription = this.roleRelationshipsService.RELATIONSHIPS[party.role?.relationDescription?.tc] || RELATION_UNDEFINED
    party.ssn = party.ssn ? this.$filter('maskedSSN')(party.ssn) : ''

    if (party.role.address) party.role.address.addressCountry = this.countries.find((country) => country.tc === party.role.address?.addressCountry?.tc)

    return party
  }

  /**
   *
   * @param rawSnapshot
   * @param roleId
   * @returns
   */
  parseTransactionSnapshot(rawSnapshot: any, roleId: string): any {
    if (rawSnapshot) {
      if (!rawSnapshot.roleId) rawSnapshot.roleId = roleId
      // rawSnapshot.roleId = roleId
      const expandedParties = this.clientUtils.expandParties(rawSnapshot ? [rawSnapshot] : [])
      let foundParty = expandedParties.find((p) => p.role.tc === roleId) || expandedParties[0]

      if (rawSnapshot.genderTypeCode && !rawSnapshot.genderTypeCode?.value) rawSnapshot.genderTypeCode = GENDER_MAP[rawSnapshot.genderTypeCode.tc]
      if (rawSnapshot.params) rawSnapshot.params = JSON.parse(rawSnapshot.params)

      if (foundParty) return new Beneficiary(null, this.updatePartyData(foundParty), BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)
    }

    return null
  }

  /**
   *
   * @param trans
   * @returns
   */
  resolvePartyByRole(trans: IIGWTransactionDetails): any {
    let expandedParties
    let tmpParty

    switch (trans.actionTypeTc) {
      case ACTION_TYPE_CODES.ADD_CLIENT:
        expandedParties = this.clientUtils.expandParties([trans.party])
        break
      case ACTION_TYPE_CODES.ADD_ROLE:
        expandedParties = this.clientUtils.expandParties([trans.afterSnapshot])
        break
      case ACTION_TYPE_CODES.DELETE_ROLE:
        trans.afterSnapshot = null
        trans.beforeSnapshot = trans.party
        expandedParties = this.clientUtils.expandParties([trans.beforeSnapshot])
        break
      default:
        expandedParties = this.clientUtils.expandParties([trans.party])
    }

    tmpParty = trans.partyRole ? expandedParties.find((party) => party.role.tc === trans.partyRole) : expandedParties[0]

    if (!tmpParty) tmpParty = expandedParties[0]

    return tmpParty
  }


  /**
   * This provider function is called by the PML List View Component, which allows
   * for sortable and pageable requests.
   *
   * @param options
   * @returns
   */
  getTransactionHistoryPage(options: any): any {
    const pageableOptions: IPageableRequestOptions = {
      page: options.page,
      queryParams: new Map<string, string>().set('pid', options.pid),
    }
    const url = this.resolveEndPointUrl(pageableOptions, "history/v2")
    // const url = testTransactionData

    return this.$http.get(url).then((result: any) => this.transactionHistoryPostProcessing(result.data)) as IHttpPromise<IPMLListViewPagedResult>
    // const rawData: any = { page: testTransactionData, totalBatches: 10 }

    // return this.$q.resolve(this.transactionHistoryPostProcessing(rawData))
  }

  /**
 * This provider function is called by the PML List View Component, which allows
 * for sortable and pageable requests.
 *
 * @param options
 * @returns
 */
  getTransactionClientHistoryPage(options: any): IHttpPromise<IPMLListViewPagedResult> {
    const pageableOptions: IPageableRequestOptions = {
      page: options.page,
      queryParams: new Map<string, string>().set('cid', options.cid),
    }
    const url = this.resolveEndPointUrl(pageableOptions, 'history/client')

    return this.$http.get(url).then((result: any) => this.transactionHistoryPostProcessing(result.data)) as IHttpPromise<IPMLListViewPagedResult>
  }

  /**
   *
   * @param batchId
   * @returns
   */
  getTransactionSummary(batchId: string): IHttpPromise<Array<IIGWTransactionSummary>> {
    const options: IPageableRequestOptions = {
      routeParams: [batchId],
    }
    const url = this.resolveEndPointUrl(options, "summary")

    return this.$http.get(url)
      .then((result: any): IHttpResponse<Array<IIGWTransactionSummary>> => result.data)
      .then((transactionSummary: any): any => {
        const resp: GenericGatewayResponse = { error: false, data: transactionSummary }

        try {
          resp.data = transactionSummary.reduce((acc, transaction) => {
            const expandedParties = this.clientUtils.expandParties([transaction.party])
            const party = expandedParties.find((roleParty) => roleParty.role?.tc === transaction.partyRole || Object.keys(ALL_ROLE_LABELS).includes(roleParty.role?.tc))

            if (party) {
              party.role.role = ALL_ROLE_LABELS[party.role?.tc]
              transaction.party = new Beneficiary(null, party, BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)
              transaction.effectiveDate = new Date(transaction.effectiveDate)
              transaction.entryDate = new Date(transaction.entryDate)

              acc.push(transaction)
            }

            return acc
          }, [])
        } catch (err) {
          console.error(err)
          resp.error = true
        }

        return resp
      })


  }


  /**
   *
   * @param transId
   * @param clientId
   * @returns
   */
  getTransactionDetail(transId: string, clientId: string): any {
    const options: IPageableRequestOptions = {
      routeParams: [transId, clientId],
    }
    const url = this.resolveEndPointUrl(options, "detail/v2")

    return this.getCountries()
      .then(() => this.roleRelationshipsService.load())
      .then(() => this.$http.get(url)).then((result) => {
        const resp: GenericGatewayResponse = { error: false, data: result.data }

        try {
          const tmp: IIGWTransactionDetails = resp.data as IIGWTransactionDetails

          tmp.status = tmp.afterSnapshot.status

          if (tmp.party) tmp.party = new Beneficiary(null, this.clientUtils.expandParties([tmp.party])[0], BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)

          tmp.effectiveDate = new Date(tmp.effectiveDate)
          tmp.entryDate = new Date(tmp.entryDate)

          if (tmp.beforeSnapshot.party) tmp.beforeSnapshot = new Beneficiary(null, this.clientUtils.expandParties([tmp.beforeSnapshot.party])[0], BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)
          if (tmp.afterSnapshot.party) tmp.afterSnapshot = new Beneficiary(null, this.clientUtils.expandParties([tmp.afterSnapshot.party])[0], BENEFICIARY_SOURCE_OBJECT_TYPES.CURRENT_BENEFICIARY)

          if (!(tmp.beforeSnapshot instanceof Beneficiary) && !(tmp.actions.includes('Add Role') || tmp.actions.includes('Add Client'))) {
            resp.error = true
          }
          if (!(tmp.afterSnapshot instanceof Beneficiary) && !tmp.actions.includes('Delete Role')) resp.error = true
        } catch (err) {
          console.error(err)
          resp.error = true
        }


        return resp
      })
  }
}
