(function() {
  'use strict'

  angular
    .module('app.directives')
    .directive('wgSwipeContainer', wgSwipeContainer)

  wgSwipeContainer.$inject = []

  function wgSwipeContainer() {
    return {
      restrict: 'E',
      controller: ['$scope', WgSwipeContainer],
    }

    function WgSwipeContainer($scope) {
      const self = this

      self.numOptions = 0
    }
  }
})()
