export {correspondenceService}

correspondenceService.$inject = ['$http', 'utils', 'CONSTANTS', 'deviceUtils', 'authService']

  /* @ngInject */
function correspondenceService($http, utils, CONSTANTS, deviceUtils, authService) {
  const service = {}

  service.correspondencesDelivered = false

  service._getPidsArrayUrlParameters = function(pidsArray) {
    let urlParam = ''
    let i

    for (i = 0; i < pidsArray.length; i++) {
      urlParam += (urlParam ? '&' : '') + 'pid=' + pidsArray[i]
    }
    return urlParam
  }

  service.getCorrespondences = function(pidsArray) {
    return service.getFilteredCorrespondences(pidsArray, [])
  }

  service.getFilteredCorrespondences = function(pidsArray, filterArray, bypassSign) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'documents': [],
    }

    const urlParam = service._getPidsArrayUrlParameters(pidsArray)
    let url
    let promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.correspondencesURL.replace('{pids}', urlParam) : 'app/mock-api/correspondences.json'

    if (filterArray && filterArray.length > 0) {
      url = url + '&docType=' + filterArray.join('&docType=')
    }

    if (bypassSign) url = utils.appendURLParameter(url, 'bypassSign', true)

    promise = $http.get(url)
        .then(function(httpData) {
          if (httpData.data && httpData.data.documents) {
            result.documents = httpData.data.documents.map(function(doc) {
              // Dirty hack because the back end can't make up its mind. See ATCS-2141
              if (doc.docType === 'Bills') {
                doc.docType = 'Bill'
              }
              return doc
            })
            sortDocumentsInDecendingOrder(result)

            service.correspondencesDelivered = true
            return result
          }

          result.error = 'Undefined response'
          service.correspondencesDelivered = true
          return result
        })
        .catch(function(httpData) {
          utils.fillAndLogError(httpData, result)

          service.correspondencesDelivered = true
          return result
        })

    return promise
  }

  service.getRelevantDocumentTypes = function(pidsArray) {
    const urlParam = service._getPidsArrayUrlParameters(pidsArray)
    const url = CONSTANTS.isDataServiceActive
      ? CONSTANTS.documentTypesURL.replace('{pids}', urlParam)
      : 'app/mock-api/relevant-correspondence-document-types.json'

    return $http.get(url)
  }

  service.showCorrespondence = function(docId) {
    let url

    if (docId) {
      url = CONSTANTS.correspondencesDownloadURL.replace('{0}', docId).replace('{1}', authService.getSessionID())

      if (deviceUtils.isMobilePlatform()) {
        window.open(url, '_system')
      } else {
        window.open(url, '_blank')
      }
    }
  }

  service.getClassByType = function(type) {
    const lowercaseType = type.toLowerCase()

    return CONSTANTS.correspondenceType[lowercaseType] || 'icon-letter'
  }

  service.fillSpecialDocIDs = function(documents, specialDocIDs) {
    let i
    let docType

    if (documents && documents.length > 0) {
      for (i = 0; i < documents.length; i++) {
        docType = documents[i].docType.toLowerCase()

        if (!specialDocIDs.applicationDocId && docType === 'new business application') {
          specialDocIDs.applicationDocId = documents[i].docId

          if (specialDocIDs.illustrationDocId && specialDocIDs.policyPagesDocId) return
        } else if (!specialDocIDs.illustrationDocId && docType === 'illustration') {
          specialDocIDs.illustrationDocId = documents[i].docId

          if (specialDocIDs.applicationDocId && specialDocIDs.policyPagesDocId) return
        } else if (!specialDocIDs.policyPagesDocId && docType === 'policy pages') {
          specialDocIDs.policyPagesDocId = documents[i].docId

          if (specialDocIDs.applicationDocId && specialDocIDs.illustrationDocId) return
        }
      }
    }
  }

  function sortDocumentsInDecendingOrder(result) {
    result.documents.sort(function(a, b) {
      const date1 = new Date(a.modDate)
      const date2 = new Date(b.modDate)

      if (date1 > date2) return -1
      if (date1 < date2) return 1
      return 0
    })
  }

  return service
}
