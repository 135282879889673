/* eslint-disable handle-callback-err */
export {clientMeService}

clientMeService.$inject = ['$http', 'clientService', 'utils', 'CONSTANTS', '$q']

/* @ngInject */
function clientMeService($http, clientService, utils, CONSTANTS, $q) {
  const service = {}

  service.getUnpaidPolicyIds = function() {
    return $http.get(CONSTANTS.isDataServiceActive ? CONSTANTS.clientActionsURL : 'app/mock-api/client-actions.json')
      .then(function(httpData) {
        if (httpData.data && httpData.data.actions) {
          return service._initialPaymentPolicyIds(httpData.data.actions)
        }

        return []
      })
  }

  service.getClientPolicies = function(page) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'client': {},
    }

    return service._getClientPolicies(result, page)
  }

  service.initialPaymentPolicyId = function(client) {
    if (service._clientHasOnlyOneUnpaidPolicy(client)) {
      return client.unpaidPolicyIds[0]
    }

    return null
  }

  service._clientHasOnlyOneUnpaidPolicy = function(client) {
    return Boolean(
      (!client.policies || !client.policies.length) &&
      client.unpaidPolicyIds &&
      client.unpaidPolicyIds.length === 1
    )
  }

  service._getClientPolicies = function(result, page) {
    let url

    page = page || 1

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.clientPoliciesURL.replace('{0}', page) : 'app/mock-api/client-my-policies.json'

    return $http.get(url).then(
      service._resolveClientPolicies.bind(service, result),
      function(httpData) {
        utils.fillAndLogError(httpData, result)
        return result
      })
  }

  service._getPartyInfo = function(policyResults, index) {
    let partyInfoUrl

    partyInfoUrl = CONSTANTS.isDataServiceActive ? CONSTANTS.clientPartyInfoURL : 'app/mock-api/client-party-info.json'

    return $http.get(partyInfoUrl).then(
      function(httpData2) {
        if (httpData2.data) {
          if (!policyResults) {
            policyResults = {
              client: {
                policies: [],
                policyCount: 0,
              },
              error: '',
              status: '',
              statusText: '',
            }
          }
          angular.merge(policyResults.client, clientService.processClientInfo(httpData2.data, policyResults.insuredSince))

          return policyResults
        }

        policyResults.error = 'Undefined response'
        return policyResults
      },
      function(httpData2) {
        utils.fillAndLogError(httpData2, policyResults)
        return policyResults
      })
  }

  service.partyInfo = null

  service.getWhoAmI = function() {
    const partyInfoUrl = CONSTANTS.isDataServiceActive ? CONSTANTS.clientPartyInfoURL : 'app/mock-api/client-party-info.json'

    return $http.get(partyInfoUrl).then((result) => {
      return service.partyInfo = result.data
    })
  }

  service._combinePoliciesAndInitialPayments = function(info, unpaidPolicyIds) {
    info.client.unpaidPolicyIds = unpaidPolicyIds

    return info
  }

  service._hasInitialPaymentAction = function(policyActions) {
    return Boolean(policyActions.filter(function(policyAction) {
      return policyAction.tc === '7'
    }).length)
  }

  service._initialPaymentClientActions = function(clientActions) {
    return clientActions.filter(function(clientAction) {
      const policyActions = clientAction.actions

      return service._hasInitialPaymentAction(policyActions)
    })
  }

  service._initialPaymentPolicyIds = function(clientActions) {
    return service._initialPaymentClientActions(clientActions).map(function(action) {
      return action.pid
    })
  }

  service._resolveClientPolicies = function(result, httpData) {
    let processedResults

    if (httpData.data) {
      result.client.policiesInfo = httpData.data
      if (httpData.data.count && httpData.data.count > 0) {
        processedResults = clientService.processPolicies(httpData.data.policies ? httpData.data.policies : [])
        result.client.policies = processedResults.policies
        result.client.insuredSince = processedResults.insuredSince
        result.client.policyCount = httpData.data.count
        return result
      }
      result.client.insuredSince = null
      result.client.policies = []
      result.client.policyCount = 0
      return result
    }
    result.error = 'Undefined response'
    return result
  }

  service.getClientInfo = function(index) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'client': {},
    }

    // TODO these three API calls could one day be handled by one call to our middle-tier.
    return service._getClientPolicies(result, index)
      .then(function(policiesResult) {
        const infoPromise = service._getPartyInfo(policiesResult, index)
          .catch(function(err) {
            console.error('Failed to retrieve PartyInfo; continuing')
            return policiesResult
          })

        const unpaidsPromise = service.getUnpaidPolicyIds()
          .catch(function(err) {
            console.error('Failed to retrieve UnPaidPolicyIds; continuing')
            return []
          })

        return $q.all([infoPromise, unpaidsPromise])
      })

      .then(([info, unpaidPolicyIds]) => service._combinePoliciesAndInitialPayments(info, unpaidPolicyIds))
  }

  service.getMyAgents = function() {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'agents': [],
    }

    const myAgentsUrl = CONSTANTS.isDataServiceActive ? CONSTANTS.clientMyAgentsURL : 'app/mock-api/client-my-agents.json'

    const promise = $http.get(myAgentsUrl).then(
      function(httpData) {
        if (httpData.data && httpData.data.agentList && httpData.data.agentList.length > 0) {
          result.agents = httpData.data.agentList

          return result
        }

        result.error = 'Undefined response'
        return result
      },
      function(httpData) {
        utils.fillAndLogError(httpData, result)
        return result
      })

    return promise
  }

  service.getAceInfo = function() {
    const result = {
      'aceInfo': '',
    }

    const isAceClientUrl = CONSTANTS.isDataServiceActive ? CONSTANTS.clientAceInfoURL : 'app/mock-api/client-my-agents.json'

    const promise = $http.get(isAceClientUrl).then(
      function(httpData) {
        if (httpData.data) {
          return httpData.data
        }

        result.error = 'Undefined response'
        return result
      }
    )

    return promise
  }

  service.getPayablePolicies = function() {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'data': {},
    }
    let url
    let promise

    url = CONSTANTS.isDataServiceActive ? CONSTANTS.clientActionsURL : 'app/mock-api/clients.json'

    promise = $http.get(url)
      .then(function(httpData) {
        if (httpData.data) {
          result.data = httpData.data ? httpData.data : {}

          return result
        }

        result.error = 'Undefined response'

        return result
      }, function(httpData) {
        utils.fillAndLogError(httpData, result)

        return result
      })

    return promise
  }

  service.getAddresses = function() {
    const url = CONSTANTS.clientAddressesURL

    return $http.get(url)
  }

  return service
}
