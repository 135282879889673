export {deviceUtils}

deviceUtils.$inject = []

  /* @ngInject */
function deviceUtils() {
  const service = {}

  service.isMobilePlatform = function() {
    return window.isMobilePlatform
  }

  service.isAppleDevice = function() {
    return /iPad|iPhone|iPod/.test(navigator.platform)
  }

  service.isMobileFormfactor = function() {
    return /iPhone|Android/.test(navigator.userAgent)
  }

  function init() {
  }

  init()

  return service
}
