/* eslint-disable no-underscore-dangle */
import { Beneficiary } from './beneficiary'
import { PhoneNumber } from './phone'
import { DEFAULT_PHONE, PHONE_TYPES } from '../constants/PHONE_TYPES'

export class PhoneList {
  public sorted: PhoneNumber[]
  // public primaryPhone: PhoneNumber

  private _phoneList: PhoneNumber[] = []
  private _originalPhoneList: PhoneNumber[] = []

  constructor(rawPhoneNumbers: any[] | PhoneList) {
    // console.log('rawPhoneNumbers.constructor.name', rawPhoneNumbers?.constructor.name)

    if (!rawPhoneNumbers) {
      this.add() // Add initial blank phone
      return
    }

    switch (rawPhoneNumbers?.constructor.name) {
      case 'PhoneList':
        (rawPhoneNumbers as PhoneList).all.forEach((phone: any) => this.add(phone))
        break
      case 'Array':
        const asArray = (rawPhoneNumbers as any[])

        if (asArray.length === 0) {
          this.add(DEFAULT_PHONE)
        } else {
          asArray.forEach((phone: any) => this.add(phone))
        }
        break
    }

    this.sorted = this._sorted()

    // Cloning for later use.
    this._originalPhoneList = this._phoneList.map((phone) => new PhoneNumber(phone, phone.index))
  }

  get all() {
    return this._phoneList
  }

  _sorted() {
    const phones = this._phoneList.map((phone: PhoneNumber) => {
      const type = PHONE_TYPES.find((pType) => pType.tc === phone.type.tc)

      if (type) {
        phone.order = type.order
      } else {
        phone.order = 8
      }

      return phone
    })

    const sort1 = phones.sort((phoneA, phoneB) => {
      return phoneA.order - phoneB.order
    })

    // this.primaryPhone = sort1[0]

    return sort1
  }

  get primaryPhone() {
    return this._phoneList.find((phone) => !phone.markedForDeletion)
  }

  get hasExistingNumbers() {
    return this.adjustedCount > 1 || (this.adjustedCount === 1 && !this._phoneList[0].isEmpty)
  }

  get count() {
    return this._phoneList.length
  }

  get adjustedCount() {
    return this._phoneList.filter((p) => !p.isEmpty && !p.markedForDeletion).length
  }

  get hasDeleted() {
    return this._phoneList.some((phone) => phone.markedForDeletion)
  }

  get isDirty() {
    let changed: boolean = false

    this._phoneList.forEach((phone) => {
      if (!phone.isEmpty) {
        const found = this._originalPhoneList.find((tPhone: PhoneNumber) => tPhone.index === phone.index)

        if (!changed && found && phone.hasChange(found)) {
          changed = true
        } else if (!changed && !found && phone.isNew && !phone.isEmpty) {
          changed = true
        }
      }
    })

    if (changed) return true

    const tmp = this._phoneList.filter((phone) => !phone.markedForDeletion && !phone.isNew && !phone.isEmpty)

    return this._originalPhoneList.length === 1 && this._originalPhoneList[0].isEmpty ? false : this._originalPhoneList.length !== tmp.length
  }

  compare(targetPhoneList: PhoneList) {
    let changed: boolean = false

    this._phoneList.forEach((phone) => {
      const found = targetPhoneList.all.find((tPhone: PhoneNumber) => tPhone.id === phone.id)

      if (!changed && found && phone.hasChange(found)) {
        changed = true
      } else if (!changed && !found) {
        changed = true
      }
    })

    return changed
  }

  hasChanges(originalBene: Beneficiary) {
    if (!this.hasExistingNumbers) return true

    return this.compare(originalBene.phoneList)
  }

  add(phone?: any): PhoneNumber {
    let newPhone

    if (phone instanceof PhoneNumber) {
      newPhone = new PhoneNumber(phone, this._phoneList.length)
    } else if (typeof phone === 'undefined') {
      newPhone = new PhoneNumber(DEFAULT_PHONE, this._phoneList.length)
    } else {
      newPhone = new PhoneNumber(phone, this._phoneList.length)
    }

    this._phoneList.push(newPhone)
    this.sorted = this._sorted()

    return newPhone
  }

  remove(targetPhone: PhoneNumber) {
    // console.log('PhoneList::remove', targetPhone)

    if (targetPhone.isNew) {
      this._phoneList = this._phoneList.filter((phone: PhoneNumber) => targetPhone.index !== phone.index)

      if (this.count === 0) this.add() // Add new empty phone

      this.sorted = this._sorted()
    } else {
      targetPhone.markedForDeletion = true
    }
  }

  private get _newNumbers(): PhoneNumber[] {
    return this._phoneList.filter((phone: PhoneNumber): boolean => phone.isNew && !phone.isEmpty)
  }

  private get _updatedNumbers(): PhoneNumber[] {
    return this._phoneList.filter((phone: PhoneNumber): boolean => phone.isExisting && phone.hasChange(this._originalPhoneList[phone.index]))
  }

  private get _deletedNumbers(): PhoneNumber[] {
    return this._phoneList.filter((phone: PhoneNumber): boolean => phone.markedForDeletion)
  }

  serialize() {
    return {
      adds: this._newNumbers.map((phone: PhoneNumber) => phone.serialize()),
      updates: this._updatedNumbers.map((phone: PhoneNumber) => phone.serialize()),
      deletes: this._deletedNumbers.map((phone: PhoneNumber) => phone.serialize()),
    }
  }

  transform(): any {
    const tmp = this._phoneList.filter((phone: PhoneNumber) => !phone.isEmpty && !phone.markedForDeletion)
    const transformed: PhoneNumber[] = tmp.map((phone: PhoneNumber) => {
      const transformedPhone: any = phone.transform()

      // eslint-disable-next-line id-denylist
      transformedPhone.number = phone.completeNumber
      transformedPhone.type = phone.type.value
      transformedPhone.tc = phone.type.tc
      transformedPhone.typeCode = phone.type.transform()
      transformedPhone.countryCode = phone.countryCode?.tc  ? '+' + phone.countryCode?.tc : ''

      return transformedPhone
    })

    return JSON.parse(JSON.stringify(transformed))
  }
}
