export {appRun}

appRun.$inject = ['routerHelper']

  /* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getStates())
}

function getStates() {
  return [{
    state: 'client',
    config: {
      abstract: true,
      url: '',
      template: '<ui-view></ui-view>',
    },
  },
  {
    state: 'clientAlerts',
    config: {
      url: '/clientAlerts/{id}',
      templateUrl: 'app/client/client-alerts-view.html',
      controller: 'ClientAlertController',
      controllerAs: 'clientAlertCtrl',
      resolve: {
        apiResult: ['$stateParams', 'clientAdvisorService',
          function($stateParams, clientAdvisorService) {
            const id = $stateParams.id

            return clientAdvisorService.getClientInfo(id)
          },
        ],
        policies: ['$stateParams', 'clientAdvisorService',
          function($stateParams, clientAdvisorService) {
            const id = $stateParams.id

            return clientAdvisorService.getClientInfo(id).then(function(r) {
              if (r.error !== '') {
                return r
              }
              return r.client.policies
            })
          },
        ],
      },
      onEnter: ['$rootScope', function($rootScope) {
        const pageTitle = 'Client Alert List'

        $rootScope.pageTitle = pageTitle
        $rootScope.mobileHeaderTitle = pageTitle
        $rootScope.state = 'client-alerts'
        $rootScope.isPopupVisible = false
      }],
    },
  },
  {
    state: 'clientAlerts.detail',
    config: {
      url: '/clientAlerts/detail/{aid}',
      templateUrl: 'app/client/client-alerts-detail.html',
      resolve: {
        alertDetailsResult: ['alertService', '$stateParams',
          function(alertService, $stateParams) {
            return alertService.getAlertDetails($stateParams.aid)
          },
        ],
      },
      controller: ['$scope', 'alertDetailsResult', function($scope, alertDetailsResult) {
        $scope.alertDetailsResult = alertDetailsResult
      }],
      onEnter: ['$rootScope', function($rootScope) {
        $rootScope.pageTitle = 'Client Alert Detail'
        $rootScope.mobileHeaderTitle = 'Client Alert Detail'
        $rootScope.state = 'client-alerts-detail'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', function($rootScope) {
        const pageTitle = 'Client Alert List'

        $rootScope.pageTitle = pageTitle
        $rootScope.mobileHeaderTitle = pageTitle
      }],
    },
  },
  ]
}
