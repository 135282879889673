export {ridersHelper}
ridersHelper.$inject = ['CONSTANTS', 'policyDetailRidersModels']

/* @ngInject */
function ridersHelper(CONSTANTS, policyDetailRidersModels) {
  const service = {}

  service.populateSections = function(summary, coverages, clients, base, tables) {
    const sections = []

    sections.push(new policyDetailRidersModels.GeneralInfo(summary, coverages, clients, base))

    coverages.forEach(function(coverage) {
      const section = getSection(coverage, base, summary, clients, tables)

      sections.push(section)
    })

    return sections
  }

  return service

  function getSection(coverage, base, summary, clients, tables) {
    switch (coverage.typeCode) {
      case 'Guaranteed Minimum Accumulation Benefit':
        return new policyDetailRidersModels.GuaranteedMinimumAccumulationBenefit(coverage)

      case 'Guaranteed Minimum Withdrawal Benefit (GMWB)':
        return new policyDetailRidersModels.GuaranteedMinimumWithdrawalBenefit(coverage, summary, clients, tables)

      case 'Death Benefit':
        return getDeathBenefitSection(coverage, base, summary)

      default:
        throw new Error('Unable to retrieve section with given typeCode: ' + coverage.typeCode)
    }
  }

  function getDeathBenefitSection(coverage, base, summary) {
    switch (coverage.riderCode) {
      case CONSTANTS.RIDER_CODES.GIP8:
      case CONSTANTS.RIDER_CODES.PPP9:
      case CONSTANTS.RIDER_CODES.GIPY:
      case CONSTANTS.RIDER_CODES.PPPZ:
        return new policyDetailRidersModels.EnhancedDeathBenefit(coverage, base, summary)

      case CONSTANTS.RIDER_CODES.INFL:
      case CONSTANTS.RIDER_CODES.GGDB:
      case CONSTANTS.RIDER_CODES.EHDB:
      case CONSTANTS.RIDER_CODES.IPWB:
      case CONSTANTS.RIDER_CODES.IPDB:
        return new policyDetailRidersModels.GuaranteedMinimumDeathBenefit(coverage, base, summary)
    }
  }
}
