  /* @ngInject */
function eventsService($rootScope, $filter, notificationCenterService, dateUtils, CONSTANTS, userAccountService) {
  const service = {}

  function getTimeUntilInPlainEnglish(date, options) {
    let today, birthDay, days, months

    options = options || {
      countMonths: false,
    }

    today = new Date()

    date = new Date(date)
    birthDay = new Date(today.getFullYear(), date.getMonth(), date.getDate())

    days = dateUtils.dateDiffInDays(today, birthDay)

    if (days === 0) {
      return 'today'
    } else if (days === 1) {
      return 'tomorrow'
    } else if (days > 1 && days < 7) {
        // John Doe's birthday is 'this Wednesday, July 15'
      return ('this ' + $filter('date')(birthDay, 'EEEE, MMMM d'))
    } else if (days >= 7 && days <= 30) {
        // John Doe's birthday is 'in 8 days'
      return ('in ' + days + ' days')
    } else if (options.countMonths && days >= 31) {
      months = getPolicyTerminationMonths(days)

      if (months > 0) {
          // John Doe's birthday is 'in 2 months'
        return 'in ' + months + ' month' + (months > 1 ? 's' : '')
      }
    }
    return ''
  }

  service.processBirthdayEvents = function(fullname, birthDate) {
    let birthDateThisYear, today, timeUntilInPlainEnglish, message
    let lastAlert, daysBetweenTodayAndBirthday, daysBetweenAlertAndBirthday, showPreAlert, showBirthdayAlert

    if (!fullname || !birthDate || birthDate === '') {
      return
    }

    if (userAccountService.fakeBirthday) {
      birthDate = userAccountService.fakeBirthday
    }
    birthDateThisYear = new Date(birthDate)
    today = new Date()

    birthDateThisYear.setFullYear(today.getFullYear())

    fullname = $filter('nameCase')(fullname)

    timeUntilInPlainEnglish = getTimeUntilInPlainEnglish(birthDate)
    message = ''

    if (timeUntilInPlainEnglish) {
      lastAlert = new Date(userAccountService.getLastBirthdayAlert())

      daysBetweenTodayAndBirthday = dateUtils.dateDiffInDays(today, birthDateThisYear)
      daysBetweenAlertAndBirthday = lastAlert ? dateUtils.dateDiffInDays(lastAlert, birthDateThisYear) : 31

      showPreAlert = daysBetweenAlertAndBirthday > 30 && (daysBetweenTodayAndBirthday > 0 && daysBetweenTodayAndBirthday <= 30)
      showBirthdayAlert = timeUntilInPlainEnglish === 'today' && daysBetweenAlertAndBirthday !== 0

      if ($rootScope.auth.isClientView() /* && has not alerted this year */) {
        if (showPreAlert || showBirthdayAlert) {
          message = CONSTANTS.NOTIFICATION_MESSAGES_HAPPY_BIRTHDAY_TEMPLATE
          message = message.replace('{0}', CONSTANTS.NOTIFICATION_MESSAGES.HAPPY_BIRTHDAY).replace('{1}', timeUntilInPlainEnglish)

            // Store that the birthday has been alerted
          if (!showBirthdayAlert) {
            userAccountService.setLastBirthdayAlert(new Date())
          }
        }
      } else {
        message = CONSTANTS.NOTIFICATION_MESSAGES_TEMPLATE
        message = message.replace('{0}', fullname).replace('{1}', CONSTANTS.NOTIFICATION_MESSAGES.BIRTHDAY).replace('{2}', timeUntilInPlainEnglish)
      }
    }

    function onClose() {
      userAccountService.setLastBirthdayAlert(new Date())
    }

    if (message !== '') {
      notificationCenterService.add('info', message, CONSTANTS.notificationType.client, CONSTANTS.eventId.CLIENT_BIRTHDAY, {
        'status': 'shown',
        'onClose': onClose,
      })
      return true
    }

    return false
  }

  service.processPolicyAnniversary = function(fullname, anniversaryDate) {
    let message, timeUntilInPlainEnglish

    if (!fullname || !anniversaryDate || anniversaryDate === '' || $rootScope.auth.isClientView()) {
      return
    }

    fullname = $filter('nameCase')(fullname)
    message = ''
    timeUntilInPlainEnglish = getTimeUntilInPlainEnglish(anniversaryDate)

    if (timeUntilInPlainEnglish) {
      message = CONSTANTS.NOTIFICATION_MESSAGES_TEMPLATE
      message = message.replace('{0}', fullname).replace('{1}', CONSTANTS.NOTIFICATION_MESSAGES.POLICY_ANNIVERSARY).replace('{2}', timeUntilInPlainEnglish)
    }

    if (message !== '') {
      notificationCenterService.add('info', message, CONSTANTS.notificationType.policy, CONSTANTS.eventId.POLICY_ANNIVERSARY, {
        'status': 'shown',
      })
      return true
    }

    return false
  }

  service.processPolicyTermination = function(fullname, terminationDate) {
    let message, timeUntilInPlainEnglish

    if (!fullname || !terminationDate || terminationDate === '') {
      return
    }

    fullname = fullname.toLowerCase()
    terminationDate = new Date(terminationDate)
    message = ''
    timeUntilInPlainEnglish = getTimeUntilInPlainEnglish(terminationDate, {
      countMonths: true,
    })

    if (timeUntilInPlainEnglish) {
      message = CONSTANTS.NOTIFICATION_MESSAGES_TEMPLATE
      message = message.replace('{0}', fullname).replace('{1}', CONSTANTS.NOTIFICATION_MESSAGES.POLICY_TERMINATION).replace('{2}', timeUntilInPlainEnglish)
    }

    if (message !== '') {
      notificationCenterService.add('info', message, CONSTANTS.notificationType.policy, CONSTANTS.eventId.POLICY_TERMINATION, {
        'status': 'shown',
      })
      return true
    }

    return false
  }

  service.showLapsedNotification = function() {
    const message = CONSTANTS.NOTIFICATION_MESSAGES.POLICY_LAPSED

    notificationCenterService.add('danger', message, CONSTANTS.notificationType.policy, CONSTANTS.eventId.POLICY_LAPSE, {
      'status': 'shown',
    })
  }

  function getPolicyTerminationMonths(days) {
    if (days >= 31 && days <= 45) {
      return 1
    } else if (days >= 46 && days <= 75) {
      return 2
    } else if (days >= 76 && days <= 105) {
      return 3
    } else if (days >= 106 && days <= 135) {
      return 4
    } else if (days >= 136 && days <= 165) {
      return 5
    } else if (days > 165 && days <= 180) {
      return 6
    } else if (days > 180) {
      return -1
    }

    return 0
  }

  return service
}

export {eventsService}

eventsService.$inject = ['$rootScope', '$filter', 'notificationCenterService', 'dateUtils', 'CONSTANTS', 'userAccountService']
