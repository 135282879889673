export { PolicyPrintController }
PolicyPrintController.$inject = ['$stateParams', '$timeout', '$window', 'summaryResult', 'coveragesResult', 'myAgents',
  'policyValuesResult', 'taxInfo', 'currentFunds', 'loans', 'maxLoanQuotes', 'baseResult',
  'policyUtils', 'CONSTANTS', 'policyCanPayResult', 'utils', '$location', 'policyService', 'authService', 'clientResults',
  'tableResultLifetime', 'tableResultStandard', 'ridersHelper', 'organizeCoverages', 'policyTransactionsHistorySummaryResult',
  'clientUtils', '$q', '$filter', 'PolicyRelativesViewManager', 'dateUtils', 'ridersService', 'riderBlacklists']

const PREMIUM_PAYMENTS = [
  'Initial Premium',
  'Premium Payment',
  'Automatic Premium Loan Premium Payment'
]

function PolicyPrintController($stateParams, $timeout, $window, summaryResult, coveragesResult, myAgents,
  policyValuesResult, taxInfo, currentFunds, loans, maxLoanQuotes, baseResult,
  policyUtils, CONSTANTS, policyCanPayResult, utils, $location, policyService, authService, clientResults,
  tableResultLifetime, tableResultStandard, ridersHelper, organizeCoverages, policyTransactionsHistorySummaryResult,
  clientUtils, $q, $filter, PolicyRelativesViewManager, dateUtils, ridersService, riderBlacklists) {
  const self = this

  self.summary = summaryResult.policy
  self.rider = coveragesResult.data.rider || {}
  self.gmaIIRider = coveragesResult?.data?.coverage?.find(cov => cov.riderCode === 'GMA2')
  self.ehdbRider = coveragesResult?.data?.coverage?.find(cov => cov.riderCode === 'EHDB')
  self.hasGmaIIRider = Boolean(self.gmaIIRider)
  self.hasEhdbRider = Boolean(self.ehdbRider)
  self.gmaIIPrintTitle = self.hasGmaIIRider ? 'Guaranteed Minimum Accumulation Benefit II' : 'Guaranteed Minimum Death Benefit'
  self.coverages = (coveragesResult.data.coverage || []).map(organizeCoverages)
  self.coverages = Array.prototype.concat([], ...self.coverages)
  self.myAgent = myAgents && myAgents.data.parties ? myAgents.data.parties[0] : {}
  self.agentList = myAgents && myAgents.data.parties ? myAgents.data.parties : []
  self.clients = clientResults.data
  self.annuitant = clientUtils.organizeRolesByType(self.clients.parties, 'Annuitant')[0]
  if (self.annuitant) dateUtils.extendedAgeTo(self.annuitant?.birthDate, new Date())
  self.policyValues = policyValuesResult && policyValuesResult.policyValues
  self.rmdValues = (self.policyValues && self.policyValues.rmd) || {}
  self.taxInfo = (taxInfo && taxInfo.data) || {}
  // self.subAccounts = currentFunds.data.subAccount || []
  self.loans = loans.loans || []
  self.maxLoans = maxLoanQuotes.policyLoanQuotes || []
  self.policyBase = baseResult.policyBase || {}
  self.organizedClients = clientUtils.organizeClientDetails(self.clients, CONSTANTS.roleOrders)
  self.policyRelations = new PolicyRelativesViewManager(self.organizedClients, self.summary?.isAnnuity, self.summary?.assignmentCode?.tc === '2')
  self.organizedAgents = policyUtils.organizedAgents(self.agentList)
  self.canPay = policyCanPayResult.data
  self.route = $location.path()
  self.id = $stateParams.id
  self.isClient = authService.isAClient()
  self.isProductCodeFixedException = policyUtils.isProductCodeFixedException(self.summary.productCode)

  self.primaryAgent = {}
  self.puaDetails = {}
  self.primaryRoleNames = []
  self.servicingRoleNames = []
  self.additionalRoleNames = []
  self.showClientDetailPrintSummary = false
  self.showAdviserDetailPrintSummary = false
  self.annuityRidersSectionTitle = ''
  self.ridersSections = []
  self.isThereRidersError = false
  self.showMaturityDate = false
  self.showEppuaAppua = false
  self.latestLoanPayment = {}
  self.latestPremiumPayment = {}
  self.hasLatestPremiumOrAdditionalPayment = true

  self.transactionHistory = policyUtils.transactionHistory(policyTransactionsHistorySummaryResult)
  self.lastAdditionalPayment = self.transactionHistory?.financialActivity?.find(transaction => transaction.description === 'Additional Payment') || {}

  self.isWholeLife = function () {
    return self.summary.productType === CONSTANTS.productType.wholeLife
  }

  self.isUniversalLifePolicy = function () {
    return self.summary.productType === CONSTANTS.productType.universalLife ||
      self.summary.productType === CONSTANTS.productType.IUL ||
      self.summary.productType === CONSTANTS.productType.variableUniversalLife
  }

  self.isVariableUniversalLife = function () {
    return self.summary.productType === CONSTANTS.productType.variableUniversalLife
  }

  self.isIndexedUniversalLife = function () {
    return self.summary.productType === CONSTANTS.productType.IUL
  }

  self.isTermPolicy = function () {
    return self.summary.productType === CONSTANTS.productType.term || self.summary.productType === CONSTANTS.productType.indeterminatePremiumLifeTerm
  }

  self.isAnnuity = function () {
    return self.summary.productType === CONSTANTS.productType.indexedAnnuity ||
      self.summary.productType === CONSTANTS.productType.fixedAnnuity ||
      self.summary.productType === CONSTANTS.productType.variableAnnuity
  }

  self.isLifePolicy = function (lineOfBusiness) {
    return policyUtils.isLifePolicy(self.summary.lineOfBusiness)
  }

  self.showAarDca = function () {
    return [CONSTANTS.productType.IUL, CONSTANTS.productType.variableUniversalLife,
    CONSTANTS.productType.indexedAnnuity, CONSTANTS.productType.variableAnnuity].includes(self.summary.productType)
  }

  self.isPennCheck = function () {
    return self.summary.paymentMethod === 'Electronic Funds Transfer'
  }

  self.specificRole = function (party, generalRole) {
    return party.roles.map(utils.dot('role'))
      .filter(function (roleString) {
        return roleString.indexOf(generalRole) !== -1
      })[0]
  }

  self.getRole = function (client) {
    return policyUtils.getRole(client)
  }

  self.showQualPlanType = function (planType, code) {
    return policyUtils.showQualPlanType(planType, code)
  }

  self.primaryLoans = function () {
    return self.loans.filter(loan => Boolean(loan.loanAmt))
  }

  self.determinePrintContent = function (route) {
    if (route.includes('/' + CONSTANTS.printPolicyContents.detailedClientSummary) && (!authService.isAClient() || authService.isAClient())) {
      self.showClientDetailPrintSummary = true
      self.showAdviserDetailPrintSummary = false
    }

    if (route.includes('/' + CONSTANTS.printPolicyContents.detailedAdviserSummary) && !authService.isAClient()) {
      self.showAdviserDetailPrintSummary = true
      self.showClientDetailPrintSummary = false
    }

    if (route.includes('/' + CONSTANTS.printPolicyContents.defaultSummary)) {
      self.showAdviserDetailPrintSummary = false
      self.showClientDetailPrintSummary = false
    }
  }

  self.showAccountsMessage = function () {
    let accountType = []

    if (self.subAccounts) {
      accountType = self.subAccounts.map(account => account.assetClass && account.assetClass.value)
    }
    // console.log('subaccounts be', self.subAccounts)

    return accountType.some(type => type === 'Variable')
  }

  self._showEppuaAppua = function (rider) {
    return rider && !policyUtils.eppuaAppuaRiderIsExpired(rider)
  }

  function initPolicyAgents() { // TODO: primary agent could be put ina a resolve that is shared with policy summary
    setAgentsNamesByRole(self.agentList)
    if (!self.summary.isPending) {
      self.primaryAgent = policyUtils.filterPrimaryAgent(self.agentList)
    }
  }

  function setAgentsNamesByRole(list) { // TODO: This can be put in policy-utils.  The policy summary controller is using the same functionality.
    if (list) {
      list.forEach(function (agent, key) {
        agent.roles.forEach(function (item) {
          if (item.role) {
            if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.primary) {
              self.primaryRoleNames.push(agent.fullName)
            } else if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.servicing) {
              self.servicingRoleNames.push(agent.fullName)
            } else if (item.role.toLowerCase() === CONSTANTS.pendingPolicyAgentRoles.additional) {
              self.additionalRoleNames.push(agent.fullName)
            }
          }
        })
      })
    }
  }

  self.initArrangement = function () {
    policyService.getPolicyArrangements(self.id).then(
      function (arrangements) {
        if (arrangements.data && arrangements.data.arrangement && arrangements.data.arrangement.length > 0) {
          self.summary.arrangement = arrangements.data.newArrangement
        }
      })
  }

  self.showZeroValue = function (value) {
    return value >= 0
  }

  self._showMaturityDate = function () {
    return self.isWholeLife() && self.summary.policyStatus === 'Extended term'
  }

  self.isPremiumPayment = function (description) {
    return PREMIUM_PAYMENTS.includes(description)
  }

  self.isPuaPayment = function (description) {
    return description === 'Dividends-Paid Up Additions' || description === 'Paid Up Additions Rider Payment'
  }

  self.isLoanPayment = function (description) {
    return description === 'Loan Payment'
  }

  self.hasTransactions = function (foundTransactions) {
    return foundTransactions > 0
  }

  self._hasLatestPremiumOrAdditionalPayment = function (premiumPayment, additionalPayment) {
    const payments = [premiumPayment, additionalPayment]

    return payments.some(payment => Object.keys(payment).length > 0 && payment.constructor === Object)
  }

  self.$onInit = function () {
    const promises = [
      policyService.getLimitedPolicyTransactionHistory(self.id, undefined, 'loan').then((results) => {
        if (self.hasTransactions(results.transactionHistory.numFound)) {
          self.latestLoanPayment = results.transactionHistory.financialActivity.filter(transaction => self.isLoanPayment(transaction.description))[0] || {}
          self.latestLoanPayment.finEffDate = new Date(self.latestLoanPayment.finEffDate)
        }
      }),

      policyService.getLimitedPolicyTransactionHistory(self.id, undefined, 'payment').then((results) => {
        if (self.hasTransactions(results.transactionHistory.numFound)) {
          self.latestPremiumPayment = results.transactionHistory.financialActivity.filter(transaction => self.isPremiumPayment(transaction.description))[0] || {}
          self.latestPremiumPayment.finEffDate = new Date(self.latestPremiumPayment.finEffDate)

          self.latestPuaPayment = results.transactionHistory.financialActivity.filter(transaction => self.isPuaPayment(transaction.description))[0] || {}
          self.latestPuaPayment.finEffDate = new Date(self.latestPuaPayment.finEffDate)
        }

        self.hasLatestPremiumOrAdditionalPayment = self._hasLatestPremiumOrAdditionalPayment(self.latestPremiumPayment, self.lastAdditionalPayment)
      }),

      policyUtils.getSubAccountsFromResult(currentFunds).then(subAccounts => {
        self.subAccounts = subAccounts || []
      }),
    ]

    return $q.all(promises).catch((error) => console.log(error)).finally(() => self.init())
  }

  self.init = function () {
    initPolicyAgents()
    self.initArrangement()
    self.determinePrintContent(self.route)
    self.pmlLogo = policyUtils.deriveCarrierLogo(self.summary.carrierCode)
    self.showMaturityDate = self._showMaturityDate()

    if (self.maxLoans.length) {
      self.maxLoans[0].loanInterestRate = parseFloat(self.maxLoans[0].loanInterestRate)
    }

    if (!self.summary.isAnnuity) {
      self.targetPremiums = policyUtils.reduceCoveragesToCommissions(coveragesResult.data.coverage) || []
    }

    if (self.isWholeLife() && self.policyValues) {
      self.wlDeathBenefits = policyUtils.orderedValues(self.policyValues.deathbenefit, CONSTANTS.wlDeathBenefitMeta, self.wlDeathBenefits)
      self.wlCashSurrenderValues = policyUtils.orderedValues(self.policyValues.cashsurrendervalue, CONSTANTS.wlCashSurrenderValueMeta, self.wlCashSurrenderValues)
    }

    if (self.isUniversalLifePolicy() && self.policyValues) {
      self.ulCashSurrenderValues = policyUtils.orderedValues(self.policyValues.cashsurrendervalue, CONSTANTS.ulCashSurrenderValueMeta, self.ulCashSurrenderValues)
      self.ulDeathBenefits = policyUtils.orderedValues(self.policyValues.deathbenefit, CONSTANTS.ulDeathBenefitMeta)
    }

    if ((self.isUniversalLifePolicy() || self.isWholeLife()) && self.loans) {
      self.primaryLoanValues = self.primaryLoans().map(function (loan) {
        if (self.isWholeLife()) {
          delete loan.loanType
        }

        if (self.maxLoans.length) {
          loan.loanInterestRate = parseFloat(self.maxLoans[0].loanInterestRate)
        }

        return policyUtils.orderedValues(loan, CONSTANTS.loanMeta)
      })
    }

    if ((self.isUniversalLifePolicy() || self.isWholeLife()) && self.taxInfo) {
      self.sevenPayGuidelinePremiumValues = policyUtils.orderedValues(self.taxInfo, CONSTANTS.sevenPayGuidelinePremiumMeta)
    }

    if (self.isTermPolicy() && self.policyValues) {
      self.termDeathBenefits = policyUtils.orderedValues(self.policyValues.deathbenefit, CONSTANTS.termDeathBenefitMeta, self.termDeathBenefits)
    }

    if (self.isWholeLife() && self.coverages) {
      self.puaDetails = policyUtils.eppuaAppuaRiders(self.coverages)
      self.showEppuaAppua = self._showEppuaAppua(self.puaDetails)
    }

    if (self.summary.isAnnuity && self.policyValues) {
      self.annuityCashSurrenderValues = policyUtils.orderedValues(self.policyValues.cashsurrendervalue, CONSTANTS.annuityCashSurrenderValueMeta)
    }

    if (coveragesResult.data.coverage?.length) {
      const organizedClients = clientUtils.organizeClientDetails(clientResults.data)

      policyUtils.riderDetailResults(summaryResult, coveragesResult, clientResults, baseResult, tableResultLifetime, tableResultStandard)
        .then(function (results) {
          const sortedCoverages = ridersService.sortCoverages(coveragesResult.data.coverage, riderBlacklists.cmsRiders)
          const combinedCmsRiderCodes = sortedCoverages.filter(cov => riderBlacklists.cmsRiders.includes(cov.riderCode)).map(cov => String(cov.riderCode + (cov.status ? '-' + cov.status : '')).toLowerCase()) ?? []
          const data = { ...results, coverages: sortedCoverages, clients: organizedClients }

          self.useCmsRiders = combinedCmsRiderCodes.length > 0

          if (self.useCmsRiders) {
            // Use CMS Based Rider Rendering
            const { availableLifetime, availableStandard } = coveragesResult.data

            sortedCoverages.forEach((coverage) => {
              coverage.availableLifetime = availableLifetime
              coverage.availableStandard = availableStandard
            })

            ridersService.getRiderSectionDefs(combinedCmsRiderCodes)
              .then((riderSectionDefs) => {
                if (riderSectionDefs.length > 0) {
                  self.cmsRiders = combinedCmsRiderCodes.map((riderCode, i) => {
                    return ridersService.generateRiderSections(riderCode, riderSectionDefs[i], data)
                  })

                  // Modal Title
                  self.title = self.cmsRiders[0]?.title

                  if (self.cmsRiders.length > 1) ridersService.resolveFirstSectionTitle(self.cmsRiders, data.coverages)

                  console.log('Rider Display\n', self.cmsRiders)
                } else {
                  policyUtils.handleRidersError(new Error(`Rider Not Found.`))
                  self.isThereError = true
                }
              })
          } else {

            self.sections = policyUtils.populateRiderModal(results, coveragesResult, ridersHelper)
            self.annuityRidersSectionTitle = policyUtils.riderModalSectionTitle(results)
          }
        })
        .catch(function (error) {
          policyUtils.handleRidersError(error)
          self.isThereError = true
        })
    }
  }

  // self.init()
}
