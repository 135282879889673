import * as angular from 'angular'
import {Requirement, Reply, CSError} from '../policy/policy-requirements-controller'
export class RequirementsHelperService {
  static $inject = ['CONSTANTS', '$q', 'authService', 'utils', '$http', 'VIEW_REPLY_ALLOWED_TYPES']

  constructor(private CONSTANTS, private $q: angular.IQService, private authService, private utils, private $http: angular.IHttpService, private VIEW_REPLY_ALLOWED_TYPES) {
  }

  /**
   * Returns `true` if the given `requirement.reqCode` is in
   * `VIEW_REPLY_ALLOWED_TYPES`, and `canReplyToRequirement` returns true.
   *
   * @param requirement
   */
  showReplyButton (canSubmit: any, replies: any, requirement: Requirement): boolean {
    const submittable = this.canReplyToRequirement(canSubmit, requirement.id)
    const canReply: boolean = Boolean(submittable)
    let allowedType: any = this.VIEW_REPLY_ALLOWED_TYPES.filter(typeCode => requirement.reqCode === typeCode.name)[0]

    // console.log('>>>>', canSubmit, replies, requirement, submittable, canReply, allowedType)

    if (/*allowedType && */ canReply) { // the service needs to be fixed to indicate this for upload types
      const reply = replies[requirement.id]

      allowedType = allowedType || {name: requirement.reqCode , text: false, upload: true }

      return !reply || (!reply.inProgress && !reply.done) ? allowedType : undefined
    } else {
      return false
    }

    // return false
  }

  /**
   * Checks to see if the requirementId is in the canSubmitToResult payload.
   *
   * @param requirementId
   */
  canReplyToRequirement(canSubmit: any[] | undefined, requirementId: string): boolean | undefined {
    let result: boolean = false

    if (Array.isArray(canSubmit) && canSubmit.includes(requirementId)) result = true

    return result || typeof canSubmit === 'undefined'
  }

  /**
   * Determine which type of reply the user can make for a given
   * `requirement`, and then display either the text reply panel,
   * or the upload reply panel. If the requirement supports both types
   * of reply then display the choice panel.
   *
   * @param requirement
   */
  openReplyRow(replies: any, requirement: Requirement): void {
    const uiState = requirement.uiState

    uiState.showTextReplyPanel = uiState.canEnterReply && !uiState.canUploadReply
    uiState.showUploadReplyPanel = !uiState.canEnterReply && uiState.canUploadReply
    uiState.showChoicePanel = uiState.canEnterReply && uiState.canUploadReply
    uiState.showReplyButton = false
    uiState.showReplyRow = true

    if (uiState.showTextReplyPanel) {
      this.openTextReply(replies, requirement)
    } else if (uiState.showUploadReplyPanel) {
      this.openUploadReply(replies, requirement)
    }
  }

  /**
   * Resets the requirement's state.
   *
   * @param requirement
   */
  closeRequirementReply(replies: any, requirement: Requirement, cancelled: boolean = false): void {

    const requirementId: string = requirement.id
    const reply: Reply = replies[requirementId]
    const uiState: any = requirement.uiState

    if (Boolean(reply) && cancelled) {
      reply.inProgress = false
      reply.done = false
      reply.sent = false
    }

    uiState.reset(reply)
    requirement.error = null
  }

  /**
   * Disables the
   * @param requirementId
   */
  disableReplyInputs (replies: any, requirementId: string): boolean {
    return replies[requirementId] && replies[requirementId].sent
  }

  /**
   * Returns true when the length of the text is at least two characters.
   *
   * @param requirementId
   */
  longEnough (replies: any, requirementId: string): boolean {
    const reply: Reply = replies[requirementId]

    return Boolean(reply) && Boolean(reply.text) && reply.text.length > 1
  }

  /**
   * Return the number characters remaining as the user types into the reply field.
   *
   * @param requirementId
   */
  replyCharactersRemaining (replies: any, requirementId: string): number {
    const reply: Reply = replies[requirementId]
    const MAX_CHARS: number = 1000
    const remainingChars: number = reply ? MAX_CHARS - reply.text.length : MAX_CHARS

    return remainingChars
  }

  /**
   * Initialize a new reply state, then shows the text panel and
   * ensures that the choice panel is hidden.
   *
   * @param requirement
   */
  openTextReply(replies: any, requirement: Requirement): void {
    // requirement.noteOpened = true
    replies[requirement.id] = {
      error: null,
      inProgress: true,
      sent: false,
      done: false,
      text: '',
    }

    requirement.uiState.showTextReplyPanel = true
    requirement.uiState.showChoicePanel = false
  }

  /**
   * Initialize a new reply state, then shows the upload panel and
   * ensures that the choice panel is hidden.
   *
   * @param requirement
   */
  openUploadReply(replies: any, requirement: Requirement): void {
    replies[requirement.id] = {
      error: null,
      inProgress: true,
      sent: false,
      done: false,
      text: '',
    }

    requirement.uiState.showUploadReplyPanel = true
    requirement.uiState.showChoicePanel = false
  }

  /**
   * Sends a PDF reply to Core Services.
   *
   * @param requirement
   */
  uploadFile(replies, requirement, theFile, pid) {
    const url = `${this.CONSTANTS.apiRoot}policy/${pid}/requirements/submit/${requirement.id}`
    // const url = `/upload-test/${this.policyId}/${requirement.id}`
    const deferred = this.$q.defer()
    const formData = new FormData()
    const config: any = {
      headers: {
        Authorization: 'Bearer ' + this.authService.getSessionID(),
      },
      eventHandlers: {
        progress: e => {
          if (e.lengthComputable) {
            const percentage = Math.round((e.loaded * 100) / e.total)

            deferred.notify(percentage)
          }
        },
        load: () => {
          deferred.notify(100)
        },
      },
    }

    if (this.isIE()) {
      config.transformRequest = angular.identity
      config.headers['content-type'] = undefined
    } else {
      config.headers['content-type'] = 'multipart/form-data'
    }

    formData.append('file', theFile)

    deferred.notify(0)

    this.$http.post(url, formData, config)
      .then(() => this._sendReplySuccess(replies, requirement))
      .catch(response => this._sendReplyFailure(replies, requirement, response))

    return deferred.promise
      .finally(() => console.log('progress'))
  }

  /**
   * Can the user do reply stuff
   *
   */
  public canCurrentUserDoReplies() {
    const canReplyAtAllUrl = `${this.CONSTANTS.apiRoot}agent/can/reply/requirements`

    // resolve(response.data)
    return this.$q((resolve) => {
      this.$http.get(canReplyAtAllUrl).then((result: any) => {
        resolve(result.data.canReply)
      }).catch(err => console.log(err))
    })
  }

  /**
   * Sends a text reply to Core Services.
   *
   * @param requirement
   */
  sendReply(replies: any, requirement: Requirement, pid) { // TODO remove the <any> in favor of a union type or something
    const reply = replies[requirement.id]

    requirement.sending = true

    if (window.dataLayer) {
      window.dataLayer.push(this.utils.buildGtmObject('AGENT REPLY', 'REPLY', {
        policyID: pid,
      }))
    }

    const formData = new FormData()
    const url = `${this.CONSTANTS.apiRoot}policy/${pid}/requirements/submit/${requirement.id}`
    const ieOptions = {
      transformRequest: angular.identity,
      headers: {'content-type': undefined},
    }
    let options: any = {
      headers: {'content-type': 'multipart/form-data'}}

    formData.append('text', reply.text)

    if (this.isIE()) {
      options = ieOptions
    }

    this.$http.post(url, formData, options)
      .then(() => this._sendReplySuccess(replies, requirement))
      .catch(response => this._sendReplyFailure(replies, requirement, response))
  }

  isIE(): boolean {
    return this.utils.isIE()
  }

  _sendReplySuccess(replies: any, requirement: Requirement) {
    // const matchingRequirement = this.requirementsData.requirements.filter(requirement => requirement.id === requirementId)[0]
    const reply = replies[requirement.id]

    if (reply) {
      reply.sent = true
      reply.done = true
      reply.inProgress = false
    }

    this.closeRequirementReply(replies, requirement)

  }

  _sendReplyFailure(replies: any, requirement: Requirement, response: any) {

    const reply = replies[requirement.id]

    requirement.sending = false
    if (response.data) {
      requirement.error = new CSError(response.data.description, response.data.code)
    } else {
      console.debug('Unexpected response data %o', response)
      requirement.error = response
    }

    // reply.error = error.data || error.statusText || 'Unknown error.'
    reply.sent = false
  }

  /**
   * Opens and closes the notes row for the given requirements.
   *
   * @param requirement
   */
  toggleNote(requirement: Requirement) {
    const isNoteOpen = requirement.noteOpened

    requirement.noteOpened = !isNoteOpen
  }
}
