const GA_PARAMETER_NAMES = {
  lob: 'lob',
  productType: 'productType',
  sortBy: 'sortBy',
  sortDirection: 'sortDirection',
  view: 'viewStyle',
  issueState: 'issueState',
  page: 'page',
  formalAppIndicator: 'formalAppIndicator',
  appStatus: 'appStatus',
  daysOpen: 'daysOpen',
  daysToExpire: 'daysToExpire',
  aceIndicator: 'aceIndicator',
  subStatus: 'subStatus',
}
const FILTER_VIEW_STATES: IFilterViewStates = {
  inforce: {
    lob: true,
    productType: true,
    issueStates: true,
    deliveryStatus: false,
    appStatus: false,
    daysOpen: false,
    expiring: false,
    sort: true,
    showDownload: true,
    viewSelect: true,
    sortContainer: true,
    holdingStatusTypeCode: '1',
    appParam: '',
    appMethod: true,
    appTypes: false,
  },
  pending: {
    lob: true,
    productType: true,
    appMethod: true,
    appTypes: true,
    issueStates: true,
    deliveryStatus: false,
    appStatus: false,
    daysOpen: false,
    expiring: false,
    sort: false,
    showDownload: true,
    viewSelect: false,
    sortContainer: true,
    holdingStatusTypeCode: '3',
    appParam: '',
  },
  notpaid: {
    lob: false,
    productType: true,
    appMethod: true,
    appTypes: true,
    issueStates: true,
    deliveryStatus: true,
    appStatus: false,
    daysOpen: false,
    expiring: false,
    sort: false,
    showDownload: true,
    viewSelect: false,
    sortContainer: false,
    holdingStatusTypeCode: '1012300005', // <----- hard coded because of the rules here, http://pmlwiki/pages/viewpage.action?spaceKey=sdf&title=Holding+and+Policy+Status.
    appParam: '',
  },
  unsubmitted: {
    lob: false,
    productType: false,
    appMethod: false,
    appTypes: true,
    issueStates: false,
    deliveryStatus: false,
    appStatus: true,
    daysOpen: true,
    expiring: true,
    sort: false,
    showDownload: true,
    viewSelect: false,
    sortContainer: true,
    holdingStatusTypeCode: '1012300001',
    appParam: '/app',
  },
}
const POLICIES_GA_INFORCE_VIEW: IPolicyView = {
  event: 'inforce_view',
  action: '',
  propertyMap: {
    [GA_PARAMETER_NAMES.lob]: 'lineOfBusiness.name',
    [GA_PARAMETER_NAMES.productType]: 'productType.name',
    [GA_PARAMETER_NAMES.sortBy]: 'currentSort.name',
    [GA_PARAMETER_NAMES.sortDirection]: 'currentSort.direction',
    [GA_PARAMETER_NAMES.view]: 'viewStyle',
    [GA_PARAMETER_NAMES.issueState]: 'includeState.name',
    [GA_PARAMETER_NAMES.page]: 'pagingInfo.page',
  },
}
const POLICIES_GA_PENDING_VIEW: IPolicyView = {
  event: 'pending_view',
  action: '',
  propertyMap: {
    [GA_PARAMETER_NAMES.lob]: 'lineOfBusiness.name',
    [GA_PARAMETER_NAMES.productType]: 'productType.name',
    [GA_PARAMETER_NAMES.sortBy]: 'currentSort.name',
    [GA_PARAMETER_NAMES.sortDirection]: 'currentSort.direction',
    [GA_PARAMETER_NAMES.view]: 'viewStyle',
    [GA_PARAMETER_NAMES.page]: 'pagingInfo.page',
    [GA_PARAMETER_NAMES.aceIndicator]: 'aceIndicator.name',
    [GA_PARAMETER_NAMES.formalAppIndicator]: 'formalAppIndicator.name',
    [GA_PARAMETER_NAMES.issueState]: 'includeState.name',

  },
}
const POLICIES_GA_NOTPAID_VIEW: IPolicyView = {
  event: 'notpaid_view',
  action: '',
  propertyMap: {
    [GA_PARAMETER_NAMES.productType]: 'productType.name',
    [GA_PARAMETER_NAMES.sortBy]: 'currentSort.name',
    [GA_PARAMETER_NAMES.sortDirection]: 'currentSort.direction',
    [GA_PARAMETER_NAMES.view]: 'viewStyle',
    [GA_PARAMETER_NAMES.issueState]: 'includeState.name',
    [GA_PARAMETER_NAMES.page]: 'pagingInfo.page',
    [GA_PARAMETER_NAMES.aceIndicator]: 'aceIndicator.name',
    [GA_PARAMETER_NAMES.formalAppIndicator]: 'formalAppIndicator.name',
    [GA_PARAMETER_NAMES.subStatus]: 'subStatus.name',
  },
}
const POLICIES_GA_UNSUBMITTED: IPolicyView = {
  event: 'unsubmitted_view',
  action: '',
  propertyMap: {
    [GA_PARAMETER_NAMES.formalAppIndicator]: 'formalAppIndicator.name',
    [GA_PARAMETER_NAMES.appStatus]: 'appStatus.name',
    [GA_PARAMETER_NAMES.page]: 'pagingInfo.page',
    [GA_PARAMETER_NAMES.view]: 'viewStyle',
    [GA_PARAMETER_NAMES.daysOpen]: 'daysOpen.name',
    [GA_PARAMETER_NAMES.daysToExpire]: 'daysToExpire.name',
    [GA_PARAMETER_NAMES.sortBy]: 'currentSort.name',
    [GA_PARAMETER_NAMES.sortDirection]: 'currentSort.direction',
  },
}
const POLICIES_POLICY_VIEWS: IPolicyViews = {
  inforce: POLICIES_GA_INFORCE_VIEW,
  pending: POLICIES_GA_PENDING_VIEW,
  notpaid: POLICIES_GA_NOTPAID_VIEW,
  unsubmitted: POLICIES_GA_UNSUBMITTED,
}

export {FILTER_VIEW_STATES, POLICIES_GA_INFORCE_VIEW, POLICIES_POLICY_VIEWS}
