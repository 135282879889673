/* eslint-disable no-underscore-dangle */
import { BENEFICIARIES_DIST_TYPES, IGW_DISTRIBUTION_TYPECODES } from '../beneficiary-constants'
import { BENEFICIARY_ROLES_ARRAY, BENEFICIARY_ROLE_LABELS2, CLIENT_BENEFICIARY_ROLE_TYPECODES } from '../constants/ROLES'
import { IBeneficiaryShare } from '../types'
import AbstractTransformableData from './abstract-transformable-data'
import { BeneficiaryAddress } from './address'
import { Beneficiary } from './beneficiary'
import { TypeCodeValue } from './type-code-value'

export class BeneficiaryShare extends AbstractTransformableData implements IBeneficiaryShare {
  type: TypeCodeValue
  _amount: number | '' | undefined
  amountBackup: number | '' | undefined
  _distributionMethod: TypeCodeValue
  settlementOption: String
  relationDescription: TypeCodeValue | undefined
  roleTypeCode: TypeCodeValue | undefined
  effectiveDate: Date | null

  constructor(role?: BeneficiaryShare | any, roleId?: string) {
    super(['amountBackup'], ['amount'])

    if (role?.constructor.name === 'BeneficiaryShare') {
      const tmp: BeneficiaryShare = role // To help TypeScript autocomplete.

      this.type = tmp.type
      this.amount = tmp.amount
      this.distributionMethod = tmp.distributionMethod
      this.settlementOption = tmp.settlementOption
      this.relationDescription = tmp.relationDescription
      this.roleTypeCode = tmp.roleTypeCode
      this.effectiveDate = tmp.effectiveDate
    } else {
      const tmp = role?.distribution || role?.distributionOption

      this.type = tmp ? new TypeCodeValue(tmp) : new TypeCodeValue(IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE)
      this.amount = this._resolveIntitalAmount(role)
      this.distributionMethod = new TypeCodeValue(role?.beneShareMethod || BENEFICIARIES_DIST_TYPES[0])
      this.settlementOption = role?.distributionOptionDesc || 'One Sum'
      this.relationDescription = new TypeCodeValue(role?.relationshipToInsured)
      this.roleTypeCode = role ? new TypeCodeValue({ tc: role.tc, value: role.role }) : new TypeCodeValue({ tc: roleId, value: '' })
      this.effectiveDate = role?.effectiveDate ? new Date(role.effectiveDate) : null

    }
  }
  // private _resolveRoleTypeCodeValue(role: any): TypeCodeValue {
  //   // If we have a 'tc' then use it.
  //   if (role?.tc && BENEFICIARY_ROLES_ARRAY.includes(role?.tc)) {
  //     return new TypeCodeValue(CLIENT_BENEFICIARY_ROLE_TYPECODES[role.tc])
  //   }

  //   return new TypeCodeValue(role?.distribution || role?.distributionOption) // Legacy code
  // }

  private _resolveIntitalAmount(inRole: any): number | undefined {
    const value: number | undefined = inRole?.distributionValue || inRole?.distribution
    let r: number | undefined


    // If percent, or dollar amount, and there is no value then return 0
    if (['2', '5'].includes(this.type?.tc) && value === undefined) {
      r = 0
    } else {
      r = typeof value === 'object' ? undefined : value
    }

    if (this.type?.tc === '1') r = undefined // No amount required for equal shares.

    return r
  }

  toCSObject(bene: Beneficiary): any {
    const tmp: any = {}

    tmp.role = this.roleTypeCode?.value || BENEFICIARY_ROLE_LABELS2[bene.roleId]
    tmp.tc = this.roleTypeCode?.tc || bene.roleId
    tmp.distribution = this.type?.transform()
    tmp.relationshipToInsured = this.relationDescription.transform()
    tmp.beneEqualShareInd = this.type?.tc === "1"
    tmp.distributionOptionDesc = this.settlementOption
    tmp.beneShareMethod = this.distributionMethod.transform()
    tmp.primaryAddressId = bene.role?.address?.id || bene.address?.id
    tmp.distributionValue = this.amount
    tmp.address = bene.role?.address || bene.address ? !bene.address.isClean ? bene.address?.transform() : undefined : undefined

    // .transform((transformed) => {
    //   if(bene.address.isClean) return

    //   return transformed
    // })

    return tmp
  }

  get amount(): number | '' | undefined {
    return this._amount
  }

  set amount(val: number | '' | undefined) {
    const temp: any = typeof val === 'undefined' ? undefined : Number(val)
    this.amountBackup = this.amount
    this._amount = temp
  }

  get hasUndefinedDistributionType(): boolean {
    return !this.type?.tc
  }

  get distributionMethod(): TypeCodeValue {
     return this._distributionMethod
  }

  set distributionMethod(value: any) {
    this._distributionMethod = new TypeCodeValue(value)
  }

  serialize(originalBene: Beneficiary | undefined, roleId: string, address: BeneficiaryAddress): any {
    const originalShare: BeneficiaryShare | undefined = originalBene?.shareDistribution
    const tmpShare: any = {}

    // if (this?.amount !== originalShare?.amount) {
    //   tmpShare.distributionValue = this.amount
    // }

    if (this?.amount !== originalShare?.amount || this.type?.tc !== originalShare?.type?.tc) {
      tmpShare.distributionOption = new TypeCodeValue(this.type).serialize()
      tmpShare.distributionValue = this.amount
    }

    if (this.distributionMethod?.tc !== originalShare?.distributionMethod?.tc) {
      tmpShare.beneShareMethod = this.distributionMethod.serialize()
    }

    if (this.settlementOption !== originalShare?.settlementOption) {
      tmpShare.distributionOptionDesc = this.settlementOption
    }

    if (this.relationDescription?.tc !== originalShare?.relationDescription?.tc) {
      tmpShare.relationDescription = { tc: this.relationDescription?.tc }
    }

    if (!address.isClean && address.hasChange(originalBene)) {
      tmpShare.address = address.serialize()
    }

    if (Object.keys(tmpShare).length > 0) {
      tmpShare.tc = roleId

      return tmpShare
    }

    return
  }
}
