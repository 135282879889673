export function alertCategoryDismiss(alertDismissService) {
  return {
    restrict: 'A',
    require: 'wgAlertCheckbox',
    link: alertCategoryDismissLinker,
  }

  function alertCategoryDismissLinker(scope, elem, attr, ctrl) {
    const originalToggle = ctrl.toggle
    const category = attr.alertCategoryDismiss

      // Update checked based on the alertDismissService buffer.
    alertDismissService.subscribeCallback(function() {
      ctrl.set(alertDismissService.isCategoryInBuffer(category))
    })

      // Extend the wgAlertCheckbox toggle function.
    ctrl.toggle = function(e) {
      const checked = originalToggle(e)

        // Adds the checked category to the alertDismissService buffer.
      if (checked) {
        alertDismissService.addCategoryToBuffer(category)
      } else {
        alertDismissService.removeCategoryFromBuffer(category)
      }
    }
  }
}

alertCategoryDismiss.$inject = ['alertDismissService']
