export class RolesRunner {
  static $inject = ['routerHelper']

  constructor(routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates() {
    const rolesRouteConfig = {
      state: 'policy.roles',
      config: {
        abstract: true,
        url: '/roles',
        template: '<ui-view></ui-view>',
        resolve: {
          policySummary: ['summaryResult', (summaryResult) => {
            return summaryResult?.policy
          }],
        },
      },
    }

    const clientRolesRouteConfig = {
      state: 'myPolicy.roles',
      config: {
        abstract: true,
        url: '/roles',
        template: '<ui-view></ui-view>',
      },
    }

    const rolesListRouteConfig = {
      state: 'policy.roles.list',
      config: {
        url: '/list',
        component: 'rolesComponent',
        resolve: {
          clientResults: ['clientResults', (clientResults) => clientResults],
        },
        onEnter: ['$rootScope', function ($rootScope) {
          const pageTitle = 'Policy Roles'

          $rootScope.pageTitle = pageTitle
          $rootScope.isPopupVisible = false
          $rootScope.state = 'policy-roles-details'
        }],
        // onExit: ['$rootScope', '$stateParams', function($rootScope, $stateParams) {
        //   setSummaryPageTitle($rootScope, $stateParams.policySource)
        // }],
      },
    }

    const clientRolesListRouteConfig = {
      state: 'myPolicy.roles.list',
      config: {...rolesListRouteConfig.config, ...{clientAppOnly: true}},
    }

    const rolesEditRouteConfig = {
      state: 'policy.roles.edit',
      config: {
        abstract: true,
        url: '/edit',
        component: 'rolesEditComponent',
      },
    }

    const rolesEditRoleDetailsRouteConfig = {
      state: 'policy.roles.edit.role-details',
      config: {
        url: '/role-details',
        component: 'rolesEditDetailsComponent',
      },
    }

    const rolesEditPersonalDetailsRouteConfig = {
      state: 'policy.roles.edit.personal-details',
      config: {
        url: '/personal-details',
        component: 'rolesEditPersonalDetailsComponent',
      },
    }

    const rolesEditPersonalInformationRouteConfig = {
      state: 'policy.roles.edit.personal-information',
      config: {
        url: '/personal-information',
        component: 'rolesEditPersonalInformationComponent',
      },
    }

    const rolesEditAddressEditRoutConfig = {
      state: 'policy.roles.edit.address-edit',
      config: {
        url: '/address-edit',
        component: 'rolesEditAddressEditComponent',
      },
    }

    return [
      rolesRouteConfig,
      rolesListRouteConfig,
      rolesEditRouteConfig,
      rolesEditRoleDetailsRouteConfig,
      rolesEditPersonalDetailsRouteConfig,
      rolesEditPersonalInformationRouteConfig,
      rolesEditAddressEditRoutConfig,
      clientRolesListRouteConfig,
      clientRolesRouteConfig,
    ]
  }
}
