const SEARCH_RESULTS = 'search.result'
const DASHBOARD = 'dashboard'
const COMPENSATION = 'compensation'
const CLIENT_ME = 'client.me'
const CLIENT_LIST = 'client.list'
const OPEN_WINDOW_SYSTEM = '_system'
const OPEN_WINDOW_BLANK = '_blank'
const ALERTS = 'alerts'
const REPLYABLE = 'replyable-requirements'
const CONTACTUS = 'contact-us'
const FORMS = 'forms'

export class NavigationService {
  static $inject = ['$rootScope', 'authService', 'stateTransitions', 'deviceUtils', 'CONSTANTS', '$http']

  constructor(private $rootScope, private authService, private stateTransitions, private deviceUtils, private CONSTANTS, private $http: ng.IHttpService) {}

  go(stateName, params?, options?, type?): void {
    this.$rootScope.$broadcast(this.CONSTANTS.events.CLOSE_POPUPS_AND_MENUS)
    this.stateTransitions.go(stateName, params, options, type)
    // if (this.$state.current.name !== stateName) {
    // }
  }

  goAlerts = (category: string): void => this.go(ALERTS, {category})

  goSearch = (): void => {
    const params = {
      c: '',
      background: true,
    }
    const options = {
      direction: 'right',
      async: true,
    }

    this.go(SEARCH_RESULTS, params, options)

  }

  // goDashboard = (): void => this.go(DASHBOARD)

  goCompensation = (): void => this.go(COMPENSATION)

  goReplyable = (): void => this.go(REPLYABLE)
  goNotifications = (): void => this.go(this.CONSTANTS.modules.notifications.id)
  goBatchStatements = (): void => this.go(this.CONSTANTS.modules.batchstatements.id)
  goContactUs = (): void => this.go(CONTACTUS)
  goToForms = (): void => this.go(FORMS)

  goClientList = (): void => this.go(CLIENT_LIST)

  goToClientLanding = (): void => this.go(CLIENT_ME)

  goBack = (): void => this.stateTransitions.goBack()

  logout = (): void => this.authService.logout('user invoked logout')

  openWindowNewTab(url) {
    if (this.deviceUtils.isMobilePlatform()) {
      window.open(url, OPEN_WINDOW_SYSTEM)
    } else {
      window.open(url, OPEN_WINDOW_BLANK)
    }
  }

  getSiteLinks(): any {
    return this.$http.get('/crafter')
    .then(function success(httpData) {
      return httpData.data || null
    })
    .catch(function failure(httpData) {
      console.log('Error retrieving site link content: ', httpData)
      return null
    })
  }

  myProfile() {
    this.openWindowNewTab(this.CONSTANTS.clientProfileURL)
  }

  setWindowLocationHref(url) { // For mocking in tests
    window.location.href = url
  }
}
