import * as angular from 'angular'

const BENEFICIARY_PARTY_TYPES = {
  PERSON: 'person',
  ORGANIZATION: 'organization',
  BUSINESS: 'business',
  TRUST: 'trust',
  ESTATE: 'estate',
  TEXT: 'text',
}

const BENEFICIARY_OTHER_OPTIONS = [
  { fullName: 'Person', id: BENEFICIARY_PARTY_TYPES.PERSON, partyTypeCode: { tc: '1', value: 'Person' }, isNew: true, isNewPartyOption: true, group: 'New Party' },
  { fullName: 'Entity', id: BENEFICIARY_PARTY_TYPES.ORGANIZATION, partyTypeCode: { tc: '2', value: 'Organization' }, isNew: true, isNewPartyOption: true, group: 'New Party' },
  { fullName: 'Text', id: BENEFICIARY_PARTY_TYPES.TEXT, partyTypeCode: { tc: '0', value: 'Unknown' }, isNew: true, isNewPartyOption: true, group: 'New Party' },
  // { fullName: 'New Estate', id: BENEFICIARY_PARTY_TYPES.ESTATE, partyTypeCode: { tc: '2', value: 'Organization' }, isNew: true, isNewPartyOption: true, group: 'New Party' },
]

const CS_PARTY_TYPES = {
  PERSON: '1',
  ORGANIZATION: '2',
  UNKNOWN: '0',
  TEXT: '0',
}

export const CS_PARTY_TYPECODES = {
  [CS_PARTY_TYPES.TEXT]: { tc: CS_PARTY_TYPES.TEXT, value: BENEFICIARY_PARTY_TYPES.TEXT},
  [CS_PARTY_TYPES.PERSON]: { tc: CS_PARTY_TYPES.PERSON, value: BENEFICIARY_PARTY_TYPES.PERSON},
  [CS_PARTY_TYPES.ORGANIZATION]: { tc: CS_PARTY_TYPES.ORGANIZATION, value: BENEFICIARY_PARTY_TYPES.ORGANIZATION},
}

const BENEFICIARY_PARTY_TYPE_INDEX = {
  [CS_PARTY_TYPES.TEXT]: BENEFICIARY_PARTY_TYPES.TEXT,
  [CS_PARTY_TYPES.PERSON]: BENEFICIARY_PARTY_TYPES.PERSON,
  [CS_PARTY_TYPES.ORGANIZATION]: BENEFICIARY_PARTY_TYPES.ORGANIZATION,
}

const BENEFICIARY_DIST_OPTIONS = [
  'Interest Income Option',
  'Fixed Amount',
  'Fixed Period',
  'Life Income, No Death Benefit',
  'Life 5 years certain',
  'Life 10 years certain',
  'Life 20 years certain',
  'Life with install. Refund',
]

export {CS_PARTY_TYPES, BENEFICIARY_PARTY_TYPES,BENEFICIARY_OTHER_OPTIONS, BENEFICIARY_DIST_OPTIONS }

angular.module('app.beneficiaries')
  .constant('BENEFICIARY_OTHER_OPTIONS', BENEFICIARY_OTHER_OPTIONS)
  .constant('BENEFICIARY_PARTY_TYPES', BENEFICIARY_PARTY_TYPES)
  .constant('CS_PARTY_TYPES', CS_PARTY_TYPES)
  .constant('BENEFICIARY_DIST_OPTIONS', BENEFICIARY_DIST_OPTIONS)
  .constant('BENEFICIARY_PARTY_TYPE_INDEX', BENEFICIARY_PARTY_TYPE_INDEX)
