export {clientAdvisorService}
clientAdvisorService.$inject = ['$http', 'clientService', 'utils', 'CONSTANTS', 'policyService', '$q', 'clientUtils']

  /* @ngInject */
function clientAdvisorService($http, clientService, utils, CONSTANTS, policyService, $q, clientUtils) {
  const service = {}
  const self = this

  service.getClientInfo = function(clientId, page = 1, index) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'client': {},
      'index': index,
    }
    const url = `${CONSTANTS.apiRoot}client/relatedpolicies?page=${page}&id=${clientId}&${CONSTANTS.requirementsToFilterParams}`

    return $http.get(url)
        .then(service._getClientInfoSuccess.bind(service, result))
        .catch(service._getClientInfoFailure.bind(service, result))
        .then(service._getClientsByPolicy)
        .then(service._combineResults)
  }

  service.getClientInfoPolicyIds = function(clientInfo) {
    return clientInfo.client.policies.map(policy => policy.polNumber)
  }

    // Get the "registered" field from the policy result and slap it onto the client result.
  service._combineResults = function(resultsArray) {
    const clientServiceResult = resultsArray[0]
    const policyServiceResult = resultsArray[1]
    const rawClient = clientServiceResult.rawClient
    let matchingClient

    if (policyServiceResult && !policyServiceResult.error) {
      matchingClient = policyServiceResult.data.parties.filter(function(party) {
        return service._samePerson(rawClient, party)
      })[0]

      if (matchingClient) {
        clientServiceResult.client.registered = matchingClient.registered
      }
    }

    return clientServiceResult
  }

  service._getClientInfoFailure = function(result, httpData) {
    utils.fillAndLogError(httpData, result)
    return result
  }

  service._getClientInfoSuccess = function(result, httpData) {
    let policiesResult

    if (httpData.data) {
      if (httpData.data.count && httpData.data.count > 0) {
        if (httpData.data.count > 10) {
          self.moreData = true
        }
        result.rawClient = angular.copy(httpData.data.party, {}) // The un-processed client object. Needed for matching later.
        policiesResult = clientService.processPolicies(httpData.data.policies ? httpData.data.policies : [])
        result.client = clientService.processClientInfo(httpData.data.party, policiesResult.insuredSince)
        result.client.policies = policiesResult.policies
        result.client.policiesInfo = httpData.data
          // result.client.isOwner = service._isClientAnOwner(result.client) isOwner should come from the payload
      }

      return result
    }

    result.error = 'Undefined response'
    return result
  }

  service._isClientAnOwner = function(client) {
    const ownersNames = service.ownersFullNames(client.policies)

    if (client.policies && client.fullName) {
      return ownersNames.some((ownerName) => ownerName === client.fullName)
    }
  }

  service.ownersFullNames = function(policies) {
    const ownersArray = []

    if (policies) {
      policies.forEach((policy) => {
        if (policy.owners) {
          policy.owners.forEach((owner) => {
            owner.fullName = clientUtils.partyName(owner)
            ownersArray.push(owner.fullName)
          })
        }
      })
    }

    return ownersArray
  }

    // XXX: All the fuss because the client endpoint currently does not tell us if the client is registered for ClientSight.
  service._getClientsByPolicy = function(clientResult) {
    let policyId

    if (typeof clientResult.client.registered === 'undefined' && clientResult.client.policies && clientResult.client.policies[0]) {
      policyId = clientResult.client.policies[0].polNumber
      return $q.all([clientResult, policyService.getClientsRaw(policyId)])
    }

    return $q.all([clientResult, null])
  }

    // XXX: for whatever reason, the "id" fields don't match up between client and policy calls!
  service._samePerson = function(personA, personB) {
    const properties = ['firstName', 'middleName', 'lastName', 'calculatedAge', 'gender']
    let property
    let i

    for (i = 0; i < properties.length; i++) {
      property = properties[i]
      if (personA[property] !== personB[property]) {
        return false
      }
    }

    return true
  }

  return service
}
