export {allPoliciesService}

allPoliciesService.$inject = ['$http', '$filter', '$q', 'loggingService', 'utils', 'dateUtils', 'clientUtils', 'policyUtils', 'CONSTANTS', 'partyService']

  /* @ngInject */
function allPoliciesService($http, $filter, $q, loggingService, utils, dateUtils, clientUtils, policyUtils, CONSTANTS, partyService) {
  const service = {}
  const PRODUCT_TYPES_POSTFIX_NAME = 'ProductTypes'
  let currentPage = 0
  let loadingMore = false
  let lastAgentKey = ''

  service.policies = []
  service.message = ''
  service.status = ''
  service.error = ''
  service.numFound = 0
  service.connError = false
  service.moreData = true

  function resetErrorState() {
    service.connError = false
    service.message = ''
    service.status = ''
    service.error = ''
  }

  service.isLoadingMore = function() {
    return loadingMore // && service.policies.length > 0
  }

  service.isFirstPage = function() {
    return service.policies.length === 0
  }

  service.resetData = function() {
    service.moreData = true
    service.numFound = 0
    currentPage = 0
    service.policies = []
  }

  service.keepFirst10 = function() {
    currentPage = 1
    service.moreData = true
    if (service.policies.length >= 10) {
      service.policies.slice(0, 10)
    }
  }

  service._addAceIndicatorParameter = function(params, parameterAdder, url) {
    if (['Electronic', 'Paper'].indexOf(params.applicationMethod) !== -1) {
      return parameterAdder(url, 'aceindicator', params.applicationMethod === 'Electronic')
    }

    return url
  }

  service._addFormalityParameter = function(params, parameterAdder, url) {
    if (['Formal', 'Informal'].indexOf(params.applicationFormalities) !== -1) {
      return parameterAdder(url, 'formalAppIndicator', params.applicationFormalities === 'Formal')
    }

    return url
  }

  service._checkProductTypesFromState = function(stateParams, productTypes) {
    if (typeof stateParams.productType === 'string') {
      return productTypes.map(function(productType) {
        if (productType.value === stateParams.productType) {
          productType.checked = true
        }

        return productType
      })
    }

    if (angular.isArray(stateParams.productType)) {
      return productTypes.map(function(productType) {
        productType.checked = stateParams.productType.indexOf(productType.value) !== -1

        return productType
      })
    }

    return productTypes
  }

  service._nextPageUrl = (pageNumber, params) => {
    const routeSourceHoldingStatus = {
      inforce: '1', // Active
        // We have a different idea of what it means to be closed.
        // closed: '2', // Inactive
      pending: '3', // Pending
        // Our routes may support suspended or issued in the future.
      suspended: '4', // Suspended
      isssued: '1012300004', // Issued
      notpaid: '1012300005', // Issued Not Paid
    }

    let url = `${CONSTANTS.apiRoot}agent/whatismybusiness?page=${pageNumber}&` + CONSTANTS.requirementsToFilterParams

    if (params.source === CONSTANTS.policySource.closed) {
        // A special case for our purposes
      url = params.stage === 'New Business' ? utils.appendURLParameter(url, 'holdingstatus', CONSTANTS.holdingStatusTypes.closed.typeCode) : utils.appendURLParameter(url, 'holdingstatus', CONSTANTS.holdingStatusTypes.inactive.typeCode)
    } else {
      url = utils.appendURLParameter(url, 'holdingstatus', routeSourceHoldingStatus[params.source])
      url = utils.appendURLParameter(url, 'substatus', params.deliveryStatus)
    }

    if (params.source === CONSTANTS.policySource.pending) {
      url = utils.appendURLParameter(url, 'sort', 'lastupdatedate')
      url = utils.appendURLParameter(url, 'order', 'desc')
    }

    if (params.lineOfBusiness !== 'All') url = utils.appendURLParameter(url, 'lineofbusiness', params.lineofbusiness)

    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())
    url = utils.appendURLParameter(url, 'productType', service._wrapComplexUrlParameter(params.productType))
    url = utils.appendURLParameter(url, 'policyStatus', service._wrapComplexUrlParameter(params.policyStatusFilters))
    url = utils.appendURLParameter(url, 'adressstate', params.adressstate)

      /*
        includeState : Can be multiple, requires full state name, takes precedence over exclude param
        excludeState : Can be multiple, requires full state name, will not take effect if include is present
       */
      // url = utils.appendURLParameter(url, 'includeState', 'New Jersey')
    if (params.issueState && params.issueState === CONSTANTS.NYBusiness.criteria.excludeNY) {
      url = utils.appendURLParameter(url, 'excludeState', CONSTANTS.NYBusiness.ny)
    } else if (params.issueState && params.issueState === CONSTANTS.NYBusiness.criteria.NYOnly) {
      url = utils.appendURLParameter(url, 'includeState', CONSTANTS.NYBusiness.ny)
    }

    url = utils.appendURLParameter(url, 'issuestate', params.issuestate)
    url = utils.appendURLParameter(url, 'fundID', params.fundID)
    url = utils.appendURLParameter(url, 'fundFamily', params.fundFamily)

    url = service._addAceIndicatorParameter(params, utils.appendURLParameter, url)
    url = service._addFormalityParameter(params, utils.appendURLParameter, url)

    if (params.applicationFormalities === 'Term Conversion') {
      url = utils.appendURLParameter(url, 'formalappIndicator', true)
      url = utils.appendURLParameter(url, 'appType', 'Term Conversion')
    }

    return url
  }

    // @deprecate
  service._wrapComplexUrlParameter = function(parameter) { // TODO we should be using typeCodes instead
    if (!parameter || !parameter.length) {
      return ''
    }

    if (angular.isArray(parameter)) {
      return '(%22' + parameter.join('%22 OR %22') + '%22)'
    }

    if (parameter.indexOf('(') === 0) {
        // Assume it's already wrapped
      return parameter
    }

    return '(%22' + parameter + '%22)'
  }

  service.nextPage = function(params = {}) {
    const currentAgentKey = partyService.getAgentKey()
    let policies, i, p
    let advisorNameList, length

    loadingMore = true

    if (currentPage === 0) {
      service.resetData()
    }

    if (lastAgentKey !== currentAgentKey) {
      lastAgentKey = currentAgentKey
      service.resetData()
    }

    currentPage++
    resetErrorState()

    return $http.get(service._nextPageUrl(currentPage, params))
        .then(
          function(httpData) {
            if (httpData.data) {
              service.numFound = httpData.data.numFound
              service.moreData = service.numFound > 11
              policies = httpData.data.policies

              if (!policies) {
                service.moreData = false
                loadingMore = false
                return httpData
              }

              if (policies.length === 0) {
                service.moreData = false
                if (service.policies && service.policies.length === 0) {
                  service.message = 'Undefined response'
                }
              } else {
                for (i = 0; i < policies.length; i++) {
                  p = policies[i]
                  p.hoReceiptDate = dateUtils.parseDate(p.hoReceiptDate)
                  p.submitDate = dateUtils.parseDate(p.submitDate)
                  p.deathBenefitAmt = typeof p.grossDeathBenefitAmt === 'undefined' ? p.netDeathBenefitAmt : p.grossDeathBenefitAmt
                  p.anniversaryDate = p.anniversaryDate || clientUtils.getAnniversaryDate(p.effDate)
                  p.lastUpdateDate = p.lastUpdateDate ? dateUtils.parseDate(p.lastUpdateDate) : dateUtils.parseDate(p.asOfDate)
                  p.type = CONSTANTS.policySource.pending
                  p.requirementInfo = policyUtils.calcRequirementsMet(p.requirementsStatus)
                  p.isAnnuity = p.lineOfBusiness && p.lineOfBusiness.toLowerCase() === CONSTANTS.annuityLineOfBusiness.toLowerCase()
                  p.paymentAmountValue = policyUtils.getPaymentAmountValue(p.isAnnuity, p.faceAmt, p.initPaymentAmt, p.paymentAmt)
                  p.policyStatus = utils.firstToUpper(p.policyStatus)
                  p.owner = clientUtils.parsePartyDetails(p.owner)
                  if (p.jointOwner) { // annuities don't have multiple owners, they have one plus an optional joint owner [WIT-48]
                    p.owner.isMultiple = true
                    p.owner.fullName = p.owner.fullName + ', +1'
                  }
                  p.payor = clientUtils.parsePartyDetails(p.payor)
                  p.beneficiary = clientUtils.parsePartyDetails(p.beneficiary)
                  p.nyIssued = p.issueState === 'NY' || p.issueState === 'New York'

                  advisorNameList = p.primaryServicingAgent

                  if (advisorNameList) {
                    p.advisorFullName = advisorNameList.length === 1 ? advisorNameList[0] : CONSTANTS.multipleAdvisorNameLabel
                  }

                  if (p.isAnnuity) {
                    if (p.annuitants && p.annuitants.length > 0) {
                      p.insured = clientUtils.parsePartyDetails(p.annuitants)
                      p.insured.birthDate = dateUtils.parseDate(p.annuitants[0].birthDate)
                    }
                  } else if (p.insureds && p.insureds.length > 0) {
                    p.insured = clientUtils.parsePartyDetails(p.insureds)
                    p.insured.birthDate = dateUtils.parseDate(p.insureds[0].birthDate)
                  }
                }

                service.policies = service.policies.concat(policies || [])

                if (service.policies.length === service.numFound) {
                  service.moreData = false
                }

                length = $filter('number')(service.policies.length)

                if (length === 1) {
                  service.message = 'Showing ' + length + ' Pending Policy'
                } else {
                  service.message = 'Showing ' + length + ' Pending Policies'
                }
              }
            } else {
              service.message = 'Undefined response'
            }

            loadingMore = false
            return httpData
          },
          // TODO: This should be a catch for the sake of readability
          function(httpData) {
            service.moreData = false
            if (httpData.status === -1) {
              service.connError = true
              service.error = httpData.statusText
              service.message = httpData.statusText
            } else if (httpData.status === 500) {
              service.status = httpData.status
              service.error = httpData.statusText
              service.message = httpData.statusText
            } else if (httpData.status === 404 || service.policies.length === 0) {
              service.message = 'Sorry, no results found'
            }

            loggingService.log(httpData.statusText, ', ' + httpData.data + ', Url: ' + httpData.config.url, 'error')
            loadingMore = false
            return httpData
          })
  }

  service.pendingLifeProductTypes = []
  service.pendingAnnuityProductTypes = []

  service.inforceLifeProductTypes = []
  service.inforceAnnuityProductTypes = []

  function getCollectionName(params) {
    params = params || {}
    params.lineofbusiness = params.lineofbusiness || ''
    params.source = params.source || ''

    if (params && params.lineofbusiness) {
      return params.source + params.lineofbusiness + PRODUCT_TYPES_POSTFIX_NAME
    }

    return ''
  }

  function areProductTypesAvailable(params) {
    const collectionName = getCollectionName(params)

    if (collectionName && service[collectionName] && service[collectionName].length > 0) {
      return true
    }

    return false
  }

  service.getCachedProductTypes = function(params) {
    let result

    if (areProductTypesAvailable(params)) {
      result = {
        'error': '',
        'status': '',
        'statusText': '',
        'productTypes': [],
      }

      result.productTypes = service[getCollectionName(params)]
      return $q.when(result)
    }
    return service.getProductTypes(params)
  }

  service._productTypesUrl = params => {
    let url = CONSTANTS.productTypesUrl  // .replace('{/source}', '/' + params.source)

    if (params.source === CONSTANTS.policySource.inForce) {
      url = utils.appendURLParameter(url, 'holdingstatus', CONSTANTS.holdingStatusTypes.inforce.typeCode)
    } else if (params.source === CONSTANTS.policySource.notPaid) {
        // url = CONSTANTS.productTypesUrl.replace('{/source}', '')
      url = utils.appendURLParameter(url, 'holdingstatus', CONSTANTS.holdingStatusTypes.notpaid.typeCode)
    }

    if (params.source === CONSTANTS.policySource.pending) {
      url = utils.appendURLParameter(url, 'holdingstatus', CONSTANTS.holdingStatusTypes.pending.typeCode)
    }

    if (params.source === CONSTANTS.policySource.closed) {
        // url = CONSTANTS.productTypesUrl.replace('{/source}', '')
      url = utils.appendURLParameter(url, 'holdingstatus', 'Closed')
      url = utils.appendURLParameter(url, 'holdingstatus', 'Inactive')
    }

    url = utils.appendURLParameter(url, 'filterKey', partyService.getAgentKey())
    if (params.lineofbusiness && params.lineofbusiness !== 'All') url = utils.appendURLParameter(url, 'lineofbusiness', params.lineofbusiness)

    return url
  }

  service.getProductTypes = function(params = {}) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'productTypes': [],
    }
    const url = service._productTypesUrl(params)

      // console.log('getProductTypes', params, url)

    return $http.get(url)
        .then(
          function(httpData) {
            if (!httpData.data) {
              result.error = 'Undefined response'
              return result
            }
            result.productTypes = (httpData.data.counts || [])
              .filter(function(productType) {
                // For some reason we sometimes get a productType without a value.
                return Boolean(productType.value)
              })
            service[getCollectionName(params)] = result.productTypes

            return result
          },
          function(httpData) {
            utils.fillAndLogError(httpData, result)
            return {
              productTypes: {},
            }
          }
        )
  }

  service.getClosedPolicyStatuses = function(holdingStatus, stage) {
    const result = {
      'error': '',
      'status': '',
      'statusText': '',
      'policyStatuses': [],
    }

    const url = `${CONSTANTS.apiRoot}policy/counts/policystatus?holdingstatus=${holdingStatus}`

    return $http.get(url)
        .then(
          function(httpData) {
            if (!httpData.data) {
              result.error = 'Undefined response'

              return result
            }

            result.policyStatuses = (httpData.data.counts || [])
              .filter(policyStatus => Boolean(policyStatus.value))

            return result
          }
        )
  }

  return service
}
