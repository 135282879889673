exception.$inject = ['loggingService']

  /* @ngInject */
function exception(loggingService) {
  const service = {
    catcher: catcher,
  }

  return service

  function catcher(message) {
    return function(reason) {
      loggingService.log(message + reason, 'error')
    }
  }
}

export {exception}
