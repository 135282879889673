import angular from 'angular'
import { transactionDetailsComponentConfig } from './components/details/transaction-details-component'
import { transactionHistoryClientComponentConfig } from './components/client-history/transaction-history-client-component'
import { transactionHistoryComponentConfig } from './components/history/transaction-history-component'
import { transactionsMainComponentConfig } from './components/main/transactions-main-component'
import { transactionSummaryComponentConfig } from './components/summary/transaction-summary-component'
import { TransactionHistoryDataProvider } from './transaction-history-data-provider'
import { TransactionHistoryRunner } from './transaction-history-routes'

angular.module('app.transactions', ['app.utils', 'app.core'])
  .service('transactionHistoryDataProvider', TransactionHistoryDataProvider)
  .component('transactionHistoryComponent', transactionHistoryComponentConfig)
  .component('transactionHistoryClientComponent', transactionHistoryClientComponentConfig)
  .component('transactionSummaryComponent', transactionSummaryComponentConfig)
  .component('transactionsMainComponent', transactionsMainComponentConfig)
  .component('transactionDetailsComponent', transactionDetailsComponentConfig)
  .run((routerHelper) => new TransactionHistoryRunner(routerHelper))
