(function() {
  'use strict'

  angular.module('app.directives').directive('wgClientLink', ClientLink)

  function ClientLink() {
    return {
      restrict: 'A',
      scope: {
        client: '=',
      },
      templateUrl: 'app/widgets/templates/client-link.html',
      controller: ClientLinkController,
      controllerAs: 'wgClientLinkCtrl',
      bindToController: true,
    }
  }

  ClientLinkController.$inject = ['stateTransitions']

  /* @ngInject */
  function ClientLinkController(stateTransitions) {
    const self = this

    self.showClient = function() {
      const id = self.client.id ? self.client.id : self.client.clientID

      stateTransitions.go('client.agent', {
        'id': id,
      })
    }
  }
})()
