class TableViewComponentController {
  static $injector: any = []

  // constructor() { // }

  // $onInit() {}
}

const AllPoliciesTableView = {
  templateUrl: 'app/search/all/table/main.html',
  controller: TableViewComponentController,
  controllerAs: 'tableViewCtrl',
  bindings: {
    lineOfBusiness: '<',
    productType: '<',
    listViewOptions: '<',
    includeState: '<',
    aceIndicator: '<',
  },
}

export { AllPoliciesTableView }
