import * as angular from 'angular'

import { EpayController } from './epay-controller'
import { EpayMessageResolver } from './epay-message-resovler'
import { appRun } from './epay-routes'
import { epayService } from './epay-service'
import { EpayUIStateService } from './epay-ui-state-service'

angular.module('app.client.epay', ['app.client', 'app.contact', 'app.download.forms'])
angular.module('app.client.epay')
    .service('epayMessageResolver', EpayMessageResolver)

angular.module('app.client.epay')
    .service('epayUIState', EpayUIStateService)
    .controller('EpayController', EpayController)
    .factory('epayService', epayService)
    .run(appRun)
