/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/dot-notation */
import * as angular from 'angular'
import { IPromise } from 'angular'
import { FORMS_MAJOR_SECTION_NAME } from './forms-constants'
import { ADDRESS_MESSAGE_MAP } from './address-constants'
import { IFormsManifest, ILobSection, ILobSectionType, IManifestLobSection } from './types'

const NEW_YORK_TC = '37'
export const RX_PNY_POLICY_PREFIX = RegExp(/^(00B)/)

export class CompaniesService {
  static $inject: any = [
    '$q',
    '$http',
    'BUSINESS_UNITS',
    'ADDRESS_FRAGMENTS',
    'lazyLoadingDataService',
  ]

  private majorSectionNames

  constructor(
    private $q: angular.IQService,
    private $http: angular.IHttpService,
    private BUSINESS_UNITS,
    private ADDRESS_FRAGMENTS,
    private lazyLoadingDataService,
  ) {
    this.majorSectionNames = FORMS_MAJOR_SECTION_NAME
  }

  /**
   *
   * @param a
   * @param b
   */
  _arraysEqual(a: string[], b: string[]): boolean {
    if (a.length !== b.length) return false

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }

    return true
  }

  resolvePMLNYpolicies(hasPMLorPIA, policies): boolean {
    if (hasPMLorPIA) {
      const hasNonPNY = policies.some(
        (policy) =>
          policy.issueState === 'New York' &&
          !RX_PNY_POLICY_PREFIX.test(policy.polNumber),
      )

      console.log('hasNonPNY', hasNonPNY)
      return hasNonPNY
    }

    return hasPMLorPIA
  }

  /**
   *
   * @param states
   * @param companies
   */
  resolveAddressTypes(companies: any[], policies: any[]): string[] {
    const hasPNYpolicies: boolean = companies.some((aCompany) =>
      [this.BUSINESS_UNITS.PNY].includes(aCompany.company),
    )
    const hasPMLNYpolicies: boolean = policies.some(
      (policy) =>
        policy.issueState === 'New York' &&
        !RX_PNY_POLICY_PREFIX.test(policy.polNumber),
    )
    const hasPMLorPIA: boolean = companies.some((aCompany) =>
      [this.BUSINESS_UNITS.PML, this.BUSINESS_UNITS.PIA].includes(
        aCompany.company,
      ),
    )
    const hasNonNewYork: boolean = policies.some(
      (policy) => policy.issueState !== 'New York',
    )
    const addressesTypes: any = {}

    if (hasPNYpolicies) {
      addressesTypes[this.BUSINESS_UNITS.PNY] = this.BUSINESS_UNITS.PNY
    }
    if (hasPMLNYpolicies) {
      addressesTypes[this.BUSINESS_UNITS.PML_NY] = this.BUSINESS_UNITS.PML_NY
    }
    if (hasPMLorPIA && hasNonNewYork) {
      addressesTypes[this.BUSINESS_UNITS.PML] = this.BUSINESS_UNITS.PML
    }

    // console.log('hasNonNewYork', hasNonNewYork, 'hasPML_NYpolicies', hasPML_NYpolicies, 'hasPNYpolicies', hasPNYpolicies, 'hasPMLorPIA', hasPMLorPIA, '\naddressesTypes', addressesTypes)

    return Object.keys(addressesTypes).sort()
  }

  /**
   *
   * @param addressTypes
   */
  resolveAddressMessages(addressTypes: string[]): any {
    const matchedMessages: any = ADDRESS_MESSAGE_MAP.find((aMap) => {
      return this._arraysEqual(aMap.addressTypes, addressTypes)
    })

    return matchedMessages
  }

  /**
   *
   * @param states
   * @param companies
   * @param policies
   */
  resolveDisplayAddresses(companies: any[], policies: any[]) {
    const addressTypes = this.resolveAddressTypes(companies, policies)
    const addressMessages = this.resolveAddressMessages(addressTypes)
    const displayAddresses = addressTypes.map((aType) => {
      return {
        type: aType,
        message: addressMessages.messages[aType],
        address: this.ADDRESS_FRAGMENTS[aType],
      }
    })

    return displayAddresses
  }

  /**
   *
   */
  getMailingLocations() {
    return this.lazyLoadingDataService.myPolicies().then((myPolicies) => {
      const { companies, policies } = myPolicies.client.policiesInfo
      const mailingLocations: any[] = this.resolveDisplayAddresses(
        companies,
        policies,
      )

      return mailingLocations
    })
  }

  _formsManifest: any
  getFormsManifest(clearCache: boolean = false): IPromise<any> {
    const url = '/company-forms/manifest.json'

    if (this._formsManifest === undefined || clearCache) {
      return this.$http.get(url).then((result: any) => {
        this._formsManifest = result.data

        return this._formsManifest
      })
    }

    console.log(this._formsManifest)
    return this.$q.resolve(this._formsManifest)
  }

  /**
   *
   * @param policies
   */
  resolveLinesOfBusiness(policies) {
    const linesOfBusiness = Object.keys(
      policies.reduce((acc, policy) => {
        acc[policy.lineOfBusiness.toLowerCase()] = true

        return acc
      }, {}),
    )

    return linesOfBusiness
  }

  resolveCompany(policy: any): string {
    if (policy.jurisdiction === 'New York' && !RX_PNY_POLICY_PREFIX.test(policy.polNumber)) return this.BUSINESS_UNITS.PML_NY
    if (policy.carrierCode === this.BUSINESS_UNITS.PIA) return this.BUSINESS_UNITS.PML

    return policy.carrierCode
  }

  /**
   *
   */
  getOrganizedForms(): any {
    const promises = [
      this.lazyLoadingDataService.myPolicies().then((myPolicies) => {
        const { companies, policies } = myPolicies.client.policiesInfo
        const addressTypes = this.resolveAddressTypes(companies, policies)
        const formMessages = this.resolveAddressMessages(addressTypes)
        const linesOfBusiness = this.resolveLinesOfBusiness(policies)

        return {
          linesOfBusiness,
          addressTypes: formMessages.addressTypes,
          sectionHeaders: formMessages.messages,
        }
      }),
      this.getFormsManifest(),
    ]

    return this.$q.all(promises).then(([policiesInfo, formsManifest]) => {
      const lobSections = policiesInfo.linesOfBusiness.map((lob) => {
        const lobManifest = formsManifest[lob]
        const sectionName = this.majorSectionNames[lob]
        const lobSection: any = { lob, sectionName, sectionTypes: [] }

        if (lob === 'all') {
          const tmp = { type: 'all', heading: '', forms: lobManifest.forms }

          lobSection.sectionTypes.push(tmp)
        } else {
          lobSection.sectionTypes = policiesInfo.addressTypes.map((aType) => {
            const companyForms = lobManifest.forms[aType]?.sort((a, b) => this.sectionNameSort(a, b))
            const heading = policiesInfo.sectionHeaders[aType]
            const companySection = { type: aType, heading, forms: companyForms }

            return companySection
          })
        }

        return lobSection
      })

      return lobSections
    })
  }

  /**
   *
   */

  getAllForms(): any {
    return this.getFormsManifest().then((formsManifest: IFormsManifest) => {
      const manifestLobSections: IManifestLobSection[] = Object.values(formsManifest)

      const lobSections: ILobSection[] = manifestLobSections.map((entry: IManifestLobSection) => {
        const lobSection: ILobSection = {
          lob: this.getFirstword(entry.sectionName), // assign section name
          sectionName: entry.sectionName,
          sectionTypes: [],
        }

        // assign region types
        // entry['sectionTypes'] = Object.entries(entry['forms']).map((key, value) => this.mapBlahForm(key, value))
        Object.entries(entry.forms).forEach(([key, value]) => {
          // console.log(`${key}${value}`) // "a 5", "b 7", "c 9"
          lobSection.sectionTypes.push(this.mapFormEntriestPerSection(key, value))
        })

        return lobSection
      })

      return lobSections
    })
  }

  getPolicyForms(policy: any): any {
    const lob = policy.lineOfBusiness.toLowerCase()
    const company = this.resolveCompany(policy)

    return this.getAllForms().then((lobSections: ILobSection[]) => {
      const sourceLobSection: ILobSection = lobSections.find((lobSection: ILobSection) => lobSection.lob === lob)
      const foundSectionType: ILobSectionType = sourceLobSection.sectionTypes.find((sectionType: ILobSectionType) => sectionType.type === company)
      const newLobSection: ILobSection = { ...sourceLobSection, sectionTypes: [foundSectionType]}

      return newLobSection
    })
  }

  private sectionNameSort(a, b) {
    const nameA = a.displayName.toUpperCase() // ignore upper and lowercase
    const nameB = b.displayName.toUpperCase() // ignore upper and lowercase

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  }

  private mapFormEntriestPerSection(subsectionName, value): ILobSectionType{
    value.sort((a, b) => this.sectionNameSort(a, b))

    const heading = this.resolveAddressMessages(['PML', 'PML-NY', 'PNY'])

    return {
      type: subsectionName,
      heading: heading.messages[subsectionName],
      forms: value,
    }
  }

  private getFirstword(str: string): string {
    return str.split(' ')[0].toLowerCase()
  }
}
