import * as angular from 'angular'
import {CLandRRunner} from './routes'
import {credentialsComponentConfig} from './controller'
import {CLandRDataProviderService} from './cl-n-r-data-provider-service'

angular.module('app.cl-and-r', ['app.utils'])
  .run(routerHelper => new CLandRRunner(routerHelper))
  .service('CLandRDataProvider', CLandRDataProviderService)
  .component('credentialsComponentConfig', credentialsComponentConfig)
  .constant('CLANDR_ENDPOINTS', {
    TRAININGS: '/proxy/core-services-gateway/agent/training',
    APPOINTMENTS: '/proxy/core-services-gateway/agent/appointments',
    LICENSES: '/proxy/core-services-gateway/agent/licenses',
    CONTRACTS: '/proxy/core-services-gateway/agent/contracts',
  })
