export { CorrespondenceController }
CorrespondenceController.$inject = ['pids', 'correspondenceResult', 'taxDocuments', 'correspondenceService', 'CONSTANTS', 'authService', 'policyUtils', 'popupManager', 'utils', 'googleAnalyticsService']

/* @ngInject */
function CorrespondenceController(pids, correspondenceResult, taxDocuments, correspondenceService, CONSTANTS, authService, policyUtils, popup, utils, googleAnalyticsService) {
  const self = this
  const policyIDs = []
  const docTypePopup = 'docType'
  const DEFAULT_FILTER_VALUE = 'None'
  let unfilteredCorrespondenceResult

  self.years = []
  self.errorMessage = ''
  self.isThereError = false
  self.noCorrespondence = false
  self.filters = []
  self.appliedFiltersString = DEFAULT_FILTER_VALUE
  self.taxDocuments = taxDocuments

  popup.register(docTypePopup)

  self.specialDocIDs = {
    'applicationDocId': null,
    'illustrationDocId': null,
    'policyPagesDocId': null,
  }

  self.clearFilters = function () {
    self.filters.forEach(function (filter) {
      filter.checked = false
    })

    if (unfilteredCorrespondenceResult) {
      correspondenceResult = unfilteredCorrespondenceResult
      submitInit()
      setAppliedFiltersString()
    } else {
      self.getSelectedDocTypes()
    }
  }

  self.isFilterActive = function () {
    return self.appliedFiltersString !== DEFAULT_FILTER_VALUE
  }

  self.isHiddenSubMenu = function () {
    return policyIDs.length > 1
  }

  self.getClassByType = function (type) {
    if (unfilteredCorrespondenceResult) {
      correspondenceResult = unfilteredCorrespondenceResult
      submitInit()
      setAppliedFiltersString()
    } else {
      self.getSelectedDocTypes()
    }
  }

  self.isFilterActive = function () {
    return self.appliedFiltersString !== DEFAULT_FILTER_VALUE
  }

  self.isHiddenSubMenu = function () {
    return policyIDs.length > 1
  }

  self.showCorrespondence = function (docId) {
    const doc = correspondenceResult.documents.find((doc) => doc.docId === docId)
    correspondenceService.showCorrespondence(docId)
    googleAnalyticsService.send('open_correspondence', doc?.docType, { policyId: doc?.polNumber })
  }

  self.getClassByType = correspondenceService.getClassByType

  self.showLinks = function () {
    const noApp = !self.specialDocIDs.applicationDocId
    const noIllustration = !self.specialDocIDs.illustrationDocId
    const noPages = !self.specialDocIDs.policyPagesDocId

    return noApp && noIllustration && noPages
  }

  self.toggleDocTypeMenu = function (e) {
    popup.toggle(docTypePopup, e)
  }

  self.isDocTypeMenuOpen = function () {
    return popup.isOpen(docTypePopup)
  }

  self.getSelectedDocTypes = function () {
    const docTypes = self.filters
    const checkedTypes = []
    let i

    for (i in docTypes) {
      if (docTypes[i].checked) {
        checkedTypes.push(docTypes[i].typeCode)
      }
    }

    // Only cache the unfiltered correspondence result,
    // because that's what's expected to be retrieved when filters are cleared.
    if (!self.isFilterActive()) {
      unfilteredCorrespondenceResult = correspondenceResult
    }

    correspondenceService.getFilteredCorrespondences(pids, checkedTypes)
      .then(function (result) {
        self.years = []
        correspondenceResult = result
        submitInit()
      })
    self.toggleDocTypeMenu()
    setAppliedFiltersString()
  }

  self._createColumns = function () {
    self.columns = utils.createListColumns(self.filters, 2)
  }

  self._setAllFilters = function () {
    let prop
    let p

    for (prop in CONSTANTS.correspondenceDocTypes) {
      p = CONSTANTS.correspondenceDocTypes[prop]
      self.filters.push({ checked: false, typeCode: p.typeCode, desc: p.desc })
    }
  }

  self._setFilters = function (httpData) {
    if (!httpData.error && httpData.data.documents.length) {
      httpData.data.documents.forEach(function (doc) {
        self.filters.push({ checked: false, typeCode: doc.docTypeTC, desc: doc.docType })
      })
    } else {
      self._setAllFilters()
    }
  }

  function setAppliedFiltersString() {
    const appliedTypes = self.filters.filter(function (filter) {
      return filter.checked
    })
      .map(function (filter) {
        return filter.desc
      })

    self.appliedFiltersString = appliedTypes.length ? appliedTypes.join(', ') : DEFAULT_FILTER_VALUE
  }

  function submitInit() {
    let i
    let docs, lastDate, doc
    let d, y, m
    let yInList, mInList
    let pid

    if (!correspondenceResult.error) {
      correspondenceService.fillSpecialDocIDs(correspondenceResult.documents, self.specialDocIDs)

      if (correspondenceResult.documents.length > 0) {
        self.noCorrespondence = false

        docs = correspondenceResult.documents
        lastDate = docs[0].modDate

        // TODO: This loop is duplicated in compensation-controller.js
        for (i = 0; i < docs.length; i++) {
          doc = docs[i]
          d = new Date(doc.modDate)
          y = d.getFullYear()
          m = d.getMonth()

          yInList = findYear(y)
          mInList = findMonth(yInList, CONSTANTS.MONTHS[m])

          doc.day = d.getDate()
          doc.latest = doc.modDate === lastDate

          mInList.documents.push(doc)
          pid = policyUtils.convertToShortPID(doc.polNumber)

          if (policyIDs.indexOf(pid) === -1) {
            policyIDs.push(pid)
          }
        }
      } else {
        self.noCorrespondence = true
      }
    } else {
      setError(correspondenceResult.error)
    }
  }

  function findYear(y) {
    let i

    for (i = 0; i < self.years.length; i++) {
      if (self.years[i].name === y) {
        return self.years[i]
      }
    }

    self.years.push({
      name: y,
      months: [],
    })

    return self.years[self.years.length - 1]
  }

  function findMonth(year, m) {
    let i

    for (i = 0; i < year.months.length; i++) {
      if (year.months[i].name === m) {
        return year.months[i]
      }
    }

    year.months.push({
      name: m,
      documents: [],
    })

    return year.months[year.months.length - 1]
  }

  function setError(error) {
    self.errorMessage = error
    self.isThereError = Boolean(self.errorMessage)
  }

  function init() {
    submitInit()

    correspondenceService
      .getRelevantDocumentTypes(pids)
      .then(self._setFilters)
      .then(self._createColumns)
  }

  init()
}
