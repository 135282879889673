import { UserSettingsService } from './user-settings.service'
import * as angular from 'angular'
import { NotificationsComponent } from './notifications/notifications.component'
import { UserSettingsRunner } from './routes'
import { EDeliveryComponent } from './e-delivery/e-delivery.component'
import { UserSettingViewStateService } from './user-settings-view-state-service'

angular.module('app.user-settings', ['app.utils'])
  .run(routerHelper => new UserSettingsRunner(routerHelper))
  .service('userSettingsService', UserSettingsService)
  .service('userSettingsViewStateService', UserSettingViewStateService)
  .component('notificationsComponent', NotificationsComponent)
  .component('eDeliveryComponent', EDeliveryComponent)
