/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as angular from 'angular'
import { AddressValidationResult, IAddressMininal, IAddressPoliciesResponse, IAddressSuccessResult } from './types'
export class ClientAddressService {
  constructor (private $http: angular.IHttpService, private CONSTANTS, private authService, private utils, private $q: angular.IQService) {
  }

  saveAddress(ids: Array<string>, address: AddressValidationResult) {
    const url = `${this.CONSTANTS.apiRoot}client/update/addresses`
    const data = {
      ids: ids,
      address: {
        line1: address.line1,
        line2: address.line2,
        line3: address.line3,
        attention: address.attention,
        city: address.city,
        state: address.state,
        zip4: address.zip4,
        zip5: address.zip5,
        addressCountry: {
          value: 'United States',
          tc: '1',
        },
        barCodeCheckDigit: '4',
        addressScrubInd: address.addressScrubInd,
      },
    }

    return this.$http.post<IAddressSuccessResult>(url, data)
  }

  validate (address: IAddressMininal) {
    let url = `${this.CONSTANTS.apiRoot}address/standardize`

    return this.$http.post<AddressValidationResult>(url, address)
  }

  getAddresses (clientId?: string): angular.IHttpPromise<IAddressPoliciesResponse> {
    const isHomeOffice = this.authService.isInRole('HOMEOFFICE')
    let url = this.CONSTANTS.clientAddressesURL //     'clientAddressesURL': DATA_API_BASEURL + 'client/whataremyaddresses',

    if (isHomeOffice) {
      url = this.utils.appendURLParameter(url, 'id', clientId)
    }

    return this.$http.get<IAddressPoliciesResponse>(url)
  }

  public getAddressesByClientId(clientId: string) {
    const url = `${this.CONSTANTS.apiRoot}client/whataremyaddresses/me?id=${clientId}`

    return this.$http.get<IAddressPoliciesResponse>(url).then((result) => {
      return result.data.addressPolicies
    })

    return this.$q.resolve([])
  }
}

ClientAddressService.$inject = ['$http', 'CONSTANTS', 'authService', 'utils', '$q']
