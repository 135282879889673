WgPolicyProductInfoCtrl.$inject = ['dateUtils', 'authService', 'notificationCenterService', '$scope', '$state']

function WgPolicyProductInfoCtrl(dateUtils, authService, notificationCenterService, $scope, $state) {
  const self = this

  self.marketingName = ''

  self.monthsActive = function(issueDate, now) {
    if (!issueDate) {
      return 0
    }

    now = now || new Date()
      // At the least, we display one month active even if that policy was just opened today.
    return dateUtils.dateDiffMonthIndex(issueDate, now) + 1
  }

  self.showYearsActive = function() {
    return self.summary.holdingStatus === 'Active' && self.summary.policyStatus !== 'Issued Not Paid'
  }

  self.marketingName = function() {
    return self.summary.source === 'rps' ? 'Annuity Payout' : self.summary.marketingName
  }

  self.gotoPolicy = (pid) => {
    console.log($state.$current)
    if($state.$current.name === 'policy'){
      return
    } else if($state.$current.name.startsWith('policy.')) {
      console.log(self.summary)
      $state.go('policy', { source: self.summary.source, pid: self.summary.polNumber })
    }
  }

  self.copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
          console.log('Policy number copied to clipboard...')
          notificationCenterService.add('info', `Policy Number ${text} copied to clipboard`, 'system', 0)
          $scope.$apply() // this triggers a $digest
        })
        .catch(err => {
          console.log('Something went wrong copying to clipboard', err)
        })
  }

  self.isARealClient = authService.isARealClient()
}

angular
  .module('app.directives')
  .controller('WgPolicyProductInfoCtrl', WgPolicyProductInfoCtrl)
  .directive('wgPolicyProductInfo', [function() {
    return {
      restrict: 'EA',
      scope: {
        summary: '=',
      },
      templateUrl: 'app/widgets/templates/policy-product-info.html',
      controller: WgPolicyProductInfoCtrl,
      controllerAs: 'wgPolicyProductInfoCtrl',
      bindToController: true,
    }
  }])
