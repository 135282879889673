/* eslint-disable no-underscore-dangle */
import { TypeCodeValue } from './../classes/type-code-value'
import { ITypeCodeValue } from '../types'

export const ROLE_CONSTANTS = {
  RELATION_NAMES: {} as any,
  RELATION_TYPE_CODES: {} as any,
}

export const RELATION_UNDEFINED = {tc: '', value: ''}
export class RoleRelationshipsService {
  static $inject: string[] = ['$q', 'beneficiariesDataProvider', 'BENEFICIARY_PARTY_TYPES', 'CS_PARTY_TYPES']

  DEFAULT_OPTIONS = {
    person: { tc: '', isSeparator: true, value: 'Choose Relationship' },
    organization: { tc: '', isSeparator: true, value: 'Business Type' },
    text: { tc: '', isSeparator: true, value: 'Choose Relationship' },
    estate: { tc: '', isSeparator: true, value: 'Relationship' },
  }

  _allRelationships: any = null
  _indexedRelationships: any = {}
  _indexKeys: any = {}
  MISC_RELATION_TYPES: string[] = ['2147483647', '1012300009']

  constructor(private $q: angular.IQService, private beneficiariesDataProvider, private BENEFICIARY_PARTY_TYPES, private CS_PARTY_TYPES) { }

  get standardDefaultOption(): any {
    return this.DEFAULT_OPTIONS.person
  }

  load() {
    if (this._allRelationships === null) {
      return this.beneficiariesDataProvider.getRelationships()
        .then((relationships) => {
          this._allRelationships = { 1: [] as ITypeCodeValue[], 2: [] as ITypeCodeValue[], 0: [] as ITypeCodeValue[], business: [] as ITypeCodeValue[], trust: [] as ITypeCodeValue[], estate: [] as ITypeCodeValue[], clientPortal: [] as ITypeCodeValue[] }

          this._allRelationships[this.CS_PARTY_TYPES.PERSON] = ([] as ITypeCodeValue[]).concat(relationships[this.CS_PARTY_TYPES.PERSON]).map((relation) => new TypeCodeValue(relation))

          this._allRelationships['clientPortal'] = ([] as ITypeCodeValue[]).concat(relationships['client-portal-relationships']).map((relation) => new TypeCodeValue(relation))

          this._allRelationships[this.CS_PARTY_TYPES.ORGANIZATION] = ([] as ITypeCodeValue[]).concat(relationships[this.CS_PARTY_TYPES.ORGANIZATION]).map((relation) => new TypeCodeValue(relation))

          this._allRelationships[this.CS_PARTY_TYPES.TEXT] = [{ tc: '2147483647', value: 'Other' }, { tc: '1012300009', value: 'Unknown' }].concat(
            relationships[this.CS_PARTY_TYPES.ORGANIZATION].map(relation => new TypeCodeValue(relation, 'Organization')).filter(relation => !this.MISC_RELATION_TYPES.some((type) => relation.tc === type)),
            relationships[this.CS_PARTY_TYPES.PERSON].map(relation => new TypeCodeValue(relation, 'Person')).filter(relation => !this.MISC_RELATION_TYPES.some((type) => relation.tc === type)),
          )

          this._allRelationships[this.BENEFICIARY_PARTY_TYPES.PERSON] = relationships[this.CS_PARTY_TYPES.PERSON].map((relation) => new TypeCodeValue(relation))
          this._allRelationships[this.BENEFICIARY_PARTY_TYPES.BUSINESS] = [].concat(relationships[this.BENEFICIARY_PARTY_TYPES.BUSINESS]).map((relation) => new TypeCodeValue(relation))
          this._allRelationships[this.BENEFICIARY_PARTY_TYPES.TRUST] = [].concat(relationships[this.BENEFICIARY_PARTY_TYPES.TRUST]).map((relation) => new TypeCodeValue(relation))
          this._allRelationships[this.BENEFICIARY_PARTY_TYPES.ESTATE] = [].concat(relationships[this.BENEFICIARY_PARTY_TYPES.ESTATE]).map((relation) => new TypeCodeValue(relation))

          this._allRelationships[this.CS_PARTY_TYPES.PERSON].forEach((relationship) => {
            this._indexKeys[relationship.value.replace(/\W+/gi, '_').toUpperCase()] = relationship.tc
            this._indexedRelationships[relationship.tc] = relationship
          })

          this._allRelationships[this.CS_PARTY_TYPES.ORGANIZATION].forEach((relationship) => {
            this._indexKeys[relationship.value.replace(/\W+/gi, '_').toUpperCase()] = relationship.tc
            this._indexedRelationships[relationship.tc] = relationship
          })

          ROLE_CONSTANTS.RELATION_NAMES = this._indexKeys
          ROLE_CONSTANTS.RELATION_TYPE_CODES = this._indexedRelationships
        })
    } else {
      return this.$q.resolve()
    }
  }

  get RELATIONSHIP() {
    return this._indexKeys
  }

  get RELATIONSHIPS() {
    return this._indexedRelationships
  }

  getDefaultOptionByPartyType(partyType: 'person' | 'business' | 'trust' | 'estate') {
    return this.DEFAULT_OPTIONS[partyType]
  }

  getByTypeCode(tc) {
    return this._allRelationships[tc]
  }

  getByPartyType(partyType) {
    return this._allRelationships[partyType]
  }
}
