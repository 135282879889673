/* eslint-disable no-empty-function */

import { v4 as uuidv4 } from 'uuid'

import { IHttpService, IPromise } from 'angular'

import Mustache from 'mustache'
import { IActivity, IActivityDescriptor, IActivityLoggingPayload, IActivityPhase } from './types'
import { deepArrayOrObjectCopy } from '../utils/utils'

import { GoogleAnalyticsService } from '../components/google-analytics'
import { CrafterService, ACTIVITY_TRACKING } from '../utils/crafter-service'
import { LoggingService } from '../utils/logging'

/* eslint-disable no-underscore-dangle */
export class ActivityTrackingService {
  static $inject = ['googleAnalyticsService', 'configService', 'loggingService', 'crafterService', '$http', 'utils']

  activityDescriptors: IActivityDescriptor[] = []
  activityDescriptorsMap: any =  {}
  _exceptionHandlerDescriptor: IActivityDescriptor
  _currentActivity: IActivity = null

  constructor(private googleAnalyticsService: GoogleAnalyticsService, private configService: any, private loggingService: LoggingService, private crafterService: CrafterService, private $http: IHttpService, private utils: any) { }

  requestDataResolvers: any = {
    submit: (_requestResponseData: any): any => {
      return
    },
    sent: (requestResponseData: any): any => {
      return requestResponseData
    },
    success: (requestResponseData: any): any => {
      const reqData: any = requestResponseData.config.data
      const resData: any = requestResponseData.data || {}

      return { reqData, resData }
    },
    failure: (requestResponseData): any => {
      const reqData: any = requestResponseData.config.data
      const resData: any = requestResponseData.data || {}

      return { reqData, resData }
    },
    exception: (error: any): any => {
      const exception: any = { exception: { message: error?.exception.message || error?.exception, payload: error.exception?.stack || 'Stack trace not available'} }

      return exception
    },
    default: (_requestResponseData: any): any => {
      return
    },
  }


  initData(): IPromise<IActivityDescriptor[]> {
    return this.crafterService.getActivityDescriptors()
      .then((activityDescriptors: any) => {
        const allEntries: IActivityDescriptor[] = Object.values(activityDescriptors || {})

        this.activityDescriptors = allEntries
        this.activityDescriptorsMap = activityDescriptors
        this._exceptionHandlerDescriptor = activityDescriptors['Default']

        return this.activityDescriptors
      })
  }

  bindMessage(messageTemplate: string, sourceData: any): string {
    const message = Mustache.render(messageTemplate, sourceData)

    return message
  }

  buildPayloadGA(activityPhase: IActivityPhase, sourceData: any, requestData?: any): any {
    const mergedData: any = this.mergeSourceAndRequestData(activityPhase.activityPhase, sourceData, requestData)
    const params: any = activityPhase.gaParameters.reduce((paramsAcc: any, param: any) => {
      const [key, value] = Object.entries(param)[0]
      const valueString = String(value)
      const finalValue = valueString.startsWith('=') ? this.utils.getData(mergedData.data, valueString.substring(1)) : value

      paramsAcc[key] = finalValue

      return paramsAcc
    }, {})

    if (mergedData.isResponse) {
      params.status = mergedData.status
      params.statusText = mergedData.statusText
    }

    return { ...mergedData, gaParams: params }
  }

  buildPayloadLog(activityPhase: IActivityPhase, sourceData: any, requestData?: any, activity?: IActivity): IActivityLoggingPayload {
    const tmpActivity: IActivity = activity || this.currentActivity
    const activityLog: IActivityLoggingPayload = {
      action: activityPhase.activityPhase,
      activityID: tmpActivity.activityID,
    }
    const mergedData: any = this.mergeSourceAndRequestData(activityPhase.activityPhase, sourceData, requestData)

    activityLog.message = this.bindMessage(activityPhase?.cloudWatchMessageTemplate || '', mergedData.data)

    if (mergedData.payload) activityLog.payload = JSON.stringify(mergedData.payload)

    if (mergedData.isResponse) {
      activityLog.status = mergedData.status
      activityLog.statusText = mergedData.statusText
    }

    return activityLog
  }

  buildPayloads(phase: IActivityPhase, activityDescriptor: any, requestData?: any): any {
    const sourceData: any = this.resolveSourceData(activityDescriptor.dataSources, this.currentActivity?.sourceData || {})
    const payloads: any = {
      ga: this.buildPayloadGA(phase, sourceData, requestData),
      log: this.buildPayloadLog(phase, sourceData, requestData),
    }

    console.info('buildPayloads:', payloads)

    return payloads
  }

  clearCurrentActivity(): void {
    this._currentActivity = null
    // delete this.$http.defaults.headers.common['activityID']
  }

  get currentActivity(): IActivity {
    return this._currentActivity
  }

  findDataSource(name: string, scope: any): any {
    let ds: any = scope?.[name]

    if (!ds && !!scope) {
      ds = this.findDataSource(name, scope.$parent)
    }

    return ds
  }

  getActivityPhase(phase: string, activityDescriptor: IActivityDescriptor): IActivityPhase {
    return activityDescriptor.activityPhases.find((ap) => ap.activityPhase === phase)
  }

  get hasCurrentActivity(): boolean {
    return !!this.currentActivity
  }

  matchActivity(uiRouterEntryPoint: string, stateName: string): boolean {
    if(uiRouterEntryPoint?.endsWith('*')) {
      const uiRouteFragment: string = uiRouterEntryPoint.split('*')[0]

      return stateName.startsWith(uiRouteFragment)
    }

    return uiRouterEntryPoint === stateName
  }

  getActivityDescriptor(stateName: string): IActivityDescriptor {
    return this.activityDescriptors.find((ac: IActivityDescriptor): boolean => this.matchActivity(ac.uiRouterEntryPoint, stateName))
  }

  isInActivity(stateName: string): boolean {
    return this.matchActivity(this.currentActivity?.activityDescriptor?.uiRouterEntryPoint, stateName)
  }

  isTrackableActivity(stateName: string): boolean {
    const activity = this.activityDescriptors.find((ad: any): any => {
      return this.matchActivity(ad.uiRouterEntryPoint, stateName)
    })

    return Boolean(activity)
  }

  isTrackableURI(submittedURL: string, httpMethod?: string): boolean {
    if(httpMethod === 'GET') return false

    if (this.currentActivity.activityDescriptor.uriIsRegEx) {
      const rx = new RegExp(this.currentActivity.activityDescriptor.submitURI, 'ig')

      return rx.test(submittedURL)
    } else {
      return submittedURL.startsWith(this.currentActivity.activityDescriptor.submitURI)
    }
  }

  mergeSourceAndRequestData(phase: string, sourceData: any, requestData?: any): any {
    const sourceDataResolverFn: Function = this.requestDataResolvers[phase] || this.requestDataResolvers.default
    const data: any = sourceDataResolverFn(requestData)
    const mergedData: any = {
      isResponse: Boolean(data?.resData && data?.reqData),
      isException: Boolean(data?.exception),
    }

    if (mergedData.isResponse) {
      mergedData.status = requestData.status
      mergedData.statusText = requestData.statusText
      mergedData.data = { ...sourceData, requestPayload: data.reqData }
      mergedData.payload = data.resData
      mergedData.responseHasError = ![200, 204].includes(data.resData.status)
    } else if (mergedData.isException) {
      mergedData.data = { ...sourceData, exception: data.exception }
      mergedData.payload = data.exception.payload
    } else {
      mergedData.data = sourceData
      if (data) mergedData.payload = data
    }

    return mergedData
  }

  recordException(exception: any): void {
    // exceptionHandlerEnabled is falsey just log the exception to the console.
    if (!this.configService.features.exceptionHandlerEnabled) {
      console.error(exception)
      return
    }

    try {
      const activity: IActivity = this.hasCurrentActivity ? this.currentActivity : this.createActivity('00000000-0000-0000-0000-000000000000', 'unexpected.exception', this._exceptionHandlerDescriptor, {}, this.configService.serverMode === 'client' ? 'client-portal-exception' : 'insight-exception')
      const activityPhase: IActivityPhase = this.getActivityPhase('exception', activity.activityDescriptor) || this.getActivityPhase('exception', this._exceptionHandlerDescriptor)
      const sourceData: any = this.resolveSourceData(activity.activityDescriptor.dataSources, activity.sourceData)
      const payload: any = this.buildPayloadLog(activityPhase, sourceData, { exception }, activity)

      this.loggingService.error(payload, activity.loggingModuleName)
    } catch (err) {
      console.error('ActivityTrackingService::recordException', err)
    }
  }

  /**
   *
   * @param phase A string that indicates which phase metadata to use.
   * @param data
   * @param isError
   */
  recordEvent(phase: string, isError: boolean = false, data?: any): void {
    try {
      const activityDescriptor: IActivityDescriptor = this.currentActivity?.activityDescriptor
      const activityPhase: IActivityPhase = this.getActivityPhase(phase, activityDescriptor)
      const payloadCopy = data ? deepArrayOrObjectCopy(data, activityDescriptor.redactedFields, true) : undefined
      const { ga, log } = this.buildPayloads(activityPhase, activityDescriptor, payloadCopy)

      if (isError) {
        this.loggingService.error(log, this.currentActivity.loggingModuleName)
        this.googleAnalyticsService.send2(activityPhase.gaEventName, activityPhase.activityPhase, ga.gaParams)
      } else {
        this.loggingService.info(log, this.currentActivity.loggingModuleName)
        this.googleAnalyticsService.send2(activityPhase.gaEventName, activityPhase.activityPhase, ga.gaParams)
      }
    } catch (err) {
      console.error('ActivityTrackingService::recordEvent', err, phase, data)
    }
  }

  /**
   *
   * @param activityID
   * @param stateName
   * @param activityDescriptor
   * @param sourceData
   * @param loggingModuleName
   * @returns
   */
  createActivity(activityID: string, stateName: string, activityDescriptor: IActivityDescriptor, sourceData: any, loggingModuleName: string): IActivity {
    return {
      activityID,
      stateName,
      activityDescriptor,
      sourceData,
      loggingModuleName,
    }
  }


  registerActivity(stateName: string, loggingModuleName: string, sourceScope: any): IActivity {
    try {
      const activityDescriptor: IActivityDescriptor = this.getActivityDescriptor(stateName)

      if (activityDescriptor) {
        const sourceData: any = this.resolveSourceData(activityDescriptor.dataSources, sourceScope)

        this._currentActivity = this.createActivity(uuidv4(), stateName, activityDescriptor, sourceData, loggingModuleName)
      }

      return this.currentActivity

    } catch (err) {
      console.error('ActivityTrackingService::registerActivity', err)
    }
  }

  registerActivityByName(activityName: string, sourceScope: any): IActivity {
    try {
      const activityDescriptor: IActivityDescriptor = this.activityDescriptorsMap[activityName]

      if (activityDescriptor) {
        const sourceData: any = this.resolveSourceData(activityDescriptor.dataSources, sourceScope)

        this._currentActivity = this.createActivity(uuidv4(), activityName, activityDescriptor, sourceData, activityName)
      }

      return this.currentActivity

    } catch (err) {
      console.error('ActivityTrackingService::registerActivityByName', err)
    }
  }

  setDatasource(dataSourceName: string, scope: any): void {
    this._currentActivity.sourceData[dataSourceName] = scope[dataSourceName]
  }

  resolveSourceData(dataSources: string[], sourceScope: any): any {
    const sourceData: any = dataSources.reduce((sd: any, dsName: string) => {
      const tmp = this.findDataSource(dsName, sourceScope)

      sd[dsName] = tmp

      return sd
    }, {})

    return sourceData
  }
}
