export class ApplicationFormController {
  static $inject: any = ['lazyLoadingDataService', 'authService']

  hasNewYorkAppointments: boolean = false
  // eslint-disable-next-line @typescript-eslint/ban-types
  onFormReady: Function
  appData: any
  public defaultEamilAddress: string

  constructor(private lazyLoadingDataService, private authService) {
  }

  $onInit() {
    const isNotAgent = !this.authService.isInRole('AGENT')

    this.appData.isNotAgent = isNotAgent
    this.lazyLoadingDataService.hasNewYorkAppointments()
      .then(hasNewYorkAppointments => {
        this.appData.hasNewYorkAppointments = hasNewYorkAppointments || !hasNewYorkAppointments && isNotAgent
        this.onFormReady()
      })
  }
}
