import { FilterFactory, IHttpService, IPromise } from "angular"
import { IRiderDataPoint, IRiderDisplay, IRiderSection, RiderDataPointDef, RiderSectionDef, RiderSourceData } from "./types"
import { ANNUITANT_ROLES } from "../../client/beneficiaries/constants/ROLES"


export class RidersService {
  static $inject = ['$http', '$filter', 'utils', 'CONSTANTS', 'dateUtils']

  constructor(private $http: IHttpService, private $filter: FilterFactory, private utils: any, private CONSTANTS: any, private dateUtils: any) { }

  /**
   *
   */
  customTransformers: any = {
    coveredLifeCurrentAge: (sourceData: RiderSourceData): any => {
      return sourceData.annuitant.birthDate ? this.dateUtils.extendedAgeTo(sourceData.annuitant.birthDate, new Date()) : ''
    },
    lifetimeWDBase: (sourceData: RiderSourceData): any => {
      switch (sourceData.coverage.status) {
        case 'Deferral':
          return sourceData.coverage.lwoBenBase
        case 'Withdrawal':
          return sourceData.coverage.lifetimeWDBase
      }
    },
    lwoBonusRate: (sourceData: RiderSourceData): any => {
      const showBonus = sourceData.summary.jurisdiction !== 'New York'

      return showBonus ? sourceData.coverage.lwoBonusRate : ''
    },
    coverLifeCurrentAge: (sourceData: RiderSourceData): any => {
      return sourceData?.annuitant?.birthDate ? this.dateUtils.extendedAgeTo(sourceData.annuitant.birthDate, new Date()) : ''
    },

    jointCoverLifeCurrentAge: (sourceData: RiderSourceData): any => {
      if (sourceData?.coverage?.covOption?.[0]?.livesType && sourceData?.coverage?.covOption[0]?.livesType?.slice(0, 5) === 'Joint') {
        return sourceData?.jointAnnuitant?.birthDate ? this.dateUtils.extendedAgeTo(sourceData.jointAnnuitant.birthDate, new Date()) : ''
      }

      return ''
    },
    jointCoveredLife: (sourceData: RiderSourceData): any => {
      if (sourceData.coverage.covOption?.[0].livesType && sourceData?.coverage?.covOption[0]?.livesType?.slice(0, 5) === 'Joint') {
        return sourceData?.jointAnnuitant?.fullName
      }

      return ''
    },
    jointCoveredLifeCurrentAge: (sourceData: RiderSourceData): any => {
      if (sourceData?.coverage?.covOption?.[0].livesType && sourceData?.coverage?.covOption[0]?.livesType?.slice(0, 5) === 'Joint') {
        return sourceData?.jointAnnuitant?.birthDate ? this.dateUtils.extendedAgeTo(sourceData?.jointAnnuitant?.birthDate, new Date()) : ''
      }

      return ''
    },
    withdrawalBenefitBase: (sourceData: RiderSourceData): any => {
      switch (sourceData.coverage.status) {
        case 'Deferral':
          return sourceData.coverage.lwoBenBase
        case 'Lifetime':
          return sourceData.coverage.lifetimeWDBase
        case 'Standard':
          return sourceData.coverage.swoBenBase
        default:
          return
      }
    },
    duration: (sourceData: RiderSourceData): any => {
      // { { ridersDetail.gmaIIRider.duration } } year{ { ridersDetail.gmaIIRider.duration > 1 ? 's' : '' } }
      return `${sourceData.coverage.duration} year${sourceData.coverage.duration > 1 ? 's' : ''}`
    }
  }

  /**
   *
   * @param riderSectionDef
   * @param sourceData
   * @returns
   */
  transformDataPoints(riderSectionDef: RiderSectionDef, sourceData: RiderSourceData): IRiderSection {
    const riderSection: IRiderSection = { ...riderSectionDef, dataPoints: [], footnotes: [] }

    if (riderSectionDef.tabular) {
      const tableType = riderSectionDef.sectionTitle
      const tableObj = sourceData.tables[tableType]

      if (tableObj?.length > 0) {
        const tableData = tableObj.find((table) => table.tableName.toLowerCase().startsWith(tableType))

        riderSection.table = tableData
      }
    } else {
      riderSectionDef.dataPoints.forEach((dataPointDef: RiderDataPointDef) => {
        const dataPoint: IRiderDataPoint = { label: dataPointDef.label, footnoteMarker: dataPointDef.footnoteMarker, value: '' }

        if (dataPointDef.useCustomTransformer) {
          const transformer: Function = this.customTransformers[dataPointDef.fieldName]

          dataPoint.value = transformer ? transformer(sourceData, dataPointDef) : `${dataPointDef.fieldName} Not Implemented Yet`
        } else {
          dataPoint.value = this.utils.getData(sourceData, dataPointDef.fieldName) || ''
        }

        if (dataPointDef.filter) {
          const filters: string[] = dataPointDef.filter.split('|')

          dataPoint.value = filters.reduce((value: any, filter: string): any => {
            const filterParts: string[] = filter.split(':')
            const filterName: string = filterParts.shift()
            const filterParams: any[] = [value].concat(filterParts)
            const filteredValue: any = this.$filter(filterName).apply(null, filterParams)

            return filteredValue
          }, dataPoint.value)
        }

        if (dataPointDef.footnote) riderSection.footnotes.push(dataPointDef.footnoteMarker + dataPointDef.footnote)

        if (!dataPointDef.hideWhenEmpty || (dataPointDef.hideWhenEmpty && dataPoint.value)) riderSection.dataPoints.push(dataPoint)
      })
    }

    return riderSection
  }

  /**
   *
   * @param riderCode
   * @param sourceData
   * @returns
   */
  extendSourceData(riderCode: string, sourceData: RiderSourceData): RiderSourceData {
    const coverage = sourceData.coverages.find((cov) => cov.riderCode === riderCode.split('-')[0].toUpperCase())
    const annuitant: any = sourceData.clients.contacts.find((a: any) => ANNUITANT_ROLES.ANNUITANT === a.role.tc)
    const jointAnnuitant: any = sourceData.clients.contacts.find((a: any) => ANNUITANT_ROLES.JOINT_ANNUITANT === a.role.tc)
    const contingentAnnuitant: any = sourceData.clients.contacts.find((a: any) => ANNUITANT_ROLES.CONTINGENT_ANNUITANT === a.role.tc)

    return { ...sourceData, coverage, annuitant, jointAnnuitant: jointAnnuitant || contingentAnnuitant }
  }

  resolveFirstSectionTitle(cmdRiders: IRiderDisplay[], coverages: any[]): void {
    cmdRiders.forEach((riderDisplay: IRiderDisplay, index: number) => {
      if (riderDisplay.sections[0].sectionTitle === 'General') {
        riderDisplay.sections[0].sectionTitle = coverages[index].marketingName
      }
    })
  }

  /**
   *
   * @param riderCode
   * @param riderSectionDefs
   * @param sourceData
   * @returns
   */
  generateRiderSections(riderCode: string, riderSectionDefs: RiderSectionDef[], sourceData: RiderSourceData): IRiderDisplay {
    const extendedSourceData: RiderSourceData = this.extendSourceData(riderCode, sourceData)
    const sections: IRiderSection[] = riderSectionDefs
      .map((riderSectionDef: RiderSectionDef): IRiderSection => this.transformDataPoints(riderSectionDef, extendedSourceData))
      .sort((a: IRiderSection, b: IRiderSection) => {
        if (a.order < b.order) return -1
        if (a.order > b.order) return 1

        return 0
      })
    const title: string = extendedSourceData.coverages.map((cov: any) => cov.marketingName).join(' & ')

    console.log('Source Data\n', extendedSourceData)

    return { title, sections }
  }

  sortCoverages(coverages: any[], cmsRiders: string[]): any[] {
    const ridersSortOrder: any = cmsRiders.reduce((acc: any, riderCode: string, index: number) => {
      acc[riderCode] = index

      return acc
    }, {})

    console.log('Rider Sort Order\n', ridersSortOrder)

    return coverages.map((cov: any): any => {
      cov.order = ridersSortOrder[cov.riderCode]

      return cov
    }).sort((a: any, b: any) => {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1

      return 0
    })
  }
  /**
   *
   * @param riderCodes
   * @returns
   */
  getRiderSectionDefs(riderCodes: string[]): IPromise<RiderSectionDef[][]> {
    const url = `/crafter/riders?riderCodes=${riderCodes.join(',')}`

    return this.$http.get(url)
      .then((result: any): RiderSectionDef[][] => {
        console.log('Rider Section Definitions\n', result.data)

        return result.data
      })
      .catch(err => {
        console.error(err)

        return []
      })
  }
}
