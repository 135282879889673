const CP_CONSTANTS = {
  accountTypeOptions: [
    {
      name: 'C',
      display: 'Checking',
    },
    {
      name: 'S',
      display: 'Savings',
    },
  ],
}

export { CP_CONSTANTS }
