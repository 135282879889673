class BankAccountPaymentFormController {
  $inject = ['$scope', 'utils', 'stringUtils', 'BANK_ACCOUNT_TYPES', 'PAYMENT_MODE_TC', 'PAYMENT_METHODS', 'PENNCHECK_WITHDRAWAL_DATES']

  accountTypeOptions: any
  bankingInfo: any
  isPennCheck: boolean
  formIsValid: Function
  _formIsValid: boolean = false
  useModalLayout: boolean
  routingNumberValidationPattern: string

  // useForPennCheckInitialPayment: boolean = false

  constructor(private $scope, private utils, private stringUtils, public BANK_ACCOUNT_TYPES, public PAYMENT_MODE_TC, public PAYMENT_METHODS,
              public PENNCHECK_WITHDRAWAL_DATES) { }

  copyAccountNumber (type, value) {
    if (this.utils.isStringDigitsOnly(value) && value.length > 3 && this.bankingInfo.saveAccountInfo === true) {
      this.bankingInfo.accountNickname = this.stringUtils.capitalize(type) + '-' + value.substr(value.length - 4)
    } else {
      this.bankingInfo.accountNickname = ''
    }
  }

  $onInit() {
    this.accountTypeOptions = this.BANK_ACCOUNT_TYPES

    if (this.isPennCheck) {
      // 9 digit number AND must start with
      // 00 through 12
      // or
      // 21 through 32
      this.routingNumberValidationPattern = '([01]?[0-9]|2[1-9]|3[0-2])[0-9]{7}$'
    } else {
      this.routingNumberValidationPattern = '[0-9]{9}$' // 9 digit number
    }
  }

  $doCheck() {
    const newFormIsValid = this.$scope.bankInfoForm?.$valid && this.$scope.bankInfoForm?.$dirty

    if (newFormIsValid !== this._formIsValid) {
      this._formIsValid = newFormIsValid
      this.formIsValid({isReady: this._formIsValid})
    }
  }
}

export const BankInfoForm = {
  bindings: {
    bankingInfo: '=',
    isPennCheck: '<',
    hideNicknameInput: '<',
    pennCheckDates: '<',
    formIsValid: '&',
    useModalLayout: '@',
  },
  templateUrl: 'app/components/payments/templates/bank-account-payment-form.html',
  controller: BankAccountPaymentFormController,
  controllerAs: 'bankInfoCtrl',
}
