(function() {
  'use strict'

  wgClientPaymentAccountsController.$inject = ['$scope']

  angular.module('app.directives')
    .controller('wgClientPaymentAccountsController', wgClientPaymentAccountsController)
    .directive('wgClientPaymentAccounts', [function() {
      return {
        restrict: 'EA',
        scope: {
          paymentAccounts: '=',
          selectedAccountId: '=',
        },
        bindToController: true,
        templateUrl: 'app/widgets/templates/wg-client-payment-accounts.html',
        controller: wgClientPaymentAccountsController,
        controllerAs: 'ctrl',
        link: (scope, el, attrs, ctrl) => {
          scope.$watch('ctrl.selectedAccountId', function(n, o, s) {
            scope.$parent.selectedPaymentAccountId = ctrl.selectedAccountId
          })
        },
      }
    }])

  // define the controller

  function wgClientPaymentAccountsController($scope) {
    // blank controller merely to contain the parameters as controller properties
  }
})()
