class BankInfoModalController {
  $inject = ['BANK_ACCOUNT_TYPES', 'PAYMENT_MODE_TC', 'PAYMENT_METHODS', 'PENNCHECK_WITHDRAWAL_DATES']

  private resolve: any

  accountTypeOptions: any
  modalInstance: any
  bankingInfo: any
  bankingInfoReady: boolean = false
  buttonLabel: string

  constructor(public BANK_ACCOUNT_TYPES, public PAYMENT_MODE_TC, public PAYMENT_METHODS, public PENNCHECK_WITHDRAWAL_DATES) {
    this.accountTypeOptions = BANK_ACCOUNT_TYPES
  }

  closeModal() {
    this.modalInstance.dismiss()
  }

  saveAndContinue() {
    this.modalInstance.close(this.bankingInfo)
  }

  paymentFormStateChange(isReady) {
    this.bankingInfoReady = isReady
  }

  $onInit() {
    this.bankingInfo = this.resolve.bankingInfo
    this.buttonLabel = this.resolve.buttonLabel
  }

}

export const BankingInfoModal = {
  bindings: {
    bankingInfo: '<',
    modalInstance: '<',
    showEffectiveDateIssueDateMessage: '<',
    resolve: '<',
  },
  templateUrl: 'app/components/payments/templates/bank-info-modal.html',
  controller: BankInfoModalController,
  controllerAs: 'modalCtrl',
}
