import {PmlNgListViewDirectiveController} from './pml-ng-list-view-directive-controller'
/**
 * Renders the <pml-ng-list-view /> directive.
 */
export class PmlNgListViewDirective implements ng.IDirective {
  restrict = 'E'
  templateUrl = (_el: any, attrs: any) => attrs.templateUrl
  replace = false
  controller = 'pmlNgListViewController'
  controllerAs = 'listView'
  scope = {
    methodOptions: '=',
    parentController: '=',
    sourceData: '=',
    hideTable: '=',
    isMobile: '=',
    externalSortValue: '=',
  }

  constructor(private utils) { }

  /**
   * AngularJS post link function use for initial configuration of instances of TrainingListDirective
   */
  // tslint:disable-next-line
  link(scope: any, el: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: PmlNgListViewDirectiveController) {

    // Check if we are in IE or not.
    scope.isIE = this.utils.isIE()

    // Initialize the controllers state, set the scope name of this instance of listView
    const scopeName = ctrl.initialize(scope, attrs)

    // Add identifying class to this instances element.
    el.addClass(scopeName)

    if (attrs.parentControllerAs) scope[attrs.parentControllerAs] = scope.parentController

    // Make initial call for list-view data.
    ctrl.getData()
      .catch(() => console.warn('TODO: implement error handling for dataProvider call.'))
  }

  /**
   * Creates an instance of TrainingsDirective, with dependencies injected.
   */
  static factory(): ng.IDirectiveFactory {

    const directive = (utils) => new PmlNgListViewDirective(utils)

    directive.$inject = ['utils']

    return directive
  }
}
