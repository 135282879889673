import {AppSwitcherService} from './app-switcher-service'
export class AppSwitcherController {
  $inject = ['appSwitcherService', 'configService']

  content: any

  constructor (private appSwitcherService: AppSwitcherService, private configService) { }

  $onInit() {
    if (this.configService.serverMode === 'agent') {
      return this.appSwitcherService.getContent().then((content) => {
        this.content = content
      })
    }
  }
}
