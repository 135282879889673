import { ModalService } from "../../../components/modal/modal-service"
import { IRoleEligibilityResult } from "../../roles/types"
import { BeneficiariesDataProviderService } from "../beneficiary-data-provider"
import { RoleChangeReponse } from "../classes/role-change-response"
import { BENEFICIARY_VIEWS, BENEFICIARY_VIEW_STATES } from "../constants/BENEFICIARY_VIEWS"

export class BeneficiaryHeaderController {
  static $inject = ['$attrs', 'modalService', 'beneficiariesDataProvider', 'configService']

  viewState: any
  changeResults: RoleChangeReponse | null
  email: string
  isDeathClaim: boolean
  ruleMessage: string
  onContinue: Function
  onChange: Function
  sendCorrespondence: boolean
  effectiveDate: Date
  showCorrespondenceToggle: boolean
  isDatepickerOpen: boolean
  datePickerOptions: any
  sliderLabelText: string
  beneCtrl: any
  beneDesignationUrl: string
  userType: string
  cssbEligibility: IRoleEligibilityResult

  constructor($attrs: any, private modalService: ModalService, private beneficiariesDataProvider: BeneficiariesDataProviderService, private configService: any) {
    this.userType = $attrs.userType
  }

  get showIneligibilityMessage(): boolean {
    return !this.cssbEligibility?.eligible && this.configService.serverMode === 'agent' && [BENEFICIARY_VIEWS.INITIAL, BENEFICIARY_VIEWS.EDIT].includes(this.viewState?.state)
  }

  openAdditionalInfo(): any {
    this.modalService.open('app/client/client-bene-edit/tmpl/additional-info.html', {
      modalHeader: 'Additional Information',
    },
      {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
      },
    )
  }

  $onInit(): void {
    if(this.userType !== 'client') return

    void this.beneficiariesDataProvider.getDesignationFormUrl(this.beneCtrl.policySummary).then((url) => {
      this.beneDesignationUrl = url
    })
  }

}

export const beneficiaryHeaderComponentConfig = {
  bindings: {
    viewState: '=',
    changeResults: '=',
    email: '<',
    isDeathClaim: '<',
    onContinue: '&',
    onChange: '&',
    sendCorrespondence: '=',
    effectiveDate: '=',
    showCorrespondenceToggle: '<',
    isDatepickerOpen: '<',
    datePickerOptions: '<',
    sliderLabelText: '<',
    beneCtrl: '<',
    cssbEligibility: '<',
  },
  templateUrl: ($attrs: any) => {
    if ($attrs.userType === 'client') {
      return 'app/client/client-bene-edit/tmpl/client-bene-header.html'
    }

    return 'app/client/beneficiaries/header/beneficiary-header.html'
  },
    controller: BeneficiaryHeaderController,
      controllerAs: 'ctrl',
  }
