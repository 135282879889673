(function() {
  'use strict'

  angular
    .module('app.directives')
    .directive('wgBCheckbox', wgBCheckbox)

  function wgBCheckbox() {
    return {
      restrict: 'EA',
      controller: WgBCheckbox,
      controllerAs: 'bcb',
      scope: {
        'checked': '=?ngModel',
        'callback': '&',
        'id': '@',
      },
      template1: '<div class="wg-checkbox" ng-click="cb.click($event);"><div class="wg-checkbox-container"><div class="wg-checkbox-inner" ng-class="{\'checked icon-check\': cb.checked}"></div></div></div>',
      template: '<div class="wg-checkbox" ng-click="bcb.toggle($event);"><div class="wg-checkbox-container"><div class="wg-checkbox-inner" ng-class="{\'checked icon-check\': bcb.checked}" id="{{bcb.id}}"></div></div></div>',
      replace: true,
      bindToController: true,
    }
  }

  WgBCheckbox.$inject = ['$rootScope', '$scope']

  function WgBCheckbox($rootScope, $scope) {
    const self = this

    self.toggle = function() {
      self.checked = !self.checked

      if (self.callback) {
        self.callback()
      }
    }
  }
})()
