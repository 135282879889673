
import * as angular from 'angular'
import { IPromise } from 'angular'

export class PmlNgListViewDirectiveController {
  static $inject = ['$q', '$injector', '$scope', 'utils']

  private SORT_HANDLERS = {
    date: (curSortColumn, a, b) => {
      const aVal = curSortColumn && new Date(this.utils.getData(a, curSortColumn.name))
      const bVal = curSortColumn && new Date(this.utils.getData(b, curSortColumn.name))

      if (aVal > bVal) return 1
      if (aVal < bVal) return -1
      return 0
    },
    undefined: (curSortColumn, a, b) => {
      const aVal = curSortColumn && this.utils.getData(a, curSortColumn.name)
      const bVal = curSortColumn && this.utils.getData(b, curSortColumn.name)

      if (aVal > bVal) return 1
      if (aVal < bVal) return -1
      return 0

    },
  }

  sourceProviderMethod: any
  public data: any[] = []

  private _rawData: any[] = []
  private _curSortColumn: ISortColumn | undefined
  private _currentFilters: IDataFilter[] | undefined
  private _pagingInfo: IPagingInfo
  private _filterFields: string[]
  private _filtersWithoutFilterSource: string[]
  private _clearWatches = []
  private _providerName: string
  private _member: string
  private _beforeRenderName: string
  private _memberIsMethod: boolean
  public _methodOptions: any
  private _sourceData: any
  private _serverSort: boolean = false
  private _serverFilter: boolean = false
  private _serverPaging: boolean = false
  private _expectedQueryParams: number = 0

  totalPages: number = 0
  totalItems: number = 0
  currentPage: number = 1
  pageSize: number = 0

  constructor(private $q: angular.IQService, private $injector: angular.auto.IInjectorService, public $scope, private utils) { }

  get pagingInfo () { return this._pagingInfo }

  get showPrevNext() {
    // console.log('Total Pages: ', this.totalPages)
    return this.totalPages > 1
  }

  get currentSortColumn() {
    return this._curSortColumn
  }

  get currentFilters() {
    return this._currentFilters
  }

  get currentPageSize() {
    return this.data?.length ?? 0
  }

  set filterFields(ff) { this._filterFields = ff }
  get filterFields() { return this._filterFields }

  get serverSortAndFilter() {
    return this._serverSort || this._serverFilter || this._serverPaging
  }

  _countExpectedQueryParams(serverOptions) {
    return Object.keys(serverOptions).reduce((acc, key) => {
      if (serverOptions[key]) acc++

      return acc
    }, 0)
  }

  enabledServerOptions(serverOptions: any) {
    this._serverSort = serverOptions.sort
    this._serverFilter = serverOptions.filter
    this._serverPaging = serverOptions.page

    this._expectedQueryParams = this._countExpectedQueryParams(serverOptions)
  }

  /**
   * @deprecated
   */
  clearFilters() {
    this._currentFilters = []
    this.filterFields.forEach((ff) => {
      this.$scope[ff] = undefined
    })
    this.sortFilterPage(undefined, this._currentFilters, undefined)
  }

  /**
   *
   * @param curentSortColumn
   * @param currentFilters
   * @param methodOptions
   * @param pagingInfo
   */
  makeQueryOptions(curentSortColumn, currentFilters, methodOptions, pagingInfo) {
    const copyOfSort = curentSortColumn ? angular.copy(curentSortColumn) : undefined
    const copyOfFilter = currentFilters ? angular.copy(currentFilters) : undefined
    const options = {...methodOptions, filterKey: methodOptions?.filterKey, isAgent: methodOptions?.isAgent, sort: copyOfSort, filter: copyOfFilter, pageable: methodOptions?.pageable, page: pagingInfo }

    return options
  }

  /**
   *
   * @param scope
   * @param attrs
   */
  private _initWatches(scope, attrs) {
    // Initialize watch for filter fields.
    if (attrs.filters) {
      this.filterFields = attrs.filters.split(' ')

      // Sometimes the filter names will have dotted notation, but the calls to back-end API
      // does not support the names that way.
      this._filtersWithoutFilterSource = this.filterFields.map((name): string => {
        const splitName: string = name.split('.').slice(-1)[0]

        return splitName
      })

      // @ts-ignore
      this._clearWatches.push(scope.$watchGroup(this.filterFields, (newFilters) => {
        const finalFilters: IDataFilter[] = newFilters.reduce((acc, fv, i) => {

          const filter = { field: this._filtersWithoutFilterSource[i], value: fv?.id }

          if (typeof filter.value !== 'undefined') acc.push(filter)
          return acc
        }, [])

        if (finalFilters.length === this.filterFields.length) this.sortFilterPage(undefined, finalFilters, { page: 1, size: this.pageSize })

      }))
    }

    if (this._methodOptions?.pageable) {
      // @ts-ignore
      this._clearWatches.push(scope.$watch('listView.pageSize', () => this.sortFilterPage(undefined, undefined, { page: 1, size: this.pageSize })))
    }

    if (scope.externalSortValue) {

      // @ts-ignore
      this._clearWatches.push(scope.$watchCollection('externalSortValue', (n: ISortColumn) => {
        // console.log('externalSortValue', n)
        this.sortFilterPage(n)
      }))
    }

    // Cleanup all listeners
    // @ts-ignore
    scope.$on('$destroy', () => this._clearWatches.forEach(f => f()))

  }

  _initState() {
    this._pagingInfo = { page: 1, size: this.pageSize }
  }

  /**
   *
   * @param scope
   * @param attrs
   */
  initialize(scope: any, attrs: any): string {
    this._providerName = attrs.sourceProvider
    this._member = attrs.sourceMethod ?? attrs.sourceProperty
    this._beforeRenderName = attrs.beforeRenderMethod
    this._memberIsMethod = !!this._member && attrs.sourceMethod === this._member
    this._methodOptions = scope.methodOptions
    this._sourceData = scope.sourceData
    this.pageSize = Number(attrs.defaultPageSize ?? 10)

    this._initWatches(scope, attrs)
    this._initState()

    // Initialize server side sort and filter.  server-sort server-page
    const serverOptions = {
      sort: !!attrs.$attr['serverSort'],
      filter: !!attrs.$attr['serverFilter'],
      page: !!attrs.$attr['serverPage'],
    }

    this.enabledServerOptions(serverOptions)

    // Let interested constituents know that we are running server side
    scope.$broadcast('list-view-state-initialized', this._curSortColumn, this._currentFilters, this._pagingInfo)

    return this._member
  }

  getData(): any {
    let promise

    if (this._memberIsMethod) {
      const options = this.makeQueryOptions(this.currentSortColumn, this.currentFilters, this._methodOptions, this.pagingInfo)
      const paramsCount = this._countExpectedQueryParams({ sort: options.sort, filter: options.filter, pageable: options.page })
      const doIt = !this.serverSortAndFilter || paramsCount === this._expectedQueryParams

      // console.log('doIt', doIt, options, paramsCount)
      if (doIt) {
        promise = this.getDataFromMethod(this._providerName, this._member, this._beforeRenderName, options)
      } else {
        promise = this.$q.resolve([])
      }

    } else {
      promise = this.getDataFromProperty(this._sourceData, this._member)
    }

    return promise
  }

  /**
   *
   * @param providerName
   * @param methodName
   * @param beforeRenderName
   * @param params
   */
  getDataFromMethod(providerName: string, methodName: string, beforeRenderName: string, params?: any): IPromise<any[]> {
    const sourceProvider: any = this.$injector.get(providerName)
    const sourceMethod: Function = sourceProvider[methodName]
    const beforeRender: Function = sourceProvider[beforeRenderName]

    let tmpResult: any = null

    if (sourceMethod) {

      if (this._serverSort && this._serverFilter && !params?.sort && !params?.filter) {
        params.sort = this._curSortColumn ? angular.copy(this._curSortColumn) : undefined
        params.filter = this._currentFilters ? angular.copy(this._currentFilters) : undefined
      }

      return sourceMethod.call(sourceProvider, params)
        .then(data => {
          tmpResult = data
        })
        .then(() => {
          this._rawData = params?.pageable ? tmpResult.data : tmpResult
        }) // Pageable data returns a different structure that non.
        .then(() => beforeRender && beforeRender.call(sourceProvider, this._rawData, this.$scope))
        .then((newData) => {
          if (newData && (this._serverSort || this._serverFilter || this._serverPaging)) {
            this.data = newData
          } else {
            this.data = this._rawData
          }

          return this.data
        })
        .then(() => !this._serverSort && this.sort(this._curSortColumn))
        .then(() => !this._serverFilter && this.filter(this._currentFilters))
        .then(() => {
          if (params?.pageable) {
            this.totalItems = tmpResult.totalItems
            this.totalPages = Math.ceil(tmpResult.totalItems / this.pageSize)
          }
          this.$scope.$root.$broadcast('PML_NG_LISTVIEW_DATA_READY', methodName, this)

          return this.data
          // this.$scope.$emit('PML_NG_LISTVIEW_DATA_READY', methodName, this)
        })
        .catch(err => console.error('TODO: Implement errors better\n\t', err))
    } else {
      console.warn('%s is not found on data provider, "%s"', methodName, providerName)
      return this.$q.resolve([])
    }
  }

  getDataFromProperty(sourceData: any, propertyName: string) {
    const sourceProperty: any = this.utils.getData(sourceData, propertyName)

    // console.log('>>>', sourceData, propertyName, sourceProperty)
    if (sourceProperty && propertyName) {
      return this.$q.resolve(sourceProperty)
        .then(data => this._rawData = data)
        // .then(() => beforeRender && beforeRender(this._rawData, this.$scope))
        .then(() => this.sort(this._curSortColumn))
        .then(() => this.filter(this._currentFilters))
        .catch(err => console.error('TODO: Implement errors better\n\t', err))
    } else if (!sourceProperty && !propertyName) {
      this._rawData = sourceData
      this.data = this._rawData
      return this.$q.resolve(this._rawData)
    } else {
      // console.warn('%s is not found on data provider, "%s"', propertyName, providerName)
      return this.$q.resolve([])
    }
  }

  sort(column?: ISortColumn) {

    this._curSortColumn = column

    let tmp: any[] = []

    // Do not mutate the original array
    tmp = tmp.concat(this._rawData)

    if (Boolean(this._curSortColumn)) {
      tmp = tmp.sort((a, b) => {
        // NOTE: SORT_HANDLERS has two properties, "date", and "undefined".
        //       future enhancement would be to handle other specific data types.
        const columnDataType = String(this._curSortColumn && this._curSortColumn.dataType)
        const compare = this.SORT_HANDLERS[columnDataType]

        return compare(this._curSortColumn, a, b)
      })

      if (this._curSortColumn && this._curSortColumn.direction === 'DESC') tmp.reverse()

    }

    this.data = tmp
  }

  filter(filters?: IDataFilter[]) {
    this._currentFilters = filters

    // TODO: This version applies the logical operator `or` bewteen
    //      filters. Future release will support definable logic operator.
    if (!this.serverSortAndFilter && this._currentFilters && this._currentFilters.length) {
      this.data = this.data.filter((datum) => {
        const r = this._currentFilters && this._currentFilters.map((filter: IDataFilter) => {
          return !!String(datum[filter.field]).match(new RegExp(filter.value, 'gi'))
        }).reduce((acc, val) => acc || val, false)

        return r
      })
    } else {
      // console.info('[PmlNgListViewDirectiveController:filter] Current filter criteria is undefined. Not applying filters.')
      // Send through sort to reset to unfiltered list
      this.sort(this._curSortColumn)
    }
  }

  /**
   *
   * @param column
   * @param filters
   * @param pagingInfo
   */
  sortFilterPage(column?: ISortColumn, filters?: IDataFilter[], pagingInfo?: IPagingInfo) {
    const prevSortColumn = Object.assign({}, this._curSortColumn)

    // console.log('Sort filter page', this._serverSort, this._serverFilter, this._serverPaging)
    if (this._serverSort || this._serverFilter || this._serverPaging) {
      if (column) this._curSortColumn = column
      if (filters) this._currentFilters = filters
      if (pagingInfo) this._pagingInfo = pagingInfo
      this.getData()
    } else {
      this.sort(column)
      this.filter(filters || this._currentFilters)
    }

    // this._saveSettings()
    // console.log('sortFilterPage', this._curSortColumn, this._currentFilters, this._pagingInfo)
    this.$scope.$broadcast('list-view-state-change', this._curSortColumn, this._currentFilters, this._pagingInfo, prevSortColumn)

  }
}
