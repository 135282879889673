import { StateService, StateParams } from '@uirouter/core'
import { MfaDataProvider } from '../mfa-data-provider'
import { MFA_TOKEN_TYPES } from '..'
import { TargetState } from 'angular-ui-router'

export class MfaSubmitCodeController {
  static $inject = ['$stateParams', 'mfaDataProvider', '$state', '$scope', 'utils' /*, '$urlService'*/]

  selectedPoc: IPointOfContact
  selectedMethod: string
  submitErrorMessage: string
  code: string
  showResendLink: boolean = false
  showAlert: boolean = true

  constructor(private $stateParams: StateParams, private mfaDataProvider: MfaDataProvider, private $state: StateService, private $scope, private utils /*, private $urlService*/) {
    this.selectedPoc = this.$stateParams.poc
    this.selectedMethod = this.$stateParams.method

    console.log('MfaSubmitCodeController', $stateParams)
  }

  resendCode(poc: any, mfaMethod: string): void {
    void this.mfaDataProvider.requestVerificationCode(poc.uid, mfaMethod)
    this.showResendLink = false
    this.submitErrorMessage = ''
    this.showAlert = true
    this.code = ''
  }

  backToSendCode(): void {
    this.$state.go('mfa.send-code')
  }

  closeAlert(): void {
    this.showAlert = false
  }

  submitCode(pocGuid: string): void {
    this.mfaDataProvider.verifyCode(pocGuid, this.code, this.$stateParams.tokenType)
      .then(() => {
        if (this.$stateParams.tokenType === MFA_TOKEN_TYPES.CSSB_MFA_VERIFIED) {
          const redirectState: TargetState = this.$stateParams.redirectState

          void this.$state.go(redirectState.name(), redirectState.params())
        } else {
          void this.$state.go('mfa.trust-browser', { redirectUrl: this.$stateParams.redirectUrl })
        }
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          this.submitErrorMessage = error.data.message
          this.showResendLink = true
        }
      })
  }

  $onInit(): void {
    if (!this.selectedPoc) this.backToSendCode()

    const clearWatch = this.$scope.$watch('ctrl.code', (code) => {
      if (code && code.length === 6) {
        this.submitCode(this.selectedPoc.uid)
      }
    })

    this.$scope.$on('$destroy', () => clearWatch())
  }
}
