import { StateParams } from "angular-ui-router"

class TransactionHistoryClientController {
  static $inject: string[] = ['$scope', '$stateParams', '$state']

  currentParties: any
  noDataAvailable: boolean
  clearWatches: Function[] = []

  constructor(private $scope: any, private $stateParams: StateParams, private $state: any) { }

  viewSummary(transaction: any): void {
    this.$state.go('policy.roles.transactions.summary', {batchId: transaction.batchId, transaction})
  }

  initWatches() {
    this.clearWatches.push(this.$scope.$on('PML_NG_LISTVIEW_DATA_READY', (_e, _name, listView) => {
      this.noDataAvailable = listView.data.length === 0
    }))
  }

  $onInit(): void {
    const cid = this.$stateParams.clientId

    this.noDataAvailable = true
    this.initWatches()
    this.$scope.queryParams = { cid, pageable: true }
  }
}

export const transactionHistoryClientComponentConfig = {
  templateUrl: 'app/transaction-history/components/client-history/transaction-history-client.html',
  controller: TransactionHistoryClientController,
  controllerAs: 'trxCtrl',
  bindings: { currentParties: '<' },

}
