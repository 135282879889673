export {notifications}

function notifications() {
  return {
    restrict: 'EA',
    scope: {
      messageNumber: '@',
      channels: '@',
    },
    templateUrl: 'app/widgets/templates/notifications.html',
    controller: ['$scope', '$rootScope', 'notificationCenterService',
      function($scope, $rootScope, notificationCenterService) {
        const self = this
        let myChannels, messages

        function _stringToArray(filters) {
          if (filters) {
            return filters.trim().split(',').map(function(value) {
              return value.trim()
            })
          }

          return []
        }

        function _changeReaction(event, to, from) {
          notificationCenterService.markShown()
          notificationCenterService.removeShown()
        }

        myChannels = _stringToArray(self.channels)
        messages = notificationCenterService.messages

        self.getMessages = () => messages

        self.applyFilter = function(message, index) {
          for (let i = 0; i < myChannels.length; i++) {
            if (message.channels.indexOf(myChannels[i]) > -1) {
              return true
            }
          }
          return false
        }

        if (notificationCenterService.offlistener === undefined) {
          notificationCenterService.offlistener = $rootScope.$on('$stateChangeSuccess', _changeReaction)
        }
      },
    ],
    controllerAs: 'notificationsCtrl',
    bindToController: true,
  }
}
