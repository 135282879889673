import { ITransactionChange } from "../types"

export class TransactionChange implements ITransactionChange {
  label: string
  originalValue: any
  newValue: any

  constructor(label: string, ovalue: any, nvalue: any) {
    this.label = label
    this.originalValue = ovalue || ''
    this.newValue = nvalue || ''
  }

  get hasChanged(): boolean {
    return this.originalValue !== this.newValue
  }
}
