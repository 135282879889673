
/* @ngInject */
export class BatchStatementsRunner {
  static $inject = ['routerHelper']

  constructor (routerHelper) {

    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates () {
    return [{
      state: 'batchstatements',
      config: {
        url: '/batch-statements',
        templateUrl: 'app/batch-statements/batch-statements-view.html',
        // controller: 'batchStatementsController',
        // controllerAs: 'bsCtrl',
        data: {
          // addToNavigation: true,
          secure: true,
          module: 'batchstatements',
        },
        onEnter: ['$rootScope','CONSTANTS', function ($rootScope, CONSTANTS) {
          $rootScope.pageTitle = CONSTANTS.modules.batchstatements.name
          $rootScope.mobileHeaderTitle = CONSTANTS.modules.batchstatements.name
          // $rootScope.hideBarHeader = false;
          $rootScope.state = CONSTANTS.modules.batchstatements.id
        }],
      },
    }]
  }
}
