export class InsightDownloadRunner {
    static $inject = ['routerHelper']

    constructor(routerHelper) {
      const myStates = this.getStates()

      routerHelper.configureStates(myStates)
      console.count('download-forms')

    }

    getStates() {
        return [{
          state: 'forms',
          config: {
            url: '/download-forms',
            templateUrl: 'app/download-forms/download-forms-insight.html',
            controller: 'DownloadFormsController',
            controllerAs: 'downloadFormsCtrl',
            data: {
              secure: true,
            },
            resolve: {
               isFromCAResponse: [() =>  null ], // reuse the client side controller
               organizedForms: ['companiesService', (companiesService) => companiesService.getAllForms()],
            },
            onEnter: ['$rootScope', ($rootScope) => {
              const pageTitle = 'Download Forms'

              // can refer to $rootScope.isClientView: true
              $rootScope.pageTitle = pageTitle
              $rootScope.state = 'download-forms-view'
              $rootScope.isPopupVisible = true
            }],
            onExit: ['$rootScope', ($rootScope) => {
              $rootScope.isPopupVisible = false
            }],
          },
        }]
  }
}
