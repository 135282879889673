import * as angular from 'angular'
export class FileSelectorController {
  static $inject: any = ['$filter', '$sce', '$scope', '$element', 'utils', 'modalService', 'FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES']

  selectedFileMessage: any
  validFileTypes: string[]
  // invalidFileType: boolean
  chosenFiles: any[]
  allowedFileTypes: string[]
  uiElements: any = {}
  maxFileSize: number
  maxUploadSize: number
  isDragging: boolean = false
  onFilesDropped: Function
  maxAttachments: number

  constructor(private $filter: angular.IFilterFunction, private $sce, private $scope, private $element, private utils, private modalService, private FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES) { }

  get accept(): string {
    return this.validFileTypes?.join(',') ?? ''
  }

  _dragenter(e) {
    this._prevent(e)

    if (this.isDragging) this.uiElements.el.addClass('hovering')

    this.isDragging = true
  }

  _dragexit(e) {
    this._prevent(e)

    if (e.target.localName !== 'drop-target') {
      this.isDragging = false
    }
  }

  _drop(e) {
    this._prevent(e)

    // overlay.addClass('hidden')
    this.isDragging = false

    this._processFiles(e.dataTransfer.files)

    this.$scope.$apply()

  }

  _processFiles(files: any) {
    const humanize = this.$filter('humanizeBytes')
    const invalidFiles: any[] = []
    let currentTotalSize = this.chosenFiles.reduce((acc, item) => {
      acc += item.file.size
      return acc
    }, 0)

    Object.keys(files).map(key => {
      const file = files[key]
      const isDup = this.chosenFiles.find(tf => tf.file.name === file.name)
      let invalidReason = ''

      currentTotalSize += file.size

      if (!invalidReason && isDup) invalidReason = 'Duplicate file name.'
      if (!invalidReason && !this.validFileTypes.includes(file.type)) invalidReason = 'Invalid file type. Must be pdf, tiff, png, or jpeg.'
      if (!invalidReason && file.size > this.maxFileSize) {
        invalidReason = `Maximum file size exceeded. File cannot be larger than ${humanize(this.maxFileSize)}`
        currentTotalSize -= file.size
      }
      // if (this.chosenFiles.length >= this.maxAttachments) invalidReason = 'Too many files selected.'
      // console.log('currentTotalSize > this.maxUploadSize', currentTotalSize > this.maxUploadSize, currentTotalSize , this.maxUploadSize)
      if (currentTotalSize > this.maxUploadSize) invalidReason = `Maximum upload size of ${humanize(this.maxUploadSize)} has been exceeded.`

      if (invalidReason) {
        invalidFiles.push({ file, isValid: !invalidReason, invalidReason })
      } else {
        this.chosenFiles.push({ file, isValid: !invalidReason, invalidReason })
      }
    })

    if (invalidFiles.length > 0) {
      this.modalService.open(this.FILE_SELECTOR_MODAL_MESSAGE_TEMPLATES.INVALID_FILES, { invalidFiles }, { size: 'lg' })
    }

    this.onFilesDropped()
  }

  _prevent(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    // return false;
  }

  _fileChanged(e) {
    this.$scope.$apply(() => {
      const file: HTMLInputElement = e.target as HTMLInputElement

      this._processFiles(file.files)

      this.uiElements.theForm?.reset()
    })
  }

  _dropTargetClicked(e: any, file: any): any {
    this._prevent(e)
    file.click(e)
  }

  $onInit() {
    const fileMessage = '<i class="icon icon-inbox-upload"></i> <span>Drop files here or click to browse</span>'

    this.selectedFileMessage = this.$sce.trustAsHtml(fileMessage)
    this.uiElements.body = angular.element(window.document.body)
    this.uiElements.el = this.$element
    this.uiElements.theForm = this.uiElements.el.find('form')[0]
    this.uiElements.theFile = this.uiElements.el.find('input')
    this.uiElements.dropTarget = this.uiElements.el.find('drop-target')
    this.uiElements.dropMessage = this.uiElements.dropTarget.find('drop-message')

    this.uiElements.dropTarget.on('click touchend', e => this._dropTargetClicked(e, this.uiElements.theFile[0]))
    this.uiElements.theFile.on('change', e => this._fileChanged(e))

    this.$scope.$on('show-file-select-dialog', () => this._dropTargetClicked(null, this.uiElements.theFile[0]))
    this.$scope.$on('all-files-removed', () => this.uiElements.theForm?.reset())

    if (!this.utils.isPhone()) {
      this.uiElements.body.on('drop dragdrop dragenter dragover', e => this._prevent(e))
      this.uiElements.dropTarget.on('dragenter dragover', e => this._dragenter(e))
      this.uiElements.dropTarget.on('dragleave', e => this._dragexit(e))
      this.uiElements.dropTarget.on('drop', e => this._drop(e))
    }

  }
}
