import './alert-table'
import {alertsRun} from './alert-routes'
import {AlertService} from './alert-service'
import {AlertNavMenuDirective} from './alert-nav-menu-directive'
import {MyAlertsController} from './alert-controller'
import {AlertIndicatorDirective} from './alert-indicator-directive'
import {alertHasFieldService} from './alert-detail-fields-service'
import { AlertDismissService } from './alert-dismiss-service'
import { findOrCreateModule } from '../ng-helper'
import { EMPTY_RESULTS_STATES, EMPTY_TABLE_STATES } from './alert-table/constants'

findOrCreateModule('app.directives', [])
  .constant('EMPTY_RESULTS_STATES', EMPTY_RESULTS_STATES)
  .constant('EMPTY_TABLE_STATES', EMPTY_TABLE_STATES)

findOrCreateModule('app.alert', [
  'app.core',
  'app.policy',
  'app.utils',
])
.run(alertsRun)
.service('alertService', AlertService)
.factory('alertHasFieldService', alertHasFieldService)
.controller('MyAlertsController', MyAlertsController)
.directive('alertIndicator', AlertIndicatorDirective.factory())
.directive('alertNavMenu', AlertNavMenuDirective.factory())
.service('alertDismissService', AlertDismissService)
