/**
 * Renders the <pending-statement-jobs /> directive as a table of items that are
 * marked as status = `WAITING`.
 */

export class PendingStatementJobs implements ng.IDirective {

  restrict = 'E'
  templateUrl = 'app/batch-statements/bs-pending-statements.html'
  replace = false

  // constructor () { console.debug('>>>INSTANCIATING <<< direct-pending-statement-jobs.ts loaded') }

  /**
   * AngularJS post link function use for initial configuration of instances of PendingStatementJobs
   */
  // tslint:disable-next-line
  // link = (_scope: any, _el: ng.IAugmentedJQuery, _attrs: ng.IAttributes) => {
  //   // const bsCtrl: IBatchStatementsController = scope.bsCtrl
  // }

  /**
   * Creates an instance of PendingStatementJobs, with dependencies injected.
   */
  static factory (): ng.IDirectiveFactory {

    const directive = () => new PendingStatementJobs()

    directive.$inject = []

    return directive
  }
}
