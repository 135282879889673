import { CLandRDataProviderService } from "./cl-n-r-data-provider-service"

/* @ngInject */
export class CLandRRunner {
  static $inject = ['routerHelper']

  constructor (routerHelper) {
    const myStates = this.getStates()

    routerHelper.configureStates(myStates)
  }

  getStates () {
    return [{
      state: 'cl-and-r',
      config: {
        url: '/cl-and-r',
        component: 'credentialsComponentConfig',
        data: {
          // addToNavigation: true,
          secure: true,
          module: 'CLandR',
        },
        onEnter: ['$rootScope','CONSTANTS', function ($rootScope, CONSTANTS) {
          $rootScope.pageTitle = CONSTANTS.modules.CLandR.name
          $rootScope.mobileHeaderTitle = CONSTANTS.modules.CLandR.name
          // $rootScope.hideBarHeader = false;
          $rootScope.state = CONSTANTS.modules.CLandR.id
        }],
      },
    }]
  }
}
