import * as angular from 'angular'

import { FutureAllocationsController, FundRebalanceController } from './allocations-controllers'
import { FundsTransferController } from './fund-transfer-controller'
import { PolicyDetailCoveragesController } from './coverages/policy-detail-coverages-controller'
import { PolicyRequirementsController } from './policy-requirements-controller'
import { AlertByPolicyController } from './alert-by-policy-controller'
import { allPoliciesService } from './all-policies-service'
import { ClientModalController } from './client-modal.controller'
import { fundTransferService } from './fund-transfer-service'
import { PendingPolicyController } from './pending-policy-controller'
import { pendingPolicyService } from './pending-policy-service'
import { OrganizeCoverageWrapper } from './policy-coverages-list'
import { PolicyDetailAdvisorController } from './policy-detail-advisor-controller'
import { PolicyDetailBeneficiariesController } from './policy-detail-beneficiaries-controller'
import { PolicyDetailInformationController } from './policy-detail-information-controller'
import { PolicyDetailPaymentsController } from './policy-detail-payments-controller'
import { PolicyDetailRidersController } from './policy-detail-riders-controller'
import { ridersHelper } from './policy-detail-riders-helper'
import { policyDetailRidersModels } from './policy-detail-riders-models'
import { policyHelper } from './policy-helper'
import { PolicyPrintController } from './policy-print-controller'
import { policyService } from './policy-service'
import { appRun } from './policy-routes'
import { PolicySummaryController } from './policy-summary-controller'
import { PolicyTransactionsHistoryController } from './policy-transactions-history-controller'
import { unsubmittedApplicationsService } from './unsubmitted-applications-service'
import { PolicyDetailValuesController } from './values/policy-detail-values-controller'
import { PolicySummaryRpsDirective } from './policy-summary/policy-summary-rps-directive'
import { RidersService } from './riders/riders-service'
import { ridersComponentConfig } from './riders/riders.component'

angular.module('app.policy', ['app.auth', 'app.core', 'app.correspondence', 'app.utils'])
  .controller('PolicyRequirementsController', PolicyRequirementsController)
  .controller('FundsTransferController', FundsTransferController)
  .controller('FutureAllocationsController', FutureAllocationsController)
  .controller('FundRebalanceController', FundRebalanceController)
  .controller('PolicyDetailCoveragesController', PolicyDetailCoveragesController)
  .controller('AlertByPolicyController', AlertByPolicyController)
  .factory('allPoliciesService', allPoliciesService)
  .controller('ClientModalController', ClientModalController)
  .factory('fundTransferService', fundTransferService)
  .controller('PendingPolicyController', PendingPolicyController)
  .factory('pendingPolicyService', pendingPolicyService)
  .factory('organizeCoverages', OrganizeCoverageWrapper)
  .controller('PolicyDetailAdvisorController', PolicyDetailAdvisorController)
  .controller('PolicyDetailBeneficiariesController', PolicyDetailBeneficiariesController)
  .controller('PolicyDetailInformationController', PolicyDetailInformationController)
  .controller('PolicyDetailPaymentsController', PolicyDetailPaymentsController)
  .service('ridersService', RidersService)
  .controller('PolicyDetailRidersController', PolicyDetailRidersController)
  .factory('ridersHelper', ridersHelper)
  .factory('policyDetailRidersModels', policyDetailRidersModels)
  .factory('policyHelper', policyHelper)
  .controller('PolicyPrintController', PolicyPrintController)
  .controller('PolicySummaryController', PolicySummaryController)
  .controller('PolicyTransactionsHistoryController', PolicyTransactionsHistoryController)
  .controller('PolicyDetailValuesController', PolicyDetailValuesController)
  .factory('policyService', policyService)
  .factory('unsubmittedApplicationsService', unsubmittedApplicationsService)
  .directive('policySummaryRps', PolicySummaryRpsDirective.factory())
  .component('ridersComponent', ridersComponentConfig)
  .run(appRun)
