import * as angular from 'angular'
interface AnnuityValues {
  policyValue: number
  netSurrenderValue: number
  surrenderChargeAmt: number
  surrenderValue: number
}

interface AsOfOption {
  name: Date
  display: string
}

interface PolicyBase {
  effDate?: string,
  polNumber?: string,
  productType?: string
}

interface SectionItem {
  itemType: string
  label: string
  value: number
  valueType: string
}

interface QuoteSection {
  sectionTitle: string
  sectionItems: SectionItem[]
}

interface PolicyValue {
  sections: QuoteSection[]
}

export class PolicyDetailValuesController {
  public allowAsOfDateChange: boolean
  public requestedAsOfDate: Date
  public asOfOptions: AsOfOption[]
  public datePickerAltInputFormats: string[]
  public datePickerOptions
  public errorMessage: string | null
  public hasCurrentValues: boolean
  public isAAR: boolean
  public isDatepickerOpen: boolean
  public isDCA: boolean
  public isLoading: boolean
  public isThereError: boolean
  public isProductCodeFixedException: boolean
  public showAccounts: boolean
  public links // an array of something
  public loan: object
  public policyBase: PolicyBase
  public policyValues: PolicyValue[]
  public subAccounts // an array of something
  public summary
  public totalValue: object
  private _latestAsOfDate: Date
  public showAllocationUnavailableMessage: boolean = false
  public surrenderFreeAmt
  public annuityCashSurrenderValues
  public redacted: boolean = false

  constructor(
    private policyId,
    private policyValuesResult,
    private dateUtils,
    private policyFundsResult,
    private policyBaseResult,
    private policyService,
    private policyUtils,
    private CONSTANTS,
    private $q,
    private summaryResult,
    private $scope,
    private maxLoanQuotes,
    private $timeout,
    private $filter
  ) {
    this._latestAsOfDate = new Date(policyBaseResult.policyBase.asOfDate)
    this.allowAsOfDateChange = true
    this.errorMessage = ''
    this.datePickerAltInputFormats = ['M!/d!/yyyy']
    this.datePickerOptions = {
      formatDay: 'd',
      formatYear: 'yy',
      formatDayHeader: 'EEE',
      minDate: this._setMinYear(this._latestAsOfDate, 3),
      maxDate: this._latestAsOfDate,
      showWeeks: false,
    }
    this.hasCurrentValues = true
    this.isAAR = false
    this.isDatepickerOpen = false
    this.isDCA = false
    this.isLoading = false
    this.isThereError = false
    this.links = this.CONSTANTS.policeDetailValuesLinks
    this.loan = {}
    this.policyBase = {}
    this.policyValues = []
    this.subAccounts = []
    this.summary = {...this.summaryResult.policy}
    this.totalValue = {}
    this.showAllocationUnavailableMessage = this.$scope.summaryCtrl ? this.$scope.summaryCtrl.unableToAllocateMessage.indexOf('pending premium allocation') > -1 : false
    this.annuityCashSurrenderValues = this.policyValuesResult ? this.policyUtils.orderedValues(this.policyValuesResult.policyValues.cashsurrendervalue, this.CONSTANTS.annuityCashSurrenderValueMeta) : []
    this.isProductCodeFixedException = policyUtils.isProductCodeFixedException(summaryResult.policy.productCode)
    this.showAccounts = this._showAccounts()
    if (this.asOfUsesDatePicker(policyBaseResult.policyBase)) {
      this.requestedAsOfDate = this._latestAsOfDate
    } else {
      this.asOfOptions = this._asOfOptions(new Date(summaryResult.policy.effDate), this._latestAsOfDate)
      this.requestedAsOfDate = this.asOfOptions[0].name
    }
    this._getAndSetTodaysPolicyValues()
  }

  hasPendingTransfer(): boolean {
    return this.policyFundsResult.data.subAccount?.some(account => account.estValue)
  }

  asOfChange(): Promise<void> {
    this.isLoading = true
    const asOfDateParameter = this.dateUtils.formatDashJoinedDate(this.requestedAsOfDate)
    const doneLoading = () => {
      this.isLoading = false
    }

    if (this.policyUtils.isAnnuity(this.policyBase)) {
      return this._handleAnnuityAsOfChange(asOfDateParameter).then(doneLoading)
    } else {
      return this._handleLifeAsOfChange(asOfDateParameter).then(doneLoading)
    }
  }

  printValues(redact: boolean): void {
    this.redacted = redact
    this.$timeout(() => {
      window.print()
    }, 100)
  }

  _decodeAnnuityValuesResult(data): AnnuityValues | null {
    if (
      data &&
      data.policyValues &&
      data.policyValues.cashsurrendervalue &&
      typeof data.policyValues.cashsurrendervalue.netSurrenderValue === 'number' &&
      typeof data.policyValues.cashsurrendervalue.policyValue === 'number'
    ) {
      return {
        policyValue: data.policyValues.cashsurrendervalue.policyValue,
        netSurrenderValue: data.policyValues.cashsurrendervalue.netSurrenderValue,
        surrenderChargeAmt: data.policyValues.cashsurrendervalue.surrenderChargeAmt,
        surrenderValue: data.policyValues.cashsurrendervalue.netSurrenderValue,
      }
    }

    return null
  }

  _handleAnnuityAsOfChange(asOfDateParameter: string): Promise<any> {
    const today = this.$filter('date')(this._latestAsOfDate, 'yyyy-MM-dd')
    const asOfDate = today === asOfDateParameter ? undefined : asOfDateParameter

    return this.$q((resolve, reject) => {
      if (!asOfDate) {
        this.summary = {...this.summaryResult.policy}
        this.hasCurrentValues = true
        this.showAccounts = this._showAccounts()

        resolve()
      } else {
      return this.policyService.getPolicyValues(this.policyId, asOfDate)
          .then((data) => {
            const decoded = this._decodeAnnuityValuesResult(data)

            if (decoded) {
              if (!asOfDate) {
                this.summary.netDeathBenefitAmt = this.summaryResult.policy.netDeathBenefitAmt
              }
              this.summary = decoded
              this.hasCurrentValues = false
              this.annuityCashSurrenderValues = this.policyUtils.orderedValues(decoded, this.CONSTANTS.annuityCashSurrenderValueMeta)
            } else {
              this.errorMessage = 'As-of data did not match expected shape.'
            }
          })
          .then(() => this.showAccounts = this._showAccounts())
          .then(() => this.hasCurrentValues = false)
          .then(resolve)
          .catch((error) => {
            this.errorMessage = error
            reject(error)
          })
      }
    })
  }

  _handleLifeAsOfChange(asOfDateParameter: string): Promise<any> {
    return this.$q(resolve => {
      if (this._valuesAreLatest()) {
        return this._getAndSetTodaysPolicyValues()
          .then(() => this.hasCurrentValues = true)
          .then(() => this.showAccounts = this._showAccounts())
          .then(resolve)
      } else {
        return this.policyService.getPolicyValues(this.policyId, asOfDateParameter)
          .then(this._mergeNewValuesResult.bind(this))
          .then(() => this.showAccounts = this._showAccounts())
          .then(() => this.hasCurrentValues = false)
          .then(resolve)
      }
    })
  }

  _mergeNewValuesResult(valuesResult) {
    // The as-of values call does not return death benefits, nor loan quotes.
    // We add an empty deathbenefit object so other things don't blow up.

    if (angular.equals(valuesResult.policyValues.deathbenefit, {}) || !valuesResult.policyValues.deathbenefit) {
      valuesResult.policyValues.deathbenefit = {}
    }

    return this.policyUtils.getPolicyValuesFromResult(valuesResult, this.policyBase, !this._valuesAreLatest(), this.maxLoanQuotes)
      .then(values => this.policyValues = values)
      .catch(error => this.errorMessage = error)
  }

  _asOfOptions(effDateObject, now): AsOfOption[] {
    const options: AsOfOption[] = []
    let monthiversary = this.dateUtils.mostRecentMonthiversary(effDateObject, now)
    let expectedNumberOfOptions = 12 // we go a year back

    if (!this.dateUtils.areSameCalendarDate(monthiversary, now)) {
      // We need today to be one of the options so the user can see everything that's current,
      // not just what the values were in the past.
      options.push(this._monthiversaryOption(now))
      expectedNumberOfOptions++
    }

    while (options.length < expectedNumberOfOptions) {
      options.push(this._monthiversaryOption(monthiversary))
      monthiversary.setMonth(monthiversary.getMonth() - 1)
    }

    return options
  }

  asOfUsesDatePicker(policyBase: PolicyBase = {}): boolean {

    if (this.summary.isAnnuity && this.summary.policyStatus.toLowerCase().includes('death claim')) {
      return false
    }

    return !this.policyUtils.isTermPolicy(policyBase.productType)
  }

  handlePopupContentClick(event): void {
    event.stopPropagation()

    if (event.target.name !== 'valuesAsOfDate') {
      this.isDatepickerOpen = false
    }
  }

  _showAccounts(): boolean {
    return Boolean(this.hasCurrentValues && this._valuesAreLatest() && this.summary &&
      this.summary.productType !== this.CONSTANTS.productType.universalLife &&
      this.summary.productType !== this.CONSTANTS.productType.term &&
      this.summary.productType !== this.CONSTANTS.productType.wholeLife &&
      this.summary.productType !== this.CONSTANTS.productType.endowment)
  }

  showPolicyDetailValueFooterLinks() {
    return this._valuesAreLatest() && this._isVariableProduct()
  }

  _addLoanQuotesToPolicyValues(loanQuoteSections): void {
    if (loanQuoteSections) {
      this.policyValues = this.policyValues.concat({ sections: loanQuoteSections })
    }
  }

  _addPolicyValues(newPolicyValues): void {
    if (newPolicyValues) {
      this.policyValues = newPolicyValues.concat(this.policyValues)
    }
  }

  // summaryResult.policy is what gives us the goodies for annuity values

  _getAndSetTodaysPolicyValues() {
    let basePromise = this.policyUtils.getPolicyBaseFromResult(this.policyBaseResult)
      .then(policyBase => {
        this.policyBase = policyBase

        if (this.policyValuesResult !== null) {
          this.policyUtils.getPolicyValuesFromResult(this.policyValuesResult, policyBase, !this._valuesAreLatest(), this.maxLoanQuotes)
            .then(policyValues => {
              this.policyValues = policyValues
            })
            .catch(errorMessage => {
              this.policyValues = []
              this.setError(errorMessage)
            })
        }

        // Term life policies shouldn't be getting loan quotes.
        if (!this.policyUtils.isTermPolicy(policyBase.productType) && !this.policyUtils.isAnnuity(policyBase)) {
          this.policyService.getPolicyLoanQuotes(this.policyId)
            .then(this._marshalLoanQuotes.bind(this))
            .then(this._addLoanQuotesToPolicyValues.bind(this))
        }
      })
      .catch(errorMessage => {
        this.policyBase = {}
        this.setError(errorMessage)
      })

    let fundPromise = this.policyService.getPolicyFunds(this.policyId) // save the promise so two separate thens can be attached to it

    fundPromise.then(this.policyUtils.getSubAccountsFromResult)
      .then(subAccounts => {
        this.subAccounts = subAccounts || []
      })

    fundPromise.then(this.policyUtils.getAccountsTotalValueFromResult)
      .then(totalValue => {
        this.totalValue = totalValue
      })

    return this.$q.all([basePromise, fundPromise])
  }

  _isVariableProduct(): boolean {
    const variableProductTypes = [this.CONSTANTS.productType.variableUniversalLife, this.CONSTANTS.productType.variableAnnuity]

    return variableProductTypes.includes(this.summary.productType)
  }

  _marshalLoanQuotes(result): QuoteSection[] | null {
    let sections: QuoteSection[] = []

    if (result.error) {
      return null
    }

    let traditionalLoanQuote = result.policyLoanQuotes.filter(quote => quote.loanType.tc === '1')
    let indexedLoanQuote = result.policyLoanQuotes.filter(quote => quote.loanType.tc === '2')

    if (traditionalLoanQuote.length) {
      sections.push(this._marshalLoanQuoteToSection(traditionalLoanQuote[0], 'Traditional'))
    }

    if (indexedLoanQuote.length) {
      sections.push(this._marshalLoanQuoteToSection(indexedLoanQuote[0], 'Indexed'))
    }

    return sections
  }

  _marshalLoanQuoteToSection(loanQuoteModel, loanQuoteType): QuoteSection {
    return {
      sectionTitle: `Available Loan (${loanQuoteType})`,
      sectionItems: [
        {
          itemType: 'info-two-cols',
          label: 'Available Loan Amount:',
          value: loanQuoteModel.maxLoan,
          valueType: 'currency',
        },
        {
          itemType: 'info-two-cols',
          label: 'Loan Interest Rate:',
          value: parseFloat(loanQuoteModel.loanInterestRate),
          valueType: 'percent',
        },
      ],
    }
  }

  _getRole(client) {
    return this.policyUtils.getRole(client)
  }

  _monthiversaryOption(monthiversary) {
    return {
      // Gotta clone it because pass-by-reference can cause unintended behavior.
      name: new Date(monthiversary),
      display: monthiversary.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
    }
  }

  _setMinYear(referenceDate: Date, yearsBack: number): Date {
    const clonedDate = new Date(referenceDate.getTime())

    clonedDate.setFullYear(clonedDate.getFullYear() - yearsBack)
    return clonedDate
  }

  _valuesAreLatest(): boolean {
    // tslint:disable-next-line
    return this.requestedAsOfDate === undefined || this.dateUtils.areSameCalendarDate(this.requestedAsOfDate, this._latestAsOfDate)
  }

  private setError(errorMessage): void {
    this.errorMessage = errorMessage
    this.isThereError = Boolean(this.errorMessage)
  }

}

PolicyDetailValuesController.$inject = ['policyId', 'policyValuesResult', 'dateUtils', 'policyFundsResult', 'policyBaseResult', 'policyService', 'policyUtils', 'CONSTANTS', '$q', 'summaryResult', '$scope', 'maxLoanQuotes', '$timeout', '$filter']
