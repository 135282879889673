/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IPromise, IScope, IController, IOnChangesObject } from 'angular'
import { CrafterService } from '../../utils/crafter-service'
import { UserSettingsService } from '../user-settings.service'
import { IEDeliveryOption, IEDeliveryResult } from '../types'
import { StateParams, StateService } from 'angular-ui-router'
import { ModalService } from '../../components/modal/modal-service'
import { findDataSource } from '../../utils/utils'

const DEFAULT_ERROR_MESSAGE: string = 'Unable to retrieve the client\'s delivery preferences, please contact customer service for more information.'
export class EDeliveryController implements IController {
  static $inject: string[] = ['$rootScope', '$scope', '$stateParams', 'stateTransitions', 'crafterService', 'authService', 'userSettingsService', 'modalService', 'CONSTANTS', '$state']

  constructor(private $rootScope: any, private $scope: IScope, private $stateParams: StateParams , private stateTransitions, private crafterService: CrafterService, private authService, private userSettingsService: UserSettingsService, private modalService: ModalService, private CONSTANTS: any, private $state: StateParams) { }

  private deregisterFn: Function[] = []

  alerts: any[] = []
  setAllPaperless: boolean
  paperlessOptions: any[] = []
  originalSettings: IEDeliveryResult
  payload: any[] = []
  showOptions: boolean = false
  infoBlock: string
  termsAccepted = false
  optionTypes: string
  errorMessage: string

  get hasChanges(): boolean {
    return this.payload?.length > 0
  }

  get disableSaveButton(): boolean {
    return !this.hasChanges || !this.termsAccepted
  }

  get allOff(): boolean {
    let allOffCount = 0
    this.paperlessOptions.forEach((option: IEDeliveryOption) => {
      if (!option.eDelivery) allOffCount++
    })

    return allOffCount === this.paperlessOptions?.length
  }


  get allOn(): boolean {
    let allOnCount = 0
    this.paperlessOptions.forEach((option: IEDeliveryOption) => {
      if (option.eDelivery) allOnCount++
    })

    return allOnCount === this.paperlessOptions?.length
  }

  get userEmail(): string {
    if (this.isARealClient) {
      return this.authService.getCurrentSession().emailAddress
    }

    const cpEmail = findDataSource('client', this.$scope)

    return cpEmail?.clientData?.emailAddress?.[0]?.addrLine
  }

  toggleSetAllPaperless(before: boolean, after: boolean): void {
    if (before === undefined && after === undefined) return

    const toggleChanged: boolean = before !== after

    this.setAllPaperless = this.allOn

    if (toggleChanged) {
      this.paperlessOptions.forEach((option: IEDeliveryOption) => option.eDelivery = after)
    }
  }

  get showUi(): boolean {
    return !this.$rootScope.xhrActive && Boolean(this.originalSettings)
  }

  get disableControl(): boolean {
    return !this.authService.isARealClient()
  }

  get isARealClient(): boolean {
    return this.authService.isARealClient()
  }

  cancel(): void {
    return this.stateTransitions.goBack()
  }

  submit(): void {
    void this.userSettingsService.saveDeliverySettings(this.payload)
      .then(() => {
        this.alerts = []
        this.alerts.push({ type: 'success', msg: 'Your preferences have been saved.' })
        this.termsAccepted = false
      })
      .then(() => this.$scope.$applyAsync(() => this.getDeliverySettings()))
      .catch((err: any) => {
        console.error(err)

        this.alerts = []
        this.alerts.push({ type: 'danger', msg: 'An error has occurred.  Please try again later.' })
      })
  }

  /**
   * Closes the alert toater message.
   *
   * @param index
   */
  closeAlert(index: number): void {
    this.alerts.splice(index, 1)
  }

  showEditPersonalInfo(): any {
    this.$rootScope.$broadcast(this.CONSTANTS.events.CLOSE_POPUPS_AND_MENUS)
    this.modalService.openComponent('changePersonalInfo', {
      keyboard: true,
      backdrop: 'static',
    })

    return this.modalService.modalInstance.result
  }

  getDeliverySettings(): IPromise<any> {
    return this.userSettingsService.getDeliverySettings(this.optionTypes, this.$state.params.cid)
      .then((eDeliveryResults: IEDeliveryResult) => {
        this.originalSettings = { ...eDeliveryResults }
        this.paperlessOptions.forEach((option: IEDeliveryOption) => {
          option.eDelivery = eDeliveryResults[option.userSettingID]
      })

      this.showOptions = true
    }).then(() => {
      const watchKeys: string[] = this.paperlessOptions.map((_option, index) => `eDeliveryCtrl.paperlessOptions[${index}].eDelivery`)
        this.deregisterFn.push(this.$scope.$watchGroup(watchKeys, (_n: boolean[]) => {
        this.setAllPaperless = this.allOn
      }))

      this.setAllPaperless = this.allOn
    })
  }


  $onInit(): void {
    if (!this.isARealClient && !this.$state.params.cid) {
      this.errorMessage = DEFAULT_ERROR_MESSAGE

      return
    }

    this.crafterService.getEDeliveryMetaData()
      .then((result) => {
        this.paperlessOptions = result
        this.infoBlock = 'app/user-settings/e-delivery/info-block-content.html'
        this.optionTypes = result.map((option) => option.userSettingID).join()
      })
      .then(() => this.getDeliverySettings())
      .catch((err) => {
        console.error(err)

        if (!this.$state.params.cid) {
          this.errorMessage = DEFAULT_ERROR_MESSAGE

          return
        }
      })
  }

  $doCheck(): void {
    if (this.originalSettings) {
      this.payload = this.userSettingsService.buildDeliveryPayload(this.paperlessOptions, this.originalSettings)

    }
  }

  $onDestroy(): void {
    this.deregisterFn.forEach((fn: Function) => fn())
  }
}

export const EDeliveryComponent = {
  // bindings: {

  // },
  // require: {

  // },
  templateUrl: 'app/user-settings/e-delivery/e-delivery.html',
  controller: EDeliveryController,
  controllerAs: 'eDeliveryCtrl',
}
