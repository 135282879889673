export {TermsAndConditionsController}
TermsAndConditionsController.$inject = ['$scope', '$sce', '$stateParams', 'authService', 'termsHtml']

  /* @ngInject */
function TermsAndConditionsController($scope, $sce, $stateParams, authService, termsHtml) {
  const self = this



  self.termsHtml = $sce.trustAsHtml(termsHtml)
  self.errorMessage = ''
  self.isThereError = isThereError

  function isThereError() {
    return Boolean(self.errorMessage)
  }
}


