export class LazyLoadingDataService {
  static $inject = ['$q', 'CLandRDataProvider', 'clientMeService']

  constructor(private $q, private CLandRDataProvider, private clientMeService) { }

  _hasNewYorkAppoinments: boolean | undefined = undefined
  hasNewYorkAppointments(): Promise<boolean> {
    if (this._hasNewYorkAppoinments === undefined) {
      return this.CLandRDataProvider.appointments()
        .then(appointments => {
          this._hasNewYorkAppoinments = !!appointments.find(appointment => appointment.state === 'NY')

          return this._hasNewYorkAppoinments
        })
    }

    return this.$q.resolve(this._hasNewYorkAppoinments)
  }

  _myPolicies: any
  myPolicies(clearCache: boolean): Promise<any> {
    if (this._myPolicies === undefined || clearCache) {
      return this.clientMeService.getClientInfo()
        .then((clientResult: any) => {
          this._myPolicies = clientResult

          return this._myPolicies
        })
    }

    return this.$q.resolve(this._myPolicies)
  }
}
