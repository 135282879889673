export {exceptionHandlerProvider, config}
/**
  angular
    .module('blocks.exception')
    .provider('exceptionHandler', exceptionHandlerProvider)
    .config(config)
 
  /**
 * Must configure the exception handling
 *
 * @return {[type]}
 */
function exceptionHandlerProvider() {
    /* jshint validthis:true */
  this.config = {
    appErrorPrefix: undefined,
  }

  this.configure = function(appErrorPrefix) {
    this.config.appErrorPrefix = appErrorPrefix
  }

  this.$get = function() {
    return {
      config: this.config,
    }
  }
}

config.$inject = ['$provide']

  /**
   * Configure by setting an optional string value for appErrorPrefix.
   * Accessible via config.appErrorPrefix (via config value).
   *
   * @param  {[type]} $provide
   * @return {[type]}
   * @ngInject
   */
function config($provide) {
    // $provide.decorator('$exceptionHandler', extendExceptionHandler)
}

extendExceptionHandler.$inject = ['$delegate', 'exceptionHandler', 'loggingService']

  /**
   * Extend the $exceptionHandler service to also display a toast.
   *
   * @param  {Object} $delegate
   * @param  {Object} exceptionHandler
   * @param  {Object} loggingService
   * @return {Function} the decorated $exceptionHandler service
   */
function extendExceptionHandler($delegate, exceptionHandler, loggingService) {
  return function(exception, cause) {
    const errorData = {
      exception: exception,
      cause: cause,
    }
      // exception.message = appErrorPrefix + exception.message;

    $delegate(exception, cause)
      /**
       * Could add the error to a service's collection,
       * add errors to $rootScope, log errors to remote web server,
       * or log locally. Or throw hard. It is entirely up to you.
       * throw exception;
       *
       * @example
       *     throw { message: 'error message we added' };
       */
    loggingService.error(exception.message + errorData, 'error')
  }
}
