import { Beneficiary } from '../../beneficiaries/classes/beneficiary'
import { TypeCodeValue } from '../../beneficiaries/classes/type-code-value'
import { IOrganizedParties } from '../../beneficiaries/types'
import { RolesPermissionService } from '../../client-bene-edit/roles-permission-service'
import { IRoleEligibilityResult } from '../types'

export class PolicyRolesController {
  static $inject = ['BENEFICIARY_ROLES', 'stateUtils', 'beneficiariesDataProvider', '$stateParams', 'modalService', 'BENEFICIARIES_DIST_TYPES', 'authService', 'configService', '$state', 'rolesPermissionService']

  beneIds: string[]
  parties: IOrganizedParties
  infoBlock: string
  sections: any[] = []
  distTypeTypeCodes: any
  isEditable: boolean = false
  policySummary: any
  eligibility: IRoleEligibilityResult
  beneficiaryMetadata: any
  effectiveDate: Date = new Date()
  datePickerOptions: any

  constructor(public BENEFICIARY_ROLES, public stateUtils, private beneficiariesDataProvider, private $stateParams, public modalService, public BENEFICIARIES_DIST_TYPES, public authService, private configService, private $state, private rolesPermissionService: RolesPermissionService) {
    this.distTypeTypeCodes = this.BENEFICIARIES_DIST_TYPES.map((newCodes: any) => new TypeCodeValue(newCodes))
  }

  handlePopupContentClick(event): void {
    event.stopPropagation()
  }

  goToBeneficiaryChange = () => this.stateUtils.checkUserAndGotoState('changeBeneficiaries')

  goToAddRole = () => this.stateUtils.checkUserAndGotoState('roles.edit.role-details')

  submitInterstitial() {
    this.modalService.open('app/client/roles/tmpl/submit-interstitial.html', {
      modalHeader: 'Submit Changes',
    },
      {
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  openShareEditor(section) {
    this.modalService.open('app/client/roles/tmpl/edit-share-modal.html', {
      modalHeader: `Edit Shares for ${section.title}`,
      section,
      distOptions: this.distTypeTypeCodes,
    },
      {
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  showRevertAllModal() {
    this.modalService.open('app/client/roles/tmpl/coming-soon.html', {
      modalHeader: 'Revert Changes',
    },
      {
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  openEditModal() {
    const newParty = new Beneficiary('')

    this.modalService.open('app/components/client-card/templates/section-edit-modal.html', {
      modalHeader: `Add New Party`,
      party: newParty,
      editingCurrent: true,
      deletingCurrent: false,
      revertCurrent: false,
    },
      {
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  viewTransactions() {
    this.$state.go('policy.roles.transactions.history')
  }

  restate(): void {
    this.modalService.openComponent('beneficiaryRestateModal', {
      windowClass: 'beneficiary-restate',
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        effectiveDate: () => this.effectiveDate,
        polNumber: () => this.policySummary.polNumber,
      },
    })

    return this.modalService.modalInstance.result
    // this.beneficiariesDataProvider.restateBeneficiaries(this.effectiveDate, this.policySummary.polNumber)
  }

  get showTransactionsButton(): boolean {
    return this.authService.matchAnyPermission(['canViewPolicyRolesHistory'])
  }

  private initSections(parties) {
    this.sections[0] = {
      rid: this.BENEFICIARY_ROLES.PRIMARY,
      title: 'Primary Beneficiaries',
      parties: parties.beneficiaries.filter((party: Beneficiary) => party.role.tc === this.BENEFICIARY_ROLES.PRIMARY),
    }
    this.sections[1] = {
      rid: this.BENEFICIARY_ROLES.CONTINGENT,
      title: 'First Contingent Beneficiaries',
      parties: parties.beneficiaries.filter((party: Beneficiary) => party.role.tc === this.BENEFICIARY_ROLES.CONTINGENT),
    }
    this.sections[2] = {
      rid: this.BENEFICIARY_ROLES.SECOND,
      title: 'Second Contingent Beneficiaries',
      parties: parties.beneficiaries.filter((party: Beneficiary) => party.role.tc === this.BENEFICIARY_ROLES.SECOND),
    }
    this.sections[3] = {
      rid: this.BENEFICIARY_ROLES.FINAL,
      title: 'Final Beneficiaries',
      parties: parties.beneficiaries.filter((party: Beneficiary) => party.role.tc === this.BENEFICIARY_ROLES.FINAL),
    }
  }

  $onInit () {
    const isDeathClaim = this.policySummary?.policyStatus?.toLowerCase().includes('death claim')

    this.isEditable = this.configService.features.enableEditableRoles && this.authService.isBeneficiaryManagmentAllowed(isDeathClaim) // TODO: Might need to do a separate check for roles management.
    this.beneficiariesDataProvider.getAllRoles(this.$stateParams.id)
      .then((parties: IOrganizedParties) => this.parties = parties)
      .then((parties: IOrganizedParties) => this.initSections(parties))
      .then(() => this.eligibility = this.rolesPermissionService.clientCanManageBeneficiaries(this.policySummary, this.parties, this.beneficiaryMetadata))

    this.infoBlock = 'app/client/roles/tmpl/roles-info-block-content.html'
    this.datePickerOptions = {
      formatDay: 'd',
      formatYear: 'yy',
      formatDayHeader: 'EEE',
      showWeeks: false,
      maxDate: new Date(),
    }
  }
}
