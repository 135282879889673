/* eslint-disable no-underscore-dangle */
import { IGW_DISTRIBUTION_TYPECODES } from '../beneficiary-constants'
import { Beneficiaries } from '../classes/beneficiaries'
import { Beneficiary } from '../classes/beneficiary'
import { BeneficiaryShare } from '../classes/share'
import { TypeCodeValue } from '../classes/type-code-value'
import { BENEFICIARY_VIEWS } from '../constants/BENEFICIARY_VIEWS'
import { BENEFICIARY_ROLES, BENEFICIARY_ROLE_LABELS2, INSURED_ROLES_ARRAY } from '../constants/ROLES'
import { ROLE_CONSTANTS } from '../relationships/role-relationships-service'
import { IEditableBeneficiary } from '../types'
import { AcordCountries } from '../acord-countries'
import { IAddressCountry } from '../../address/types'
import { ClientBeneficiariesController } from '../../client-bene-edit/client-bene.controller'
export class BeneficiaryCategoryController {
  static $inject: string[] = ['$scope', '$state', '$attrs', 'beneficiariesDataProvider', 'BENEFICIARY_EVENTS', 'BENEFICIARY_OTHER_OPTIONS', 'BENEFICIARY_VIEWS', 'BENEFICIARY_PARTY_TYPES', 'BENEFICIARY_ROLES', 'IGW_DISTRIBUTION_TYPECODES', 'FINAL_BENEFICIARY_OPTIONS', 'BENEFICIARIES_DIST_TYPES', 'BENEFICIARY_SETTLEMENT_OPTIONS', 'BENEFICIARY_PARTY_TYPE_INDEX']

  FINAL_BENE_TYPE_OTHER = '3'
  PROPERTY_BLACKLIST = ['isEditMode', 'partyType', '$$hashKey', 'isClean', 'isSeparator', 'hasTypeCode', 'isExistingParty', 'isInsuredAnnuitant', '_isDirty', '_originalPhoneList', 'toBeDeleted', 'primaryPhone', 'sorted']
  category: Beneficiaries
  categoryIndex: number
  beneCtrl: any
  formName: string
  onValidate: Function
  onReady: Function
  onEditorStateChanged: Function
  showBeneficiaryEditor: boolean = false
  activeCategory: any
  currentBeneficiary: Beneficiary | undefined
  relationships: any[]
  selectedParty: any
  equalShares: boolean = false
  policy: any
  partiesDropdownData: any[]
  enableAddBeneficiaries: any
  showSplitEqualOption: any
  hideBeneficiaryEditButtons: boolean
  currentBeneBackup: Beneficiary | undefined
  igwDistributionTypeCodes: any
  distTypeTypeCodes: any
  viewState: any
  userType: string
  clientBeneCtrl: ClientBeneficiariesController
  acordCountries: IAddressCountry[]

  private _deregisterFunctions: Function[] = []
  private _undoCategory: Beneficiaries | null = null
  private _previousViewState: string
  currentValidationState: string

  constructor(private $scope, private $state, private $attrs, private beneficiariesDataProvider, private BENEFICIARY_EVENTS, public BENEFICIARY_OTHER_OPTIONS, private BENEFICIARY_VIEWS, public BENEFICIARY_PARTY_TYPES, public BENEFICIARY_ROLES, IGW_DISTRIBUTION_TYPECODES, private FINAL_BENEFICIARY_OPTIONS, public BENEFICIARIES_DIST_TYPES, public BENEFICIARY_SETTLEMENT_OPTIONS, public BENEFICIARY_PARTY_TYPE_INDEX) {
    this.igwDistributionTypeCodes = Object.keys(IGW_DISTRIBUTION_TYPECODES).reduce((newCodes: any, tcKey: any) => {
      newCodes[tcKey] = new TypeCodeValue(IGW_DISTRIBUTION_TYPECODES[tcKey])

      return newCodes
    }, {})

    this.distTypeTypeCodes = BENEFICIARIES_DIST_TYPES.map((newCodes: any) => new TypeCodeValue(newCodes))
    this.userType = $attrs.userType
  }

  private isEditView: boolean = false

  get isClientEditor(): boolean {
    return this.userType === 'client'
  }

  get isFinalBeneficiary(): boolean {
    return this.category.rid === this.BENEFICIARY_ROLES.FINAL
  }

  get showEditView(): boolean {
    if (this.isClientEditor) {
      return this.viewState?.state === BENEFICIARY_VIEWS.EDIT
    }

    return this.isEditView
  }

  get form(): any {
    if (this.isClientEditor) {
      return this.$scope.$$childHead?.[this.formName]
    }

    return this.$scope[this.formName]
  }

  _partyDropdownOptions(): any[] {
    const parties: any[] = this.isClientEditor ? [] : this.category.associatedParties
      .phoneBookSortedParties()
      .filter((party: any) => !this._partyExists(party))
      .filter((party: any) => !party.policyRoles.find((role: any) => INSURED_ROLES_ARRAY.includes(role.tc)))

    if (this.isFinalBeneficiary) {
      return parties.concat([
        // { isSeparator: true, fullName: '\u2501', id: 'separator' },
        { fullName: 'Update Final Beneficiary', id: 'update_final_beneficiary' },
      ])
    }

    return parties.concat(this.BENEFICIARY_OTHER_OPTIONS)
  }

  _beneficiaryExists(bene: Beneficiary): boolean {
    const foundBene = this.category.beneficiaries.find((b) => b.$$hashKey === bene?.$$hashKey)

    return Boolean(foundBene)
  }

  _partyExists(party: any): boolean {
    const foundBene = this.category.beneficiaries.find((b) => b.id === party?.id)

    return Boolean(foundBene)
  }

  /**
   *
   * @param current Original version of the data being compared
   * @param original Current version of the data being compared
   * @param blackList is an array of strings that indicate which properties to ignore when comparing the objects.
   * @returns
   */
  private _compareObjects(current, original, blackList) {
    const diffs = {}
    const keys = current ? Object.keys(current) : null

    if (!keys) return diffs

    keys.forEach((key) => {
      if (blackList.find(tKey => tKey === key)) return

      const val1 = current[key]
      const val2 = original?.[key]
      const valIsObject = typeof val1 === 'object'

      if (!valIsObject && val1 !== val2) {
        diffs[key] = { before: val2, after: val1 }
      } else if (valIsObject) {
        diffs[key] = this._compareObjects(val1, val2, blackList)
        // if (key === '_phoneList') {
        //   console.log(val1, val2, diffs[key])
        // }
        if (Object.keys(diffs[key]).length === 0) {
          delete diffs[key]
        }
      }
    })

    return diffs
  }

  /**
   *
   * @param currentVersion of the beneficiaries being compared
   * @param originalVersion of the beneficiaries being compared
   * @returns
   */
  private _compareBeneficiaries(currentVersion: Beneficiary | undefined, originalVersion: Beneficiary | undefined) {
    const diffs: any = this._compareObjects(currentVersion, originalVersion, this.PROPERTY_BLACKLIST)
    const hasChanges: boolean = Object.keys(diffs).length > 0

    return { diffs, hasChanges }
  }

  private _resolveFinalBeneficiaryOption(key): any {
    return this.FINAL_BENEFICIARY_OPTIONS.find((optn) => optn.key === key)
  }

  private _checkHasDataState(hasDataMap): void {
    // console.log('_checkHasDataState', hasDataMap)

    switch (this.category.rid) {
      case this.BENEFICIARY_ROLES.PRIMARY:
        this.enableAddBeneficiaries = true
        break

      case this.BENEFICIARY_ROLES.CONTINGENT:
        this.enableAddBeneficiaries = hasDataMap[this.BENEFICIARY_ROLES.PRIMARY]
        break

      case this.BENEFICIARY_ROLES.SECOND:
        this.enableAddBeneficiaries = hasDataMap[this.BENEFICIARY_ROLES.PRIMARY] && hasDataMap[this.BENEFICIARY_ROLES.CONTINGENT]
        break

      case this.BENEFICIARY_ROLES.FINAL:
        this.enableAddBeneficiaries = true
        break
    }
  }

  /**
   *
   */
  _sharesInitialized: boolean = false
  _initializeShares(): void {
    if (!this._sharesInitialized) {
      this.equalShares = this.category.hasEqualShares && this.category.equalSharesCount > 1 && !this.category.inconsistentData

      if (this.equalShares) {
        this.category.beneficiaries.forEach((bene, index) => {
          if (!bene.isFinalBeneficiary && bene.shareDistribution.hasUndefinedDistributionType) {
            const beneForm = this.form['bene_' + String(index)]

            bene.shareDistribution.type = this.igwDistributionTypeCodes.EQUAL_SHARE
            beneForm.$setDirty()
          }
        })
      }

      if (this.userType !== 'client' && this.category.rid === this.BENEFICIARY_ROLES.FINAL) {
        const tmpBene = this.category.beneficiaries[0]
        const tmpBeneForm = this.form?.bene_0

        if (tmpBene && tmpBeneForm && tmpBene.shareDistribution.hasUndefinedDistributionType) {
          tmpBene.shareDistribution.relationDescription = ROLE_CONSTANTS.RELATION_TYPE_CODES.ESTATE
          tmpBene.shareDistribution.type = this.igwDistributionTypeCodes.PERCENT_SHARE
          tmpBene.shareDistribution.amount = 100
          tmpBeneForm.$setDirty()
        }
      }

      this._sharesInitialized = true
    }
  }

  /**
   * Determine if all of the rows in a category have loaded.
   *
   */
  private _formFinishedLoading: boolean = false
  private _formIsReady(): boolean {
    if (!this._formFinishedLoading) {
      let beneCount: number = 0

      if (this.form?.$$controls?.length > 0) {
        for (let i = 0; i < this.category.count; i++) {
          const tmpBene = this.form['bene_' + String(i)]

          if (tmpBene && tmpBene.$$controls.length > 0) beneCount++
        }
      }

      this._formFinishedLoading = beneCount === this.category.count // && (this.category.hasData || this.category.hasDeletedBeneficiaries)

      // console.log(this.formName, ':', this._formFinishedLoading)
    }

    return this._formFinishedLoading

  }

  /**
   *
   */
  private _reset(): void {
    this.category.cleanBeneficiaries()
    this.form.$setPristine()
  }

  splitEqually(): void {
    // Enable Equal Shares
    if (this.equalShares) {
      this.category.beneficiaries.forEach((bene) => {
        if (!bene.isFlatAmount) {
          bene.shareDistribution = new BeneficiaryShare({
            distribution: this.igwDistributionTypeCodes.EQUAL_SHARE,
            distributionValue: '',
            relationshipToInsured: bene.shareDistribution.relationDescription,
            beneShareMethod: bene.shareDistribution.distributionMethod,
            distributionOptionDesc: bene.shareDistribution.settlementOption,
          })
          bene.isDirty = true
        }
      })
    } else {
      // Disable Equal Shares
      this.category.beneficiaries.forEach((bene) => {
        if (!bene.isFlatAmount) { // Don't reset anything if the bene get's a flat amount
          // bene.shareDistribution.amount = bene.percentageAmountBackup || ''
          bene.shareDistribution.type = this.igwDistributionTypeCodes.PERCENT_SHARE
        }

        bene.isDirty = true
      })
    }

    // this.$scope.$applyAsync()

  }

  selectText(event: any): void {
    event.target?.select()
  }

  startChanges(_event: any, previousViewState: any): void {
    if (this.isClientEditor) {
      this._undoCategory = this.clientBeneCtrl.originalData.find((origBenes: Beneficiaries) => origBenes.rid === this.category.rid)
    } else {
      this._undoCategory = this.beneficiariesDataProvider.deepCloneBeneficiaries(this.category)
    }

    this._previousViewState = previousViewState // Not really needed for CBSS
    this.isEditView = true
    this.onReady({ categoryIndex: this.categoryIndex, reason: this.BENEFICIARY_VIEWS.EDIT })
  }

  /**
   *
   * @param party
   */
  partySelected(party: any): void {
    const newBene: Beneficiary = party instanceof Beneficiary ? party : new Beneficiary(this.category.rid, party)

    if (this.equalShares && newBene.roleId !== this.BENEFICIARY_ROLES.FINAL) {
      newBene.shareDistribution.type = this.igwDistributionTypeCodes.EQUAL_SHARE
    }

    if (newBene.isNew && ((!newBene.isExistingParty && newBene.name.isEmpty) || (newBene.isExistingParty))) {
      this.addBeneficiaryToCategory(newBene)
      this.partiesDropdownData = this._partyDropdownOptions()
    }

    if (!party.isExistingParty) {
      this.openBeneficiaryEditor(newBene)
    }
  }

  /**
   *  Adds a new bene to the categoroes beneficiaries array.
   *
   * @param newBene
   */
  addBeneficiaryToCategory(newBene: any): any {
    const newShareDistribution = (this.equalShares && this.category.equalSharesCount > 1) ? { distribution: IGW_DISTRIBUTION_TYPECODES.EQUAL_SHARE, distributionValue: 0 } : { distribution: IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE, distributionValue: 100 }
    const constructorParam = Object.assign({
      beneShareMethod: newBene?.shareDistribution.distributionMethod,
      distributionOptionDesc: newBene?.shareDistribution.settlementOption,
      relationshipToInsured: newBene?.shareDistribution.relationDescription,
      role: BENEFICIARY_ROLE_LABELS2[newBene.roleId],
      tc: newBene.roleId,
    }, newShareDistribution)

    newBene.shareDistribution = new BeneficiaryShare(constructorParam)

    this.category.beneficiaries.push(newBene)
  }

  /**
   * Opens the editor, displaying the provided bene.
   *
   * @param bene
   */
  openBeneficiaryEditor(bene: Beneficiary | undefined): void {
    if (this.isClientEditor) {
      this.clientBeneCtrl.setCurrentBeneficiary(this.category.rid, bene, this.equalShares)
      this.$state.go('myPolicy.clientBeneEdit.edit')
    } else {
      if (!bene) throw new Error('openBeneficiaryEditor: bene parameter is required')

      // Make a backup for when the user cancels the editing
      this.currentBeneBackup = new Beneficiary(this.category.rid, bene)

      // Sets the Beneficiary object that is to be edited.
      this.currentBeneficiary = bene

      bene.partyType = this.BENEFICIARY_PARTY_TYPE_INDEX[bene.partyTypeCode.tc]

      // if (bene.partyType === BENEFICIARY_PARTY_TYPES.ESTATE) {
      //   bene.shareDistribution.relationDescription = ROLE_CONSTANTS.RELATION_TYPE_CODES[ROLE_CONSTANTS.RELATION_NAMES.ESTATE]
      // }

      // Used to determine which version of the editor to show. (e.g. PERSON editor)
      this.selectedParty = bene.partyType

      // Used to determin the viewState of the category when in edit mode or not.
      bene.isEditMode = true

      // Used to hide and show the editor.
      this.showBeneficiaryEditor = true

      // Signal all category components that the editor has been opened by this current category.
      this.onEditorStateChanged({ categoryId: this.category.rid, state: 'open' })

    }
  }

  /**
   * Closes the editor, and resets all related properties, then notifies
   * the main component so that it can broadcast the new state to the
   * other categories.
   *
   * NOTE: This throws away the and data entered by the user.
   *
   */
  cancelBeneficiaryEditor(index): void {
    // const beneEditorForm = this.form['bene_' + index]?.beneEditorForm
    const isNetNewBene = this.currentBeneBackup?.isNew && !this.currentBeneBackup.isExistingParty
    const cleanName: boolean | undefined = this.currentBeneBackup?.name.isEmpty

    console.log('cancelBeneficiaryEditor', isNetNewBene, cleanName)

    // if ((this.currentBeneficiary.isNew && !this.currentBeneficiary.isExistingParty && this.currentBeneficiary?.name?.isEmpty && (beneEditorForm.$pristine || beneEditorForm.$invalid)) || (!this.currentBeneficiary.name && this.currentBeneficiary.isFinalBeneficiary)) {
    if (isNetNewBene && cleanName) {
      // Newly added bene (not an existing party) are removed from the beneficiaries array when cancelling
      this.category.removeBeneficiaryByIndex(0, -1)
    } else if (this.currentBeneBackup) {
      this.category.beneficiaries[index] = this.currentBeneBackup
      this.category.beneficiaries[index].isEditMode = false
      this.category.beneficiaries[index].partyType = ''
    }

    this.showBeneficiaryEditor = false
    this.activeCategory = null
    this.currentBeneficiary = undefined
    this.currentBeneBackup = undefined

    this.onEditorStateChanged({ categoryId: this.category.rid, state: 'closed' })
  }

  /**
   *
   * @param index
   * @returns
   */
  disableEditorSaveButton(index: number): boolean {
    const beneForm = this.form[`bene_${index}`]
    const { hasChanges } = this._compareBeneficiaries(this.currentBeneficiary, this.currentBeneBackup)
    const tmp = beneForm.$$controls.find((c) => c.$dirty)

    if (!tmp) beneForm.$setPristine()

    const editorState = {
      beneForm: `$invalid: ${beneForm?.$invalid}, $pristine: ${beneForm?.$pristine}, hasChanges: ${hasChanges}`,
      disableButton: beneForm?.$pristine || beneForm?.$invalid, // || (!hasChanges && !this.currentBeneficiary?.isFinalBeneficiary)||*/  !beneForm?.$touched,
    }

    return editorState.disableButton

  }

  /**
   * Removes a newly added beneficiary from the category. If the beneficiary
   * was already part of the category then it is marked toBeDeleted instead
   * of deleting it fr4om the list.
   *
   * @param index
   * @param bene
   */
  removeNewBeneficiary(index: number, bene: Beneficiary): void {
    if (bene.isNew || bene.isExistingParty) {
      this.category.beneficiaries.splice(index, 1)
      this.partiesDropdownData = this._partyDropdownOptions()
    } else {
      bene.toBeDeleted = true
      bene.isDirty = true
    }

    // console.log(`removeNewBeneficiary:: deletedPolicyCount: ${this.category.deletedPolicyCount}`)
    if (this.category.hasEqualShares && this.category.equalSharesCount === 1) {
      const beneObj: IEditableBeneficiary | undefined = this.category.beneficiaries.find((b: Beneficiary): boolean => b.isEqualShare && !b.toBeDeleted)

      // turn off equal shares flag
      this.equalShares = false

      // Convert to percentage when there is only one equal shares.
      if (beneObj) {
        beneObj.shareDistribution = new BeneficiaryShare({
          distribution: IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE,
          distributionValue: 100,
          beneShareMethod: beneObj?.shareDistribution.distributionMethod,
          distributionOptionDesc: beneObj?.shareDistribution.settlementOption,
          relationshipToInsured: beneObj?.shareDistribution.relationDescription,
        })
        beneObj.isDirty = true

        bene.shareDistribution = new BeneficiaryShare({
          distribution: IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE,
          distributionValue: '',
          beneShareMethod: beneObj?.shareDistribution.distributionMethod,
          distributionOptionDesc: beneObj?.shareDistribution.settlementOption,
          relationshipToInsured: beneObj?.shareDistribution.relationDescription,
        })
        bene.isDirty = true
      }

    } else if (this.category.hasPercentageShares && this.category.percentageShareCount === 1) {
      const beneObj: IEditableBeneficiary | undefined = this.category.beneficiaries.find((b: Beneficiary): boolean => b.isPercentage && !b.toBeDeleted)

      if (beneObj) {
        beneObj.shareDistribution.amount = 100
        beneObj.isDirty = true
      }
    }

    this.$doCheck()

  }

  /**
   * Clears the toBeDeleted flag on the given bene.
   *
   * If it was an equal share, and the equalShares flag is not checked,
   * and the equalSharesCount is greater than 1 call splitEqual() to
   * enabled equalShares again.
   *
   * @param bene
   */
  undeleteBeneficiary(bene: Beneficiary): void {
    bene.toBeDeleted = false

    console.log('undeleteBeneficiary: bene.isEqualShare: %s, this.category.inconsistentData: %s', bene.isEqualShare, this.category.inconsistentData)

    if ((bene.isEqualShare && !this.equalShares) || this.category.inconsistentData) {
      this.equalShares = true
      this.splitEqually()
    }

    if (this.equalShares && this.category.count === 1) {
      const remainingBene = this.category.beneficiaries.find((b) => !b.toBeDeleted)

      this.equalShares = false

      if (remainingBene) {
        bene.shareDistribution.type = new TypeCodeValue(IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE)
        bene.shareDistribution.amount = 100
      }
    }
  }

  _markDirtyBeneficiaries(): void {
    this.category.beneficiaries.forEach((bene, index) => {
      const beneForm = this.form['bene_' + String(index)]

      bene.isDirty = bene.isDirty || beneForm?.$dirty
    })
  }

  saveChanges(): void {
    this._markDirtyBeneficiaries()

    this._undoCategory = null
    this.isEditView = false
    this.category.hasPendingChanges = true
    this.onReady({ categoryIndex: this.categoryIndex, reason: this.BENEFICIARY_VIEWS.REVIEW })
  }

  undoChanges(): void {
    this.category = this.beneficiariesDataProvider.deepCloneBeneficiaries(this._undoCategory)
    this._undoCategory = null
    this.isEditView = false
    this.onReady({ categoryIndex: this.categoryIndex, reason: this._previousViewState })
  }

  inputValueChanged(bene: Beneficiary): void {
    if (bene?.isPercentage) {
      bene.shareDistribution.amount = ''

      if (this.equalShares) {
        bene.shareDistribution.type = this.igwDistributionTypeCodes.EQUAL_SHARE
      }
    }

    if (this.category.hasEqualShares && this.category.equalSharesCount === 1) {
      const equalBene = this.category.beneficiaries.find((b) => b.isEqualShare)

      if (equalBene) {
        equalBene.shareDistribution = new BeneficiaryShare({
          distribution: IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE,
          distributionValue: 100,
          beneShareMethod: equalBene?.shareDistribution.distributionMethod,
          distributionOptionDesc: equalBene?.shareDistribution.settlementOption,
          relationshipToInsured: equalBene?.shareDistribution.relationDescription,
        })

        equalBene.isDirty = true
        this.equalShares = false

        this.$scope.$applyAsync()
      }

    }
    // console.log('inputValueChanged', type, )
    // console.log('inputValueChanged after: ', type, cacheValue, bene[type], bene.shareDistribution)
  }

  saveBeneficiary(index: number, bene: Beneficiary): void {
    const editorForm = this.form['bene_' + String(index)]?.beneEditorForm

    if (bene && editorForm) {
      bene.isEditMode = false
      bene.isDirty = true
      if (bene.partyType === this.BENEFICIARY_PARTY_TYPES.ESTATE) {
        if (editorForm.finalBeneType.$modelValue === this.FINAL_BENE_TYPE_OTHER) {
          bene.name.fullName = editorForm.finalBeneName.$modelValue
        } else {
          bene.name.fullName = this._resolveFinalBeneficiaryOption(editorForm.finalBeneType.$modelValue)?.value
        }
      }
    }

    this.showBeneficiaryEditor = false
    this.activeCategory = null
    this.currentBeneficiary = undefined
    this.currentBeneBackup = undefined

    this.onEditorStateChanged({ categoryId: this.category.rid, state: 'closed' })
  }

  toFlatAmount(bene: Beneficiary): void {
    bene.shareDistribution = new BeneficiaryShare({
      distribution: IGW_DISTRIBUTION_TYPECODES.FLATAMOUNT_SHARE,
      distributionValue: 0,
      beneShareMethod: bene?.shareDistribution.distributionMethod,
      distributionOptionDesc: bene?.shareDistribution.settlementOption,
      relationshipToInsured: bene?.shareDistribution.relationDescription,
    })

    if (this.equalShares && this.category.hasEqualShares && this.category.equalSharesCount === 1) {
      this.equalShares = false

      const remainingEqualShare: Beneficiary | undefined = this.category.beneficiaries.find((b: Beneficiary): boolean => b.isEqualShare)

      if (remainingEqualShare) {
        remainingEqualShare.shareDistribution = new BeneficiaryShare({
          distribution: IGW_DISTRIBUTION_TYPECODES.PERCENT_SHARE,
          distributionValue: 100,
          beneShareMethod: remainingEqualShare?.shareDistribution.distributionMethod,
          distributionOptionDesc: remainingEqualShare?.shareDistribution.settlementOption,
          relationshipToInsured: remainingEqualShare?.shareDistribution.relationDescription,
        })

        remainingEqualShare.isDirty = true
      }
    }
  }

  get hideCategoryWhenActive(): boolean {
    return this.viewState ? this.viewState.state !== 'open' && this.$scope.$root.xhrActive : false
  }

  /**
   * Returns true if the form, or category are dirty, or the category has deleted beneficiaries.
   */
  get isDirty(): boolean {
    return this.form?.$dirty || this.category.isDirty || this.category.hasDeletedBeneficiaries
  }

  /**
   * returns true when the category has data.
   */
  get showTable(): boolean {
    return this.category.hasData || (!this.category.hasData && this.category.hasDeletedBeneficiaries)
  }

  private _updateViewState(viewState) {
    this.hideBeneficiaryEditButtons = viewState.state === 'open'  // Alternate state is 'closed'

    if (viewState.state === BENEFICIARY_VIEWS.CONFIRMATION) {
      this.form.$setPristine()
    }
  }

  $onInit(): void {
    this.hideBeneficiaryEditButtons = false
    this.formName = 'catForm' + this.category.rid

    this.partiesDropdownData = this._partyDropdownOptions()
    // When there are equalShares check for undefined shareDistribution.type

    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.START_CHANGES, (event, previousViewState) => this.startChanges(event, previousViewState)))
    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.UNDO_CHANGES, () => this.undoChanges()))
    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.SAVE_CHANGES, () => this.saveChanges()))
    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.CHANGES_COMPLETED, () => this._reset()))
    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.ENABLE_ADD_BENE_DD, (_e, hasDataMap) => this._checkHasDataState(hasDataMap)))
    this._deregisterFunctions.push(this.$scope.$on(this.BENEFICIARY_EVENTS.EDITOR_STATE_CHANGE, (_e, viewState) => this._updateViewState(viewState)))

    this.onReady({ categoryIndex: this.categoryIndex, reason: this.BENEFICIARY_VIEWS.INITIAL })

  }

  get hasValidFlatAmounts(): boolean {
    const formValuesValid = this.form?.$$controls.reduce((acc, item) => {
      if (!item.$$attr) {
        const tmpItem = item.$$controls.find((childItem) => childItem.$$attr?.ngModel === 'bene.shareDistribution.amount')

        if ((tmpItem?.$dirty && tmpItem?.$valid) || tmpItem?.$pristine) {
          acc.push(tmpItem)
        }
      }

      return acc
    }, []).length > 0

    return this.category.dollarAmountsValid && formValuesValid
  }

  hasValidData(cat: Beneficiaries): boolean {
    const tests = {
      hasConsistentData: cat.hasConsistentData,
      hasValidShares: cat.hasValidShares,
      validPercentAmount: (cat.hasPercentageShares && cat.totals100Percent) || cat.hasEqualShares,
      validDollarAmount: (cat.hasDollarAmounts && this.hasValidFlatAmounts) || !cat.hasDollarAmounts,
      hasDeletedBenes: cat.hasDeletedBeneficiaries,
      validSingleBene: cat.rid !== BENEFICIARY_ROLES.FINAL && cat.count === 1 && cat.hasPercentageShares && cat.totals100Percent,
    }

    return tests.hasConsistentData && tests.hasValidShares && (cat.hasEqualShares || tests.validPercentAmount || tests.validSingleBene) && tests.validDollarAmount
  }

  hasValidData2(cat: Beneficiaries): boolean {
    const tests = {
      cat: cat.rid,
      hasConsistentData: cat.hasConsistentData,
      hasValidShares: cat.hasValidShares,
      validPercentAmount: (cat.hasPercentageShares && cat.totals100Percent) || cat.hasEqualShares,
      categoryValid: false,
    }

    switch (cat.rid) {
      case BENEFICIARY_ROLES.PRIMARY:
        tests.categoryValid = cat.count > 0 && tests.validPercentAmount
        break
      case BENEFICIARY_ROLES.CONTINGENT:
        tests.categoryValid = cat.count === 0 || cat.count > 0 && tests.validPercentAmount
        break
      case BENEFICIARY_ROLES.FINAL:
        tests.categoryValid = cat.count === 0 || cat.count === 1 && (cat.totals100Percent || !cat.beneficiaries[0]?.shareDistribution?.amount)
        break
    }

    // console.log('hasValidData2')
    // console.table(tests)

    return tests.hasConsistentData && tests.hasValidShares && tests.categoryValid
  }

  get ngFormIsValid(): boolean {
    return this.form?.$pristine || (this.form?.$dirty && this.form?.$valid)
  }

  get hasChanges(): boolean {
    const serialized: any = this.category.serialize(this._undoCategory.beneficiaries)
    const changeCount: number = Object.values(serialized).reduce((acc1: number, value1: any[]): number => {
      const t = Object.values(value1).reduce((acc2, value2) => {
        acc2 += value2.length

        return acc2
      }, 0)
      acc1 += t

      return acc1
    }, 0) as number

    return changeCount > 0
  }

  get hasChanges2(): boolean {
    // if(this.isClientEditor) {
    //   return false  // TODO: Make this work for Client Bene Changes.
    // }

    const serialized: any = this.category.serialize(this.clientBeneCtrl.originalData.find((benes: Beneficiaries) => benes.rid === this.category.rid)?.beneficiaries)
    const changeCount: number = Object.values(serialized).reduce((acc1: number, value1: any[]): number => {
      const t = Object.values(value1).reduce((acc2, value2) => {
        acc2 += value2.length

        return acc2
      }, 0)
      acc1 += t

      return acc1
    }, 0) as number

    return changeCount > 0
  }

  $doCheck(): void {
    if (this.showEditView && this._formIsReady()) {

      if (this.isClientEditor) {

        const categoryHasChanges: boolean = this.category.isDirty || this.category.hasDeletedBeneficiaries || this.category.hasNewBeneficiaries
        const categoryFormIsValid: boolean = this.form?.$dirty && this.form?.$valid
        const hasValidChanges: boolean = (categoryHasChanges || categoryFormIsValid) && (this.category.hasEqualShares || this.category.percentageShareAreBalanced)

        // console.log('$doCheck', this.formName, categoryHasChanges, this.form?.$dirty, categoryFormIsValid, hasValidChanges)

        this._initializeShares()

        this.showSplitEqualOption = this.BENEFICIARY_ROLES.FINAL !== this.category.rid &&
          this.category.count > 1 &&
          ((this.category.hasEqualShares && this.category.equalSharesCount > 1) || (this.category.hasPercentageShares && this.category.percentageShareCount > 1))

        this.clientBeneCtrl.runChecks(this.categoryIndex, this.hasValidData2(this.category), this.hasChanges2)
      } else {
        const categoryChanges = this.category.beneficiaries.map((bene: Beneficiary) => {
          const prev = this._undoCategory?.beneficiaries.find((oBene) => oBene.id === bene.id)
          const { diffs, hasChanges } = this._compareBeneficiaries(bene, prev)

          return { diffs, hasChanges }
        })
        // const diffCount = categoryChanges.filter((t) => t.hasChanges).length

        const categoryHasChanges = this.hasChanges // diffCount > 0 || this.category.count !== this._undoCategory?.count || this.form.$dirty
        const result = {
          formName: this.formName,
          hasData: this.category.hasData,
          categoryIsValid: this.hasValidData(this.category) && this.ngFormIsValid,
          hasChanges: categoryHasChanges,
          hashKey: '',
        }

        result.hashKey = String(Number(result.hasData)) + String(Number(result.categoryIsValid)) + String(Number(result.hasChanges))

        this._initializeShares()

        this.showSplitEqualOption = this.BENEFICIARY_ROLES.FINAL !== this.category.rid &&
          this.category.count > 1 &&
          ((this.category.hasEqualShares && this.category.equalSharesCount > 1) || (this.category.hasPercentageShares && this.category.percentageShareCount > 1))

        this.currentValidationState = JSON.stringify({ categoryChanges, $valid: this.form?.$valid, $dirty: this.form?.$dirty, $pristine: this.form?.$pristine, result }, null, 2)

        this.onValidate(result) // Start here tomorrow
      }
    }
  }

  $onDestroy(): void {
    this._deregisterFunctions.forEach(fn => fn())
  }

}

export const beneficiaryCategoryComponentConfig = {
  transclude: true,
  require: {
    beneCtrl: '?^beneficiariesComponent',
  },
  bindings: {
    relationships: '=',
    category: '=',
    policy: '=',
    categoryIndex: '@',
    onValidate: '&',
    onReady: '&',
    onEditorStateChanged: '&',
    viewState: '<',
    clientBeneCtrl: '<',
    acordCountries: '<',
    // userType: '<',
  },
  templateUrl: ['$attrs', ($attrs) => {
    if ($attrs.userType === 'client') return 'app/client/client-bene-edit/tmpl/beneficiary-category.html'

    return 'app/client/beneficiaries/category/templates/beneficiary-category.html'
  }],
  controller: BeneficiaryCategoryController,
  controllerAs: 'ctrl',
}