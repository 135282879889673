export const RESTRICTED_CONTRACT_FLAGS = [
  '1012300001',
  '1012300002',
  '1012300003',
  '1012300004',
  '1012300005',
  '1012300006',
  '1012300007',
  '1012300008',
  '1012300009',
  '1012300010',
  '1012300011',
  '1012300012',
  '1012300013',
  '1012300014',
  '1012300015',
  '1012300016',
  '1012300017',
  '1012300018',
  '1012300019',
  '1012300020',
  '1012300021',
  '1012300022',
  '1012300023',
  '1012300024',
  '1012300025',
  '1012300026',
  '1012300027',
  '1012300028',
  '1012300029',
  '1012300030',
  '1012300031',
  '1012300032',
  '1012300033',
]

export const RESTRICTED_CLIENT_FLAGS = [
  '1012300001',
  '1012300002',
  '1012300003',
  '1012300004',
  '1012300005',
  '1012300006',
  '1012300007',
  '1012300008',
  '1012300009',
  '1012300010',
  '1012300011',
  '1012300012',
  '1012300013',
  '1012300014',
  '1012300015',
  '1012300016',
  '1012300017',
  '1012300018',
  '1012300019',
  '1012300020',
  '1012300021',
  '1012300022',
  '1012300023',
  '1012300024',
]
