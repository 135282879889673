export {fundTransferService}

fundTransferService.$inject = ['$rootScope', '$stateParams', '$http', 'CONSTANTS', 'stringUtils', 'policyService', 'authService']

/* @ngInject */
function fundTransferService($rootScope, $stateParams, $http, CONSTANTS, stringUtils, policyService, authService) {
  const service = {}

  service.transferFunds = function(id, fundParams) {
    const url = CONSTANTS.policyTransferFundsURL.replace('{0}', id)

    return $http.post(url, fundParams)
  }

  service.getCanTransferFunds = function(policyId) {
    const url = CONSTANTS.policyCanTransferFundsURL.replace('{0}', policyId)

    return $http.get(url)
  }

  service.getCanRebalanceFunds = function(policyId) {
    const url = CONSTANTS.policyRebalanceFundsURL.replace('{0}', policyId)

    return $http.get(url)
  }

  service.getSourceFunds = function(id) {
    const url = CONSTANTS.policyTransferSourceFundsURL.replace('{0}', id)

    return $http.get(url)
      .catch(angular.identity)
  }

  service.getDestinationFunds = function(id) {
    const url = CONSTANTS.policyTransferDestinationFundsURL.replace('{0}', id)

    return $http.get(url)
      .catch(angular.identity)
  }

  service.getDestinationRebalanceFunds = function(id) {
    const url = CONSTANTS.policyRebalanceDestinationFundsURL.replace('{0}', id)

    return $http.get(url)
      .catch(angular.identity)
  }

  service.rebalanceFunds = function(id, fundParamsArray) {
    return $http.post(`${CONSTANTS.apiRoot}policy/${id}/funds/rebalance`, fundParamsArray)
  }

  service.setFutureAllocations = function(id, fundParamsArray) {
    return $http.post(`${CONSTANTS.apiRoot}policy/${id}/funds/allocation`, fundParamsArray)
  }

  service.transactionData = function(id, section) {
    if (section === 'transfer') {
      return policyService.getPolicyCanTransferFunds(id).then((data) => {
        return data.data
      })
    } else if (section === 'rebalance') {
      return service.getCanRebalanceFunds(id).then((data) => {
        return data.data
      })
    }

    return policyService.getPolicyCanAllocateFunds(id).then((data) => {
      return data.data
    })
  }

  service.summaryResult = function(policyID, policySource) {
    return policyService.getPolicySummary(policyID, policySource).then((data) => {
      return data.policy
    })
  }

  service.setUnableTransactionTypes = function(section) {
    if (section === 'transfer') {
      return CONSTANTS.unableToTransferTypes
    } else if (section === 'rebalance') {
      return CONSTANTS.unableToRebalanceTypes
    }

    return CONSTANTS.unableToAllocateTypes
  }

  service.setClientUnableTransactionMessage = function(section, reasonTC) {
    if (section === 'transfer') {
      return CONSTANTS.clientUnableToTransferTypes[reasonTC]
    } else if (section === 'rebalance') {
      return CONSTANTS.clientUnableToRebalanceTypes[reasonTC]
    }

    return CONSTANTS.clientUnableToAllocateTypes[reasonTC]
  }

  /**
   * Returns the message based on section and transactData passed into the function.
   *
   * @param {*} transactData
   * @param {*} section
   */
  service.resolveUnableToTransactMessage = function(transactData, section, policy) {
    const previousTransactionTypes = ['Fund Transfer', 'Premium Allocation', 'Fund Rebalance', 'Service']
    let message = ''
    const result = transactData

    if (result.reason) {
      const previousTransaction = stringUtils.containsAny(result.reason.value, previousTransactionTypes)
      let product = policy.lineOfBusiness === 'Annuity' ? 'contract' : 'policy'
      const unableTypes = service.setUnableTransactionTypes(section)

      message = unableTypes[result.reason.tc]

      if (section === 'rebalance' && result.reason.tc === '15') {
        product = policy.lineOfBusiness === 'Annuity' ? 'contracts' : 'policies'
      }

      if (result.reason.tc === '5') {
        product = policy.lineOfBusiness === 'Annuity' ? 'Contract' : 'Policy'
      }

      if ((!authService.isARealClient() && $rootScope.auth.isClientView()) || authService.isARealClient()) {
        message = service.setClientUnableTransactionMessage(section, result.reason.tc)
      }

      if (message) {
        if (message && previousTransaction) {
          message = message.replace('{0}', product).replace('{1}', policy.policyStatus).replace('{2}', previousTransaction.toLowerCase())
        }

        message = message.replace('{0}', product).replace('{1}', policy.policyStatus)
      }
    }

    return message || ''
  }

  // @DEPRECATED: The logic in the then of this function has been moved to `resolveUnableToTransactMessage`
  service.unableToTransactMessages = function(section, policy) {
    return service.transactionData(policy.polNumber, section).then((data) => {
      const previousTransactionTypes = ['Fund Transfer', 'Premium Allocation', 'Fund Rebalance', 'Service']
      let message = ''
      const result = data

      if (result.reason) {
        const previousTransaction = stringUtils.containsAny(result.reason.value, previousTransactionTypes)
        let product = policy.lineOfBusiness === 'Annuity' ? 'contract' : 'policy'
        const unableTypes = service.setUnableTransactionTypes(section)

        message = unableTypes[result.reason.tc]

        if (section === 'rebalance' && result.reason.tc === '15') {
          product = policy.lineOfBusiness === 'Annuity' ? 'contracts' : 'policies'
        }

        if (result.reason.tc === '5') {
          product = policy.lineOfBusiness === 'Annuity' ? 'Contract' : 'Policy'
        }

        if ((!authService.isARealClient() && $rootScope.auth.isClientView()) || authService.isARealClient()) {
          message = service.setClientUnableTransactionMessage(section, result.reason.tc)
        }

        if (message) {
          if (message && previousTransaction) {
            message = message.replace('{0}', product).replace('{1}', policy.policyStatus).replace('{2}', previousTransaction.toLowerCase())
          }

          message = message.replace('{0}', product).replace('{1}', policy.policyStatus)
        }
      }

      return message || ''
    })
  }

  return service
}
