import ng, { IAttributes, IAugmentedJQuery, INgModelController, IScope } from "angular"
// eslint-disable-next-line no-control-regex
export const BAD_CHARS_RX: RegExp = /[^\u0000-\u00ff]/gumi


class ValidateTextDirectiveController {
  badCharacterText: string = ''

  highlightBadChars(sourceText: string): string {
    if (!sourceText) return ''

    return sourceText.replace(BAD_CHARS_RX, (c) => {
      return `<span class='invalid-text'>${c}</span>`
    })
  }
}

export class ValidateTextDirective implements ng.IDirective {
  require = 'ngModel'
  restrict = 'A'
  bindToController = true
  scope = {
    onBadText: '&',
  }

  controller = ValidateTextDirectiveController
  controllerAs = 'vtCtrl'

  link(_$scope: any, _$el: IAugmentedJQuery, _$attrs: IAttributes, model: INgModelController): void {

    model.$validators.validateText = (_modelValue: String, viewValue: String): boolean => {
      if(!viewValue) return true // empty fields don't need this validation.

      const matches = viewValue.match(BAD_CHARS_RX)

      if(matches) {
        _$scope.vtCtrl.onBadText({ invalidTextKey: _$attrs.validateText, invalidText: _$scope.vtCtrl.highlightBadChars(viewValue)})
      } else {
        _$scope.vtCtrl.onBadText({ invalidTextKey: _$attrs.validateText, invalidText: ''})
      }
      return !matches
    }

  }

  static factory(): ng.IDirectiveFactory {

    const directive = () => new ValidateTextDirective()

    directive.$inject = []

    return directive
  }
}
