class TransactionsMainController {
  static $inject: string[] = ['$scope', '$state']

  infoBlock: string = ''
  blockHeading: string = ''

   constructor(private $scope, public $state) { }

  // $onInit(): void {
  //   console.log(this.$state)
  // }
}

export const transactionsMainComponentConfig = {
  templateUrl: 'app/transaction-history/components/main/main.html',
  controller: TransactionsMainController,
  controllerAs: 'trxMainCtrl',
  bindings: {},
}
