export default class AbstractTransformableData {
  constructor(private blacklist: string[] = [], private whitelist: string[]) { }

  private dedupKeys(): string[] {
    const keys = Object.keys(this).concat(this.whitelist)

    const keyHash = keys.reduce((hash, key) => {
      if (!hash[key]) hash[key] = true

      return hash
    }, {})

    return Object.keys(keyHash)
  }

  transform(postTransformHandler?: (transformed: any) => any): any {
    const transformed: any = {}
    const keys: string[] = this.dedupKeys()
    const blacklist: string[] = this.blacklist.concat(['blacklist', 'whitelist'])

    keys.forEach((key: string) => {
      if (this.whitelist.includes(key) || (!blacklist.includes(key) && !key.startsWith('_') && !key.startsWith('is') && !key.startsWith('$$'))) {
        if (typeof this[key]?.transform === 'function') {
          transformed[key] = this[key].transform()
        } else {
          transformed[key] = this[key]
        }
      }
    })

    if(postTransformHandler) {
      return postTransformHandler(JSON.parse(JSON.stringify(transformed)))
    }

    return JSON.parse(JSON.stringify(transformed))
  }
}
