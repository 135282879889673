import { TransactionHistoryDataProvider } from './transaction-history-data-provider'

export class TransactionHistoryRunner {
  static $inject = ['routerHelper']

  constructor(routerHelper: any) {
    routerHelper.configureStates(this.getStates())
  }

  getStates(): any[] {
    return [
      {
        state: 'policy.roles.transactions',
        config: {
          component: 'transactionsMainComponent',
          url: '/transactions',
          data: {
            secure: true,
            module: 'transactions',
          },

        },
      },
      {
        state: 'policy.roles.transactions.history',
        config: {
          component: 'transactionHistoryComponent',
          url: '/history',
          data: {
            secure: true,
            module: 'transactions',
            title: 'Role History',
          },
          resolve: {
            // currentParties: ['clientResults', (clientResults) => clientResults.data.parties],
          },
          // onEnter: [() => {

          // }],
        },
      },
      {
        state: 'policy.roles.transactions.client',
        config: {
          component: 'transactionHistoryClientComponent',
          url: '/history-client/:clientId',
          data: {
            secure: true,
            module: 'transactions',
            title: 'Client History Transactions',
          },
          resolve: {
            // currentParties: ['clientResults', (clientResults) => clientResults.data.parties],
          },
          // onEnter: [() => {

          // }],
        },
      },
      {
        state: 'policy.roles.transactions.summary',
        config: {
          component: 'transactionSummaryComponent',
          url: '/summary/:batchId',
          data: {
            secure: true,
            module: 'transactions',
            title: 'Role History',
          },
          params: {
            transaction: null,
          },
          resolve: {
            transaction: ['$stateParams', ($stateParams) => $stateParams.transaction],
            summaryResponse: ['transactionHistoryDataProvider', '$stateParams', 'transaction', (transactionHistoryDataProvider: TransactionHistoryDataProvider, $stateParams) => transactionHistoryDataProvider.getTransactionSummary($stateParams.batchId)],
          },
          // onEnter: ['transactionHistoryDataProvider', '$state', '$stateParams', (transactionHistoryDataProvider, $state, $stateParams) => {
          // }],
        },
      },
      {
        state: 'policy.roles.transactions.details',
        config: {
          component: 'transactionDetailsComponent',
          url: '/details/:actionId/:clientId',
          data: {
            secure: true,
            module: 'transactions',
            title: 'Role History',
          },
          params: {
            headerInfo: null,
          },
          resolve: {
            headerInfo: ['$stateParams', ($stateParams) => $stateParams.headerInfo],
            detailsResponse: ['transactionHistoryDataProvider', '$stateParams', (transactionHistoryDataProvider: TransactionHistoryDataProvider, $stateParams) => transactionHistoryDataProvider.getTransactionDetail($stateParams.actionId, $stateParams.clientId)],
          },
          // onEnter: ['transactionHistoryDataProvider', '$state', '$stateParams', (transactionHistoryDataProvider, $state, $stateParams) => {
          //   if (!transactionHistoryDataProvider.hasHistoryData) {
          //     $state.go('policy.roles.transactions.history', { id: $stateParams.id })
          //   }
          // }],
        },
      },
    ]
  }
}
