
const REFRESH_TIMEOUT = 1000 * 20 // 20 seconds
const FORCE_HIDE_NO_DATA_MESSAGE = true

export class BatchStatementsController implements IBatchStatementsController {

  static $injector: any = ['$q', 'md5', 'batchStatementsDataProvider', 'authService', 'utils', 'BS_GTM', 'partyService']
  data: IBatchStatementsData
  selectedQuarter: string = ''
  message: string = ''
  statementDataAvailable: boolean = false
  showNoDataMessage: boolean
  private refreshTimerHandle: any
  private preventRefresh: boolean = false
  private isResolveDataInProgress: boolean

  constructor (private md5: any, private batchStatementsDataProvider: IBatchStatementsDataProvider, private authService: any, private utils: any,
    private BS_GTM: any, private partyService: any) {

    this.data = {} as IBatchStatementsData
    this.preventRefresh = false
    this.refresh()
      .catch((err: ErrorConstructor) => console.error('[BatchStatementsController::refresh]', err))

  }

  private updateUI (forceHideNoDataMessage: boolean = false): void {
    this.showNoDataMessage = forceHideNoDataMessage ? false : (this.authService.isInRole('HOMEOFFICE') || this.authService.isInRole('ASSISTANT')) && !this.isResolveDataInProgress && !this.data.hasData
    this.statementDataAvailable = !this.isResolveDataInProgress && this.data.hasData
  }

  /**
   * Calls BatchStatementsController::resolveData and then schedule
   * another called to refresh in `REFRESH_TIMEOUT` seconds
   */
  private refresh (): Promise<any> {
    if (this.preventRefresh) {
      return Promise.resolve()
    } else {
      this.updateUI(FORCE_HIDE_NO_DATA_MESSAGE)
      return this.resolveData()
        .catch(err => console.error(err))
        .then(() => this.updateUI())
        .then(() => setTimeout(() => this.refresh(), REFRESH_TIMEOUT))
        .then(handle => this.refreshTimerHandle = handle)

    }
  }

  public stopRefresh () {
    // console.debug('[BatchStatementsController::stopRefresh] stopping refresh timer: ', this.refreshTimerHandle)
    this.preventRefresh = true
    clearTimeout(this.refreshTimerHandle)
  }
  /**
   * Returns `true` if the md5 checksum of currentData and newData do not match
   *
   * @param data
   */
  private hasNewData (data: IBatchStatementsData): boolean {
    const currentDataMd5 = this.md5.createHash(JSON.stringify(this.data))
    const newDataMd5 = this.md5.createHash(JSON.stringify(data))

    // console.debug('currentDataMd5[%s] !== newDataMd5[%s] === %s', currentDataMd5, newDataMd5, currentDataMd5 !== newDataMd5)
    return currentDataMd5 !== newDataMd5
  }

  /**
   * Send GA tracking info for the submitted `quarter` provided.
   *
   * @param quarter
   */
  private gtmSubmit (quarter: string) {
    if (window.dataLayer) {
      window.dataLayer.push(this.utils.buildGtmObject(this.BS_GTM.BATCH_STATEMENTS, this.BS_GTM.SUBMIT, {
        quarter,
      }))
    }
  }

  /**
   * Send GA tracking info for the downloaded `quarter` provided.
   *
   * @param quarter
   */
  private gtmDownload (quarter: string) {
    if (window.dataLayer) {
      window.dataLayer.push(this.utils.buildGtmObject(this.BS_GTM.BATCH_STATEMENTS, this.BS_GTM.DOWNLOAD, {
        quarter,
      }))
    }
  }

  /**
   * Returns a promise to get all available statement data for logged in user.
   */
  resolveData (): Promise<boolean> {

    if (this.isResolveDataInProgress) {
      console.debug('[BatchStatementsController::resolveData] Skipping because refresh is already in progress.')
      return Promise.resolve(false)
    } else {
      this.isResolveDataInProgress = true
      return this.batchStatementsDataProvider.getAvailable(this.partyService.getAgentKey())
        .then((data: IBatchStatementsData) => {
          data.sortAndFilterAvailable()
          if (this.hasNewData(data)) this.data = data
          return true
        })
        .catch(err => console.error('[BatchStatementsController::resolveData] %o', err))
        .then(() => this.isResolveDataInProgress = false)
    }
  }

  /**
   * Sends the request to generate a batch of statements for the selectedQuarter
   *
   * @param selectedQuarter
   *
   * > example: Q1-2019
   */
  requestStatements (selectedQuarter: string): Promise<boolean> {
    const foundQuarter = this.data.quarters.filter(quarter => quarter.key === selectedQuarter)[0]

    if (!foundQuarter) throw new Error('Quarter ' + selectedQuarter + ' not found in available quarters')

    return this.batchStatementsDataProvider.requestStatements(this.partyService.getAgentKey(), foundQuarter.year, foundQuarter.quarter)
      .then(() => this.gtmSubmit(this.selectedQuarter))
      .then(() => true)

  }

  /**
   * Starts the download of the batch statements zip referenced by the button clicked.
   *
   * @param quarter
   * @param fileUrl
   */
  download (quarter: string, fileUrl: string) {
    // if (this.isMobilePlatform) {
    //   window.open(url, '_system')
    // } else {
    const url = this.batchStatementsDataProvider.getDownloadUrl(fileUrl, this.authService.getSessionID(), this.partyService.getAgentKey())

    window.open(url, '_blank')
    this.gtmDownload(quarter)
    // }
  }

}
