export { appRun }
appRun.$inject = ['routerHelper']

/* @ngInject */
function appRun(routerHelper) {
  routerHelper.configureStates(getStates())
}

function getStates() {
  return [{
    state: 'client.me.terms-and-conditions',
    config: {
      url: '/terms-and-condition',
      templateUrl: 'app/terms-and-condition/terms-and-conditions-view.html',
      controller: 'TermsAndConditionsController',
      controllerAs: 'termsAndConditionsCtrl',
      data: {
        secure: true,
      },
      resolve: {
        termsHtml: ['$http', function ($http) {
          return $http.get('/crafter/assets/html-templates/terms-and-conditions.html')
            .then((resp) => resp.data)
        }],
      },
      onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
        const pageTitle = 'Terms and Condition'

        $rootScope.pageTitle = pageTitle
        $rootScope.state = 'terms-and-condition-view'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', '$stateParams', function ($rootScope) {
        $rootScope.isPopupVisible = false
      }],
    },
  },
  {
    state: 'terms-and-conditions',
    config: {
      url: '/terms-and-condition',
      templateUrl: 'app/terms-and-condition/terms-and-conditions-view.html',
      controller: 'TermsAndConditionsController',
      controllerAs: 'termsAndConditionsCtrl',
      data: {
        secure: false,
      },
      resolve: {
        termsHtml: ['$http', function ($http) {
          return $http.get('/crafter/assets/html-templates/terms-and-conditions.html')
            .then((resp) => resp.data)
        }],
      },
      onEnter: ['$rootScope', '$stateParams', function ($rootScope, $stateParams) {
        const pageTitle = 'Terms and Condition'

        $rootScope.pageTitle = pageTitle
        $rootScope.state = 'terms-and-condition-view'
        $rootScope.isPopupVisible = true
      }],
      onExit: ['$rootScope', '$stateParams', function ($rootScope) {
        $rootScope.isPopupVisible = false
      }],
    },
  },
  ]
}
