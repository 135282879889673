export {PolicyDetailInformationController}

PolicyDetailInformationController.$inject = ['taxInfo', 'policyCoveragesResult', 'utils', '$filter', 'policyUtils', 'authService']

function PolicyDetailInformationController(taxInfo, policyCoveragesResult, utils, $filter, policyUtils, authService) {
  const self = this
  const currencyFilter = $filter('currency')

  self.commissionInformation = []
  self.coverages = policyCoveragesResult.data
  self.regulatoryTests = []
  self.regulatoryTestsTitle = '' // Might be filled in when initialized
  self.tax = taxInfo
  self.taxInfoNotAvailableMessage = 'There is no additional information available for this policy.'
  self.isClient = authService.isAClient()

  self._constants = {
    GUIDELINE_PREMIUM: 'Guideline Premium',
    SEVEN_PAY: '7-Pay',
  }

  self._init = function() {
    const makeDisplayObject = self._regulatoryTestDisplay(self._regulatoryTestsInfo, taxInfo.data)
    const regulatoryTestKeysPresent = Object.keys(taxInfo.data || {})

    self.regulatoryTests = regulatoryTestKeysPresent
      .map(makeDisplayObject)
      .filter(Boolean)

    self.regulatoryTestsTitle = self._regulatoryTestsTitle(self._regulatoryTestsInfo, regulatoryTestKeysPresent)
    self.commissionInformation = policyUtils.reduceCoveragesToCommissions(self.coverages.coverage)
  }

  self._regulatoryTestDisplay = function(regulatoryTestsInfo, data) {
    // Returns a function that only needs a key so it can be used for mapping.
    return function(testKey) {
      const regulatoryTest = regulatoryTestsInfo[testKey]

      if (regulatoryTest) {
        return {
          label: regulatoryTest.label,
          value: regulatoryTest.filter(data[testKey]),
        }
      }

      return null
    }
  }

  self.showIndicatorCode = function(indicatorCode) {
    return indicatorCode === 'Basic Coverage' || indicatorCode === 'Base Increase'
  }

  self._regulatoryTestsInfo = {
    guidelineSinglePrem: {
      filter: currencyFilter,
      label: 'GLP Single',
      regulation: self._constants.GUIDELINE_PREMIUM,
    },
    guidelineAnnPrem: {
      filter: currencyFilter,
      label: 'GLP Level',
      regulation: self._constants.GUIDELINE_PREMIUM,
    },
    cumGuidelineAnnPrem: {
      filter: currencyFilter,
      label: 'GLP Level Accum',
      regulation: self._constants.GUIDELINE_PREMIUM,
    },
    sevenPayPrem: {
      filter: currencyFilter,
      label: '7-Pay Premium',
      regulation: self._constants.SEVEN_PAY,
    },
    cumSevenPayPrem: {
      filter: currencyFilter,
      label: '7-Pay Premium Accum',
      regulation: self._constants.SEVEN_PAY,
    },
    tamraPaidITD: {
      filter: currencyFilter,
      label: '7-Pay Paid Accum',
      regulation: self._constants.SEVEN_PAY,
    },
    tamraWithdrawnITD: {
      filter: currencyFilter,
      label: '7-Pay Withdrawn Accum',
      regulation: self._constants.SEVEN_PAY,
    },
    materialChangeDate: {
      filter: function(dateString) {
        return $filter('date')(new Date(dateString), 'MMM d, yyyy')
      },
      label: 'Material Change Date',
      regulation: null,
    },
  }

  self._regulatoryTestsTitle = function(regulatoryTestsInfo, regulatoryTestKeysPresent) {
    let regulationsPresent = regulatoryTestKeysPresent
      .map(utils.getFrom(regulatoryTestsInfo))
      .filter(Boolean)
      .map(utils.dot('regulation'))
      .filter(Boolean)

    regulationsPresent = utils.dedupe(regulationsPresent)

    if (regulationsPresent.length) {
      return regulationsPresent.join(' and ') + ' Information'
    }

    return ''
  }

  self._init()
}
